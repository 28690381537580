<div class="min-vh-100">
  <div class="our-choose-area our-choose-area-css dec-spacing mt-100">
    <div class="container">
      <div>
        <h1 class="text-center p-5">FREQUENTLY ASKED QUESTIONS ON PAYROLL</h1>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <!-- section title -->
          <div class="accordion" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingOne">
                <a
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <i class="fal fa-long-arrow-right"></i> Q.1: WHAT IS
                  CONSIDERED AS SALARY INCOME?
                </a>
              </div>
              <div
                id="collapseOne"
                class="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p>
                    <span style="color: green; font-weight: 550">Answer:</span>
                    Section 17 of the Income-tax Act defines the term ‘salary’.
                    However, not going into the technical definition, generally
                    whatever is received by an employee from an employer in
                    cash, kind or as a facility [perquisite] is considered as
                    salary.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingTwo">
                <a
                  class="collapsed"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <i class="fal fa-long-arrow-right"></i>Q.2: WHAT ARE
                  ALLOWANCES? ARE ALL ALLOWANCES TAXABLE? DURING THE YEAR I HAD
                  WORKED WITH THREE DIFFERENT EMPLOYERS AND NONE OF THEM
                  DEDUCTED ANY TAX FROM SALARY PAID TO
                </a>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p>
                    <span style="color: green; font-weight: 550">Answer:</span>
                    Allowances are fixed periodic amounts, apart from salary,
                    which are paid by an employer for meeting some requirements
                    of the employee. E.g., Tiffin allowance, transport
                    allowance, uniform allowance, etc. There are generally three
                    types of allowances for Income-tax Act – taxable allowances,
                    fully exempted allowances and partially exempted allowances.
                    ​
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingThree">
                <a
                  class="collapsed"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <i class="fal fa-long-arrow-right"></i> Q.3: DURING THE YEAR I HAD WORKED WITH THREE DIFFERENT EMPLOYERS AND NONE OF THEM DEDUCTED ANY TAX FROM SALARY PAID TO ME. IF ALL THESE AMOUNTS ARE CLUBBED TOGETHER, MY INCOME WILL EXCEED THE  BASIC EXEMPTION LIMIT. DO I HAVE TO PAY TAXES ON MY OWN ?
                </a>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p>
                    <span style="color: green; font-weight: 550">Answer: </span>
                    Yes, you will have to pay self-assessment tax and file the return of income.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingFour">
                <a
                  class="collapsed"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <i class="fal fa-long-arrow-right"></i> Q.4: EVEN IF NO TAXES
                  HAVE BEEN DEDUCTED FROM SALARY, IS THERE ANY NEED FOR MY
                  EMPLOYER TO ISSUE FORM-16 TO ME?
                </a>
              </div>
              <div
                id="collapseFour"
                class="collapse"
                aria-labelledby="headingFour"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p>
                    <span style="color: green; font-weight: 550">Answer: </span>
                    Form-16 is a certificate of TDS. In your case it will not apply. However, your employer can issue a salary statement.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingFive">
                <a
                  class="collapsed"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  <i class="fal fa-long-arrow-right"></i> Q.5: ARE RETIREMENT
                  BENEFITS LIKE PF AND GRATUITY TAXABLE?
                </a>
              </div>
              <div
                id="collapseFive"
                class="collapse"
                aria-labelledby="headingFive"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p>
                    <span style="color: green; font-weight: 550">Answer: </span>
                    In the hands of a Government employee Gratuity and PF receipts on retirement are exempt from tax. In the hands of non-Government employee, gratuity is exempt subject to the limits prescribed in this regard and PF receipts are exempt from tax, if the same are received from a recognised PF after rendering continuous service of not less than 5 years.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingSix">
                <a
                  class="collapsed"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  <i class="fal fa-long-arrow-right"></i> Q.6: ARE ARREARS OF
                  SALARY TAXABLE?
                </a>
              </div>
              <div
                id="collapseSix"
                class="collapse"
                aria-labelledby="headingSix"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p>
                    <span style="color: green; font-weight: 550">Answer: </span>
                    Yes. However, the benefit of spread over of income to the years to which it relates to can be availed for lower incidence of tax. This is called as relief u/s 89 of the Income-tax Act.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingSeven">
                <a
                  class="collapsed"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  <i class="fal fa-long-arrow-right"></i> Q.7: IS LEAVE
                  ENCASHMENT TAXABLE AS SALARY? 
                </a>
              </div>
              <div
                id="collapseSeven"
                class="collapse"
                aria-labelledby="headingSeven"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p>
                    <span style="color: green; font-weight: 550">Answer: </span>
                    It is taxable if received while in service. Leave encashment received at the time of retirement is exempt in the hands of the Government employee. In the hands of non-Government employee leave encashment will be exempt subject to the limit prescribed in this behalf under the Income-tax Law.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingEight">
                <a
                  class="collapsed"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  <i class="fal fa-long-arrow-right"></i> Q.8: IS STANDARD DEDUCTION APPLICABLE TO ALL THE SALARIED PERSON WHETHER HE IS AN EMPLOYEE OF CENTRAL OR STATE GOVERNMENT ? | IS
                  STANDARD DEDUCTION APPLICABLE TO PENSIONER ? | DOES STANDARD
                  DEDUCTION APPLICABLE TO PERSON RECEIVING PENSION? | WHAT IS
                  THE LIMIT OF ?
                </a>
              </div>
              <div
                id="collapseEight"
                class="collapse"
                aria-labelledby="headingEight"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p>
                    <span style="color: green; font-weight: 550">Answer: </span>
                    Generally, the tax on income crystallizes only on completion
                    of the previous year. However, for ease of collection and
                    regularity of flow of funds to the Government for its
                    various activities, the Income-tax Act has laid down the
                    provisions for payment of taxes in advance during the year
                    of earning itself. It is called as ‘pay as you earn’
                    concept. Taxes may also be collected on your behalf during
                    the previous year itself through TDS and TCS mode. If at the
                    time of filing of return you find that you have some balance
                    tax to be paid after taking into account the credit of your
                    advance tax, TDS & TCS, the shortfall is to be deposited as
                    Self Assessment Tax.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- accordion -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </div>
</div>
