<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop divmart">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/consulting.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Consulting & Advisory
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                  <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev" >
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                      aria-expanded="true" aria-controls="collapseSevv" >
                       <span (click)="onAccounting()"> Accounting & Transaction Processing</span>
                      </a>
                    </div>
                    <div id="collapseSevv" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div class="list-group">
                          <ul class="text-dark serviceul">

                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Receivable
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Fixed Assets Accounting
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Budgeting & Variance Analysis
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Consolidation Reporting & GAAP Conversion
                            </li>
                            <li routerLink="/small-business-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Small Business Accounting
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href=""  data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li routerLink="/individual-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Individual Tax Return
                            </li>
                            <li routerLink="/corporate-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Tax Return
                            </li>
                            <li routerLink="/sales-and-use-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Sales & Use Tax
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href=""  data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span (click)="onPayroll()">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Payroll Processing
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a  href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span routerLink="/consulting-and-advisory-services">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse show" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Setting up an entity in the US
                            </li>
                            <li routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Global Corporate Structuring
                            </li>
                            <li routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Plan Preparation
                            </li>
                            <li routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#business-valuation-service"> Business Valuation</a>
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3>Consulting and Advisory</h3>
                    <!--<p class="mb-15 mt-20 text-dark servicep">
                      Establish Your Business Presence with Expert Entity Setup Services by BFAG
                    </p>-->
                    <p class="mb-15 mt-20 text-dark servicep">
                      As consultants specializing in Mergers and Acquisitions (M&A) and Global Structuring, our philosophy is centred around helping businesses navigate the complex landscape of strategic transactions and international business structures. We understand that M&A deals and global structuring have the potential to reshape companies and unlock significant growth opportunities. Our aim is to provide comprehensive expertise, strategic guidance, and meticulous execution to ensure our clients achieve their desired outcomes.
                    </p>
                    <p class="mb-15 mt-20 text-dark servicep">
                      At BFAG, we provide exceptional consulting services for M&A and Global Structuring. Our philosophy is rooted in strategic thinking, meticulous execution, and a commitment to delivering results that drive business growth and global success. Partner with us to navigate the complexities of M&A transactions and global structuring with confidence and achieve your business objectives effectively.
                    </p>
                  </div>

                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>

<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop divmart">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center banner-image bmTop"
      style="background: url('../../../../assets/images/service/consulting.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Consulting & Advisory
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                  <div class="faq-accordion-3 pt-0">
                  <div class="accordion" id="accordionExample1">
                 <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                   <div class="card-header" id="headingSev" >
                     <a class="collapsed"  data-toggle="collapse" data-target="#collapseSevv"
                     aria-expanded="true" aria-controls="collapseSevv" >
                      <span (click)="onAccounting()"> Accounting & Transaction Processing</span>
                     </a>
                   </div>
                   <div id="collapseSevv" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample1">
                     <div class="card-body bg-white">
                       <div class="list-group">
                         <ul class="text-dark serviceul">

                           <li  routerLink="/accounts-payable">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Accounts Payable
                           </li>
                           <li routerLink="/accounts-receivable">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Accounts Receivable
                           </li>
                           <li routerLink="/fixed-assets-accounting">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Fixed Assets Accounting
                           </li>
                           <li routerLink="/financial-reporting">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Financial Reporting
                           </li>
                           <li routerLink="/budgeting-and-variance-analysis">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Budgeting & Variance Analysis
                           </li>
                           <li routerLink="/reconciliation-services">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Reconciliation Services
                           </li>
                           <li routerLink="/consolidation-reporting-gaap-conversion">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Consolidation Reporting & GAAP Conversion
                           </li>

                         </ul>
                       </div>
                     </div>
                   </div>
                 </div>
                 <!-- card -->
                 <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                   <div class="card-header" id="headingEight">
                     <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                       aria-expanded="false" aria-controls="collapseEight">
                       <span (click)="onTax()">Tax & Regulatory Compliances</span>
                     </a>
                   </div>
                   <div id="collapseEight" class="collapse " aria-labelledby="headingEight" data-parent="#accordionExample1">
                     <div class="card-body bg-white">
                       <div>
                         <ul class="text-dark serviceul">
                           <li routerLink="/income-tax">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Income Tax
                           </li>
                           <li  routerLink="/goods-and-service-tax">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Goods & Service Tax
                           </li>
                           <li routerLink="/corporate-secretarial-compliance">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Corporate Secretarial Compliance
                           </li>
                           <li  routerLink="/fema-advisory">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            FEMA Compliance
                           </li>
                         </ul>
                       </div>
                     </div>
                   </div>
                 </div>

                 <!-- card -->
                 <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                   <div class="card-header" id="headingNine">
                     <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                       aria-expanded="false" aria-controls="collapseNine">
                       <span (click)="onPayroll()">Payroll Advisory</span>
                     </a>
                   </div>
                   <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                     <div class="card-body bg-white">
                       <div>
                         <ul class="text-dark pt-4 serviceul">
                           <li routerLink="/payroll-processing">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Payroll - Compensation & Benefit Management
                           </li>
                         </ul>
                       </div>
                     </div>
                   </div>
                 </div>
                 <!-- card -->

                 <!-- card -->
                 <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                   <div class="card-header" id="headingTen">
                     <a  href="" data-toggle="collapse" data-target="#collapseTen"
                       aria-expanded="false" aria-controls="collapseTen">
                       <span routerLink="/consulting-and-advisory-services">Consulting & Advisory</span>
                     </a>
                   </div>
                   <div id="collapseTen" class="collapse show" aria-labelledby="headingTen" data-parent="#accordionExample1">
                     <div class="card-body bg-white">
                       <div>
                         <ul class="text-dark pt-4 serviceul">
                           <li routerLink="/business-setup">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Setting up an entity in the India
                           </li>
                           <li routerLink="/global-corporate-structuring">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Global Corporate Structuring
                           </li>
                           <li routerLink="/business-plan-preparation">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Business Plan Preparation
                           </li>
                           <li routerLink="/business-valuation-service">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Business Valuation
                           </li>
                           <li routerLink="/mergers-and-acquisitions">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Mergers and Acquisitions
                           </li>
                         </ul>
                       </div>
                     </div>
                   </div>
                 </div>
                 <!-- card -->
                 <!-- card -->
                 <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                   <div class="card-header" id="headingElevenStartup">
                     <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseElevenStartup"
                       aria-expanded="false" aria-controls="collapseElevenStartup">
                       <span (click)="onStartup()">Startup Solution</span>
                     </a>
                   </div>
                   <div id="collapseElevenStartup" class="collapse" aria-labelledby="headingElevenStartup" data-parent="#accordionExample1">
                     <div class="card-body bg-white">
                       <div>
                         <ul class="text-dark pt-4 serviceul">
                           <li routerLink="/startup-solution">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Startup Solution
                           </li>
                           <li routerLink="/fund-raising">
                             <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Fund Raise
                           </li>
                         </ul>
                       </div>
                     </div>
                   </div>
                 </div>
                 <!-- card -->
                  </div>
                  </div>
               </div>
               <div class="col-md-8">
                <div style="margin-top: 1rem">
                  <h3>Consulting and Advisory</h3>
                  <!--<p class="mb-15 mt-20 text-dark servicep">
                    Establish Your Business Presence with Expert Entity Setup Services by BFAG
                  </p>-->
                  <p class="mb-15 mt-20 text-dark servicep">
                    As consultants specializing in Mergers and Acquisitions (M&A) and Global Structuring, our philosophy is centred around helping businesses navigate the complex landscape of strategic transactions and international business structures. We understand that M&A deals and global structuring have the potential to reshape companies and unlock significant growth opportunities. Our aim is to provide comprehensive expertise, strategic guidance, and meticulous execution to ensure our clients achieve their desired outcomes.
                  </p>
                  <p class="mb-15 mt-20 text-dark servicep">
                    At BFAG, we provide exceptional consulting services for M&A and Global Structuring. Our philosophy is rooted in strategic thinking, meticulous execution, and a commitment to delivering results that drive business growth and global success. Partner with us to navigate the complexities of M&A transactions and global structuring with confidence and achieve your business objectives effectively.
                  </p>
                </div>

              </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>

<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop divmart">
  <div class="d-flex justify-content-center align-items-center banner-image bmTop"
      style="background: url('../../../../assets/images/service/consulting.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Consulting & Advisory
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">

          <div class="row">
            <div class="col-md-4">
              <div class="faq-accordion-3 pt-0">
              <div class="accordion" id="accordionExample1">
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px; background-color: #4180521A;">
                <div class="card-header" id="headingSev">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                    aria-expanded="true" aria-controls="collapseSevv" style="color: #418052;">
                    <span (click)="onAccounting()">Accounting & Transaction Processing</span>
                  </a>
                </div>
                <div id="collapseSevv" class="collapse " aria-labelledby="headingSev" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark serviceul">
                        <li routerLink="/accounting-services" style="color: black">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Accounts Payable
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingEight">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                    aria-expanded="false" aria-controls="collapseEight" style="color: #418052;">
                    <span (click)="onTax()">Tax & Regulatory Compliances</span>
                  </a>
                </div>
                <div id="collapseEight" class="collapse " aria-labelledby="headingEight" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark serviceul">
                        <li class="shli" routerLink="/coordination-and-assistance-for-vat-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         VAT Advisory
                        </li>
                        <li  routerLink="/coordination-and-assistance-for-tax-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Corporate Tax Advisory
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingNine">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                    aria-expanded="false" aria-controls="collapseNine" style="color: #418052;">
                    <span (click)="onAudit()"> Audit & Assurance Support</span>
                  </a>
                </div>
                <div id="collapseNine" class="collapse " aria-labelledby="headingNine" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark pt-4 serviceul">
                        <li  routerLink="/statutory-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Statutory Audit
                        </li>
                        <li  routerLink="/internal-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Internal Audit
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->

              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingTen">
                  <a  href="" data-toggle="collapse" data-target="#collapseTen"
                    aria-expanded="false" aria-controls="collapseTen" style="color: #418052;">
                    <span routerLink="/consulting-and-advisory-services">Consulting & Advisory</span>
                  </a>
                </div>
                <div id="collapseTen" class="collapse show" aria-labelledby="headingTen" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark pt-4 serviceul">
                        <li routerLink="/business-setup">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Setting up an entity in Dubai
                        </li>
                        <li routerLink="/business-plan-preparation">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Business Plan Preparation
                        </li>
                        <li routerLink="/business-valuation-service">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Business Valuation
                        </li>
                        <li routerLink="/mergers-and-acquisitions">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Mergers and Acquisitions (M&A)
                        </li>
                        <li routerLink="/strategic-cfo-services">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Strategic CFO
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->
              </div>
              </div>

            </div>
            <div class="col-md-8">
              <div style="margin-top: 1rem">
                <h3 style="color: #418052;">Consulting and Advisory </h3>
                <!--<p class="mb-15 mt-20 text-dark servicep">
                  Streamline Your Finances with Expert Accounts Payable Services
                </p>-->
                <p class="mb-15 mt-20 text-dark servicep">
                  As consultants specializing in Mergers and Acquisitions (M&A) and Global Structuring, our philosophy is centered around helping businesses navigate the complex landscape of strategic transactions and international business structures. We understand that M&A deals and global structuring have the potential to reshape companies and unlock significant growth opportunities. Our aim is to provide comprehensive expertise, strategic guidance, and meticulous execution to ensure our clients achieve their desired outcomes.
                </p>
                <p class="mb-15 mt-20 text-dark servicep">
                  At BFAG, we are dedicated to providing exceptional consulting services for M&A and Global Structuring. Our philosophy is rooted in strategic thinking, meticulous execution, and a commitment to delivering results that drive business growth and global success. Partner with us to navigate the complexities of M&A transactions and global structuring with confidence and achieve your business objectives effectively.
                </p>
              </div>

            </div>
          </div>
          <!-- row -->

      </div>

    </div>

</section>
