import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {
  selectCountryName: string;
  defaultCountry: string;
  selectedFlag: string;

  blog: any; // Define your blog object structure here
  subarray:any = []
  constructor(private LocalStorageServiceService: LocalStorageServiceService, private route: ActivatedRoute) {}



  ngOnInit(): void {
    this.defaultCountry = 'USA';

    this.selectCountryName = localStorage.getItem('selectedCountry');

    if (this.selectCountryName == null) {
      localStorage.setItem('selectedCountry', this.defaultCountry);
      window.location.reload();
    } else {
      this.selectCountryName = localStorage.getItem('selectedCountry');
    }
    // Retrieve the blog ID from the route parameter
    // Retrieve the blog ID from the route parameter
    this.route.params.subscribe((params) => {
      const blogId = params['_id']; // 'id' should match the route parameter name

      // Fetch the specific blog post using the ID (you'll need to implement this)
      this.fetchBlogById(blogId);
    });

  }


  // Implement a method to fetch the specific blog post by ID
  // You can use a similar API call as in your 'fetchBlogs' method
  async fetchBlogById(blogId: string) {
    try {
      // Make an HTTP request to your API endpoint to fetch the blog post by ID
      const response = await fetch(`https://rich-tan-dhole-kilt.cyclic.app/api/blogs/${blogId}`, {
        method: 'GET',
      });

      if (response.ok) {
        // Parse the response JSON to get the blog data
        const blogData = await response.json();

        // Assign the fetched blog data to the 'blog' property
        this.blog = blogData;
        this.subarray = JSON.parse(this.blog.subtitle)
        console.log(this.blog)
      } else {
        console.error('Failed to fetch blog data.');
      }
    } catch (error) {
      console.error('An error occurred while fetching blog data:', error);
    }
  }


  selectCountry(country: string): void {
    // Update the selectedFlag with the corresponding image path
    if (country === 'Dubai') {
      this.selectedFlag =
        '/assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png';
    } else if (country === 'India') {
      this.selectedFlag = '/assets/images/flag/download.png';
    } else if (country === 'USA') {
      this.selectedFlag = '/assets/images/flag/USA.png';
    } else {
      // Set USA as the default country
      this.selectedFlag =
        '/assets/images/flag/USA.png';
      country = 'USA';
    }

    this.LocalStorageServiceService.setItem('selectedCountry', country);
  }
}


