<style>
    .newColor{
        color:#343a40;
    }
</style>

<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Cookies Policy</h3>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Privacy Policy Area -->
<div class="privacy-policy-area pt-100 pb-70">
    <div class="container">
        <div class="single-content">
            <p class="newColor"><strong><em>Last Updated : 01/03/2024 </em></strong></p>
            <p class="newColor"><strong><em>Last updated : 27/01/2022 </em></strong></p>
            <p>&nbsp;</p>
            <p class="newColor">This Cookie Policy explains how Boston Financial Advisory Group (collectively, “we” or
                the Company” or &quot;Boston&quot;) uses cookies and similar technologies to recognize and
                when you visit our website at https://bostonfagroup.com/ , It explain what these
                technologies are and why we use them, as well as your rights to control our use of
                them.
                In some cases, we may use cookies to collect personal information, or that becomes
                personal information if we combine it with other information.This Cookie Policy explains how Boston
                Financial Advisory Group (collectively, “we” or
                the Company” or &quot;Boston&quot;) uses cookies and similar technologies to recognize and
                when you visit our website at https://bostonfagroup.com/ , It explain what these
                technologies are and why we use them, as well as your rights to control our use of
                them.
                In some cases, we may use cookies to collect personal information, or that becomes
                personal information if we combine it with other information.</p>
            <p></p>
            <p><strong>&nbsp;</strong></p>
            <p class="newColor"><strong>What are cookies?</strong></p>

            <p class="newColor">Cookies are small data files that are placed on your computer or mobile device when
                you visit a website. Cookies are widely used by website owners in order to make their
                website work, or to work more efficiently, as well as to provide reporting information.Cookies are small
                data files that are placed on your computer or mobile device when
                you visit a website. Cookies are widely used by website owners in order to make their
                website work, or to work more efficiently, as well as to provide reporting information.</p>
            <p></p>
            <p class="newColor">Cookies set by the website owner( in this case, Boston Financial Advisory Group) are
                called “First-Party cookies.” Cookies set by parties other than the website owner are
                called “Third-Party Cookies”.</p>
            <p>&nbsp;</p>
            <p class="newColor"><strong>USER INFORMATION</strong></p>
            <p class="newColor">Third-party cookies enable third-party feature or functionality to be provided on or
                through the website. The parties that set these third-party cookies can recognize your
                computer both when it visits the website in questions and also when it visits certain
                other websites.</p>
            <p class="newColor"><strong>Why do we use cookies?</strong></p>


            <p>&nbsp;</p>
            <p class="newColor">We use first and third-party cookies for several reasons. Some cookies are required for
                technical reasons in order for our website io operate, and we refer to these as
                “essential” or “Strictly necessary” Cookies. Other cookies also enable us to track and
                target the interests of our users to enhance the experience on our online properties.
                Third parties serve cookies through website for advertising, analytics and other
                purposes. This is described in more detail below.</p>
            <p class="newColor"><strong>Why can I control cookies?</strong></p>
            <p class="newColor">
                You have the right to decide whether to accept or reject cookies. You can exercise your
                cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie
                Consent Manager allows you to select which categories of cookies you accept or reject.

                Essential cookies cannot be rejected as they are strictly necessary to provide you with
                services.You have the right to decide whether to accept or reject cookies. You can exercise your
                cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie
                Consent Manager allows you to select which categories of cookies you accept or reject.
            </p>
            <p class="newColor">The Cookie Consent Manager can be found in the notification banner and on our
                website. If you choose to reject cookies, you may still use our website though your
                access to some functionality and areas of our website may be restricted. You may also
                set or amend your web browser controls to accept or refuse cookies.</p>
                <p class="newColor">The specific types of first- and third-party cookies served through our Website and the
                    purposes they perform are described in the table below (please note that the specific
                    cookies served may vary depending on the specific Online Properties you visit):</p>
                    <p class="newColor"><strong>How can I control cookies on my browser?</strong></p>
                    <p class="newColor">As the means by which you can refuse cookies through your web browser controls vary
                        from browser to browser, you should visit your browsers help menu for more
                        information. The following is information about how to manage cookies on the most
                        popular browsers:</p>
                        <ul>
                            <li class="newColor">Chrome</li>
                            <li class="newColor">Internet Explorer</li>
                            <li class="newColor">Firefox</li>
                            <li class="newColor">Safari</li>
                            <li class="newColor">Edge</li>
                            <li class="newColor">Opera</li>
                          </ul>
                          <p>&nbsp;</p>
                          <p class="newColor"><strong>What about other tracking technologies, like web beacons?</strong></p>
                          <p class="newColor">Cookies are not the only way to recognize or track visitors to a website. We may use
                          other, similar technologies from time to time, like web beacons (sometimes called
                          &quot;tracking pixels&quot; or &quot;clear gifs&quot;). These are tiny graphics files that contain a unique
                          identifier that enables us to recognize when someone has visited our website or opened
                          an email including them. This allows us, for example, to monitor the traffic patterns of
                          users from one page within a website to another, to deliver or communicate with
                          cookies, to understand whether you have come to the website from an online
                          advertisement displayed on a third-party website, to improve site performance, and to
                          measure the success of email marketing campaigns. In many instances, these
                          technologies are reliant on cookies to function properly, and so declining cookies will
                          impair their functioning.</p>

                          <p class="newColor"><strong>Do you serve targeted advertising? </strong></p>
                          <p class="newColor">Third parties may serve cookies on your computer or mobile device to serve advertising
                            through our website. These companies may use information about your visits to this and
                            other websites in order to provide relevant advertisements about goods and services
                            that you may be interested in. They may also employ technology that is used to
                            measure the effectiveness of advertisements. They can accomplish this by using cookies
                            or web beacons to collect information about your visits to this and other sites in order
                            to provide relevant advertisements about goods and services of potential interest to
                            you. The information collected through this process does not enable us or them to
                            identify your name, contact details, or other details that directly identify you unless you
                            choose to provide these.</p>
                            <p class="newColor"><strong>How often will you update this cookie policy?</strong></p>
                            <p class="newColor">We may update this Cookie Policy from time to time in order to reflect changes to the
                                cookies we use for other operational, legal or regulatory reasons. Please therefore
                                revisit this cookies policy regularly to stay informed about our use of cookies and related
                                technologies.
                                The date at the top of this cookie policy indicates when it was last updated.</p>
                                <p class="newColor"><strong>Where Can I Get Further Information?</strong></p>
                                <p class="newColor">If there are any questions regarding this Cookie Policy or other technologies, please
                                    email us at <br/> <a href="mailto:privacy@bostonfagroup.com">privacy@bostonfagroup.com</a>
                                    or write to <br/><strong>USA Address:</strong><br/>  The Schrafts Center
                                    Powerhouse, Office No 341, 529 Main Street, Charlestown, MA – 02129  <br/><strong>India
                                    Address:</strong><br/>  # 54, 3rd Floor, 13th Main 17th Cross, Sector 6, Behind BDA Shopping Complex,
                                    HSR Layout, Bangalore 560 102.</p>
        </div>

    </div>
</div>
<!-- Privacy Policy Area End -->