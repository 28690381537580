<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/Tax-and-compliance-banner.png')no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Tax & Regulatory Compliances
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">

          <div class="row">
            <div class="col-md-4">
              <div class="faq-accordion-3 pt-0">
              <div class="accordion" id="accordionExample1">
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px; background-color: #4180521A;">
                <div class="card-header" id="headingSev">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                    aria-expanded="true" aria-controls="collapseSevv" style="color: #418052;">
                    <span (click)="onAccounting()">Accounting & Transaction Processing</span>
                  </a>
                </div>
                <div id="collapseSevv" class="collapse " aria-labelledby="headingSev" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark serviceul">
                        <li routerLink="/accounting-services" style="color: black">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Accounts Payable
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingEight">
                  <a  href="" data-toggle="collapse" data-target="#collapseEight"
                    aria-expanded="false" aria-controls="collapseEight" style="color: #418052;">
                    <span (click)="onTax()">Tax & Regulatory Compliances</span>
                  </a>
                </div>
                <div id="collapseEight" class="collapse show" aria-labelledby="headingEight" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark serviceul">
                        <li class="shli" routerLink="/coordination-and-assistance-for-vat-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         VAT Advisory
                        </li>
                        <li  routerLink="/coordination-and-assistance-for-tax-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Corporate Tax Advisory
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingNine">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                    aria-expanded="false" aria-controls="collapseNine" style="color: #418052;">
                    <span (click)="onAudit()"> Audit & Assurance Support</span>
                  </a>
                </div>
                <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark pt-4 serviceul">
                        <li  routerLink="/statutory-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Statutory Audit
                        </li>
                        <li routerLink="/internal-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Internal Audit
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->

              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingTen">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                    aria-expanded="false" aria-controls="collapseTen" style="color: #418052;">
                    <span (click)="onConsulting()">Consulting & Advisory</span>
                  </a>
                </div>
                <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark pt-4 serviceul">
                        <li routerLink="/business-setup">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Setting up an entity in Dubai
                        </li>
                        <li routerLink="/business-plan-preparation">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Business Plan Preparation
                        </li>
                        <li routerLink="/business-valuation-service">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Business Valuation
                        </li>
                        <li routerLink="/mergers-and-acquisitions">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Mergers and Acquisitions (M&A)
                        </li>
                        <li routerLink="/strategic-cfo-services">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Strategic CFO
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->
              </div>
              </div>

            </div>
            <div class="col-md-8">
              <div style="margin-top: 1rem">
                <h3 id="coordination-and-assistance-for-vat-audit" style="color: #418052;">VAT Advisory</h3>
                <!--<p class="mb-15 mt-20 text-dark servicep">
                  Streamline Your Finances with Expert Accounts Payable Services
                </p>-->
                <p class="mb-15 mt-20 text-dark servicep">
                  BFAG is a trusted provider of Tax & Regulatory Compliance services. We understand the ever-evolving tax landscape and regulatory requirements and offer specialized solutions to ensure your business remains compliant. Our team provides expert assistance to navigate the complexities of tax regulations.
                </p>
                <p class="mb-15 mt-20 text-dark servicep">
                  VAT has become a key aspect of business operations. BFAG offers comprehensive VAT Advisory Services to ensure your business complies with VAT regulations and optimizes its VAT-related activities. Our services include:
                </p>
              </div>
              <div class="about-faq-area-custom horizon-hrms gray-bg clearfix" style="background-color: #4180521A;">
                <div class="container">
                    <div class="row" style="display: block;">
                        <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                            <div class="accordion" id="accordionExample2">
                                <div class="col-lg-6">
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                        <div class="card-header" id="headingEleven">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven" style="color: #418052A1;">
                                              VAT Registration
                                            </a>
                                        </div>
                                        <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p style="color:black">We assist businesses in the VAT registration process, ensuring compliance with the registration requirements set by the Federal Tax Authority (FTA).</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                        <div class="card-header" id="headingTwelve">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve" style="color: #418052A1;">
                                              VAT Compliance
                                            </a>
                                        </div>
                                        <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p style="color:black">Our team helps you meet the ongoing VAT compliance obligations, including accurate record-keeping, timely VAT return filing, and compliance with VAT payment deadlines.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                      <div class="card-header" id="headingThirteen">
                                          <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen" style="color: #418052A1;">
                                            VAT Impact Assessment
                                          </a>
                                      </div>
                                      <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                          <div class="card-body text-justify" style="padding-left:20px !important">
                                              <p style="color:black">We conduct a thorough assessment of your business operations to determine the impact of VAT implementation, enabling you to make informed decisions and mitigate potential risks.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <!-- card -->
                                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                    <div class="card-header" id="headingFourteen">
                                        <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen" style="color: #418052A1;">
                                          VAT Transaction Advisory Services
                                        </a>
                                    </div>
                                    <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                        <div class="card-body text-justify" style="padding-left:20px !important">
                                            <p style="color:black">Our experts provide guidance on complex VAT transactions, ensuring compliance and minimizing VAT liabilities.</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- card -->
                                <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                  <div class="card-header" id="headingFifteen">
                                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen" style="color: #418052A1;">
                                        VAT Refund Assistance
                                      </a>
                                  </div>
                                  <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                      <div class="card-body text-justify" style="padding-left:20px !important">
                                          <p style="color: black">BFAG assists eligible businesses in navigating the VAT refund process, helping you recover eligible input VAT effectively.</p>
                                      </div>
                                  </div>
                              </div>
                              <!-- card -->
                                </div>

                                <div class="col-lg-6">
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                        <div class="card-header" id="headingSixteen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen" style="color: #418052A1;">
                                              VAT Voluntary Disclosure Assistance
                                            </a>
                                        </div>
                                        <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p style="color:black">In case of any unintentional errors or omissions in VAT filings, we provide guidance and support in making voluntary disclosures to rectify the issues.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".7s">
                                        <div class="card-header" id="headingSeventeen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen" style="color: #418052A1;">
                                              VAT Reconsideration Form Assistance
                                            </a>
                                        </div>
                                        <div id="collapseSeventeen" class="collapse " aria-labelledby="headingSeventeen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p style="color: black">If you disagree with an FTA decision, we help you prepare and submit a VAT Reconsideration Form to address the matter appropriately.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".8s">
                                      <div class="card-header" id="headingEighteen">
                                          <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen" style="color: #418052A1;">
                                            VAT Audit Support
                                          </a>
                                      </div>
                                      <div id="collapseEighteen" class="collapse " aria-labelledby="headingEighteen" data-parent="#accordionExample2">
                                          <div class="card-body text-justify" style="padding-left:20px !important">
                                              <p style="color:black">Our team offers comprehensive support during VAT audits conducted by the FTA, ensuring your business is prepared and effectively addresses any audit queries.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <!-- card -->
                                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".9s">
                                    <div class="card-header" id="headingNineteen">
                                        <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen" style="color: #418052A1;">
                                         VAT Health Check-Up
                                        </a>
                                    </div>
                                    <div id="collapseNineteen" class="collapse " aria-labelledby="headingNineteen" data-parent="#accordionExample2">
                                        <div class="card-body text-justify" style="padding-left:20px !important">
                                            <p style="color: black;">We perform a VAT Health Check-Up to assess your VAT compliance status, identify potential risks, and provide recommendations for improvement.</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- card -->

                                </div>
                            </div>
                            <!-- faq accordion -->
                        </div>
                    </div>
                    <!-- row -->
                </div>
                <!-- container -->
            </div>
            <p class="mb-15 mt-20 text-dark servicep">
              BFAG's Tax & Regulatory Compliance services are designed to ensure your business remains compliant with the ever-evolving tax landscape and regulatory requirements. By partnering with BFAG, you can navigate the complexities of tax regulations with confidence, optimizing your VAT-related activities and mitigating potential risks. Trust BFAG as your advisor in tax and regulatory compliance, allowing you to focus on your core business activities while ensuring adherence to all applicable tax laws and regulations.
            </p>
            </div>
          </div>
          <!-- row -->

      </div>

    </div>
  </div>
</section>

