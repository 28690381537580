<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/Accounting.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Accounting & Transaction Processing
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                  <div class="col-md-4">
                    <div class="faq-accordion-3 pt-0">
                      <div class="accordion" id="accordionExample1">
                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                      <div class="card-header" id="headingSev" >
                        <a  href="#accounting-and-transition-processing" data-toggle="collapse" data-target="#collapseSevv"
                        aria-expanded="true" aria-controls="collapseSevv" >
                          Accounting & Transaction Processing
                        </a>
                      </div>
                      <div id="collapseSevv" class="collapse show" aria-labelledby="headingSev" data-parent="#accordionExample1">
                        <div class="card-body bg-white">
                          <div class="list-group">
                            <ul class="text-dark serviceul">

                              <li  routerLink="/accounts-payable">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Accounts Payable
                              </li>
                              <li routerLink="/accounts-receivable">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Accounts Receivable
                              </li>
                              <li class="shli" routerLink="/fixed-assets-accounting">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Fixed Assets Accounting
                              </li>
                              <li routerLink="/financial-reporting">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Financial Reporting
                              </li>
                              <li routerLink="/budgeting-and-variance-analysis">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Budgeting & Variance Analysis
                              </li>
                              <li routerLink="/reconciliation-services">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Reconciliation Services
                              </li>
                              <li routerLink="/consolidation-reporting-gaap-conversion">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                 Consolidation Reporting & GAAP Conversion
                              </li>
                              <li routerLink="/small-business-accounting">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Small Business Accounting
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->
                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                      <div class="card-header" id="headingEight">
                        <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                          aria-expanded="false" aria-controls="collapseEight">
                          <span (click)="onTax()">Tax & Regulatory Compliances</span>
                        </a>
                      </div>
                      <div id="collapseEight" class="collapse " aria-labelledby="headingEight" data-parent="#accordionExample1">
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark serviceul">
                              <li  routerLink="/individual-tax-return">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Individual Tax Return
                              </li>
                              <li  routerLink="/corporate-tax-return">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Corporate Tax Return
                              </li>
                              <li routerLink="/sales-and-use-tax">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Sales & Use Tax
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- card -->
                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                      <div class="card-header" id="headingNine">
                        <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                          aria-expanded="false" aria-controls="collapseNine">
                          <span (click)="onPayroll()">Payroll Advisory</span>
                        </a>
                      </div>
                      <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark pt-4 serviceul">
                              <li routerLink="/payroll-processing">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Payroll Processing
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->

                    <!-- card -->
                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                      <div class="card-header" id="headingTen">
                        <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                          aria-expanded="false" aria-controls="collapseTen">
                          <span (click)="onConsulting()">Consulting & Advisory</span>
                        </a>
                      </div>
                      <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark pt-4 serviceul">
                              <li routerLink="/business-setup">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Setting up an entity in the US
                              </li>
                              <li routerLink="/global-corporate-structuring">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Global Corporate Structuring
                              </li>
                              <li routerLink="/business-plan-preparation">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Business Plan Preparation
                              </li>
                              <li routerLink="/business-valuation-service">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               <a href="#business-valuation-service"> Business Valuation</a>
                              </li>
                              <li routerLink="/mergers-and-acquisitions">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Mergers and Acquisitions
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->
                  </div>
                    </div>
                  </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3 id="fixed-assets-accounting">Fixed Assets Accounting</h3>
                    <!--<p class="mb-15 mt-20 text-dark servicep">
                      Optimize Your Fixed Assets Accounting with BFAG
                    </p>-->
                    <p class="mb-15 mt-20 text-dark servicep">
                      Managing fixed assets is a crucial aspect of financial management for businesses across industries. Accurate tracking, valuation, and reporting of fixed assets are essential for regulatory compliance, financial planning, and informed decision-making. However, the complexities associated with fixed assets accounting can be overwhelming, especially for organizations lacking dedicated expertise or resources. At BFAG, we offer specialized fixed assets accounting services to help you streamline your accounting processes and maximize the value of your fixed assets.
                    </p>
                    <p class="mb-15 mt-20 text-dark servicep">
                      Our Fixed Assets Accounting Services include:
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                  Comprehensive Fixed Assets Register
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Our experienced professionals assist in creating and maintaining a comprehensive fixed assets register, ensuring accurate and up-to-date records of your assets. We collaborate with you to establish appropriate asset categories, record acquisition details, track depreciation, and monitor disposals or transfers. By maintaining a detailed fixed assets register, you gain a clear overview of your asset base, facilitating efficient asset management and decision-making.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                            <div class="card-header" id="headingTwelve">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                  Accurate Asset Valuation and Depreciation
                                                </a>
                                            </div>
                                            <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Determining the value and depreciation of fixed assets requires in-depth knowledge of accounting standards and methodologies. Our team ensures accurate asset valuation by employing industry-approved techniques such as straight-line, declining balance, or units of production. We also provide guidance on the appropriate useful life and residual value estimates, helping you adhere to regulatory requirements while maximizing the value of your assets.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingThirteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                  Regulatory Compliance and Reporting
                                                </a>
                                            </div>
                                            <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Fixed assets accounting involves compliance with specific accounting standards and reporting requirements. Our financial advisory services ensure adherence to relevant regulations, such as Generally Accepted Accounting Principles (GAAP) or International Financial Reporting Standards (IFRS). We assist in generating accurate and compliant financial statements, including balance sheets, income statements, and cash flow statements, to meet regulatory obligations and facilitate effective financial analysis.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- card -->
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingFourteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                  Efficient Capital Expenditure Planning
                                                </a>
                                            </div>
                                            <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Effective capital expenditure planning is critical for optimizing resource allocation and long-term financial stability. Our fixed assets accounting services provide insights into the performance and utilization of your assets. By analyzing depreciation trends, identifying maintenance or replacement needs, and conducting cost-benefit analyses, we help you make informed decisions regarding capital expenditures. This enables you to allocate resources strategically and ensure the efficient use of your assets.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                            <div class="card-header" id="headingFifteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                  Improved Asset Tracking and Management
                                                </a>
                                            </div>
                                            <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Maintaining accurate records and tracking the location, condition, and maintenance history of fixed assets can be challenging. Our services include implementing robust asset tracking systems and processes, utilizing barcode or RFID technologies, and integrating asset management software. This enhances asset visibility, facilitates efficient maintenance scheduling, and minimizes the risk of loss or theft.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                            <div class="card-header" id="headingSixteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                  Cost Savings and Risk Mitigation
                                                </a>
                                            </div>
                                            <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Effective fixed assets accounting can result in cost savings and risk mitigation for your organization. By accurately tracking depreciation and maintenance costs, you can identify underutilized assets, optimize maintenance schedules, and make informed decisions regarding disposals or replacements. Additionally, our services help mitigate the risk of non-compliance, penalties, or financial inaccuracies associated with fixed assets accounting.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->

                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                  Efficient fixed assets accounting is essential for maintaining financial control, regulatory compliance, and effective decision-making. BFAG offers comprehensive fixed assets accounting solutions tailored to your business needs. By leveraging our expertise, advanced tracking systems, and adherence to accounting standards, you can optimize your fixed assets accounting processes, enhance financial control, and achieve cost savings. Contact us today to experience the benefits of our professional fixed assets accounting services and take your business's financial management to the next level.
                </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>

<!-- India started -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/Accounting.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Accounting & Transaction Processing
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                  <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev">
                      <a class="" href="" data-toggle="collapse" data-target="#collapseSev"
                        aria-expanded="true" aria-controls="collapseSev">
                        <span routerLink="/accounting-and-transition-processing">Accounting & Transaction Processing</span>
                      </a>
                    </div>
                    <div id="collapseSev" class="collapse show" aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#accounts-receivable"> Accounts Receivable</a>
                            </li>
                            <li class="shli" routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#fixed-assets-accounting"> Fixed Assets Accounting</a>
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              <a href="#budgeting-and-variance-analysis">Budgeting & Variance Analysis</a>
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Consolidation Reporting & GAAP Conversion
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li routerLink="/income-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Income Tax
                            </li>
                            <li  routerLink="/goods-and-service-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Goods & Service Tax
                            </li>
                            <li routerLink="/corporate-secretarial-compliance">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Secretarial Compliance
                            </li>
                            <li  routerLink="/fema-advisory">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              FEMA Compliance
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                 <!-- card -->
                 <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                  <div class="card-header" id="headingNine">
                    <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                      aria-expanded="false" aria-controls="collapseNine">
                      <span (click)="onPayroll()">Payroll Advisory</span>
                    </a>
                  </div>
                  <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                    <div class="card-body bg-white">
                      <div>
                        <ul class="text-dark pt-4 serviceul">
                          <li routerLink="/payroll-processing">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Payroll - Compensation & Benefit Management
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card -->

                <!-- card -->
                <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                  <div class="card-header" id="headingTen">
                    <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                      aria-expanded="false" aria-controls="collapseTen">
                      <span (click)="onConsulting()">Consulting & Advisory</span>
                    </a>
                  </div>
                  <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                    <div class="card-body bg-white">
                      <div>
                        <ul class="text-dark pt-4 serviceul">
                          <li routerLink="/business-setup">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Setting up an entity in the India
                          </li>
                          <li routerLink="/global-corporate-structuring">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Global Corporate Structuring
                          </li>
                          <li routerLink="/business-plan-preparation">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Business Plan Preparation
                          </li>
                          <li routerLink="/business-valuation-service">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Business Valuation
                          </li>
                          <li routerLink="/mergers-and-acquisitions">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Mergers and Acquisitions
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card -->
                <!-- card -->
                <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                  <div class="card-header" id="headingElevenStartup">
                    <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseElevenStartup"
                      aria-expanded="false" aria-controls="collapseElevenStartup">
                      <span (click)="onStartup()">Startup Solution</span>
                    </a>
                  </div>
                  <div id="collapseElevenStartup" class="collapse" aria-labelledby="headingElevenStartup" data-parent="#accordionExample1">
                    <div class="card-body bg-white">
                      <div>
                        <ul class="text-dark pt-4 serviceul">
                          <li routerLink="/startup-solution">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Startup Solution
                          </li>
                          <li routerLink="/fund-raising">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Fund Raise
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card -->
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3 id="fixed-assets-accounting">Fixed Assets Accounting</h3>
                    <!--<p class="mb-15 mt-20 text-dark servicep">
                      Optimize Your Fixed Assets Accounting with BFAG
                    </p>-->
                    <p class="mb-15 mt-20 text-dark servicep">
                      Managing fixed assets is a crucial aspect of financial management for businesses across industries. Accurate tracking, valuation, and reporting of fixed assets are essential for regulatory compliance, financial planning, and informed decision-making. However, the complexities associated with fixed assets accounting can be overwhelming, especially for organizations lacking dedicated expertise or resources. At BFAG, we offer specialized fixed assets accounting services to help you streamline your accounting processes and maximize the value of your fixed assets.
                    </p>
                    <p class="mb-15 mt-20 text-dark servicep">
                      Our Fixed Assets Accounting Services include:
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                  Comprehensive Fixed Assets Register
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Our experienced professionals assist in creating and maintaining a comprehensive fixed assets register, ensuring accurate and up-to-date records of your assets. We collaborate with you to establish appropriate asset categories, record acquisition details, track depreciation, and monitor disposals or transfers. By maintaining a detailed fixed assets register, you gain a clear overview of your asset base, facilitating efficient asset management and decision-making.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                            <div class="card-header" id="headingTwelve">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                  Accurate Asset Valuation and Depreciation
                                                </a>
                                            </div>
                                            <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Determining the value and depreciation of fixed assets requires in-depth knowledge of accounting standards and methodologies. Our team ensures accurate asset valuation by employing industry-approved techniques such as straight-line, declining balance, or units of production. We also provide guidance on the appropriate useful life and residual value estimates, helping you adhere to regulatory requirements while maximizing the value of your assets.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingThirteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                  Regulatory Compliance and Reporting
                                                </a>
                                            </div>
                                            <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Fixed assets accounting involves compliance with specific accounting standards and reporting requirements. Our financial advisory services ensure adherence to relevant regulations, such as Generally Accepted Accounting Principles (GAAP) or International Financial Reporting Standards (IFRS). We assist in generating accurate and compliant financial statements, including balance sheets, income statements, and cash flow statements, to meet regulatory obligations and facilitate effective financial analysis.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- card -->
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingFourteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                  Efficient Capital Expenditure Planning
                                                </a>
                                            </div>
                                            <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Effective capital expenditure planning is critical for optimizing resource allocation and long-term financial stability. Our fixed assets accounting services provide insights into the performance and utilization of your assets. By analyzing depreciation trends, identifying maintenance or replacement needs, and conducting cost-benefit analyses, we help you make informed decisions regarding capital expenditures. This enables you to allocate resources strategically and ensure the efficient use of your assets.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                            <div class="card-header" id="headingFifteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                  Improved Asset Tracking and Management
                                                </a>
                                            </div>
                                            <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Maintaining accurate records and tracking the location, condition, and maintenance history of fixed assets can be challenging. Our services include implementing robust asset tracking systems and processes, utilizing barcode or RFID technologies, and integrating asset management software. This enhances asset visibility, facilitates efficient maintenance scheduling, and minimizes the risk of loss or theft.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                            <div class="card-header" id="headingSixteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                  Cost Savings and Risk Mitigation
                                                </a>
                                            </div>
                                            <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Effective fixed assets accounting can result in cost savings and risk mitigation for your organization. By accurately tracking depreciation and maintenance costs, you can identify underutilized assets, optimize maintenance schedules, and make informed decisions regarding disposals or replacements. Additionally, our services help mitigate the risk of non-compliance, penalties, or financial inaccuracies associated with fixed assets accounting.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->

                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                  Efficient fixed assets accounting is essential for maintaining financial control, regulatory compliance, and effective decision-making. BFAG offers comprehensive fixed assets accounting solutions tailored to your business needs. By leveraging our expertise, advanced tracking systems, and adherence to accounting standards, you can optimize your fixed assets accounting processes, enhance financial control, and achieve cost savings. Contact us today to experience the benefits of our professional fixed assets accounting services and take your business's financial management to the next level.
                </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>
