import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CaseStudiesService {

  constructor(private http: HttpClient) { }

  caseStudies(){
    return this.http.get(`${environment.baseUrl}view_casestudies_list`);
  }

  caseStudiesDetails(data){
    return this.http.post(`${environment.baseUrl}view_casestudies_details`, data);
  }

  detailsCasestudyList(data){
    return this.http.post(`${environment.baseUrl}view_details_casestudy_list`, data);
  }

}
