
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area horizon-implementation customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Horizon Implementation</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Horizon Implementation</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->

<!--====== Implementation PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Implementation Services</span>
                    <h2 class="title pb-15">Implementation</h2>
                    <p>Horizon has been developed exclusively, to coherently meet all the needs of businesses. It provides a robust, user-friendly interface, which enables a reliable decision-making process across the entire organization. It provides support
                        to meet the current needs of the organization with the future in mind.</p>
                    <p>Frontline’s agile implementation methodologies have ensured the success and growth of business across several industrial sectors. With a team of experts and with cutting edge technology, Frontline has successfully implemented ERP solutions
                        in over 350 projects.</p>
                    <p>Horizon’s specially designed modules will help companies boost their common business functionalities such as finance, administration & HR, procurement & inventory, sales, operations management, project management and all other key
                        business verticals.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Implementation PART END ======-->

<!--====== ERP Consultancy accordion PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-12">
                <div class="experience-item">
                    <span>Highlights</span>
                    <h2 class="title pb-15">Key Features</h2>
                </div>
            </div> -->
            <div class="col-md-12">
                <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a class="w-100" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Data Migration
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">Complications associated with transferring data from a legacy system to an ERP system are enormous. Frontline’s team of migration experts not only provide accelerated and efficient data migration service, but also explain
                                        the need and benefits of data migration. Frontline’s aim is to help organizations enjoy the full benefits of the improved functionality in meeting day to day business needs, through migration from legacy to ERP
                                        system. Organizations can leverage the best migration solutions, derived from systematic analysis of the data. Frontline provides scientific migration and data integration services, to help organizations keep up
                                        with the growing technology and scale up to a wider customer base.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Project Management
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">Frontline’s experience and project management methodologies will help your organization in the successful completion of a project. A combination of unique project management modules, Horizon experts and experience with
                                        a wide range of projects from various sectors, ensures the delivery of high quality results for your organization within stipulated time. Frontline’s project management service takes care of optimizing the entire
                                        process right from project conception to commissioning, ensuring high productivity and profitability for your organization. Our expert team, composed of a rich talent pool, will ensure the successful completion
                                        of the project at a high standard as expected by your organization. Our experienced experts’ focus is to extend proper communication with all the project stakeholders and ensure the smooth completion of the project
                                        within the expected time and budget framework.</p>

                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Extended Onsite Support
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">Even with the best ERP software system in place, organization requires onsite support for smoothening the post implementation processes. Frontline makes your organization the cynosure of all its attention, in providing
                                        able support for your organization’s post implementation. Frontline’s personalized service and premium access to accomplished experts enables us in providing unparalleled support for all your post implementation
                                        requirements. Frontline offers a technical or functional consultant available to your organization, either on a short or long term basis for smoothening the process post implementation.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== ERP Consultancy accordion PART END ======-->
