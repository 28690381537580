import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DsarService } from 'src/app/services/dsar-page/dsar.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.css']
})
export class ContactModalComponent implements OnInit {
  inquiryType = ["Scheduling Demo", "Product Enquiry", "Others"];
  resultTrue = false;
  resultFalse = false;
  defaultCountry: string;
  selectCountryName: string;
  countryCode: string;

  constructor(
    private _dsar: DsarService,
  ) { }

  ngOnInit(): void {
    this.defaultCountry = 'USA';
    this.selectCountryName = localStorage.getItem('selectedCountry') || this.defaultCountry;
    this.countryCode = this.getCountryCode(this.selectCountryName);
  }

  onSubmit(contactForm: NgForm) {
    if (contactForm.invalid) {
      return;
    }
    this._dsar.addContact(contactForm.value).subscribe({
      next: (data: any) => {
        Swal.fire({
          icon: "success",
          title: "Thank you",
          text: "Your request has been saved successfully",
        }).then((result) => {
          if (result.isConfirmed) {
            contactForm.reset();
            this.resultTrue = true;
            setTimeout(() => {
              this.resultTrue = false;
              window.location.reload();
            }, 1000);
          }
        });
      },
      error: error => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    });
  }

  getCountryCode(country: string): string {
    switch (country) {
      case 'Dubai':
        return '+971';
      case 'India':
        return '+91';
      case 'USA':
        return '+1';
      default:
        return '+91';
    }
  }
}






// export class ContactModalComponent implements OnInit {
//   inquiryType = ["Scheduling Demo", "Product Enquiry", "Others"]
//   contact: FormGroup;
//   subjectData;
//   resultFalse = false;
//   resultTrue = false;
//   select = false;
//   submitted = false;
//   validCaptcha = false;
//   defaultCountry: string;
//   selectCountryName: string;
//   selectedFlag: string;
//   countryCode: any;
//   constructor( private metaInfo:metaData,
//      private _contactFormService:ContactFormService,
//      private formBuilder: FormBuilder,
//      public dialogRef: MatDialogRef<ContactModalComponent>,
//      private _customCaptcha:CustomCaptcha,
//      private LocalStorageServiceService: LocalStorageServiceService,
//      ) {
//       this.contact = this.formBuilder.group({
//         name: ['', Validators.required],
//         email_id: ['', [Validators.required, Validators.email]],
//         mobile_number: ['', [Validators.required, Validators.pattern('\\d*')]],
//         subject: [''],
//         message: ['', Validators.required],
//         recaptchaReactive: ['', Validators.required]
//       });
//       }



//       ngOnInit(): void {
//         this.defaultCountry = 'USA';
//         this.selectCountryName = localStorage.getItem('selectedCountry') || this.defaultCountry;
//         this.countryCode = this.getCountryCode(this.selectCountryName);
//       }

//   // selectCountry(country: string): void {
//   //   // Update the selectedFlag with the corresponding image path
//   //   if (country === 'Dubai') {
//   //     this.selectedFlag =
//   //       '/assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png';
//   //     this.countryCode = '+971';
//   //   } else if (country === 'India') {
//   //     this.selectedFlag = '/assets/images/flag/download.png';
//   //     this.countryCode = '+91';
//   //   } else if (country === 'USA') {
//   //     this.selectedFlag = '/assets/images/flag/USA.png';
//   //     this.countryCode = '+1';
//   //   } else {
//   //     // Set USA as the default country
//   //     this.selectedFlag = '/assets/images/flag/USA.png';
//   //     country = 'USA';
//   //     this.countryCode = '+91';
//   //   }
//   //   console.log('THIS IS COUNTRY CODE');
//   //   console.log(this.countryCode);
//   //   this.LocalStorageServiceService.setItem('selectedCountry', country);
//   // }

//   reCaptcha(){
//     this._customCaptcha.createCaptchaModal();
//   }


//   // contactForm(){
//   //   this.contact = this.formBuilder.group({
//   //     name: ['',  Validators.required],
//   //     email_id: ['',  Validators.compose([
//   //       Validators.required,
//   //       Validators.email
//   //     ])],
//   //     mobile_number: [`${this.countryCode}`, Validators.compose([Validators.required,
//   //     Validators.pattern('^[(+0-9) 0-9 -]+$')])],
//   //     subject: ['',],
//   //     message: ['', Validators.required],
//   //     recaptchaReactive: ['', Validators.required]
//   //   })
//   // }

//   // get f() { return this.contact.controls; }

//   onSubmit(contactForm: NgForm) {
//     if (contactForm.invalid) {
//       return;
//     }
//     this._dsar.addContact(contactForm.value).subscribe({
//       next: (data: any) => {
//         Swal.fire({
//           icon: "success",
//           title: "Thank you",
//           text: "Your request has been saved successfully",
//         }).then((result) => {
//           if (result.isConfirmed) {
//             contactForm.reset();
//             this.resultTrue = true;
//             setTimeout(() => {
//               this.resultTrue = false;
//               window.location.reload(); 
//             }, 1000);
//           }
//         });
//       },
//       error: error => {
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "Something went wrong!",
//         });
//       }
//     });
//   }
//   getCountryCode(country: string): string {
//     switch (country) {
//       case 'Dubai':
//         return '+971';
//       case 'India':
//         return '+91';
//       case 'USA':
//         return '+1';
//       default:
//         return '+91';
//     }
//   }
// }
  

  // captchaResolved(captchaResponse: string) {
  //   if (!captchaResponse) {
  //     this.validCaptcha = false;
  //   } else {
  //     this.validCaptcha = true;
  //   }
  //   this.contact.patchValue({
  //     recaptchaReactive : this.validCaptcha
  //   });
  // }

  // trackByFn(index, item) {
  //   return index; // or item.id
  // }
  // onNoClick(): void {
  //   this.dialogRef.close();
  // }
// }
