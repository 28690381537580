<style>
    .new{
        margin-top: 20px;
    }
 .about-middle p {
  list-style-type: disc;
  margin: 0;
  padding-left: 20px;
}
.newMargin{
    margin-left: 142px;
}
.color{
    color:#343a40;
}
.newMargintwo{
    margin-left: 15px;
}
</style>
<div class="col p-0 m-0 ">
    <div class="d-flex justify-content-center align-items-center banner-image bmTop" style="
  background: url('../../../../assets/images/service/isotwo.jpeg') no-repeat center;
  background-size: cover;
  height: 430px;
  margin-top: 138px;
">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <!--<h4 routerLink="/" class="cursor-pointer pt-2 shade" style="color: #69a1bb">Home | Services</h4>-->
        </div>
    </div>

<h3 class="text-center new color">Information Security Management System</h3>
<p class="text-center color">Boston Financial Advisory Group is ISO 27001:2022 certified company. Where we maintain CIA Triad (Confidentiality, Integrity and Availability).</p>
<p class="text-center color">We maintain a clear framework of Information Security Management Process and key operational elements such as</p>
<!-- Choose Area -->


<div class="choose-area-two newMargin choose-bg pt-40" >
    <div class="container">
        <div class="row my-list">
            <div class="col-lg-12 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <div class="col-md-4 about-middle">
                        
                            <li class ='color'><i class='bx bx-check color'></i> Keeping IT System up to date</li>

                        </div>
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check color'></i> Information Security Policies</li>
                        </div>
                        <div class="col-md-4 about-middle">
                            <li class='color'><i class='bx bx-check color'></i> Background Verification of Employees</li>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-list">
            <div class="col-lg-12 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> Anti-Virus Protection</li>
                        </div>
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> Data Storage & Backups</li>
                        </div>
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> IT Change Management</li>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-list">
            <div class="col-lg-12 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> Regular Information Security Training</li>
                        </div>
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> Regular Internal Audits</li>
                        </div>
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> Assets Management</li>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-list">
            <div class="col-lg-12 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> Information Classification</li>
                        </div>
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> Access Control & Access Rights</li>
                        </div>
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> Physical & Environmental Security</li>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-list">
            <div class="col-lg-12 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> Information Security Risk Assessment &nbsp; &nbsp; &nbsp; &nbsp; &  Risk Treatment</li>
                        </div>
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> Protection of PII (Personally Identifiable &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information)</li>
                        </div>
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> Network Security</li>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-list">
            <div class="col-lg-12 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> Regular VAPT (Vulnerability &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Assessment  and Penetration Testing)</li>
                        </div>
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> Event Logging</li>
                        </div>
                        <div class="col-md-4 about-middle">
                            <li class = 'color'><i class='bx bx-check'></i> Dedicated CISO (Chief Information &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Security Officer)</li>
                        </div>
                        <div class="clearfix"></div>
                        <p>&nbsp;</p>
                        <p class="color newMargintwo" style="font-weight: bold;">
                            <strong>ISO 27001 certificates may be requested via by writing to <a href="mailto:privacy@bostonfagroup.com"> privacy@bostonfagroup.com</a>.</strong>
                          </p>
                          
                                <br/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>