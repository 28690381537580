
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">PMV Management</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">PMV Management</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== (PMV) Management PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>PMV Solutions</span>
                    <h2 class="title pb-15">Plant, Machinery and Vehicle<br/> (PMV) Management</h2>
                    <p>Plant, Machinery and Vehicle (PMV) Management module provides an integrated solution for supporting the operational needs of an enterprise-wide system. The module includes an entire family of products covering all aspects of plant,
                        machinery and vehicle maintenance and becomes integral to the achievement of process improvement.</p>
                    <p>The PMV module provides all the essential tools to implement a complete maintenance program. PMV is designed to help protect your tools, machines, gages, devices and other equipment by automatically tracking usage and generating maintenance
                        work orders. The maintenance labor scheduling capability allows for increased efficiency and reduced labor costs. Customizable tasks and templates make upkeep thorough, accurate and easy to maintain.</p>
                    <p>It is designed cohesively within the Horizon. It links to Scheduling, Project Manager, Purchasing, Inventory, HRMS and the financial system (AR/AP and Fixed Assets). Maintenance tasks are assigned to the equipment along with a time
                        or usage interval, associated worker skill levels and material inventory requirements — all tailored to your environment. Equipment usage is captured automatically and calculates when maintenance is required or can be created manually
                        for emergency or other needs. Best of all, time, inventory and other related costs offer a complete maintenance history.</p>

                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== (PMV) Management PART END ======-->

<!--====== Key Features PART START ======-->
<div class="about-faq-area about-faq-area-custom horizon-hrms dec-spacing bg_cover gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Highlights</span>
                    <h2 class="title">Key Features</h2>
                </div>
            </div>
            <div class="faq-accordion-3">
                <div class="accordion" id="accordionExample">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Record Equipment Information
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Maintenance items can be machines, tools, inserts, dies, auxiliary equipment, gages, calipers, devices or any other item or activity that needs regular maintenance or service. Maintenance intervals can be tracked by
                                        days, hours or cycles.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Track PMV Task Details, Skills and Attached Inventory
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Each task contains unique information as it pertains to the equipment, including skills and spare parts required, time to complete, recommended maintenance intervals, drawings, schematics and other information.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Generate Work Orders
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Work orders can be generated based on machine, tool or equipment usage. Work order history is maintained to provide a complete maintenance audit trail.</p>
                                </div>
                            </div>
                        </div>

                        <!-- card -->
                    </div>

                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSev">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSev" aria-expanded="false" aria-controls="collapseSev">
                                    <i class="fal fa-magic"></i>Labor Scheduling
                                </a>
                            </div>
                            <div id="collapseSev" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Labor can be scheduled and planned by labor type based on PMV Schedule and labor capacity.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEight">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    <i class="fal fa-magic"></i>Track PMV Costs
                                </a>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Activity Summary provides a complete picture of cost activity against equipment, including purchase orders, AP/AR, labor consumed and materials used. Single click drill-down to the details is available.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingNine">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    <i class="fal fa-magic"></i>Where Used Capability
                                </a>
                            </div>
                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Shows all bills of material and inventory items manufactured from machines, tools and auxiliary equipment to quickly identify which products may be affected by maintenance activities.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->

                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== Key Features PART END ======-->

<!--====== Benefits at a glance PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                </div>
                <p class="add-arrow"> Covers all maintenance tasks such as Inspection, Servicing and Repair activities</p>
                <p class="add-arrow"> Reduce equipment downtime through regular maintenance</p>
                <p class="add-arrow"> Reduce labor costs and increase efficiency by scheduling PMV labor</p>
                <p class="add-arrow"> Enable users to recognize possible weak points within technical system in good time</p>
                <p class="add-arrow"> Lower expenditures by reducing last minute and emergency repairs</p>
                <p class="add-arrow"> Easily create a complete audit trail for maintenance</p>
                <p class="add-arrow"> Streamline reporting of labor, spare parts usage and costs</p>
                <p class="add-arrow"> Reduce administrative tasks by automatically generating and printing work orders</p>
                <p class="add-arrow"> Accurately provide up-to-date financial information on equipment</p>
                <p class="add-arrow"> Provides Integrated Business Reports that help in reducing the duration and cost of downtime as a result of damage.</p>
            </div>
            <!-- experience item -->
        </div>
    </div>
    <!-- container -->
</div>
<!--====== Benefits at a glance PART END ======-->

