import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pmv-management',
  templateUrl: './pmv-management.component.html',
  styleUrls: ['./pmv-management.component.css']
})
export class PmvManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
