<!-- usa started -->
<div
  *ngIf="selectCountryName == 'USA'"
  class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing min-vh-100 mt-130"
>
<div class="col p-0 m-0 ">
    <div class="d-flex justify-content-center align-items-center banner-image bmTop" style="
  background: url('assets/images/NewIcon/ccpacom.png') no-repeat center;
  background-size: cover;
  height: 430px;
">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <!-- <h3 class="text-white">CCPA Compliant</h3> -->
            <!--<h4 routerLink="/" class="cursor-pointer pt-2 shade" style="color: #69a1bb">Home | Services</h4>-->
        </div>
    </div>
</div>
<div style="overflow: hidden; position: relative;">
    <img src="../../../../../assets/images/products/products-abstract.png" alt="shape"
        style="position: absolute; overflow: clip;left: -3%; top: 0%; " />

    <div class="mt-50 mb-50 container d-flex justify-content-start align-items-start">
        <div class="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">

            <img src="assets/images/NewIcon/law.png" class="img-fluid" style="height: 17rem;" />

        </div>
        <div class="col-lg-8 col-md-12">
            <p class="text-dark text-justify mb-30" style="line-height: 2.5rem; font-weight: 500">
                Under the California Consumer Privacy Act of 2018 (“CCPA”), California residents have the right to
                opt-out of the “sale” of their personal information. We do not sell your personal information. While we
                do not receive financial compensation for your personal information, we may share it with third parties
                to personalize your experience on our site, improve our site and services, and so that we can engage you
                with interest-based advertising on other websites. To exercise this right, please see the options
                detailed below. Please visit our Website Privacy and Cookie Policy for further details about our privacy
                practices.
            </p>
            <h6>Submit your request via email or fill out a request form</h6>
            <div class="faq-accordion-3 pt-0">
            <div class="accordion" id="accordionExample">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <a class="w-100" href="" data-toggle="collapse" data-target="#collapseOne"
                        aria-expanded="true" aria-controls="collapseOne">
                        Email request. 
                    </a>
                </div>
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body">
                        <p class="pb-10">
                            You may submit your request to opt out of the sale of your personal information described above by
                            contacting us at <a href="mailto:privacy@bostonfagroup.com">privacy@bostonfagroup.com</a>.
                        </p>
                    </div>
                </div>
            </div>
              <!-- card -->
              <div class="card">
                <div class="card-header" id="headingThree">
                    <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseThree"
                        aria-expanded="false" aria-controls="collapseThree">
                      Request form.
                    </a>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div class="card-body" style="padding-bottom: 1rem !important;">
                        <p class="pb-5">
                            You may submit a request via DSAR (<a routerLink="/dsar-page">Data Subject Access Request</a>)
                            
                        </p>
                    </div>
                </div>
            </div>
            <!-- card -->
            </div>
            </div>
            <p class="text-dark text-justify mb-30" style="line-height: 2.5rem; font-weight: 500">
               <strong> Note: </strong>Please note that we may need to request certain information from you to verify your identity or otherwise process your request. If you are making this request on 
            </p>
            <!-- <div class="navbar-btn contactUs-btn cursor-pointer" routerLink="/under-development">
                <a class="text-white btn d-sm-mobile rounded contactBtn" style="background: #418052;"
                    ng-reflect-router-link-active="active">Learn More</a>
            </div> -->
        </div>
    </div>

    <!-- end -->


    
</div>
</div>
<!-- usa ends -->

<!-- INdia Started -->
<div
  *ngIf="selectCountryName == 'India'"
  class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing min-vh-100 mt-130"
>
<div class="col p-0 m-0 ">
    <div class="d-flex justify-content-center align-items-center banner-image bmTop" style="
  background: url('assets/images/NewIcon/ccpacom.png') no-repeat center;
  background-size: cover;
  height: 430px;
">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <!-- <h3 class="text-white">CCPA Complaint</h3> -->
            <!--<h4 routerLink="/" class="cursor-pointer pt-2 shade" style="color: #69a1bb">Home | Services</h4>-->
        </div>
    </div>
</div>
<div style="overflow: hidden; position: relative;">
    <img src="../../../../../assets/images/products/products-abstract.png" alt="shape"
        style="position: absolute; overflow: clip;left: -3%; top: 0%; " />

    <div class="mt-50 mb-50 container d-flex justify-content-start align-items-start">
        <div class="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">

            <img src="assets/images/NewIcon/law.png" class="img-fluid" style="height: 17rem;" />

        </div>
        <div class="col-lg-8 col-md-12">
            <p class="text-dark text-justify mb-30" style="line-height: 2.5rem; font-weight: 500">
                Under the California Consumer Privacy Act of 2018 (“CCPA”), California residents have the right to
                opt-out of the “sale” of their personal information. We do not sell your personal information. While we
                do not receive financial compensation for your personal information, we may share it with third parties
                to personalize your experience on our site, improve our site and services, and so that we can engage you
                with interest-based advertising on other websites. To exercise this right, please see the options
                detailed below. Please visit our Website Privacy and Cookie Policy for further details about our privacy
                practices.
            </p>
            <h6>Submit your request via email or fill out a request form</h6>
            <br/>
            <div class="faq-accordion-3 pt-0">
            <div class="accordion" id="accordionExample">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <a class="w-100" href="" data-toggle="collapse" data-target="#collapseOne"
                        aria-expanded="true" aria-controls="collapseOne">
                        Email request. 
                    </a>
                </div>
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body">
                        <p class="pb-10">
                            You may submit your request to opt out of the sale of your personal information described above by
                            contacting us at <a href="mailto:privacy@bostonfagroup.com">privacy@bostonfagroup.com</a>.
                        </p>
                    </div>
                </div>
                
            </div>
              <!-- card -->
              <div class="card">
                <div class="card-header" id="headingThree">
                    <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseThree"
                        aria-expanded="false" aria-controls="collapseThree">
                      Request form.
                    </a>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div class="card-body" style="padding-bottom: 1rem !important;">
                        <p class="pb-5">
                            You may submit a request via DSAR (<a routerLink="/dsar-page">Data Subject Access Request</a>)
                        </p>
                    </div>
                </div>
                
            </div>
            <!-- card -->
            </div>
            </div>
            <p class="text-dark text-justify mb-30" style="line-height: 2.5rem; font-weight: 500">
               <strong> Note: </strong>Please note that we may need to request certain information from you to verify your identity or otherwise process your request. If you are making this request on 
            </p>
            <!-- <div class="navbar-btn contactUs-btn cursor-pointer" routerLink="/under-development">
                <a class="text-white btn d-sm-mobile rounded contactBtn" style="background: #418052;"
                    ng-reflect-router-link-active="active">Learn More</a>
            </div> -->
        </div>
    </div>

    <!-- end -->


    
</div>



</div>
<!-- india ends -->

<!-- Dubai Started -->
<div
  *ngIf="selectCountryName == 'Dubai'"
  class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing min-vh-100 mt-130"
>
<div class="col p-0 m-0 ">
    <div class="d-flex justify-content-center align-items-center banner-image bmTop" style="
  background: url('../../../../assets/images/service/download.jpg') no-repeat center;
  background-size: cover;
  height: 430px;
">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <h3 class="text-white">CCPA Complaint</h3>
            <!--<h4 routerLink="/" class="cursor-pointer pt-2 shade" style="color: #69a1bb">Home | Services</h4>-->
        </div>
    </div>
</div>
<div style="overflow: hidden; position: relative;">
    <img src="../../../../../assets/images/products/products-abstract.png" alt="shape"
        style="position: absolute; overflow: clip;left: -3%; top: 0%; " />

    <div class="mt-50 mb-50 container d-flex justify-content-start align-items-start">
        <div class="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">

            <img src="assets/images/NewIcon/law.png" class="img-fluid" style="height: 17rem;" />

        </div>
        <div class="col-lg-8 col-md-12">
            <p class="text-dark text-justify mb-30" style="line-height: 2.5rem; font-weight: 500">
                Under the California Consumer Privacy Act of 2018 (“CCPA”), California residents have the right to
                opt-out of the “sale” of their personal information. We do not sell your personal information. While we
                do not receive financial compensation for your personal information, we may share it with third parties
                to personalize your experience on our site, improve our site and services, and so that we can engage you
                with interest-based advertising on other websites. To exercise this right, please see the options
                detailed below. Please visit our Website Privacy and Cookie Policy for further details about our privacy
                practices.
            </p>
            <h6>Submit your request via email or fill out a request form</h6>
            <br/>
            <div class="faq-accordion-3 pt-0">
            <div class="accordion" id="accordionExample">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <a class="w-100" href="" data-toggle="collapse" data-target="#collapseOne"
                        aria-expanded="true" aria-controls="collapseOne">
                        Email request. 
                    </a>
                </div>
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body">
                        <p class="pb-10">
                            You may submit your request to opt out of the sale of your personal information described above by
                            contacting us at <a href="mailto:privacy@bostonfagroup.com">privacy@bostonfagroup.com</a>.
                        </p>
                    </div>
                </div>
                
            </div>
              <!-- card -->
              <div class="card">
                <div class="card-header" id="headingThree">
                    <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseThree"
                        aria-expanded="false" aria-controls="collapseThree">
                      Request form.
                    </a>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div class="card-body" style="padding-bottom: 1rem !important;">
                        <p class="pb-5">
                            You may submit a request via DSAR (<a routerLink="/dsar-page">Data Subject Access Request</a>)
                        </p>
                    </div>
                </div>
            </div>
            <!-- card -->
            </div>
            </div>
            <p class="text-dark text-justify mb-30" style="line-height: 2.5rem; font-weight: 500">
               <strong> Note: </strong>Please note that we may need to request certain information from you to verify your identity or otherwise process your request. If you are making this request on 
            </p>
            <!-- <div class="navbar-btn contactUs-btn cursor-pointer" routerLink="/under-development">
                <a class="text-white btn d-sm-mobile rounded contactBtn" style="background: #418052;"
                    ng-reflect-router-link-active="active">Learn More</a>
            </div> -->
        </div>
    </div>

    <!-- end -->


    
</div>
</div>
<!-- Dubai ends -->
