<!-- dubai start -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 pb-100 mTop">
  <div
    class="col-lg-12 d-flex flex-column justify-content-center align-items-center"
    style="
      background: linear-gradient(
        to bottom,
        #418052 0%,
        rgba(50, 217, 81, 0) 100%,
        #ffff 100%
      );
    "
  >
    <div
      class="d-flex flex-column justify-content-center align-items-center"
      style="padding-top: 50px !important; overflow: hidden"
    >
      <h3 class="text-white text-uppercase text-center missionh3 m-auto">
        Our Mission and Vision
      </h3>
      <div style="padding-top: 50px !important; text-align: center">
        <img
          src="../../../../../assets/images/mission and vision/team.JPG"
          style="width: 100%; border-radius: 35px"
        />
      </div>
    </div>
  </div>
  <div
    class="d-flex flex-column justify-content-center align-items-center"
    style="padding-top: 65px !important"
  >
    <!-- <div class="w-55 row mainDiv">
        <div id="myDivi">
          <h3 class="card-title text-uppercase">Our Mission and Vision</h3>
        </div>
      </div> -->
    <!-- <p class="text-dark w-50 text-center" style="line-height: text-dark normal; font-size: 16px;">
        To be a leading outsourcing and advisory partner for the SME sector
        worldwide that provides analytical information, technology, and domain
        expertise quickly and cost effectively.
      </p> -->
  </div>
  <div class="container-fluid">
    <div
      class="row d-flex justify-content-center align-items-center"
      style="
        padding-top: 40px !important;
        gap: 3rem;
        position: relative;
        overflow: hidden;
      "
    >
      <img
        src="../../../../../assets/images/mission and vision/Vector-left.png"
        alt="shape"
        style="
          position: absolute;
          overflow: clip;
          left: 0;
          bottom: 0;
          width: 12%;
        "
      />
      <img
        src="../../../../../assets/images/mission and vision/Vector-right.png"
        alt="shape"
        style="
          position: absolute;
          overflow: clip;
          right: 0;
          bottom: 0;
          width: 12%;
        "
      />

      <div
        class="card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
        style="
          width: 350px;
          height: 460px;
          border-radius: 15px;
          margin-bottom: 2rem;
        "
      >
        <img
          class="img-fluid pt-10"
          src="../../../../../assets/images/mission and vision/mission.gif"
          alt="Card image cap"
          style="width: 12rem"
        />
        <div
          class="card-body d-flex flex-column justify-content-center align-items-center pt-0 mt-0"
        >
          <span
            class="card-title text-center text-dark"
            style="
              border-bottom: 3px solid #418052;
              font-weight: 700;
              font-size: 21px;
            "
            >OUR MISSION</span
          >
          <p class="text-dark" style="line-height: normal; text-align: justify">
            To be a leading outsourcing and advisory partner for the SME sector
            worldwide that provides analytical information, technology, and
            domain expertise quickly and cost effectively.
          </p>
        </div>
      </div>
      <div
        class="card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
        style="
          width: 350px;
          height: 460px;
          border-radius: 15px;
          margin-bottom: 2rem;
        "
      >
        <img
          class="img-fluid pt-50"
          src="../../../../../assets/images/mission and vision/vision.gif"
          alt="Card image cap"
          style="width: 10rem"
        />
        <div
          class="card-body d-flex flex-column justify-content-center align-items-center"
        >
          <span
            class="card-title text-center text-dark"
            style="
              border-bottom: 3px solid #418052;
              font-weight: 700;
              font-size: 21px;
            "
            >OUR VISION</span
          >
          <p class="text-dark" style="line-height: normal; text-align: justify">
            At the core of our success story lies the unwavering commitment to
            excellence, which permeates every interaction and is fortified by
            our five pillars: advice, outsourcing services, domain knowledge,
            processes, and technology
          </p>
        </div>
      </div>
      <div
        class="card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
        style="
          width: 350px;
          height: 460px;
          border-radius: 15px;
          margin-bottom: 2rem;
        "
      >
        <img
          class="img-fluid pt-10"
          src="../../../../../assets/images/mission and vision/statement.gif"
          alt="Card image cap"
          style="width: 12rem"
        />
        <div
          class="card-body d-flex flex-column justify-content-center align-items-center pt-0 mt-0"
        >
          <span
            class="card-title text-center text-dark text-uppercase"
            style="
              border-bottom: 3px solid #418052;
              font-weight: 700;
              font-size: 21px;
            "
            >Quality Statement</span
          >
          <p class="text-dark" style="line-height: normal; text-align: justify">
            Excel in providing financial outsourcing, consulting and advisory
            services leveraging our expertise and technology- driven process
            leading to customer and partner delight
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- dubai end -->

<!-- India start -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 pb-100 mTop">
  <div
    class="col-lg-12 d-flex flex-column justify-content-center align-items-center"
    style="
      background: linear-gradient(
        to bottom,
        #072f5f 0%,
        rgba(50, 217, 81, 0) 100%,
        #ffff 100%
      );
    "
  >
    <div
      class="d-flex flex-column justify-content-center align-items-center"
      style="padding-top: 50px !important"
    >
      <h3 class="text-white text-uppercase text-center missionh3">
        Our Mission and Vision
      </h3>
      <div style="padding-top: 50px !important; text-align: center">
        <img
          src="../../../../../assets/images/mission and vision/team.JPG"
          style="width: 95%; border-radius: 40px"
          class="imgmission"
        />
      </div>
    </div>
  </div>
  <div
    class="d-flex flex-column justify-content-center align-items-center"
    style="padding-top: 65px !important"
  >
    <!-- <div class="w-55 row mainDiv">
        <div id="myDivi">
          <h3 class="card-title text-uppercase">Our Mission and Vision</h3>
        </div>
      </div> -->
    <!-- <p class="text-dark w-50 text-center" style="line-height: text-dark normal; font-size: 16px;">
        To be a leading outsourcing and advisory partner for the SME sector
        worldwide that provides analytical information, technology, and domain
        expertise quickly and cost effectively.
      </p> -->
  </div>
  <div class="container-fluid">
    <div
      class="row d-flex justify-content-center align-items-center"
      style="
        padding-top: 40px !important;
        gap: 3rem;
        position: relative;
        overflow: hidden;
      "
    >
      <img
        src="../../../../../assets/images/mission and vision/Vector-left.png"
        alt="shape"
        style="
          position: absolute;
          overflow: clip;
          left: 0;
          bottom: 0;
          width: 12%;
        "
      />
      <img
        src="../../../../../assets/images/mission and vision/Vector-right.png"
        alt="shape"
        style="
          position: absolute;
          overflow: clip;
          right: 0;
          bottom: 0;
          width: 12%;
        "
      />

      <div
        class="card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
        style="
          width: 350px;
          height: 460px;
          border-radius: 15px;
          margin-bottom: 2rem;
        "
      >
        <img
          class="img-fluid pt-10"
          src="../../../../../assets/images/mission and vision/missionINd.gif"
          alt="Card image cap"
          style="width: 12rem"
        />
        <div
          class="card-body d-flex flex-column justify-content-center align-items-center pt-0 mt-0"
        >
          <span
            class="card-title text-center text-dark"
            style="
              border-bottom: 3px solid #072f5f;
              font-weight: 700;
              font-size: 21px;
            "
            >OUR MISSION</span
          >
          <p class="text-dark" style="line-height: normal; text-align: justify">
            To be a leading outsourcing and advisory partner for the SME sector
            worldwide that provides analytical information, technology, and
            domain expertise quickly and cost effectively.
          </p>
        </div>
      </div>
      <div
        class="card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
        style="
          width: 350px;
          height: 460px;
          border-radius: 15px;
          margin-bottom: 2rem;
        "
      >
        <img
          class="img-fluid pt-50"
          src="../../../../../assets/images/mission and vision/visionInd.gif"
          alt="Card image cap"
          style="width: 10rem"
        />
        <div
          class="card-body d-flex flex-column justify-content-center align-items-center"
        >
          <span
            class="card-title text-center text-dark"
            style="
              border-bottom: 3px solid #072f5f;
              font-weight: 700;
              font-size: 21px;
            "
            >OUR VISION</span
          >
          <p class="text-dark" style="line-height: normal; text-align: justify">
            At the core of our success story lies the unwavering commitment to
            excellence, which permeates every interaction and is fortified by
            our five pillars: advice, outsourcing services, domain knowledge,
            processes, and technology
          </p>
        </div>
      </div>
      <div
        class="card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
        style="
          width: 350px;
          height: 460px;
          border-radius: 15px;
          margin-bottom: 2rem;
        "
      >
        <img
          class="img-fluid pt-10"
          src="../../../../../assets/images/mission and vision/statementInd.gif"
          alt="Card image cap"
          style="width: 12rem"
        />
        <div
          class="card-body d-flex flex-column justify-content-center align-items-center pt-0 mt-0"
        >
          <span
            class="card-title text-center text-dark text-uppercase"
            style="
              border-bottom: 3px solid #072f5f;
              font-weight: 700;
              font-size: 21px;
            "
            >Quality Statement</span
          >
          <p class="text-dark" style="line-height: normal; text-align: justify">
            Excel in providing financial outsourcing, consulting and advisory
            services leveraging our expertise and technology- driven process
            leading to customer and partner delight
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- india end -->

<!-- USA start -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 pb-100 mTop">
  <div
    class="col-lg-12 d-flex flex-column justify-content-center align-items-center"
    style="
      background: linear-gradient(
        to bottom,
        #072f5f 0%,
        rgba(50, 217, 81, 0) 100%,
        #ffff 100%
      );
    "
  >
    <div
      class="d-flex flex-column justify-content-center align-items-center"
      style="padding-top: 50px !important"
    >
      <h3 class="text-white text-uppercase text-center missionh3">
        Our Mission and Vision
      </h3>
      <div style="padding-top: 50px !important; text-align: center">
        <img
          src="../../../../../assets/images/mission and vision/team.JPG"
          style="width: 100%; border-radius: 40px"
        />
      </div>
    </div>
  </div>
  <div
    class="d-flex flex-column justify-content-center align-items-center"
    style="padding-top: 65px !important"
  >
    <!-- <div class="w-55 row mainDiv">
        <div id="myDivi">
          <h3 class="card-title text-uppercase">Our Mission and Vision</h3>
        </div>
      </div> -->
    <!-- <p class="text-dark w-50 text-center" style="line-height: text-dark normal; font-size: 16px;">
        To be a leading outsourcing and advisory partner for the SME sector
        worldwide that provides analytical information, technology, and domain
        expertise quickly and cost effectively.
      </p> -->
  </div>
  <div class="container-fluid">
    <div
      class="row d-flex justify-content-center align-items-center"
      style="
        padding-top: 40px !important;
        gap: 3rem;
        position: relative;
        overflow: hidden;
      "
    >
      <img
        src="../../../../../assets/images/mission and vision/Vector-left.png"
        alt="shape"
        style="
          position: absolute;
          overflow: clip;
          left: 0;
          bottom: 0;
          width: 12%;
        "
      />
      <img
        src="../../../../../assets/images/mission and vision/Vector-right.png"
        alt="shape"
        style="
          position: absolute;
          overflow: clip;
          right: 0;
          bottom: 0;
          width: 12%;
        "
      />

      <div
        class="card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
        style="
          width: 350px;
          height: 460px;
          border-radius: 15px;
          margin-bottom: 2rem;
        "
      >
        <img
          class="img-fluid pt-10"
          src="../../../../../assets/images/mission and vision/missionINd.gif"
          alt="Card image cap"
          style="width: 12rem"
        />
        <div
          class="card-body d-flex flex-column justify-content-center align-items-center pt-0 mt-0"
        >
          <span
            class="card-title text-center text-dark"
            style="
              border-bottom: 3px solid #072f5f;
              font-weight: 700;
              font-size: 21px;
            "
            >OUR MISSION</span
          >
          <p class="text-dark" style="line-height: normal; text-align: justify">
            To be a leading outsourcing and advisory partner for the SME sector
            worldwide that provides analytical information, technology, and
            domain expertise quickly and cost effectively.
          </p>
        </div>
      </div>
      <div
        class="card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
        style="
          width: 350px;
          height: 460px;
          border-radius: 15px;
          margin-bottom: 2rem;
        "
      >
        <img
          class="img-fluid pt-50"
          src="../../../../../assets/images/mission and vision/visionInd.gif"
          alt="Card image cap"
          style="width: 10rem"
        />
        <div
          class="card-body d-flex flex-column justify-content-center align-items-center"
        >
          <span
            class="card-title text-center text-dark"
            style="
              border-bottom: 3px solid #072f5f;
              font-weight: 700;
              font-size: 21px;
            "
            >OUR VISION</span
          >
          <p class="text-dark" style="line-height: normal; text-align: justify">
            At the core of our success story lies the unwavering commitment to
            excellence, which permeates every interaction and is fortified by
            our five pillars: advice, outsourcing services, domain knowledge,
            processes, and technology
          </p>
        </div>
      </div>
      <div
        class="card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
        style="
          width: 350px;
          height: 460px;
          border-radius: 15px;
          margin-bottom: 2rem;
        "
      >
        <img
          class="img-fluid pt-10"
          src="../../../../../assets/images/mission and vision/statementInd.gif"
          alt="Card image cap"
          style="width: 12rem"
        />
        <div
          class="card-body d-flex flex-column justify-content-center align-items-center pt-0 mt-0"
        >
          <span
            class="card-title text-center text-dark text-uppercase"
            style="
              border-bottom: 3px solid #072f5f;
              font-weight: 700;
              font-size: 21px;
            "
            >Quality Statement</span
          >
          <p class="text-dark" style="line-height: normal; text-align: justify">
            Excel in providing financial outsourcing, consulting and advisory
            services leveraging our expertise and technology- driven process
            leading to customer and partner delight
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- USA end -->
