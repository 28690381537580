
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area horizon-fms customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Horizon Facility Management Solution</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Horizon  FMS</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== (FMS) PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>FMS Products</span>
                    <h2 class="title pb-15">Horizon – Facility <br/> Management Solution (FMS)</h2>
                    <p>Horizon FMS has been designed to suit any organization including those who manage a single facility or a global property portfolio, or those who focus on a singular aspect of facilities operations or a full range of FM services.</p>
                    <p>The complete solution is suitable for the maintenance of Hard Services viz. HVAC, Electricals, Mechanical, Plumbing, Energy, etc. and Soft Services viz. Cleaning, Security, Pest Control, etc. </p>
                    <p>Horizon FMS is a complete web-based Computer Aided Facility Management with Mobile Functionality that helps to computerize organize and enhance maintenance activities. Horizon FMS with its unique features enables Site Surveys, Inspections,
                        Daily Visits, Monthly Visits, Asset Management, Planned Preventive Maintenance schedules, Breakdown Reporting and Corrective Maintenance, Employee details, Scheduling, Inventory, Vendor Management, Procurement & MIS reports in
                        an effective methodology.</p>
                    <p>Horizon FMS is a modular facilities management system that works together with your business and allows day-to-day management of the processes, automates operations, provides a management dashboard for critical analysis, and enables
                        enterprise-wide control in the most intelligent and efficient way.
                    </p>
                    <p>HORIZON FMS makes critical operational data available on-demand to users in any location. It offers a single view of all facilities and maintenance activities, and can easily track every location, asset, and person, analyze the processes,
                        costs, and efforts required for optimal operations, and understand the impact of utilization, maintenance, improvements, service provision, and expenditures.</p>
                    <p>HORIZON FMS allows you to stay on time and on budget, driving significant cost savings that translate to long-term profitability HORIZON FMS design structure and functional aspects support users providing maintenance services as well
                        as major companies who sub-contract maintenance activities.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== (FMS) PART End ======-->

<!--====== Features of Horizon - FMS PART START ======-->
<div class="about-faq-area about-faq-area-custom horizon-hrms dec-spacing bg_cover gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Highlights</span>
                    <h2 class="title">Features of Horizon - FMS</h2>
                </div>
            </div>
            <div class="faq-accordion-3">
                <div class="accordion" id="accordionExample">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>FM Helpdesk
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Each complaint raised through the helpdesk provides the user to enter the nature of complaint, detailed description of complaint, priority, mode, complainer information, specify assets, contracts, subcontracts, location,
                                        building, spot, maintenance type, etc. SMS and Email notifications can be configured and sent upon registering a complaint. Client Self Help Desk option is also available where the client can put a request for service
                                        and review the status themselves. This is also available on Horizon Apps.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Task Scheduling and Updates
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Each work order provides the user with an option to assign staff, enter work analysis reports, estimate cost, request materials, incorporate images, record start & end time, root cause, actions taken, and complainer
                                        remarks. Track the complete lifecycle of the complaint and request. Horizon FMS with is unique workflow capability enables the user to track the progress of complaint at different stages (example: Complaint Raised,
                                        Staff Assignment, Work Analysis, Job Estimation, Approval on Job Estimation, Work at the site by a technician, Job Inspection, completion).</p>
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>SLA - Service Level Agreements
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Each task, whether reactive or preventive, has an associated Service Level Agreement (SLA) that displays the estimated completion time against which task progress can be monitored. This ensures that overdue jobs or
                                        jobs about to breach will be easily identifiable to the user. SLA configuration allows the definition of a matrix of sites, buildings, priorities, and contracts. It further configuration to assist in meeting specific
                                        contract requirements. Multiple time points can be defined, monitored, and escalated against comprehensive user-defined business rules for each task.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <i class="fal fa-magic"></i>Contract Management
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Contract Management provides comprehensive information on the various maintenance activities carried out. The Contract Management System allows you to keep an accurate record of all your contracts, the agreed services,
                                        associated values, terms, and conditions. Each contract record provides the user with an option to incorporate unique contract code, contract name, associate customer, period, type of contract, activities, assets
                                        to maintain, financial details, employees, location, and site involved. </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-6">
                        <!-- card -->

                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingFive">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <i class="fal fa-magic"></i>Asset Management and PPM <small class="sm-none">( Planned Preventive Maintenance )</small>
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Asset Management is a comprehensive repository of all sites or clients' assets. The Asset Management solution gives you a single point of control over all types of assets. Manage assets such as HVAC, Mechanical, Electrical,
                                        Plumbing, and Production, IT, Office equipment, and much more. This helps complete control and track changes to an asset throughout its lifecycle and maintenance management. History is available for each asset with
                                        comprehensive information on the material used, details of preventive & breakdown maintenance activities carried, manpower details and cost of maintenance, preventive maintenance schedules. </p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSix">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    <i class="fal fa-magic"></i>Planned Preventive Maintenance
                                </a>
                            </div>
                            <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Planned Preventive Maintenance module will ensure optimization, maintenance analysis, and future financial planning of your assets. It gives you complete control over the PM task carried out on an asset and capable
                                        of tracking the progress from planning to completion. Planned Preventative Maintenance (PPM) software module satisfies all the related requirements whether scheduling tasks to prevent the failure of assets and is
                                        the ideal solution to help you manage repetitive tasks. The solution enables users to control and manage tasks via a simple, intuitive software solution.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingnineo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapsenineo" aria-expanded="false" aria-controls="collapsenineo">
                                    <i class="fal fa-magic"></i>Project Management
                                </a>
                            </div>
                            <div id="collapsenineo" class="collapse" aria-labelledby="headingnineo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Horizon – FMS allows us to handle all the non-contractual jobs, call out or variations jobs within the contract, and the routine jobs without quotation. The estimation tool allows you to estimate each task with different
                                        expense categories and costs codes. The detailed quotation can be raised based on the estimation and can be sent to the client. You can Estimate, quote, track, and manage the renovations, extensions, expansions
                                        activity including invoicing the clients for the same.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingEle">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEle" aria-expanded="false" aria-controls="collapseSix">
                                    <i class="fal fa-magic"></i>Auto / Manual Invoicing
                                </a>
                            </div>
                            <div id="collapseEle" class="collapse" aria-labelledby="headingEle" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Horizon – FMS allows raising contractual and non contractual invoicing from the system as per customized format. System has a facility to setup the auto invoice frequency and amount for each contract which reduced the
                                        mundane work drastically. For non contractual or call out jobs system can generate invoice based on the quote approved by the client.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== Features of Horizon - FMS PART START ======-->

<!--====== Other features PART START ======-->
<div class="about-faq-area about-faq-area-custom horizon-hrms dec-spacing bg_cover">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Additional Highlights</span>
                    <h2 class="title pb-15">Other features</h2>
                </div>
            </div>
            <div class="faq-accordion-3 pt-0">
                <div class="accordion" id="accordionExample1">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSev">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseSev" aria-expanded="true" aria-controls="collapseSev">
                                    <i class="fal fa-magic"></i>SMS and Email
                                </a>
                            </div>
                            <div id="collapseSev" class="collapse show" aria-labelledby="headingSev" data-parent="#accordionExample1">
                                <div class="card-body">
                                    <p>The facility of SMS and Email to directly alert the technician/ users for their work assigned to them and updates the client with their request status via email and SMS.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEight">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    <i class="fal fa-magic"></i>Online Access
                                </a>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                                <div class="card-body">
                                    <p>The system has specifically designed web portals for Customers for major Service providers to work using the portal platforms irrespective of their physical location as long as they can access the application through
                                        the Internet.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingNine">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    <i class="fal fa-magic"></i>Approvals
                                </a>
                            </div>
                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                                <div class="card-body">
                                    <p>Increase the decision making efficiency with effective usage of the online management approval for your internal team.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->

                    </div>
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingTen">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                    <i class="fal fa-magic"></i>Reports and Dashboards
                                </a>
                            </div>
                            <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                                <div class="card-body">
                                    <p>Our dashboards provide a real-time, easy to understand graphical representation of Key Performance Indicators (KPI’s) defined and tailored according to your requirements. The system also has many reporting functionalities
                                        tailored around team-specific objectives.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingTwe">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwe" aria-expanded="false" aria-controls="collapseTwe">
                                    <i class="fal fa-magic"></i>Sub-Contracts Management
                                </a>
                            </div>
                            <div id="collapseTwe" class="collapse" aria-labelledby="headingTwe" data-parent="#accordionExample1">
                                <div class="card-body">
                                    <p>Assign and track the specialized tasks which are not managed by your internal team by using the subcontracting in order to increase the transparency and accountability of the outsourced tasks.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingThr">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThr" aria-expanded="false" aria-controls="collapseThr">
                                    <i class="fal fa-magic"></i>Integration with ERP
                                </a>
                            </div>
                            <div id="collapseThr" class="collapse" aria-labelledby="headingThr" data-parent="#accordionExample1">
                                <div class="card-body">
                                    <p>The entire Horizon FMS system can be further integrated with Horizon – EBS and Horizon HRMS or with any other ERP OR HRMS/HCM you are using for better control of your organization operations.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== Other features PART END ======-->

<!--====== Staff and Technicians PART START ======-->
<div class="about-experience-area dec-spacing about-experience-area-custom horizon-fms gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="experience-item">
                    <span>App Highlights</span>
                    <h2 class="title pb-15">Horizon – FMS App for Field <br/> Staff and Technicians</h2>
                </div>
            </div>
            <div class="col-lg-12 mb-20">
                <p>By extending Horizon FMS end-user oriented features to smartphones, tablets, and mobile devices, the user experience, and convenience increase multi-fold.</p>
                <p>Horizon FMS App enables extended service to technicians performing the facility management and maintenance operations and managers overseeing those using devices like Android Phones, Android Tablets, etc. on the move.</p>
                <p>The ability to direct service calls and work orders with complete information on the asset, the location of the asset, the problem and the details of the work to be carried out, the tools required, the spares to be used, etc. directly
                    to technicians mobile devices enhances significantly the efficiency, quality of work and the speed of service to the end-users.</p>
                <p>The Seamless integration of Reach with Horizon FMS enables secured access to asset information and various maintenance tasks planned and assigned to technicians, supervisors, and inspectors. With Horizon FMS App you can do the followings:
                </p>
            </div>
            <div class="col-lg-6 add-arrow-circle">
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i>Track asset information by browsing in the search panel or by scanning the barcode/QR Code fixed to an asset. </p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i>Receive preventive, breakdown and daily inspection tasks</p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i>Option to Scan the barcode fixed in the asset before commencing a tasks.</p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i>Option to select material, enter root cause, observation, recommendation and corrective action taken during a breakdown maintenance</p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i>Time based maintenance activity</p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i>Option to keep a task on standby mode with valid predefined remarks</p>
                <!-- experience item -->
            </div>
            <div class="col-lg-6 add-arrow-circle">
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i>Perform site inspections</p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i>Take picture of assets and damaged parts while performing a tasks</p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i>View the SOP, health & safety instructions, sub assets</p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i>Get feedback and signature from the complaint originator</p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i>View status of activity performed</p>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Staff and Technicians PART END ======-->

<!--======  Helpdesk (Web and Mobile App) PART START ======-->
<div class="about-experience-area dec-spacing about-experience-area-custom horizon-fms">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="experience-item">
                    <span>Client Helpdesk Highlights</span>
                    <h2 class="title pb-15">Horizon – FMS Client <br/> Helpdesk (Web and Mobile App)</h2>
                </div>
            </div>
            <div class="col-lg-12 mb-20">
                <p>Horizon FMS dedicated, centralized web and mobile client helpdesk enhance the quality of service to your clients. Horizon FMS Client Helpdesk is a facility to simplify and enhance the quality of service to your client through quick response
                    times and better communication. This advanced tool is available online accessible through standard web browsers. Through App, helpdesk client can communicate directly with you.</p>
                <p>At any time your client can submit a complaint, raise a request work, and upload images and documents. Your maintenance team will operate in the most efficient and professional way possible. Users can easily track the status of their complaints
                    through the system from the time it is submitted to its completion.</p>
                <p>It enhances communication between end-users and maintenance teams, reduces direct phone calls, reduces response time, and Speed-up the tasks. Quick reporting with system updated status is available. There is controlled access to specific
                    information by individual or user groups. </p>
                <p>Horizon Client Helpdesk core features includes</p>
            </div>
            <div class="col-lg-6">
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> Manage Customer details</p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> Manage Contract details</p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> Create & manage users</p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> Raise complaints & service request</p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> Upload images, documents while registering a complaint</p>
                <!-- experience item -->
            </div>
            <div class="col-lg-6">
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> Track status from raised to completion</p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> Access to contract details</p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> Customize login page to feel your organization</p>
                <!-- <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> MIS Reports</p> -->
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--======  Helpdesk (Web and Mobile App) PART END ======-->
