import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { metaData } from 'src/app/services/metaData';
import { HorizonSupportFormService } from 'src/app/services/horizon-support-form.service';
import { CustomCaptcha } from 'src/app/services/custom-captcha';

@Component({
  selector: 'app-horizon-support',
  templateUrl: './horizon-support.component.html',
  styleUrls: ['./horizon-support.component.css']
})
export class HorizonSupportComponent implements OnInit {
  horizonSupportForm: FormGroup;
  resultFalse = false;
  resultTrue = false;
  selectedFile: File;
  getImgExt;
  ImgUrl
  imgName
  submitted = false;
  validCaptcha = false;
  getFileName;

  constructor(
    private metaInfo: metaData,
    private formBuilder: FormBuilder,
    private _horizonSupportFormService: HorizonSupportFormService,
    private _customCaptcha: CustomCaptcha
  ) { }

  ngOnInit(): void {
    this.horizonForm();
  }

  ngAfterViewInit(): void {
    this._customCaptcha.createCaptchaHorizonSupportPage();
  }

  reCaptcha() {
    this._customCaptcha.createCaptchaHorizonSupportPage();
  }

  horizonForm() {
    this.horizonSupportForm = this.formBuilder.group({
      name: ['', Validators.required],
      department: ['', Validators.required],
      comments: [''],
      subject: ['', Validators.required],
      upload_cv: [''],
      mobile: ['', Validators.compose([Validators.required,
      Validators.pattern('[0-9]*')])],
      email_id: ['', Validators.compose([
        Validators.required,
        Validators.email
      ])],
      recaptchaReactive:['', Validators.required]
    })
  }

  get f() { return this.horizonSupportForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.horizonSupportForm.invalid) {
      return;
    }
    else if (this.horizonSupportForm.value.recaptchaReactive != this._customCaptcha.validateCaptchaHorizonSupportPage()) {
      alert("Invalid Captcha. try Again");
      return
    }
    else {
      const formData = new FormData();
      formData.append('name', this.horizonSupportForm.value.name);
      formData.append('department', this.horizonSupportForm.value.department);
      formData.append('comments', this.horizonSupportForm.value.comments);
      formData.append('subject', this.horizonSupportForm.value.subject);
      formData.append('upload_cv', this.selectedFile);
      formData.append('mobile', this.horizonSupportForm.value.mobile);
      formData.append('email_id', this.horizonSupportForm.value.email_id);
      this._horizonSupportFormService.horizonDetails(formData).subscribe(res => {
        let response = res;
        if (response['result'] == 'F') {
          this.resultFalse = true;
          this.resultTrue = false;
        } else {
          this.resultFalse = false;
          this.resultTrue = true;
          this.horizonSupportForm.reset();
          this.getFileName = null;
          this.submitted = false;
          this._customCaptcha.createCaptchaHorizonSupportPage();
        }
      })
    }
  }

  fileUpload(event: any) {
    this.selectedFile = <File>event.target.files[0];
    let selResult = event.target.value.split('.');
    this.getImgExt = selResult.pop();
    this.getImgExt.toLowerCase();
    if (this.getImgExt == "pdf" || this.getImgExt == "doc" || this.getImgExt == "docx") {
      this.getFileName = this.selectedFile.name;
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.ImgUrl = event.target.result;
        }
        reader.readAsDataURL(event.target.files[0]);
        this.imgName = event.target.files[0].name;
      }
    } else {
      alert("Please upload only pdf,doc,docx files");

    }
  }

  choosePhoto() {
    document.getElementById('upload_file').click();
  }


  captchaResolved(captchaResponse: string) {
    if (!captchaResponse) {
      this.validCaptcha = false;
    } else {
      this.validCaptcha = true;
    }
    this.horizonSupportForm.patchValue({
      recaptchaReactive: this.validCaptcha
    });
  }
}
