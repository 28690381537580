
<div id="content" class="site-content">

    <div class="container">
        <div class="error-404 not-found text-center">
            <h2>404 <img class="error-image" src="https://webtend.biz/wp-content/themes/onum/images/404.png" alt="404"></h2>
            <h1>Sorry! Page Not Found!</h1>
            <div class="content-404">
                <p>Oops! The page you are looking for does not exist. Please return to the site is homepage.</p>
                <a class="octf-btn octf-btn-third octf-btn-icon" routerLink='/'>Take Me Home &nbsp; <i class="fal fa-arrow-right"></i></a>
            </div>
        </div>
    </div>

</div>
