<!-- dubai start -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
          <div class="" style="padding: 1%">
            <img
              style="border-radius: 10px; max-width: 95%"
              class="img-fluid"
              src="../../../../../assets/images/teams/AmitCircle.jpg"
              alt="amit"
            />
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start align-items-start pl-0 pr-0"
        >
          <h3
            class="text-uppercase text-dark title"
            style="font-size: 20px; font-weight: 700; "
          >
            AMIT MULGUND
          </h3>
          <div>
            <span
              class="text-uppercase text-dark"
              style="font-size: 16px; font-weight: 600"
            >
              Managing Director – EMEA
            </span>
            <a
              style="color: #fff"
              href="https://www.linkedin.com/in/amit-mulgund-ca-icai-3084a448/"
              target="_blank"
            >
              <i
                class="fab fa-linkedin-in"
                style="
                  margin-left: 10px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                "
              ></i>
            </a>
          </div>
          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Amit Mulgund is an astute finance professional and seasoned leader with an impressive 23-year track record in the Automotive Sector and Commodity & Engineering Procurement and Construction industries. Throughout his career, Amit has demonstrated a strong focus on the total cost of ownership and process management, leading to enhanced business results and boosted returns across diverse markets.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
            An Ex Group CFO and astute finance professional, he has experience
            of more than 20 years in the core F&A areas of creating
            tax-efficient cross border Holding-Subsidiary structures, Treasury
            and Risk management, Working capital management, Credit insurance
            management, ERP implementation, Valuation of companies and Mergers &
            Acquisitions.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          One of Amit's notable areas of expertise lies in mergers and acquisitions (M&A). He has successfully handled sell-side and buy-side M&A mandates/deals, providing invaluable support in due diligence, feasibility studies, financial health assessment, and cost reduction planning. Additionally, his proficiency extends to working capital gap analysis, 5-year business plan development, valuation, and turnaround strategy. Furthermore, he has extensive experience in finance, accounts, and payroll headcount outsourcing, enabling organizations to optimize their operations.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
          >
            Currently, Amit serves as the Managing Director EMEA at Boston Financial Advisory Group. In this role, he provides strategic guidance and oversees a range of critical areas.
          </p>


        </div>
        <div class="col-lg-12 pt-0 mt-0 ">
          <p class="mt-5 text-dark text-justify"
          style="font-size: 16px !important">

            Some of his key responsibilities include Export Pricing, Risk Management (including currency, commodities, and interest rate exposures), optimizing profitability in the Supply Chain, and establishing Holding and Subsidiary Companies across the Middle East, Asia, Europe, and Africa. Amit's strategic acumen and deep understanding of the financial landscape have been instrumental in driving the success of Boston Financial Advisory Group and its clients.
          </p>
          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
            Passionate about leveraging his extensive experience, Amit is dedicated to delivering tangible results. With his unwavering commitment to excellence, he continues to empower financial futures, one strategic move at a time. He possesses a proven track record of leading teams to success and collaborating with stakeholders at all levels to drive business growth and improve financial performance.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
            Amit Mulgund's remarkable journey in the finance industry has shaped him into a highly respected professional. By leveraging his extensive experience and understanding of the financial landscape, Amit continues to create a positive impact and pave the way for financial success.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- duabi end -->

<!-- india start -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
          <div class="" style="padding: 1%">
            <img
              style="border-radius: 10px; max-width: 95%"
              class="img-fluid"
              src="../../../../../assets/images/teams/AmitCircle.jpg"
              alt="amit"
            />
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start align-items-start pl-0 pr-0"
        >
          <h3
            class="text-uppercase text-dark title"
            style="font-size: 20px; font-weight: 700; "
          >
            AMIT MULGUND
          </h3>
          <div>
            <span
              class="text-uppercase text-dark"
              style="font-size: 16px; font-weight: 600"
            >
              Managing Director – EMEA
            </span>
            <a
              style="color: #fff"
              href="https://www.linkedin.com/in/amit-mulgund-ca-icai-3084a448/"
              target="_blank"
            >
              <i
                class="fab fa-linkedin-in"
                style="
                  margin-left: 10px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                "
              ></i>
            </a>
          </div>
          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Amit Mulgund is an astute finance professional and seasoned leader with an impressive 23-year track record in the Automotive Sector and Commodity & Engineering Procurement and Construction industries. Throughout his career, Amit has demonstrated a strong focus on the total cost of ownership and process management, leading to enhanced business results and boosted returns across diverse markets.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
            An Ex Group CFO and astute finance professional, he has experience
            of more than 20 years in the core F&A areas of creating
            tax-efficient cross border Holding-Subsidiary structures, Treasury
            and Risk management, Working capital management, Credit insurance
            management, ERP implementation, Valuation of companies and Mergers &
            Acquisitions.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          One of Amit's notable areas of expertise lies in mergers and acquisitions (M&A). He has successfully handled sell-side and buy-side M&A mandates/deals, providing invaluable support in due diligence, feasibility studies, financial health assessment, and cost reduction planning. Additionally, his proficiency extends to working capital gap analysis, 5-year business plan development, valuation, and turnaround strategy. Furthermore, he has extensive experience in finance, accounts, and payroll headcount outsourcing, enabling organizations to optimize their operations.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
          >
            Currently, Amit serves as the Managing Director EMEA at Boston Financial Advisory Group. In this role, he provides strategic guidance and oversees a range of critical areas.
          </p>


        </div>
        <div class="col-lg-12 pt-0 mt-0 ">
          <p class="mt-5 text-dark text-justify"
          style="font-size: 16px !important">

            Some of his key responsibilities include Export Pricing, Risk Management (including currency, commodities, and interest rate exposures), optimizing profitability in the Supply Chain, and establishing Holding and Subsidiary Companies across the Middle East, Asia, Europe, and Africa. Amit's strategic acumen and deep understanding of the financial landscape have been instrumental in driving the success of Boston Financial Advisory Group and its clients.
          </p>
          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
            Passionate about leveraging his extensive experience, Amit is dedicated to delivering tangible results. With his unwavering commitment to excellence, he continues to empower financial futures, one strategic move at a time. He possesses a proven track record of leading teams to success and collaborating with stakeholders at all levels to drive business growth and improve financial performance.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
            Amit Mulgund's remarkable journey in the finance industry has shaped him into a highly respected professional. By leveraging his extensive experience and understanding of the financial landscape, Amit continues to create a positive impact and pave the way for financial success.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- INdia ends -->

<!-- USA start -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
          <div class="" style="padding: 1%">
            <img
              style="border-radius: 10px; max-width: 95%"
              class="img-fluid"
              src="../../../../../assets/images/teams/AmitCircle.jpg"
              alt="amit"
            />
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start align-items-start pl-0 pr-0"
        >
          <h3
            class="text-uppercase text-dark title"
            style="font-size: 20px; font-weight: 700; "
          >
            AMIT MULGUND
          </h3>
          <div>
            <span
              class="text-uppercase text-dark"
              style="font-size: 16px; font-weight: 600"
            >
              Managing Director – EMEA
            </span>
            <a
              style="color: #fff"
              href="https://www.linkedin.com/in/amit-mulgund-ca-icai-3084a448/"
              target="_blank"
            >
              <i
                class="fab fa-linkedin-in"
                style="
                  margin-left: 10px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                "
              ></i>
            </a>
          </div>
          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Amit Mulgund is an astute finance professional and seasoned leader with an impressive 23-year track record in the Automotive Sector and Commodity & Engineering Procurement and Construction industries. Throughout his career, Amit has demonstrated a strong focus on the total cost of ownership and process management, leading to enhanced business results and boosted returns across diverse markets.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
            An Ex Group CFO and astute finance professional, he has experience
            of more than 20 years in the core F&A areas of creating
            tax-efficient cross border Holding-Subsidiary structures, Treasury
            and Risk management, Working capital management, Credit insurance
            management, ERP implementation, Valuation of companies and Mergers &
            Acquisitions.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          One of Amit's notable areas of expertise lies in mergers and acquisitions (M&A). He has successfully handled sell-side and buy-side M&A mandates/deals, providing invaluable support in due diligence, feasibility studies, financial health assessment, and cost reduction planning. Additionally, his proficiency extends to working capital gap analysis, 5-year business plan development, valuation, and turnaround strategy. Furthermore, he has extensive experience in finance, accounts, and payroll headcount outsourcing, enabling organizations to optimize their operations.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
          >
            Currently, Amit serves as the Managing Director EMEA at Boston Financial Advisory Group. In this role, he provides strategic guidance and oversees a range of critical areas.
          </p>


        </div>
        <div class="col-lg-12 pt-0 mt-0 ">
          <p class="mt-5 text-dark text-justify"
          style="font-size: 16px !important">

            Some of his key responsibilities include Export Pricing, Risk Management (including currency, commodities, and interest rate exposures), optimizing profitability in the Supply Chain, and establishing Holding and Subsidiary Companies across the Middle East, Asia, Europe, and Africa. Amit's strategic acumen and deep understanding of the financial landscape have been instrumental in driving the success of Boston Financial Advisory Group and its clients.
          </p>
          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
            Passionate about leveraging his extensive experience, Amit is dedicated to delivering tangible results. With his unwavering commitment to excellence, he continues to empower financial futures, one strategic move at a time. He possesses a proven track record of leading teams to success and collaborating with stakeholders at all levels to drive business growth and improve financial performance.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
            Amit Mulgund's remarkable journey in the finance industry has shaped him into a highly respected professional. By leveraging his extensive experience and understanding of the financial landscape, Amit continues to create a positive impact and pave the way for financial success.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- USA ends -->
