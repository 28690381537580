<!-- dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100">
  <div class="container-fluid  mt-150 divmart d-flex flex-column justify-content-center align-items-center">
    <div class="row d-flex flex-column justify-content-center align-items-center">
      <div class="col-lg-12 d-flex flex-column justify-content-center align-items-center">
        <div>
          <h3 class="text-center " style="border-bottom: 4px solid #418052; display: inline-block">
           DATA SECURITY AND CONFIDENTIALITY
          </h3>
        </div>
        <div class=" mb-5 " style="margin-top: 3rem !important;">
          <img class="img-fluid" style="border-radius: 15px; " src="../../../../../assets/images/how we do it/dataSecurity.jpg"
          alt="execution_methodology" />
        </div>
      </div>
      <div class="col-lg-12">
        <p class="text-dark text-justify ">
          Financial data sharing, with us it is secure and seamless. Whether it is a physical document or digital files or the software you use for financial accounting / reporting, we have well defined standard protocols that ensure that you share with us in the most secured way. We are ISO 27001 and ISO 9001 certified company which follows information security standards, the person in charge of our IT is a Microsoft and CISCO certified professional.
        </p>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="col-12 container-fluid pt-50 pb-3  pr-0 ml-0 mr-0 ">
      <ul class=" justify-content-between nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item col-lg-6 pl-0 pr-0" role="presentation">
          <button
            class="nav-link active border-0 text-white buttonUAE"
            id="pills-home-tab"
            data-toggle="pill"
            data-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
            style="background: #418052;  border-radius: 0; width: 100%;"
          >
          A. The client can share the document in following ways;
          </button>
        </li>
        <li class="nav-item col-lg-6 pl-0 pr-0" role="presentation">
          <button
            class="nav-link border-0 text-white buttonUAE"
            id="pills-profile-tab"
            data-toggle="pill"
            data-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
            style="background: #418052;  border-radius: 0; width: 100%;"
          >
          B. What BFAG does on receipt of a document;
          </button>
        </li>
      </ul>
      <div class="col-12 pl-0 ml-0 pr-0 mr-0 tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
      <ul class="text-dark" style="line-height: 2rem;">

        <li><i class="fas fa-arrow-circle-right" style="color: #418052;" ></i>  Client can save the documents on a predefined path on client server (when working on client’s environment)</li>
        <li><i class="fas fa-arrow-circle-right" style="color: #418052;" ></i> Client can save the documents on cloud storage (when working on BFAG environment)</li>
        <li><i class="fas fa-arrow-circle-right" style="color: #418052;" ></i> Share thru email, fax or CamScanner mobile application</li>
        <li><i class="fas fa-arrow-circle-right" style="color: #418052;" ></i> Bank, Customer, Vendor can send documents directly to BFAG at a predefined email id</li>
        <li><i class="fas fa-arrow-circle-right" style="color: #418052;" ></i> BFAG can download from third party portals directly in case instructions are given</li>
      </ul>
        </div>
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
        <ul class="text-dark" style="line-height: 2rem;">
          <li><i class="fas fa-arrow-circle-right" style="color: #418052;" ></i>  Verify the document and revert back with query if any</li>
          <li><i class="fas fa-arrow-circle-right" style="color: #418052;" ></i> Scheduling the document to respective process associate</li>
          <li><i class="fas fa-arrow-circle-right" style="color: #418052;" ></i> Document coding and entry into the F & A system</li>
          <li><i class="fas fa-arrow-circle-right" style="color: #418052;" ></i> Saving the document on the predefined path</li>
        </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- dubai end -->

<!-- India started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100">
  <div class="container-fluid  mt-150 divmart d-flex flex-column justify-content-center align-items-center">
    <div class="row d-flex flex-column justify-content-center align-items-center">
      <div class="col-lg-12 d-flex flex-column justify-content-center align-items-center">
        <div>
          <h3 class="text-center " style="border-bottom: 4px solid #072F5F; display: inline-block">
           DATA SECURITY AND CONFIDENTIALITY
          </h3>
        </div>
        <div class=" mb-5 " style="margin-top: 3rem !important;">
          <img class="img-fluid" style="border-radius: 15px; " src="../../../../../assets/images/how we do it/dataSecurity.jpg"
          alt="execution_methodology" />
        </div>
      </div>
      <div class="col-lg-12">
        <p class="text-dark text-justify ">
          Financial data sharing, with us it is secure and seamless. Whether it is a physical document or digital files or the software you use for financial accounting / reporting, we have well defined standard protocols that ensure that you share with us in the most secured way. We are ISO 27001 and ISO 9001 certified company which follows information security standards, the person in charge of our IT is a Microsoft and CISCO certified professional.
        </p>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="col-12 container-fluid pt-50 pb-3  pr-0 ml-0 mr-0 ">
      <ul class=" justify-content-between nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item col-lg-6 pl-0 pr-0" role="presentation">
          <button
            class="nav-link active border-0 text-white buttonInd"
            id="pills-home-tab"
            data-toggle="pill"
            data-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
            style="background: #072F5F;  border-radius: 0; width: 100%;"
          >
          A. The client can share the document in following ways
          </button>
        </li>
        <li class="nav-item col-lg-6 pl-0 pr-0" role="presentation">
          <button
            class="nav-link border-0 text-white buttonInd"
            id="pills-profile-tab"
            data-toggle="pill"
            data-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
            style="background: #072F5F;  border-radius: 0; width: 100%;"
          >
          B. What BFAG does on receipt of a document
          </button>
        </li>
      </ul>
      <div class="col-12 pl-0 ml-0 pr-0 mr-0 tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
      <ul class="text-dark" style="line-height: 2rem;">

        <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i>  Client can save the documents on a predefined path on client server (when working on client’s environment)</li>
        <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i> Client can save the documents on cloud storage (when working on BFAG environment)</li>
        <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i> Share thru email, fax or CamScanner mobile application</li>
        <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i> Bank, Customer, Vendor can send documents directly to BFAG at a predefined email id</li>
        <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i> BFAG can download from third party portals directly in case instructions are given</li>
      </ul>
        </div>
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
        <ul class="text-dark" style="line-height: 2rem;">
          <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i>  Verify the document and revert back with query if any</li>
          <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i> Scheduling the document to respective process associate</li>
          <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i> Document coding and entry into the F & A system</li>
          <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i> Saving the document on the predefined path</li>
        </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- India end -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100">
  <div class="container-fluid  mt-150 divmart d-flex flex-column justify-content-center align-items-center">
    <div class="row d-flex flex-column justify-content-center align-items-center">
      <div class="col-lg-12 d-flex flex-column justify-content-center align-items-center">
        <div>
          <h3 class="text-center " style="border-bottom: 4px solid #072F5F; display: inline-block">
           DATA SECURITY AND CONFIDENTIALITY
          </h3>
        </div>
        <div class=" mb-5 " style="margin-top: 3rem !important;">
          <img class="img-fluid" style="border-radius: 15px; " src="../../../../../assets/images/how we do it/dataSecurity.jpg"
          alt="execution_methodology" />
        </div>
      </div>
      <div class="col-lg-12">
        <p class="text-dark text-justify ">
          Financial data sharing, with us it is secure and seamless. Whether it is a physical document or digital files or the software you use for financial accounting / reporting, we have well defined standard protocols that ensure that you share with us in the most secured way. We are ISO 27001 and ISO 9001 certified company which follows information security standards, the person in charge of our IT is a Microsoft and CISCO certified professional.
        </p>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="col-12 container-fluid pt-50 pb-3  pr-0 ml-0 mr-0 ">
      <ul class=" justify-content-between nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item col-lg-6 pl-0 pr-0" role="presentation">
          <button
            class="nav-link active border-0 text-white buttonInd"
            id="pills-home-tab"
            data-toggle="pill"
            data-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
            style="background: #072F5F;  border-radius: 0; width: 100%;"
          >
          A. The client can share the document in following ways
          </button>
        </li>
        <li class="nav-item col-lg-6 pl-0 pr-0" role="presentation">
          <button
            class="nav-link border-0 text-white buttonInd"
            id="pills-profile-tab"
            data-toggle="pill"
            data-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
            style="background: #072F5F;  border-radius: 0; width: 100%;"
          >
          B. What BFAG does on receipt of a document
          </button>
        </li>
      </ul>
      <div class="col-12 pl-0 ml-0 pr-0 mr-0 tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
      <ul class="text-dark" style="line-height: 2rem;">

        <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i>  Client can save the documents on a predefined path on client server (when working on client’s environment)</li>
        <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i> Client can save the documents on cloud storage (when working on BFAG environment)</li>
        <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i> Share thru email, fax or CamScanner mobile application</li>
        <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i> Bank, Customer, Vendor can send documents directly to BFAG at a predefined email id</li>
        <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i> BFAG can download from third party portals directly in case instructions are given</li>
      </ul>
        </div>
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
        <ul class="text-dark" style="line-height: 2rem;">
          <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i>  Verify the document and revert back with query if any</li>
          <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i> Scheduling the document to respective process associate</li>
          <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i> Document coding and entry into the F & A system</li>
          <li><i class="fas fa-arrow-circle-right" style="color: #072F5F;" ></i> Saving the document on the predefined path</li>
        </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- USA end -->


