<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div
      class="d-flex justify-content-center align-items-center banner-image bmTop"
      style="
        background: url('../../../../assets/images/service/Accounting.png')
          no-repeat center;
        background-size: cover;
        height: 430px;
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase text-center">
          Accounting & Transaction Processing
        </h3>
        <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
      </div>
    </div>
    <div
      class="container-fluid d-flex justify-content-center align-content-center bg-white"
    >
      <div class="about-faq-area-custom horizon-hrms bg_cover">
        <div>
          <div>
            <div class="row">
              <div class="col-md-4">
                <div class="faq-accordion-3 pt-0">
                  <div class="accordion" id="accordionExample1">
                    <div
                      class="card wow fadeInLeft"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".4s"
                      style="margin-bottom: 0px"
                    >
                      <div class="card-header" id="headingSev">
                        <a
                          href="#accounting-and-transition-processing"
                          data-toggle="collapse"
                          data-target="#collapseSevv"
                          aria-expanded="true"
                          aria-controls="collapseSevv"
                        >
                          Accounting & Transaction Processing
                        </a>
                      </div>
                      <div
                        id="collapseSevv"
                        class="collapse show"
                        aria-labelledby="headingSev"
                        data-parent="#accordionExample1"
                      >
                        <div class="card-body bg-white">
                          <div class="list-group">
                            <ul class="text-dark serviceul">
                              <li routerLink="/accounts-payable">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Accounts Payable
                              </li>
                              <li routerLink="/accounts-receivable">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Accounts Receivable
                              </li>
                              <li routerLink="/fixed-assets-accounting">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Fixed Assets Accounting
                              </li>
                              <li routerLink="/financial-reporting">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Financial Reporting
                              </li>
                              <li
                                class="shli"
                                routerLink="/budgeting-and-variance-analysis"
                              >
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Budgeting & Variance Analysis
                              </li>
                              <li routerLink="/reconciliation-services">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Reconciliation Services
                              </li>
                              <li
                                routerLink="/consolidation-reporting-gaap-conversion"
                              >
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Consolidation Reporting & GAAP Conversion
                              </li>
                              <li routerLink="/small-business-accounting">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Small Business Accounting
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->
                    <div
                      class="card wow fadeInLeft"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".5s"
                    >
                      <div class="card-header" id="headingEight">
                        <a
                          class="collapsed"
                          href=""
                          data-toggle="collapse"
                          data-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          <span (click)="onTax()"
                            >Tax & Regulatory Compliances</span
                          >
                        </a>
                      </div>
                      <div
                        id="collapseEight"
                        class="collapse"
                        aria-labelledby="headingEight"
                        data-parent="#accordionExample1"
                      >
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark serviceul">
                              <li routerLink="/individual-tax-return">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Individual Tax Return
                              </li>
                              <li routerLink="/corporate-tax-return">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Corporate Tax Return
                              </li>
                              <li routerLink="/sales-and-use-tax">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Sales & Use Tax
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- card -->
                    <div
                      class="card wow fadeInLeft"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".6s"
                    >
                      <div class="card-header" id="headingNine">
                        <a
                          class="collapsed"
                          href=""
                          data-toggle="collapse"
                          data-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                          <span (click)="onPayroll()"
                            >Payroll Advisory</span
                          >
                        </a>
                      </div>
                      <div
                        id="collapseNine"
                        class="collapse"
                        aria-labelledby="headingNine"
                        data-parent="#accordionExample1"
                      >
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark pt-4 serviceul">
                              <li routerLink="/payroll-processing">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Payroll Processing
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->

                    <!-- card -->
                    <div
                      class="card wow fadeInLeft"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".6s"
                    >
                      <div class="card-header" id="headingTen">
                        <a
                          class="collapsed"
                          href=""
                          data-toggle="collapse"
                          data-target="#collapseTen"
                          aria-expanded="false"
                          aria-controls="collapseTen"
                        >
                          <span (click)="onConsulting()"
                            >Consulting & Advisory</span
                          >
                        </a>
                      </div>
                      <div
                        id="collapseTen"
                        class="collapse"
                        aria-labelledby="headingTen"
                        data-parent="#accordionExample1"
                      >
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark pt-4 serviceul">
                              <li routerLink="/business-setup">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Setting up an entity in the US
                              </li>
                              <li routerLink="/global-corporate-structuring">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Global Corporate Structuring
                              </li>
                              <li routerLink="/business-plan-preparation">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Business Plan Preparation
                              </li>
                              <li routerLink="/business-valuation-service">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Business Valuation
                              </li>
                              <li routerLink="/mergers-and-acquisitions">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Mergers and Acquisitions
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div style="margin-top: 1rem">
                  <h3 id="budgeting-and-variance-analysis">
                    Budgeting & Variance Analysis
                  </h3>
                  <!--<p class="mb-15 mt-20 text-dark servicep">
                      Achieve Financial Excellence with Expert Budgeting & Variance Analysis Services
                    </p>-->
                  <p class="mb-15 mt-20 text-dark servicep">
                    Budgeting and variance analysis play a pivotal role in
                    effective financial management, providing businesses with
                    the ability to plan, monitor, and control their financial
                    performance. However, developing accurate budgets and
                    analyzing variances can be challenging, particularly for
                    organizations without dedicated expertise or resources. At
                    BFAG we offer specialized budgeting and variance analysis
                    services designed to help you achieve financial excellence.
                    Our expert team can optimize your budgeting process and
                    provide valuable insights through variance analysis.
                  </p>
                </div>
                <div
                  class="about-faq-area-custom horizon-hrms gray-bg clearfix"
                >
                  <div class="container">
                    <div class="row" style="display: block">
                      <div
                        class="faq-accordion-2"
                        style="padding-bottom: 20px; padding-top: 20px"
                      >
                        <div class="accordion" id="accordionExample2">
                          <div class="col-lg-6">
                            <div
                              class="card wow fadeInLeft"
                              data-wow-duration=".1.2s"
                              data-wow-delay=".2s"
                            >
                              <div class="card-header" id="headingEleven">
                                <a
                                  class="collapsed"
                                  href=""
                                  data-toggle="collapse"
                                  data-target="#collapseEleven"
                                  aria-expanded="false"
                                  aria-controls="collapseEleven"
                                >
                                  Customized Budgeting Solutions
                                </a>
                              </div>
                              <div
                                id="collapseEleven"
                                class="collapse"
                                aria-labelledby="headingEleven"
                                data-parent="#accordionExample2"
                              >
                                <div
                                  class="card-body text-justify"
                                  style="padding-left: 20px !important"
                                >
                                  <p>
                                    Our experienced professionals understand
                                    that one size does not fit all when it comes
                                    to budgeting. We offer customized budgeting
                                    solutions tailored to your business's unique
                                    needs and objectives. Whether you require
                                    annual budgets, project budgets, or rolling
                                    forecasts, our team collaborates with you to
                                    develop comprehensive budgeting frameworks
                                    that align with your strategic goals.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <!-- card -->
                            <div
                              class="card wow fadeInLeft"
                              data-wow-duration=".1.2s"
                              data-wow-delay=".3s"
                            >
                              <div class="card-header" id="headingTwelve">
                                <a
                                  class="collapsed"
                                  href=""
                                  data-toggle="collapse"
                                  data-target="#collapseTwelve"
                                  aria-expanded="false"
                                  aria-controls="collapseTwelve"
                                >
                                  Accurate Budget Development
                                </a>
                              </div>
                              <div
                                id="collapseTwelve"
                                class="collapse"
                                aria-labelledby="headingTwelve"
                                data-parent="#accordionExample2"
                              >
                                <div
                                  class="card-body text-justify"
                                  style="padding-left: 20px !important"
                                >
                                  <p>
                                    Developing an accurate budget is the
                                    foundation of effective financial planning.
                                    Our team utilizes advanced financial
                                    modeling techniques and industry expertise
                                    to ensure precise budget development. We
                                    work closely with you to identify revenue
                                    projections, cost drivers, and key
                                    performance indicators (KPIs) that influence
                                    your financial outcomes. By leveraging our
                                    expertise, you can make informed decisions,
                                    allocate resources strategically, and plan
                                    for growth.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <!-- card -->
                            <div
                              class="card wow fadeInLeft"
                              data-wow-duration=".1.2s"
                              data-wow-delay=".4s"
                            >
                              <div class="card-header" id="headingThirteen">
                                <a
                                  class="collapsed"
                                  href=""
                                  data-toggle="collapse"
                                  data-target="#collapseThirteen"
                                  aria-expanded="false"
                                  aria-controls="collapseThirteen"
                                >
                                  Ongoing Variance Analysis
                                </a>
                              </div>
                              <div
                                id="collapseThirteen"
                                class="collapse"
                                aria-labelledby="headingThirteen"
                                data-parent="#accordionExample2"
                              >
                                <div
                                  class="card-body text-justify"
                                  style="padding-left: 20px !important"
                                >
                                  <p>
                                    Variance analysis is a powerful tool for
                                    assessing the financial performance of your
                                    business. Our services include ongoing
                                    variance analysis, where we compare actual
                                    financial results against budgeted
                                    expectations. By identifying and analyzing
                                    variances, we help you understand the root
                                    causes behind deviations and take corrective
                                    actions in a timely manner. This enables you
                                    to maintain financial control, optimize
                                    operations, and drive profitability.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <!-- card -->
                          </div>

                          <div class="col-lg-6">
                            <div
                              class="card wow fadeInLeft"
                              data-wow-duration=".1.2s"
                              data-wow-delay=".4s"
                            >
                              <div class="card-header" id="headingFourteen">
                                <a
                                  class="collapsed"
                                  href=""
                                  data-toggle="collapse"
                                  data-target="#collapseFourteen"
                                  aria-expanded="false"
                                  aria-controls="collapseFourteen"
                                >
                                  Key Performance Indicators (KPIs) Tracking
                                </a>
                              </div>
                              <div
                                id="collapseFourteen"
                                class="collapse"
                                aria-labelledby="headingFourteen"
                                data-parent="#accordionExample2"
                              >
                                <div
                                  class="card-body text-justify"
                                  style="padding-left: 20px !important"
                                >
                                  <p>
                                    Monitoring and tracking KPIs is essential
                                    for measuring the success of your business
                                    and aligning with your strategic objectives.
                                    Our budgeting and variance analysis services
                                    include the identification and tracking of
                                    relevant KPIs specific to your industry and
                                    business goals. We provide regular reports
                                    and visual representations of KPIs, enabling
                                    you to assess performance, identify trends,
                                    and make data-driven decisions.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              class="card wow fadeInLeft"
                              data-wow-duration=".1.2s"
                              data-wow-delay=".5s"
                            >
                              <div class="card-header" id="headingFifteen">
                                <a
                                  class="collapsed"
                                  href=""
                                  data-toggle="collapse"
                                  data-target="#collapseFifteen"
                                  aria-expanded="false"
                                  aria-controls="collapseFifteen"
                                >
                                  Proactive Financial Planning and Control
                                </a>
                              </div>
                              <div
                                id="collapseFifteen"
                                class="collapse"
                                aria-labelledby="headingFifteen"
                                data-parent="#accordionExample2"
                              >
                                <div
                                  class="card-body text-justify"
                                  style="padding-left: 20px !important"
                                >
                                  <p>
                                    Effective budgeting and variance analysis
                                    provide the foundation for proactive
                                    financial planning and control. By
                                    leveraging our services, you gain valuable
                                    insights into your business's financial
                                    performance, enabling you to identify
                                    potential risks and opportunities in
                                    advance. We help you implement control
                                    mechanisms, establish early warning systems,
                                    and develop contingency plans to mitigate
                                    risks and optimize financial outcomes.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <!-- card -->
                            <div
                              class="card wow fadeInLeft"
                              data-wow-duration=".1.2s"
                              data-wow-delay=".6s"
                            >
                              <div class="card-header" id="headingSixteen">
                                <a
                                  class="collapsed"
                                  href=""
                                  data-toggle="collapse"
                                  data-target="#collapseSixteen"
                                  aria-expanded="false"
                                  aria-controls="collapseSixteen"
                                >
                                  Strategic Decision-Making
                                </a>
                              </div>
                              <div
                                id="collapseSixteen"
                                class="collapse"
                                aria-labelledby="headingSixteen"
                                data-parent="#accordionExample2"
                              >
                                <div
                                  class="card-body text-justify"
                                  style="padding-left: 20px !important"
                                >
                                  <p>
                                    Accurate budgeting and variance analysis
                                    empower you to make strategic decisions with
                                    confidence. By understanding the financial
                                    impact of different scenarios and
                                    considering variances, you can make informed
                                    choices that align with your business goals.
                                    Our expert team provides comprehensive
                                    financial analysis and recommendations to
                                    support your strategic decision-making
                                    process.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <!-- card -->
                          </div>
                        </div>
                        <!-- faq accordion -->
                      </div>
                    </div>
                    <!-- row -->
                  </div>
                  <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                  Effective budgeting and variance analysis are essential
                  components of financial excellence. BFAG offers customized
                  budgeting solutions, ongoing variance analysis, and proactive
                  financial planning. By leveraging our expertise, you can
                  optimize your budgeting process, gain valuable insights
                  through variance analysis, and make informed decisions that
                  drive your business's success. Contact us today to experience
                  the benefits of our professional budgeting and variance
                  analysis.
                </p>
              </div>
            </div>
            <!-- faq accordion -->
          </div>
        </div>
        <!-- row -->
      </div>
    </div>
  </div>
</section>

<!-- india -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div
      class="d-flex justify-content-center align-items-center banner-image bmTop"
      style="
        background: url('../../../../assets/images/service/Accounting.png')
          no-repeat center;
        background-size: cover;
        height: 430px;
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase text-center">
          Accounting & Transaction Processing
        </h3>
        <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
      </div>
    </div>
    <div
      class="container-fluid d-flex justify-content-center align-content-center bg-white"
    >
      <div class="about-faq-area-custom horizon-hrms bg_cover">
        <div>
          <div>
            <div class="row">
              <div class="col-md-4">
                <div class="faq-accordion-3 pt-0">
                  <div class="accordion" id="accordionExample1">
                    <div
                      class="card wow fadeInLeft"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".4s"
                      style="margin-bottom: 0px"
                    >
                      <div class="card-header" id="headingSev">
                        <a
                          class=""
                          href=""
                          data-toggle="collapse"
                          data-target="#collapseSev"
                          aria-expanded="true"
                          aria-controls="collapseSev"
                        >
                          <span
                            routerLink="/accounting-and-transition-processing"
                            >Accounting & Transaction Processing</span
                          >
                        </a>
                      </div>
                      <div
                        id="collapseSev"
                        class="collapse show"
                        aria-labelledby="headingSev"
                        data-parent="#accordionExample1"
                      >
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark serviceul">
                              <li routerLink="/accounts-payable">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Accounts Payable
                              </li>
                              <li routerLink="/accounts-receivable">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Accounts Receivable
                              </li>
                              <li routerLink="/fixed-assets-accounting">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Fixed Assets Accounting
                              </li>
                              <li routerLink="/financial-reporting">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Financial Reporting
                              </li>
                              <li
                                class="shli"
                                routerLink="/budgeting-and-variance-analysis"
                              >
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                <a href="#budgeting-and-variance-analysis"
                                  >Budgeting & Variance Analysis</a
                                >
                              </li>
                              <li routerLink="/reconciliation-services">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Reconciliation Services
                              </li>
                              <li
                                routerLink="/consolidation-reporting-gaap-conversion"
                              >
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Consolidation Reporting & GAAP Conversion
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->
                    <div
                      class="card wow fadeInLeft"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".5s"
                    >
                      <div class="card-header" id="headingEight">
                        <a
                          class="collapsed"
                          href=""
                          data-toggle="collapse"
                          data-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          <span (click)="onTax()"
                            >Tax & Regulatory Compliances</span
                          >
                        </a>
                      </div>
                      <div
                        id="collapseEight"
                        class="collapse"
                        aria-labelledby="headingEight"
                        data-parent="#accordionExample1"
                      >
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark serviceul">
                              <li routerLink="/income-tax">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Income Tax
                              </li>
                              <li routerLink="/goods-and-service-tax">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Goods & Service Tax
                              </li>
                              <li
                                routerLink="/corporate-secretarial-compliance"
                              >
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Corporate Secretarial Compliance
                              </li>
                              <li routerLink="/fema-advisory">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                FEMA Compliance
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- card -->
                    <div
                      class="card wow fadeInLeft"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".6s"
                    >
                      <div class="card-header" id="headingNine">
                        <a
                          class="collapsed"
                          href=""
                          data-toggle="collapse"
                          data-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                          <span (click)="onPayroll()"
                            >Payroll Advisory</span
                          >
                        </a>
                      </div>
                      <div
                        id="collapseNine"
                        class="collapse"
                        aria-labelledby="headingNine"
                        data-parent="#accordionExample1"
                      >
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark pt-4 serviceul">
                              <li routerLink="/payroll-processing">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Payroll - Compensation & Benefit Management
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->

                    <!-- card -->
                    <div
                      class="card wow fadeInLeft"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".6s"
                    >
                      <div class="card-header" id="headingTen">
                        <a
                          class="collapsed"
                          href=""
                          data-toggle="collapse"
                          data-target="#collapseTen"
                          aria-expanded="false"
                          aria-controls="collapseTen"
                        >
                          <span (click)="onConsulting()"
                            >Consulting & Advisory</span
                          >
                        </a>
                      </div>
                      <div
                        id="collapseTen"
                        class="collapse"
                        aria-labelledby="headingTen"
                        data-parent="#accordionExample1"
                      >
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark pt-4 serviceul">
                              <li routerLink="/business-setup">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Setting up an entity in the India
                              </li>
                              <li routerLink="/global-corporate-structuring">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Global Corporate Structuring
                              </li>
                              <li routerLink="/business-plan-preparation">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Business Plan Preparation
                              </li>
                              <li routerLink="/business-valuation-service">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Business Valuation
                              </li>
                              <li routerLink="/mergers-and-acquisitions">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Mergers and Acquisitions
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->
                    <!-- card -->
                    <div
                      class="card wow fadeInLeft"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".6s"
                    >
                      <div class="card-header" id="headingElevenStartup">
                        <a
                          class="collapsed"
                          href=""
                          data-toggle="collapse"
                          data-target="#collapseElevenStartup"
                          aria-expanded="false"
                          aria-controls="collapseElevenStartup"
                        >
                          <span (click)="onStartup()"
                            >Startup Solution</span
                          >
                        </a>
                      </div>
                      <div
                        id="collapseElevenStartup"
                        class="collapse"
                        aria-labelledby="headingElevenStartup"
                        data-parent="#accordionExample1"
                      >
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark pt-4 serviceul">
                              <li routerLink="/startup-solution">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Startup Solution
                              </li>
                              <li routerLink="/fund-raising">
                                <i
                                  class="far fa-angle-double-right pt-1 pr-1"
                                ></i>
                                Fund Raise
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div style="margin-top: 1rem">
                  <h3 id="budgeting-and-variance-analysis">
                    Budgeting & Variance Analysis
                  </h3>
                  <!--<p class="mb-15 mt-20 text-dark servicep">
                      Achieve Financial Excellence with Expert Budgeting & Variance Analysis Services
                    </p>-->
                  <p class="mb-15 mt-20 text-dark servicep">
                    Budgeting and variance analysis play a pivotal role in
                    effective financial management, providing businesses with
                    the ability to plan, monitor, and control their financial
                    performance. However, developing accurate budgets and
                    analyzing variances can be challenging, particularly for
                    organizations without dedicated expertise or resources. At
                    BFAG we offer specialized budgeting and variance analysis
                    services designed to help you achieve financial excellence.
                    Our expert team can optimize your budgeting process and
                    provide valuable insights through variance analysis.
                  </p>
                </div>
                <div
                  class="about-faq-area-custom horizon-hrms gray-bg clearfix"
                >
                  <div class="container">
                    <div class="row" style="display: block">
                      <div
                        class="faq-accordion-2"
                        style="padding-bottom: 20px; padding-top: 20px"
                      >
                        <div class="accordion" id="accordionExample2">
                          <div class="col-lg-6">
                            <div
                              class="card wow fadeInLeft"
                              data-wow-duration=".1.2s"
                              data-wow-delay=".2s"
                            >
                              <div class="card-header" id="headingEleven">
                                <a
                                  class="collapsed"
                                  href=""
                                  data-toggle="collapse"
                                  data-target="#collapseEleven"
                                  aria-expanded="false"
                                  aria-controls="collapseEleven"
                                >
                                  Customized Budgeting Solutions
                                </a>
                              </div>
                              <div
                                id="collapseEleven"
                                class="collapse"
                                aria-labelledby="headingEleven"
                                data-parent="#accordionExample2"
                              >
                                <div
                                  class="card-body text-justify"
                                  style="padding-left: 20px !important"
                                >
                                  <p>
                                    Our experienced professionals understand
                                    that one size does not fit all when it comes
                                    to budgeting. We offer customized budgeting
                                    solutions tailored to your business's unique
                                    needs and objectives. Whether you require
                                    annual budgets, project budgets, or rolling
                                    forecasts, our team collaborates with you to
                                    develop comprehensive budgeting frameworks
                                    that align with your strategic goals.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <!-- card -->
                            <div
                              class="card wow fadeInLeft"
                              data-wow-duration=".1.2s"
                              data-wow-delay=".3s"
                            >
                              <div class="card-header" id="headingTwelve">
                                <a
                                  class="collapsed"
                                  href=""
                                  data-toggle="collapse"
                                  data-target="#collapseTwelve"
                                  aria-expanded="false"
                                  aria-controls="collapseTwelve"
                                >
                                  Accurate Budget Development
                                </a>
                              </div>
                              <div
                                id="collapseTwelve"
                                class="collapse"
                                aria-labelledby="headingTwelve"
                                data-parent="#accordionExample2"
                              >
                                <div
                                  class="card-body text-justify"
                                  style="padding-left: 20px !important"
                                >
                                  <p>
                                    Developing an accurate budget is the
                                    foundation of effective financial planning.
                                    Our team utilizes advanced financial
                                    modeling techniques and industry expertise
                                    to ensure precise budget development. We
                                    work closely with you to identify revenue
                                    projections, cost drivers, and key
                                    performance indicators (KPIs) that influence
                                    your financial outcomes. By leveraging our
                                    expertise, you can make informed decisions,
                                    allocate resources strategically, and plan
                                    for growth.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <!-- card -->
                            <div
                              class="card wow fadeInLeft"
                              data-wow-duration=".1.2s"
                              data-wow-delay=".4s"
                            >
                              <div class="card-header" id="headingThirteen">
                                <a
                                  class="collapsed"
                                  href=""
                                  data-toggle="collapse"
                                  data-target="#collapseThirteen"
                                  aria-expanded="false"
                                  aria-controls="collapseThirteen"
                                >
                                  Ongoing Variance Analysis
                                </a>
                              </div>
                              <div
                                id="collapseThirteen"
                                class="collapse"
                                aria-labelledby="headingThirteen"
                                data-parent="#accordionExample2"
                              >
                                <div
                                  class="card-body text-justify"
                                  style="padding-left: 20px !important"
                                >
                                  <p>
                                    Variance analysis is a powerful tool for
                                    assessing the financial performance of your
                                    business. Our services include ongoing
                                    variance analysis, where we compare actual
                                    financial results against budgeted
                                    expectations. By identifying and analyzing
                                    variances, we help you understand the root
                                    causes behind deviations and take corrective
                                    actions in a timely manner. This enables you
                                    to maintain financial control, optimize
                                    operations, and drive profitability.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <!-- card -->
                          </div>

                          <div class="col-lg-6">
                            <div
                              class="card wow fadeInLeft"
                              data-wow-duration=".1.2s"
                              data-wow-delay=".4s"
                            >
                              <div class="card-header" id="headingFourteen">
                                <a
                                  class="collapsed"
                                  href=""
                                  data-toggle="collapse"
                                  data-target="#collapseFourteen"
                                  aria-expanded="false"
                                  aria-controls="collapseFourteen"
                                >
                                  Key Performance Indicators (KPIs) Tracking
                                </a>
                              </div>
                              <div
                                id="collapseFourteen"
                                class="collapse"
                                aria-labelledby="headingFourteen"
                                data-parent="#accordionExample2"
                              >
                                <div
                                  class="card-body text-justify"
                                  style="padding-left: 20px !important"
                                >
                                  <p>
                                    Monitoring and tracking KPIs is essential
                                    for measuring the success of your business
                                    and aligning with your strategic objectives.
                                    Our budgeting and variance analysis services
                                    include the identification and tracking of
                                    relevant KPIs specific to your industry and
                                    business goals. We provide regular reports
                                    and visual representations of KPIs, enabling
                                    you to assess performance, identify trends,
                                    and make data-driven decisions.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              class="card wow fadeInLeft"
                              data-wow-duration=".1.2s"
                              data-wow-delay=".5s"
                            >
                              <div class="card-header" id="headingFifteen">
                                <a
                                  class="collapsed"
                                  href=""
                                  data-toggle="collapse"
                                  data-target="#collapseFifteen"
                                  aria-expanded="false"
                                  aria-controls="collapseFifteen"
                                >
                                  Proactive Financial Planning and Control
                                </a>
                              </div>
                              <div
                                id="collapseFifteen"
                                class="collapse"
                                aria-labelledby="headingFifteen"
                                data-parent="#accordionExample2"
                              >
                                <div
                                  class="card-body text-justify"
                                  style="padding-left: 20px !important"
                                >
                                  <p>
                                    Effective budgeting and variance analysis
                                    provide the foundation for proactive
                                    financial planning and control. By
                                    leveraging our services, you gain valuable
                                    insights into your business's financial
                                    performance, enabling you to identify
                                    potential risks and opportunities in
                                    advance. We help you implement control
                                    mechanisms, establish early warning systems,
                                    and develop contingency plans to mitigate
                                    risks and optimize financial outcomes.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <!-- card -->
                            <div
                              class="card wow fadeInLeft"
                              data-wow-duration=".1.2s"
                              data-wow-delay=".6s"
                            >
                              <div class="card-header" id="headingSixteen">
                                <a
                                  class="collapsed"
                                  href=""
                                  data-toggle="collapse"
                                  data-target="#collapseSixteen"
                                  aria-expanded="false"
                                  aria-controls="collapseSixteen"
                                >
                                  Strategic Decision-Making
                                </a>
                              </div>
                              <div
                                id="collapseSixteen"
                                class="collapse"
                                aria-labelledby="headingSixteen"
                                data-parent="#accordionExample2"
                              >
                                <div
                                  class="card-body text-justify"
                                  style="padding-left: 20px !important"
                                >
                                  <p>
                                    Accurate budgeting and variance analysis
                                    empower you to make strategic decisions with
                                    confidence. By understanding the financial
                                    impact of different scenarios and
                                    considering variances, you can make informed
                                    choices that align with your business goals.
                                    Our expert team provides comprehensive
                                    financial analysis and recommendations to
                                    support your strategic decision-making
                                    process.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <!-- card -->
                          </div>
                        </div>
                        <!-- faq accordion -->
                      </div>
                    </div>
                    <!-- row -->
                  </div>
                  <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                  Effective budgeting and variance analysis are essential
                  components of financial excellence. BFAG offers customized
                  budgeting solutions, ongoing variance analysis, and proactive
                  financial planning. By leveraging our expertise, you can
                  optimize your budgeting process, gain valuable insights
                  through variance analysis, and make informed decisions that
                  drive your business's success. Contact us today to experience
                  the benefits of our professional budgeting and variance
                  analysis.
                </p>
              </div>
            </div>
            <!-- faq accordion -->
          </div>
        </div>
        <!-- row -->
      </div>
    </div>
  </div>
</section>
