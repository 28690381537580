<!-- dubai start -->
<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/blogs/blog.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Blogs</h3>
    </div>
  </div>

  <div class="mt-50 mb-50  container" *ngFor="let blog of blogs">
    <div class="flex-column ">
      <div class="d-flex justify-content-center align-items-center">
        <img
        class="img-fluid"
        [src]="blog?.imgFile.secure_url"
        alt=""
        style="margin-bottom: 2rem; text-align: center;  "
      />
      </div>
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #418052;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >{{ blog.category }}</small
          >
        </div>
        <div>
          <div class="mt-2">
            <span
              class="text-capitalize"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #418052 !important;
              "
              >{{ blog.title }}</span
            >
          </div>
          <p class="text-dark servicep">
            {{ blog.description }}
            <a
            class="font-weight-bold"
            [routerLink]="['/blog-details', blog._id]"

            style="color: #418052"
            >Read more....</a
          >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-3">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #418052;
              "
            >
              <i class="fal fa-user"></i> By {{ blog.author }}</small
            >
            <small
                  style="
                    font-weight: 600 !important;
                    font-size: 14px !important;
                    color: #418052;
                    margin-left:1rem
                  "
                >
                <i class="fal fa-calendar"></i> {{blog.createdAt | date: 'yyyy/MM/dd'}}</small
                >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- dubai end -->

<!-- India start -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop divmart">
  <!-- <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/blogs/blog.png')no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Blogs</h3>
    </div>
  </div>
  <div class="mt-50 container mb-50">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img
        class="img-fluid"
        src="../../../../assets/images/case-studies/IT.png"
        alt=""
        style="margin-bottom: 2rem; max-height: 260px;"
      />
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #072f5f;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Business</small
          >
        </div>
        <div>
          <div class="mt-15">
            <span
              class="text-capitalize"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #002249 !important;
              "
              >India Digital Personal Data Protection Bill </span
            >
          </div>
          <p class="text-dark servicep">
            With the introduction of Digital Personal Data Protection bill in 2022 and subsequent amendments in 2023, the Indian government aims to establish a comprehensive framework for safeguarding personal data in the digital sphere. This legislation has far-reaching implications for individuals, businesses, and the overall digital ecosystem in India.
            <a
              class="font-weight-bold"
              routerLink="/first-blog"
              style="color: #072f5f"
              >Read more....</a
            >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-30">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #072f5f;
              "
            >
              <i class="fal fa-user"></i> By BFAG</small
            >
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #072f5f;
                margin-left:1rem
              "
            >
            <i class="fal fa-calendar"></i> 6/9/2023</small
            >
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/blogs/blog.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Blogs</h3>
    </div>
  </div>

  <div class="mt-25 mb-25 container" *ngFor="let blog of blogs">
    <div class="flex-column ">
      <div class="d-flex justify-content-center align-items-center">
        <img
        class="img-fluid"
        [src]="blog?.imgFile.secure_url"
        alt=""
        style="margin-bottom: 2rem; text-align: center; max-height: 320px;"
      />
      </div>
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #072f5f;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >{{ blog.category }}</small
          >
        </div>
        <div>
          <div class="mt-2">
            <span
              class="text-capitalize"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #072f5f !important;
              "
              >{{ blog.title }}</span
            >
          </div>
          <p class="text-dark servicep">
            {{ blog.description }}
            <a
            class="font-weight-bold"
            [routerLink]="['/blog-details', blog._id]"

            style="color: #072f5f"
            >Read more....</a
          >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-3">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #072f5f;
              "
            >
              <i class="fal fa-user"></i> By {{ blog.author }}</small
            >
            <small
                  style="
                    font-weight: 600 !important;
                    font-size: 14px !important;
                    color: #072f5f;
                    margin-left:1rem
                  "
                >
                <i class="fal fa-calendar"></i> {{blog.createdAt | date: 'yyyy/MM/dd'}}</small
                >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- INdia end -->

<!-- USA start -->
<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/blogs/blog.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Blogs</h3>
    </div>
  </div>

  <div class="mt-25 mb-25 container" *ngFor="let blog of blogs">
    <div class="flex-column ">
      <div class="d-flex justify-content-center align-items-center">
        <img
        class="img-fluid"
        [src]="blog?.imgFile.secure_url"
        alt=""
        style="margin-bottom: 2rem; text-align: center;  ;"
      />
      </div>
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #072f5f;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >{{ blog.category }}</small
          >
        </div>
        <div>
          <div class="mt-2">
            <span
              class="text-capitalize"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #072f5f !important;
              "
              >{{ blog.title }}</span
            >
          </div>
          <p class="text-dark servicep">
            {{ blog.description }}
            <a
            class="font-weight-bold"
            [routerLink]="['/blog-details', blog._id]"

            style="color: #072f5f"
            >Read more....</a
          >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-3">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #072f5f;
              "
            >
              <i class="fal fa-user"></i> By {{ blog.author }}</small
            >
            <small
                  style="
                    font-weight: 600 !important;
                    font-size: 14px !important;
                    color: #072f5f;
                    margin-left:1rem
                  "
                >
                <i class="fal fa-calendar"></i> {{blog.createdAt | date: 'yyyy/MM/dd'}}</small
                >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- USA end -->
