<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/consulting.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Consulting & Advisory
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                  <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev" >
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                      aria-expanded="true" aria-controls="collapseSevv" >
                      <span (click)="onAccounting()">  Accounting & Transaction Processing</span>
                      </a>
                    </div>
                    <div id="collapseSevv" class="collapse " aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div class="list-group">
                          <ul class="text-dark serviceul">

                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Receivable
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Fixed Assets Accounting
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Budgeting & Variance Analysis
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Consolidation Reporting & GAAP Conversion
                            </li>
                            <li routerLink="/small-business-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Small Business Accounting
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li routerLink="/individual-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Individual Tax Return
                            </li>
                            <li routerLink="/corporate-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Tax Return
                            </li>
                            <li routerLink="/sales-and-use-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Sales & Use Tax
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span (click)="onPayroll()">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Payroll Processing
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a  href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span (click)="onConsulting()">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse show" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Setting up an entity in the US
                            </li>
                            <li class="shli" routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Global Corporate Structuring
                            </li>
                            <li routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Plan Preparation
                            </li>
                            <li routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#business-valuation-service"> Business Valuation</a>
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div id="global-corporate-structuring" style="margin-top: 1rem">
                    <h3>Global Corporate Structuring</h3>
                    <!--<p class="mb-15 mt-20 text-dark servicep">
                      Establish Your Business Presence with Expert Entity Setup Services by BFAG
                    </p>-->
                    <p class="mb-15 mt-20 text-dark servicep">
                        We at BFAG specialize in providing comprehensive corporate global structuring services to help businesses optimize their organizational and operational structures for global growth and efficiency. BFAG helps businesses identify opportunities, mitigate risks, and optimize cross-border transactions, regulatory frameworks, and industry-specific challenges, enabling businesses to make informed decisions. Our experienced team of professionals is dedicated to supporting companies in navigating complex global structures and achieving their strategic objectives, sustainable growth in foreign markets. Here's an overview of our corporate global structuring services:
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                    Entity Formation and Registration
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p> <b>International entity selection:</b> We assist in identifying the most suitable legal structures for your global operations, considering factors such as tax efficiency, liability protection, and local regulatory requirements.</p>
                                                    <p> <b>Incorporation and registration:</b> Our experts facilitate the process of incorporating new entities and registering them with the relevant authorities in different jurisdictions.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                            <div class="card-header" id="headingTwelve">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                    Cross-Border Tax Planning
                                                </a>
                                            </div>
                                            <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p> <b>Transfer pricing strategies: </b> We help develop transfer pricing policies to ensure compliance with international tax regulations and optimize tax positions within the global organization.</p>
                                                    <p> <b>Tax treaty analysis:</b> Our team analyzes and leverages tax treaties between jurisdictions to minimize double taxation and maximize tax benefits.</p>
                                                    <p> <b>Tax optimization structures:</b> We design tax-efficient structures, such as holding companies, intellectual property (IP) holding structures, and regional headquarters, to optimize global tax liabilities.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingThirteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                    Global Legal and Regulatory Compliance
                                                </a>
                                            </div>
                                            <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p> <b>Regulatory compliance assessment: </b> We conduct comprehensive assessments of legal and regulatory requirements in different jurisdictions to ensure compliance with local laws.</p>
                                                    <p> <b>Corporate governance:</b> Our experts assist in establishing corporate governance frameworks that align with global best practices and local legal requirements.</p>
                                                    <p> <b>Compliance management: </b> We help develop compliance programs, monitor regulatory changes, and support ongoing compliance efforts across multiple jurisdictions.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-lg-6">

                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                            <div class="card-header" id="headingSixteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                    Cross-Border Transaction Support
                                                </a>
                                            </div>
                                            <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p> <b>Merger and acquisition structuring:</b> We provide guidance on structuring cross-border mergers, acquisitions, and joint ventures, considering legal, tax, and regulatory implications.</p>
                                                    <p> <b>Due diligence assistance:</b> Our team supports due diligence activities, including legal, financial, and operational due diligence, to assess risks and opportunities associated with global transactions.</p>
                                                    <p> <b>Post-transaction integration:</b> We assist in the integration of acquired entities or business units into the global corporate structure, ensuring smooth operational and financial consolidation.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                          <div class="card-header" id="headingSeventeen">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                                Intellectual Property (IP) Management
                                              </a>
                                          </div>
                                          <div id="collapseSeventeen" class="collapse " aria-labelledby="headingSeventeen" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p> <b>IP holding structures:</b> We help establish efficient IP holding structures to protect and manage intellectual property assets globally, optimizing tax and legal considerations.</p>
                                                  <p> <b>Licensing and royalty agreements:</b> Our experts assist in structuring licensing and royalty agreements to facilitate the global monetization of intellectual property assets.</p>
                                                  <p> <b>IP migration strategies:</b> We provide guidance on transferring and centralizing intellectual property rights within the global organization, aligning with business goals and legal requirements.</p>
                                              </div>
                                          </div>
                                      </div>

                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                    At BFAG, we understand the complexity and challenges associated with corporate global structuring. Our dedicated team is committed to providing you with tailored solutions that meet your unique needs and objectives. With our expertise and global perspective, we aim to optimize your organizational and operational structures for successful global expansion.
                </p>
                <p class="mb-15 mt-20 text-dark servicep">
                    Contact us today to learn more about our corporate global structuring services and how we can assist you in achieving your global growth goals.
                </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>


<!-- INDIA STARTS -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
<div class="col p-0 m-0">
  <div class="d-flex justify-content-center align-items-center bmTop banner-image "
    style="background: url('../../../../assets/images/service/consulting.png') no-repeat center;
    background-size: cover;
    height: 430px;">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase text-center">
          Consulting & Advisory
        </h3>
        <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
      </div>
  </div>
  <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
    <div class="about-faq-area-custom horizon-hrms bg_cover">


          <div >
            <div >
              <div class="row">
              <div class="col-md-4">
                <div class="faq-accordion-3 pt-0">
                  <div class="accordion" id="accordionExample1">

                <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                  <div class="card-header" id="headingSev" >
                    <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                    aria-expanded="true" aria-controls="collapseSevv" >
                     <span (click)="onAccounting()"> Accounting & Transaction Processing</span>
                    </a>
                  </div>
                  <div id="collapseSevv" class="collapse " aria-labelledby="headingSev" data-parent="#accordionExample1">
                    <div class="card-body bg-white">
                      <div class="list-group">
                        <ul class="text-dark serviceul">

                          <li  routerLink="/accounts-payable">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Accounts Payable
                          </li>
                          <li routerLink="/accounts-receivable">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Accounts Receivable
                          </li>
                          <li routerLink="/fixed-assets-accounting">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Fixed Assets Accounting
                          </li>
                          <li routerLink="/financial-reporting">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Financial Reporting
                          </li>
                          <li routerLink="/budgeting-and-variance-analysis">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Budgeting & Variance Analysis
                          </li>
                          <li routerLink="/reconciliation-services">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Reconciliation Services
                          </li>
                          <li routerLink="/consolidation-reporting-gaap-conversion">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Consolidation Reporting & GAAP Conversion
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card -->
                <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                  <div class="card-header" id="headingEight">
                    <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                      aria-expanded="false" aria-controls="collapseEight">
                      <span (click)="onTax()">Tax & Regulatory Compliances</span>
                    </a>
                  </div>
                  <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                    <div class="card-body bg-white">
                      <div>
                        <ul class="text-dark serviceul">
                          <li routerLink="/income-tax">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Income Tax
                          </li>
                          <li  routerLink="/goods-and-service-tax">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Goods & Service Tax
                          </li>
                          <li routerLink="/corporate-secretarial-compliance">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Corporate Secretarial Compliance
                          </li>
                          <li  routerLink="/fema-advisory">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           FEMA Compliance
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- card -->
                <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                  <div class="card-header" id="headingNine">
                    <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                      aria-expanded="false" aria-controls="collapseNine">
                      <span (click)="onPayroll()">Payroll Advisory</span>
                    </a>
                  </div>
                  <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                    <div class="card-body bg-white">
                      <div>
                        <ul class="text-dark pt-4 serviceul">
                          <li routerLink="/payroll-processing">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Payroll - Compensation & Benefit Management
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card -->

                <!-- card -->
                <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                  <div class="card-header" id="headingTen">
                    <a  href="" data-toggle="collapse" data-target="#collapseTen"
                      aria-expanded="false" aria-controls="collapseTen">
                      <span (click)="onConsulting()">Consulting & Advisory</span>
                    </a>
                  </div>
                  <div id="collapseTen" class="collapse show"  aria-labelledby="headingTen" data-parent="#accordionExample1">
                    <div class="card-body bg-white">
                      <div>
                        <ul class="text-dark pt-4 serviceul">
                          <li routerLink="/business-setup">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Setting up an entity in the India
                          </li>
                          <li class="shli" routerLink="/global-corporate-structuring">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Global Corporate Structuring
                          </li>
                          <li routerLink="/business-plan-preparation">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Business Plan Preparation
                          </li>
                          <li routerLink="/business-valuation-service">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Business Valuation
                          </li>
                          <li routerLink="/mergers-and-acquisitions">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Mergers and Acquisitions
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card -->
                <!-- card -->
                <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                  <div class="card-header" id="headingElevenStartup">
                    <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseElevenStartup"
                      aria-expanded="false" aria-controls="collapseElevenStartup">
                      <span (click)="onStartup()">Startup Solution</span>
                    </a>
                  </div>
                  <div id="collapseElevenStartup" class="collapse" aria-labelledby="headingElevenStartup" data-parent="#accordionExample1">
                    <div class="card-body bg-white">
                      <div>
                        <ul class="text-dark pt-4 serviceul">
                          <li routerLink="/startup-solution">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Startup Solution
                          </li>
                          <li routerLink="/fund-raising">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Fund Raise
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card -->
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div style="margin-top: 1rem">
                  <h3 id="global-corporate-structuring">Global Corporate Structuring</h3>
                  <!--<p class="mb-15 mt-20 text-dark servicep">
                    Establish Your Business Presence with Expert Entity Setup Services by BFAG
                  </p>-->
                  <p class="mb-15 mt-20 text-dark servicep">
                    We at BFAG specialize in providing comprehensive corporate global structuring services to help businesses optimize their organizational and operational structures for global growth and efficiency. BFAG helps businesses identify opportunities, mitigate risks, and optimize cross-border transactions, regulatory frameworks, and industry-specific challenges, enabling businesses to make informed decisions. Our experienced team of professionals is dedicated to supporting companies in navigating complex global structures and achieving their strategic objectives, sustainable growth in foreign markets. Here's an overview of our corporate global structuring services:
                  </p>
                </div>
                <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                  <div class="container">
                      <div class="row" style="display: block;">
                          <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                              <div class="accordion" id="accordionExample2">
                                  <div class="col-lg-6">
                                      <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                          <div class="card-header" id="headingEleven">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                Entity Formation and Registration
                                              </a>
                                          </div>
                                          <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p> <b>International entity selection: </b>We assist in identifying the most suitable legal structures for your global operations, considering factors such as tax efficiency, liability protection, and local regulatory requirements.</p>
                                                  <p> <b>Incorporation and registration:</b> Our experts facilitate the process of incorporating new entities and registering them with the relevant authorities in different jurisdictions.</p>
                                              </div>
                                          </div>
                                      </div>
                                      <!-- card -->
                                      <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                          <div class="card-header" id="headingTwelve">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                Cross-Border Tax Planning
                                              </a>
                                          </div>
                                          <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p> <b>Transfer pricing strategies: </b> We help develop transfer pricing policies to ensure compliance with international tax regulations and optimize tax positions within the global organization.</p>
                                                  <p> <b>Tax treaty analysis:</b> Our team analyzes and leverages tax treaties between jurisdictions to minimize double taxation and maximize tax benefits.</p>
                                                  <p> <b>Tax optimization structures:</b> We design tax-efficient structures, such as holding companies, intellectual property (IP) holding structures, and regional headquarters, to optimize global tax liabilities.</p>
                                              </div>
                                          </div>
                                      </div>
                                      <!-- card -->
                                      <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                          <div class="card-header" id="headingThirteen">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                  Global Legal and Regulatory Compliance
                                              </a>
                                          </div>
                                          <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p> <b>Regulatory compliance assessment: </b> We conduct comprehensive assessments of legal and regulatory requirements in different jurisdictions to ensure compliance with local laws.</p>
                                                  <p> <b>Corporate governance:</b> Our experts assist in establishing corporate governance frameworks that align with global best practices and local legal requirements.</p>
                                                  <p> <b>Compliance management: </b> We help develop compliance programs, monitor regulatory changes, and support ongoing compliance efforts across multiple jurisdictions.</p>
                                              </div>
                                          </div>
                                      </div>

                                  </div>

                                  <div class="col-lg-6">

                                      <!-- card -->
                                      <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                          <div class="card-header" id="headingSixteen">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                  Cross-Border Transaction Support
                                              </a>
                                          </div>
                                          <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p> <b>Merger and acquisition structuring:</b>We provide guidance on structuring cross-border mergers, acquisitions and joint ventures, considering legal, tax, and regulatory implications.</p>
                                                  <p> <b>Due diligence assistance:</b> Our team supports due diligence activities, including legal, financial, and operational due diligence, to assess risks and opportunities associated with global transactions.</p>
                                                  <p> <b>Post-transaction integration:</b> We assist in the integration of acquired entities or business units into the global corporate structure, ensuring smooth operational and financial consolidation.</p>
                                              </div>
                                          </div>
                                      </div>
                                      <!-- card -->
                                      <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                        <div class="card-header" id="headingSeventeen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                              Intellectual Property (IP) Management
                                            </a>
                                        </div>
                                        <div id="collapseSeventeen" class="collapse " aria-labelledby="headingSeventeen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p> <b>IP holding structures:</b> We help establish efficient IP holding structures to protect and manage intellectual property assets globally, optimizing tax and legal considerations.</p>
                                                <p> <b>Licensing and royalty agreements:</b> Our experts assist in structuring licensing and royalty agreements to facilitate the global monetization of intellectual property assets.</p>
                                                <p> <b>IP migration strategies:</b> We provide guidance on transferring and centralizing intellectual property rights within the global organization, aligning with business goals and legal requirements.</p>
                                            </div>
                                        </div>
                                    </div>

                                  </div>
                              </div>
                              <!-- faq accordion -->
                          </div>
                      </div>
                      <!-- row -->
                  </div>
                  <!-- container -->
              </div>
              <p class="mb-15 mt-20 text-dark servicep">
                At BFAG, we understand the complexity and challenges associated with corporate global structuring. Our dedicated team is committed to providing you with tailored solutions that meet your unique needs and objectives. With our expertise and global perspective, we aim to optimize your organizational and operational structures for successful global expansion.
              </p>
              <p class="mb-15 mt-20 text-dark servicep">
                Contact us today to learn more about our corporate global structuring services and how we can assist you in achieving your global growth goals.
              </p>
              </div>
            </div>
            <!-- faq accordion -->
          </div>
        </div>
        <!-- row -->

    </div>

  </div>

</div>
</section>
<!-- INDIA ENDS -->
