<!-- usa started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
          <div class="" style="padding: 1%">
            <img
              style="border-radius: 10px; max-width: 95%"
              class="img-fluid"
              src="../../../../../assets/images/teams/ganeshCircle.jpeg"
              alt="Ganesh"
            />
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start align-items-start pl-0 pr-0"
        >
          <h3
            class="text-uppercase text-dark title"
            style="font-size: 20px; font-weight: 700; padding-top: 2%"
          >
          GANESH PADAKANNAYA
          </h3>
          <div>
            <span
              class="text-uppercase text-dark"
              style="font-size: 16px; font-weight: 600"
            >
            Chief Operating Officer
            </span>
            <a
              style="color: #fff"
              href="https://www.linkedin.com/in/ganesh-padakannaya-2b739516/"
              target="_blank"
            >
              <i
                class="fab fa-linkedin-in"
                style="
                  margin-left: 10px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                "
              ></i>
            </a>
          </div>
          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
           Ganesh is a target-driven Chartered Accountant and Company Secretary with a proven track record in finance, accounting, and business advisory. A seasoned professional with over 16 years of experience.
         </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          As the COO at Boston Financial Advisory Group since December 2009, Ganesh has been instrumental in developing the M&A disposition model and successfully delivering efficient F&A processes. He has showcased his expertise in planning, organizing, and implementing strategies that drive business valuation, business deals, and business structuring. Additionally, his management consultancy skills have helped numerous clients achieve their financial goals.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Ganesh's expertise extends beyond traditional financial roles. As an International Finance Manager at a BSE-listed software company, he gained valuable experience in working with different jurisdictional GAAP and consolidation requirements. This exposure allowed him to navigate various legal setups and critically analyze business models. His knowledge of taxation and assurance has further enhanced his ability to perform meticulous due diligence practices.
          </p>

          <p
          class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
            Driven by a passion for achieving results, the focus on meticulous planning has been instrumental in his career. He holds the prestigious titles of Chartered Accountant and Law Graduate, highlighting his strong educational background and professional qualifications. With a strong focus on planning, organizing, and implementation skills, successfully provided management consultancy services to multiple clients. Have keen eye for business valuation and excels at proposing and negotiating budgets, ensuring quality and cost control within the organization.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- usa started -->


<!-- india started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
          <div class="" style="padding: 1%">
            <img
              style="border-radius: 10px; max-width: 95%"
              class="img-fluid"
              src="../../../../../assets/images/teams/ganeshCircle.jpeg"
              alt="Ganesh"
            />
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start align-items-start pl-0 pr-0"
        >
          <h3
            class="text-uppercase text-dark title"
            style="font-size: 20px; font-weight: 700; padding-top: 2%"
          >
          GANESH PADAKANNAYA
          </h3>
          <div>
            <span
              class="text-uppercase text-dark"
              style="font-size: 16px; font-weight: 600"
            >
            Chief Operating Officer
            </span>
            <a
              style="color: #fff"
              href="https://www.linkedin.com/in/ganesh-padakannaya-2b739516/"
              target="_blank"
            >
              <i
                class="fab fa-linkedin-in"
                style="
                  margin-left: 10px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                "
              ></i>
            </a>
          </div>
          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
           Ganesh is a target-driven Chartered Accountant and Company Secretary with a proven track record in finance, accounting, and business advisory. A seasoned professional with over 16 years of experience.
         </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          As the COO at Boston Financial Advisory Group since December 2009, Ganesh has been instrumental in developing the M&A disposition model and successfully delivering efficient F&A processes. He has showcased his expertise in planning, organizing, and implementing strategies that drive business valuation, business deals, and business structuring. Additionally, his management consultancy skills have helped numerous clients achieve their financial goals.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Ganesh's expertise extends beyond traditional financial roles. As an International Finance Manager at a BSE-listed software company, he gained valuable experience in working with different jurisdictional GAAP and consolidation requirements. This exposure allowed him to navigate various legal setups and critically analyze business models. His knowledge of taxation and assurance has further enhanced his ability to perform meticulous due diligence practices.
          </p>

          <p
          class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
            Driven by a passion for achieving results, the focus on meticulous planning has been instrumental in his career. He holds the prestigious titles of Chartered Accountant and Law Graduate, highlighting his strong educational background and professional qualifications. With a strong focus on planning, organizing, and implementation skills, successfully provided management consultancy services to multiple clients. Have keen eye for business valuation and excels at proposing and negotiating budgets, ensuring quality and cost control within the organization.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- india started -->

<!-- Dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
          <div class="" style="padding: 1%">
            <img
              style="border-radius: 10px; max-width: 95%"
              class="img-fluid"
              src="../../../../../assets/images/teams/ganeshCircle.jpeg"
              alt="Ganesh"
            />
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start align-items-start pl-0 pr-0"
        >
          <h3
            class="text-uppercase text-dark title"
            style="font-size: 20px; font-weight: 700; padding-top: 2%"
          >
          GANESH PADAKANNAYA
          </h3>
          <div>
            <span
              class="text-uppercase text-dark"
              style="font-size: 16px; font-weight: 600"
            >
            Chief Operating Officer
            </span>
            <a
              style="color: #fff"
              href="https://www.linkedin.com/in/ganesh-padakannaya-2b739516/"
              target="_blank"
            >
              <i
                class="fab fa-linkedin-in"
                style="
                  margin-left: 10px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                "
              ></i>
            </a>
          </div>
          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
           Ganesh is a target-driven Chartered Accountant and Company Secretary with a proven track record in finance, accounting, and business advisory. A seasoned professional with over 16 years of experience.
         </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          As the COO at Boston Financial Advisory Group since December 2009, Ganesh has been instrumental in developing the M&A disposition model and successfully delivering efficient F&A processes. He has showcased his expertise in planning, organizing, and implementing strategies that drive business valuation, business deals, and business structuring. Additionally, his management consultancy skills have helped numerous clients achieve their financial goals.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Ganesh's expertise extends beyond traditional financial roles. As an International Finance Manager at a BSE-listed software company, he gained valuable experience in working with different jurisdictional GAAP and consolidation requirements. This exposure allowed him to navigate various legal setups and critically analyze business models. His knowledge of taxation and assurance has further enhanced his ability to perform meticulous due diligence practices.
          </p>

          <p
          class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
            Driven by a passion for achieving results, the focus on meticulous planning has been instrumental in his career. He holds the prestigious titles of Chartered Accountant and Law Graduate, highlighting his strong educational background and professional qualifications. With a strong focus on planning, organizing, and implementation skills, successfully provided management consultancy services to multiple clients. Have keen eye for business valuation and excels at proposing and negotiating budgets, ensuring quality and cost control within the organization.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Dubai started -->
