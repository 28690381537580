<!-- dubai start -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center text-uppercase"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      Business Plans
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0 text-uppercase" routerLink="/startup-solution"
        >Home <i class="fas fa-arrow-circle-right"></i> Business Plans</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 justify-content-center align-items-center imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 420px; height: 100px"
      >
        <img
          src="../../../../assets/images/cpa solutions/accounts.png"
          class="img-fluid"
          style="max-width: 350px; position: relative; top: 12rem"
        />
        <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        For a startup, a good business plan is extremely essential as it can act
        as a roadmap for the company as well as provide information in a
        systematic format to promoters, investors, and financial advisors. A
        business plan must contain all the elements necessary to determine the
        success of the enterprise.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Benefits of having a sound business plan:
      </p>
      <ul class="mt-10">
        <li
          class="mb-2 text-dark"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: green"></i>
          Determining whether the idea can be successful
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: green"></i>
          Getting financing from Angels, Venture Capitalists, and Institutions
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: green"></i>
          Setting priorities
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: green"></i>
          Ensuring proper attention
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: green"></i>
          Managing change
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: green"></i>
          Formulating a strategy
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: green"></i>
          Review the mechanism
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: green"></i>
          Fund Management
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- dubai end -->

<!-- india start -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center text-uppercase"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      Business Plans
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0 text-uppercase" routerLink="/startup-solution"
        >Home <i class="fas fa-arrow-circle-right"></i> Business Plans</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 justify-content-center align-items-center imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 420px; height: 100px"
      >
        <img
          src="../../../../assets/images/cpa solutions/accounts.png"
          class="img-fluid"
          style="max-width: 350px; position: relative; top: 12rem"
        />
        <!-- <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a> -->
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        For a startup, a good business plan is extremely essential as it can act
        as a roadmap for the company as well as provide information in a
        systematic format to promoters, investors, and financial advisors. A
        business plan must contain all the elements necessary to determine the
        success of the enterprise.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Benefits of having a sound business plan:
      </p>
      <ul class="mt-10">
        <li
          class="mb-2 text-dark"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: #0951A4"></i>
          Determining whether the idea can be successful
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: #0951A4"></i>
          Getting financing from Angels, Venture Capitalists, and Institutions
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: #0951A4"></i>
          Setting priorities
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: #0951A4"></i>
          Ensuring proper attention
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: #0951A4"></i>
          Managing change
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: #0951A4"></i>
          Formulating a strategy
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: #0951A4"></i>
          Review the mechanism
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: #0951A4"></i>
          Fund Management
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- india end -->
