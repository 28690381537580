<!-- Dubai start -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      GLOBAL REPORTING
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> GLOBAL REPORTING</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/order to cash.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 14rem"
        />
        <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Reporting is a tool which in the hands of right people will help the
        organizations to reach their goal in an efficient manner. Timely and
        accurate information is the key to quick and efficient decision making.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        We at BFAG provide a customised solution based on the needs and current
        dynamics. We also design and advise on the type of reports which an
        organization must see/get at a predetermined frequency.
      </p>
      <div>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Consolidation Reports
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Financial Performance Analysis
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Financial and Operational KPI’s Reporting
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Segment-wise Reporting
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Variance Analysis
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Statutory Compliance status
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- Dubai End -->

<!-- India start -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951a4; display: inline-block"
    >
      GLOBAL REPORTING
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> GLOBAL REPORTING</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951a4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/order to cash.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 14rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Reporting is a tool which in the hands of right people will help the
        organizations to reach their goal in an efficient manner. Timely and
        accurate information is the key to quick and efficient decision making.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        We at BFAG provide a customised solution based on the needs and current
        dynamics. We also design and advise on the type of reports which an
        organization must see/get at a predetermined frequency.
      </p>
      <div>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951a4"></i>
            Consolidation Reports
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951a4"></i>
            Financial Performance Analysis
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951a4"></i>
            Financial and Operational KPI’s Reporting
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951a4"></i>
            Segment-wise Reporting
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951a4"></i>
            Variance Analysis
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951a4"></i>
            Statutory Compliance status
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- India End -->

<!-- USA start -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951a4; display: inline-block"
    >
      GLOBAL REPORTING
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> GLOBAL REPORTING</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951a4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/order to cash.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 14rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Reporting is a tool which in the hands of right people will help the
        organizations to reach their goal in an efficient manner. Timely and
        accurate information is the key to quick and efficient decision making.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        We at BFAG provide a customised solution based on the needs and current
        dynamics. We also design and advise on the type of reports which an
        organization must see/get at a predetermined frequency.
      </p>
      <div>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951a4"></i>
            Consolidation Reports
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951a4"></i>
            Financial Performance Analysis
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951a4"></i>
            Financial and Operational KPI’s Reporting
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951a4"></i>
            Segment-wise Reporting
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951a4"></i>
            Variance Analysis
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951a4"></i>
            Statutory Compliance status
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- USA End -->
