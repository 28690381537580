<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'"  class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing min-vh-100 mt-130" >
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="experience-item">
                  <h2 class="title pb-15">FAQ on Setting Up an Entity in the USA</h2>
              </div>
              <div class="faq-accordion-3 pt-0">
                  <div class="accordion" id="accordionExample">
                      <div class="card">
                          <div class="card-header" id="headingOne">
                              <a class="w-100" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                  What are the different types of business entities in the USA?
                              </a>
                          </div>
                          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">In USA, common types of business entities include:</p>
                                  <p><b>a) Sole Proprietorship: </b></p>
                                  <p>A sole proprietorship is the simplest and most common structure chosen to start a business. A business owned and operated by one individual. A sole proprietor is someone who owns an unincorporated business by himself or herself. The owner is personally responsible for all business debts.</p>
                                      <br>
                                  <p><b>b)  Partnership: </b></p>
                                  <p>A partnership exists when two or more persons co-own a business and share in the profits and losses of the business. Each of the co-owners or partners contribute something, usually money or real property, to the business endeavour.  </p>
                                      <br>
                                  <p><b>c) Corporation:</b></p>
                                  <p>A corporation is an independent legal entity that exists separately from the people who own, control and manage it. It does not dissolve when its owners (or shareholders) die because it is considered a separate “person.” A corporation can enter into contracts, pay taxes, transact business, etc. The owners have limited liability. A legal entity separates from its owners, offering limited liability protection.</p>
                                      <br>
                                  <p><b>d) Limited Liability Company (LLC): </b></p>
                                  <p>This is not a partnership or a corporation but rather is a distinct type of entity that has the powers of both a corporation and a partnership. Depending on how the LLC is structured, it may be likened to a general partnership with limited liability, to a limited partnership where all the owners are free to participate in the management and all have limited liability, or to an “S” corporation without the ownership and tax restrictions imposed by the Internal Revenue Code.</p>
                                  <br>
                                  <ul >
                                      <li><p><b>Difference between S’ corp. and C’ corp:</b></p>
                                          <p>C-Corp: For federal tax purposes, C corporation profits are taxed, and are reported on the corporation tax return. Any after-tax profits distributed to shareholders as dividends are taxed again (double taxation) and are reported by the shareholders on their personal tax returns.</p>
                                          <p> S-Corp: An S corp. is treated like a sole proprietorship or a partnership. The profits (or losses) are passed through the S corp. to the shareholders and are only taxed to the shareholders and reported on their personal tax returns. </p></li>
                                  </ul>


                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingTwo">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                  What is the process for setting up a business entity in the USA?
                              </a>
                          </div>
                          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">The process for setting up a business entity in the USA typically involves the following steps:</p>
                                  <p>a) Choose a business name and check its availability.</p>
                                  <p>b) Determine the state where you want to establish your entity.</p>
                                  <p>c) Register your business with the state's Secretary of State or appropriate agency.</p>
                                  <p>d) Obtain an Employer Identification Number (EIN) from the Internal Revenue Service (IRS).</p>
                                  <p>e) Comply with any additional state or local requirements, such as obtaining licenses or permits.</p>
                                  <p class="pb-15">f) Consider consulting with an attorney or business advisor for guidance throughout the process.</p>

                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingThree">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                  What are the resources available to help with setting up a business entity in the USA?
                              </a>
                          </div>
                          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">Several resources are available to assist with setting up a business entity in the USA, including:</p>
                                  <p>a) State-specific websites and Secretary of State offices.</p>
                                  <p>b) Small Business Administration (SBA) and its local offices.</p>
                                  <p>c) Local chambers of commerce or business development centres.</p>
                                  <p class="pb-15">d) Legal and business consulting services specializing in entity formation.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingFour">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                  Where should I register my Company in USA?
                              </a>
                          </div>
                          <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p><b>US Residents</b></p>
                                  <p>If you are a US resident, your best bet is to register in the state where you live and work, rather than Wyoming or Delaware, where you do not operate in. If, on the other hand, you want to prepare your startup for Angel and VC investments, we recommend that you incorporate your company as a corporation in Delaware and foreign qualify the business in the state where you conduct business. Normally, this refers to the state where the founders are located and is often the home of one of the founders.</p>
                                  <br>
                                  <p><b>Non-US Residents</b></p>
                                  <p class="pb-15">If you are a non-US resident and do not have any US residents on your team, your best option is to either incorporate in Delaware or Wyoming. If you plan to raise funds from angel investors or venture capitalists, you will need to incorporate a Corporation in Delaware instead of Wyoming.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingFive">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                  Which is the best State to register in USA?
                              </a>
                          </div>
                          <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">Delaware and Wyoming are the top two choices for non-US founders all over the world.
                                      Merits of incorporating an entity in Delaware –
                                  </p>
                                  <p><b>1. Easy and Reliable </b></p>
                                  <ul>
                                      <li>Delaware law has been tested and provides predictability. </li>
                                      <li>Delaware regularly updates its laws to remain the leading state for incorporation.  </li>
                                      <li>Delaware has a separate Court of Chancery that handles Corporation law cases quickly and competently. </li>
                                  </ul>
                                  <br>
                                  <p><b>2. Lower Costs </b></p>
                                  <ul>
                                      <li>The Delaware Corporation minimum annual franchise tax is $175, and the filing fee is $50. </li>
                                      <li>The Delaware LLC annual fee is $300, regardless of capitalization. </li>
                                      <li>Delaware does not tax intangible property such as leases or royalties (the “Delaware loophole”).  </li>
                                  </ul>
                                  <br>
                                  <p><b>3. Protection </b></p>
                                  <ul class="pb-15">
                                      <li>Piercing the corporate veil or making individuals personally liable for claims against their business, is very unlikely in Delaware. </li>
                                      <li>Delaware explicitly makes the charging order the sole and exclusive remedy of creditors of LLC members, meaning that creditors of members can only receive distributions from the LLC, and not control foreclosure, or liquidation.</li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingSex">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSex" aria-expanded="false" aria-controls="collapseSex">
                                  What are the requirements to register a Company in Delaware?
                              </a>
                          </div>
                          <div id="collapseSex" class="collapse" aria-labelledby="headingSex" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> Delaware is a popular state among foreign businesses that are planning to do business in the USA. Delaware formation requirements for starting a company in Delaware is as follows:</p>

                                  <p>
                                      <b>1. Hire a Delaware registered agent:</b>
                                  </p>
                                  <p>
                                      A registered agent can be an individual or business entity. The registered agent will help your company in receiving important information such as official government notification, legal documents, tax forms, and others related to lawsuits. It will represent your business’s point of contact with the state without the need of you being present at the company which allows you to operate the company outside the USA.
                                  </p>
                                  <br>
                                  <p>
                                      <b>2. Provide the Name the company: </b>
                                  </p>
                                  <p>
                                      Choose 3 different names and make sure that these names are still available. After that, the business owner submits the business’s names to the Delaware Government, and it will automatically belong to your business after the state approved.
                                  </p>
                                  <br>
                                  <p>
                                      <b>3. File Certificate of Incorporation: </b>
                                  </p>
                                  <p>
                                      To register an entity in Delaware, the business owners need to file the Certificate of Incorporation with the Secretary of State. It can be done online or mail to the Delaware Division of Corporation’s website.
                                  </p>
                                  <br>
                                  <p>
                                      <b>4. Obtain an EIN: </b>
                                  </p>
                                  <p class="pb-15">
                                      The Delaware company requires to obtain Employer Identification Number (EIN) to file taxations and pay taxes later.
                                  </p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingSeven">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                  Do I need SSN (Social Security Number) of the officer to apply for EIN?
                              </a>
                          </div>
                          <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">EIN can be applied online on IRS portal if any director or officer is having valid SSN. However, if SSN is not available with any officer, EIN can be applied for by filling and submitting Form SS-4 physically (via courier / post) with IRS. Usually it takes around 3-5 weeks to get the EIN if applied physically.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingEight">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                  What is the Information Required for Incorporation of a corporation?
                              </a>
                          </div>
                          <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p>1. Proposed Name of the Entity (At least 2 options, preference wise) </p>
                                      <p>2. Name and address of Directors </p>
                                      <p>3. Name, address, and designation of Officers</p>
                                      <p>4. Address of Principal Place of business </p>
                                      <p>5. Identity Proof (Passport) of Directors and Officers</p>
                                      <p>6. Share capital of the Entity and value of each share</p>
                                  <p class="pb-15">7. SSN of any one of the Directors/Officers, if any.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingNine">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                  How long does it take to set up a business entity in the USA?
                              </a>
                          </div>
                          <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">The time required to set up a business entity in the USA can vary depending on various factors, such as the type of entity, the state of formation, and the completeness of the application. Generally, it can take a few weeks to several months to complete the entire process.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingTen">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                  Can I change the type of business entity after it is formed?
                              </a>
                          </div>
                          <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">In many cases, it is possible to change the type of business entity after it is formed. This process is often referred to as "entity conversion" or "entity restructuring." However, the specific procedures and requirements for changing entity types may vary by state.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                        <!-- card -->
                        <div class="card">
                          <div class="card-header" id="headingElev">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseElev" aria-expanded="false" aria-controls="collapseElev">
                                  What are the ongoing compliance requirements for business entities in the USA?
                              </a>
                          </div>
                          <div id="collapseElev" class="collapse" aria-labelledby="headingElev" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> Business entities in the USA have ongoing compliance requirements, which may include:</p>
                                  <p>1. Filing annual reports or statements with the state.</p>
                                  <p>2. Paying state and federal taxes and maintaining proper accounting records.</p>
                                  <p>3. Holding regular meetings and documenting minutes for corporations.</p>
                                  <p>4. Complying with any specific industry regulations or licensing requirements.</p>
                                  <p class="pb-15">5.	Updating and renewing licenses or permits as necessary.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                         <!-- card -->
                         <div class="card">
                          <div class="card-header" id="headingTlve">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTlve" aria-expanded="false" aria-controls="collapse">
                                  Do I need to visit US for incorporation of the Corporation?
                              </a>
                          </div>
                          <div id="collapseTlve" class="collapse" aria-labelledby="headingTlve" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">You don't have to physically visit the United States. When it comes to creating a company, we do everything remotely. This means that you can set up a company from wherever in the world.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                       <!-- card -->
                       <div class="card">
                        <div class="card-header" id="headingTeen">
                            <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTeen" aria-expanded="false" aria-controls="collapseTeen">
                               Can a foreign individual or company set up a business entity in the USA?
                            </a>
                        </div>
                        <div id="collapseTeen" class="collapse" aria-labelledby="headingTeen" data-parent="#accordionExample">
                            <div class="card-body">
                                <p class="pb-15"> Yes, foreign individuals and companies can set up a business entity in the USA. However, there may be additional requirements and considerations, such as obtaining the necessary visas or permits, appointing a registered agent, and complying with specific regulations for foreign-owned entities. Individuals residing in or citizens of the following countries, however, will be unable to use our service: Cuba, Iran, North Korea, and Syria.</p>
                            </div>
                        </div>
                    </div>
                    <!-- card -->
                    <!-- card -->
                    <div class="card">
                      <div class="card-header" id="headingFrTeen">
                          <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFrTeen" aria-expanded="false" aria-controls="collapseFrTeen">
                              Why should I start my company in the US?
                          </a>
                      </div>
                      <div id="collapseFrTeen" class="collapse" aria-labelledby="headingFrTeen" data-parent="#accordionExample">
                          <div class="card-body">
                              <p class="pb-15">Access to U.S.-based customers, venture capital, tax benefits, banks, accelerators, payment providers and lines of credit are among the benefits of starting a business in the United States. American customers also have a high level of comfort purchasing digital and physical products from U.S.-based companies.</p>
                          </div>
                      </div>
                  </div>
                  <!-- card -->
                  <!-- card -->
                  <div class="card">
                      <div class="card-header" id="headingFvTeen">
                          <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFvTeen" aria-expanded="false" aria-controls="collapseFvTeen">
                              Will I have a US Address?
                          </a>
                      </div>
                      <div id="collapseFvTeen" class="collapse" aria-labelledby="headingFvTeen" data-parent="#accordionExample">
                          <div class="card-body">
                              <p class="pb-15">The registered agent address that comes with the plan serves as your US Mailing Address. You are entitled to receive and scan up to 10 regular mails per year. Mail from the state does not count against this quota because the Registered Agent is required by law to accept mail from state government on your behalf.</p>
                          </div>
                      </div>
                  </div>
                  <!-- card -->
                    </div>
              </div>
              <!-- faq accordion -->
          </div>
      </div>
      <!-- container -->
  </div>
</div>
<!-- USA end -->
