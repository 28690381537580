<!--====== HEADER Dubai start======-->
<!-- <header
  *ngIf="selectCountryName == 'Dubai'"
  class="header-area header-area-2 header-area-4"
>
  <div class="header-top pl-30 pr-30 black-bg">
    <div class="row align-items-center">
      <div class="col-md-8 col-sm-7">
        <div class="header-left-side text-center text-sm-left">
          <ul>
            <li>
              <a href="mailto:info@bostonfagroup.com"
                ><i class="fal fa-envelope"></i> info@bostonfagroup.com</a
              >
            </li>
            <li>
              <a href="tel:+ 1-408-533-1050"
                ><i class="fal fa-phone"></i> + 1-408-533-1050</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 col-sm-5">
        <div class="header-right-social text-center text-sm-right">
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/BostonFinancialAdvisoryGroup/"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
            <li>
              <a target="_blank" href="https://twitter.com/BostonfaGroup"
                ><i class="fab fa-twitter"></i
              ></a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/boston-financial-advisory-group/"
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="header-nav dubai">
    <div class="navigation">
      <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" routerLink="/" style="max-width: 216px;"
          ><img
            src="../../../../assets/images/BFAG_logo.png"
            alt="Frontline ERP Solutions"
        /></a>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="toggler-icon"></span>
          <span class="toggler-icon"></span>
          <span class="toggler-icon"></span>
        </button>

        <div
          class="dubiaToggler collapse navbar-collapse sub-menu-bar"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav ml-auto">

            <li class="nav-item dubaiItem" routerLinkActive="active">
              <a class="nav-link dubaiLink">About Us</a>
              <ul class="sub-menu scroll-navbar-mob" >
                <li>
                  <a
                    routerLink="/why-bfag"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Why BFAG</a>
                    <a
                     routerLink="/why-bfag"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    [routerLinkActiveOptions]="{ exact: true }"
                     id="mob-view"
                     >Why BFAG
                    </a>

                </li>
                <li>
                  <a
                    routerLink="/our-team"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Our Team</a>
                  <a
                    routerLink="/our-team"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Our Team
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/mission-and-vision"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Mission and Vision</a>
                  <a
                    routerLink="/mission-and-vision"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Mission and Vision</a
                  >
                </li>
                <li><a
                  routerLink="/client-speaks"
                  #solutions="routerLinkActive"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  id="desk-view"
                  >Client Speaks</a>
                  <a

                    routerLink="/client-speaks"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Client Speaks</a
                  >
                </li>
                <li><a
                  routerLink="/how-we-do-it"
                  #solutions="routerLinkActive"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  id="desk-view"
                  >How We Do It</a>
                  <a
                    routerLink="/how-we-do-it"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >How We Do It</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dubaiItem" routerLinkActive="active">
              <a (click)="redirectToOtherPage()" class="nav-link dubaiLink" id="desk-view">Services </a>
              <a (click)="redirectToOtherPage()" class="nav-link dubaiLink"
              data-toggle="collapse"
              data-target="#navbarSupportedContent" id="mob-view">Services </a>
              <ul class="sub-menu scroll-navbar-mob" style="width: 20rem !important;">
                <li>
                  <a
                    routerLink="/accounting-and-transition-processing"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="mainBtnOpton1"
                    ><div id="desk-view">
                      Accounting & Transaction Processing</div>
                  </a>
                  <a
                    routerLink="/accounting-and-transition-processing"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="mainBtnOpton1"
                    ><div id="mob-view" data-toggle="collapse"
                    data-target="#navbarSupportedContent">
                    Accounting & Transaction Processing</div>
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/tax-regulatory-compliances"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="mainBtnOpton1"
                    ><div id="desk-view">
                    Tax & Regulatory Compliances</div>
                  </a>
                  <a
                    routerLink="/tax-regulatory-compliances"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="mainBtnOpton1"
                    ><div id="mob-view" data-toggle="collapse"
                    data-target="#navbarSupportedContent">
                    Tax & Regulatory Compliances</div>
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/audit-and-assurance-support"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="mainBtnOpton1"
                    ><div id="desk-view">
                    Audit & Assurance Support</div></a>
                  <a
                    routerLink="/audit-and-assurance-support"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="mainBtnOpton1"
                    ><div id="mob-view" data-toggle="collapse"
                    data-target="#navbarSupportedContent">
                    Audit & Assurance Support</div></a>
                </li>
                <li>
                  <a
                  routerLink="/consulting-and-advisory-services"
                  #solutions="routerLinkActive"
                  routerLinkActive="active"
                  id="mainBtnOpton1"
                  ><div id="desk-view">
                  Consulting & Advisory</div></a>
                  <a
                    routerLink="/consulting-and-advisory-services"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="mainBtnOpton1"
                    ><div id="mob-view" data-toggle="collapse"
                    data-target="#navbarSupportedContent">
                    Consulting & Advisory</div></a>
                </li>
              </ul>
            </li>
          <li  class="nav-item dubaiItem" routerLinkActive="active" routerLink="/cpa-back-office">
            <a class="nav-link dubaiLink" id="desk-view">CPA Back-Office</a>
            <a class="nav-link dubaiLink" id="mob-view" data-toggle="collapse"
            data-target="#navbarSupportedContent" >CPA Back-Office</a>
          </li>
            <li class="nav-item dubaiItem" routerLinkActive="active">
              <a class="nav-link dubaiLink" >Products</a>

              <ul class="sub-menu scroll-navbar-mob">
                <li>
                  <a
                    routerLink="/compliance-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >COMPLIANCE iNBOX
                  </a> <a
                  routerLink="/compliance-inbox"
                  #solutions="routerLinkActive"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  id="mob-view"
                  >COMPLIANCE iNBOX
                </a>
                </li>
                <li>
                  <a
                  routerLink="/expense-inbox"
                  #solutions="routerLinkActive"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  id="desk-view"
                  >EXPENSE iNBOX
                </a>
                  <a
                    routerLink="/expense-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >EXPENSE iNBOX
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/analytics-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >ANALYTICS iNBOX</a
                  >
                  <a
                    routerLink="/analytics-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >ANALYTICS iNBOX</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dubaiItem" routerLinkActive="active">
              <a class="nav-link dubaiLink" >Info Centre</a>

              <ul class="sub-menu scroll-navbar-mob">
                <li>
                  <a
                    routerLink="/newsletter"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Newsletters
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/case-studies"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Case Studies
                  </a>
                  <a
                    routerLink="/case-studies"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Case Studies
                  </a>
                </li>

                <li>
                  <a
                  routerLink="/careers"
                  #solutions="routerLinkActive"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  id="desk-view"
                  >Careers</a
                >
                  <a
                    routerLink="/careers"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Careers</a
                  >
                </li>

                <li>
                  <a
                  routerLink="/media-hits"
                  #solutions="routerLinkActive"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  id="desk-view"
                  >Media Hits</a
                >
                  <a
                    routerLink="/media-hits"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Media Hits</a
                  >
                </li>
                <li>
                  <a
                  routerLink="/blogs"
                  #solutions="routerLinkActive"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  id="desk-view"
                  >Blogs</a
                >

                </li>
              </ul>
            </li>
            <li  class="nav-item dubaiItem" routerLinkActive="active" routerLink="/contact-us">
              <a class="nav-link dubaiLink" id="desk-view">Contact</a>
              <a class="nav-link dubaiLink" data-toggle="collapse"
              data-target="#navbarSupportedContent" id="mob-view">Contact</a>
            </li>
          </ul>
        </div>

        <div class="bar-area d-none d-xl-block pos-rel" style="display: none">
          <div *ngIf="searchDiv" class="w-search-form-row">
            <div class="w-search-input">
              <input
                type="search"
                class="field searchform-s"
                name="s"
                placeholder="Search..."
              />
              <button (click)="search()" class="search-btn">
                <i class="fal fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <a
          class="lg-none btn-alignment"
          (click)="openDialog()"
          style="display: none"
          ><i class="fal fa-envelope"></i
        ></a>
        <div class="navbar-btn mr-100 contactUs-btn">
          <a
           (click)="openDialog()"
            routerLinkActive="active"
            class="text-white btn ml-5 rounded d-sm-mobile"
            style="background: #418052;"

            >Request For Info</a
          >
        </div>

        <div class="dropdown">
          <button
            class="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src="../../../../assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png" alt="" style="width: 3rem" />
          </button>
          <div
            class="pl-2 m-0 dropdown-menu"
            aria-labelledby="dropdownMenuButton"
          >

          <a
              class="p-0 dropdown-item countryFlag"
              (click)="selectCountry('USA')"
            >
              <span>USA</span>
            </a>
             <a
              class="p-0 dropdown-item countryFlag"
              (click)="selectCountry('India')"
            >
              <span>India</span>
            </a>
            <a
            class="p-0 dropdown-item countryFlag active"
            (click)="selectCountry('Dubai')"
          >
            <span style="color: black;">UAE</span>
          </a>
          </div>
        </div>
      </nav>
    </div>

  </div>
</header> -->
<!--====== HEADER Dubai ENDS ======-->

<!--====== HEADER India start======-->
<header
  *ngIf="selectCountryName == 'India'"
  class="header-area header-area-2 header-area-4"
>
  <div class="header-top pl-30 pr-30 black-bg">
    <div class="row align-items-center">
      <div class="col-md-8 col-sm-7">
        <div class="header-left-side text-center text-sm-left">
          <ul>
            <li>
              <a href="mailto:info@bostonfagroup.com"
                ><i class="fal fa-envelope"></i> info@bostonfagroup.com</a
              >
            </li>
            <li>
              <a href="tel:+ 1-408-533-1050"
                ><i class="fal fa-phone"></i> + 1-408-533-1050</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 col-sm-5">
        <div class="header-right-social text-center text-sm-right">
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/BostonFinancialAdvisoryGroup/"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
            <li>
              <a target="_blank" href="https://twitter.com/BostonfaGroup"
                ><i class="fab fa-twitter"></i
              ></a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/boston-financial-advisory-group/"
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="header-nav india">
    <div class="navigation">
      <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" routerLink="/" style="max-width: 216px"
          ><img
            src="../../../../assets/images/BFAG_logo.png"
            alt="Frontline ERP Solutions"
        /></a>
        <!-- logo -->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="toggler-icon"></span>
          <span class="toggler-icon"></span>
          <span class="toggler-icon"></span>
        </button>
        <!-- navbar toggler -->
        <div
          class="indiaToggler collapse navbar-collapse sub-menu-bar"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav ml-auto">
            <!-- <li class="nav-item" routerLink="/home" routerLinkActive="active">
              <a class="nav-link">Home </a>
            </li> -->
            <li class="nav-item indiaItem" routerLinkActive="active">
              <a class="nav-link indiaLink">About Us</a>
              <ul class="sub-menu scroll-navbar-mob">
                <li>
                  <a
                    routerLink="/why-bfag"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Why BFAG
                  </a>
                  <a
                    routerLink="/why-bfag"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Why BFAG
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/our-team"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Our Team
                  </a>
                  <a
                    routerLink="/our-team"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Our Team
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/mission-and-vision"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Mission and Vision</a
                  >
                  <a
                    routerLink="/mission-and-vision"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Mission and Vision</a
                  >
                </li>
                <li>
                  <a
                    routerLink="/client-speaks"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Client Speaks</a
                  >
                  <a
                    routerLink="/client-speaks"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Client Speaks</a
                  >
                </li>
                <li>
                  <a
                    routerLink="/how-we-do-it"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >How We Do It</a
                  >
                  <a
                    routerLink="/how-we-do-it"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >How We Do It</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item indiaItem" routerLinkActive="active">
              <a (click)="redirectToOtherPage()" class="nav-link" id="desk-view"
                >Services
              </a>
              <a
                (click)="redirectToOtherPage()"
                class="nav-link indiaLink"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                id="mob-view"
                >Services
              </a>
              <ul
                class="sub-menu scroll-navbar-mob"
                style="width: 20rem !important"
              >
                <li>
                  <a
                    routerLink="/accounting-and-transition-processing"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="mainBtnOpton1"
                    ><div id="desk-view">
                      Accounting & Transaction Processing
                    </div>
                  </a>
                  <a
                    routerLink="/accounting-and-transition-processing"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="mainBtnOpton1"
                    ><div
                      id="mob-view"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                    >
                      Accounting & Transaction Processing
                    </div></a
                  >
                </li>
                <li>
                  <a
                    routerLink="/tax-regulatory-compliances"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="desk-view"
                    >Tax & Regulatory Compliances </a
                  ><a
                    routerLink="/tax-regulatory-compliances"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Tax & Regulatory Compliances
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/payroll-advisory"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="desk-view"
                    >Payroll Advisory</a
                  ><a
                    routerLink="/payroll-advisory"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Payroll Advisory</a
                  >
                </li>
                <li>
                  <a
                    routerLink="/consulting-and-advisory-services"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="desk-view"
                    >Consulting & Advisory</a
                  >
                  <a
                    routerLink="/consulting-and-advisory-services"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Consulting & Advisory</a
                  >
                </li>
                <li>
                  <a
                    routerLink="/startup-solution-services"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="desk-view"
                    >Startup Solution</a
                  >
                  <a
                    routerLink="/startup-solution-services"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Startup Solution</a
                  >
                </li>
              </ul>
            </li>
            <li
              class="nav-item"
              routerLinkActive="active"
              routerLink="/cpa-back-office"
            >
              <a class="nav-link" id="desk-view">CPA Back-Office</a>
              <a
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                id="mob-view"
              >
                CPA Back-Office</a
              >
            </li>
            <li class="nav-item indiaItem" routerLinkActive="active">
              <a class="nav-link indiaLink">Products</a>
              <ul class="sub-menu scroll-navbar-mob">
                <li>
                  <a
                    routerLink="/compliance-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >COMPLIANCE iNBOX </a
                  ><a
                    routerLink="/compliance-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >COMPLIANCE iNBOX
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/expense-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >EXPENSE iNBOX </a
                  ><a
                    routerLink="/expense-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >EXPENSE iNBOX
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/analytics-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >ANALYTICS iNBOX</a
                  >
                  <a
                    routerLink="/analytics-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >ANALYTICS iNBOX</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item indiaItem" routerLinkActive="active">
              <a class="nav-link indiaLink">Info Centre</a>
              <ul class="sub-menu scroll-navbar-mob">
                <li>
                  <a
                    routerLink="/newsletter"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Newsletters
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/case-studies"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Case Studies </a
                  ><a
                    routerLink="/case-studies"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Case Studies
                  </a>
                </li>
                <!-- <li>
                  <a
                    routerLink="/blogs"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Blogs</a
                  >
                </li> -->
                <li>
                  <a
                    routerLink="/careers"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Careers</a
                  ><a
                    routerLink="/careers"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Careers</a
                  >
                </li>
                <!-- <li>
                  <a
                    routerLink="/fraud-alert"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Fraud Alert</a
                  >
                </li> -->
                <li>
                  <a
                    routerLink="/media-hits"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Media Hits</a
                  ><a
                    routerLink="/media-hits"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Media Hits</a
                  >
                </li>
                <li>
                  <a
                    routerLink="/blogs"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Blogs</a
                  >
                </li>
              </ul>
            </li>
            <li
              class="nav-item"
              routerLinkActive="active"
              routerLink="/contact-us"
            >
              <a class="nav-link" id="desk-view">Contact</a>
              <a
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                id="mob-view"
                >Contact</a
              >
            </li>
          </ul>
        </div>
        <!-- navbar collapse -->
        <div class="bar-area d-none d-xl-block pos-rel" style="display: none">
          <div *ngIf="searchDiv" class="w-search-form-row">
            <div class="w-search-input">
              <input
                type="search"
                class="field searchform-s"
                name="s"
                placeholder="Search..."
              />
              <button (click)="search()" class="search-btn">
                <i class="fal fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <a
          class="lg-none btn-alignment"
          (click)="openDialog()"
          style="display: none"
          ><i class="fal fa-envelope"></i
        ></a>
        <div class="navbar-btn mr-100 contactUs-btn">
          <a
            (click)="openDialog()"
            routerLinkActive="active"
            class="text-white btn ml-5 rounded d-sm-m`obile"
            style="background: #072f5f"
            >Request For Info</a
          >
        </div>

        <div class="dropdown">
          <button
            class="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              src="../../../../assets/images/flag/download.png"
              alt=""
              style="width: 3rem"
            />
          </button>
          <div
            class="pl-2 m-0 dropdown-menu"
            aria-labelledby="dropdownMenuButton"
          >
            <a
              class="p-0 dropdown-item countryFlag"
              (click)="selectCountry('USA')"
            >
              <span>USA</span>
            </a>
            <a
              class="p-0 dropdown-item countryFlag active"
              (click)="selectCountry('India')"
            >
              <span style="color: black">India</span>
            </a>
            <!-- <a
              class="p-0 dropdown-item countryFlag"
              (click)="selectCountry('Dubai')"
            >
              <span>UAE</span>
            </a> -->
          </div>
        </div>
      </nav>
    </div>
    <!-- navigation -->
  </div>
</header>
<!--====== HEADER India ENDS ======-->

<!--====== HEADER USAstart ======-->
<header
  *ngIf="selectCountryName == 'USA'"
  class="header-area header-area-2 header-area-4"
>
  <div class="header-top pl-30 pr-30 black-bg">
    <div class="row align-items-center">
      <div class="col-md-8 col-sm-7">
        <div class="header-left-side text-center text-sm-left">
          <ul>
            <li>
              <a href="mailto:info@bostonfagroup.com"
                ><i class="fal fa-envelope"></i> info@bostonfagroup.com</a
              >
            </li>
            <li>
              <a href="tel:+ 1-408-533-1050"
                ><i class="fal fa-phone"></i> + 1-408-533-1050</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 col-sm-5">
        <div class="header-right-social text-center text-sm-right">
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/BostonFinancialAdvisoryGroup/"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
            <li>
              <a target="_blank" href="https://twitter.com/BostonfaGroup"
                ><i class="fab fa-twitter"></i
              ></a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/boston-financial-advisory-group/"
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="header-nav india">
    <div class="navigation">
      <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" routerLink="/" style="max-width: 216px"
          ><img
            src="../../../../assets/images/BFAG_logo.png"
            alt="Frontline ERP Solutions"
        /></a>
        <!-- logo -->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="toggler-icon"></span>
          <span class="toggler-icon"></span>
          <span class="toggler-icon"></span>
        </button>
        <!-- navbar toggler -->
        <div
          class="indiaToggler collapse navbar-collapse sub-menu-bar"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav ml-auto">

            <li class="nav-item indiaItem" routerLinkActive="active">
              <a class="nav-link indiaLink">About Us</a>
              <ul class="sub-menu scroll-navbar-mob">
                <li>
                  <a
                    routerLink="/why-bfag"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Why BFAG </a
                  ><a
                    routerLink="/why-bfag"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Why BFAG
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/our-team"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Our Team </a
                  ><a
                    routerLink="/our-team"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Our Team
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/mission-and-vision"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Mission and Vision</a
                  ><a
                    routerLink="/mission-and-vision"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Mission and Vision</a
                  >
                </li>
                <li>
                  <a
                    routerLink="/client-speaks"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Client Speaks</a
                  ><a
                    routerLink="/client-speaks"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Client Speaks</a
                  >
                </li>
                <li>
                  <a
                    routerLink="/how-we-do-it"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >How We Do It</a
                  ><a
                    routerLink="/how-we-do-it"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >How We Do It</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item indiaItem" routerLinkActive="active">
              <a
                (click)="redirectToOtherPage()"
                class="nav-link indiaLink"
                id="desk-view"
                >Services
              </a>
              <a
                (click)="redirectToOtherPage()"
                class="nav-link indiaLink"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                id="mob-view"
                >Services
              </a>
              <ul
                class="sub-menu scroll-navbar-mob"
                style="width: 20rem !important"
              >
                <li>
                  <a
                    routerLink="/accounting-and-transition-processing"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="desk-view"
                    >Accounting & Transaction Processing </a
                  ><a
                    routerLink="/accounting-and-transition-processing"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Accounting & Transaction Processing
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/tax-regulatory-compliances"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="desk-view"
                    >Tax & Regulatory Compliances </a
                  ><a
                    routerLink="/tax-regulatory-compliances"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Tax & Regulatory Compliances
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/payroll-advisory"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="desk-view"
                    >Payroll Advisory</a
                  ><a
                    routerLink="/payroll-advisory"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Payroll Advisory</a
                  >
                </li>
                <li>
                  <a
                    routerLink="/consulting-and-advisory-services"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    id="desk-view"
                    >Consulting & Advisory</a
                  ><a
                    routerLink="/consulting-and-advisory-services"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Consulting & Advisory</a
                  >
                </li>
              </ul>
            </li>
            <li
              class="nav-item"
              routerLinkActive="active"
              routerLink="/cpa-back-office"
            >
              <a class="nav-link" id="desk-view">CPA Back-Office</a>
              <a
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                id="mob-view"
                >CPA Back-Office</a
              >
            </li>
            <li class="nav-item indiaItem" routerLinkActive="active">
              <a class="nav-link indiaLink">Products</a>
              <ul class="sub-menu scroll-navbar-mob">
                <li>
                  <a
                    routerLink="/compliance-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >COMPLIANCE iNBOX
                  </a>
                  <a
                    routerLink="/compliance-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >COMPLIANCE iNBOX
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/expense-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >EXPENSE iNBOX </a
                  ><a
                    routerLink="/expense-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >EXPENSE iNBOX
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/analytics-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >ANALYTICS iNBOX</a
                  ><a
                    routerLink="/analytics-inbox"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >ANALYTICS iNBOX</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item indiaItem" routerLinkActive="active">
              <a class="nav-link indiaLink">Info Centre</a>
              <ul class="sub-menu scroll-navbar-mob">
                <li>
                  <a
                    routerLink="/newsletter"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Newsletters
                  </a>
                </li>
                <li>
                  <a
                    routerLink="/case-studies"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Case Studies </a
                  ><a
                    routerLink="/case-studies"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Case Studies
                  </a>
                </li>
                <!-- <li>
                  <a
                    routerLink="/blogs"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Blogs</a
                  >
                </li> -->
                <li>
                  <a
                    routerLink="/careers"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Careers</a
                  ><a
                    routerLink="/careers"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Careers</a
                  >
                </li>
                <!-- <li>
                  <a
                    routerLink="/fraud-alert"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Fraud Alert</a
                  >
                </li> -->
                <li>
                  <a
                    routerLink="/media-hits"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Media Hits</a
                  ><a
                    routerLink="/media-hits"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    id="mob-view"
                    >Media Hits</a
                  >
                </li>
                <li>
                  <a
                    routerLink="/blogs"
                    #solutions="routerLinkActive"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    id="desk-view"
                    >Blogs</a
                  >
                </li>
              </ul>
            </li>
            <li
              class="nav-item"
              routerLinkActive="active"
              routerLink="/contact-us"
            >
              <a class="nav-link" id="desk-view">Contact</a>
              <a
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                id="mob-view"
                >Contact</a
              >
            </li>
          </ul>
        </div>
        <!-- navbar collapse -->
        <div class="bar-area d-none d-xl-block pos-rel" style="display: none">
          <div *ngIf="searchDiv" class="w-search-form-row">
            <div class="w-search-input">
              <input
                type="search"
                class="field searchform-s"
                name="s"
                placeholder="Search..."
              />
              <button (click)="search()" class="search-btn">
                <i class="fal fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <a
          class="lg-none btn-alignment"
          (click)="openDialog()"
          style="display: none"
          ><i class="fal fa-envelope"></i
        ></a>
        <div class="navbar-btn mr-100 contactUs-btn">
          <a
            (click)="openDialog()"
            routerLinkActive="active"
            class="text-white btn ml-5 rounded d-sm-mobile"
            style="background: #072f5f"
            >Request For Info</a
          >
        </div>

        <div class="dropdown">
          <button
            class="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              src="../../../../assets/images/flag/USA.png"
              alt=""
              style="width: 3rem"
            />
          </button>
          <div
            class="pl-2 m-0 dropdown-menu"
            aria-labelledby="dropdownMenuButton"
          >
            <a
              class="p-0 dropdown-item countryFlag active"
              (click)="selectCountry('USA')"
            >
              <span style="color: black">USA</span>
            </a>
            <a
              class="p-0 dropdown-item countryFlag"
              (click)="selectCountry('India')"
            >
              <span>India</span>
            </a>
            <!-- <a
              class="p-0 dropdown-item countryFlag"
              (click)="selectCountry('Dubai')"
            >
              <span>UAE</span>
            </a> -->
          </div>
        </div>
      </nav>
    </div>
    <!-- navigation -->
  </div>
</header>
<!--====== HEADER USA ENDS ======-->
