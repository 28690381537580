
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area horizon-support customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Horizon Support</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Horizon Support</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->

<!--====== Horizon Support PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>24x7 Support</span>
                    <h2 class="title pb-15">Horizon Support</h2>
                    <p>Even with the best ERP software system in place, organizations require support for fixes, updates and enhancements. Frontline makes your organization the cynosure of all its attention, in providing able support for your organization’s
                        system needs.</p>
                    <p>Our personalized service and premium access to accomplished experts enables us in providing unparalleled support for all our clients. Our fast and accurate support solutions, delivered by a dedicated team of support experts, will help
                        organizations achieve goals and drive continuous development into business.</p>
                    <p>Our customers depend not only on our software, but also on our experience in supporting those solutions. Frontline stands behind all of its products with a world-class support organization, capable of serving our customer base.</p>
                    <p>At Frontline, we are driven by professionalism, focus and passion to deliver excellent customer service. We strongly believe that support, service and training are ongoing and continuous process that does not end when the sale is formally
                        over. Customer satisfaction at all levels is very important to us whether it is pre-sales, after sales or just customer support. We offer 24X7 customer and service support. We have dedicated customer service department with knowledgeable
                        technical professionals, who provide support over the telephone, on the internet and personalized on-site services.</p>
                    <p>We provide our customers the best possible technical and application support available today through personnel who are experts in our applications, technology, and industry; utilizing advanced support tools and technology.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>

<!--====== Horizon Support PART START ======-->

<!--====== GET IN TOUCH PART START ======-->
<div class="horizon-support get-in-touch-area get-in-touch-area-2 dec-spacing">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title text-left">
                    <span>Get In Touch</span>
                    <h2 class="title">Feel free to reach us</h2>
                </div>
                <!-- section title -->
                <div class="form-area">
                   
                    <form id="contact-form" [formGroup]="horizonSupportForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="input-box mt-20">
                                    <input type="text" placeholder="Name *" formControlName="name" required>
                                    <i class="fal fa-user"></i>
                                    <div *ngIf="submitted && f.name.errors" class="text-danger small" [ngClass]="{'is-invalid': submitted && f.name.errors }">
                                        <div *ngIf="f.name.errors?.required">Notice period is required</div>
                                    </div>
                                   
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-box mt-20">
                                    <input type="text" placeholder="Department *" formControlName="department" required>
                                    <i class="fal fal fa-edit"></i>
                                    <div *ngIf="submitted && f.department.errors" class="text-danger small" [ngClass]="{'is-invalid': submitted && f.department.errors }">
                                        <div *ngIf="f.department.errors?.required">Department is required</div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="input-box mt-20">
                                    <input type="email" placeholder="Email *" formControlName="email_id" required>
                                    <i class="fal fa-envelope"></i>
                                    <div *ngIf="submitted && f.email_id.errors" class="text-danger small" [ngClass]="{'is-invalid': submitted && f.email_id.errors }">
                                        <div *ngIf="f.email_id.errors?.required">Email is required</div>
                                        <div *ngIf="f.email_id.errors?.email">Please enter valid email</div>
                                    </div>
                                   
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-box mt-20">
                                    <input type="text" pattern="\d*" maxlength="15" placeholder="Number *" formControlName="mobile" required>
                                    <i class="fal fal fa-phone"></i>
                                    <div *ngIf="submitted && f.mobile.errors" class="text-danger small" [ngClass]="{'is-invalid': submitted && f.mobile.errors }">
                                        <div *ngIf="f.mobile.errors?.required">Mobile is required</div>
                                       
                                        <div *ngIf="f.mobile.errors?.pattern">Invalid mobile number</div>
                                    </div>
                                  
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="input-box mt-20">
                                    <input type="text" placeholder="Subject *" formControlName="subject" required>
                                    <i class="fal fa-edit"></i>
                                    <div *ngIf="submitted && f.subject.errors" class="text-danger small" [ngClass]="{'is-invalid': submitted && f.subject.errors }">
                                        <div *ngIf="f.subject.errors?.required">Subject is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mt-20">
                                    <input type="file" class="pt-2" (change)="fileUpload($event)" formControlName="upload_cv" id="upload_file" style="display: none;" >
                                    <a (click)="choosePhoto()" class="choose-file">Choose File </a><label class="ml-2 new-label"><small> {{getFileName}}</small></label>
                                    <p _ngcontent-dwm-c54="" class="color-gray"><small _ngcontent-dwm-c54="">Accepted file formats : pdf,doc,docx</small></p>
                                    <div class="text-danger small">
                                        <div *ngIf="horizonSupportForm.controls.upload_cv.errors?.required && horizonSupportForm.controls.upload_cv.dirty">Documents required</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                        <div class="input-box mt-20">
                            <textarea name="message" id="#" cols="30" rows="10" placeholder="Message" formControlName="comments"></textarea>
                            <i class="fal fa-edit"></i>
                            <div class="text-danger small">
                                <div *ngIf="horizonSupportForm.controls.comments.errors?.required && horizonSupportForm.controls.comments.dirty">Comments is required</div>

                            </div>
                        </div>
                        </div>
                        <div class="col-lg-12 mt-20">
                            <div class="row">
                            <div class="col-lg-3 sync-new">
                                <div class="input-box">
                                    <input type="text" placeholder="Enter Captcha*" formControlName="recaptchaReactive">
                                    <span class="badge badge-danger sync" (click)="reCaptcha()"> <i class="fal fa-sync"></i></span>
                                </div>
                            </div>
                            <div class="col-lg-3 mt-1">
                                <div id="captchaHorizonSupport"></div>
                            </div>
                        </div> 
                            <div *ngIf="submitted && f.recaptchaReactive.errors" class="text-danger small"
                            [ngClass]="{'is-invalid': submitted && f.recaptchaReactive.errors }">
                            <div *ngIf="f.recaptchaReactive.errors?.required" class="pl-3">Captcha is required
                            </div>
                              
                        </div>
                           
                        </div>
                        <!-- <div class="col-lg-12 mt-20">
                            <input type="hidden" formControlName="recaptchaReactive">
                            <re-captcha (resolved)="captchaResolved($event)" 
                            siteKey="6Le-wvkSAAAAAPBMRTvw0Q4Muexq9bi0DJwx_mJ-"></re-captcha>
                            <div *ngIf="submitted && f.recaptchaReactive.errors" class="text-danger small"
                            [ngClass]="{'is-invalid': submitted && f.recaptchaReactive.errors }">
                            <div *ngIf="f.recaptchaReactive.errors?.required" class="pl-3">Recaptcha is required
                            </div>
                            </div>
                        </div> -->
                        </div>
                        <div class="text-right  mt-20">
                            <button class="main-btn main-btn-new">SEND MESSAGE </button>
                        </div>
                    </form>
                    <div class="alert alert-success  mt-4" role="alert" *ngIf="resultTrue">
                        Thank You for reaching out . We would get back to you shortly.
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="resultFalse">
                        Something went wrong please try again
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== GET IN TOUCH PART END ======-->
