import { Component, OnInit } from '@angular/core';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';


@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {
  defaultCountry: string;
  selectCountryName: any;
  selectedFlag: string;
  newsletter:any
  constructor(
    private LocalStorageServiceService: LocalStorageServiceService,) { }

    ngOnInit(): void {
      this.defaultCountry = 'USA';

      this.selectCountryName = localStorage.getItem('selectedCountry');

      if (this.selectCountryName == null) {
        localStorage.setItem('selectedCountry', this.defaultCountry);
        window.location.reload();
      } else {
        this.selectCountryName = localStorage.getItem('selectedCountry');
      }

      this.fetchNews();
    }

    selectCountry(country: string): void {
      // Update the selectedFlag with the corresponding image path
      if (country === 'Dubai') {
        this.selectedFlag =
          '/assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png';
      } else if (country === 'India') {
        this.selectedFlag = '/assets/images/flag/download.png';
      } else if (country === 'USA') {
        this.selectedFlag = '/assets/images/flag/USA.png';
      } else {
        // Set USA as the default country
        this.selectedFlag =
          '/assets/images/flag/USA.png';
        country = 'USA';
      }

      this.LocalStorageServiceService.setItem('selectedCountry', country);
    }

    
    async fetchNews() {
      const res = await fetch(`https://rich-tan-dhole-kilt.cyclic.app/api/news/`, {
        method: 'GET',
      });
      this.newsletter = await res.json();
      console.log(this.newsletter);
    }
    // https://rich-tan-dhole-kilt.cyclic.app/api/blogs/


// newsletter = [
//   {
//     id:'1',
//     title: "Lorem ipsum dolor sit amet",
//     posted_on: "Jan 12, 2023",
//     img:"../../../../assets/images/newsletter/img 1.png",
//     short_desc:`Lorem ipsum dolor sit amet consectetur. Pretium vitae tincidunt nunc
//     tellus placerat. Neque nisi volutpat senectus quam elit risus.
//     Malesuada pellentesque pulvinar orci nam aliquam suscipit. Vitae
//     pulvinar tortor cras diam mi risus leo. Ultrices molestie quam sed
//     proin felis blandit ullamcorper consequat. Lorem ut dictum sem nisi
//     pretium fringilla proin eu ut. Sed tortor tempor faucibus nec.`
//   },
//   {
//     id:'2',
//     title: "Lorem ipsum dolor sit amet",
//     posted_on: "Jan 12, 2023",
//     img:"../../../../assets/images/newsletter/img 1.png",
//     short_desc:`Lorem ipsum dolor sit amet consectetur. Pretium vitae tincidunt nunc
//     tellus placerat. Neque nisi volutpat senectus quam elit risus.
//     Malesuada pellentesque pulvinar orci nam aliquam suscipit. Vitae
//     pulvinar tortor cras diam mi risus leo. Ultrices molestie quam sed
//     proin felis blandit ullamcorper consequat. Lorem ut dictum sem nisi
//     pretium fringilla proin eu ut. Sed tortor tempor faucibus nec.`
//   },
//   {
//     id:'3',
//     title: "Lorem ipsum dolor sit amet",
//     posted_on: "Jan 12, 2023",
//     img:"../../../../assets/images/newsletter/img 1.png",
//     short_desc:`Lorem ipsum dolor sit amet consectetur. Pretium vitae tincidunt nunc
//     tellus placerat. Neque nisi volutpat senectus quam elit risus.
//     Malesuada pellentesque pulvinar orci nam aliquam suscipit. Vitae
//     pulvinar tortor cras diam mi risus leo. Ultrices molestie quam sed
//     proin felis blandit ullamcorper consequat. Lorem ut dictum sem nisi
//     pretium fringilla proin eu ut. Sed tortor tempor faucibus nec.`
//   }
// ]

}
