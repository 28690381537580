
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area horizon-hrms customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Horizon Human Resource   <span class="d-md-block">Management Solution</span></h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Horizon HRMS</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== HRMS PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>HRMS Products</span>
                    <h2 class="title pb-15">Horizon – Human Resource <br/> Management Solution (HRMS)</h2>
                    <p>Horizon HRMS software empowers organizational leaders with the critical tools and information they need to optimize the workforce and HR.</p>
                    <p>Horizon HRMS is a complete Human Resource Management Solution that manages & maintains Employee Profiles, Payroll preparation & WPS Sheet (.SIF File) with automatic calculation of allowances & deduction, etc. The system can store scanned documents of employees along with records to view them later when required urgently. Horizon HRMS gives reminders for various employee document renewal, leave, etc. </p>
                    <p>Horizon HRMS is a comprehensive system which handles HR Functions like Recruitment, Offer Letter, Visa Monitoring, Accommodation/Camp Management, Training, Insurance Management, Performance Management, Payroll Management, Benefits (Leave, Gratuity, Airfare, and other provisional benefits) Management and End of Service Management.</p>
                    <p>It can be integrated with the other modules of the ERP software system making it a powerful instrument that enables Human Resource and payroll departments to access, process, operate, and manage all aspects of the organization’s Human Resource cycle including payroll function.</p>
                    <p>Horizon HRMS provides you with speedy and accurate information for controlling every aspect of your human resource with the help of its powerful MIS Reporting.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== HRMS PART END ======-->
<!--====== Features of Horizon PART START ======-->
<div class="about-faq-area about-faq-area-custom horizon-hrms dec-spacing bg_cover gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Highlights</span>
                    <h2 class="title pb-15">Features of Horizon <br/> HRMS (Standard Module)</h2>
                </div>
            </div>
            <div class="faq-accordion-3 pt-0">
                <div class="accordion" id="accordionExample">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Employee document expiry reminders
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Horizon HRM Solution highlights all documents which are going to expire or already expired. No more fines for expired documents. You can set number of days before which you would like to get reminder of document expiry.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Employee Profiles
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Maintain Basic details of employee with File No, code, department, designation, joining date, status, other personal details with insurance & skills, local contact details, home country contact details etc. to view
                                        or print when you need. It offers maximum information to be recorded for each employee, which is well categorized and organized for quick access purpose.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Passport / Visa Details
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Maintain passport, visa and labor card along with respective expiry dates to get reminder before they get expire. Horizon HRMS provides report listing of passport details, visa and labor card etc. These reports can
                                        be printed or exported to excel, pdf file or 20 other different formats.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <i class="fal fa-magic"></i>Qualification & Work Experience
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Maintain qualification details & work experience of each employee. User may input unlimited records!</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingFive">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <i class="fal fa-magic"></i>Scan Documents
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Save unlimited scanned documents of each employee along with their records to view or print later as and when you need them. You may also attach other formats like word, excel, or pdf, etc for each & every employee record.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSix">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    <i class="fal fa-magic"></i>Entitlements
                                </a>
                            </div>
                            <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Maintain record of issued entitlement to each employee so you can get back valuable assets when employee leaves the company. This option is also useful to track, in case you issue certain things like dresses or safety
                                        shoes to employees every 6 months or a year</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingEle">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEle" aria-expanded="false" aria-controls="collapseSix">
                                    <i class="fal fa-magic"></i>Annual Leave Records
                                </a>
                            </div>
                            <div id="collapseEle" class="collapse" aria-labelledby="headingEle" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Input a record every time an employee goes on annual leave. You will input from date and to date of leave, Ticket is given or not, etc. The system will automatically calculate total consumed leave and balance leave of each employee as per their joining date</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSev">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSev" aria-expanded="false" aria-controls="collapseSev">
                                    <i class="fal fa-magic"></i>Salary Details
                                </a>
                            </div>
                            <div id="collapseSev" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Input Basic salary & bank details of the employee along with OT profile assignment to automatic salary calculation. Users may add unlimited allowances & fix deductions for each employee. The system uses this information to prepare a WPS. sif file to transfer salaries through banks or exchanges.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEight">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    <i class="fal fa-magic"></i>Salary Increment history
                                </a>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Input a record every time you give salary increment and you will be in a better position to make a decision if it is the right time to give increment when employees demand.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingNine">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    <i class="fal fa-magic"></i>Payroll - Automatic Salary Calculation
                                </a>
                            </div>
                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>The system automatically calculates monthly salary for all employees based on defined Basic Salary + Allowances – Deductions. You may input OT hours for OT calculation and absents to affect salary. Users can print salary slips & monthly salary statements etc. The system has an option to deliver respective salary slips by email to every employee in just one click.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingTen">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                    <i class="fal fa-magic"></i>Loans and Advance
                                </a>
                            </div>
                            <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>You can define issued cash advance & agreed on monthly installment in the system for the employee. It automatically deducts installment from monthly salary every month until the full loan is recovered. Horizon HRM Solution has an option in case you don't want to deduct installment for any particular month or want to increase or decrease the installment amount.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingTwe">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwe" aria-expanded="false" aria-controls="collapseTwe">
                                    <i class="fal fa-magic"></i>Time sheet Management
                                </a>
                            </div>
                            <div id="collapseTwe" class="collapse" aria-labelledby="headingTwe" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>The system has a daily as well as a monthly timesheet. With one click it generates timesheet for the month to save time and you can mark absents/leave for particular employees. Timesheet module is integrated with payroll to automatically deduct marked absents.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingThr">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThr" aria-expanded="false" aria-controls="collapseThr">
                                    <i class="fal fa-magic"></i>User Defined Over Time (OT)
                                </a>
                            </div>
                            <div id="collapseThr" class="collapse" aria-labelledby="headingThr" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>You can define unlimited overtime (OT) profiles and define your own formulas. These OT profiles can be assigned to employees and payroll will automatically calculate OT amount for each employee as per their assigned formula.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingFor">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFor" aria-expanded="false" aria-controls="collapseFor">
                                    <i class="fal fa-magic"></i>Automatic Gratuity Calculation
                                </a>
                            </div>
                            <div id="collapseFor" class="collapse" aria-labelledby="headingFor" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>You can define/change the gratuity calculation formula. The system will automatically calculate gratuity for every employee as per defined formula on the basis of their basic salary.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== Features of Horizon PART END ======-->

<!--====== (Advance Modules) PART START ======-->
<div class="about-faq-area about-faq-area-custom dec-spacing bg_cover horizon-hrms">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Additional Highlights</span>
                    <h2 class="title pb-20">Other Add-On Modules <br/> (Advance Modules)</h2>
                </div>
            </div>
            <div class="faq-accordion-3 pt-0">
                <div class="accordion" id="accordionExample2">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingsixteen">
                                <a class="" href="" data-toggle="collapse" data-target="#collapsesixteen" aria-expanded="true" aria-controls="collapsesixteen">
                                    <i class="fal fa-magic"></i>Employee Self Service <span class="sm-none">(ESS)</span> with Mobility
                                </a>
                            </div>
                            <div id="collapsesixteen" class="collapse show" aria-labelledby="headinsixteen" data-parent="#accordionExample2">
                                <div class="card-body">
                                    <p>It empowers both employee and supervisor to access directly and view all their leave information, salary slip, etc. It has become so invaluable that it is being termed as “an essential core application” for businesses. It provides essential benefits in terms of efficiency, time, ease in complying with HR rules and regulations. ESS App enables you to receive the notification on your phone/tablets, viewing the request and approved, disapprove, or clarification requests on your phone. </p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingseventeen">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseseventeen" aria-expanded="false" aria-controls="collapseseventeen">
                                    <i class="fal fa-magic"></i>Manpower Request and Planning
                                </a>
                            </div>
                            <div id="collapseseventeen" class="collapse" aria-labelledby="headingseventeen" data-parent="#accordionExample2">
                                <div class="card-body">
                                    <p>Various departments or projects can raise a manpower request with the required specification in terms of skills, experiences, and qualifications. The recruitment in charge can decide and plan to put the resources from the in house pool of resources or recruit it externally. It manages the complete manpower requirement and planning. </p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingeighteen">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseeighteen" aria-expanded="false" aria-controls="collapseseighteen">
                                    <i class="fal fa-magic"></i>Recruitment Management
                                </a>
                            </div>
                            <div id="collapseeighteen" class="collapse" aria-labelledby="headingeighteen" data-parent="#accordionExample2">
                                <div class="card-body">
                                    <p>It helps Human Resource people optimize the processes involved in sourcing, interviewing, and shortlisting candidates for any specific requirement down to making that candidate into an employee of the organization. It has a list of vacancies with a direct linkage to job descriptions. Actions are taken by HR to fill all vacancies. Managing application for vacancies: internal and external, Candidate to update CV and link to vacancies through your website or third party links. Complete interview process: scheduling of interviews, management of the interview process, etc.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingnineteen">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapsenineteen" aria-expanded="false" aria-controls="collapsenineteen">
                                    <i class="fal fa-magic"></i>Visa Monitoring System
                                </a>
                            </div>
                            <div id="collapsenineteen" class="collapse" aria-labelledby="headingnineteen" data-parent="#accordionExample2">
                                <div class="card-body">
                                    <p>This helps you to manage the complete VISA process for the newly recruited employees as well as renewals, cancellation, etc. Different processes and steps for different VISA type can be setup. This can be updated and monitored further with various status and cost. </p>
                                </div>
                            </div>
                        </div>
                        <!-- faq accordion -->
                    </div>
                    <div class="col-lg-6">
                        <!-- card -->
                        
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingtwenty">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapsetwenty" aria-expanded="false" aria-controls="collapsetwenty">
                                    <i class="fal fa-magic"></i>Letter Management
                                </a>
                            </div>
                            <div id="collapsetwenty" class="collapse" aria-labelledby="headingtwenty" data-parent="#accordionExample2">
                                <div class="card-body">
                                    <p>It can generate and manage various letters including post recruitment letters like offer letters and company employee letters like warning letters, memos, increment letters, review letters, or any other letters with the user-defined format. These issued letters will have a complete history log with employee records.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingtwentyOne">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapsetwentyOne" aria-expanded="false" aria-controls="collapsetwentyOne">
                                    <i class="fal fa-magic"></i>Training Management
                                </a>
                            </div>
                            <div id="collapsetwentyOne" class="collapse" aria-labelledby="headingtwentyOne" data-parent="#accordionExample2">
                                <div class="card-body">
                                    <p>This module allows the HR and Line managers an opportunity to conduct training more professionally. This module manages the functions like, Training Plans, Training requests and scheduling, Training management, Training venues, Training providers, Provision for internal and external training, Training skills mapping and Training costing and budgeting.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingtwentyTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapsetwentyTwo" aria-expanded="false" aria-controls="collapsetwentyTwo">
                                    <i class="fal fa-magic"></i>Employee Accommodation/Camp Management
                                </a>
                            </div>
                            <div id="collapsetwentyTwo" class="collapse" aria-labelledby="headingtwentyTwo" data-parent="#accordionExample2">
                                <div class="card-body">
                                    <p>Accommodation or camp can be defined by classifying it through group and category. Camp wise facilities and units can be defined up to unit wise bed space with various facilities. Employees can be accommodated and group by their projects, nationalities, religion, and food habits. Allocation and de-allocation of units can be done to various employees at the discretion of the camp boss as per the availability.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingtwentyThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapsetwentyThree" aria-expanded="false" aria-controls="collapsetwentyThree">
                                    <i class="fal fa-magic"></i>Performance Appraisal
                                </a>
                            </div>
                            <div id="collapsetwentyThree" class="collapse" aria-labelledby="headingtwentyThree" data-parent="#accordionExample2">
                                <div class="card-body">
                                    <p>This module allows HR and Management to create, manage, and evaluate the skills or competencies required by a particular person for a particular job. It also allows HR and Management to create, manage, and evaluate these skills or competency assessment questionnaires as a development tool for employees. It provides a more efficient way to perform employee reviews. It allows both the employee and the supervisor(s) to rate the employee on a wide range of standard skills and capabilities related to their current position.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== (Advance Modules) PART END ======-->

