import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HorizonSupportFormService {

  constructor(private http: HttpClient) { }
  //this method is use home page contact form, header modal contact form & contact page form
  horizonDetails(data){
    return this.http.post(`${environment.baseUrl}save_horizon_details`, data)
  }
}
