<!-- Dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white ">COMPLIANCE iNBOX (CiB)</h3>
    </div>
  </div>
  <div style="overflow: hidden; position: relative;">
    <img src="../../../../../assets/images/products/products-abstract.png" alt="shape" style="position: absolute; overflow: clip;left: -3%; top: 0%; "/>
    <div class="mt-50 mb-50 container d-flex justify-content-start align-items-start" >

      <div class="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">

          <img src="../../../../assets/images/products/complience.png" class="img-fluid" style="height: 23rem;" />

      </div>
      <div class="col-lg-8 col-md-12 ">
        <p class="text-dark mb-30 text-justify" style="line-height: 2.5rem; font-weight: 500">
          COMPLIANCE iNBOX is an intuitive solution developed to address this very pressing need to simplify COMPLIANCE management across the organization. Developed by a team of practicing CAs, CPAs, and financial stalwarts from Boston Financial Advisory Group (BFAG), COMPLIANCE iNBOX brings to you the promise of timely and accurate COMPLIANCE without the typical cost and hassle associated with it.
        </p>

        <div class="navbar-btn contactUs-btn cursor-pointer">
          <a href="https://complianceinbox.com/" target="_blank" class="text-white btn d-sm-mobile rounded contactBtn" style="background: #418052;"  ng-reflect-router-link-active="active" >Learn More</a>
        </div>

      </div>
    </div>
  </div>

</div>
<!-- Dubai Ended -->

<!-- India started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white">COMPLIANCE iNBOX (CiB)</h3>
    </div>
  </div>
  <div style="overflow: hidden; position: relative;">
    <img src="../../../../../assets/images/products/products-abstract.png" alt="shape" style="position: absolute; overflow: clip;left: -3%; top: 0%; "/>
    <div class="mt-50 mb-50 container d-flex justify-content-start align-items-start" >

      <div class="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">

          <img src="../../../../assets/images/products/complience.png" class="img-fluid" style="height: 23rem;" />

      </div>
      <div class="col-lg-8 col-md-12 ">
        <p class="text-dark mb-30 text-justify" style="line-height: 2.5rem; font-weight: 500">
          COMPLIANCE iNBOX is an intuitive solution developed to address this very pressing need to simplify COMPLIANCE management across the organization. Developed by a team of practicing CAs, CPAs, and financial stalwarts from Boston Financial Advisory Group (BFAG), COMPLIANCE iNBOX brings to you the promise of timely and accurate COMPLIANCE without the typical cost and hassle associated with it.
        </p>

        <div class="navbar-btn contactUs-btn cursor-pointer">
          <a href="https://complianceinbox.com/" target="_blank" class="text-white btn d-sm-mobile rounded contactBtn" style="background: #072F5F;"  ng-reflect-router-link-active="active" >Learn More</a>
        </div>

      </div>
    </div>
  </div>

</div>
<!-- India Ended -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white ">COMPLIANCE iNBOX (CiB)</h3>
    </div>
  </div>
  <div style="overflow: hidden; position: relative;">
    <img src="../../../../../assets/images/products/products-abstract.png" alt="shape" style="position: absolute; overflow: clip;left: -3%; top: 0%; "/>
    <div class="mt-50 mb-50 container d-flex justify-content-start align-items-start" >

      <div class="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">

          <img src="../../../../assets/images/products/complience.png" class="img-fluid" style="height: 23rem;" />

      </div>
      <div class="col-lg-8 col-md-12 ">
        <p class="text-dark mb-30 text-justify" style="line-height: 2.5rem; font-weight: 500">
          COMPLIANCE iNBOX is an intuitive solution developed to address this very pressing need to simplify COMPLIANCE management across the organization. Developed by a team of practicing CAs, CPAs, and financial stalwarts from Boston Financial Advisory Group (BFAG), COMPLIANCE iNBOX brings to you the promise of timely and accurate COMPLIANCE without the typical cost and hassle associated with it.
        </p>

        <div class="navbar-btn contactUs-btn cursor-pointer">
          <a href="https://complianceinbox.com/" target="_blank" class="text-white btn d-sm-mobile rounded contactBtn" style="background: #072F5F;"  ng-reflect-router-link-active="active" >Learn More</a>
        </div>

      </div>
    </div>
  </div>

</div>
<!-- USA Ended -->
<!-- USA Ended -->

