<!-- dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      GLOBAL COMPLIANCE MANAGEMENT
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> GLOBAL COMPLIANCE
        MANAGEMENT</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/compliance.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 8rem;"
        />
        <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Working with customers having multi-jurisdiction/cross border presence
        has helped us to come up with Global Compliance Management. In essence
        it includes Accounting, HR & Payroll, Tax compliance.
      </p>
      <div>
        <h5 class="mb-20" style="color: green;">
          OUR CLIENTS GET AN EFFICIENT AND EFFECTIVE COMPLIANCE SOLUTION THAT
          HELPS THEM IN:
        </h5>
        <ul class="mt-10" style="line-height: 2rem;">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Getting real time visibility on compliances so that quick resolution
            to escalation if any can be provided.
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Better Management information on compliances so as to minimize
            costs.
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Better and complete information to help proactively manage
            operational risks.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- dubai end -->

<!-- India started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      GLOBAL COMPLIANCE MANAGEMENT
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> GLOBAL COMPLIANCE
        MANAGEMENT</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/compliance.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 8rem;"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Working with customers having multi-jurisdiction/cross border presence
        has helped us to come up with Global Compliance Management. In essence
        it includes Accounting, HR & Payroll, Tax compliance.
      </p>
      <div>
        <h5 class="mb-20" style="color: #0951A4;">
          OUR CLIENTS GET AN EFFICIENT AND EFFECTIVE COMPLIANCE SOLUTION THAT
          HELPS THEM IN:
        </h5>
        <ul class="mt-10" style="line-height: 2rem;">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Getting real time visibility on compliances so that quick resolution
            to escalation if any can be provided.
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Better Management information on compliances so as to minimize
            costs.
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Better and complete information to help proactively manage
            operational risks.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- india end -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      GLOBAL COMPLIANCE MANAGEMENT
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> GLOBAL COMPLIANCE
        MANAGEMENT</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/compliance.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 8rem;"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Working with customers having multi-jurisdiction/cross border presence
        has helped us to come up with Global Compliance Management. In essence
        it includes Accounting, HR & Payroll, Tax compliance.
      </p>
      <div>
        <h5 class="mb-20" style="color: #0951A4;">
          OUR CLIENTS GET AN EFFICIENT AND EFFECTIVE COMPLIANCE SOLUTION THAT
          HELPS THEM IN:
        </h5>
        <ul class="mt-10" style="line-height: 2rem;">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Getting real time visibility on compliances so that quick resolution
            to escalation if any can be provided.
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Better Management information on compliances so as to minimize
            costs.
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Better and complete information to help proactively manage
            operational risks.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- USA end -->