<!-- dubai start -->
<section
  *ngIf="selectCountryName == 'Dubai'"
  class="min-vh-100 mTop"
>
<section class="pt-20 pb-30 mt-50"
style="position: relative;
  background-color: #418052;
  overflow: hidden;">
    <img src="assets/images/what-we-are-shape-2.png" alt="shape" style="position: absolute; transform: rotate(180deg); top:0; overflow: clip;left: -30%;width: 75%;"/>
    <img src="assets/images/what-we-are-shape-2.png" alt="shape" style="position: absolute; overflow: clip;right: -30%;width: 75%;bottom: -10%;"/>

  <div class="container pt-40" style="min-height: 18rem">
    <div class="row text-center" style="margin-bottom: 0.5rem">
      <div class="col-lg-12">
        <h3
          class="title p-0 m-0"
          style="border-bottom: 3px solid #418052; display: inline; color: white;"
        >
          TESTIMONIAL
        </h3>
      </div>
    </div>
    <div
      class="container col-lg-12 d-flex flex-column justify-content-center align-items-center pt-4"
    >
      <div
        id="myCarousel"
        class="carousel slide"
        data-ride="carousel"
        data-interval="5000"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                CEO
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                CEO of Leading Educational Institute in Southern India
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financial has been our Outsourced Accounting & Regulatory
                compliance partner from the beginning. Our association is more
                than a decade long and I am still pretty much impressed by their
                adherence to service levels. They have proactively solved issues
                impacting the business and have been consistently providing
                inputs on key performance indicators. This has helped me in
                running the business more effectively. The problem solving
                ability of the team continues to amaze.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                President/CEO
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                A leading SAP solution provider
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financial have been an asset to us and we consider them
                as an internal partner who has helped us on our growth path.
                Since we are working in multiple geographies, the biggest
                advantage of being associated with Boston Financial is that we
                have to talk to a single agency who does all our compliances and
                presents us with timely & accurate consolidated financial at all
                times
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center  text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Sunanda Subramanyam
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Manager (Finance), Ephicacy Lifescience Analytics
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financials is associated with Ephicacy as its accounting
                partner for 6 years. Their team comprising experts in finance,
                legal domain, have done a splendid job in efficiently managing
                Ephicacy's compliance to statutory and regulatory requirements
                without compromising data security. Over the years, they have
                become an extended arm of Ephicacy Finance and have earned the
                tag of 'trustworthy and reliable partners. As we continue our
                association with Boston team, we strongly recommend the same to
                others too!
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Atul Siddam
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Director, Diligent Tech India Private Limited
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financial have been an asset to us and we consider them
                as an internal partner who has helped us on our growth path.
                Since we are working in multiple geographies, the biggest
                advantage of being associated with Boston Financial is that we
                have to deal with a single agency who does all our compliances
                and presents us with timely & accurate consolidated financials.
                Their expertise in Corporate Structuring has helped us a lot in
                venturing overseas.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Solution Provider
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                A Communication & Data Connectivity Solution Provider
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Your Outsourced Accounting & Reporting services have always been
                excellent. Delivering in a complex manufacturing set-up,
                post-merger integration support, Global reporting, everything on
                time and always meeting the expectations. Great team that never
                fails to come up with solutions.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Leading IT Architecture Solution Provider
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                A Leading IT Architecture Solution Provider For Banks
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                I am continually pleased with Boston Financial, their
                professionalism, their service levels and their round the clock
                support. Would definitely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                JACKSON L.
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Munsey-Chief Executive Office, Washburn Law, PLLC
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
              
                  color: white !important;
                "
              >
                We have utilized Boston Financial services for the past 18
                months. They have identified management and accounting issues
                that have resulted in improved processing and real time
                information. This has allowed management us to deal with issues
                immediately before they become problems. Timely, precise and
                correct information always has been the hallmark of Boston
                Financial. They have helped double our revenue and improve our
                operational cost. Great team and an asset to our firm.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Company Manager
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                A Top Company in Wound Management
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                We have been associated with Boston Financial right from
                incorporating a legal entity in India. Though we are globally
                associated with the big fours we selected them for incorporation
                as well as on-going compliance support. They have an excellent
                team that always delivers.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Director
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Director of a CRM Company
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financial has been our Outsourced F & A partner for the
                past many years. | am continually pleased with their
                professionalism, service levels and round the clock support.
                Would definitely recommend them.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                CPA Firm
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Florida Based Mid Sized CPA Firm
              </span>
              <p
                class="card-text text-center text-white pt-3 "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financial has been assisting in Accounting, Tax
                preparation for the past 3 years. Their delivery capability and
                resources are top notch. Ideal partners for back-office
                operations, would surely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                CEO
              </p>
              <span class="text-center text-white" style=" font-size: 16px">
                CEO Of Venture Capital And Healthcare Service PROVIDER
              </span>
              <p
                class="card-text text-center text-white pt-3 "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                I am continually pleased with Boston Financial, their
                professionalism, their service levels and their round the clock
                support. Would definitely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                CEO
              </p>
              <span class="text-center text-white" style=" font-size: 16px">
                CEO of IT Service Company
              </span>
              <p
                class="card-text text-center text-white pt-3 "
                style="
                  line-height: normal;
                  text-align: justify;
                
                  color: white !important;
                "
              >
                Your service has always been spectacular. Whether it be
                incorporation, structuring, Compliance, always there to support.
                MIS and reporting are the hallmarks which have benefitted me a
                lot in making timely decisions. Never felt like Boston Financial
                is an Outsourced Accounting partner, we think of them as a team.
              </p>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#myCarousel"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#myCarousel"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <!-- row -->
    </div>
  </div>
</section>


<div class="container col-lg-12 pt-80 pb-80 d-flex flex-column justify-content-center  align-items-center">
  <div class="d-flex justify-content-center align-items-center">
    <h3
      class="title p-0 m-0"
      style="border-bottom: 3px solid #072F5F; display: inline"
    >
    OUR ESTEEMED CLIENTS
    </h3>
  </div>
  <div class="pt-5 d-flex flex-column justify-content-center align-items-center">
      <div class="d-flex justify-content-center align-items-center">
       <div class="border p-0 m-0 d-flex justify-content-center align-items-center">
        <img  src="../../../../assets/images/Client logos/Archwell.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img src="../../../../../assets/images/Client logos/Avanze.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img  src="../../../../../assets/images/Client logos/Berkower.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img  src="../../../../../assets/images/Client logos/BOAT.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img  src="../../../../../assets/images/Client logos/Diligent.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img  src="../../../../../assets/images/Client logos/JioSaavn.png" alt="">
       </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/KIC.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/MSRDC.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/Source Photonics.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/Sproxil.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/Univar.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/Washburn.png" alt="">
         </div>
      </div>



  </div>

</div>



</section>
<!-- dubai end -->

<!-- india start -->
<section
  *ngIf="selectCountryName == 'India'"
  class="min-vh-100 mTop "
>
<section class="pt-20 pb-30 mt-50"
style="position: relative;
  background-color: #002249;
  overflow: hidden;">
    <img src="assets/images/what-we-are-shape-2.png" alt="shape" style="position: absolute; transform: rotate(180deg); top:0; overflow: clip;left: -30%;width: 75%;"/>
    <img src="assets/images/what-we-are-shape-2.png" alt="shape" style="position: absolute; overflow: clip;right: -30%;width: 75%;bottom: -10%;"/>

  <div class="container pt-40" style="min-height: 18rem">
    <div class="row text-center" style="margin-bottom: 0.5rem">
      <div class="col-lg-12">
        <h3
          class="title p-0 m-0"
          style="border-bottom: 3px solid #072F5F; display: inline; color: white;"
        >
          TESTIMONIAL
        </h3>
      </div>
    </div>
    <div
      class="container col-lg-12 d-flex flex-column justify-content-center align-items-center pt-4"
    >
      <div
        id="myCarousel"
        class="carousel slide"
        data-ride="carousel"
        data-interval="5000"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                CEO
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                CEO of Leading Educational Institute in Southern India
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financial has been our Outsourced Accounting & Regulatory
                compliance partner from the beginning. Our association is more
                than a decade long and I am still pretty much impressed by their
                adherence to service levels. They have proactively solved issues
                impacting the business and have been consistently providing
                inputs on key performance indicators. This has helped me in
                running the business more effectively. The problem solving
                ability of the team continues to amaze.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                President/CEO
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                A leading SAP solution provider
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financial have been an asset to us and we consider them
                as an internal partner who has helped us on our growth path.
                Since we are working in multiple geographies, the biggest
                advantage of being associated with Boston Financial is that we
                have to talk to a single agency who does all our compliances and
                presents us with timely & accurate consolidated financial at all
                times
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center  text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Sunanda Subramanyam
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Manager (Finance), Ephicacy Lifescience Analytics
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financials is associated with Ephicacy as its accounting
                partner for 6 years. Their team comprising experts in finance,
                legal domain, have done a splendid job in efficiently managing
                Ephicacy's compliance to statutory and regulatory requirements
                without compromising data security. Over the years, they have
                become an extended arm of Ephicacy Finance and have earned the
                tag of 'trustworthy and reliable partners. As we continue our
                association with Boston team, we strongly recommend the same to
                others too!
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Atul Siddam
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Director, Diligent Tech India Private Limited
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financial have been an asset to us and we consider them
                as an internal partner who has helped us on our growth path.
                Since we are working in multiple geographies, the biggest
                advantage of being associated with Boston Financial is that we
                have to deal with a single agency who does all our compliances
                and presents us with timely & accurate consolidated financials.
                Their expertise in Corporate Structuring has helped us a lot in
                venturing overseas.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Solution Provider
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                A Communication & Data Connectivity Solution Provider
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Your Outsourced Accounting & Reporting services have always been
                excellent. Delivering in a complex manufacturing set-up,
                post-merger integration support, Global reporting, everything on
                time and always meeting the expectations. Great team that never
                fails to come up with solutions.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Leading IT Architecture Solution Provider
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                A Leading IT Architecture Solution Provider For Banks
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                I am continually pleased with Boston Financial, their
                professionalism, their service levels and their round the clock
                support. Would definitely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                JACKSON L.
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Munsey-Chief Executive Office, Washburn Law, PLLC
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                We have utilized Boston Financial services for the past 18
                months. They have identified management and accounting issues
                that have resulted in improved processing and real time
                information. This has allowed management us to deal with issues
                immediately before they become problems. Timely, precise and
                correct information always has been the hallmark of Boston
                Financial. They have helped double our revenue and improve our
                operational cost. Great team and an asset to our firm.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Company Manager
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                A Top Company in Wound Management
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                
                  color: white !important;
                "
              >
                We have been associated with Boston Financial right from
                incorporating a legal entity in India. Though we are globally
                associated with the big fours we selected them for incorporation
                as well as on-going compliance support. They have an excellent
                team that always delivers.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Director
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Director of a CRM Company
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financial has been our Outsourced F & A partner for the
                past many years. | am continually pleased with their
                professionalism, service levels and round the clock support.
                Would definitely recommend them.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                CPA Firm
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Florida Based Mid Sized CPA Firm
              </span>
              <p
                class="card-text text-center text-white pt-3 "
                style="
                  line-height: normal;
                  text-align: justify;
                
                  color: white !important;
                "
              >
                Boston Financial has been assisting in Accounting, Tax
                preparation for the past 3 years. Their delivery capability and
                resources are top notch. Ideal partners for back-office
                operations, would surely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                CEO
              </p>
              <span class="text-center text-white" style=" font-size: 16px">
                CEO Of Venture Capital And Healthcare Service PROVIDER
              </span>
              <p
                class="card-text text-center text-white pt-3 "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                I am continually pleased with Boston Financial, their
                professionalism, their service levels and their round the clock
                support. Would definitely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                CEO
              </p>
              <span class="text-center text-white" style=" font-size: 16px">
                CEO of IT Service Company
              </span>
              <p
                class="card-text text-center text-white pt-3 "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Your service has always been spectacular. Whether it be
                incorporation, structuring, Compliance, always there to support.
                MIS and reporting are the hallmarks which have benefitted me a
                lot in making timely decisions. Never felt like Boston Financial
                is an Outsourced Accounting partner, we think of them as a team.
              </p>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#myCarousel"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#myCarousel"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <!-- row -->
    </div>
  </div>
</section>


<div class="container col-lg-12 pt-80 pb-80 d-flex flex-column justify-content-center  align-items-center">
  <div class="d-flex justify-content-center align-items-center">
    <h3
      class="title p-0 m-0"
      style="border-bottom: 3px solid #072F5F; display: inline"
    >
    OUR ESTEEMED CLIENTS
    </h3>
  </div>
  <div class="pt-5 d-flex flex-column justify-content-center align-items-center">
      <div class="d-flex justify-content-center align-items-center">
       <div class="border p-0 m-0 d-flex justify-content-center align-items-center">
        <img  src="../../../../assets/images/Client logos/Archwell.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img src="../../../../../assets/images/Client logos/Avanze.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img  src="../../../../../assets/images/Client logos/Berkower.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img  src="../../../../../assets/images/Client logos/BOAT.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img  src="../../../../../assets/images/Client logos/Diligent.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img  src="../../../../../assets/images/Client logos/JioSaavn.png" alt="">
       </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/KIC.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/MSRDC.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/Source Photonics.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/Sproxil.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/Univar.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/Washburn.png" alt="">
         </div>
      </div>



  </div>

</div>



</section>
<!-- india end -->

<!-- USA start -->
<section
  *ngIf="selectCountryName == 'USA'"
  class="min-vh-100 mTop "
>
<section class="pt-20 pb-30 mt-50"
style="position: relative;
  background-color: #002249;
  overflow: hidden;">
    <img src="assets/images/what-we-are-shape-2.png" alt="shape" style="position: absolute; transform: rotate(180deg); top:0; overflow: clip;left: -30%;width: 75%;"/>
    <img src="assets/images/what-we-are-shape-2.png" alt="shape" style="position: absolute; overflow: clip;right: -30%;width: 75%;bottom: -10%;"/>

  <div class="container pt-40" style="min-height: 18rem">
    <div class="row text-center" style="margin-bottom: 0.5rem">
      <div class="col-lg-12">
        <h3
          class="title p-0 m-0"
          style="border-bottom: 3px solid #072F5F; display: inline; color: white;"
        >
          TESTIMONIAL
        </h3>
      </div>
    </div>
    <div
      class="container col-lg-12 d-flex flex-column justify-content-center align-items-center pt-4"
    >
      <div
        id="myCarousel"
        class="carousel slide"
        data-ride="carousel"
        data-interval="5000"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                CEO
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                CEO of Leading Educational Institute in Southern India
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financial has been our Outsourced Accounting & Regulatory
                compliance partner from the beginning. Our association is more
                than a decade long and I am still pretty much impressed by their
                adherence to service levels. They have proactively solved issues
                impacting the business and have been consistently providing
                inputs on key performance indicators. This has helped me in
                running the business more effectively. The problem solving
                ability of the team continues to amaze.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                President/CEO
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                A leading SAP solution provider
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financial have been an asset to us and we consider them
                as an internal partner who has helped us on our growth path.
                Since we are working in multiple geographies, the biggest
                advantage of being associated with Boston Financial is that we
                have to talk to a single agency who does all our compliances and
                presents us with timely & accurate consolidated financial at all
                times
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center  text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Sunanda Subramanyam
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Manager (Finance), Ephicacy Lifescience Analytics
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financials is associated with Ephicacy as its accounting
                partner for 6 years. Their team comprising experts in finance,
                legal domain, have done a splendid job in efficiently managing
                Ephicacy's compliance to statutory and regulatory requirements
                without compromising data security. Over the years, they have
                become an extended arm of Ephicacy Finance and have earned the
                tag of 'trustworthy and reliable partners. As we continue our
                association with Boston team, we strongly recommend the same to
                others too!
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Atul Siddam
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Director, Diligent Tech India Private Limited
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financial have been an asset to us and we consider them
                as an internal partner who has helped us on our growth path.
                Since we are working in multiple geographies, the biggest
                advantage of being associated with Boston Financial is that we
                have to deal with a single agency who does all our compliances
                and presents us with timely & accurate consolidated financials.
                Their expertise in Corporate Structuring has helped us a lot in
                venturing overseas.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Solution Provider
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                A Communication & Data Connectivity Solution Provider
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Your Outsourced Accounting & Reporting services have always been
                excellent. Delivering in a complex manufacturing set-up,
                post-merger integration support, Global reporting, everything on
                time and always meeting the expectations. Great team that never
                fails to come up with solutions.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Leading IT Architecture Solution Provider
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                A Leading IT Architecture Solution Provider For Banks
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                I am continually pleased with Boston Financial, their
                professionalism, their service levels and their round the clock
                support. Would definitely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                JACKSON L.
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Munsey-Chief Executive Office, Washburn Law, PLLC
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                We have utilized Boston Financial services for the past 18
                months. They have identified management and accounting issues
                that have resulted in improved processing and real time
                information. This has allowed management us to deal with issues
                immediately before they become problems. Timely, precise and
                correct information always has been the hallmark of Boston
                Financial. They have helped double our revenue and improve our
                operational cost. Great team and an asset to our firm.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Company Manager
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                A Top Company in Wound Management
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                We have been associated with Boston Financial right from
                incorporating a legal entity in India. Though we are globally
                associated with the big fours we selected them for incorporation
                as well as on-going compliance support. They have an excellent
                team that always delivers.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                Director
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Director of a CRM Company
              </span>
              <p
                class="card-text text-center text-white pt-3  "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financial has been our Outsourced F & A partner for the
                past many years. | am continually pleased with their
                professionalism, service levels and round the clock support.
                Would definitely recommend them.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                CPA Firm
              </p>
              <span class="text-center text-white" style="  font-size: 16px">
                Florida Based Mid Sized CPA Firm
              </span>
              <p
                class="card-text text-center text-white pt-3 "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                Boston Financial has been assisting in Accounting, Tax
                preparation for the past 3 years. Their delivery capability and
                resources are top notch. Ideal partners for back-office
                operations, would surely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                CEO
              </p>
              <span class="text-center text-white" style=" font-size: 16px">
                CEO Of Venture Capital And Healthcare Service PROVIDER
              </span>
              <p
                class="card-text text-center text-white pt-3 "
                style="
                  line-height: normal;
                  text-align: justify;
                  
                  color: white !important;
                "
              >
                I am continually pleased with Boston Financial, their
                professionalism, their service levels and their round the clock
                support. Would definitely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="container d-flex flex-column justify-content-center align-items-center"
            >
              <p
                class="card-title text-center text-white m-0 p-0 font-weight-bolder"
                style="font-size: 18px"
              >
                CEO
              </p>
              <span class="text-center text-white" style=" font-size: 16px">
                CEO of IT Service Company
              </span>
              <p
                class="card-text text-center text-white pt-3 "
                style="
                  line-height: normal;
                  text-align: justify;
                
                  color: white !important;
                "
              >
                Your service has always been spectacular. Whether it be
                incorporation, structuring, Compliance, always there to support.
                MIS and reporting are the hallmarks which have benefitted me a
                lot in making timely decisions. Never felt like Boston Financial
                is an Outsourced Accounting partner, we think of them as a team.
              </p>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#myCarousel"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#myCarousel"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <!-- row -->
    </div>
  </div>
</section>


<div class="container col-lg-12 pt-80 pb-80 d-flex flex-column justify-content-center  align-items-center">
  <div class="d-flex justify-content-center align-items-center">
    <h3
      class="title p-0 m-0"
      style="border-bottom: 3px solid #072F5F; display: inline"
    >
    OUR ESTEEMED CLIENTS
    </h3>
  </div>
  <div class="pt-5 d-flex flex-column justify-content-center align-items-center">
      <div class="d-flex justify-content-center align-items-center">
       <div class="border p-0 m-0 d-flex justify-content-center align-items-center">
        <img  src="../../../../assets/images/Client logos/Archwell.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img src="../../../../../assets/images/Client logos/Avanze.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img  src="../../../../../assets/images/Client logos/Berkower.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img  src="../../../../../assets/images/Client logos/BOAT.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img  src="../../../../../assets/images/Client logos/Diligent.png" alt="">
       </div>
       <div class="border d-flex justify-content-center align-items-center">
        <img  src="../../../../../assets/images/Client logos/JioSaavn.png" alt="">
       </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/KIC.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/MSRDC.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/Source Photonics.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/Sproxil.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/Univar.png" alt="">
         </div>
         <div class="border d-flex justify-content-center align-items-center">
          <img src="../../../../../assets/images/Client logos/Washburn.png" alt="">
         </div>
      </div>



  </div>

</div>



</section>
<!-- USA end -->
