<link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet">
<style>
  .form-container {
      width: 90%; /* Adjust the width as needed */
      margin: 0 auto; /* Center align the form horizontally */
      background-color: #f0f0f0;
      padding: 25px;
      overflow-y: auto; /* Enable vertical scrolling */
  }

  .form-group {
      margin-bottom: 20px; /* Increase margin-bottom for better spacing */
  }

  label {
      display: block; /* Ensure labels are displayed in block format */
      /* font-weight: bold; */
  }

  .button {
      width: 200px; /* Adjust button width as needed */
  }

  .form-control {
      width: 100%; /* Full width input boxes */
  }

  .btn-container {
      text-align: center;
  }

  .button {
      width: 210px;
  }

  .head {
      margin-top: 30px; /* Adjust margin-top for better alignment */
      margin-bottom: 20px; /* Add margin-bottom for better spacing */
  }

  /* Set maximum height and enable scrolling for the dropdown menu */
  .select-wrapper select {
      max-height: 200px; /* Adjust the maximum height as needed */
      overflow-y: auto; /* Enable vertical scrolling */
  }
</style>
 <!-- Inner Banner -->
<div class="inner-banner inner-bg5">
  <div class="container">
      <div class="inner-title text-center">
          <h3>DSAR</h3>
          <!-- <ul>
              <li><a routerLink="/">Home</a></li>
              <li><i class='bx bxs-chevron-right'></i></li>
              <li>Privacy Policy</li>
          </ul> -->
      </div>
  </div>
</div>
<!-- Inner Banner End -->

    

<div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center banner-image bmTop" style="
    background: url('assets/images/NewIcon/dsarBanner.jpg') no-repeat center;
    background-size: cover;
    height: 430px;
  ">
          <div class="d-flex flex-column justify-content-center align-items-center">
              <!-- <h3 class="text-white">TRUST CENTER</h3> -->
              <!--<h4 routerLink="/" class="cursor-pointer pt-2 shade" style="color: #69a1bb">Home | Services</h4>-->
          </div>
      </div>
  <div class="row mb-3">
      <div class="col-lg-8 mx-auto text-center">
          <div class="lc-block mb-4">
              <div editable="rich">
                  <p class="badge bg-dark mb-0">DSAR</p>
                  <h4 class="head">DSAR (data subject access request)</h4>
              </div>
          </div>

          <div class="lc-block">
              <div editable="rich">
                  <!-- Content goes here -->
              </div>
          </div>
      </div>
  </div>

  <!-- Add [formGroup]="contact" to the form tag -->
<div class="form-container">
    <form [formGroup]="contact" (ngSubmit)="submitForm()">
      <div class="form-group">
        <label for="website">Website:</label>
        <input type="url" class="form-control" formControlName="websiteUrl" value="https://www.bostonfagroup.com/" disabled>
      </div>
  
      <div class="form-group">
        <label for="name">Your Name :</label>
        <input type="text" class="form-control" formControlName="userName" placeholder="Enter Your Name">
      </div>
  
      <div class="form-group">
        <label for="email">What email address do you use to access the above website/portal</label>
        <input type="email" class="form-control" formControlName="emailAddress" placeholder="Enter Your Email">
      </div>
  
      <div class="form-group">
        <label for="requestOption">You are submitting this request as</label>
        <div class="form-check">
          <input type="radio" class="form-check-input" formControlName="requestAs" value="The person or the parent/guardian of the person, whose name appears above">
          <label class="form-check-label">The person or the parent/guardian of the person, whose name appears above</label>
        </div>
        <div class="form-check">
          <input type="radio" class="form-check-input" formControlName="requestAs" value="An agent authorized by the consumer to make this request on their behalf">
          <label class="form-check-label">An agent authorized by the consumer to make this request on their behalf</label>
        </div>
      </div>
  
      <div class="form-group">
        <label for="ccpaDropdown">CCPA/Other:</label>
        <div class="form-check">
          <input type="radio" class="form-check-input" formControlName="ccpaOhters" value="Know what information is being collected from me">
          <label class="form-check-label">Know what information is being collected from me</label>
        </div>
        <div class="form-check">
          <input type="radio" class="form-check-input" formControlName="ccpaOhters" value="Have my information deleted">
          <label class="form-check-label">Have my information deleted</label>
        </div>
        <div class="form-check">
          <input type="radio" class="form-check-input" formControlName="ccpaOhters" value="Access my personal information">
          <label class="form-check-label">Access my personal information</label>
        </div>
        <div class="form-check">
          <input type="radio" class="form-check-input" formControlName="ccpaOhters" value="Fix Inaccurate information">
          <label class="form-check-label">Fix Inaccurate information</label>
        </div>
        <div class="form-check">
          <input type="radio" class="form-check-input" formControlName="ccpaOhters" value="Receive a copy of my personal information">
          <label class="form-check-label">Receive a copy of my personal information</label>
        </div>
        <div class="form-check">
          <input type="radio" class="form-check-input" formControlName="ccpaOhters" value="Opt out of having my data shared for cross-context behavioral advertising">
          <label class="form-check-label">Opt out of having my data shared for cross-context behavioral advertising</label>
        </div>
        <div class="form-check">
          <input type="radio" class="form-check-input" formControlName="ccpaOhters" value="Limit the use and disclosure of my sensitive personal information">
          <label class="form-check-label">Limit the use and disclosure of my sensitive personal information</label>
        </div>
        <div class="form-check">
          <input type="radio" class="form-check-input" formControlName="ccpaOhters" value="Other (Please specify in comment box below)">
          <label class="form-check-label">Other (Please specify in comment box below)</label>
        </div>
      </div>
  
      <div class="form-group">
        <label for="additionalComments">Additional Comments:</label>
        <textarea class="form-control" formControlName="comment" rows="4" placeholder="Enter your additional comments here"></textarea>
      </div>
      <span class="confirm-text">I Confirm that</span>
      &nbsp; &nbsp;
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="confirmed1">
        <label class="form-check-label">Under penalty of perjury, I declare all the above information to be true and accurate.</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="confirmed2">
        <label class="form-check-label">I understand that the deletion or restriction of my personal data is irreversible and may result in the termination of services with My Great New Website / App.</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="confirmed3">
        <label class="form-check-label">I understand that I will be required to validate my request by email, and I may be contacted in order to complete the request.</label>
      </div>
      <p>&nbsp;</p>
      <div class="btn-container">
        <button type="submit" class="btn btn-primary button" [disabled]="contact.invalid">Submit</button>
      </div>
    </form>
  </div>
  
  <p>&nbsp;</p>
</div>
