<!-- dubai content -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h2
        class="text-center"
        style="border-bottom: 4px solid #21ac51; display: inline-block"
      >
      CORPORATE LAW COMPLIANCE
      </h2>
      <small class="mt-10" style="font-size: 10px;"><a class="text-dark border-0" routerLink="/financial-services">HOME <i class="fas fa-arrow-circle-right"></i>  CORPORATE LAW COMPLIANCE</a></small>
    </div>
    <div class="row mt-50">
      <div class="col-lg-5 imgDiv">
        <div
          class="border-0 rounded d-flex justify-content-center align-items-center"
          style="background: #21ac51; max-width: 460px; height: 100px"
        >
          <img
            src="../../../../assets/images/financial service/corporate_law.jpg"
            class="img-fluid"
            style="max-width: 400px; position: relative; top: 11rem;"
          />
          <a class="imgBtn btn"><i class="far fa-chevron-right" style="color: white;"></i></a>
        </div>
      </div>
      <div class="col-lg-7 pr-0">
        <p
          class="text-dark mb-30"
          style="font-size: 20px; line-height: normal; font-weight: 500"
        >
        BFAG has a team of corporate counsellors and corporate secretaries with decades of experience in the corporate compliance. We have been providing secretarial services both to local and overseas companies, as well as other valued added services in the delivery of our core functions.
        </p>
        <div>
          <ul class="mt-10" style="line-height: 2rem;">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Maintenance of records and statutory registers
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Arranging and convening of Board and Shareholders’ meetings and preparing necessary minutes
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Providing Corporate Secretarial support for any other corporate action taken by the Board
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Drafting corporate documents and agreements
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Consultancy & Compliance on Rights issue, bonus issue, private placements & Buyback of shares.
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Shifting of Registered office within local limits, from one ROC jurisdiction to another and from one State to another.
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Filing statutory forms and annual returns
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Corporate Restructuring
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Consultancy & Compliance on CSR activities
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Assisting in winding up / closure of companies.
            </li>
          </ul>
          
        </div>
      </div>
    </div>
  </div>
<!-- dubai content -->

<!-- India content -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
    CORPORATE LAW COMPLIANCE
    </h2>
    <small class="mt-10" style="font-size: 10px;"><a class="text-dark border-0" routerLink="/financial-services">HOME <i class="fas fa-arrow-circle-right"></i>  CORPORATE LAW COMPLIANCE</a></small>
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/corporate_law.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 11rem;"
        />
        <a class="imgBtn-india btn"><i class="far fa-chevron-right" style="color: white;"></i></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      BFAG has a team of corporate counsellors and corporate secretaries with decades of experience in the corporate compliance. We have been providing secretarial services both to local and overseas companies, as well as other valued added services in the delivery of our core functions.
      </p>
      <div>
        <ul class="mt-10" style="line-height: 2rem;">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Maintenance of records and statutory registers
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Arranging and convening of Board and Shareholders’ meetings and preparing necessary minutes
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Providing Corporate Secretarial support for any other corporate action taken by the Board
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Drafting corporate documents and agreements
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Consultancy & Compliance on Rights issue, bonus issue, private placements & Buyback of shares.
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Shifting of Registered office within local limits, from one ROC jurisdiction to another and from one State to another.
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Filing statutory forms and annual returns
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Corporate Restructuring
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Consultancy & Compliance on CSR activities
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Assisting in winding up / closure of companies.
          </li>
        </ul>
        
      </div>
    </div>
  </div>
</div>
<!-- India content -->

<!-- USA content -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
    CORPORATE LAW COMPLIANCE
    </h2>
    <small class="mt-10" style="font-size: 10px;"><a class="text-dark border-0" routerLink="/financial-services">HOME <i class="fas fa-arrow-circle-right"></i>  CORPORATE LAW COMPLIANCE</a></small>
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/corporate_law.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 11rem;"
        />
        <a class="imgBtn-india btn"><i class="far fa-chevron-right" style="color: white;"></i></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      BFAG has a team of corporate counsellors and corporate secretaries with decades of experience in the corporate compliance. We have been providing secretarial services both to local and overseas companies, as well as other valued added services in the delivery of our core functions.
      </p>
      <div>
        <ul class="mt-10" style="line-height: 2rem;">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Maintenance of records and statutory registers
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Arranging and convening of Board and Shareholders’ meetings and preparing necessary minutes
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Providing Corporate Secretarial support for any other corporate action taken by the Board
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Drafting corporate documents and agreements
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Consultancy & Compliance on Rights issue, bonus issue, private placements & Buyback of shares.
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Shifting of Registered office within local limits, from one ROC jurisdiction to another and from one State to another.
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Filing statutory forms and annual returns
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Corporate Restructuring
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Consultancy & Compliance on CSR activities
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Assisting in winding up / closure of companies.
          </li>
        </ul>
        
      </div>
    </div>
  </div>
</div>
<!-- usa content -->