
<!-- INDIA STARTS -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop divmart">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/Tax-and-compliance-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
              Tax & Regulatory Compliances
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                    <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev" >
                      <a class="collapsed" href="#accounting-and-transition-processing" data-toggle="collapse" data-target="#collapseSevv"
                      aria-expanded="true" aria-controls="collapseSevv" >
                        Accounting & Transaction Processing
                      </a>
                    </div>
                    <div id="collapseSevv" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div class="list-group">
                          <ul class="text-dark serviceul">

                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Receivable
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Fixed Assets Accounting
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Budgeting & Variance Analysis
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Consolidation Reporting & GAAP Conversion
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a  href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span routerLink="/tax-regulatory-compliances">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse show" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li class="shli" routerLink="/income-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Income Tax
                            </li>
                            <li  routerLink="/goods-and-service-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Goods & Service Tax
                            </li>
                            <li routerLink="/corporate-secretarial-compliance">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Corporate Secretarial Compliance
                            </li>
                            <li  routerLink="/fema-advisory">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             FEMA Compliance
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span routerLink="/payroll-advisory">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Payroll - Compensation & Benefit Management
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span routerLink="/consulting-and-advisory-services">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Setting up an entity in the India
                            </li>
                            <li routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Global Corporate Structuring
                            </li>
                            <li routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Plan Preparation
                            </li>
                            <li routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Valuation
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingElevenStartup">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseElevenStartup"
                        aria-expanded="false" aria-controls="collapseElevenStartup">
                        <span routerLink="/startup-solution-services">Startup Solution</span>
                      </a>
                    </div>
                    <div id="collapseElevenStartup" class="collapse" aria-labelledby="headingElevenStartup" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/startup-solution">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Startup Solution
                            </li>
                            <li routerLink="/fund-raising">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Fund Raise
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                    </div>
                    </div>
                </div>
                <div class="col-md-8">
                  <div id="income-tax" style="margin-top: 1rem">
                    <h3>Income Tax </h3>
                    <p class="mb-15 mt-20 text-dark servicep">
                        Navigating the complexities of the Indian income tax system can be a daunting task for individuals and businesses alike. At BFAG we offer specialized Income Tax Services designed to help you effectively manage your tax obligations. Our experienced team of tax professionals ensures that you stay compliant with the ever-evolving tax regulations while minimizing your tax liabilities.
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                    Personalized Tax Planning and Consulting
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>BFAG understands that each taxpayer's situation is unique. Our experts work closely with businesses to develop customized tax planning strategies that maximize tax savings and minimize liabilities. We analyze your financial situation, investments, and income sources to identify potential tax deductions, exemptions, and credits, ensuring you take full advantage of available tax benefits.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                            <div class="card-header" id="headingTwelve">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                    Business Tax Return Preparation
                                                </a>
                                            </div>
                                            <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Preparing and filing accurate income tax returns is essential for businesses to comply with tax laws. BFAG provides meticulous tax return preparation services, ensuring all relevant income, deductions, and exemptions are properly accounted for. Our team stays up to date with the latest tax regulations and leverages technology to streamline the filing process, saving you time and effort.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                          <div class="card-header" id="headingSixteen">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                               Tax Compliance and Risk Management
                                              </a>
                                          </div>
                                          <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p>Maintaining tax compliance is critical to avoid penalties and legal complications. BFAG assists you in understanding and complying with various income tax provisions, including Identifying Tax exposures and Tax planning, advance tax payments, TDS (Tax Deducted at Source) compliance, tax audit support, and filing of tax declarations. We help you manage your tax-related risks and ensure that your tax records are accurate and up to date.</p>
                                              </div>
                                          </div>
                                      </div>


                                    </div>

                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                            <div class="card-header" id="headingFourteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                    International Taxation and Transfer Pricing
                                                </a>
                                            </div>
                                            <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>In an increasingly globalized world, cross-border transactions and international tax obligations have become more complex. BFAG offers specialized expertise in international taxation, including transfer pricing, double taxation avoidance, foreign tax credit planning, and tax treaty interpretation. Our team assists multinational businesses in optimizing their global tax positions and ensuring compliance with international tax regulations.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                            <div class="card-header" id="headingFifteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                    Tax Dispute Resolution
                                                </a>
                                            </div>
                                            <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>In the event of tax assessments, BFAG provides effective assistance. Our tax professionals have in-depth knowledge of the tax dispute resolution process and can help you navigate audits, assessments, and appeals. We work diligently to protect your interests, ensuring a fair resolution of tax-related disputes.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".7s">
                                          <div class="card-header" id="headingSeventeen">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                                Tax Advisory and Updates
                                              </a>
                                          </div>
                                          <div id="collapseSeventeen" class="collapse " aria-labelledby="headingSeventeen" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p>Staying informed about changes in tax laws and regulations is crucial for effective tax planning. BFAG keeps you updated on relevant tax changes, including amendments to income tax laws, tax incentives, and compliance requirements. Our tax advisory services provide proactive guidance on how to adapt your tax strategies to optimize savings and compliance in a rapidly evolving tax landscape.</p>
                                              </div>
                                          </div>
                                      </div>

                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                    Managing income tax obligations requires expertise, careful planning, and staying abreast of tax regulations. BFAG's Income Tax Services offer a comprehensive suite of solutions to meet your individual and business tax needs. From personalized tax planning and return preparation to tax compliance, international taxation, dispute resolution, and ongoing advisory, our team is committed to optimizing your tax position while ensuring compliance with tax laws. Contact us today to benefit from our expert income tax services and take control of your tax obligations.
                </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>
<!-- INDIA ENDS -->
