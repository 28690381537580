import { Component, OnInit } from '@angular/core';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css'],
})
export class BlogsComponent implements OnInit {
  selectCountryName: string;
  defaultCountry: string;
  selectedFlag: string;
  blogs: any;

  constructor(private LocalStorageServiceService: LocalStorageServiceService) {}

  ngOnInit(): void {
    this.defaultCountry = 'USA';

    this.selectCountryName = localStorage.getItem('selectedCountry');

    if (this.selectCountryName == null) {
      localStorage.setItem('selectedCountry', this.defaultCountry);
      window.location.reload();
    } else {
      this.selectCountryName = localStorage.getItem('selectedCountry');
    }

    this.fetchBlogs()
  }

  selectCountry(country: string): void {
    // Update the selectedFlag with the corresponding image path
    if (country === 'Dubai') {
      this.selectedFlag =
        '/assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png';
    } else if (country === 'India') {
      this.selectedFlag = '/assets/images/flag/download.png';
    } else if (country === 'USA') {
      this.selectedFlag = '/assets/images/flag/USA.png';
    } else {
      // Set USA as the default country
      this.selectedFlag =
        '/assets/images/flag/USA.png';
      country = 'USA';
    }

    this.LocalStorageServiceService.setItem('selectedCountry', country);
  }

   async fetchBlogs() {
    const res = await fetch(`https://rich-tan-dhole-kilt.cyclic.app/api/blogs/`, {
      method: 'GET',
    });
    this.blogs = await res.json();
    console.log(this.blogs);
  }
}
