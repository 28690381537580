import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { metaData } from './services/metaData';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { ContactModalComponent } from './components/dialog/contact-modal/contact-modal.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
 
// import { RecaptchaModule } from 'ng-recaptcha';
import { QuicklinkModule } from 'ngx-quicklink';
import { NgxSelectModule } from 'ngx-select-ex';
import { CustomCaptcha } from './services/custom-captcha';
import { WhyBfagComponent } from './components/pages/about/why-bfag/why-bfag.component';
import { OurTeamComponent } from './components/pages/about/our-team/our-team.component';
import { ClientSpeaksComponent } from './components/pages/about/client-speaks/client-speaks.component';
import { MissionAndVisionComponent } from './components/pages/about/mission-and-vision/mission-and-vision.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { FinancialServicesComponent } from './components/pages/financial-services/financial-services.component';
import { SourceToPayComponent } from './components/pages/source-to-pay/source-to-pay.component';
import { OrderToCashComponent } from './components/pages/order-to-cash/order-to-cash.component';
import { CpaSolutionComponent } from './components/pages/cpa-solution/cpa-solution.component';
import { AccountingForTheAccountantsComponent } from './components/pages/accounting-for-the-accountants/accounting-for-the-accountants.component';
import { TaxReturnPreparationComponent } from './components/pages/tax-return-preparation/tax-return-preparation.component';
import { ComplianceInboxComponent } from './components/pages/compliance-inbox/compliance-inbox.component';
import { ExpenseInboxComponent } from './components/pages/expense-inbox/expense-inbox.component';
import { AnalyticsInboxComponent } from './components/pages/analytics-inbox/analytics-inbox.component';
import { NewsletterComponent } from './components/pages/newsletter/newsletter.component';
import { CaseStudiesComponent } from './components/pages/case-studies/case-studies.component';
import { BlogsComponent } from './components/pages/blogs/blogs.component';
import { CareersComponent } from './components/pages/careers/careers.component';
import { AuditAndAssuranceSupportComponent } from './components/pages/audit-and-assurance-support/audit-and-assurance-support.component';
import { ConsultingAndAdvisoryServicesComponent } from './components/pages/consulting-and-advisory-services/consulting-and-advisory-services.component';
import { StartupSolutionComponent } from './components/pages/startup-solution/startup-solution.component';
import { TechnologyComponent } from './components/pages/technology/technology.component';
import { CorporateStructuringComponent } from './components/pages/corporate-structuring/corporate-structuring.component';
import { RegistrationUnderStartUpIndiaComponent } from './components/pages/registration-under-start-up-india/registration-under-start-up-india.component';
import { BusinessPlansComponent } from './components/pages/business-plans/business-plans.component';
import { FundRaisingComponent } from './components/pages/fund-raising/fund-raising.component';
import { MaSupportComponent } from './components/pages/ma-support/ma-support.component';
import { FaqOnGstComponent } from './components/pages/faq-on-gst/faq-on-gst.component';
import { FaqOnPayrollComponent } from './components/pages/faq-on-payroll/faq-on-payroll.component';
import { FaqOnFemaComponent } from './components/pages/faq-on-fema/faq-on-fema.component';
import { FaqOnCpaSolutionComponent } from './components/pages/faq-on-cpa-solution/faq-on-cpa-solution.component';
import { FinancialAnalysisComponent } from './components/pages/financial-analysis/financial-analysis.component';
import { GlobalReportComponent } from './components/pages/global-report/global-report.component';
import { DirectTaxServicesComponent } from './components/pages/direct-tax-services/direct-tax-services.component';
import { IndirectTaxServicesComponent } from './components/pages/indirect-tax-services/indirect-tax-services.component';
import { CorporateLawComplianceComponent } from './components/pages/corporate-law-compliance/corporate-law-compliance.component';
import { PayrollCompensationAndBenefitManagementComponent } from './components/pages/payroll-compensation-and-benefit-management/payroll-compensation-and-benefit-management.component';
import { BusinessSetupAndCorporateStructuringComponent } from './components/pages/business-setup-and-corporate-structuring/business-setup-and-corporate-structuring.component';
import { GlobalComplianceManagementComponent } from './components/pages/global-compliance-management/global-compliance-management.component';
import { CfoServicesAndDebitComponent } from './components/pages/cfo-services-and-debit/cfo-services-and-debit.component';
import { FemaAdvisoryComponent } from './components/pages/fema-advisory/fema-advisory.component';
import { HowWeDoItComponent } from './components/pages/how-we-do-it/how-we-do-it.component';
import { ExecutionMethodologyComponent } from './components/pages/how-we-do-it/execution-methodology/execution-methodology.component';
import { DataSecurityAndConfidentialityComponent } from './components/pages/how-we-do-it/data-security-and-confidentiality/data-security-and-confidentiality.component';
import { EngagementProcessComponent } from './components/pages/how-we-do-it/engagement-process/engagement-process.component';
import { ItCompanyCaseStudyComponent } from './components/pages/case-studies/it-company-case-study/it-company-case-study.component';
import { LawFirmCaseStudyComponent } from './components/pages/case-studies/law-firm-case-study/law-firm-case-study.component';
import { CpaCaseStudyComponent } from './components/pages/case-studies/cpa-case-study/cpa-case-study.component';
import { VcFirmCaseStudyComponent } from './components/pages/case-studies/vc-firm-case-study/vc-firm-case-study.component';
import { FraudAlertComponent } from './components/pages/fraud-alert/fraud-alert.component';
import { MediaHitsComponent } from './components/pages/media-hits/media-hits.component';
import { CoordinationAndAssistanceForTaxAuditComponent } from './components/pages/audit-and-assurance-support/coordination-and-assistance-for-tax-audit/coordination-and-assistance-for-tax-audit.component';
import { CoordinationAndAssistanceForVatAuditComponent } from './components/pages/audit-and-assurance-support/coordination-and-assistance-for-vat-audit/coordination-and-assistance-for-vat-audit.component';
import { BusinessPlanPreparationComponent } from './components/pages/consulting-and-advisory-services/business-plan-preparation/business-plan-preparation.component';
import { BusinessValuationServiceComponent } from './components/pages/consulting-and-advisory-services/business-valuation-service/business-valuation-service.component';
import { FinancialDueDiligenceServicesComponent } from './components/pages/consulting-and-advisory-services/financial-due-diligence-services/financial-due-diligence-services.component';
import { FundRaiseComponent } from './components/pages/consulting-and-advisory-services/fund-raise/fund-raise.component';
import { MergersAndAcquisitionsComponent } from './components/pages/consulting-and-advisory-services/mergers-and-acquisitions/mergers-and-acquisitions.component';
import { StrategicCfoServicesComponent } from './components/pages/consulting-and-advisory-services/strategic-cfo-services/strategic-cfo-services.component';
import { TaxRegulatoryCompliancesComponent } from './components/pages/tax-regulatory-compliances/tax-regulatory-compliances.component';
import { AccountingServicesComponent } from './components/pages/accounting-services/accounting-services.component';
import { PrafullaProfileComponent } from './components/pages/profile/prafulla-profile/prafulla-profile.component';
import { SameerProfileComponent } from './components/pages/profile/sameer-profile/sameer-profile.component';
import { SanjayProfileComponent } from './components/pages/profile/sanjay-profile/sanjay-profile.component';
import { GaneshProfileComponent } from './components/pages/profile/ganesh-profile/ganesh-profile.component';
import { AmitProfileComponent } from './components/pages/profile/amit-profile/amit-profile.component';
import { KartikProfileComponent } from './components/pages/profile/kartik-profile/kartik-profile.component';
import { AmmarProfileComponent } from './components/pages/profile/ammar-profile/ammar-profile.component';
import { PayrollAdvisoryComponent } from './components/pages/payroll-advisory/payroll-advisory.component';
// import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AccountingAndTransactionProcessingComponent } from './components/pages/accounting-and-transaction-processing/accounting-and-transaction-processing.component';
import { AccountsPayableComponent } from './components/pages/accounting-and-transaction-processing/accounts-payable/accounts-payable.component';
import { AccountsReceivableComponent } from './components/pages/accounting-and-transaction-processing/accounts-receivable/accounts-receivable.component';
import { FixedAssetsAccountingComponent } from './components/pages/accounting-and-transaction-processing/fixed-assets-accounting/fixed-assets-accounting.component';
import { FinancialReportingComponent } from './components/pages/accounting-and-transaction-processing/financial-reporting/financial-reporting.component';
import { BudgetingAndVarianceAnalysisComponent } from './components/pages/accounting-and-transaction-processing/budgeting-and-variance-analysis/budgeting-and-variance-analysis.component';
import { ReconciliationServicesComponent } from './components/pages/accounting-and-transaction-processing/reconciliation-services/reconciliation-services.component';
import { ConsolidationReportingGaapConversionComponent } from './components/pages/accounting-and-transaction-processing/consolidation-reporting-gaap-conversion/consolidation-reporting-gaap-conversion.component';
import { SmallBusinessAccountingComponent } from './components/pages/accounting-and-transaction-processing/small-business-accounting/small-business-accounting.component';
import { IndividualTaxReturnComponent } from './components/pages/tax-regulatory-compliances/individual-tax-return/individual-tax-return.component';
import { CorporateTaxReturnComponent } from './components/pages/tax-regulatory-compliances/corporate-tax-return/corporate-tax-return.component';
import { SalesAndUseTaxComponent } from './components/pages/tax-regulatory-compliances/sales-and-use-tax/sales-and-use-tax.component';
import { PayrollProcessingComponent } from './components/pages/payroll-processing/payroll-processing.component';
import { BusinessSetupComponent } from './components/pages/consulting-and-advisory-services/business-setup/business-setup.component';
import { UnderDevelopmentComponent } from './components/pages/under-development/under-development.component';
import { CpaBackOfficeComponent } from './components/pages/cpa-back-office/cpa-back-office.component';
import { FinancialAccountingSolutionComponent } from './components/pages/financial-accounting-solution/financial-accounting-solution.component';
import { AccountingComponent } from './components/pages/accounting/accounting.component';
import { AuditSupportComponent } from './components/pages/audit-support/audit-support.component';
import { GlobalCorporateStructuringComponent } from './components/pages/consulting-and-advisory-services/global-corporate-structuring/global-corporate-structuring.component';
import { VatAdvisoryComponent } from './components/pages/tax-regulatory-compliances/vat-advisory/vat-advisory.component';
import { IncomeTaxComponent } from './components/pages/income-tax/income-tax.component';
import { GoodsServiceTaxComponent } from './components/pages/goods-service-tax/goods-service-tax.component';
import { CorporateSecretarialComplianceComponent } from './components/pages/corporate-secretarial-compliance/corporate-secretarial-compliance.component';
import { InternalAuditComponent } from './components/pages/internal-audit/internal-audit.component';
import { StatutoryAuditComponent } from './components/pages/statutory-audit/statutory-audit.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { StartupSolutionServicesComponent } from './components/pages/startup-solution-services/startup-solution-services.component';
import { FaqDubaiComponent } from './components/pages/faq-dubai/faq-dubai.component';
import { FaqIndiaComponent } from './components/pages/faq-india/faq-india.component';
import { FaqUsaComponent } from './components/pages/faq-usa/faq-usa.component';
import { FaqOdiComponent } from './components/pages/faq-odi/faq-odi.component';
import { FaqFdiComponent } from './components/pages/faq-fdi/faq-fdi.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { FirstBlogComponent } from './components/pages/first-blog/first-blog.component';
import { NewsletterDetailsComponent } from './components/pages/newsletter-details/newsletter-details.component';
import { TrustCenterComponent } from './components/pages/trust-center/trust-center.component';
import { CcpaPageComponent } from './components/pages/ccpa-page/ccpa-page.component';
import { DsarPageComponent } from './components/pages/dsar-page/dsar-page.component';
import { IsoPageComponent } from './components/pages/iso-page/iso-page.component';
import { CookiesPolicyComponent } from './components/pages/cookies-policy/cookies-policy.component';
import { DsarViewComponent } from './dsar-view/dsar-view.component';
import { CookiesViewComponent } from './components/pages/cookies-view/cookies-view.component';
import { EndPointSecurityComponent } from './components/pages/end-point-security/end-point-security.component';
import { KetanChokshiComponent } from './components/pages/profile/ketan-chokshi/ketan-chokshi.component';
import { MyNewComponentComponent } from './components/pages/profile/my-new-component/my-new-component.component';
import { AnoopMehtaComponent } from './components/pages/profile/anoop-mehta/anoop-mehta.component';


@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    ContactModalComponent,
    WhyBfagComponent,
    OurTeamComponent,
    ClientSpeaksComponent,
    MissionAndVisionComponent,
    ServicesComponent,
    FinancialServicesComponent,
    SourceToPayComponent,
    OrderToCashComponent,
    CpaSolutionComponent,
    AccountingForTheAccountantsComponent,
    TaxReturnPreparationComponent,
    ComplianceInboxComponent,
    ExpenseInboxComponent,
    AnalyticsInboxComponent,
    NewsletterComponent,
    CaseStudiesComponent,
    BlogsComponent,
    CareersComponent,
    AuditAndAssuranceSupportComponent,
    ConsultingAndAdvisoryServicesComponent,
    StartupSolutionComponent,
    TechnologyComponent,
    CorporateStructuringComponent,
    RegistrationUnderStartUpIndiaComponent,
    BusinessPlansComponent,
    FundRaisingComponent,
    MaSupportComponent,
    FaqOnGstComponent,
    FaqOnPayrollComponent,
    FaqOnFemaComponent,
    FaqOnCpaSolutionComponent,
    FinancialAnalysisComponent,
    GlobalReportComponent,
    DirectTaxServicesComponent,
    IndirectTaxServicesComponent,
    CorporateLawComplianceComponent,
    PayrollCompensationAndBenefitManagementComponent,
    BusinessSetupAndCorporateStructuringComponent,
    GlobalComplianceManagementComponent,
    CfoServicesAndDebitComponent,
    FemaAdvisoryComponent,
    HowWeDoItComponent,
    ExecutionMethodologyComponent,
    DataSecurityAndConfidentialityComponent,
    EngagementProcessComponent,
    ItCompanyCaseStudyComponent,
    LawFirmCaseStudyComponent,
    CpaCaseStudyComponent,
    VcFirmCaseStudyComponent,
    FraudAlertComponent,
    MediaHitsComponent,
    CoordinationAndAssistanceForTaxAuditComponent,
    CoordinationAndAssistanceForVatAuditComponent,
    BusinessPlanPreparationComponent,
    BusinessValuationServiceComponent,
    FinancialDueDiligenceServicesComponent,
    FundRaiseComponent,
    MergersAndAcquisitionsComponent,
    StrategicCfoServicesComponent,
    TaxRegulatoryCompliancesComponent,
    AccountingServicesComponent,
    PrafullaProfileComponent,
    SameerProfileComponent,
    SanjayProfileComponent,
    GaneshProfileComponent,
    AmitProfileComponent,
    KartikProfileComponent,
    AmmarProfileComponent,
    PayrollAdvisoryComponent,
    AccountingAndTransactionProcessingComponent,
    AccountsPayableComponent,
    AccountsReceivableComponent,
    FixedAssetsAccountingComponent,
    FinancialReportingComponent,
    BudgetingAndVarianceAnalysisComponent,
    ReconciliationServicesComponent,
    ConsolidationReportingGaapConversionComponent,
    SmallBusinessAccountingComponent,
    IndividualTaxReturnComponent,
    CorporateTaxReturnComponent,
    SalesAndUseTaxComponent,
    PayrollProcessingComponent,
    BusinessSetupComponent,
    UnderDevelopmentComponent,
    CpaBackOfficeComponent,
    FinancialAccountingSolutionComponent,
    AccountingComponent,
    AuditSupportComponent,
    GlobalCorporateStructuringComponent,
    VatAdvisoryComponent,
    IncomeTaxComponent,
    GoodsServiceTaxComponent,
    CorporateSecretarialComplianceComponent,
    InternalAuditComponent,
    StatutoryAuditComponent,
    StartupSolutionServicesComponent,
    FaqDubaiComponent,
    FaqIndiaComponent,
    FaqUsaComponent,
    FaqOdiComponent,
    FaqFdiComponent,
    BlogDetailsComponent,
    FirstBlogComponent,
    NewsletterDetailsComponent,
    TrustCenterComponent,
    CcpaPageComponent,
    DsarPageComponent,
    IsoPageComponent,
    CookiesPolicyComponent,
    DsarViewComponent,
    CookiesViewComponent,
    EndPointSecurityComponent,
    KetanChokshiComponent,
    MyNewComponentComponent,
    AnoopMehtaComponent,

  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatNativeDateModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatSnackBarModule,
    MatInputModule,
    HttpClientModule,
    MatDatepickerModule,
    MatRadioModule,
    MatFormFieldModule,
    // RecaptchaModule,
    QuicklinkModule,
    NgxSelectModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule
  ],

  entryComponents: [ContactModalComponent],
  providers: [  metaData, CustomCaptcha,   {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],

})

export class AppModule { }
