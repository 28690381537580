<app-preloader></app-preloader>
<app-header *ngIf="showHeader"></app-header>
<router-outlet></router-outlet>
<app-footer *ngIf="showFooter"></app-footer>



<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
<style>
  @keyframes borderAnimation {
  0% {
    border-color: #64b0f3;
  }
  25% {
    border-color: #779ad5;
  }
  50% {
    border-color: #FF4136;
  }
  75% {
    border-color: #FF851B;
  }
  100% {
    border-color: #64b0f3;
  }
}

.fixed-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex; /* Use flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  /* background-color: #13a718; */
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  z-index: 2;
  bottom: 7%;
}

.fixed-button:hover {
  background-color: #0056b3;
}

/* .icon-large {
  font-size: 40px;
} */

.gradient-background {
    background: 'white';
  
}
.text-black {
    color: black;
}

.font-bold {
    font-weight: bold;
}
li {
  cursor: pointer;
  margin-bottom: 10px;
}

.active {
  font-weight: bold; /* Example of style change when active */
}

.description {
  margin-top: 5px;
  padding: 5px;
  background-color: #f0f0f0;
  border: 1px solid #000000;
  border-radius: 5px;
  display: block;
}


/* Banner code start  */
.cookie-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align content horizontally at center */
}

.cookie-description {
  margin-bottom: 20px;
  text-align: center; /* Center align text */
  color: #343a40;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust as needed */
  justify-content: center; /* Align buttons horizontally at center */
}

.cookie-banner {
  z-index: 9999;
  position: fixed;
  top: 78%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the banner */
  width: 80%;
  /* max-width: 500px;  */
  background-color: #B2BEB5;
  color: #000;
  border-radius: 0; /* Remove border-radius for full-width */
  padding: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.cookie-content {
  position: relative; /* To position close icon */
}

.cookie-banner button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.cookie-banner button:hover {
  background-color: #0074D9; /* Change background color on hover */
  color: #fff; /* Change text color on hover */
  z-index: 2;
}

.close-icon {
  cursor: pointer;
  align-self: flex-end; /* Align icon to the end of the container */
}
.shadowed-hr {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}


/* button */
/* Example custom button styles */
.button-container button {
    margin-right: 20px; /* Optional: Adjust margin between buttons */
}

/* Override button background colors */
.mat-raised-button.mat-primary {
    background-color: #0074D9; /* Set primary button background color */
}

.mat-raised-button.mat-warn {
    background-color: #0074D9; /* Set warning button background color */
}

.mat-raised-button.mat-accent {
    background-color: #0074D9; /* Set accent button background color */
}

/* Hover effect */
.mat-raised-button:hover {
    opacity: 0.8; /* Reduce opacity on hover */
}
.text-color {
  color:rgb(153, 153, 159);
}
.animated-border {
    animation: borderAnimation 2s linear infinite;
  }

/* End */
</style>
<button class="fixed-button" (click)="openAddDialog()">
    <img src=" assets/images/NewIcon/cookies.png" alt="Cookie Icon" class="icon-small">
  </button>
  
 
<ng-template #cookieDialog>
  <div class="container p-3 gradient-background">
    <mat-card style="border-radius: 50px; max-height: 70vh; overflow-y: auto;">
        <mat-card-header>
            <h3 class="my-2 font-bold" style="color: rgb(87, 87, 187); font-weight: bold; margin-left: -33px;">Cookie concern</h3>
        </mat-card-header>

        <mat-card-content>
            <p class="text-color">We use essential cookies to make our site work. With your consent, <br>
                We may also use non-essential cookies to improve user experience and analyze website traffic. <br>
                By Clicking “Accept” you agree to our website’s cookies use as described in our <a routerLink="cookies-policy">Cookie Policy.</a> <br>
                You can change your cookie settings at any time by clicking “Preferences”.
            </p>
            <hr class="shadowed-hr" />
            <h3 class="my-2 font-bold" style="color: rgb(87, 87, 187); font-weight: bold;">Preferences </h3>
            <ol class="text-black">
              <li>  <span class="text-color"> Essentials</span><mat-icon (click)="(isEssential = isEssential ? false : true)" class="mx-2">
                  {{isEssential ? '-' : ''}}
              </mat-icon></li>
              <!-- <div class="description" *ngIf="isEssential">
                This category includes essential cookies for basic site functionality.
              </div> -->

              <li>  <span class="text-color"> Advertising</span><mat-icon (click)="(isadvertising = isadvertising ? false : true)" class="mx-2">
                  {{isadvertising ? '-' : ''}}
              </mat-icon></li>
              <!-- <div class="description" *ngIf="isadvertising">
                This category includes cookies used for marketing and advertising purposes.
              </div> -->

              <li>  <span class="text-color"> Analytics and Customization</span><mat-icon (click)="(isAandC = isAandC ? false : true)" class="mx-2">
                  {{isAandC ? '-' : ''}}
              </mat-icon></li>
              <!-- <div class="description" *ngIf="isAandC">
                This category includes cookies that help us understand and improve user experiences.
              </div> -->

             <li>  <span class="text-color"> Performance and functionality</span><mat-icon (click)="(isPandF = isPandF ? false : true)" class="mx-2">
                  {{isPandF ? '-' : ''}}
              </mat-icon></li>
              <!-- <div class="description" *ngIf="isPandF">
                This category includes cookies that enhance the performance and functionality of the site.
              </div> -->

              <li>  <span class="text-color"> Social Networking</span><mat-icon (click)="(isSandN = isSandN ? false : true)" class="mx-2">
                  {{isSandN ? '-' : ''}}
              </mat-icon></li>
              <!-- <div class="description" *ngIf="isSandN">
                This category includes cookies related to social networking services.
              </div> -->

              <li>  <span class="text-color"> Unclassified</span><mat-icon (click)="(isunclassified = isunclassified ? false : true)" class="mx-2">
                  {{isunclassified ? '-' : ''}}
              </mat-icon></li>
              <!-- <div class="description" *ngIf="isunclassified">
                This category includes unclassified cookies that do not fit into other categories.
              </div> -->
            </ol>

            <hr class="shadowed-hr" />

            <div class="button-container">
                <button mat-raised-button color="primary" mat-dialog-close (click)="showBanner = false; acceptAll()"  style="background-color: #01559f; color: #ffffff; border: 2px solid #dd9b00;">Accept All Cookies</button>
                <button mat-raised-button color="warn" mat-dialog-close (click)="showBanner = false" style="background-color: #5b88d5; color: #ffffff; border: 2px solid #779ad5;">
                    Reject All
                </button>
                <!-- <button mat-raised-button color="accent" (click)="openAddDialog()" style="background-color: #5b88d5; color: #ffffff; border: 2px solid #779ad5;">
                    Customize
                </button> -->
            </div>
        </mat-card-content>
    </mat-card>
</div> 
</ng-template>
<div class="cookie-banner" *ngIf="showBanner">
    <div class="cookie-content">
        <span class="close-icon" (click)="showBanner = false">X</span>
        <p class="cookie-description">We use essential cookies to make our site work. With your consent, We may also use non-essential cookies to improve user experience and analyze website traffic. By Clicking “Accept” you agree to our website’s cookies use as described in our <a routerLink="/cookies-policy">Cookie Policy.</a> You can change your cookie settings at any time by clicking <a (click)="openAddDialog()">“Preferences”</a>.</p>
        <div class="button-container">
          <button mat-dialog-close (click)="showBanner = false; acceptAll()" style="background-color: #01559f; color: #ffffff; border: 2px solid #dd9b00;">Accept All Cookies</button>
          <button mat-dialog-close (click)="showBanner = false" style="background-color: #5b88d5; color: #ffffff; animation: borderAnimation 2s linear infinite;">Reject All</button>
          <button (click)="openAddDialog()" style="background-color: #5b88d5; color: #ffffff; animation: borderAnimation 2s linear infinite;">Customize</button>
        </div>
    </div>
</div>