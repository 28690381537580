import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BlogsNewsService {

  constructor(private http: HttpClient) { }

  blogList(){
    return this.http.get(`${environment.baseUrl}view_blog_list`);
  }

  blogDetails(id){
    return this.http.post(`${environment.baseUrl}view_blog_details`, id);
  }

  popBlogList(){
    return this.http.get(`${environment.baseUrl}view_popular_blog_list`);
  }
  detailsBlogList(data){
    return this.http.post(`${environment.baseUrl}view_details_blog_list`, data);
  }
  
}
