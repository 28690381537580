
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area procurement-and-inventory-control customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Procurement and Inventory Management </h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Procurement and Inventory Management </li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->

<!--====== Procurement and Inventory PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Procurement and Inventory Management </span>
                    <h2 class="title pb-15">Procurement and Inventory <br/> Management </h2>
                    <p>The Procurement and Inventory management module of Horizon is very flexible, user-friendly, and excellent in enhancing the efficiency and productivity of your people and company.It offers you the best solutions in streamlining your
                        procurement and inventory, reducing costs, and time consumed in performing day-to-day tasks. Businesses can manage diverse spend to ensure on-time delivery and to improve contract compliance, cost savings, and visibility into strategic
                        procurement and inventory management.</p>
                    <p>The Procurement and Inventory management module of Horizon is completely integrated with other modules in ERP. The module offers a host of advanced procurement and inventory features such as material requisition, purchase inquiry,
                        supplier quotation analysis, purchase orders, good receipt, purchase invoice, store issue, stock adjustment, and stock transfer. </p>
                    <p>Many features including management of multiple storage centers and locations, specialized inventory management features (serial numbers, batch tracking, and special stock), which are part of a diverse capability in inventory management,
                        are included as standard functionality within the Procurement and Inventory Management module.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Procurement and Inventory PART END ======-->

<!--====== Features  PART START ======-->
<div class="about-faq-area about-faq-area-custom horizon-hrms dec-spacing bg_cover gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Highlights</span>
                    <h2 class="title pb-20">Features</h2>
                </div>
            </div>
            <div class="faq-accordion-3 pt-0 inventory">
                <div class="accordion" id="accordionExample">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOne">
                                <a class="remove-icon collapsed change-line-height" href="" data-toggle="collapse" data-target="" aria-expanded="false" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Product creation with various dimension, category, and grouping
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse hide" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Enable accounting for multiple reporting standards and industries.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwo">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Location wise Store and Stock Management
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts payable to vendors.</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Location wise Bin and Batch Definition
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts receivable for customers.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSev">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseSev">
                                    <i class="fal fa-magic"></i>Department and Job wise Material Requisition
                                </a>
                            </div>
                            <div id="collapseSev" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage fixed assets for multiple reporting standards.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingfour">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapsefour">
                                    <i class="fal fa-magic"></i>Purchase Enquiry to Multiple Supplier with Email Facility
                                </a>
                            </div>
                            <div id="collapsefour" class="collapse" aria-labelledby="headingfour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts receivable for customers.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingfive">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapsefive">
                                    <i class="fal fa-magic"></i>Supplier Quotation Receivingwith an upload facility
                                </a>
                            </div>
                            <div id="collapsefive" class="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts receivable for customers.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingsix">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapsesix">
                                    <i class="fal fa-magic"></i>Supplier Quotation Analysis and Approval
                                </a>
                            </div>
                            <div id="collapsesix" class="collapse" aria-labelledby="headingsix" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts receivable for customers.</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                    </div>

                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingnineteenn">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapsenineteenn" aria-expanded="false" aria-controls="collapsenineteenn">
                                    <i class="fal fa-magic"></i>Purchase Order System with VAT functionality
                                </a>
                            </div>
                            <div id="collapsenineteenn" class="collapse" aria-labelledby="headingnineteenn" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Inventory OR Stock </p>
                                    <p>Jobs OR Projects </p>
                                    <p>Services </p>
                                    <p>Fixed Assets </p>
                                    <p>Sub Contractors</p>


                                    <!-- <p>Manage revenues and costs for internal and external reporting.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingNine">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseNine">
                                    <i class="fal fa-magic"></i>Supplier wise PurchaseContract & Blanket PO
                                </a>
                            </div>
                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts receivable for customers.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingTen">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseTen">
                                    <i class="fal fa-magic"></i>Good Receipt or Delivery Note Management
                                </a>
                            </div>
                            <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts receivable for customers.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEle">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseEle">
                                    <i class="fal fa-magic"></i>Supplier Invoice Booking
                                </a>
                            </div>
                            <div id="collapseEle" class="collapse" aria-labelledby="headingEle" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Perform inventory valuation for multiple reporting standards</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingTwe">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseTwe">
                                    <i class="fal fa-magic"></i>Direct Purchase Vouchers – Receipt and Invoice
                                </a>
                            </div>
                            <div id="collapseTwe" class="collapse" aria-labelledby="headingTwe" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Manage high-volume accounts receivable and accounts payable</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingnineteen">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapsenineteen" aria-expanded="false" aria-controls="collapsenineteen">
                                    <i class="fal fa-magic"></i>Store and Stock Management
                                </a>
                            </div>
                            <div id="collapsenineteen" class="collapse" aria-labelledby="headingnineteen" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Product Expiry Monitoring </p>
                                    <p>Store Issue Note </p>
                                    <p>Stock Transfer and Receipts </p>
                                    <p>Stock Adjustments </p>
                                    <p>Sub Stores and Material at Site</p>


                                    <!-- <p>Manage revenues and costs for internal and external reporting.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingfou">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapsefou">
                                    <i class="fal fa-magic"></i>ApprovalWorkflow Management Setup at any level
                                </a>
                            </div>
                            <div id="collapsefou" class="collapse" aria-labelledby="headingfou" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Manage revenues and costs for internal and external reporting.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingfif">
                                <a class="remove-icon collapsed change-line-height" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapsefif">
                                    <i class="fal fa-magic"></i>Powerful MIS, Analytical and Business Intelligence Reporting
                                </a>
                            </div>
                            <div id="collapsefif" class="collapse" aria-labelledby="headingfif" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Manage revenues and costs for internal and external reporting.</p> -->
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
            <!-- faq accordion -->
        </div>
    </div>
    <!-- row -->
</div>
<!-- container -->
<!--====== Features  PART END ======-->

<!--====== Benefits at a glance PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                    <p class="add-arrow"> Manage the entire procure-to-pay process with capabilities to process requisitions, purchase orders, invoices, and payments in a single solution.</p>
                    <p class="add-arrow"> Improve business performance with real-time access to requisition, purchase order, and other key procurement information.</p>
                    <p class="add-arrow"> Send inquiries and receive a quotation from various vendors. Analyze the quotes and make the best-informed decision for your company. </p>
                    <p class="add-arrow"> Optimize procurement by using Supplier Contract for recurring needs with negotiated terms, tiered pricing, and pre-specified delivery schedules.</p>

                    <p class="add-arrow"> Realize savings by eliminating unauthorized spending and by consolidating multiple requisitions to negotiate favorable bulk-purchase pricing.</p>
                    <p class="add-arrow"> Simplify the procurement process on one system for employees to request and for purchasing departments to acquire goods and services.</p>
                    <p class="add-arrow"> Digital approval capabilities to authorize expenditures in accordance with company policy.Enforce compliance with purchasing thresholds and guidelines.</p>
                    <p class="add-arrow"> Receive items against the purchase orders as they come into the store and utilize the best storage location. Issue the stock to various jobs/departments based on requisition received. Perform the inventory transfer across locations,
                        jobs, and bins with various costing methods available. </p>

                    <p class="add-arrow"> Accurately track and report costs by accounting for specific detailed landed costs and allocating inventory costs by value or quantity.</p>
                    <p class="add-arrow"> Control inventory replenishment with capabilities to minimize excess stock and fulfill orders on time.</p>
                    <p class="add-arrow"> Have the feature to create sub store locations and material at site to have better control of inventory for the running sites/jobs. </p>
                    <p class="add-arrow"> Gain real-time visibility into key inventory trends, on-time delivery metrics, etc.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Benefits at a glance PART END ======-->
