
<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/blogs/blog.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Blog Details</h3>
    </div>
  </div>

  <div class="container-fluid mt-4">
    <div
      class="d-flex row justify-content-center align-items-start pl-4 pr-4"
      style="gap: 2rem"
    >
      <div class="col-lg-8 mt-50 mb-50 border shadow" style="padding: 2rem">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <img
            class="img-fluid"
            [src]="blog?.imgFile.secure_url"
            alt=""
            style="margin-bottom: 2rem; max-height: 290px;"
          />
          <div>
            <div
              style="
                background: #418052;
                display: inline;
                width: fit-content;
                padding: 0.2rem 1rem 0.2rem 1rem;
              "
            >
              <small
                class="text-uppercase text-white"
                style="font-weight: 600 !important; font-size: 15px !important"
                >{{ blog.category }}</small
              >
            </div>
            <div>
              <div class="mt-3">
                <span
                  class="text-capitalize"
                  style="
                    font-size: 1.5rem !important;
                    font-weight: 700 !important;
                    color: #418052 !important;
                  "
                  >Overview of {{ blog.title }}</span
                >
              </div>
              <p class="text-dark servicep">
                {{ blog.description }}
              </p>
            </div>
            <div *ngFor="let subtitle of subarray;">
              <div class="mt-3">
                <span
                  class="text-capitalize"
                  style="
                    font-size: 1.5rem !important;
                    font-weight: 700 !important;
                    color: #418052 !important;
                  "
                  >{{subtitle.title}}</span
                >
              </div>
              <p class="text-dark servicep mb-2 mt-2" style="white-space: pre-line;">
                {{subtitle.description}}
              </p>
            </div>
          
            <div class="d-flex justify-content-start align-items-start mt-3">
              <small
                style="
                  font-weight: 600 !important;
                  font-size: 14px !important;
                  color: #418052;
                "
              >
                <i class="fal fa-user"></i> By {{ blog.author }}</small
              >
              <small
                style="
                  font-weight: 600 !important;
                  font-size: 14px !important;
                  color: #418052;
                  margin-left:1rem
                "
              >
              <i class="fal fa-calendar"></i> {{blog.createdAt | date: 'yyyy/MM/dd'}}</small
              >
            </div>
          </div>
        </div>
      </div>
<!-- BLOG DETAILS ENDS -->
    </div>
  </div>
</section>

<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/blogs/blog.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Blog Details</h3>
    </div>
  </div>

  <div class="container-fluid mt-4">
    <div
      class="d-flex row justify-content-center align-items-start pl-4 pr-4"
      style="gap: 2rem"
    >
      <div class="col-lg-8 mt-50 mb-50 border shadow" style="padding: 2rem">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <img
            class="img-fluid"
            [src]="blog?.imgFile.secure_url"
            alt=""
            style="margin-bottom: 2rem; max-height: 290px;"
          />
          <div>
            <div
              style="
                background: #072f5f;
                display: inline;
                width: fit-content;
                padding: 0.2rem 1rem 0.2rem 1rem;
              "
            >
              <small
                class="text-uppercase text-white"
                style="font-weight: 600 !important; font-size: 15px !important"
                >{{ blog.category }}</small
              >
            </div>
            <div>
              <div class="mt-3">
                <span
                  class="text-capitalize"
                  style="
                    font-size: 1.5rem !important;
                    font-weight: 700 !important;
                    color: #072f5f !important;
                  "
                  >Overview of {{ blog.title }}</span
                >
              </div>
              <p class="text-dark servicep">
                {{ blog.description }}
              </p>
            </div>
            <div *ngFor="let subtitle of subarray;">
              <div class="mt-3">
                <span
                  class="text-capitalize"
                  style="
                    font-size: 1.5rem !important;
                    font-weight: 700 !important;
                    color: #072f5f !important;
                  "
                  >{{subtitle.title}}</span
                >
              </div>
              <p class="text-dark servicep mb-2 mt-2" style="white-space: pre-line;">
                {{subtitle.description}}
              </p>
            </div>
          
            <div class="d-flex justify-content-start align-items-start mt-3">
              <small
                style="
                  font-weight: 600 !important;
                  font-size: 14px !important;
                  color: #072f5f;
                "
              >
                <i class="fal fa-user"></i> By {{ blog.author }}</small
              >
              <small
                style="
                  font-weight: 600 !important;
                  font-size: 14px !important;
                  color: #072f5f;
                  margin-left:1rem
                "
              >
              <i class="fal fa-calendar"></i> {{blog.createdAt | date: 'yyyy/MM/dd'}}</small
              >
            </div>
          </div>
        </div>
      </div>
<!-- BLOG DETAILS ENDS -->
    </div>
  </div>
</section>
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/blogs/blog.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Blog Details</h3>
    </div>
  </div>

  <div class="container-fluid mt-4">
    <div
      class="d-flex row justify-content-center align-items-start pl-4 pr-4"
      style="gap: 2rem"
    >
      <div class="col-lg-8 mt-50 mb-50 border shadow" style="padding: 2rem">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <img
            class="img-fluid"
            [src]="blog?.imgFile.secure_url"
            alt=""
            style="margin-bottom: 2rem; max-height: 290px;"
          />
          <div>
            <div
              style="
                background: #072f5f;
                display: inline;
                width: fit-content;
                padding: 0.2rem 1rem 0.2rem 1rem;
              "
            >
              <small
                class="text-uppercase text-white"
                style="font-weight: 600 !important; font-size: 15px !important"
                >{{ blog.category }}</small
              >
            </div>
            <div>
              <div class="mt-3">
                <span
                  class="text-capitalize"
                  style="
                    font-size: 1.5rem !important;
                    font-weight: 700 !important;
                    color: #072f5f !important;
                  "
                  >Overview of {{ blog.title }}</span
                >
              </div>
              <p class="text-dark servicep">
                {{ blog.description }}
              </p>
            </div>
            <div *ngFor="let subtitle of subarray;">
              <div class="mt-3">
                <span
                  class="text-capitalize"
                  style="
                    font-size: 1.5rem !important;
                    font-weight: 700 !important;
                    color: #072f5f !important;
                  "
                  >{{subtitle.title}}</span
                >
              </div>
              <p class="text-dark servicep mb-2 mt-2" style="white-space: pre-line;">
                {{subtitle.description}}
              </p>
            </div>
          
            <div class="d-flex justify-content-start align-items-start mt-3">
              <small
                style="
                  font-weight: 600 !important;
                  font-size: 14px !important;
                  color: #072f5f;
                "
              >
                <i class="fal fa-user"></i> By {{ blog.author }}</small
              >
              <small
                style="
                  font-weight: 600 !important;
                  font-size: 14px !important;
                  color: #072f5f;
                  margin-left:1rem
                "
              >
              <i class="fal fa-calendar"></i> {{blog.createdAt | date: 'yyyy/MM/dd'}}</small
              >
            </div>
          </div>
        </div>
      </div>
<!-- BLOG DETAILS ENDS -->
    </div>
  </div>
</section>