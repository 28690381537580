<div
  *ngIf="selectCountryName == 'India'"
  class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing min-vh-100 mt-130"
>
  <div class="container text-justify">
    <div class="row">
      <div class="col-md-12">
        <div class="experience-item">
          <h2 class="title pb-15">
            FAQS ON OVERSEAS DIRECTOR INVESTMENT (ODI)
          </h2>
        </div>
        <div class="faq-accordion-3 pt-0">
          <div class="accordion" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingOne">
                <a
                  class="w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What are the governing regulations for making overseas
                  investments?
                </a>
              </div>
              <div
                id="collapseOne"
                class="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    Direct investment outside India is governed by Foreign
                    Exchange Management (Transfer or Issue of Any Foreign
                    Security) (Amendment) Regulations, 2004 (‘FEMA
                    Regulations’), as amended from time to time.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingTwo">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What is Overseas Direct Investment?
                </a>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    Overseas Direct Investments (ODI) refers to the investments
                    made in overseas entities by way of contribution to their
                    capital or subscription to the Memorandum of Association of
                    a foreign entity or by way of purchase of existing shares of
                    a foreign entity either by market purchase or private
                    placement or through the stock exchange, but it does not
                    include portfolio investment.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingThree">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What is ‘Financial Commitment’?
                </a>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    Financial commitment means the amount of direct investments
                    outside India by an Indian Party-
                  </p>
                  <ul>
                    <li>
                      By way of contribution to equity shares or CCPS of the JV
                      / WOS abroad.
                    </li>
                    <li>
                      Contribution to the JV / WOS as preference shares (for
                      reporting purposes to be treated as loans).
                    </li>
                    <li>As loans to its JV / WOS abroad.</li>
                    <li>
                      100% of the amount of corporate guarantee issued on behalf
                      of its overseas JV/WOS
                    </li>
                    <li>
                      50% of the amount of performance guarantee issued on
                      behalf of its overseas JV/WOS.
                    </li>
                    <li>
                      Bank guarantee/standby letter of credit issued by a
                      resident bank on behalf of an overseas JV/WOS of the
                      Indian party, which is backed by a counter
                      guarantee/collateral by the Indian party.
                    </li>
                    <li>
                      Amount of fund/non-fund-based credit facility availed by
                      the creation of charge (pledge/mortgage/hypothecation) on
                      the movable/immovable property or other financial assets
                      of the Indian party / its group companies.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingFour">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Can an Indian Party or a resident Individual make an
                  investment in any activity?
                </a>
              </div>
              <div
                id="collapseFour"
                class="collapse"
                aria-labelledby="headingFour"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    Yes, but, subject to the Regulations and/or prior approval
                    of the Reserve Bank of India (RBI) –
                  </p>

                  <ul>
                    <li>
                      Any person resident in India shall not make any direct
                      investment outside India; and
                    </li>
                    <li>
                      An Indian party shall not make any direct investment in a
                      foreign entity engaged in the
                      <b> real estate or the banking sector </b>
                    </li>
                    <li><b> Gambling </b> in any form</li>
                    <li>
                     <b> Dealing in Financial products </b> linked to INR (without prior
                      RBI approval)
                    </li>
                    <li>
                      Approval Route – Investment/Financial Commitment in
                      Pakistan (permissible under approval route and
                      Jurisdiction as may be advised/notified by the central
                      government.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingFive">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  What are Automatic Route and Approval Routes?
                </a>
              </div>
              <div
                id="collapseFive"
                class="collapse"
                aria-labelledby="headingFive"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    Under the Automatic Route, an Indian Party does not require
                    any prior approval from the Reserve Bank for making overseas
                    direct investments in a JV/WOS abroad. The Indian Party
                    should approach an Authorized Dealer Category – I bank with
                    an application in Form ODI and the prescribed
                    enclosures/documents for effecting the remittances towards
                    such investments. However, in the case of investment in the
                    financial services sector, prior approval is required from
                    the regulatory authority concerned, both in India and
                    abroad.
                  </p>
                  <p class="pb-15">
                    Under the Approval Route, an Indian Party require prior
                    approval from the Reserve Bank for making overseas direct
                    investments in a JV/WOS abroad. The applicant should
                    approach their designated Authorized Dealer (AD) with the
                    proposal which shall be submitted to Reserve Bank after due
                    scrutiny and with the specific recommendations of the
                    designated AD bank along with supporting documents.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingSex">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseSex"
                  aria-expanded="false"
                  aria-controls="collapseSex"
                >
                  What are the limits and requirements for overseas direct
                  investment to be made under the Automatic Route?
                </a>
              </div>
              <div
                id="collapseSex"
                class="collapse"
                aria-labelledby="headingSex"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <ul>
                    <li>
                      The Indian Party can invest
                      <b> up to 400% of its Net worth </b> as of the date of the
                      last balance sheet (Not exceeding 18 months preceding the
                      date of the transaction) subject to an overall limit of
                      USD 1 billion per financial year.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingSeven">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  What is the permissible source of funding?
                </a>
              </div>
              <div
                id="collapseSeven"
                class="collapse"
                aria-labelledby="headingSeven"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <ul>
                    <li>EEFC A/c Balance.</li>
                    <li>ADR/GDR Proceeds.</li>
                    <li>Share Swap.</li>
                    <li>Rupees resources</li>
                    <li>ECB proceeds</li>
                    <li>Capitalization of export receivables/other dues.</li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingEight">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  Are overseas investments freely allowed in all the countries
                  and are there any restrictions regarding the currency of
                  investment?
                </a>
              </div>
              <div
                id="collapseEight"
                class="collapse"
                aria-labelledby="headingEight"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    Investment in Pakistan is allowed under the approval route.
                    Investments in Nepal can be only in Indian Rupees.
                    Investments in Bhutan are allowed in Indian Rupees and in
                    freely convertible currencies.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingNine">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  What is the procedure to be followed by an Indian party to
                  make overseas direct investments in a JV/WOS under the
                  Automatic Route?
                </a>
              </div>
              <div
                id="collapseNine"
                class="collapse"
                aria-labelledby="headingNine"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    The Indian Party intending to make an overseas direct
                    investment under the automatic route is required to fill up
                    form ODI duly supported by the documents listed therein,
                    i.e., a certified copy of the Board Resolution, Statutory
                    Auditor’s certificate and Valuation report (in case of
                    acquisition of an existing company) as per the valuation
                    norms for making the investment/remittance.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingTen">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  Is it mandatory to furnish Annual Performance Reports (APR) of
                  the overseas JV/WOS based on its audited financial statements?
                </a>
              </div>
              <div
                id="collapseTen"
                class="collapse"
                aria-labelledby="headingTen"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    An Indian Party (IP) / Resident Individual (RI) which has
                    made an Overseas Direct Investment (ODI) has to submit an
                    Annual Performance Report (APR) in Form ODI Part III to the
                    Reserve Bank through AD Bank by 31st of December every year
                    in respect of each Joint Venture (JV) / Wholly Owned
                    Subsidiary (WOS) outside India.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingElev">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseElev"
                  aria-expanded="false"
                  aria-controls="collapseElev"
                >
                  What are the various filing requirements in ODI?
                </a>
              </div>
              <div
                id="collapseElev"
                class="collapse"
                aria-labelledby="headingElev"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <ul>
                    <li>
                      Form ODI Part I – Application for making overseas direct
                      investments under the approval route.
                    </li>
                    <li>
                      Form ODI Part II – Annual Performance Report (APR) – To be
                      submitted, certified by Statutory Auditors of the Indian
                      party, through the designated AD Category– I bank every
                      year by December 31st as long, as the JV / WOS is in
                      existence.
                    </li>
                    <li>
                      Form ODI Part III - To be submitted for reporting the
                      closure/ disinvestment/voluntary liquidation, winding up
                      of the JV/WOS abroad.
                    </li>
                    <li>
                      Annual Return on Foreign Liabilities and Assets (FLA
                      Return) – All the Indian Parties having ODI have to file
                      the FLA return every year by July 15.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- card -->
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingTlve">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseTlve"
                  aria-expanded="false"
                  aria-controls="collapse"
                >
                  Is an Indian Party permitted to extend a loan/guarantee on
                  behalf of its overseas JV/WOS?
                </a>
              </div>
              <div
                id="collapseTlve"
                class="collapse"
                aria-labelledby="headingTlve"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    <b> Yes. </b><br />
                    The Indian Party may extend a loan or a guarantee to or on
                    behalf of its JV/WOS abroad, subject to the following –
                  </p>
                  <ul>
                    <li>
                      The same should be within the permissible financial
                      commitment limits.
                    </li>
                    <li>
                      The Indian Party has made the investment by way of
                      contribution to the equity capital of the Joint Venture.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- card -->
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingTeen">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseTeen"
                  aria-expanded="false"
                  aria-controls="collapseTeen"
                >
                  What are the obligations of an Indian party making ODI?
                </a>
              </div>
              <div
                id="collapseTeen"
                class="collapse"
                aria-labelledby="headingTeen"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    An Indian Party which has made ODI in securities of the
                    overseas JV/WOS shall –
                  </p>
                  <ul>
                    <li>
                      Receive the share certificates within 60 days from the
                      date of making investments.
                    </li>
                    <li>
                      Repatriate to India, all dues receivable from the foreign
                      entity, like dividends, royalty, technical fees etc.,
                      within 60 days of its falling due, or such further period
                      as the Reserve Bank may permit
                    </li>
                    <li>
                      Submit to the RBI, through an Authorised Dealer, on or
                      before a specified date, an Annual Performance Report in
                      respect of each overseas JV/WOS of the Indian Party based
                      on annual audited accounts of the preceding years
                    </li>
                    <li>
                      Have to submit an Annual Return on Foreign Assets and
                      Liabilities in a specified format by a specified time to
                      the RBI
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- card -->
          </div>
        </div>
        <!-- faq accordion -->
      </div>
    </div>
    <!-- container -->
  </div>
</div>
