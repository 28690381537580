
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area manufacturing-management customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Manufacturing Management</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Manufacturing Management</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Manufacturing Management PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Manufacturing Solutions</span>
                    <h2 class="title pb-15">Manufacturing Management</h2>
                    <p>Horizon Manufacturing Management Module is perfect suit for make-to-order environment, where the production order is based upon the sales order. In order to succeed in the made-to-order manufacturing industry, you must meet the diverse
                        needs of your customers quickly and cost effectively. You need precise management of each job, good inventory and production controls, and an effective software solution. Our Manufacturing Management Solution steps in as an intelligent
                        link between the sales order module, the production-planning module and the procurement department.</p>
                    <p>You can seamlessly translate sales orders, scrutinize the progress of individual production orders and have a clear picture of what needs to be procured. By combining several production orders and refining the production process to
                        its maximum potential, you can curb wastage and inefficiencies in the cycle.</p>
                    <p>It helps companies to produce cost-effectively, without compromising on quality and quantity. With this module, you can plan, track, analyze, and control major factors including materials, machinery, people and money.</p>
                    <p>It provides a strong link between sales order and production process, helping you translate sales orders into production orders, meet sale delivery commitment, plan production by consolidating confirmed orders and forecasted demands,
                        enabling better planning of inventory; minimizing wastage and inefficiencies, manage work orders, resource allocation and lead time</p>
                    <p>It seamlessly integrates with inventory, purchase, subcontracting and accounts, giving you overall visibility to make timely and sound decisions. You can benefit from a flexible and comprehensive planning, scheduling and execution
                        system regardless of whether you are a single plant, multi-plant, multi-geography or multi-company enterprise.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Manufacturing Management PART END ======-->

<!--====== Key Features PART START ======-->
<div class="about-faq-area about-faq-area-custom horizon-hrms dec-spacing bg_cover gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Highlights</span>
                    <h2 class="title">Key Features</h2>
                </div>
            </div>
            <div class="faq-accordion-3">
                <div class="accordion" id="accordionExample">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Sales Enquiry
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Book the sales enquiry from various customers/clients. Check the status of each enquiry vis-à-vis the quotation and actual order received.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Estimation
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Gain complete freedom in estimating costs for multiple products, clearly and accurately. Distinctly identify the key cost drivers. Determine product prices based on a comprehensive and accurate cost estimate for ensuring
                                        better margins.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Quotation
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Optimize your profit margin with an efficient and accurate quoting module based on the estimation.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSev">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSev" aria-expanded="false" aria-controls="collapseSev">
                                    <i class="fal fa-magic"></i>Work Order
                                </a>
                            </div>
                            <div id="collapseSev" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Get complete transparency of your work orders. Tweak work orders easily to adapt to the dynamic manufacturing environment.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEight">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    <i class="fal fa-magic"></i>Make to Order Manufacturing Schedule
                                </a>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Plan well to keep production rolling, yet maintain optimal inventory levels. Plan work orders, or create manual orders for high priority requirements.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-lg-6">

                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingNine">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    <i class="fal fa-magic"></i>Stock and Job Inventory
                                </a>
                            </div>
                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Cut purchase orders directly from a work order, allowing purchases to be tracked to a job and helping ensure you buy only what you need. Track all materials required for a job</p>
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingTen">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                    <i class="fal fa-magic"></i>Complete Order Traceability
                                </a>
                            </div>
                            <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Manage resources and track the status of production jobs by monitoring dates, activities, material requirements and subcontractor activities</p>
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEleven">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                    <i class="fal fa-magic"></i>Make to Order Driven Scheduling
                                </a>
                            </div>
                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Optimize production process by scheduling with precision. Explore and choose the scheduling functionality and options to manage production schedules.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingTwelve">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                    <i class="fal fa-magic"></i>Make to Order Manufacturing Reporting
                                </a>
                            </div>
                            <div id="collapseTwelve" class="collapse" aria-labelledby="headingTwelve" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Keep a tab on product and facility costs in real time. Easily compare actual manufacturing costs of products with their estimated and planned cost values. Gain access to real time cost data and make future strategies
                                        and decisions based on it.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== Key Features PART END ======-->

<!--====== Benefits at a glance PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                    <p class="add-arrow"> Analyzing material requirements accurately, keeping stocks as low as possible</p>
                    <p class="add-arrow"> Tracking the status of production jobs by monitoring dates, activities, material requirements, and subcontractor activities</p>
                    <p class="add-arrow"> Helping you to quickly look for suppliers and make just-in-time delivery arrangements</p>
                    <p class="add-arrow"> Enabling you to combine production orders and refine the production process to its maximum potential</p>
                    <p class="add-arrow"> Highly flexible processing of orders helps meet irregular sales demands</p>
                    <p class="add-arrow"> Collect time against jobs directly from the shop floor</p>
                    <p class="add-arrow"> Get accurate, up to the minute costing on every job with accessible status reports</p>
                    <p class="add-arrow"> Meet and exceed customer delivery deadlines</p>
                    <p class="add-arrow"> Meet your own job profitability targets</p>
                    <p class="add-arrow"> Run a more efficient business with reduced overhead</p>
                    <p class="add-arrow"> Manage every aspect of your business with a complete, integrated ERP manufacturing software solution</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Benefits at a glance PART END ======-->
