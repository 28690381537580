import { Component, OnInit } from '@angular/core';
import { DsarService } from '../services/dsar-page/dsar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-dsar-view',
  templateUrl: './dsar-view.component.html',
  styleUrls: ['./dsar-view.component.css']
})
export class DsarViewComponent implements OnInit {
  dsars: any[] = [];
  dsarForm: FormGroup;

  constructor(
    private _dsar: DsarService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.dsarForm = this.formBuilder.group({
      websiteUrl: ['', [Validators.required, Validators.pattern('https?://.+')]],
      userName: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.email]],
      requestAs: ['', Validators.required],
      ccpaOhters: ['', Validators.required],
      comment: ['', Validators.required],
      dateTime: ['', Validators.required]
    });

    this._dsar.getAllDsar().subscribe({
      next: (data: any) => {
        this.dsars = data;
      },
      error: error => {
        console.error(error);
        this.snackBar.open("Error occurs while getting the data", 'OK', {
          duration: 5000,
        });
      }
    });
  }

  get f() { return this.dsarForm.controls; }

  onSubmit() {
    if (this.dsarForm.valid) {
      // Handle form submission
      console.log(this.dsarForm.value);
    } else {
      // Handle form errors
      console.log('Form is invalid');
      this.snackBar.open("Form is invalid. Please check the fields.", 'OK', {
        duration: 5000,
      });
    }
  }
}
