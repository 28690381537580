<!-- dubai started -->
<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase text-center">
        Law Firm Case <br />
        Study
      </h3>
    </div>
  </div>
  <div class="container-fluid">
  <div
    class="d-flex row justify-content-center align-items-start pl-4 pr-4"
    style="gap: 2rem"
  >
    <div class="col-lg-8 mt-50 mb-50 border shadow" style="padding: 2rem">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img
          class="img-fluid"
          src="../../../../assets/images/case-studies/law.png"
          alt=""
          style="margin-bottom: 2rem"
        />
        <div>
          <div
            style="
              background: #418052;
              display: inline;
              width: fit-content;
              padding: 0.2rem 1rem 0.2rem 1rem;
            "
          >
            <small
              class="text-uppercase text-white"
              style="font-weight: 600 !important; font-size: 15px !important"
              >case details</small
            >
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >overview</span
              >
            </div>
            <p class="text-dark servicep">
              Law Firm specialized in real estate closing support services and
              advisory services. The team, The team comprises of best of the
              lawyers each heading a different expert area.
            </p>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >Client</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>Washburn Law PLLC</li>
              <li>
                Law firm having offices in North & South Carolina, Georgia.
                Expertise in providing legal services in areas such as real
                estate Closing support services.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >Strategies Described</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Defining the proper off-site Accounting organization structure
                and providing capable resources.
              </li>
              <li>
                Defining the SLA’s for keeping the F & A function online,
                defining the roadmap for ongoing accounting activity.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >Client's Challenges</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                No proper Books of Accounts, QB was used to only print checks.
              </li>
              <li>Everything was done manually, Lack of proper systems.</li>
              <li>
                Lack of information, if at all information was available it was
                in scattered form.
              </li>
              <li>
                No accounting policies in place. AP, AR reimbursements were
                carried out blindly without any supporting and based on the
                demand from vendors and employees.
              </li>
              <li>Lack of co-ordination with the offshore operations team.</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >Solution Provider</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Initially, Accounting organization structure with onsite and offsite mix was put in place with capable & experienced resources. Once function stabilized, the accounting organization structure was changed to offsite, this resulted in huge savings in payroll but without compromise on quality.
              </li>
              <li>

Complete understanding of business was sought and accounting policies, internal controls were put in place. System of payments was put in place.
              </li>
              <li>
                AR reconciliation, Monthly/Daily AR reporting was put in place.
              </li>
              <li>AP reconciliation, Monthly/Daily AP reporting was put in place.</li>
              <li>Monthly/quarterly MIS reports with emphasis on KPI’s was put in place.</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >Outcome</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Better coordination between operations and Accounts departments.
              </li>
              <li>
                Proper recording of vendor invoices after approvals and processing payments as per schedule. Excess payments were stopped, instances of overdue reduced to a large extent.
              </li>
              <li>
                Excellent coordination between operations and Accounting
                function.
              </li>
              <li>
                Increased collections from customers resulting in reduced collection time. Complete stop to revenue leakages.
              </li>
              <li>
                Accurate &Timely MIS reporting.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >Client Testimonial</span
              >
            </div>
            <p class="text-dark servicep" style="font-style: italic;">
              Law Firm specialized in real estate closing support services and
              advisory services. The team, The team comprises of best of the
              lawyers each heading a different expert area.
            </p>

            <p class="text-dark servicep font-weight-light mt-5" style="font-style: italic;">
              – Jack Munsey (CEO)
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--<div
      class="mt-50 border d-flex flex-column justify-content-start align-items-start"
      style="padding: 2rem"
    >
      <div
        class="mt-15 mb-10 d-flex justify-content-center align-items-center"
        style="gap: 5px"
      >
        <div
          class=""
          style="border: 3px solid #072f5f; width: 1.5rem; height: 1px"
        ></div>
        <div
          class=""
          style="border: 3px solid #072f5f; width: 0.5rem; height: 1px"
        ></div>
        <div>
          <span
            class="text-capitalize"
            style="
              font-size: 1.5rem !important;
              font-weight: 700 !important;
              color: #002249 !important;
            "
            >Case Studies</span
          >
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img1.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Facilities <br />
            Management</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 19, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img2.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Specialities Co. <br />
            L.L.C</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 7, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img3.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Specon LLC</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jun 17, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img4.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Al Ahmadiah <br />
            Contracting & Trading</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jun 13, 2020</small
            >
          </div>
        </div>
      </div>
    </div>-->
  </div></div>
</section>
<!-- dubai end -->

<!-- India started -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase text-center">
        Law Firm Case <br />
        Study
      </h3>
    </div>
  </div>
  <div class="container-fluid">
  <div
    class="d-flex row justify-content-center align-items-start pl-4 pr-4"
    style="gap: 2rem"
  >
    <div class="col-lg-8 mt-50 mb-50 border shadow" style="padding: 2rem">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img
          class="img-fluid"
          src="../../../../assets/images/case-studies/law.png"
          alt=""
          style="margin-bottom: 2rem"
        />
        <div>
          <div
            style="
              background: #072f5f;
              display: inline;
              width: fit-content;
              padding: 0.2rem 1rem 0.2rem 1rem;
            "
          >
            <small
              class="text-uppercase text-white"
              style="font-weight: 600 !important; font-size: 15px !important"
              >case details</small
            >
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >overview</span
              >
            </div>
            <p class="text-dark servicep">
              Law Firm specialized in real estate closing support services and
              advisory services. The team, The team comprises of best of the
              lawyers each heading a different expert area.
            </p>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Client</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>Washburn Law PLLC</li>
              <li>
                Law firm having offices in North & South Carolina, Georgia.
                Expertise in providing legal services in areas such as real
                estate Closing support services.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Strategies Described</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Defining the proper off-site Accounting organization structure
                and providing capable resources.
              </li>
              <li>
                Defining the SLA’s for keeping the F & A function online,
                defining the roadmap for ongoing accounting activity.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Client's Challenges</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                No proper Books of Accounts, QB was used to only print checks.
              </li>
              <li>Everything was done manually, Lack of proper systems.</li>
              <li>
                Lack of information, if at all information was available it was
                in scattered form.
              </li>
              <li>
                No accounting policies in place. AP, AR reimbursements were
                carried out blindly without any supporting and based on the
                demand from vendors and employees.
              </li>
              <li>Lack of co-ordination with the offshore operations team.</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Solution Provider</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Initially, Accounting organization structure with onsite and offsite mix was put in place with capable & experienced resources. Once function stabilized, the accounting organization structure was changed to offsite, this resulted in huge savings in payroll but without compromise on quality.
              </li>
              <li>

Complete understanding of business was sought and accounting policies, internal controls were put in place. System of payments was put in place.
              </li>
              <li>
                AR reconciliation, Monthly/Daily AR reporting was put in place.
              </li>
              <li>AP reconciliation, Monthly/Daily AP reporting was put in place.</li>
              <li>Monthly/quarterly MIS reports with emphasis on KPI’s was put in place.</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Outcome</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Better coordination between operations and Accounts departments.
              </li>
              <li>
                Proper recording of vendor invoices after approvals and processing payments as per schedule. Excess payments were stopped, instances of overdue reduced to a large extent.
              </li>
              <li>
                Excellent coordination between operations and Accounting
                function.
              </li>
              <li>
                Increased collections from customers resulting in reduced collection time. Complete stop to revenue leakages.
              </li>
              <li>
                Accurate &Timely MIS reporting.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Client Testimonial</span
              >
            </div>
            <p class="text-dark servicep" style="font-style: italic;">
              Law Firm specialized in real estate closing support services and
              advisory services. The team, The team comprises of best of the
              lawyers each heading a different expert area.
            </p>

            <p class="text-dark servicep font-weight-light mt-5" style="font-style: italic;">
              – Jack Munsey (CEO)
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--<div
      class="mt-50 border d-flex flex-column justify-content-start align-items-start"
      style="padding: 2rem"
    >
      <div
        class="mt-15 mb-10 d-flex justify-content-center align-items-center"
        style="gap: 5px"
      >
        <div
          class=""
          style="border: 3px solid #072f5f; width: 1.5rem; height: 1px"
        ></div>
        <div
          class=""
          style="border: 3px solid #072f5f; width: 0.5rem; height: 1px"
        ></div>
        <div>
          <span
            class="text-capitalize"
            style="
              font-size: 1.5rem !important;
              font-weight: 700 !important;
              color: #002249 !important;
            "
            >Case Studies</span
          >
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img1.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Facilities <br />
            Management</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 19, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img2.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Specialities Co. <br />
            L.L.C</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 7, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img3.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Specon LLC</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jun 17, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img4.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Al Ahmadiah <br />
            Contracting & Trading</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jun 13, 2020</small
            >
          </div>
        </div>
      </div>
    </div>-->
  </div></div>
</section>
<!-- India end -->

<!-- USA started -->
<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase text-center">
        Law Firm Case <br />
        Study
      </h3>
    </div>
  </div>
  <div class="container-fluid">
  <div
    class="d-flex row justify-content-center align-items-start pl-4 pr-4"
    style="gap: 2rem"
  >
    <div class="col-lg-8 mt-50 mb-50 border shadow" style="padding: 2rem">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img
          class="img-fluid"
          src="../../../../assets/images/case-studies/law.png"
          alt=""
          style="margin-bottom: 2rem"
        />
        <div>
          <div
            style="
              background: #072f5f;
              display: inline;
              width: fit-content;
              padding: 0.2rem 1rem 0.2rem 1rem;
            "
          >
            <small
              class="text-uppercase text-white"
              style="font-weight: 600 !important; font-size: 15px !important"
              >case details</small
            >
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >overview</span
              >
            </div>
            <p class="text-dark servicep">
              Law Firm specialized in real estate closing support services and
              advisory services. The team, The team comprises of best of the
              lawyers each heading a different expert area.
            </p>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Client</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>Washburn Law PLLC</li>
              <li>
                Law firm having offices in North & South Carolina, Georgia.
                Expertise in providing legal services in areas such as real
                estate Closing support services.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Strategies Described</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Defining the proper off-site Accounting organization structure
                and providing capable resources.
              </li>
              <li>
                Defining the SLA’s for keeping the F & A function online,
                defining the roadmap for ongoing accounting activity.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Client's Challenges</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                No proper Books of Accounts, QB was used to only print checks.
              </li>
              <li>Everything was done manually, Lack of proper systems.</li>
              <li>
                Lack of information, if at all information was available it was
                in scattered form.
              </li>
              <li>
                No accounting policies in place. AP, AR reimbursements were
                carried out blindly without any supporting and based on the
                demand from vendors and employees.
              </li>
              <li>Lack of co-ordination with the offshore operations team.</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Solution Provider</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Initially, Accounting organization structure with onsite and offsite mix was put in place with capable & experienced resources. Once function stabilized, the accounting organization structure was changed to offsite, this resulted in huge savings in payroll but without compromise on quality.
              </li>
              <li>

Complete understanding of business was sought and accounting policies, internal controls were put in place. System of payments was put in place.
              </li>
              <li>
                AR reconciliation, Monthly/Daily AR reporting was put in place.
              </li>
              <li>AP reconciliation, Monthly/Daily AP reporting was put in place.</li>
              <li>Monthly/quarterly MIS reports with emphasis on KPI’s was put in place.</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Outcome</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Better coordination between operations and Accounts departments.
              </li>
              <li>
                Proper recording of vendor invoices after approvals and processing payments as per schedule. Excess payments were stopped, instances of overdue reduced to a large extent.
              </li>
              <li>
                Excellent coordination between operations and Accounting
                function.
              </li>
              <li>
                Increased collections from customers resulting in reduced collection time. Complete stop to revenue leakages.
              </li>
              <li>
                Accurate &Timely MIS reporting.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Client Testimonial</span
              >
            </div>
            <p class="text-dark servicep" style="font-style: italic;">
              Law Firm specialized in real estate closing support services and
              advisory services. The team, The team comprises of best of the
              lawyers each heading a different expert area.
            </p>

            <p class="text-dark servicep font-weight-light mt-5" style="font-style: italic;">
              – Jack Munsey (CEO)
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--<div
      class="mt-50 border d-flex flex-column justify-content-start align-items-start"
      style="padding: 2rem"
    >
      <div
        class="mt-15 mb-10 d-flex justify-content-center align-items-center"
        style="gap: 5px"
      >
        <div
          class=""
          style="border: 3px solid #072f5f; width: 1.5rem; height: 1px"
        ></div>
        <div
          class=""
          style="border: 3px solid #072f5f; width: 0.5rem; height: 1px"
        ></div>
        <div>
          <span
            class="text-capitalize"
            style="
              font-size: 1.5rem !important;
              font-weight: 700 !important;
              color: #002249 !important;
            "
            >Case Studies</span
          >
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img1.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Facilities <br />
            Management</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 19, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img2.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Specialities Co. <br />
            L.L.C</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 7, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img3.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Specon LLC</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jun 17, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img4.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Al Ahmadiah <br />
            Contracting & Trading</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jun 13, 2020</small
            >
          </div>
        </div>
      </div>
    </div>-->
  </div></div>
</section>
<!-- USA ends -->
<!-- USA ends -->
<!-- USA end -->
