<!-- dubai start -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center text-uppercase"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      Corporate Structuring
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0 text-uppercase" routerLink="/startup-solution"
        >Home <i class="fas fa-arrow-circle-right"></i> Corporate Structuring</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 justify-content-center align-items-center imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 420px; height: 100px"
      >
        <img
          src="../../../../assets/images/cpa solutions/accounts.png"
          class="img-fluid"
          style="max-width: 350px; position: relative; top: 12rem"
        />
        <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        This term assumes very high importance for start-ups. Having the right
        form of business entity, in the beginning, ensures that there is maximum
        value creation for the promoters and at the same time there is enough
        scope for investors to step in and help the promoters move to the next
        level.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      We advise start-ups regarding their business goals, and tax goals and provide implementation support for their infrastructure activity. We advise and assist in:

      </p>
      <ul class="mt-10">
        <li
          class="mb-2 text-dark"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: green"></i>
          Optimal corporate structure to ensure the smooth running of commercial activities.

        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: green"></i>
          Formation of entities including Private Limited Companies, LLP, INC, LLC.

        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: green"></i>
          Drafting of Shareholder Agreement.

        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: green"></i>
          A transactional framework for private placements, and acquisitions
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: green"></i>
          On corporate laws, regulations, and compliance.

        </li>
        
      </ul>
    </div>
  </div>
  
</div>
<!-- dubai end -->

<!-- india start -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center text-uppercase"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      Corporate Structuring
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0 text-uppercase" routerLink="/startup-solution"
        >Home <i class="fas fa-arrow-circle-right"></i> Corporate Structuring</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 justify-content-center align-items-center imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 420px; height: 100px"
      >
        <img
          src="../../../../assets/images/cpa solutions/accounts.png"
          class="img-fluid"
          style="max-width: 350px; position: relative; top: 12rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        This term assumes very high importance for start-ups. Having the right
        form of business entity, in the beginning, ensures that there is maximum
        value creation for the promoters and at the same time there is enough
        scope for investors to step in and help the promoters move to the next
        level.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      We advise start-ups regarding their business goals, and tax goals and provide implementation support for their infrastructure activity. We advise and assist in:

      </p>
      <ul class="mt-10">
        <li
          class="mb-2 text-dark"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: #0951A4"></i>
          Optimal corporate structure to ensure the smooth running of commercial activities.

        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: #0951A4"></i>
          Formation of entities including Private Limited Companies, LLP, INC, LLC.

        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: #0951A4"></i>
          Drafting of Shareholder Agreement.

        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: #0951A4"></i>
          A transactional framework for private placements, and acquisitions
        </li>
        <li
          class="mb-2 text-dark mb-40"
          style="font-size: 24px; margin-bottom: 30px !important"
        >
          <i class="fas fa-star" style="color: #0951A4"></i>
          On corporate laws, regulations, and compliance.

        </li>
        
      </ul>
    </div>
  </div>
  
</div>
<!-- india end -->

