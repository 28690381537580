<!-- dubai Started -->
<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100">
  <div
    class="row d-flex justify-content-center align-items-center pTop m-0 backgroundImg shadow"
    style="
      gap: 2rem;
      padding-bottom: 4rem;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
    "
  >
    <img
      src="../../../../../assets/images/why-bfag/abstract-right.png"
      alt="shape"
      style="position: absolute; overflow: clip; right: -3%"
    />
    <img
      src="../../../../../assets/images/why-bfag/abstract-left.png"
      alt="shape"
      style="position: absolute; overflow: clip; left: 0%; top: 20%"
    />
    <div class="col-lg-6 text-black-50 p-0 m-0 imgSpace">
      <div
        class="row p-3"
        style="border: 3px solid #418052; border-radius: 15px"
        class="mTop"
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/why-bfag/Why-bfag.png"
          alt=""
        />
      </div>
    </div>
    <div class="col-lg-4 justify-content-start pt-0">
      <div>
        <h1
          style="
            border-bottom: 3px solid #418052;
            display: inline-block;
            font-size: 30px;
            color: #418052;
          "
        >
          Why BFAG
        </h1>
        <p
          class="mb-15 mt-20 text-dark"
          style="line-height: normal; text-align: justify"
        >
          Boston Advisory Financial Group is one of the most demanded
          outsourcing partners for accounting and financial consulting purposes.
          This was made possible purely due to our ingenious strategies and
          quality accounting practices. A combination of these two aspects
          allows us to customize results and bring forward insight-driven
          solutions that maximize your efficiency and results.
        </p>
        <p
          class="mb-15 text-dark"
          style="line-height: normal; text-align: justify"
        >
          However, these are not the only aspects which make BFAG a lucrative
          choice for our clients. Our solutions are programmed to strategically
          enhance your activities. Implementing our technology-oriented services
          in unison with the solutions enables us to take over your financial
          processes and engineer them to ensure accuracy and minimum human
          intervention. With constant 24×7 secure online access, we push you
          forward and enable you to outperform your competitors.
        </p>
      </div>
    </div>
  </div>
  <div
    style="
      background-color: #418052;
      padding-top: 30px;
      padding-bottom: 30px;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
    "
  >
    <img
      src="../../../../../assets/images/why-bfag/design-left.png"
      alt="shape"
      style="position: absolute; overflow: clip; left: -3%; width: 45%; top: 0%"
    />
    <img
      src="../../../../../assets/images/why-bfag/design-right.png"
      alt="shape"
      style="
        position: absolute;
        overflow: clip;
        right: -3%;
        width: 50%;
        bottom: 0%;
      "
    />

    <div class="row d-flex justify-content-center mt-20 mb-25">
      <div
        class="d-flex flex-wrap justify-content-center align-items-center row-eq-height"
        style="gap: 1rem"
      >
        <!-- <div
          data-wow-duration=".1.5s"
          data-wow-delay=".2s"
          class="wow fadeInDown card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
          style="width: 20rem; height: 51rem; border-radius: 15px"
        >
          <img
            class="img-fluid res-img"
            src="../../../../../assets/images/why-bfag/3UAE.png"
            alt="Card image cap"
          />
          <div
            class="card-body d-flex flex-column justify-content-center align-items-center res-div"
            style="padding-top: 0rem"
          >
            <span
              class="card-title text-center"
              style="border-bottom: 3px solid #418052"
              >Competence</span
            >
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                Profit from the extensive knowledge and expertise of our team,
                accumulated over 1000 plus years of providing accounting and
                advisory services. We deliver personalized insights to address
                your specific requirements.
              </li>
              <li>
                Our certified CPAs and CAs demonstrate exceptional proficiency
                in delivering accounting, tax, regulatory compliance, and
                payroll solutions. We guarantee compliance and expert guidance.
              </li>
              <li>
                Rely on our deep expertise, dedicated support, and commitment to
                industry standards (GAAP, FASB, GAS, IFRS) to ensure smooth
                accounting processes and regulatory compliance.
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".3s"
          class="wow fadeInDown card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
          style="width: 20rem; height: 51rem; border-radius: 15px"
        >
          <img
            class="img-fluid res-img"
            src="../../../../../assets/images/why-bfag/1UAE.png"
            alt="Card image cap"
          />
          <div
            class="card-body d-flex flex-column justify-content-center align-items-center res-div"
            style="padding-top: 0rem"
          >
            <span
              class="card-title text-center"
              style="border-bottom: 3px solid #418052"
              >Extensive Offering</span
            >
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                At BFAG, we pride ourselves on providing a comprehensive range
                of financial services to cater to the diverse
                multi-jurisdictional needs of our clients. Our extensive
                offering covers a wide spectrum of financial solutions, ensuring
                that you can find everything you require under one roof.
              </li>
              <li>
                Whether it is Accounting Services, Financial Consulting, Direct
                & Indirect Tax Compliance, Secretarial Compliance, Payroll
                Management, you can rely on us as your trusted partner for all
                your accounting, financial and advisory needs.
              </li>
              <li>
                Our commitment to excellence, combined with our
                technology-driven approach & skilled Resources, ensures that you
                receive top-notch services that enable your business to thrive
                and outperform competitors in the market.
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".4s"
          class="wow fadeInDown card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
          style="width: 20rem; height: 51rem; border-radius: 15px"
        >
          <img
            class="img-fluid"
            src="../../../../../assets/images/why-bfag/4UAE.png"
            alt="Card image cap"
          />
          <div
            class="card-body d-flex flex-column justify-content-start align-items-center"
            style="padding-top: 0.9rem !important"
          >
            <span
              class="card-title text-center"
              style="border-bottom: 3px solid #418052"
              >Exceptional Solutions</span
            >
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                We ensure the implementation of the finest accounting practices
                and strict adherence to ISO 9001 and 27001 standards.
              </li>
              <li>
                Our service includes robust quality control measures,
                incorporating multiple levels of accuracy checks and quarterly
                internal reviews.
              </li>
              <li>
                Enjoy round-the-clock customer support, featuring a dedicated
                account manager and dedicated email IDs for seamless
                communication
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".4s"
          class="wow fadeInDown card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
          style="width: 20rem; height: 51rem; border-radius: 15px"
        >
          <img
            class="img-fluid"
            src="../../../../../assets/images/why-bfag/2UAE.png"
            alt="Card image cap"
          />
          <div
            class="card-body d-flex flex-column justify-content-start align-items-center"
            style="padding-top: 0.9rem !important"
          >
            <span
              class="card-title text-center"
              style="border-bottom: 3px solid #418052"
              >Tech-Driven Approach</span
            >
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                We leverage technology to minimize errors, improve efficiency,
                and expedite turnaround times.
              </li>
              <li>
                Utilizing intelligent processes, we digitize and track financial
                information with minimal human involvement, ensuring precision
                and dependability.
              </li>
              <li>
                Experience a secure and paperless office environment, granting
                24/7 online access to your financial records.
              </li>
            </ul>
          </div>
        </div> -->
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".2s"
          class="card p-2 wow fadeInDown shadow"
          style="width: 20rem; border-radius: 15px; height: 54rem"
        >
          <div class="d-flex justify-content-center align-items-center pt-1">
            <img
              class="res-img"
              src="../../../../../assets/images/why-bfag/3UAE.png"
              alt="Card image cap"
            />
          </div>
          <div class="card-body" style="padding-top: 1rem !important">
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="card-title text-center"
                style="border-bottom: 3px solid #418052"
                >Competence</span
              >
            </div>

            <ul class="p-0 card-text text-dark" style="list-style-type: disc">
              <li>
                Profit from the extensive knowledge and expertise of our team,
                accumulated over 1000 plus years of providing accounting and
                advisory services. We deliver personalized insights to address
                your specific requirements.
              </li>
              <li>
                Our certified CPAs and CAs demonstrate exceptional proficiency
                in delivering accounting, tax, regulatory compliance, and
                payroll solutions. We guarantee compliance and expert guidance.
              </li>
              <li>
                Rely on our deep expertise, dedicated support, and commitment to
                industry standards (GAAP, FASB, GAS, IFRS) to ensure smooth
                accounting processes and regulatory compliance.
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".2s"
          class="card p-2 wow fadeInDown shadow"
          style="width: 20rem; border-radius: 15px"
        >
          <div class="d-flex justify-content-center align-items-center pt-1">
            <img
              class="res-img"
              src="../../../../../assets/images/why-bfag/1UAE.png"
              alt="Card image cap"
            />
          </div>
          <div class="card-body" style="padding-top: 1rem !important">
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="card-title text-center"
                style="border-bottom: 3px solid #418052"
                >Extensive Offering</span
              >
            </div>
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                At BFAG, we pride ourselves on providing a comprehensive range
                of financial services to cater to the diverse
                multi-jurisdictional needs of our clients. Our extensive
                offering covers a wide spectrum of financial solutions, ensuring
                that you can find everything you require under one roof.
              </li>
              <li>
                Whether it is Accounting Services, Financial Consulting, Direct
                & Indirect Tax Compliance, Secretarial Compliance, Payroll
                Management, you can rely on us as your trusted partner for all
                your accounting, financial and advisory needs.
              </li>
              <li>
                Our commitment to excellence, combined with our
                technology-driven approach & skilled Resources, ensures that you
                receive top-notch services that enable your business to thrive
                and outperform competitors in the market.
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".2s"
          class="card p-2 wow fadeInDown shadow"
          style="width: 20rem; border-radius: 15px; height: 54rem"
        >
          <div class="d-flex justify-content-center align-items-center pt-1">
            <img
              class="res-img"
              src="../../../../../assets/images/why-bfag/4UAE.png"
              alt="Card image cap"
            />
          </div>
          <div class="card-body" style="padding-top: 1rem !important">
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="card-title text-center"
                style="border-bottom: 3px solid #418052"
                >Exceptional Solutions</span
              >
            </div>
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                We ensure the implementation of the finest accounting practices
                and strict adherence to ISO 9001 and 27001 standards.
              </li>
              <li>
                Our service includes robust quality control measures,
                incorporating multiple levels of accuracy checks and quarterly
                internal reviews.
              </li>
              <li>
                Enjoy round-the-clock customer support, featuring a dedicated
                account manager and dedicated email IDs for seamless
                communication.
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".2s"
          class="card p-2 wow fadeInDown shadow"
          style="width: 20rem; border-radius: 15px; height: 54rem"
        >
          <div class="d-flex justify-content-center align-items-center pt-1">
            <img
              class="res-img"
              src="../../../../../assets/images/why-bfag/2UAE.png"
              alt="Card image cap"
            />
          </div>
          <div class="card-body" style="padding-top: 1rem !important">
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="card-title text-center"
                style="border-bottom: 3px solid #418052"
                >Tech-Driven Approach</span
              >
            </div>
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                We leverage technology to minimize errors, improve efficiency,
                and expedite turnaround times.
              </li>
              <li>
                Utilizing intelligent processes, we digitize and track financial
                information with minimal human involvement, ensuring precision
                and dependability.
              </li>
              <li>
                Experience a secure and paperless office environment, granting
                24/7 online access to your financial records.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- dubai end -->

<!-- India Started -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100">
  <div
    class="row d-flex justify-content-center align-items-center pTop m-0 backgroundImg shadow"
    style="
      gap: 2rem;
      padding-bottom: 4rem;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
    "
  >
    <img
      src="../../../../../assets/images/why-bfag/abstract-right.png"
      alt="shape"
      style="position: absolute; overflow: clip; right: -3%"
    />
    <img
      src="../../../../../assets/images/why-bfag/abstract-left.png"
      alt="shape"
      style="position: absolute; overflow: clip; left: 0%; top: 20%"
    />
    <div class="col-lg-6 text-black-50 p-0 m-0 imgSpace">
      <div
        class="row p-3"
        style="border: 3px solid #072f5f; border-radius: 15px"
        class="mTop"
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/why-bfag/Why-bfag.png"
          alt=""
        />
      </div>
    </div>
    <div class="col-lg-4 justify-content-start pt-0">
      <div>
        <h1
          style="
            border-bottom: 3px solid #072f5f;
            display: inline-block;
            font-size: 30px;
            color: #072f5f;
          "
        >
          Why BFAG
        </h1>
        <p
          class="mb-15 mt-20 text-dark"
          style="line-height: normal; text-align: justify"
        >
          Boston Advisory Financial Group is one of the most demanded
          outsourcing partners for accounting and financial consulting purposes.
          This was made possible purely due to our ingenious strategies and
          quality accounting practices. A combination of these two aspects
          allows us to customize results and bring forward insight-driven
          solutions that maximize your efficiency and results.
        </p>
        <p
          class="mb-15 text-dark"
          style="line-height: normal; text-align: justify"
        >
          However, these are not the only aspects which make BFAG a lucrative
          choice for our clients. Our solutions are programmed to strategically
          enhance your activities. Implementing our technology-oriented services
          in unison with the solutions enables us to take over your financial
          processes and engineer them to ensure accuracy and minimum human
          intervention. With constant 24×7 secure online access, we push you
          forward and enable you to outperform your competitors.
        </p>
      </div>
    </div>
  </div>
  <div
    style="
      background-color: #072f5f;
      padding-top: 30px;
      padding-bottom: 30px;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
    "
  >
    <img
      src="../../../../../assets/images/why-bfag/design-left.png"
      alt="shape"
      style="position: absolute; overflow: clip; left: -3%; width: 45%; top: 0%"
    />
    <img
      src="../../../../../assets/images/why-bfag/design-right.png"
      alt="shape"
      style="
        position: absolute;
        overflow: clip;
        right: -3%;
        width: 50%;
        bottom: 0%;
      "
    />

    <div class="row d-flex justify-content-center mt-20 mb-25">
      <div
        class="d-flex flex-wrap justify-content-center align-items-center row-eq-height"
        style="gap: 1rem"
      >
        <!-- <div
          data-wow-duration=".1.5s"
          data-wow-delay=".2s"
          class="wow fadeInDown card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
          style="width: 20rem; height: 51rem; border-radius: 15px"
        >
          <img
            class="img-fluid res-img"
            src="../../../../../assets/images/why-bfag/3.png"
            alt="Card image cap"
          />
          <div
            class="card-body d-flex flex-column justify-content-center align-items-center res-div"
            style="padding-top: 0rem"
          >
            <span
              class="card-title text-center"
              style="border-bottom: 3px solid #072f5f"
              >Competence</span
            >
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                Profit from the extensive knowledge and expertise of our team,
                accumulated over 1000 plus years of providing accounting and
                advisory services. We deliver personalized insights to address
                your specific requirements.
              </li>
              <li>
                Our certified CPAs and CAs demonstrate exceptional proficiency
                in delivering accounting, tax, regulatory compliance, and
                payroll solutions. We guarantee compliance and expert guidance.
              </li>
              <li>
                Rely on our deep expertise, dedicated support, and commitment to
                industry standards (GAAP, FASB, GAS, IFRS) to ensure smooth
                accounting processes and regulatory compliance.
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".3s"
          class="wow fadeInDown card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
          style="width: 20rem; height: 51rem; border-radius: 15px"
        >
          <img
            class="img-fluid res-img"
            src="../../../../../assets/images/why-bfag/1.png"
            alt="Card image cap"
          />
          <div
            class="card-body d-flex flex-column justify-content-center align-items-center res-div"
            style="padding-top: 0rem"
          >
            <span
              class="card-title text-center"
              style="border-bottom: 3px solid #072f5f"
              >Extensive Offering</span
            >
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                At BFAG, we pride ourselves on providing a comprehensive range
                of financial services to cater to the diverse
                multi-jurisdictional needs of our clients. Our extensive
                offering covers a wide spectrum of financial solutions, ensuring
                that you can find everything you require under one roof.
              </li>
              <li>
                Whether it is Accounting Services, Financial Consulting, Direct
                & Indirect Tax Compliance, Secretarial Compliance, Payroll
                Management, you can rely on us as your trusted partner for all
                your accounting, financial and advisory needs.
              </li>
              <li>
                Our commitment to excellence, combined with our
                technology-driven approach & skilled Resources, ensures that you
                receive top-notch services that enable your business to thrive
                and outperform competitors in the market.
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".4s"
          class="wow fadeInDown card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
          style="width: 20rem; height: 51rem; border-radius: 15px"
        >
          <img
            class="img-fluid"
            src="../../../../../assets/images/why-bfag/4.png"
            alt="Card image cap"
          />
          <div
            class="card-body d-flex flex-column justify-content-start align-items-center"
            style="padding-top: 0.9rem !important"
          >
            <span
              class="card-title text-center"
              style="border-bottom: 3px solid #072f5f"
              >Exceptional Solutions</span
            >
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                We ensure the implementation of the finest accounting practices
                and strict adherence to ISO 9001 and 27001 standards.
              </li>
              <li>
                Our service includes robust quality control measures,
                incorporating multiple levels of accuracy checks and quarterly
                internal reviews.
              </li>
              <li>
                Enjoy round-the-clock customer support, featuring a dedicated
                account manager and dedicated email IDs for seamless
                communication
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".4s"
          class="wow fadeInDown card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
          style="width: 20rem; height: 51rem; border-radius: 15px"
        >
          <img
            class="img-fluid"
            src="../../../../../assets/images/why-bfag/2.png"
            alt="Card image cap"
          />
          <div
            class="card-body d-flex flex-column justify-content-start align-items-center"
            style="padding-top: 0.9rem !important"
          >
            <span
              class="card-title text-center"
              style="border-bottom: 3px solid #072f5f"
              >Tech-Driven Approach</span
            >
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                We leverage technology to minimize errors, improve efficiency,
                and expedite turnaround times.
              </li>
              <li>
                Utilizing intelligent processes, we digitize and track financial
                information with minimal human involvement, ensuring precision
                and dependability.
              </li>
              <li>
                Experience a secure and paperless office environment, granting
                24/7 online access to your financial records.
              </li>
            </ul>
          </div>
        </div> -->

        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".2s"
          class="card p-2 wow fadeInDown shadow"
          style="width: 20rem; border-radius: 15px; height: 54rem"
        >
          <div class="d-flex justify-content-center align-items-center pt-1">
            <img
              class="res-img"
              src="../../../../../assets/images/why-bfag/3.png"
              alt="Card image cap"
            />
          </div>
          <div class="card-body" style="padding-top: 1rem !important">
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="card-title text-center"
                style="border-bottom: 3px solid #072f5f"
                >Competence</span
              >
            </div>

            <ul class="p-0 card-text text-dark" style="list-style-type: disc">
              <li>
                Profit from the extensive knowledge and expertise of our team,
                accumulated over 1000 plus years of providing accounting and
                advisory services. We deliver personalized insights to address
                your specific requirements.
              </li>
              <li>
                Our certified CPAs and CAs demonstrate exceptional proficiency
                in delivering accounting, tax, regulatory compliance, and
                payroll solutions. We guarantee compliance and expert guidance.
              </li>
              <li>
                Rely on our deep expertise, dedicated support, and commitment to
                industry standards (GAAP, FASB, GAS, IFRS) to ensure smooth
                accounting processes and regulatory compliance.
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".2s"
          class="card p-2 wow fadeInDown shadow"
          style="width: 20rem; border-radius: 15px"
        >
          <div class="d-flex justify-content-center align-items-center pt-1">
            <img
              class="res-img"
              src="../../../../../assets/images/why-bfag/1.png"
              alt="Card image cap"
            />
          </div>
          <div class="card-body" style="padding-top: 1rem !important">
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="card-title text-center"
                style="border-bottom: 3px solid #072f5f"
                >Extensive Offering</span
              >
            </div>
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                At BFAG, we pride ourselves on providing a comprehensive range
                of financial services to cater to the diverse
                multi-jurisdictional needs of our clients. Our extensive
                offering covers a wide spectrum of financial solutions, ensuring
                that you can find everything you require under one roof.
              </li>
              <li>
                Whether it is Accounting Services, Financial Consulting, Direct
                & Indirect Tax Compliance, Secretarial Compliance, Payroll
                Management, you can rely on us as your trusted partner for all
                your accounting, financial and advisory needs.
              </li>
              <li>
                Our commitment to excellence, combined with our
                technology-driven approach & skilled Resources, ensures that you
                receive top-notch services that enable your business to thrive
                and outperform competitors in the market.
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".2s"
          class="card p-2 wow fadeInDown shadow"
          style="width: 20rem; border-radius: 15px; height: 54rem"
        >
          <div class="d-flex justify-content-center align-items-center pt-1">
            <img
              class="res-img"
              src="../../../../../assets/images/why-bfag/4.png"
              alt="Card image cap"
            />
          </div>
          <div class="card-body" style="padding-top: 1rem !important">
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="card-title text-center"
                style="border-bottom: 3px solid #072f5f"
                >Exceptional Solutions</span
              >
            </div>
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                We ensure the implementation of the finest accounting practices
                and strict adherence to ISO 9001 and 27001 standards.
              </li>
              <li>
                Our service includes robust quality control measures,
                incorporating multiple levels of accuracy checks and quarterly
                internal reviews.
              </li>
              <li>
                Enjoy round-the-clock customer support, featuring a dedicated
                account manager and dedicated email IDs for seamless
                communication.
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".2s"
          class="card p-2 wow fadeInDown shadow"
          style="width: 20rem; border-radius: 15px; height: 54rem"
        >
          <div class="d-flex justify-content-center align-items-center pt-1">
            <img
              class="res-img"
              src="../../../../../assets/images/why-bfag/2.png"
              alt="Card image cap"
            />
          </div>
          <div class="card-body" style="padding-top: 1rem !important">
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="card-title text-center"
                style="border-bottom: 3px solid #072f5f"
                >Tech-Driven Approach</span
              >
            </div>
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                We leverage technology to minimize errors, improve efficiency,
                and expedite turnaround times.
              </li>
              <li>
                Utilizing intelligent processes, we digitize and track financial
                information with minimal human involvement, ensuring precision
                and dependability.
              </li>
              <li>
                Experience a secure and paperless office environment, granting
                24/7 online access to your financial records.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- INida end -->

<!-- USA Started -->
<section *ngIf="selectCountryName == 'USA'" class="min-vh-100">
  <div
    class="row d-flex justify-content-center align-items-center pTop m-0 backgroundImg shadow"
    style="
      gap: 2rem;
      padding-bottom: 4rem;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
    "
  >
    <img
      src="../../../../../assets/images/why-bfag/abstract-right.png"
      alt="shape"
      style="position: absolute; overflow: clip; right: -3%"
    />
    <img
      src="../../../../../assets/images/why-bfag/abstract-left.png"
      alt="shape"
      style="position: absolute; overflow: clip; left: 0%; top: 20%"
    />
    <div class="col-lg-6 text-black-50 p-0 m-0 imgSpace">
      <div
        class="row p-3"
        style="border: 3px solid #072f5f; border-radius: 15px"
        class="mTop"
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/why-bfag/Why-bfag.png"
          alt=""
        />
      </div>
    </div>
    <div class="col-lg-4 justify-content-start pt-0">
      <div>
        <h1
          style="
            border-bottom: 3px solid #072f5f;
            display: inline-block;
            font-size: 30px;
            color: #072f5f;
          "
        >
          Why BFAG
        </h1>
        <p
          class="mb-15 mt-20 text-dark"
          style="line-height: normal; text-align: justify"
        >
          Boston Advisory Financial Group is one of the most demanded
          outsourcing partners for accounting and financial consulting purposes.
          This was made possible purely due to our ingenious strategies and
          quality accounting practices. A combination of these two aspects
          allows us to customize results and bring forward insight-driven
          solutions that maximize your efficiency and results.
        </p>
        <p
          class="mb-15 text-dark"
          style="line-height: normal; text-align: justify"
        >
          However, these are not the only aspects which make BFAG a lucrative
          choice for our clients. Our solutions are programmed to strategically
          enhance your activities. Implementing our technology-oriented services
          in unison with the solutions enables us to take over your financial
          processes and engineer them to ensure accuracy and minimum human
          intervention. With constant 24×7 secure online access, we push you
          forward and enable you to outperform your competitors.
        </p>
      </div>
    </div>
  </div>
  <div
    style="
      background-color: #072f5f;
      padding-top: 30px;
      padding-bottom: 30px;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
    "
  >
    <img
      src="../../../../../assets/images/why-bfag/design-left.png"
      alt="shape"
      style="position: absolute; overflow: clip; left: -3%; width: 45%; top: 0%"
    />
    <img
      src="../../../../../assets/images/why-bfag/design-right.png"
      alt="shape"
      style="
        position: absolute;
        overflow: clip;
        right: -3%;
        width: 50%;
        bottom: 0%;
      "
    />

    <div class="row d-flex justify-content-center mt-20 mb-25">
      <div
        class="d-flex flex-wrap justify-content-center align-items-center row-eq-height"
        style="gap: 1rem"
      >
        <!-- <div data-wow-duration=".1.5s" data-wow-delay=".2s" class="wow fadeInDown card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3" style="width: 20rem; height: 51rem; border-radius:15px;">
        <img class="img-fluid res-img" src="../../../../../assets/images/why-bfag/3.png" alt="Card image cap" />
        <div class="card-body d-flex flex-column justify-content-center align-items-center res-div" style="padding-top: 0rem;">
          <span class="card-title text-center" style="border-bottom: 3px solid #072F5F">Competence</span>
          <ul  class="p-0 text-dark"  style="list-style-type: disc;">
            <li>Profit from the extensive knowledge and expertise of our team, accumulated over 1000 plus years of providing accounting and advisory services. We deliver personalized insights to address your specific requirements.</li>
            <li>Our certified CPAs and CAs demonstrate exceptional proficiency in delivering accounting, tax, regulatory compliance, and payroll solutions. We guarantee compliance and expert guidance.</li>
            <li>Rely on our deep expertise, dedicated support, and commitment to industry standards (GAAP, FASB, GAS, IFRS) to ensure smooth accounting processes and regulatory compliance.</li>
          </ul>
        </div>
      </div>
      <div data-wow-duration=".1.5s" data-wow-delay=".3s" class="wow fadeInDown card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3" style="width: 20rem; height: 51rem; border-radius:15px;">
        <img class="img-fluid res-img" src="../../../../../assets/images/why-bfag/1.png" alt="Card image cap" />
        <div class="card-body d-flex flex-column justify-content-center align-items-center res-div" style="padding-top: 0rem;">
          <span class="card-title text-center" style="border-bottom: 3px solid #072F5F">Extensive Offering</span>
          <ul class="p-0 text-dark"  style="list-style-type: disc;">
            <li>At BFAG, we pride ourselves on providing a comprehensive range of financial services to cater to the diverse multi-jurisdictional needs of our clients. Our extensive offering covers a wide spectrum of financial solutions, ensuring that you can find everything you require under one roof.
            </li>
            <li> Whether it is Accounting Services, Financial Consulting, Direct & Indirect Tax Compliance, Secretarial Compliance, Payroll Management,  you can rely on us as your trusted partner for all your accounting, financial and advisory needs.
            </li>
            <li>Our commitment to excellence, combined with our technology-driven approach & skilled Resources, ensures that you receive top-notch services that enable your business to thrive and outperform competitors in the market.</li>
          </ul>
        </div>
      </div>
      <div data-wow-duration=".1.5s" data-wow-delay=".4s" class="wow fadeInDown card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3" style="width: 20rem; height: 51rem; border-radius:15px;">
        <img class="img-fluid" src="../../../../../assets/images/why-bfag/4.png" alt="Card image cap" />
        <div class="card-body d-flex flex-column justify-content-start align-items-center" style="padding-top: 0.90rem !important;">
          <span class="card-title text-center" style="border-bottom: 3px solid #072F5F">Exceptional Solutions</span>
          <ul  class="p-0 text-dark"  style="list-style-type: disc;">
            <li>We ensure the implementation of the finest accounting practices and strict adherence to ISO 9001 and 27001 standards.</li>
            <li>Our service includes robust quality control measures, incorporating multiple levels of accuracy checks and quarterly internal reviews.</li>
            <li>Enjoy round-the-clock customer support, featuring a dedicated account manager and dedicated email IDs for seamless communication.</li>
          </ul>
        </div>
      </div>
      <div data-wow-duration=".1.5s" data-wow-delay=".4s" class="wow fadeInDown card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3" style="width: 20rem; height: 51rem; border-radius:15px;">
        <img class="img-fluid" src="../../../../../assets/images/why-bfag/2.png" alt="Card image cap" />
        <div class="card-body d-flex flex-column justify-content-start align-items-center" style="padding-top: 0.90rem !important;">
          <span class="card-title text-center" style="border-bottom: 3px solid #072F5F">Tech-Driven Approach</span>
          <ul class="p-0 text-dark"  style="list-style-type: disc;">
            <li>We leverage technology to minimize errors, improve efficiency, and expedite turnaround times.</li>
            <li>Utilizing intelligent processes, we digitize and track financial information with minimal human involvement, ensuring precision and dependability.</li>
            <li>Experience a secure and paperless office environment, granting 24/7 online access to your financial records.</li>
          </ul>
        </div>
      </div> -->
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".2s"
          class="card p-2 wow fadeInDown shadow"
          style="width: 20rem; border-radius: 15px; height: 54rem"
        >
          <div class="d-flex justify-content-center align-items-center pt-1">
            <img
              class="res-img"
              src="../../../../../assets/images/why-bfag/3.png"
              alt="Card image cap"
            />
          </div>
          <div class="card-body" style="padding-top: 1rem !important">
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="card-title text-center"
                style="border-bottom: 3px solid #072f5f"
                >Competence</span
              >
            </div>

            <ul class="p-0 card-text text-dark" style="list-style-type: disc">
              <li>
                Profit from the extensive knowledge and expertise of our team,
                accumulated over 1000 plus years of providing accounting and
                advisory services. We deliver personalized insights to address
                your specific requirements.
              </li>
              <li>
                Our certified CPAs and CAs demonstrate exceptional proficiency
                in delivering accounting, tax, regulatory compliance, and
                payroll solutions. We guarantee compliance and expert guidance.
              </li>
              <li>
                Rely on our deep expertise, dedicated support, and commitment to
                industry standards (GAAP, FASB, GAS, IFRS) to ensure smooth
                accounting processes and regulatory compliance.
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".2s"
          class="card p-2 wow fadeInDown shadow"
          style="width: 20rem; border-radius: 15px"
        >
          <div class="d-flex justify-content-center align-items-center pt-1">
            <img
              class="res-img"
              src="../../../../../assets/images/why-bfag/1.png"
              alt="Card image cap"
            />
          </div>
          <div class="card-body" style="padding-top: 1rem !important">
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="card-title text-center"
                style="border-bottom: 3px solid #072f5f"
                >Extensive Offering</span
              >
            </div>
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                At BFAG, we pride ourselves on providing a comprehensive range
                of financial services to cater to the diverse
                multi-jurisdictional needs of our clients. Our extensive
                offering covers a wide spectrum of financial solutions, ensuring
                that you can find everything you require under one roof.
              </li>
              <li>
                Whether it is Accounting Services, Financial Consulting, Direct
                & Indirect Tax Compliance, Secretarial Compliance, Payroll
                Management, you can rely on us as your trusted partner for all
                your accounting, financial and advisory needs.
              </li>
              <li>
                Our commitment to excellence, combined with our
                technology-driven approach & skilled Resources, ensures that you
                receive top-notch services that enable your business to thrive
                and outperform competitors in the market.
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".2s"
          class="card p-2 wow fadeInDown shadow"
          style="width: 20rem; border-radius: 15px; height: 54rem"
        >
          <div class="d-flex justify-content-center align-items-center pt-1">
            <img
              class="res-img"
              src="../../../../../assets/images/why-bfag/4.png"
              alt="Card image cap"
            />
          </div>
          <div class="card-body" style="padding-top: 1rem !important">
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="card-title text-center"
                style="border-bottom: 3px solid #072f5f"
                >Exceptional Solutions</span
              >
            </div>
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                We ensure the implementation of the finest accounting practices
                and strict adherence to ISO 9001 and 27001 standards.
              </li>
              <li>
                Our service includes robust quality control measures,
                incorporating multiple levels of accuracy checks and quarterly
                internal reviews.
              </li>
              <li>
                Enjoy round-the-clock customer support, featuring a dedicated
                account manager and dedicated email IDs for seamless
                communication.
              </li>
            </ul>
          </div>
        </div>
        <div
          data-wow-duration=".1.5s"
          data-wow-delay=".2s"
          class="card p-2 wow fadeInDown shadow"
          style="width: 20rem; border-radius: 15px; height: 54rem"
        >
          <div class="d-flex justify-content-center align-items-center pt-1">
            <img
              class="res-img"
              src="../../../../../assets/images/why-bfag/2.png"
              alt="Card image cap"
            />
          </div>
          <div class="card-body" style="padding-top: 1rem !important">
            <div class="d-flex justify-content-center align-items-center">
              <span
                class="card-title text-center"
                style="border-bottom: 3px solid #072f5f"
                >Tech-Driven Approach</span
              >
            </div>
            <ul class="p-0 text-dark" style="list-style-type: disc">
              <li>
                We leverage technology to minimize errors, improve efficiency,
                and expedite turnaround times.
              </li>
              <li>
                Utilizing intelligent processes, we digitize and track financial
                information with minimal human involvement, ensuring precision
                and dependability.
              </li>
              <li>
                Experience a secure and paperless office environment, granting
                24/7 online access to your financial records.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- USA end -->
