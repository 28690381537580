import { Component, OnInit } from '@angular/core';

import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';

@Component({
  selector: 'app-accounts-payable',
  templateUrl: './accounts-payable.component.html',
  styleUrls: ['./accounts-payable.component.css']
})
export class AccountsPayableComponent implements OnInit {
  defaultCountry: string;
  selectCountryName: any;
  selectedFlag: string;
  // isCollapsed: boolean = true;


  constructor(private LocalStorageServiceService: LocalStorageServiceService,) { }



  ngOnInit(): void {
    this.defaultCountry = 'USA';

    this.selectCountryName = localStorage.getItem('selectedCountry');

    if (this.selectCountryName == null) {
      localStorage.setItem('selectedCountry', this.defaultCountry);
      window.location.reload();
    } else {
      this.selectCountryName = localStorage.getItem('selectedCountry');
    }
  }

  onAccounting(){
    window.location.href="#accounting-and-transition-processing";
  }

  onTax(){
    window.location.href="#tax-regulatory-compliances";
    //this.route.navigate(["tax-regulatory-compliances"]);
  }

  onPayroll(){
    window.location.href="#payroll-advisory";
  }
 
  onConsulting(){
    window.location.href="#consulting-and-advisory-services";
  }

  onStartup(){
    window.location.href="#startup-solution-services";
  }

  onAudit(){
    window.location.href="#audit-and-assurance-support";
  }


  selectCountry(country: string): void {
    // Update the selectedFlag with the corresponding image path
    if (country === 'Dubai') {
      this.selectedFlag =
        '/assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png';
    } else if (country === 'India') {
      this.selectedFlag = '/assets/images/flag/download.png';
    } else if (country === 'USA') {
      this.selectedFlag = '/assets/images/flag/USA.png';
    } else {
      // Set USA as the default country
      this.selectedFlag =
        '/assets/images/flag/USA.png';
      country = 'USA';
    }

    this.LocalStorageServiceService.setItem('selectedCountry', country);
  }






  // toggleCollapse() {
  //   this.isCollapsed = !this.isCollapsed;
  // }

}
