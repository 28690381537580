<!-- Dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      INDIRECT TAX SERVICES
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> INDIRECT TAX SERVICES</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/taxpaid2.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 7rem"
        />
        <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        BFAG has subject matter experts in providing Indirect tax compliances
        specifically in Goods and Service Tax. Our aim is to help the
        entrepreneur on the legal and regulatory requirements, and be a partner
        throughout the business lifecycle, offering support at every stage to
        ensure the business remains compliant and continually growing.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        With the mission of making it easier for Entrepreneurs to grow their
        business, we are offering a range of GST solution services from our ISO
        and ISMS Certified delivery center in Bangalore.
      </p>
      <div>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Manage multi-state GST compliance
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Multi-State GST Returns Preparation and filing
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Reconciliation of invoices and Input Tax Credit
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Quick and easy e-way bill generation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Interact with suppliers and clients for the identified mismatches
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Assistance in audits/investigations carried out by tax authorities
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Drafting appeals, submission and replies to notices
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- Dubai end -->

<!-- india started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      INDIRECT TAX SERVICES
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> INDIRECT TAX SERVICES</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/taxpaid2.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 7rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        BFAG has subject matter experts in providing Indirect tax compliances
        specifically in Goods and Service Tax. Our aim is to help the
        entrepreneur on the legal and regulatory requirements, and be a partner
        throughout the business lifecycle, offering support at every stage to
        ensure the business remains compliant and continually growing.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        With the mission of making it easier for Entrepreneurs to grow their
        business, we are offering a range of GST solution services from our ISO
        and ISMS Certified delivery center in Bangalore.
      </p>
      <div>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Manage multi-state GST compliance
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Multi-State GST Returns Preparation and filing
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Reconciliation of invoices and Input Tax Credit
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Quick and easy e-way bill generation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Interact with suppliers and clients for the identified mismatches
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Assistance in audits/investigations carried out by tax authorities
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Drafting appeals, submission and replies to notices
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- india end -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      INDIRECT TAX SERVICES
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> INDIRECT TAX SERVICES</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/taxpaid2.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 7rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        BFAG has subject matter experts in providing Indirect tax compliances
        specifically in Goods and Service Tax. Our aim is to help the
        entrepreneur on the legal and regulatory requirements, and be a partner
        throughout the business lifecycle, offering support at every stage to
        ensure the business remains compliant and continually growing.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        With the mission of making it easier for Entrepreneurs to grow their
        business, we are offering a range of GST solution services from our ISO
        and ISMS Certified delivery center in Bangalore.
      </p>
      <div>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Manage multi-state GST compliance
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Multi-State GST Returns Preparation and filing
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Reconciliation of invoices and Input Tax Credit
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Quick and easy e-way bill generation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Interact with suppliers and clients for the identified mismatches
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Assistance in audits/investigations carried out by tax authorities
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Drafting appeals, submission and replies to notices
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- USA end -->
