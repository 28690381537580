import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-on-gst',
  templateUrl: './faq-on-gst.component.html',
  styleUrls: ['./faq-on-gst.component.css']
})
export class FaqOnGstComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
