<!--Dubai started  -->
<div *ngIf="selectCountryName == 'Dubai'"
  class="min-vh-100 mt-100 container d-flex flex-column justify-content-center align-items-center"
>
  <div class="justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      CPA SOLUTION
    </h2>
  </div>
  <div
    class="row mb-100 mt-130 justify-content-center align-items-center"
    style="gap: 4rem"
  >
    <div
      class="card justify-content-center align-items-center shadow border-0"
      style="width: 15rem"
    >
      <div class="icon shadow-sm mt-10">
        <i class="far fa-globe fa-2x" style="color: #21ac51"></i>
      </div>
      <div class="card-body text-center">
        <h5 style="font-weight: 600">Accounting For The Accountants</h5>
        <a
          routerLink="/accounting-for-the-accountants"
          routerLinkActive="active"
          class="cardBtn text-white mt-40 mb-30"
          >Know More</a
        >
      </div>
    </div>

    <div
      class="card justify-content-center align-items-center shadow border-0"
      style="width: 15rem"
    >
      <div class="icon shadow-sm mt-10">
        <i class="fas fa-search fa-2x" style="color: #21ac51"></i>
      </div>
      <div class="card-body text-center">
        <h5 style="font-weight: 600">Tax Return Preparation</h5>
        <a
          routerLink="/tax-return-preparation"
          routerLinkActive="active"
          class="cardBtn text-white mt-40 mb-30"
          >Know More</a
        >
      </div>
    </div>
  </div>
</div>
<!-- dubai end -->

<!--india started  -->
<div *ngIf="selectCountryName == 'India'"
  class="min-vh-100 mt-100 container d-flex flex-column justify-content-center align-items-center"
>
  <div class="justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      CPA SOLUTION
    </h2>
  </div>
  <div
    class="row mb-100 mt-130 justify-content-center align-items-center"
    style="gap: 4rem"
  >
    <div
      class="card justify-content-center align-items-center shadow border-0"
      style="width: 15rem"
    >
      <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
        <i class="far fa-globe fa-2x" style="color: #0951A4"></i>
      </div>
      <div class="card-body text-center">
        <h5 style="font-weight: 600">Accounting For The Accountants</h5>
        <a
          routerLink="/accounting-for-the-accountants"
          routerLinkActive="active"
          class="cardBtn-india text-white mt-40 mb-30"
          >Know More</a
        >
      </div>
    </div>

    <div
      class="card justify-content-center align-items-center shadow border-0"
      style="width: 15rem"
    >
      <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
        <i class="fas fa-search fa-2x" style="color: #0951A4"></i>
      </div>
      <div class="card-body text-center">
        <h5 style="font-weight: 600">Tax Return Preparation</h5>
        <a
          routerLink="/tax-return-preparation"
          routerLinkActive="active"
          class="cardBtn-india text-white mt-40 mb-30"
          >Know More</a
        >
      </div>
    </div>
  </div>
</div>
<!-- india end -->