
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area Real-Estate customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Real Estate </h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Real Estate</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Horizon for Real Estate PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Real Estate Industries</span>
                    <h2 class="title pb-15">Horizon - EBS for Real Estate</h2>
                    <p>Horizon – EBS for Real Estate with VAT enabled features gives your team the core administrative tools to manage properties, leases, renewals, and appraisals across your organization more effectively. With your company’s Property information
                        stored in a structured, easily retrievable manner you can concentrate on managing and solving property management issues rather than simply documenting them in excel or in spreadsheets.</p>
                    <p>Centralize management of properties and the individual units across the organization, including the breakup of the lease components, assets linked to the units etc., so that you can get a detailed overview of your Property/units whenever
                        you need it. You can raise and track tax invoices and collections throughout the system using Lease Numbers. You can also view documents related to a property such as the unit plan, furnishing details etc. At any time, you have
                        direct access to information telling you which units are available/ vacant and against which customer are they currently with.</p>
                    <p>Creating new Property Leases and managing occupancy/vacancy levels are ongoing processes. You can control the process from the moment an inquiry is received for a vacant unit/property, through correspondence and workflows with relevant
                        managers, right up to the point of creating a Lease / Cancellation of a Lease.</p>

                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Horizon for Real Estate PART END ======-->

<!--====== CLIENTS PART START ======-->
<div class="brand-2-area pb-120  dec-spacing  gray-bg">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-12">
                <div class="experience-item">
                    <span>Damy Text</span>
                    <h2 class="title pb-15">Damy Text</h2>
                </div>
            </div> -->
            <div class="col-lg-12">
                <div class="brand-active">
                    <div class="brand-item">
                        <img src="assets/images/clients/uere.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/nrl.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/bhre.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/bhi.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/bena.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/akc.jpg" alt="brand name">
                    </div>
                </div>
                <!-- brand item -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== CLIENTS PART ENDS ======-->

<!--====== Components PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom ">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="experience-item">
                    <span>Solution Modules</span>
                    <h2 class="title pb-15">Components of Real Estate</h2>
                </div>
            </div>
            <div class="col-lg-6 add-arrow-circle">
                <h5 class="title pb-15">Main Components</h5>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/realestate">Real Estate Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/financial-accounting">Financial Accounting</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/fixed-asset-management">Fixed Asset Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/approval-management">Approval Management System</a></p>

                <!-- experience item -->
            </div>
            <div class="col-lg-6 add-arrow-circle">
                <h5 class="title pb-15">Additional Components</h5>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/facility">Facility Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/inventory-management">Procurement and Inventory Control</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/human-resource-management-system">Human Resource Management System (Standard)</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/realestate">Approval Management App</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/human-resource-management-system">Human Resource Management System (Advance)</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/bank-facility-management">Bank Facility Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/realestate">Dashboard Management</a></p>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Components PART END ======-->

<!--====== Benefits at a glance PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                    <p class="add-arrow"> Maintain properties by using up to three hierarchies such as Property group, Property Type, Premise Categories and Individual units
                    </p>
                    <p class="add-arrow"> Manage the furnishings provided to the units and link to the Fixed Assets</p>
                    <p class="add-arrow"> Maintain rent values, standard charges & rates</p>
                    <p class="add-arrow"> Split & Merge Units to make the unit easily leasable</p>
                    <p class="add-arrow"> Maintain unlimited Unit Types such as apartment, villa, office shop, kiosks etc.,</p>
                    <p class="add-arrow"> Lease Management with multiple options – Single unit / merged units</p>
                    <p class="add-arrow"> Maintain leases with unfurnished to fully furnished units</p>
                    <p class="add-arrow"> Manage the Payment frequency with multiple options – Yearly, Half‐yearly, Quarterly & Monthly</p>

                    <p class="add-arrow"> Calculation of Contract value with a comprehensive rate matrix with multiple rental break ups</p>
                    <p class="add-arrow"> Manage the furnishings provided along with the leases</p>
                    <p class="add-arrow"> Capability to generate Lease Contracts and archive pdf version of signed contracts</p>
                    <p class="add-arrow"> Maintain periodic history of rent appraisals for the units</p>
                    <p class="add-arrow"> Maintain multiple increments / rent revisions for leases</p>
                    <p class="add-arrow"> Track the history of rent revisions against the generated revenues</p>
                    <p class="add-arrow"> Renew leases with options to renew the payment terms, extension of duration and increment / decrement in the lease values</p>

                    <p class="add-arrow"> Calculation and breakdown of the “Unearned Revenue” and reconciliation of opening and ending monthly balances with Rental Revenue and rental collections.</p>
                    <p class="add-arrow"> Calculation and breakdown of the accounting “Rental Revenue” on a monthly basis on a straight‐line basis.</p>
                    <p class="add-arrow"> User customizable reports giving users the ability to pick information to be displayed on reports</p>
                    <p class="add-arrow"> Ageing analysis of the tenants on multiple levels</p>
                    <p class="add-arrow"> Ability to inquire and generate reports on all historical data including as‐of records of AR and unearned revenue.</p>
                    <p class="add-arrow"> The dashboard on occupancy, vacancy, collections, gross revenue, rent per unit area</p>
                    <p>Horizon – EBS for the Real Estate can be deployed on-premise or on the cloud with aligned mobile applications. If you are looking for more details or having queries about Horizon – EBS for Real Estate, please feel free to Contact Us.
                    </p>

                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Benefits at a glance PART END ======-->
