<!-- Dubai Start -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop">
  <div
  class="d-flex justify-content-center align-items-center banner-image bmTop"
  style="
    background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
  "
>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h3 class="text-white">EXPENSE iNBOX</h3>
  </div>
</div>
<div style="overflow: hidden; position: relative;">
  <img src="../../../../../assets/images/products/products-abstract.png" alt="shape" style="position: absolute; overflow: clip;left: -3%; top: 0%; "/>

  <div class="mt-50 mb-50 container d-flex justify-content-start align-items-start">
    <div class="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">

      <img src="../../../../assets/images/products/expence.png" class="img-fluid" style="height: 23rem;" />

  </div>
    <div class="col-lg-8 col-md-12">
      <p
        class="text-dark text-justify mb-30"
        style="line-height: 2.5rem; font-weight: 500"
      >
        EXPENSE iNBOX is a highly scalable, out of the box EXPENSE reporting
        solution that delivers powerful insight into company and departmental
        spending through automated data analysis. Designed and built by Boston
        Financial, EXPENSE iNBOX benefits from the vast experience and in-depth
        understanding of corporate accounts and reporting.
      </p>

      <div class="navbar-btn contactUs-btn cursor-pointer" routerLink="/under-development">
        <a  class="text-white btn d-sm-mobile rounded contactBtn" style="background: #418052;"  ng-reflect-router-link-active="active" >Learn More</a>
      </div>
    </div>
  </div>
</div>

</div>
<!-- Dubai end -->

<!-- India Start -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div
  class="d-flex justify-content-center align-items-center banner-image bmTop"
  style="
    background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
  "
>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h3 class="text-white">EXPENSE iNBOX</h3>
  </div>
</div>
<div style="overflow: hidden; position: relative;">
  <img src="../../../../../assets/images/products/products-abstract.png" alt="shape" style="position: absolute; overflow: clip;left: -3%; top: 0%; "/>

  <div class="mt-50 mb-50 container d-flex justify-content-start align-items-start">
    <div class="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">

      <img src="../../../../assets/images/products/expence.png" class="img-fluid" style="height: 23rem;" />

  </div>
    <div class="col-lg-8 col-md-12">
      <p
        class="text-dark text-justify mb-30"
        style="line-height: 2.5rem; font-weight: 500"
      >
        EXPENSE iNBOX is a highly scalable, out of the box EXPENSE reporting
        solution that delivers powerful insight into company and departmental
        spending through automated data analysis. Designed and built by Boston
        Financial, EXPENSE iNBOX benefits from the vast experience and in-depth
        understanding of corporate accounts and reporting.
      </p>

      <div class="navbar-btn contactUs-btn cursor-pointer" routerLink="/under-development">
        <a  class="text-white btn d-sm-mobile rounded contactBtn" style="background: #072F5F;"  ng-reflect-router-link-active="active" >Learn More</a>
      </div>
    </div>
  </div>
</div>

</div>
<!-- India end -->

<!-- USA Start -->
<!-- USA Start -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div
  class="d-flex justify-content-center align-items-center banner-image bmTop"
  style="
    background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
  "
>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h3 class="text-white">EXPENSE iNBOX</h3>
  </div>
</div>
<div style="overflow: hidden; position: relative;">
  <img src="../../../../../assets/images/products/products-abstract.png" alt="shape" style="position: absolute; overflow: clip;left: -3%; top: 0%; "/>

  <div class="mt-50 mb-50 container d-flex justify-content-start align-items-start">
    <div class="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">

      <img src="../../../../assets/images/products/expence.png" class="img-fluid" style="height: 23rem;" />

  </div>
    <div class="col-lg-8 col-md-12">
      <p
        class="text-dark text-justify mb-30"
        style="line-height: 2.5rem; font-weight: 500"
      >
        EXPENSE iNBOX is a highly scalable, out of the box EXPENSE reporting
        solution that delivers powerful insight into company and departmental
        spending through automated data analysis. Designed and built by Boston
        Financial, EXPENSE iNBOX benefits from the vast experience and in-depth
        understanding of corporate accounts and reporting.
      </p>

      <div class="navbar-btn contactUs-btn cursor-pointer" routerLink="/under-development">
        <a  class="text-white btn d-sm-mobile rounded contactBtn" style="background: #072F5F;"  ng-reflect-router-link-active="active" >Learn More</a>
      </div>
    </div>
  </div>
</div>

</div>
<!-- USA end -->
<!-- USA end -->
