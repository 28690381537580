<!-- dubai Start -->
<div id="contact-modal" class="contact-modal">
  <div class="header get-in-touch-area get-in-touch-area-2 pt-3 pt-0-mob pb-0 bg-white modal-mob-design">
    <div class="form-area">
      <form class="bg-white" #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
        <div class="mob-form-height">
          <div class="row">
            <div class="col-lg-6">
              <div class="mt-18">
                <input type="text" placeholder="Full Name *" class="no-border" name="name" ngModel required />
                <i class="fal fa-user"></i>
                <hr />
                <div *ngIf="contactForm.submitted && contactForm.controls['name'].errors"
                  class="text-danger small" [ngClass]="{ 'is-invalid': contactForm.submitted && contactForm.controls['name'].errors }">
                  <div *ngIf="contactForm.controls['name'].errors?.required">Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="mt-18">
                <input type="email" placeholder="Email *" class="no-border" name="email" ngModel required />
                <i class="fal fa-envelope"></i>
                <hr />
                <div *ngIf="contactForm.submitted && contactForm.controls['email'].errors"
                  class="text-danger small" [ngClass]="{ 'is-invalid': contactForm.submitted && contactForm.controls['email'].errors }">
                  <div *ngIf="contactForm.controls['email'].errors?.required">Email is required</div>
                  <div *ngIf="contactForm.controls['email'].errors?.email">Please enter a valid email</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="mt-20">
                <input type="text" placeholder="Phone Number *" class="no-border" pattern="\d*" maxlength="15"
                  name="phoneNumber" ngModel required />
                <i class="fal fal fa-phone"></i>
                <hr />
                <div *ngIf="contactForm.submitted && contactForm.controls['phoneNumber'].errors"
                  class="text-danger small" [ngClass]="{ 'is-invalid': contactForm.submitted && contactForm.controls['phoneNumber'].errors }">
                  <div *ngIf="contactForm.controls['phoneNumber'].errors?.required">Mobile number is required</div>
                  <div *ngIf="contactForm.controls['phoneNumber'].errors?.pattern">Invalid mobile number</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="mt-20">
                <input type="text" placeholder="Subject" class="no-border" name="subject" ngModel />
                <i class="fal fa-edit"></i>
                <hr />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="mt-4">
                <div>
                  <textarea name="enquiryService" cols="60" rows="2" class="no-border" placeholder="Message *"
                    ngModel required></textarea>
                  <hr />
                  <div *ngIf="contactForm.submitted && contactForm.controls['enquiryService'].errors"
                    class="text-danger small" [ngClass]="{ 'is-invalid': contactForm.submitted && contactForm.controls['enquiryService'].errors }">
                    <div *ngIf="contactForm.controls['enquiryService'].errors?.required">Message is required</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 mt-3">
              <div class="d-flex justify-content-center">
                <div class="d-flex justify-content-center align-items-center"></div>
                <div class="col align-items-center" id="captchaModal"></div>
              </div>
            </div>
            <div class="col-lg-6">
              <div *ngIf="contactForm.submitted && contactForm.controls['recaptchaReactive'].errors"
                class="text-danger small" [ngClass]="{ 'is-invalid': contactForm.submitted && contactForm.controls['recaptchaReactive'].errors }">
                <div *ngIf="contactForm.controls['recaptchaReactive'].errors?.required" class="pl-3">Captcha is required</div>
              </div>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="termsCheckbox" name="acceptTerms" ngModel required />
                <label class="form-check-label" for="termsCheckbox">
                  I accept the <a routerLink="/privacy-policy" mat-dialog-close>terms and conditions</a>
                </label>
              </div>
              <div *ngIf="contactForm.submitted && contactForm.controls['acceptTerms'].errors?.required"
                class="text-danger small">
                You must accept the terms and conditions
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-15">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <button class="btn text-dark cancelBtn" mat-button mat-dialog-close
                style="background: white !important; border: 1px solid #072f5f;">
                Cancel
              </button>
              &nbsp;
              <button class="btn text-white" type="submit" [disabled]="contactForm.invalid || !contactForm.controls['acceptTerms'].value"
                style="background: #072f5f">SEND MESSAGE</button>
            </div>
          </div>
        </div>
      </form>
      <div class="alert alert-success mt-3" role="alert" *ngIf="resultTrue">
        Thank You for reaching out. We would get back to you shortly.
      </div>
      <div class="alert alert-danger mt-3" role="alert" *ngIf="resultFalse">
        Something went wrong please try again
      </div>
    </div>
    
  </div>
</div>
