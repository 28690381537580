
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area customized-solution customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Customized Solution</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Customized Solution</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Customized SolutionPART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Tailor made solution</span>
                    <h2 class="title pb-15">Customized Solution</h2>
                    <p>Any industry and organization is unique, when generic ERP software designed for standard business models won’t do, we fills the gap with specially-designed functionality for every aspect of your business. Get you ERP built the way
                        you do your business. Why to go for non-customizable software solution when you have opportunity to have a unique one that fits perfectly to your present and future organizational requirements? At Frontline we offer tailor made
                        solution considering the nature of the organization, offering global functionality with your business perspective.</p>
                    <p>Frontline’s experienced team of ERP experts can help your organization customize the Horizon Software based on the needs of the business, without any compromise. Our business solutions analysts can expertly break down the entire business
                        process of the organization and provide a solution that can align strategically to enhance the business processes and improve efficiencies.</p>
                    <p>Our customization services employ their experience and expertise in the field to review system architecture, design and customizations. Also, the end users are provided training to help validate performance, provide scalability, ensure
                        security and facilitate integration with the system requirements.</p>
                    <p>Customization benefits include improved design, performance and scalability, smooth integration with other systems and enforce quality control and consistency.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Customized SolutionPART END ======-->

<!--====== Process of Customization PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="experience-item"><span>Procedure</span>
                    <h2 class="title pb-15">Process of Customization</h2>
                </div>
            </div>
            <div class="col-md-12">
                <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a class="w-100" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Requirement Study
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">In this phase, the requirements related to scope of the project, functionality, automizations, integration, resources involved, deliverables are gathered and functional documents are prepared.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>System Designing
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">Based on the functional definition document a software architecture which is the logical flow of the software to be developed is designed.</p>

                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Development and Testing
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">Based on the system design, our team makes use of the latest technologies and tools for the software development. The software gets process through series of quality assessment procedures, beginning with us and ending
                                        with the client in their User Acceptance Testing (UAT) phase.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Process of Customization PART END ======-->
