<style>

  .text-dark-one{
    color: #343a40 ;
    font-size: 16px;

  }
</style>


<!-- dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 divm">
  <!--====== PAGE TITLE PART START ======-->
  <div class="d-flex justify-content-center align-items-center banner-image" style="
      background: url('../../../../assets/images/contact/Contact.png') no-repeat
        center;
      background-size: cover;
      height: 430px;
    ">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Contact Us</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Contact Us</h4> -->
    </div>
  </div>
  <!--====== CONTACT DETAILS PART START ======-->
  <div class="contact-details-area contact-details-area-custom">
    <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="mt-30">
          <!-- <div class="d-flex justify-content-center align-items-center">
            <h3
              class="text-center"
              style="border-bottom: 3px solid #072F5F; display: inline-block"
            >
              CONTACT US
            </h3>
          </div> -->
          <div>

            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1FxbQDV1mxYYvPsLcdQj8EF8LnZVkWBo&ehbc=2E312F"
              width="100%" height="450" style="border: 0" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade" class="banner-image"></iframe>


            <!-- <iframe
              src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d30766419.24642019!2d60.97850039341277!3d19.726821591337078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!3m2!1d20.593684!2d78.96288!5e0!3m2!1sen!2sin!4v1682479467605!5m2!1sen!2sin"
              width="100%"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe> -->

          </div>
        </div>
      </div>
    </div></div>
  </div>
  <!-- main -->
  <section style="position: relative;">
    <img src="assets/images/contact-us-abstract.png" alt="shape"
      style="position: absolute; overflow: clip;max-width: 21%; z-index: -1;">
    <img src="assets/images/contact-us-abstract.png" alt="shape"
      style="position: absolute; overflow: clip;max-width: 21%; z-index: -1; right: 0;">
    <img src="assets/images/contact-us-abstract.png" alt="shape"
      style="position: absolute; overflow: clip;max-width: 21%; z-index: -1; left:25%">
    <img src="assets/images/contact-us-abstract.png" alt="shape"
      style="position: absolute; overflow: clip;max-width: 21%; z-index: -1; right:25%">
      <div class="container d-flex justify-content-center align-items-center">
        <div class="row d-flex justify-content-center align-items-center">

    <div class="d-flex justify-content-center align-items-center   mb-50"
      style="background-color: white; max-width: 930px !important; margin: auto;">
      <div class="row d-flex justify-content-center align-items-start shadow-lg divmar divusa"
        style="border-radius: 21px; padding: 2rem 2rem 0 2rem;  background-color: white;">
        <div class="d-flex flex-column justify-content-center align-items-center" style="width: 9rem">
          <div class="circle" style="background: #072f5f33">
            <!-- <i class="far fa-map-marker-alt fa-2x"></i> -->
            <img src="../../../../assets/images/contact/usaCircle.png" alt="">
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center">
            <span class="text-center text-dark" style="font-size: 18px; font-weight: 600">USA</span>
            <!-- <span style="width: 2rem;">
              <img src="../../../../assets/images/flag/USA.png" alt="">
            </span> -->
          </div>
        </div>
        <div class="col-sm d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
          <ul>
            <li class="text-dark d-flex" style="
                line-height: normal;
                /* text-align: justify; */
                font-size: 16px;
              ">
              <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem; color:#418052;"></i>
              <span>Boston Financial Advisory Group Inc. The Schrafts Center Power House, Office No 341, 529 Main
                Street,Charlestown, MA – 02129</span>
            </li>
          </ul>
          <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
            style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 1rem !important;">
            <a class="text-dark">
              <i class="far fa-phone" style="color: #418052;"></i> +1 617-886-5181</a>
          </div>
          <div style="height: 3rem"></div>
        </div>
        <div class="col-sm d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
          <ul>
            <li class="text-dark d-flex" style="
                line-height: normal;
                /* text-align: justify; */
                font-size: 16px;
              ">
              <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem; color:#418052;"></i>
              <span>2214 Walnut Street, Mckeesport, PA 15132</span>
            </li>
          </ul>
          <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop usapone2"
            style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 5.5rem;">
            <a class="text-dark">
              <i class="far fa-phone" style="color: #418052;"></i> +1 617-886-5181</a>
          </div>
        </div>
        <!-- <div class="col-sm d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
          <ul>
            <li class="text-dark d-flex" style="
                line-height: normal;
                text-align: justify;
                font-size: 16px;
              ">
              <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem; color:#418052;"></i>
              <span>Boston Financial Advisory Group Inc.4725 West Sand Lake Road,Orlando, FL 32819</span>
            </li>
          </ul>
          <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop "
            style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 4rem !important;">
            <a class="text-dark">
              <i class="far fa-phone" style="color: #418052;"></i> +1 617-886-5181</a>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

    <div class="container d-flex justify-content-center align-items-start mt-50" style="gap: 3rem"
      style="background-color: white;">
        <div class="row d-flex justify-content-center">
      <div class="col-xl-7 d-flex justify-content-between align-items-center divmar divind  mb-50">
        <div class="row d-flex justify-content-center align-items-start shadow-lg"
          style="border-radius: 21px; padding: 2rem 2rem 0 2rem">

          <div class="d-flex flex-column justify-content-center align-items-center" style="width: 9rem">
            <div class="circle" style="background: #072f5f33">
              <!-- <i class="far fa-map-marker-alt fa-2x"></i> -->
              <img src="../../../../assets/images/contact/indiaCircle.png" alt="">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="text-center text-dark" style="font-size: 18px; font-weight: 600">INDIA</span>
              <!-- <span style="width: 2rem;">
                <img src="../../../../assets/images/flag/download.png" alt="">
              </span> -->
            </div>
          </div>

          <div class="col-sm d-flex flex-column justify-content-center align-items-center"
            style="width: 15rem; height: 19rem;">
            <ul>
              <li class="text-dark d-flex" style="
                  line-height: normal;
                  /* text-align: justify; */
                  font-size: 16px;
                ">
                <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem; color:#418052;"></i>
                <span>Boston Financial Advisory Group Inc.Office No. 203/204, 2nd
                  Floor,Crystal Empire, Near Karnataka Bank,Behind D-Mart,
                  Baner, Pune 411045.</span>
              </li>
            </ul>
            <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
              style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 3rem !important;">
              <a class="text-dark">
                <i class="far fa-phone" style="color: #418052;"></i> +91 7420061235</a>
            </div>
            <div style="height: 3rem"></div>
          </div>
          <div class="col-sm d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
            <ul>
              <li class="text-dark d-flex" style="
                  line-height: normal;
                  /* text-align: justify; */
                  font-size: 16px;
                ">
                <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem; color:#418052;"></i>
                <span>Boston Financial Advisory Group Inc.# 54, 3rd Floor, 13th
                  Main 17th Cross,Sector 6, Behind BDA Shopping Complex,HSR
                  Layout, Bangalore 560 102.</span>
              </li>
            </ul>
            <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
              style="border-radius: 30px; background: #d9d9d9; width: 12rem">
              <a class="text-dark">
                <i class="far fa-phone" style="color: #418052;"></i> +91 7420061235</a>
            </div>
            <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
              style="border-radius: 30px; background: #d9d9d9; width: 12rem">
              <a class="text-dark">
                <i class="far fa-phone" style="color: #418052;"></i> +91 7420061235</a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-5 d-flex justify-content-center align-items-center divmar divuae mb-50">
        <div class="row d-flex justify-content-center align-items-start shadow-lg"
          style="border-radius: 21px; padding: 2rem 2rem 3rem 2rem; height: 23rem">

          <div class="d-flex flex-column justify-content-center align-items-center" style="width: 8rem">
            <div class="circle">
              <img src="../../../../assets/images/contact/uaeCircle.png" alt="">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center pt-1">
              <span class="text-center text-dark" style="font-size: 18px; font-weight: 600">UAE</span>
              <!-- <span style="width: 2rem;">
                  <img src="../../../../assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png" alt="">
                </span> -->
            </div>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
            <ul>
              <li class="text-dark d-flex" style="
                  line-height: normal;
                  /* text-align: justify; */
                  font-size: 16px;
                  width: 15rem;
                ">
                <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem; color:#418052;"></i>
                <span>Boston Financial Advisory Group Inc.Unit 2540, 25th Floor,
                  Iris Bay, Business Bay, Dubai, UAE</span>
              </li>
            </ul>

            <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
              style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 2rem !important;">
              <a class="text-dark">
                <i class="far fa-phone" style="color: #418052;"></i> +971 4 3760840</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="container mb-30">
      <div class="d-flex justify-content-center align-items-center w-100 flex-wrap" style="
          background: #418052;
          min-height: 4rem;
          border-radius: 20px;
          padding: 1rem;
        ">
        <div class="col-sm d-flex justify-content-center align-items-center">
          <span class="text-white">info@bostonfagroup.com</span>
        </div>
        <div class="col-sm d-flex justify-content-center align-items-center">
          <span class="text-white">sales@bostonfagroup.com</span>
        </div>
        <div class="col-sm d-flex justify-content-center align-items-center">
          <span class="text-white">careers@bostonfagroup.com</span>
        </div>
      </div>
    </div>
  </section>

  <!--====== CONTACT DETAILS PART ENDS ======-->
  <div class="container-fluid">
  <div class="row d-flex flex-column justify-content-center align-items-center pt-4" style="background: #f0f5f5">
    <!-- <div id="container">
    <h4 id="title">Sound Finance Matters</h4>
  </div> -->
    <div class="d-flex justify-content-center align-items-center">
      <div class="line1" style="min-width: 5rem; height: 2px; background: black"></div>
      <div>
        <h4 class="text-uppercase pl-2 pr-2 soundh">Sound Finance Matters</h4>
      </div>
      <div class="line2" style="min-width: 5rem; height: 2px; background: black"></div>
    </div>
    <div class="col-lg-12 d-flex justify-content-center">

      <small class="p-15 pt-3 pl-1 pr-1 text-center text-dark font-weight-bold " style="font-size: 14px !important">To know
        more, do not hesitate to get in touch by phone or email. Take a
        moment to fill out this form to get quick support for your queries.</small>
      </div>
      <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
        <div class="col-lg-12 d-flex flex-wrap pb-3">
          <div class="col-lg-6 col-md-6 col-12 pt-5">
            <div class="row">
              <div class="col">
                <input type="text" class="form-control" ngModel name="name" placeholder="Name*" required />
                <div *ngIf="contactForm.submitted && contactForm.controls.name?.errors">
                  <small class="text-danger" *ngIf="contactForm.controls.name?.errors.required">Name is required</small>
                </div>
              </div>
              <div class="col">
                <input type="email" class="form-control" ngModel name="email" placeholder="E-mail" required />
                <div *ngIf="contactForm.submitted && contactForm.controls.email?.errors">
                  <small class="text-danger" *ngIf="contactForm.controls.email?.errors.required">Email is required</small>
                  <small class="text-danger" *ngIf="contactForm.controls.email?.errors.email">Invalid email format</small>
                </div>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col">
                <input type="text" class="form-control" ngModel name="phoneNumber" placeholder="Phone*" required />
                <div *ngIf="contactForm.submitted && contactForm.controls.phoneNumber?.errors">
                  <small class="text-danger" *ngIf="contactForm.controls.phoneNumber?.errors.required">Phone number is required</small>
                </div>
              </div>
              <div class="col">
                <input type="text" class="form-control" ngModel name="subject" placeholder="Subject" required />
                <div *ngIf="contactForm.submitted && contactForm.controls.subject?.errors">
                  <small class="text-danger" *ngIf="contactForm.controls.subject?.errors.required">Subject is required</small>
                </div>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col">
                <label for="">Enquiry for Services*</label>
                <input type="text" class="form-control" ngModel name="enquiryService" required />
                <div *ngIf="contactForm.submitted && contactForm.controls.enquiryService?.errors">
                  <small class="text-danger" *ngIf="contactForm.controls.enquiryService?.errors.required">Enquiry service is required</small>
                </div>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="termsCheckbox" ngModel name="acceptTerms" required />
                  <label class="form-check-label" for="termsCheckbox">
                    I accept the <a routerLink="/privacy-policy">terms and conditions</a>
                  </label>
                  <div *ngIf="contactForm.submitted && contactForm.controls.acceptTerms?.errors">
                    <small class="text-danger" *ngIf="contactForm.controls.acceptTerms?.errors.required">You must accept the terms</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-4" id="button">
              <div class="col">
                <button class="btn form-control text-white" style="background: #418052">
                  Send
                </button>
              </div>
            </div>
            <div class="alert alert-success mt-3" role="alert" *ngIf="resultTrue">
              Thank You for reaching out. We will get back to you shortly.
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12 pt-5" id="msg-box">
            <div class="row">
              <div class="col">
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="10" ngModel name="message" placeholder="Message" required></textarea>
                <div *ngIf="contactForm.submitted && contactForm.controls.message?.errors">
                  <small class="text-danger" *ngIf="contactForm.controls.message?.errors.required">Message is required</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      
  </div></div>
</div>
<!-- dubai end -->

<!-- india started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 divm">
  <!--====== PAGE TITLE PART START ======-->
  <div class="d-flex justify-content-center align-items-center banner-image" style="
      background: url('../../../../assets/images/contact/Contact.png') no-repeat
        center;
      background-size: cover;
      height: 430px;
    ">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Contact Us</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Contact Us</h4> -->
    </div>
  </div>
  <!--====== CONTACT DETAILS PART START ======-->
  <div class="contact-details-area contact-details-area-custom">
    <div class="container-fluid">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-12">
        <div class="mt-30">
          <!-- <div class="d-flex justify-content-center align-items-center">
            <h3
              class="text-center"
              style="border-bottom: 3px solid #072F5F; display: inline-block"
            >
              CONTACT US
            </h3>
          </div> -->
          <div>
            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1FxbQDV1mxYYvPsLcdQj8EF8LnZVkWBo&ehbc=2E312F"
              width="100%" height="450" style="border: 0" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade" class="banner-image"></iframe>
            <!-- <iframe
              src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d30766419.24642019!2d60.97850039341277!3d19.726821591337078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!3m2!1d20.593684!2d78.96288!5e0!3m2!1sen!2sin!4v1682479467605!5m2!1sen!2sin"
              width="100%"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe> -->

          </div>
        </div>
      </div>
    </div></div>
  </div>
  <!-- main -->
  <section style="position: relative;">
    <img src="assets/images/contact-us-abstract.png" alt="shape"
      style="position: absolute; overflow: clip;max-width: 21%; z-index: -1;">
    <img src="assets/images/contact-us-abstract.png" alt="shape"
      style="position: absolute; overflow: clip;max-width: 21%; z-index: -1; right: 0;">
    <img src="assets/images/contact-us-abstract.png" alt="shape"
      style="position: absolute; overflow: clip;max-width: 21%; z-index: -1; left:25%">
    <img src="assets/images/contact-us-abstract.png" alt="shape"
      style="position: absolute; overflow: clip;max-width: 21%; z-index: -1; right:25%">
      <div class="container d-flex justify-content-center align-items-center">
        <div class="row d-flex justify-content-center align-items-center">

    <div class="d-flex justify-content-center align-items-center   mb-50"
      style="background-color: white; max-width: 930px !important; margin: auto;">
      <div class="row d-flex justify-content-center align-items-start shadow-lg divmar divusa"
        style="border-radius: 21px; padding: 2rem 2rem 0 2rem;  background-color: white;">
        <div class="d-flex flex-column justify-content-center align-items-center" style="width: 9rem">
          <div class="circle" style="background: #072f5f33">
            <!-- <i class="far fa-map-marker-alt fa-2x"></i> -->
            <img src="../../../../assets/images/contact/usaCircle.png" alt="">
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center">
            <span class="text-center text-dark" style="font-size: 18px; font-weight: 600">USA</span>
            <!-- <span style="width: 2rem;">
              <img src="../../../../assets/images/flag/USA.png" alt="">
            </span> -->
          </div>
        </div>
        <div class="col-sm d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
          <ul>
            <li class="text-dark d-flex" style="
                line-height: normal;
                /* text-align: justify; */
                font-size: 16px;
              ">
              <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem"></i>
              <span>Boston Financial Advisory Group Inc. The Schrafts Center Power House, Office No 341, 529 Main
                Street,Charlestown, MA – 02129</span>
            </li>
          </ul>
          <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
            style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 1rem !important;">
            <a class="text-dark">
              <i class="far fa-phone"></i> +1 617-886-5181</a>
          </div>
          <div style="height: 3rem"></div>
        </div>
        <div class="col-sm d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
          <ul>
            <li class="text-dark d-flex" style="
                line-height: normal;
                /* text-align: justify; */
                font-size: 16px;
              ">
              <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem"></i>
              <span>2214 Walnut Street, Mckeesport, PA 15132</span>
            </li>
          </ul>
          <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop usapone2"
            style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 5.5rem;">
            <a class="text-dark">
              <i class="far fa-phone"></i> +1 617-886-5181</a>
          </div>
        </div>
        <!-- <div class="col-sm d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
          <ul>
            <li class="text-dark d-flex" style="
                line-height: normal;
                text-align: justify;
                font-size: 16px;
              ">
              <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem"></i>
              <span>Boston Financial Advisory Group Inc.4725 West Sand Lake Road,Orlando, FL 32819</span>
            </li>
          </ul>
          <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop "
            style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 4rem !important;">
            <a class="text-dark">
              <i class="far fa-phone"></i> +1 617-886-5181</a>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

    <div class="container d-flex justify-content-center align-items-start mt-50" style="gap: 3rem"
      style="background-color: white;">
        <div class="row d-flex justify-content-center">
      <div class="col-xl-7 d-flex justify-content-between align-items-center divmar divind  mb-50">
        <div class="row d-flex justify-content-center align-items-start shadow-lg"
          style="border-radius: 21px; padding: 2rem 2rem 0 2rem">

          <div class="d-flex flex-column justify-content-center align-items-center" style="width: 9rem">
            <div class="circle" style="background: #072f5f33">
              <!-- <i class="far fa-map-marker-alt fa-2x"></i> -->
              <img src="../../../../assets/images/contact/indiaCircle.png" alt="">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="text-center text-dark" style="font-size: 18px; font-weight: 600">INDIA</span>
              <!-- <span style="width: 2rem;">
                <img src="../../../../assets/images/flag/download.png" alt="">
              </span> -->
            </div>
          </div>

          <div class="col-sm d-flex flex-column justify-content-center align-items-center"
            style="width: 15rem; height: 19rem;">
            <ul>
              <li class="text-dark d-flex" style="
                  line-height: normal;
                  /* text-align: justify; */
                  font-size: 16px;
                ">
                <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem"></i>
                <span>Boston Financial Advisory Group Inc.Office No. 203/204, 2nd
                  Floor,Crystal Empire, Near Karnataka Bank,Behind D-Mart,
                  Baner, Pune 411045.</span>
              </li>
            </ul>
            <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
              style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 3rem !important;">
              <a class="text-dark">
                <i class="far fa-phone"></i> +91 7420061235</a>
            </div>
            <div style="height: 3rem"></div>
          </div>
          <div class="col-sm d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
            <ul>
              <li class="text-dark d-flex" style="
                  line-height: normal;
                  /* text-align: justify; */
                  font-size: 16px;
                ">
                <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem"></i>
                <span>Boston Financial Advisory Group Inc.# 54, 3rd Floor, 13th
                  Main 17th Cross,Sector 6, Behind BDA Shopping Complex,HSR
                  Layout, Bangalore 560 102.</span>
              </li>
            </ul>
            <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
              style="border-radius: 30px; background: #d9d9d9; width: 12rem">
              <a class="text-dark">
                <i class="far fa-phone"></i> +91 7420061235</a>
            </div>
            <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
              style="border-radius: 30px; background: #d9d9d9; width: 12rem">
              <a class="text-dark">
                <i class="far fa-phone"></i> +91 7420061235</a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-5 d-flex justify-content-center align-items-center divmar divuae mb-50">
        <div class="row d-flex justify-content-center align-items-start shadow-lg"
          style="border-radius: 21px; padding: 2rem 2rem 3rem 2rem; height: 23rem">

          <div class="d-flex flex-column justify-content-center align-items-center" style="width: 8rem">
            <div class="circle">
              <img src="../../../../assets/images/contact/uaeCircle.png" alt="">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center pt-1">
              <span class="text-center text-dark" style="font-size: 18px; font-weight: 600">UAE</span>
              <!-- <span style="width: 2rem;">
                  <img src="../../../../assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png" alt="">
                </span> -->
            </div>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
            <ul>
              <li class="text-dark d-flex" style="
                  line-height: normal;
                  /* text-align: justify; */
                  font-size: 16px;
                  width: 15rem;
                ">
                <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem"></i>
                <span>Boston Financial Advisory Group Inc.Unit 2540, 25th Floor,
                  Iris Bay, Business Bay, Dubai, UAE</span>
              </li>
            </ul>

            <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
              style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 2rem !important;">
              <a class="text-dark">
                <i class="far fa-phone"></i> +971 4 3760840</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="container mb-30">
      <div class="d-flex justify-content-center align-items-center w-100 flex-wrap" style="
          background: #072F5F;
          min-height: 4rem;
          border-radius: 20px;
          padding: 1rem;
        ">
        <div class="col-sm d-flex justify-content-center align-items-center">
          <span class="text-white">info@bostonfagroup.com</span>
        </div>
        <div class="col-sm d-flex justify-content-center align-items-center">
          <span class="text-white">sales@bostonfagroup.com</span>
        </div>
        <div class="col-sm d-flex justify-content-center align-items-center">
          <span class="text-white">careers@bostonfagroup.com</span>
        </div>
      </div>
    </div>
  </section>


  <!--====== CONTACT DETAILS PART ENDS ======-->
  <div class="container-fluid">
  <div class="row d-flex flex-column justify-content-center align-items-center pt-4" style="background: #f0f5f5">
    <!-- <div id="container">
    <h4 id="title">Sound Finance Matters</h4>
  </div> -->
    <div class="d-flex justify-content-center align-items-center">
      <div class="line1" style="min-width: 5rem; height: 2px; background: black"></div>
      <div>
        <h4 class="text-uppercase pl-2 pr-2 soundh">Sound Finance Matters</h4>
      </div>
      <div class="line2" style="min-width: 5rem; height: 2px; background: black"></div>
    </div>

    <div class="col-lg-12 d-flex justify-content-center">

    <small class="p-15 pt-3 pl-1 pr-1 text-center text-dark font-weight-bold " style="font-size: 14px !important">To know
      more, do not hesitate to get in touch by phone or email. Take a
      moment to fill out this form to get quick support for your queries.</small>
    </div>
    <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
      <div class="col-lg-12 d-flex flex-wrap pb-3">
        <div class="col-lg-6 col-md-6 col-12 pt-5">
          <div class="row">
            <div class="col">
              <input type="text" class="form-control" ngModel name="name" placeholder="Name*" required />
              <div *ngIf="contactForm.submitted && contactForm.controls.name?.errors">
                <small class="text-danger" *ngIf="contactForm.controls.name?.errors.required">Name is required</small>
              </div>
            </div>
            <div class="col">
              <input type="email" class="form-control" ngModel name="email" placeholder="E-mail" required />
              <div *ngIf="contactForm.submitted && contactForm.controls.email?.errors">
                <small class="text-danger" *ngIf="contactForm.controls.email?.errors.required">Email is required</small>
                <small class="text-danger" *ngIf="contactForm.controls.email?.errors.email">Invalid email format</small>
              </div>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col">
              <input type="text" class="form-control" ngModel name="phoneNumber" placeholder="Phone*" required />
              <div *ngIf="contactForm.submitted && contactForm.controls.phoneNumber?.errors">
                <small class="text-danger" *ngIf="contactForm.controls.phoneNumber?.errors.required">Phone number is required</small>
              </div>
            </div>
            <div class="col">
              <input type="text" class="form-control" ngModel name="subject" placeholder="Subject" required />
              <div *ngIf="contactForm.submitted && contactForm.controls.subject?.errors">
                <small class="text-danger" *ngIf="contactForm.controls.subject?.errors.required">Subject is required</small>
              </div>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col">
              <label for="">Enquiry for Services*</label>
              <input type="text" class="form-control" ngModel name="enquiryService" required />
              <div *ngIf="contactForm.submitted && contactForm.controls.enquiryService?.errors">
                <small class="text-danger" *ngIf="contactForm.controls.enquiryService?.errors.required">Enquiry service is required</small>
              </div>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="termsCheckbox" ngModel name="acceptTerms" required />
                <label class="form-check-label" for="termsCheckbox">
                  I accept the <a routerLink="/privacy-policy">terms and conditions</a>
                </label>
                <div *ngIf="contactForm.submitted && contactForm.controls.acceptTerms?.errors">
                  <small class="text-danger" *ngIf="contactForm.controls.acceptTerms?.errors.required">You must accept the terms</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-4" id="button">
            <div class="col">
              <button class="btn form-control text-white" style="background: #418052">
                Send
              </button>
            </div>
          </div>
          <div class="alert alert-success mt-3" role="alert" *ngIf="resultTrue">
            Thank You for reaching out. We will get back to you shortly.
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12 pt-5" id="msg-box">
          <div class="row">
            <div class="col">
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="10" ngModel name="message" placeholder="Message" required></textarea>
              <div *ngIf="contactForm.submitted && contactForm.controls.message?.errors">
                <small class="text-danger" *ngIf="contactForm.controls.message?.errors.required">Message is required</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    
  </div></div>
</div>
<!-- india end -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 divm">
  <!--====== PAGE TITLE PART START ======-->
  <div class="d-flex justify-content-center align-items-center banner-image" style="
      background: url('../../../../assets/images/contact/Contact.png') no-repeat
        center;
      background-size: cover;
      height: 430px;
    ">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Contact Us</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Contact Us</h4> -->
    </div>
  </div>
  <!--====== CONTACT DETAILS PART START ======-->
  <div class="contact-details-area contact-details-area-custom">
    <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="mt-30">
          <!-- <div class="d-flex justify-content-center align-items-center">
            <h3
              class="text-center"
              style="border-bottom: 3px solid #072F5F; display: inline-block"
            >
              CONTACT US
            </h3>
          </div> -->
          <div>
            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1FxbQDV1mxYYvPsLcdQj8EF8LnZVkWBo&ehbc=2E312F"
              width="100%" height="450" style="border: 0" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade" class="banner-image"></iframe>
            <!-- <iframe
              src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d30766419.24642019!2d60.97850039341277!3d19.726821591337078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!3m2!1d20.593684!2d78.96288!5e0!3m2!1sen!2sin!4v1682479467605!5m2!1sen!2sin"
              width="100%"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe> -->

          </div>
        </div>
      </div>
    </div></div>
  </div>
  <!-- main -->
  <section style="position: relative;">
    <img src="assets/images/contact-us-abstract.png" alt="shape"
      style="position: absolute; overflow: clip;max-width: 21%; z-index: -1;">
    <img src="assets/images/contact-us-abstract.png" alt="shape"
      style="position: absolute; overflow: clip;max-width: 21%; z-index: -1; right: 0;">
    <img src="assets/images/contact-us-abstract.png" alt="shape"
      style="position: absolute; overflow: clip;max-width: 21%; z-index: -1; left:25%">
    <img src="assets/images/contact-us-abstract.png" alt="shape"
      style="position: absolute; overflow: clip;max-width: 21%; z-index: -1; right:25%">
      <div class="container d-flex justify-content-center align-items-center">
        <div class="row d-flex justify-content-center align-items-center">

    <div class="d-flex justify-content-center align-items-center   mb-50"
      style="background-color: white; max-width: 930px !important; margin: auto;">
      <div class="row d-flex justify-content-center align-items-start shadow-lg divmar divusa"
        style="border-radius: 21px; padding: 2rem 2rem 0 2rem;  background-color: white;">
        <div class="d-flex flex-column justify-content-center align-items-center" style="width: 9rem">
          <div class="circle" style="background: #072f5f33">
            <!-- <i class="far fa-map-marker-alt fa-2x"></i> -->
            <img src="../../../../assets/images/contact/usaCircle.png" alt="">
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center">
            <span class="text-center text-dark" style="font-size: 18px; font-weight: 600">USA</span>
            <!-- <span style="width: 2rem;">
              <img src="../../../../assets/images/flag/USA.png" alt="">
            </span> -->
          </div>
        </div>
        <div class="col-sm d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
          <ul>
            <li class="text-dark d-flex" style="
                line-height: normal;
                /* text-align: justify; */
                font-size: 16px;
              ">
              <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem"></i>
              <span>Boston Financial Advisory Group Inc. The Schrafts Center Power House, Office No 341, 529 Main
                Street,Charlestown, MA – 02129</span>
            </li>
          </ul>
          <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
            style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 1rem !important;">
            <a class="text-dark">
              <i class="far fa-phone"></i> +1 617-886-5181</a>
          </div>
          <div style="height: 3rem"></div>
        </div>
        <div class="col-sm d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
          <ul>
            <li class="text-dark d-flex" style="
                line-height: normal;
                /* text-align: justify; */
                font-size: 16px;
              ">
              <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem"></i>
              <span>2214 Walnut Street, Mckeesport, PA 15132</span>
            </li>
          </ul>
          <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop usapone2"
            style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 5.5rem;">
            <a class="text-dark">
              <i class="far fa-phone"></i> +1 617-886-5181</a>
          </div>
        </div>
        <!-- <div class="col-sm d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
          <ul>
            <li class="text-dark d-flex" style="
                line-height: normal;
                text-align: justify;
                font-size: 16px;
              ">
              <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem"></i>
              <span>Boston Financial Advisory Group Inc.4725 West Sand Lake Road,Orlando, FL 32819</span>
            </li>
          </ul>
          <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop "
            style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 4rem !important;">
            <a class="text-dark">
              <i class="far fa-phone"></i> +1 617-886-5181</a>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

    <div class="container d-flex justify-content-center align-items-start mt-50" style="gap: 3rem"
      style="background-color: white;">
        <div class="row d-flex justify-content-center">
      <div class="col-xl-7 d-flex justify-content-between align-items-center divmar divind  mb-50">
        <div class="row d-flex justify-content-center align-items-start shadow-lg"
          style="border-radius: 21px; padding: 2rem 2rem 0 2rem">

          <div class="d-flex flex-column justify-content-center align-items-center" style="width: 9rem">
            <div class="circle" style="background: #072f5f33">
              <!-- <i class="far fa-map-marker-alt fa-2x"></i> -->
              <img src="../../../../assets/images/contact/indiaCircle.png" alt="">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <span class="text-center text-dark" style="font-size: 18px; font-weight: 600">INDIA</span>
              <!-- <span style="width: 2rem;">
                <img src="../../../../assets/images/flag/download.png" alt="">
              </span> -->
            </div>
          </div>

          <div class="col-sm d-flex flex-column justify-content-center align-items-center"
            style="width: 15rem; height: 19rem;">
            <ul>
              <li class="text-dark d-flex" style="
                  line-height: normal;
                  /* text-align: justify; */
                  font-size: 16px;
                ">
                <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem"></i>
                <span>Boston Financial Advisory Group Inc.Office No. 203/204, 2nd
                  Floor,Crystal Empire, Near Karnataka Bank,Behind D-Mart,
                  Baner, Pune 411045.</span>
              </li>
            </ul>
            <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
              style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 3rem !important;">
              <a class="text-dark">
                <i class="far fa-phone"></i> +91 7420061235</a>
            </div>
            <div style="height: 3rem"></div>
          </div>
          <div class="col-sm d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
            <ul>
              <li class="text-dark d-flex" style="
                  line-height: normal;
                  /* text-align: justify; */
                  font-size: 16px;
                ">
                <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem"></i>
                <span>Boston Financial Advisory Group Inc.# 54, 3rd Floor, 13th
                  Main 17th Cross,Sector 6, Behind BDA Shopping Complex,HSR
                  Layout, Bangalore 560 102.</span>
              </li>
            </ul>
            <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
              style="border-radius: 30px; background: #d9d9d9; width: 12rem">
              <a class="text-dark">
                <i class="far fa-phone"></i> +91 7420061235</a>
            </div>
            <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
              style="border-radius: 30px; background: #d9d9d9; width: 12rem">
              <a class="text-dark">
                <i class="far fa-phone"></i> +91 7420061235</a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-5 d-flex justify-content-center align-items-center divmar divuae mb-50">
        <div class="row d-flex justify-content-center align-items-start shadow-lg"
          style="border-radius: 21px; padding: 2rem 2rem 3rem 2rem; height: 23rem">

          <div class="d-flex flex-column justify-content-center align-items-center" style="width: 8rem">
            <div class="circle">
              <img src="../../../../assets/images/contact/uaeCircle.png" alt="">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center pt-1">
              <span class="text-center text-dark" style="font-size: 18px; font-weight: 600">UAE</span>
              <!-- <span style="width: 2rem;">
                  <img src="../../../../assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png" alt="">
                </span> -->
            </div>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center" style="width: 15rem">
            <ul>
              <li class="text-dark d-flex" style="
                  line-height: normal;
                  /* text-align: justify; */
                  font-size: 16px;
                  width: 15rem;
                ">
                <i class="fas fa-location-arrow" style="padding-right: 0.5rem; padding-top: 0.4rem"></i>
                <span>Boston Financial Advisory Group Inc.Unit 2540, 25th Floor,
                  Iris Bay, Business Bay, Dubai, UAE</span>
              </li>
            </ul>

            <div class="d-flex justify-content-center align-items-center pl-0 pr-0 pt-2 pb-2 mt-3 phonenop"
              style="border-radius: 30px; background: #d9d9d9; width: 12rem; margin-top: 2rem !important;">
              <a class="text-dark">
                <i class="far fa-phone"></i> +971 4 3760840</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="container mb-30">
      <div class="d-flex justify-content-center align-items-center w-100 flex-wrap" style="
          background: #072F5F;
          min-height: 4rem;
          border-radius: 20px;
          padding: 1rem;
        ">
        <div class="col-sm d-flex justify-content-center align-items-center">
          <span class="text-white">info@bostonfagroup.com</span>
        </div>
        <div class="col-sm d-flex justify-content-center align-items-center">
          <span class="text-white">sales@bostonfagroup.com</span>
        </div>
        <div class="col-sm d-flex justify-content-center align-items-center">
          <span class="text-white">careers@bostonfagroup.com</span>
        </div>
      </div>
    </div>
  </section>

  <!--====== CONTACT DETAILS PART ENDS ======-->
  <div class="container-fluid">
  <div class="row d-flex flex-column justify-content-center align-items-center pt-4" style="background: #f0f5f5">
    <!-- <div id="container">
    <h4 id="title">Sound Finance Matters</h4>
  </div> -->
    <div class="d-flex justify-content-center align-items-center">
      <div class="line1" style="min-width: 5rem; height: 2px; background: black"></div>
      <div>
        <h4 class="text-uppercase pl-2 pr-2 soundh">Sound Finance Matters</h4>
      </div>
      <div class="line2" style="min-width: 5rem; height: 2px; background: black"></div>
    </div>

    <div class="col-lg-12 d-flex justify-content-center">

      <small class="p-15 pt-3 pl-1 pr-1 text-center text-dark font-weight-bold " style="font-size: 14px !important">To know
        more, do not hesitate to get in touch by phone or email. Take a
        moment to fill out this form to get quick support for your queries.</small>
      </div>
      <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
        <div class="col-lg-12 d-flex flex-wrap pb-3">
          <div class="col-lg-6 col-md-6 col-12 pt-5">
            <div class="row">
              <div class="col">
                <input type="text" class="form-control" ngModel name="name" placeholder="Name*" required />
                <div *ngIf="contactForm.submitted && contactForm.controls.name?.errors">
                  <small class="text-danger" *ngIf="contactForm.controls.name?.errors.required">Name is required</small>
                </div>
              </div>
              <div class="col">
                <input type="email" class="form-control" ngModel name="email" placeholder="E-mail" required />
                <div *ngIf="contactForm.submitted && contactForm.controls.email?.errors">
                  <small class="text-danger" *ngIf="contactForm.controls.email?.errors.required">Email is required</small>
                  <small class="text-danger" *ngIf="contactForm.controls.email?.errors.email">Invalid email format</small>
                </div>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col">
                <input type="text" class="form-control" ngModel name="phoneNumber" placeholder="Phone*" required />
                <div *ngIf="contactForm.submitted && contactForm.controls.phoneNumber?.errors">
                  <small class="text-danger" *ngIf="contactForm.controls.phoneNumber?.errors.required">Phone number is required</small>
                </div>
              </div>
              <div class="col">
                <input type="text" class="form-control" ngModel name="subject" placeholder="Subject" required />
                <div *ngIf="contactForm.submitted && contactForm.controls.subject?.errors">
                  <small class="text-danger" *ngIf="contactForm.controls.subject?.errors.required">Subject is required</small>
                </div>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col">
                <label for="">Enquiry for Services*</label>
                <input type="text" class="form-control" ngModel name="enquiryService" required />
                <div *ngIf="contactForm.submitted && contactForm.controls.enquiryService?.errors">
                  <small class="text-danger" *ngIf="contactForm.controls.enquiryService?.errors.required">Enquiry service is required</small>
                </div>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="termsCheckbox" ngModel name="acceptTerms" required />
                  <label class="form-check-label" for="termsCheckbox">
                    I accept the <a routerLink="/privacy-policy">terms and conditions</a>
                  </label>
                  <div *ngIf="contactForm.submitted && contactForm.controls.acceptTerms?.errors">
                    <small class="text-danger" *ngIf="contactForm.controls.acceptTerms?.errors.required">You must accept the terms</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-4" id="button">
              <div class="col">
                <button class="btn form-control text-white" style="background: #418052">
                  Send
                </button>
              </div>
            </div>
            <div class="alert alert-success mt-3" role="alert" *ngIf="resultTrue">
              Thank You for reaching out. We will get back to you shortly.
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12 pt-5" id="msg-box">
            <div class="row">
              <div class="col">
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="10" ngModel name="message" placeholder="Message" required></textarea>
                <div *ngIf="contactForm.submitted && contactForm.controls.message?.errors">
                  <small class="text-danger" *ngIf="contactForm.controls.message?.errors.required">Message is required</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      
  </div></div>
</div>
<!-- USA end -->
