
<!-- dubai started -->
<section
  *ngIf="selectCountryName == 'Dubai'"
  class="min-vh-100 mt-130 sectionm"
>
  <div class="col p-0 m-0">
    <div
      class="d-flex justify-content-center align-items-center banner-image"
      style="
        background: url('../../../../assets/images/career/CiB-Collage.jpg')
          no-repeat center;
        background-size: cover;
        height: 430px;
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase">careers</h3>
        <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
      </div>
    </div>
    <div
      class="d-flex flex-column justify-content-center align-items-center"
      style="background: #f0f5f5"
    >
      <div
        class="p-5 d-flex flex-column justify-content-center align-items-center"
      >
        <P class="text-center text-dark careerPageText" style="font-size: 16px"
          >Boston Financial Advisory Group is committed to providing its
          employees with a secure, challenging and rewarding work environment.
          We stand by principles like honesty, integrity, attention to detail
          and exemplary service levels and would expect our employees to adhere
          by the same philosophy.</P
        >
        <P class="text-center text-dark careerPageText" style="font-size: 16px"
          >We are growing quickly and we need dedicated employee who can
          contribute to the success of our clients.</P
        >
        <div class="d-flex justify-content-center align-items-center mt-15">
          <div
            class="line1"
            style="min-width: 5rem; height: 2px; background: black"
          ></div>
          <div>
            <h4
              class="text-uppercase pl-2 pr-2"
              style="color: #418052; text-align: center"
            >
              Join the team
            </h4>
          </div>
          <div
            class="line2"
            style="min-width: 5rem; height: 2px; background: black"
          ></div>
        </div>
      </div>
    </div>
    <div class="container-fluid pt-4">
      <div class="row">
        <div
          class="col-lg-12 d-flex justify-content-center ml mb-30"
          [class.flex-column-reverse]="isMobileView == true"
        >
          <div
            id="career-info"
            class="col-lg mr-5 ml-5 shadow-lg custom-div"
            style="
              overflow: hidden;
              border-radius: 17px;
              padding-left: 15px;
              padding-right: 15px;
            "
          >
            <div class="tab-content" id="tab-content">
              <div class="tab-pane show active fadeIn" id="v-pills-job-0">
                <form
                  class="pt-30 pb-30 pl-30 pr-30"
                  [formGroup]="inqForm"
                  (ngSubmit)="onSubmit()"
                >
                  <div class="form-row pb-2" style="gap: 2rem">
                    <!-- <div class="form-group col-md-5">
                    <label>Position available *</label>
                    <input type="text" class="form-control" id="inputText" placeholder="Position available" formControlName="position" required/>
                    <div *ngIf="submitted && f.position.errors" class="text-danger small" [ngClass]="{
                  'is-invalid': submitted && f.position.errors
                }">
                      <div *ngIf="f.position.errors?.required">Positions available is required</div>
                    </div>
                  </div> -->
                    <div class="form-group col-md-5">
                      <label for="inputPassword4">Full Name *</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputName"
                        placeholder="Full Name"
                        formControlName="name"
                        required
                      />
                      <div
                        *ngIf="submitted && f.name.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.name.errors
                        }"
                      >
                        <div *ngIf="f.name.errors?.required">
                          Name is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Email *</label>
                      <input
                        type="email"
                        class="form-control"
                        id="inputText"
                        placeholder="Email"
                        formControlName="email_id"
                        required
                      />
                      <div
                        *ngIf="submitted && f.email_id.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.email_id.errors
                        }"
                      >
                        <div *ngIf="f.email_id.errors?.required">
                          Email is required
                        </div>
                        <div *ngIf="f.email_id.errors?.email">
                          Please enter valid email
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <div class="form-group col-md-5">
                      <label>Your Phone *</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="mobile"
                        required
                      />
                      <div
                        *ngIf="submitted && f.mobile.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.mobile.errors
                        }"
                      >
                        <div *ngIf="f.mobile.errors?.required">
                          Number is required
                        </div>
                        <div *ngIf="f.mobile.errors?.pattern">
                          Number is invalid
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Address *</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="current_location"
                        required
                      />
                      <div
                        *ngIf="submitted && f.current_location.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.current_location.errors
                        }"
                      >
                        <div *ngIf="f.current_location.errors?.required">
                          Address is required
                        </div>
                      </div>
                      <!-- <label>Gender*</label>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gender" id="inlineRadio2"
                        value="Male" formControlName="gender"/>
                      <label class="form-check-label" for="inlineRadio2">Male</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gender" id="inlineRadio1"
                        value="Female" formControlName="gender"/>
                      <label class="form-check-label" for="inlineRadio1">Female</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gender" id="inlineRadio1"
                        value="Other" formControlName="gender"/>
                      <label class="form-check-label" for="inlineRadio3">Other</label>
                    </div>
                    <div *ngIf="submitted && f.gender.errors" class="text-danger small" [ngClass]="{
                      'is-invalid': submitted && f.gender.errors
                    }">
                          <div *ngIf="f.gender.errors?.required">Gender is required</div>
                    </div> -->
                    </div>
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <!-- <div class="form-group col-md-5">
                    <label>Marital status *</label>
                    <input type="text" class="form-control" formControlName="marital_status" required />
                    <div *ngIf="submitted && f.marital_status.errors" class="text-danger small" [ngClass]="{
                  'is-invalid': submitted && f.marital_status.errors
                }">
                      <div *ngIf="f.marital_status.errors?.required">
                        Marital status is required
                      </div>
                    </div>
                  </div> -->
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <div class="form-group col-md-5">
                      <label>Total Work Experience *</label>
                      <!-- <input type="text" class="form-control" formControlName="yoexp" required /> -->
                      <div class="custom-dropdown" style="width: 100%">
                        <div
                          formControlName="yoexp"
                          class="selected-option"
                          (click)="toggleDropdownYoExp()"
                        >
                          {{ selected_yoexp || "Select an option" }}
                        </div>
                        <div
                          class="dropdown-options"
                          [hidden]="!dropdownOpenYoExp"
                        >
                          <div
                            *ngFor="let option of optionsYoExp"
                            (click)="selectOptionYoExp(option)"
                          >
                            {{ option }}
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="submitted && f.yoexp.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.yoexp.errors
                        }"
                      >
                        <div *ngIf="f.yoexp.errors?.required">
                          Experience is required
                        </div>
                      </div>
                      <!-- <label>Date of birth *</label> -->
                      <!-- <input type="date" class="form-control" id="inputText" formControlName="dob" required />
                    <div *ngIf="submitted && f.dob.errors" class="text-danger small" [ngClass]="{
                  'is-invalid': submitted && f.dob.errors
                }">
                      <div *ngIf="f.dob.errors?.required">Date of birth is required</div>
                    </div> -->
                    </div>
                    <div class="form-group col-md-5">
                      <label for="inputPassword4">Highest Qualification *</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputName"
                        placeholder="Highest Qualification"
                        formControlName="highest_qualification"
                        required
                      />
                      <div
                        *ngIf="submitted && f.highest_qualification.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.highest_qualification.errors
                        }"
                      >
                        <div *ngIf="f.highest_qualification.errors?.required">
                          Qualification is required
                        </div>
                      </div>
                    </div>

                    <!-- <div class="form-row pb-2" style="gap: 2rem"> -->
                    <!-- <div class="form-group col-md-5">
                    <label>Nationality *</label>
                    <input type="text" class="form-control" id="inputText" formControlName="nationality" required />
                    <div *ngIf="submitted && f.nationality.errors" class="text-danger small" [ngClass]="{
                'is-invalid': submitted && f.nationality.errors
              }">
                      <div *ngIf="f.nationality.errors?.required">Nationality is required</div>
                    </div>
                  </div>
                  <div class="form-group col-md-5">

                  </div> -->
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">

                    <div class="form-group col-md-5">
                      <label for="visa_status">Visa Status</label>
                      <div class="custom-dropdown" style="width: 100%">
                        <div
                          formControlName="visa_status"
                          class="selected-option"
                          (click)="toggleDropdown()"
                        >
                          {{ selected_visa || "Select an option" }}
                        </div>
                        <div class="dropdown-options" [hidden]="!dropdownOpen">
                          <div
                            *ngFor="let option of optionsUAE"
                            (click)="selectOption(option)"
                          >
                            {{ option }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-5">
                      <label>Expires On </label>
                      <input
                        type="date"
                        class="form-control"
                        formControlName="expires_on"
                      />
                    </div>



                    <!-- <input type="text" class="form-control" id="inputText" formControlName="visa_status" /> -->
                    <!-- <div *ngIf="submitted && f.visa_status.errors" class="text-danger small" [ngClass]="{
                'is-invalid': submitted && f.visa_status.errors
              }">
                      <div *ngIf="f.visa_status.errors?.required">Visa Status is required</div>
                    </div> -->
                    <!-- </div> -->
                    <!-- <div class="form-group col-md-5"> -->

                    <!-- <div *ngIf="submitted && f.expires_on.errors" class="text-danger small" [ngClass]="{
                'is-invalid': submitted && f.expires_on.errors
              }">
                      <div *ngIf="f.expires_on.errors?.required">Experience is required</div>
                    </div> -->
                    <!-- </div> -->
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <!-- <div class="form-group col-md-5"> -->
                      <!-- <label>Current Company </label> -->
                      <!-- <input type="text" class="form-control" id="inputText" formControlName="company_name" /> -->
                      <!-- <div *ngIf="submitted && f.company_name.errors" class="text-danger small" [ngClass]="{
                'is-invalid': submitted && f.company_name.errors
              }">
                      <div *ngIf="f.company_name.errors?.required">Current company is required</div>
                    </div> -->
                    <!-- </div> -->
                    <!-- <div class="form-group col-md-5"> -->
                      <!-- <input type="text" class="form-control" formControlName="notice_period"  /> -->
                      <!-- <div *ngIf="submitted && f.notice_period.errors" class="text-danger small" [ngClass]="{
                      'is-invalid': submitted && f.notice_period.errors
                    }">
                            <div *ngIf="f.notice_period.errors?.required">Notice Period is required</div>
                          </div> -->
                    <!-- </div> -->
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <!-- <div class="form-group col-md-5">
                    <label>Previous Salary (AED/INR/$) </label>
                    <input type="text" class="form-control" id="inputText" formControlName="previous_salary"/>
                  </div> -->
                  <div class="form-group col-md-5">
                    <label>Notice Period </label>
                    <div class="custom-dropdown" style="width: 100%">
                      <div
                        formControlName="notice_period"
                        class="selected-option"
                        (click)="toggleDropdownNoticePeriod()"
                      >
                        {{ selected_noticePeriod || "Select an option" }}
                      </div>
                      <div
                        class="dropdown-options"
                        [hidden]="!dropdownOpennoticePeriod"
                      >
                        <div
                          *ngFor="let option of optionsNoticePeriod"
                          (click)="selectOptionNoticePeriod(option)"
                        >
                          {{ option }}
                        </div>
                      </div>
                    </div>
                  </div>
                    <div class="form-group col-md-5">
                      <label>Expected Salary AED</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="expected_salary"
                      />
                    </div>

                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <div class="form-group col-md-5">
                      <label for="exampleFormControlFile1"
                        >Attached Resume *</label
                      >
                      <input
                        (change)="onFileUpload($event)"
                        name="upload_cv"
                        type="file"
                        class="form-control-file"
                        id="exampleFormControlFile1"
                        formControlName="upload_cv"
                        required
                      />
                      <small>Accepted file formats : pdf,doc,docx</small>
                      <div
                        *ngIf="submitted && f.upload_cv.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.upload_cv.errors
                        }"
                      >
                        <div *ngIf="f.upload_cv.errors?.required">
                          Invalid File Format. Accepted file formats :
                          pdf,doc,docx
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Reason to join BFAG</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="reason_to_join"
                      />
                    </div>

                    <!-- <div class="form-group col-md-5">
                    <label>Reason to join BFAG</label>
                    <input type="text" class="form-control" formControlName="reason_to_join"/>
                  </div> -->
                  </div>

                  <div class="form-row" style="gap: 1rem">
                    <div class="input-box mt-20">
                      <input
                        type="text"
                        placeholder="Enter Captcha*"
                        formControlName="recaptchaReactive"
                      />
                      <span class="badge sync" (click)="reCaptcha()">
                        <i class="fal fa-sync"></i
                      ></span>
                    </div>
                    <div class="col-lg-3 mt-1">
                      <div id="captcha"></div>
                    </div>
                  </div>

                  <div
                    *ngIf="submitted && f.recaptchaReactive.errors"
                    class="text-danger small"
                    [ngClass]="{
                      'is-invalid': submitted && f.recaptchaReactive.errors
                    }"
                  >
                    <div
                      *ngIf="f.recaptchaReactive.errors?.required"
                      class="pl-3"
                    >
                      Captcha is required
                    </div>
                  </div>
                  <div
                    class="form-group text-center"
                    style="margin-top: 2.5rem"
                  >
                    <button
                      type="submit"
                      class="btn btn-primary border-0"
                      style="background: #418052 !important"
                    >
                      Submit
                    </button>
                  </div>
                  <div
                    class="alert alert-success mt-3"
                    role="alert"
                    *ngIf="resultTrue"
                  >
                    Thank You for reaching out . We would get back to you
                    shortly.
                  </div>
                  <div
                    class="alert alert-danger mt-3"
                    role="alert"
                    *ngIf="resultFalse"
                  >
                    Something went wrong please try again
                  </div>
                </form>
              </div>
              
                <div class="tab-pane fadeIn" id="v-pills-job-1">
                  <ng-container *ngIf="selectedJob">
                  <div class="pt-30 pb-30 pl-30 pr-30">
                    <h4 class="mt-2" style="color: #418052">
                      {{selectedJob.title}}
                    </h4>
                    <div class="mt-50 mb-50">
                      <h6 class="mt-20" style="color: #418052">Requirements:</h6>
                      <div class="mt-40">
                        <ng-container *ngFor="let data of selectedJob.requirements; let i=index">
                          <p class="card-text" style="color: #418052a1">
                           {{i+1}}. {{data}}
                          </p>
                        </ng-container>
                      </div>
                    </div>
  
                    <div class="mt-50 mb-50">
                      <h6 class="mt-20" style="color: #418052">Qualification</h6>
                      <div class="mt-40">
                        <ng-container *ngFor="let data of selectedJob.qualification">
                          <p class="card-text" style="color: #418052a1">
                            {{data}}
                          </p>
                        </ng-container>
                      </div>
                    </div>
  
                    <div class="mt-50 mb-50">
                      <h6 class="mt-20" style="color: #418052">
                        Work timing & location
                      </h6>
                      <div class="mt-40">
                        <p class="card-text" style="color: #418052a1">
                          {{selectedJob.timingAndLocation}}
                        </p>
                        <!-- <p class="card-text" style="color: #418052a1">
                          Work timing is {{selectedJob.timingAndLocation}} & should be
                          flexible enough to stretch the timing if required.
                        </p> -->
                      </div>
                    </div>
                    <div class="text-center">
                      <button
                        (click)="goToForm(selectedJob)"
                        class="btn btn-primary border-0"
                        style="background: #418052"
                        role="tab"
                        data-toggle="pill"
                        href="#v-pills-home"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </ng-container>
                </div>
              
              
            </div>
          </div>
          <div
            class="col-lg shadow-lg custom-div"
            style="background-color: white; border-radius: 17px"
          >
            <div
            id="career-list"
              class="career-list mt-20 pb-20"
              style="overflow-y: scroll; "
            >
            <ng-container *ngFor="let job of jobList">
              <div
                class="card nav flex-column nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <div class="card-body d-flex align-items-center">
                  <div class="container">
                    <div class="row">
                      <p class="card-title mr-auto" style="color: #418052">
                        {{job.createdAt | date: 'yyyy/MM/dd'}}
                      </p>
                    </div>
                    <div class="row">
                      <h4 style="color: #418052"  >{{job.title}}</h4>
                    </div>
                  </div>


                  <img
                    src="../../../../assets/images/BFAG_logo.png"
                    style="width: 20%"
                    class="card-img-top"
                    alt="Image"
                  />
                </div>
                <div class="m-3" style="margin-top:0 !important">
                  <p class="card-text" style="color: #418052a1">
                    <i
                      class="mr-1 fa fa-graduation-cap text-dark fa-1x"
                      aria-hidden="true"
                      style="color: black"
                    ></i>
                    {{job.qualification[0]}}
                  </p>
                  <p class="card-text mt-10" style="color: #418052a1">
                    <i
                      class="mr-1 fa fa-briefcase text-dark fa-1x"
                      aria-hidden="true"
                      style="color: black"
                    ></i>
                    Work Timing & Location - {{job.timingAndLocation}}
                  </p>
                  <!-- <p class="card-text mt-10" style="color: #418052a1">
                    <i
                      class="mr-1 fal fa-map-marker text-dark fa-1x"
                      style="color: black"
                    ></i>
                    Work Location - {{job.timingAndLocation}}
                  </p> -->
                  <button
                    (click)="scrollToDiv(job)"
                    class="btn btn-primary float-right nav-link border-0"
                    style="background: #418052"
                  >
                    View Job
                  </button>
                </div>
              </div>
            </ng-container>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- dubai end -->

<!-- India starts -->

<section
  *ngIf="selectCountryName == 'India'"
  class="min-vh-100 mt-130 sectionm"
>
  <div class="col p-0 m-0">
    <div
      class="d-flex justify-content-center align-items-center banner-image"
      style="
        background: url('../../../../assets/images/career/CiB-Collage.jpg')
          no-repeat center;
        background-size: cover;
        height: 430px;
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase">careers</h3>
        <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
      </div>
    </div>
    <div
      class="d-flex flex-column justify-content-center align-items-center"
      style="background: #f0f5f5"
    >
      <div
        class="p-5 d-flex flex-column justify-content-center align-items-center"
      >
        <P class="text-center text-dark careerPageText" style="font-size: 16px"
          >Boston Financial Advisory Group is committed to providing its
          employees with a secure, challenging and rewarding work environment.
          We stand by principles like honesty, integrity, attention to detail
          and exemplary service levels and would expect our employees to adhere
          by the same philosophy.</P
        >
        <P class="text-center text-dark careerPageText" style="font-size: 16px"
          >We are growing quickly and we need dedicated employee who can
          contribute to the success of our clients.</P
        >
        <div class="d-flex justify-content-center align-items-center mt-15">
          <div
            class="line1"
            style="min-width: 5rem; height: 2px; background: black"
          ></div>
          <div>
            <h4
              class="text-uppercase pl-2 pr-2"
              style="color: #072f5f; text-align: center"
            >
              Join the team
            </h4>
          </div>
          <div
            class="line2"
            style="min-width: 5rem; height: 2px; background: black"
          ></div>
        </div>
      </div>
    </div>
    <div class="container-fluid pt-4">
      <div class="row">
        <div
          class="col-lg-12 d-flex justify-content-center ml mb-30"
          [class.flex-column-reverse]="isMobileView == true"
        >
          <div
            id="career-info"
            class="col-lg mr-5 ml-5 shadow-lg custom-div"
            style="
              overflow: hidden;
              border-radius: 17px;
              padding-left: 15px;
              padding-right: 15px;
            "
          >
            <div class="tab-content" id="tab-content">
              <div class="tab-pane show active fadeIn" id="v-pills-job-0">
                <form
                  class="pt-30 pb-30 pl-30 pr-30"
                  [formGroup]="inqForm"
                  (ngSubmit)="onSubmit()"
                >
                  <div class="form-row pb-2" style="gap: 2rem">
                    <!-- <div class="form-group col-md-5">
                    <label>Position available *</label>
                    <input type="text" class="form-control" id="inputText" placeholder="Position available" formControlName="position" required/>
                    <div *ngIf="submitted && f.position.errors" class="text-danger small" [ngClass]="{
                  'is-invalid': submitted && f.position.errors
                }">
                      <div *ngIf="f.position.errors?.required">Positions available is required</div>
                    </div>
                  </div> -->
                    <div class="form-group col-md-5">
                      <label for="inputPassword4">Full Name *</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputName"
                        placeholder="Full Name"
                        formControlName="name"
                        required
                      />
                      <div
                        *ngIf="submitted && f.name.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.name.errors
                        }"
                      >
                        <div *ngIf="f.name.errors?.required">
                          Name is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Email *</label>
                      <input
                        type="email"
                        class="form-control"
                        id="inputText"
                        placeholder="Email"
                        formControlName="email_id"
                        required
                      />
                      <div
                        *ngIf="submitted && f.email_id.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.email_id.errors
                        }"
                      >
                        <div *ngIf="f.email_id.errors?.required">
                          Email is required
                        </div>
                        <div *ngIf="f.email_id.errors?.email">
                          Please enter valid email
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <div class="form-group col-md-5">
                      <label>Your Phone *</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="mobile"
                        required
                      />
                      <div
                        *ngIf="submitted && f.mobile.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.mobile.errors
                        }"
                      >
                        <div *ngIf="f.mobile.errors?.required">
                          Number is required
                        </div>
                        <div *ngIf="f.mobile.errors?.pattern">
                          Number is invalid
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Address *</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="current_location"
                        required
                      />
                      <div
                        *ngIf="submitted && f.current_location.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.current_location.errors
                        }"
                      >
                        <div *ngIf="f.current_location.errors?.required">
                          Address is required
                        </div>
                      </div>
                      <!-- <label>Gender*</label>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gender" id="inlineRadio2"
                        value="Male" formControlName="gender"/>
                      <label class="form-check-label" for="inlineRadio2">Male</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gender" id="inlineRadio1"
                        value="Female" formControlName="gender"/>
                      <label class="form-check-label" for="inlineRadio1">Female</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gender" id="inlineRadio1"
                        value="Other" formControlName="gender"/>
                      <label class="form-check-label" for="inlineRadio3">Other</label>
                    </div>
                    <div *ngIf="submitted && f.gender.errors" class="text-danger small" [ngClass]="{
                      'is-invalid': submitted && f.gender.errors
                    }">
                          <div *ngIf="f.gender.errors?.required">Gender is required</div>
                    </div> -->
                    </div>
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <!-- <div class="form-group col-md-5">
                    <label>Marital status *</label>
                    <input type="text" class="form-control" formControlName="marital_status" required />
                    <div *ngIf="submitted && f.marital_status.errors" class="text-danger small" [ngClass]="{
                  'is-invalid': submitted && f.marital_status.errors
                }">
                      <div *ngIf="f.marital_status.errors?.required">
                        Marital status is required
                      </div>
                    </div>
                  </div> -->
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <div class="form-group col-md-5">
                      <label>Total Work Experience *</label>
                      <!-- <input type="text" class="form-control" formControlName="yoexp" required /> -->
                      <div class="custom-dropdown" style="width: 100%">
                        <div
                          formControlName="yoexp"
                          class="selected-option"
                          (click)="toggleDropdownYoExp()"
                        >
                          {{ selected_yoexp || "Select an option" }}
                        </div>
                        <div
                          class="dropdown-options"
                          [hidden]="!dropdownOpenYoExp"
                        >
                          <div
                            *ngFor="let option of optionsYoExp"
                            (click)="selectOptionYoExp(option)"
                          >
                            {{ option }}
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="submitted && f.yoexp.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.yoexp.errors
                        }"
                      >
                        <div *ngIf="f.yoexp.errors?.required">
                          Experience is required
                        </div>
                      </div>
                      <!-- <label>Date of birth *</label> -->
                      <!-- <input type="date" class="form-control" id="inputText" formControlName="dob" required />
                    <div *ngIf="submitted && f.dob.errors" class="text-danger small" [ngClass]="{
                  'is-invalid': submitted && f.dob.errors
                }">
                      <div *ngIf="f.dob.errors?.required">Date of birth is required</div>
                    </div> -->
                    </div>
                    <div class="form-group col-md-5">
                      <label for="inputPassword4">Highest Qualification *</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputName"
                        placeholder="Highest Qualification"
                        formControlName="highest_qualification"
                        required
                      />
                      <div
                        *ngIf="submitted && f.highest_qualification.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.highest_qualification.errors
                        }"
                      >
                        <div *ngIf="f.highest_qualification.errors?.required">
                          Qualification is required
                        </div>
                      </div>
                    </div>

                    <!-- <div class="form-row pb-2" style="gap: 2rem"> -->
                    <!-- <div class="form-group col-md-5">
                    <label>Nationality *</label>
                    <input type="text" class="form-control" id="inputText" formControlName="nationality" required />
                    <div *ngIf="submitted && f.nationality.errors" class="text-danger small" [ngClass]="{
                'is-invalid': submitted && f.nationality.errors
              }">
                      <div *ngIf="f.nationality.errors?.required">Nationality is required</div>
                    </div>
                  </div>
                  <div class="form-group col-md-5">

                  </div> -->
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">

                    <div class="form-group col-md-5">
                      <label for="visa_status">Visa Status</label>
                      <div class="custom-dropdown" style="width: 100%">
                        <div
                          formControlName="visa_status"
                          class="selected-option"
                          (click)="toggleDropdown()"
                        >
                          {{ selected_visa || "Select an option" }}
                        </div>
                        <div class="dropdown-options" [hidden]="!dropdownOpen">
                          <div
                            *ngFor="let option of optionsUAE"
                            (click)="selectOption(option)"
                          >
                            {{ option }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-5">
                      <label>Expires On </label>
                      <input
                        type="date"
                        class="form-control"
                        formControlName="expires_on"
                      />
                    </div>



                    <!-- <input type="text" class="form-control" id="inputText" formControlName="visa_status" /> -->
                    <!-- <div *ngIf="submitted && f.visa_status.errors" class="text-danger small" [ngClass]="{
                'is-invalid': submitted && f.visa_status.errors
              }">
                      <div *ngIf="f.visa_status.errors?.required">Visa Status is required</div>
                    </div> -->
                    <!-- </div> -->
                    <!-- <div class="form-group col-md-5"> -->

                    <!-- <div *ngIf="submitted && f.expires_on.errors" class="text-danger small" [ngClass]="{
                'is-invalid': submitted && f.expires_on.errors
              }">
                      <div *ngIf="f.expires_on.errors?.required">Experience is required</div>
                    </div> -->
                    <!-- </div> -->
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <!-- <div class="form-group col-md-5"> -->
                      <!-- <label>Current Company </label> -->
                      <!-- <input type="text" class="form-control" id="inputText" formControlName="company_name" /> -->
                      <!-- <div *ngIf="submitted && f.company_name.errors" class="text-danger small" [ngClass]="{
                'is-invalid': submitted && f.company_name.errors
              }">
                      <div *ngIf="f.company_name.errors?.required">Current company is required</div>
                    </div> -->
                    <!-- </div> -->
                    <!-- <div class="form-group col-md-5"> -->
                      <!-- <input type="text" class="form-control" formControlName="notice_period"  /> -->
                      <!-- <div *ngIf="submitted && f.notice_period.errors" class="text-danger small" [ngClass]="{
                      'is-invalid': submitted && f.notice_period.errors
                    }">
                            <div *ngIf="f.notice_period.errors?.required">Notice Period is required</div>
                          </div> -->
                    <!-- </div> -->
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <!-- <div class="form-group col-md-5">
                    <label>Previous Salary (AED/INR/$) </label>
                    <input type="text" class="form-control" id="inputText" formControlName="previous_salary"/>
                  </div> -->
                  <div class="form-group col-md-5">
                    <label>Notice Period </label>
                    <div class="custom-dropdown" style="width: 100%">
                      <div
                        formControlName="notice_period"
                        class="selected-option"
                        (click)="toggleDropdownNoticePeriod()"
                      >
                        {{ selected_noticePeriod || "Select an option" }}
                      </div>
                      <div
                        class="dropdown-options"
                        [hidden]="!dropdownOpennoticePeriod"
                      >
                        <div
                          *ngFor="let option of optionsNoticePeriod"
                          (click)="selectOptionNoticePeriod(option)"
                        >
                          {{ option }}
                        </div>
                      </div>
                    </div>
                  </div>
                    <div class="form-group col-md-5">
                      <label>Expected Salary AED</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="expected_salary"
                      />
                    </div>

                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <div class="form-group col-md-5">
                      <label for="exampleFormControlFile1"
                        >Attached Resume *</label
                      >
                      <input
                        (change)="onFileUpload($event)"
                        name="upload_cv"
                        type="file"
                        class="form-control-file"
                        id="exampleFormControlFile1"
                        formControlName="upload_cv"
                        required
                      />
                      <small>Accepted file formats : pdf,doc,docx</small>
                      <div
                        *ngIf="submitted && f.upload_cv.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.upload_cv.errors
                        }"
                      >
                        <div *ngIf="f.upload_cv.errors?.required">
                          Invalid File Format. Accepted file formats :
                          pdf,doc,docx
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Reason to join BFAG</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="reason_to_join"
                      />
                    </div>

                    <!-- <div class="form-group col-md-5">
                    <label>Reason to join BFAG</label>
                    <input type="text" class="form-control" formControlName="reason_to_join"/>
                  </div> -->
                  </div>

                  <div class="form-row" style="gap: 1rem">
                    <div class="input-box mt-20">
                      <input
                        type="text"
                        placeholder="Enter Captcha*"
                        formControlName="recaptchaReactive"
                      />
                      <span class="badge sync" (click)="reCaptcha()">
                        <i class="fal fa-sync"></i
                      ></span>
                    </div>
                    <div class="col-lg-3 mt-1">
                      <div id="captcha"></div>
                    </div>
                  </div>

                  <div
                    *ngIf="submitted && f.recaptchaReactive.errors"
                    class="text-danger small"
                    [ngClass]="{
                      'is-invalid': submitted && f.recaptchaReactive.errors
                    }"
                  >
                    <div
                      *ngIf="f.recaptchaReactive.errors?.required"
                      class="pl-3"
                    >
                      Captcha is required
                    </div>
                  </div>
                  <div
                    class="form-group text-center"
                    style="margin-top: 2.5rem"
                  >
                    <button
                      type="submit"
                      class="btn btn-primary border-0"
                      style="background: #072f5f !important"
                    >
                      Submit
                    </button>
                  </div>
                  <div
                    class="alert alert-success mt-3"
                    role="alert"
                    *ngIf="resultTrue"
                  >
                    Thank You for reaching out . We would get back to you
                    shortly.
                  </div>
                  <div
                    class="alert alert-danger mt-3"
                    role="alert"
                    *ngIf="resultFalse"
                  >
                    Something went wrong please try again
                  </div>
                </form>
              </div>
              
                <div class="tab-pane fadeIn" id="v-pills-job-1">
                  <ng-container *ngIf="selectedJob">
                  <div class="pt-30 pb-30 pl-30 pr-30">
                    <h4 class="mt-2" style="color: #072f5f">
                      {{selectedJob.title}}
                    </h4>
                    <div class="mt-50 mb-50">
                      <h6 class="mt-20" style="color: #072f5f">Requirements:</h6>
                      <div class="mt-40">
                        <ng-container *ngFor="let data of selectedJob.requirements; let i=index">
                          <p class="card-text" style="color: #072f5fa1">
                           {{i+1}}. {{data}}
                          </p>
                        </ng-container>
                      </div>
                    </div>
  
                    <div class="mt-50 mb-50">
                      <h6 class="mt-20" style="color: #072f5f">Qualification</h6>
                      <div class="mt-40">
                        <ng-container *ngFor="let data of selectedJob.qualification">
                          <p class="card-text" style="color: #072f5fa1">
                            {{data}}
                          </p>
                        </ng-container>
                      </div>
                    </div>
  
                    <div class="mt-50 mb-50">
                      <h6 class="mt-20" style="color: #072f5f">
                        Work timing & location
                      </h6>
                      <div class="mt-40">
                        <p class="card-text" style="color: #072f5fa1">
                          {{selectedJob.timingAndLocation}}
                        </p>
                        <!-- <p class="card-text" style="color: #072f5fa1">
                          Work timing is {{selectedJob.timingAndLocation}} & should be
                          flexible enough to stretch the timing if required.
                        </p> -->
                      </div>
                    </div>
                    <div class="text-center">
                      <button
                        (click)="goToForm(selectedJob)"
                        class="btn btn-primary border-0"
                        style="background: #072f5f"
                        role="tab"
                        data-toggle="pill"
                        href="#v-pills-home"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </ng-container>
                </div>
              
              
            </div>
          </div>
          <div
            class="col-lg shadow-lg custom-div"
            style="background-color: white; border-radius: 17px"
          >
            <div
            id="career-list"
              class="career-list mt-20 pb-20"
              style="overflow-y: scroll; "
            >
            <ng-container *ngFor="let job of jobList">
              <div
                class="card nav flex-column nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <div class="card-body d-flex align-items-center">
                  <div class="container">
                    <div class="row">
                      <p class="card-title mr-auto" style="color: #072f5f">
                        {{job.createdAt | date: 'yyyy/MM/dd'}}
                      </p>
                    </div>
                    <div class="row">
                      <h4 style="color: #072f5f"  >{{job.title}}</h4>
                    </div>
                  </div>


                  <img
                    src="../../../../assets/images/BFAG_logo.png"
                    style="width: 20%"
                    class="card-img-top"
                    alt="Image"
                  />
                </div>
                <div class="m-3" style="margin-top:0 !important">
                  <p class="card-text" style="color: #072f5fa1">
                    <i
                      class="mr-1 fa fa-graduation-cap text-dark fa-1x"
                      aria-hidden="true"
                      style="color: black"
                    ></i>
                    {{job.qualification[0]}}
                  </p>
                  <p class="card-text mt-10" style="color: #072f5fa1">
                    <i
                      class="mr-1 fa fa-briefcase text-dark fa-1x"
                      aria-hidden="true"
                      style="color: black"
                    ></i>
                    Work Timing & Location - {{job.timingAndLocation}}
                  </p>
                  <!-- <p class="card-text mt-10" style="color: #072f5fa1">
                    <i
                      class="mr-1 fal fa-map-marker text-dark fa-1x"
                      style="color: black"
                    ></i>
                    Work Location - {{job.timingAndLocation}}
                  </p> -->
                  <button
                    (click)="scrollToDiv(job)"
                    class="btn btn-primary float-right nav-link border-0"
                    style="background: #072f5f"
                  >
                    View Job
                  </button>
                </div>
              </div>
            </ng-container>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- India end -->

<!-- USA STARTS -->
<section
  *ngIf="selectCountryName == 'USA'"
  class="min-vh-100 mt-130 sectionm"
>
  <div class="col p-0 m-0">
    <div
      class="d-flex justify-content-center align-items-center banner-image"
      style="
        background: url('../../../../assets/images/career/CiB-Collage.jpg')
          no-repeat center;
        background-size: cover;
        height: 430px;
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase">careers</h3>
        <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
      </div>
    </div>
    <div
      class="d-flex flex-column justify-content-center align-items-center"
      style="background: #f0f5f5"
    >
      <div
        class="p-5 d-flex flex-column justify-content-center align-items-center"
      >
        <P class="text-center text-dark careerPageText" style="font-size: 16px"
          >Boston Financial Advisory Group is committed to providing its
          employees with a secure, challenging and rewarding work environment.
          We stand by principles like honesty, integrity, attention to detail
          and exemplary service levels and would expect our employees to adhere
          by the same philosophy.</P
        >
        <P class="text-center text-dark careerPageText" style="font-size: 16px"
          >We are growing quickly and we need dedicated employee who can
          contribute to the success of our clients.</P
        >
        <div class="d-flex justify-content-center align-items-center mt-15">
          <div
            class="line1"
            style="min-width: 5rem; height: 2px; background: black"
          ></div>
          <div>
            <h4
              class="text-uppercase pl-2 pr-2"
              style="color: #072f5f; text-align: center"
            >
              Join the team
            </h4>
          </div>
          <div
            class="line2"
            style="min-width: 5rem; height: 2px; background: black"
          ></div>
        </div>
      </div>
    </div>
    <div class="container-fluid pt-4">
      <div class="row">
        <div
          class="col-lg-12 d-flex justify-content-center ml mb-30"
          [class.flex-column-reverse]="isMobileView == true"
        >
          <div
            id="career-info"
            class="col-lg mr-5 ml-5 shadow-lg custom-div"
            style="
              overflow: hidden;
              border-radius: 17px;
              padding-left: 15px;
              padding-right: 15px;
            "
          >
            <div class="tab-content" id="tab-content">
              <div class="tab-pane show active fadeIn" id="v-pills-job-0">
                <form
                  class="pt-30 pb-30 pl-30 pr-30"
                  [formGroup]="inqForm"
                  (ngSubmit)="onSubmit()"
                >
                  <div class="form-row pb-2" style="gap: 2rem">
                    <!-- <div class="form-group col-md-5">
                    <label>Position available *</label>
                    <input type="text" class="form-control" id="inputText" placeholder="Position available" formControlName="position" required/>
                    <div *ngIf="submitted && f.position.errors" class="text-danger small" [ngClass]="{
                  'is-invalid': submitted && f.position.errors
                }">
                      <div *ngIf="f.position.errors?.required">Positions available is required</div>
                    </div>
                  </div> -->
                    <div class="form-group col-md-5">
                      <label for="inputPassword4">Full Name *</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputName"
                        placeholder="Full Name"
                        formControlName="name"
                        required
                      />
                      <div
                        *ngIf="submitted && f.name.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.name.errors
                        }"
                      >
                        <div *ngIf="f.name.errors?.required">
                          Name is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Email *</label>
                      <input
                        type="email"
                        class="form-control"
                        id="inputText"
                        placeholder="Email"
                        formControlName="email_id"
                        required
                      />
                      <div
                        *ngIf="submitted && f.email_id.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.email_id.errors
                        }"
                      >
                        <div *ngIf="f.email_id.errors?.required">
                          Email is required
                        </div>
                        <div *ngIf="f.email_id.errors?.email">
                          Please enter valid email
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <div class="form-group col-md-5">
                      <label>Your Phone *</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="mobile"
                        required
                      />
                      <div
                        *ngIf="submitted && f.mobile.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.mobile.errors
                        }"
                      >
                        <div *ngIf="f.mobile.errors?.required">
                          Number is required
                        </div>
                        <div *ngIf="f.mobile.errors?.pattern">
                          Number is invalid
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Address *</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="current_location"
                        required
                      />
                      <div
                        *ngIf="submitted && f.current_location.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.current_location.errors
                        }"
                      >
                        <div *ngIf="f.current_location.errors?.required">
                          Address is required
                        </div>
                      </div>
                      <!-- <label>Gender*</label>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gender" id="inlineRadio2"
                        value="Male" formControlName="gender"/>
                      <label class="form-check-label" for="inlineRadio2">Male</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gender" id="inlineRadio1"
                        value="Female" formControlName="gender"/>
                      <label class="form-check-label" for="inlineRadio1">Female</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gender" id="inlineRadio1"
                        value="Other" formControlName="gender"/>
                      <label class="form-check-label" for="inlineRadio3">Other</label>
                    </div>
                    <div *ngIf="submitted && f.gender.errors" class="text-danger small" [ngClass]="{
                      'is-invalid': submitted && f.gender.errors
                    }">
                          <div *ngIf="f.gender.errors?.required">Gender is required</div>
                    </div> -->
                    </div>
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <!-- <div class="form-group col-md-5">
                    <label>Marital status *</label>
                    <input type="text" class="form-control" formControlName="marital_status" required />
                    <div *ngIf="submitted && f.marital_status.errors" class="text-danger small" [ngClass]="{
                  'is-invalid': submitted && f.marital_status.errors
                }">
                      <div *ngIf="f.marital_status.errors?.required">
                        Marital status is required
                      </div>
                    </div>
                  </div> -->
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <div class="form-group col-md-5">
                      <label>Total Work Experience *</label>
                      <!-- <input type="text" class="form-control" formControlName="yoexp" required /> -->
                      <div class="custom-dropdown" style="width: 100%">
                        <div
                          formControlName="yoexp"
                          class="selected-option"
                          (click)="toggleDropdownYoExp()"
                        >
                          {{ selected_yoexp || "Select an option" }}
                        </div>
                        <div
                          class="dropdown-options"
                          [hidden]="!dropdownOpenYoExp"
                        >
                          <div
                            *ngFor="let option of optionsYoExp"
                            (click)="selectOptionYoExp(option)"
                          >
                            {{ option }}
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="submitted && f.yoexp.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.yoexp.errors
                        }"
                      >
                        <div *ngIf="f.yoexp.errors?.required">
                          Experience is required
                        </div>
                      </div>
                      <!-- <label>Date of birth *</label> -->
                      <!-- <input type="date" class="form-control" id="inputText" formControlName="dob" required />
                    <div *ngIf="submitted && f.dob.errors" class="text-danger small" [ngClass]="{
                  'is-invalid': submitted && f.dob.errors
                }">
                      <div *ngIf="f.dob.errors?.required">Date of birth is required</div>
                    </div> -->
                    </div>
                    <div class="form-group col-md-5">
                      <label for="inputPassword4">Highest Qualification *</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputName"
                        placeholder="Highest Qualification"
                        formControlName="highest_qualification"
                        required
                      />
                      <div
                        *ngIf="submitted && f.highest_qualification.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.highest_qualification.errors
                        }"
                      >
                        <div *ngIf="f.highest_qualification.errors?.required">
                          Qualification is required
                        </div>
                      </div>
                    </div>

                    <!-- <div class="form-row pb-2" style="gap: 2rem"> -->
                    <!-- <div class="form-group col-md-5">
                    <label>Nationality *</label>
                    <input type="text" class="form-control" id="inputText" formControlName="nationality" required />
                    <div *ngIf="submitted && f.nationality.errors" class="text-danger small" [ngClass]="{
                'is-invalid': submitted && f.nationality.errors
              }">
                      <div *ngIf="f.nationality.errors?.required">Nationality is required</div>
                    </div>
                  </div>
                  <div class="form-group col-md-5">

                  </div> -->
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">

                    <div class="form-group col-md-5">
                      <label for="visa_status">Visa Status</label>
                      <div class="custom-dropdown" style="width: 100%">
                        <div
                          formControlName="visa_status"
                          class="selected-option"
                          (click)="toggleDropdown()"
                        >
                          {{ selected_visa || "Select an option" }}
                        </div>
                        <div class="dropdown-options" [hidden]="!dropdownOpen">
                          <div
                            *ngFor="let option of optionsUAE"
                            (click)="selectOption(option)"
                          >
                            {{ option }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-5">
                      <label>Expires On </label>
                      <input
                        type="date"
                        class="form-control"
                        formControlName="expires_on"
                      />
                    </div>



                    <!-- <input type="text" class="form-control" id="inputText" formControlName="visa_status" /> -->
                    <!-- <div *ngIf="submitted && f.visa_status.errors" class="text-danger small" [ngClass]="{
                'is-invalid': submitted && f.visa_status.errors
              }">
                      <div *ngIf="f.visa_status.errors?.required">Visa Status is required</div>
                    </div> -->
                    <!-- </div> -->
                    <!-- <div class="form-group col-md-5"> -->

                    <!-- <div *ngIf="submitted && f.expires_on.errors" class="text-danger small" [ngClass]="{
                'is-invalid': submitted && f.expires_on.errors
              }">
                      <div *ngIf="f.expires_on.errors?.required">Experience is required</div>
                    </div> -->
                    <!-- </div> -->
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <!-- <div class="form-group col-md-5"> -->
                      <!-- <label>Current Company </label> -->
                      <!-- <input type="text" class="form-control" id="inputText" formControlName="company_name" /> -->
                      <!-- <div *ngIf="submitted && f.company_name.errors" class="text-danger small" [ngClass]="{
                'is-invalid': submitted && f.company_name.errors
              }">
                      <div *ngIf="f.company_name.errors?.required">Current company is required</div>
                    </div> -->
                    <!-- </div> -->
                    <!-- <div class="form-group col-md-5"> -->
                      <!-- <input type="text" class="form-control" formControlName="notice_period"  /> -->
                      <!-- <div *ngIf="submitted && f.notice_period.errors" class="text-danger small" [ngClass]="{
                      'is-invalid': submitted && f.notice_period.errors
                    }">
                            <div *ngIf="f.notice_period.errors?.required">Notice Period is required</div>
                          </div> -->
                    <!-- </div> -->
                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <!-- <div class="form-group col-md-5">
                    <label>Previous Salary (AED/INR/$) </label>
                    <input type="text" class="form-control" id="inputText" formControlName="previous_salary"/>
                  </div> -->
                  <div class="form-group col-md-5">
                    <label>Notice Period </label>
                    <div class="custom-dropdown" style="width: 100%">
                      <div
                        formControlName="notice_period"
                        class="selected-option"
                        (click)="toggleDropdownNoticePeriod()"
                      >
                        {{ selected_noticePeriod || "Select an option" }}
                      </div>
                      <div
                        class="dropdown-options"
                        [hidden]="!dropdownOpennoticePeriod"
                      >
                        <div
                          *ngFor="let option of optionsNoticePeriod"
                          (click)="selectOptionNoticePeriod(option)"
                        >
                          {{ option }}
                        </div>
                      </div>
                    </div>
                  </div>
                    <div class="form-group col-md-5">
                      <label>Expected Salary AED</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="expected_salary"
                      />
                    </div>

                  </div>
                  <div class="form-row pb-2" style="gap: 2rem">
                    <div class="form-group col-md-5">
                      <label for="exampleFormControlFile1"
                        >Attached Resume *</label
                      >
                      <input
                        (change)="onFileUpload($event)"
                        name="upload_cv"
                        type="file"
                        class="form-control-file"
                        id="exampleFormControlFile1"
                        formControlName="upload_cv"
                        required
                      />
                      <small>Accepted file formats : pdf,doc,docx</small>
                      <div
                        *ngIf="submitted && f.upload_cv.errors"
                        class="text-danger small"
                        [ngClass]="{
                          'is-invalid': submitted && f.upload_cv.errors
                        }"
                      >
                        <div *ngIf="f.upload_cv.errors?.required">
                          Invalid File Format. Accepted file formats :
                          pdf,doc,docx
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Reason to join BFAG</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="reason_to_join"
                      />
                    </div>

                    <!-- <div class="form-group col-md-5">
                    <label>Reason to join BFAG</label>
                    <input type="text" class="form-control" formControlName="reason_to_join"/>
                  </div> -->
                  </div>

                  <div class="form-row" style="gap: 1rem">
                    <div class="input-box mt-20">
                      <input
                        type="text"
                        placeholder="Enter Captcha*"
                        formControlName="recaptchaReactive"
                      />
                      <span class="badge sync" (click)="reCaptcha()">
                        <i class="fal fa-sync"></i
                      ></span>
                    </div>
                    <div class="col-lg-3 mt-1">
                      <div id="captcha"></div>
                    </div>
                  </div>

                  <div
                    *ngIf="submitted && f.recaptchaReactive.errors"
                    class="text-danger small"
                    [ngClass]="{
                      'is-invalid': submitted && f.recaptchaReactive.errors
                    }"
                  >
                    <div
                      *ngIf="f.recaptchaReactive.errors?.required"
                      class="pl-3"
                    >
                      Captcha is required
                    </div>
                  </div>
                  <div
                    class="form-group text-center"
                    style="margin-top: 2.5rem"
                  >
                    <button
                      type="submit"
                      class="btn btn-primary border-0"
                      style="background: #072f5f !important"
                    >
                      Submit
                    </button>
                  </div>
                  <div
                    class="alert alert-success mt-3"
                    role="alert"
                    *ngIf="resultTrue"
                  >
                    Thank You for reaching out . We would get back to you
                    shortly.
                  </div>
                  <div
                    class="alert alert-danger mt-3"
                    role="alert"
                    *ngIf="resultFalse"
                  >
                    Something went wrong please try again
                  </div>
                </form>
              </div>
              
                <div class="tab-pane fadeIn" id="v-pills-job-1">
                  <ng-container *ngIf="selectedJob">
                  <div class="pt-30 pb-30 pl-30 pr-30">
                    <h4 class="mt-2" style="color: #072f5f">
                      {{selectedJob.title}}
                    </h4>
                    <div class="mt-50 mb-50">
                      <h6 class="mt-20" style="color: #072f5f">Requirements:</h6>
                      <div class="mt-40">
                        <ng-container *ngFor="let data of selectedJob.requirements; let i=index">
                          <p class="card-text" style="color: #072f5fa1">
                           {{i+1}}. {{data}}
                          </p>
                        </ng-container>
                      </div>
                    </div>
  
                    <div class="mt-50 mb-50">
                      <h6 class="mt-20" style="color: #072f5f">Qualification</h6>
                      <div class="mt-40">
                        <ng-container *ngFor="let data of selectedJob.qualification">
                          <p class="card-text" style="color: #072f5fa1">
                            {{data}}
                          </p>
                        </ng-container>
                      </div>
                    </div>
  
                    <div class="mt-50 mb-50">
                      <h6 class="mt-20" style="color: #072f5f">
                        Work timing & location
                      </h6>
                      <div class="mt-40">
                        <p class="card-text" style="color: #072f5fa1">
                          {{selectedJob.timingAndLocation}}
                        </p>
                        <!-- <p class="card-text" style="color: #072f5fa1">
                          Work timing is {{selectedJob.timingAndLocation}} & should be
                          flexible enough to stretch the timing if required.
                        </p> -->
                      </div>
                    </div>
                    <div class="text-center">
                      <button
                        (click)="goToForm(selectedJob)"
                        class="btn btn-primary border-0"
                        style="background: #072f5f"
                        role="tab"
                        data-toggle="pill"
                        href="#v-pills-home"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </ng-container>
                </div>
              
              
            </div>
          </div>
          <div
            class="col-lg shadow-lg custom-div"
            style="background-color: white; border-radius: 17px"
          >
            <div
            id="career-list"
              class="career-list mt-20 pb-20"
              style="overflow-y: scroll; "
            >
            <ng-container *ngFor="let job of jobList">
              <div
                class="card nav flex-column nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <div class="card-body d-flex align-items-center">
                  <div class="container">
                    <div class="row">
                      <p class="card-title mr-auto" style="color: #072f5f">
                        {{job.createdAt | date: 'yyyy/MM/dd'}}
                      </p>
                    </div>
                    <div class="row">
                      <h4 style="color: #072f5f"  >{{job.title}}</h4>
                    </div>
                  </div>


                  <img
                    src="../../../../assets/images/BFAG_logo.png"
                    style="width: 20%"
                    class="card-img-top"
                    alt="Image"
                  />
                </div>
                <div class="m-3" style="margin-top:0 !important">
                  <p class="card-text" style="color: #072f5fa1">
                    <i
                      class="mr-1 fa fa-graduation-cap text-dark fa-1x"
                      aria-hidden="true"
                      style="color: black"
                    ></i>
                    {{job.qualification[0]}}
                  </p>
                  <p class="card-text mt-10" style="color: #072f5fa1">
                    <i
                      class="mr-1 fa fa-briefcase text-dark fa-1x"
                      aria-hidden="true"
                      style="color: black"
                    ></i>
                    Work Timing & Location - {{job.timingAndLocation}}
                  </p>
                  <!-- <p class="card-text mt-10" style="color: #072f5fa1">
                    <i
                      class="mr-1 fal fa-map-marker text-dark fa-1x"
                      style="color: black"
                    ></i>
                    Work Location - {{job.timingAndLocation}}
                  </p> -->
                  <button
                    (click)="scrollToDiv(job)"
                    class="btn btn-primary float-right nav-link border-0"
                    style="background: #072f5f"
                  >
                    View Job
                  </button>
                </div>
              </div>
            </ng-container>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- USA ENDS -->