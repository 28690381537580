import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-end-point-security',
  templateUrl: './end-point-security.component.html',
  styleUrls: ['./end-point-security.component.css']
})
export class EndPointSecurityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
