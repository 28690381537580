<!-- Dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      FINANCIAL ANALYSIS
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> FINANCIAL ANALYSIS</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/financial_analytics.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 10rem"
        />
        <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Financial Analytics gives different views of company’s financial data
        and it also gives in depth knowledge about the business and helps to
        take timely action to improve the performance
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Financial Analytics in finance function is mainly related to analyzing
        the financial statements such as, The Balance Sheet, The Income
        Statement, The Cash Flow for the companY.
      </p>
      <div>
        <h5 class="mb-20" style="color: green">
          Based on our experience and customer requirement, we provide
          assistance in:
        </h5>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Customer Profitability Analytics
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Customer Profitability Analytics
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Cash Flow Analytics
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Value Driver Analytics
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Share Holder Value Analytics
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- Dubai ended -->
<!-- INdia started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      FINANCIAL ANALYSIS
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> FINANCIAL ANALYSIS</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/financial_analytics.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 10rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Financial Analytics gives different views of company’s financial data
        and it also gives in depth knowledge about the business and helps to
        take timely action to improve the performance
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Financial Analytics in finance function is mainly related to analyzing
        the financial statements such as, The Balance Sheet, The Income
        Statement, The Cash Flow for the companY.
      </p>
      <div>
        <h5 class="mb-20" style="color: #0951A4">
          Based on our experience and customer requirement, we provide
          assistance in:
        </h5>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Customer Profitability Analytics
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Customer Profitability Analytics
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Cash Flow Analytics
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Value Driver Analytics
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Share Holder Value Analytics
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- India ended -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      FINANCIAL ANALYSIS
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> FINANCIAL ANALYSIS</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/financial_analytics.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 10rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Financial Analytics gives different views of company’s financial data
        and it also gives in depth knowledge about the business and helps to
        take timely action to improve the performance
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Financial Analytics in finance function is mainly related to analyzing
        the financial statements such as, The Balance Sheet, The Income
        Statement, The Cash Flow for the companY.
      </p>
      <div>
        <h5 class="mb-20" style="color: #0951A4">
          Based on our experience and customer requirement, we provide
          assistance in:
        </h5>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Customer Profitability Analytics
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Customer Profitability Analytics
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Cash Flow Analytics
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Value Driver Analytics
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Share Holder Value Analytics
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- USA ended -->
