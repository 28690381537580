<div
  *ngIf="selectCountryName == 'India'"
  class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing min-vh-100 mt-130"
>
  <div class="container text-justify">
    <div class="row">
      <div class="col-md-12">
        <div class="experience-item">
          <h2 class="title pb-15">FAQ's ON SETTING UP AN ENTITY IN INDIA</h2>
        </div>
        <div class="faq-accordion-3 pt-0">
          <div class="accordion" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingOne">
                <a
                  class="w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What are the forms of setting up a business entity in India
                  available for Foreign Investors? <br> Which one is the most
                  popular?
                </a>
              </div>
              <div
                id="collapseOne"
                class="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    Foreign investors can operate in India through separate
                    legal entities such as Private Limited Company, Public
                    Limited Company and Limited Liability Partnership (LLP) or
                    through dependent representative offices such as the Liaison
                    Office (LO), Project Office (PO) and Branch Office (BO).
                    <b>The Private Limited Company</b> is the most frequently
                    chosen form to set up a business in India, as it is
                    particularly convincing due to its relatively uncomplicated
                    incorporation process and high flexibility when it comes to
                    business models. There are other investment vehicles like
                    partnerships etc., but they are not available for foreign
                    investors.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingTwo">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What is a Private Limited Company?
                </a>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    A Private Limited Company is a separate legal entity, which
                    offers limited liability to its shareholders. The liability
                    of the shareholders can be either limited by shares or
                    limited by guarantee.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingThree">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Is there a minimum number of shareholders required for an
                  Indian Private Limited Company?
                </a>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    Yes, a minimum of two shareholders are required to form a
                    Private Limited Company in India. It is sufficient if one of
                    the two shareholders holds only one share and the other
                    holds all of the remaining shares. Shareholders can be
                    either individuals or legal entities, as long as they are
                    legally recognized.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingFour">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  What are the duties and rights of shareholders of an Indian
                  Private Limited Company?
                </a>
              </div>
              <div
                id="collapseFour"
                class="collapse"
                aria-labelledby="headingFour"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    The shareholders can carry out their rights exclusively
                    through General Meetings. Shareholders have a right, among
                    others, to vote on matters concerning the substrate of the
                    company. Such matters include but are not limited to,
                    liquidity, the sale of assets, the MOA/AOA, inspect
                    statutory registers and minutes books and appointment and
                    removal of Directors. The shareholders are obliged to
                    participate in the General Meetings and exercise their
                    voting rights.<br />


                  </p>
                  <p class="pb-15">
                    <b> Our Tip:</b> The shareholders of the Private Limited
                    Company need to be physically present for certain steps in
                    India, for example for the AGM. Therefore, it may be easier
                    to have two legal entities as shareholders, since an
                    individual shareholder may not be able to give power of
                    authorisation to someone else and thus would have to
                    continue to be physically present in India for attending the
                    meeting.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingFive">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Who is responsible for managing a Private Limited Company?
                </a>
              </div>
              <div
                id="collapseFive"
                class="collapse"
                aria-labelledby="headingFive"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    The responsibility to manage the company is on the Board of
                    Directors, as a collective management body. It carries out a
                    double function: On one hand, it is the collective
                    management body but on the other hand, it is also carrying
                    out the controlling functions and is therefore comparable to
                    a supervisory or advisory board.


                  </p>
                  <p class="pb-15">
                    The Board of Directors consists at least two members of which
                  <b
                    >at least one has to be Resident of India (Resident
                    Director).</b
                  >
                  Resident of India is a person who stayed, means who has been
                  physically present, in India for<b> 182 days or more </b
                  >within the last Financial Year. The purpose of his stay in
                  India does not matter.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingSex">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseSex"
                  aria-expanded="false"
                  aria-controls="collapseSex"
                >
                  What are the duties and rights of Directors of a Private
                  Limited Company?
                </a>
              </div>
              <div
                id="collapseSex"
                class="collapse"
                aria-labelledby="headingSex"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    The Board of Directors is responsible for the implementation
                    of shareholder resolutions and is therefore the sole
                    executive body responsible to the shareholders
                  </p>
                  <p class="pb-15">

                    Therefore, all Directors are obligated to Fiduciary Duties
                    and to the Duty of Care, Skill and Diligence. Fiduciary
                    duties ensure that Directors always put the interests of the
                    company and its shareholders first and above their own.
                    Fiduciary duties arise from the general authority granted to
                    the Board of Directors, according to which Directors have to
                    act in good faith and to their best knowledge.
                  </p>
                  <p class="pb-15">
                    The Directors are entitled to both individual and collective
                    rights. Individual rights include, for example, the right to
                    inspect the books and various voting rights. The collective
                    rights of the Board of Directors include, in particular, the
                    right to propose dividends, to appoint a Chairman and a
                    Managing Director. A Managing Director is, who is appointed
                    by virtue of the articles of the company or agreement with
                    the company or resolution passed in its general meeting or
                    by its board of Directors, entrusted with substantial powers
                    of management. The Managing Director usually manages the
                    day-to-day business of the company. He has a double role, as
                    he is both a Director and also an employee of the company.
                    <b>
                      It is not compulsory to appoint a Managing Director for a
                      Private Limited Company.</b
                    >
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingSeven">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  What is the liability of a director?
                </a>
              </div>
              <div
                id="collapseSeven"
                class="collapse"
                aria-labelledby="headingSeven"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    Liability of Directors may result from wilful acts, gross
                    negligence or slight negligence.<br />


                  </p>
                  <p class="pb-15">
                    <b>
                      Directors are personally liable to the company for all
                      unlawful acts and for transactions for which they were not
                      authorized and are inadmissible for being Ultra Vires.
                      This liability also includes damage compensation.</b
                    >
                  </p>
                  <p class="pb-15">
                    Directors are in general not liable for gross negligence or
                    slight negligence if the Directors have acted truly in the
                    interest of the company and within the scope of their powers
                    of disposal and with such due diligence, as it is
                    appropriate for their level of knowledge and experience.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingEight">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  Is it required to have a statute?
                </a>
              </div>
              <div
                id="collapseEight"
                class="collapse"
                aria-labelledby="headingEight"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    Yes, every Indian Private Limited Company has to have a
                    statute which is the
                    <b
                      >Memorandum of Association (MOA) and the Articles of
                      Association (AOA)</b
                    >. The MOA defines the name, objectives, registered office
                    address and capital of the company. Therefore, it explains
                    the relationship of a company with the outside world. The
                    AOA regulates the internal rules and regulations and the
                    internal relationship with the outside world. Both documents
                    can be seen as public documents at the Registrar of
                    Companies (ROC).
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingNine">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  Is there a requirement for minimum share capital in India?
                </a>
              </div>
              <div
                id="collapseNine"
                class="collapse"
                aria-labelledby="headingNine"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    There is no minimum share capital required for incorporating
                    a Private Limited Company. However, the amount of capital
                    required depends on the requirements of the proposed
                    business model of the company. Therefore, the company should
                    have at least enough capital in order to secure business
                    activities for a few months after incorporation.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingTen">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  What different types of capital exist
                </a>
              </div>
              <div
                id="collapseTen"
                class="collapse"
                aria-labelledby="headingTen"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    There are three different "types" of capital: Authorised
                    Share Capital, Subscribed Share Capital and Paid-up
                    Capital.<b> Authorised Share Capital</b> is the maximum
                    amount of share capital, up to which a company can issue
                    shares.<b> Subscribed Share Capital</b> is a subgroup of
                    Authorised Capital and it indicates the number of shares
                    that the shareholders have to subscribe to. Each shareholder
                    must subscribe for at least one share but is not obliged to
                    convert the shares he subscribed for into capital in full.
                    <b>Paid-up Capital</b> is the capital which is actually paid
                    into the company's bank account by the shareholders in
                    accordance with their Subscribed Share Capital. Upon receipt
                    of the Subscribed Share Capital, the company must issue
                    shares to the shareholders in the form of Share
                    Certificates. This process has to be documented and reported
                    to the authorities.


                  </p>
                  <p>
                    The personal liability of the shareholders then only extends
                    to unpaid capital on their shares.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingElev">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseElev"
                  aria-expanded="false"
                  aria-controls="collapseElev"
                >
                  Is there a need to obtain additional permits or approvals for
                  foreign entities investing in India?
                </a>
              </div>
              <div
                id="collapseElev"
                class="collapse"
                aria-labelledby="headingElev"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    No, Foreign Direct Investment in India has been greatly
                    liberalized in recent years and is now almost 100% permitted
                    in all sectors through the<b> Automatic route.</b> However,
                    foreign exchange regulations and procedures under the
                    Foreign Exchange Management Act (FEMA) must be carried out
                    in the case of cash payments from foreign shareholders. For
                    example, payments have to be made via the international
                    banking channel and via the Reserve Bank of India (RBI).
                    After the payment has been made, it has to be reported that
                    the shares were issued at a Fair Value.

                  </p>
                  <p class="pb-15">
                    Only in some highly sensible sectors, such as Defence and
                    Defence Industries, Foreign Direct Investment is only
                    allowed via the <b>Approval Route.</b> Further, it is to be
                    mentioned that Foreign Direct Investment in the e-commerce
                    sector is subject to restrictions.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingTlve">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseTlve"
                  aria-expanded="false"
                  aria-controls="collapse"
                >
                  Can the capital of an Indian Private Limited Company be
                  increased?
                </a>
              </div>
              <div
                id="collapseTlve"
                class="collapse"
                aria-labelledby="headingTlve"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    <b>
                      Yes, the Paid-up Capital can be increased as long it is
                      not exceeding the Authorised Share Capital. </b
                    >

                  </p>
                  <p class="pb-15">
                    <b>The Authorised Share Capital can also be increased.</b>
                    However, increasing the Authorised Share capital is rather
                    time-consuming, as the MOA has to be changed for this
                    purpose. For changing the MOA, a Special Resolution has to
                    be passed at an EGM. Any change in the MOA must be filed
                    with the ROC.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingTeen">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapseTeen"
                  aria-expanded="false"
                  aria-controls="collapseTeen"
                >
                  Can an Indian Private Limited Company take a loan?
                </a>
              </div>
              <div
                id="collapseTeen"
                class="collapse"
                aria-labelledby="headingTeen"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    Yes, an Indian Private Limited Company can either take a
                    loan or overdraft facilities after checking the regulatory
                    restrictions. The loan is usually provided by an Indian bank
                    at local conditions.

                  </p>
                  <p class="pb-15"> Loans from a foreign shareholder are considered External
                    Commercial Borrowings (ECB). These ECBs are subject to
                    strict regulations.</p>
                </div>
              </div>
            </div>
            <!-- card -->
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingfourteen">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapsefteen"
                  aria-expanded="false"
                  aria-controls="collapsefteen"
                >
                  How do you incorporate a Private Limited Company in India?
                </a>
              </div>
              <div
                id="collapsefteen"
                class="collapse"
                aria-labelledby="headingfourteen"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <ul>
                    <li> Apply for Name reservation.</li>
                    <li>If the shareholders are legal entities, they must
                      report their will for incorporating a new company in a Board
                      Resolution. Some basic information of the newly incorporated
                      company such as the name of the company, Authorised Share
                      Capital, Paid-up Capital, registered office, etc. has to be
                      reflected in the Board Resolution.</li>
                    <li>At least two Directors have to be appointed, and
                      one of them has to be Resident Director. All Directors have
                      to apply for a Director Identification Number (DIN) and a
                      Digital Signature Certificate (DSC) during the incorporation
                      process.</li>
                    <li>Several required documents must be signed and stamped by the shareholders.</li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingfiTeen">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapsefiteen"
                  aria-expanded="false"
                  aria-controls="collapsefiteen"
                >
                  How long does the incorporation process take?
                </a>
              </div>
              <div
                id="collapsefiteen"
                class="collapse"
                aria-labelledby="headingfiTeen"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    Usually, the incorporation process for a Private Limited
                    Company takes <b>30 to 45 days,</b> once all the documents
                    are received from the client. However, this duration heavily
                    depends on the participation of the shareholders, the
                    Availability of the Name of the proposed entity, the working
                    conditions of the Ministry of Corporate Affairs (MCA)
                    website and the procurement of necessary documents.
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingsiTeen">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapsesiteen"
                  aria-expanded="false"
                  aria-controls="collapsesiteen"
                >
                  What is a Fast Track Incorporation?
                </a>
              </div>
              <div
                id="collapsesiteen"
                class="collapse"
                aria-labelledby="headingsiTeen"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <p class="pb-15">
                    It is possible to first
                    <b>incorporate a company with local shareholders.</b>
                    The incorporation process and the bank account opening
                    process are significantly faster in such a scenario.
                    <b>
                      After incorporation, this fully operational company can
                      then be transferred to the foreign shareholders.</b
                    >
                  </p>
                </div>
              </div>
            </div>
            <!-- card -->
            <!-- card -->
            <div class="card">
              <div class="card-header" id="headingsevTeen">
                <a
                  class="collapsed w-100"
                  href=""
                  data-toggle="collapse"
                  data-target="#collapsesevteen"
                  aria-expanded="false"
                  aria-controls="collapsesevteen"
                >
                  What is the post Incorporation process?
                </a>
              </div>
              <div
                id="collapsesevteen"
                class="collapse"
                aria-labelledby="headingsevTeen"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <ul>
                    <li>
                      Conducting of First Board Meeting within 30 days from the
                      date of incorporation.
                    </li>
                    <li>
                      Filing of Form INC-22 for the situation of the registered
                      office of the Company within 30 days from the date of
                      Incorporation.
                    </li>
                    <li>
                      Appointment of the First Statutory Auditor of the Company
                      and filing of all the forms with ROC.
                    </li>
                    <li>
                      Opening of a Bank account in the name of the Company.
                    </li>
                    <li>
                      Allotment of Shares and Issue of share certificates.
                    </li>
                    <li>Deposition of Capital Contribution.</li>
                    <li>
                      Filing of Commencement of Business (Form INC-20A) with
                      MCA.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- card -->
          </div>
        </div>
        <!-- faq accordion -->
      </div>
    </div>
    <!-- container -->
  </div>
</div>
