import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-management',
  templateUrl: './sales-management.component.html',
  styleUrls: ['./sales-management.component.css']
})
export class SalesManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
