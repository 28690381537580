<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'"  class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing min-vh-100 mt-130" >
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="experience-item">
                  <h2 class="title pb-15">FAQ on CPA Solution</h2>
              </div>
              <div class="faq-accordion-3 pt-0">
                  <div class="accordion" id="accordionExample">
                      <div class="card">
                          <div class="card-header" id="headingOne">
                              <a class="w-100" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                HOW THE ACTUAL TAX RETURN PREPARATION WORK WILL INITIATE?
                              </a>
                          </div>
                          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                              <div class="card-body">

                                  <p class="pb-15">CPA will assign the Tax Returns to be prepared via e-Mail.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingTwo">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                WILL THESE RETURNS BE ASSIGNED ONE-BY-ONE OR IN BULK?
                              </a>
                          </div>
                          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> This is purely dependent on the Workload of a CPA & at the same time, availability of Source Documents for preparation and completion of a particular return.</p>

                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingThree">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                TALKING ABOUT THE 'SOURCE DOCUMENTS', WHAT IS THE PROCESS OF SHARING THE SAME?
                              </a>
                          </div>
                          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">Knowing the sensitivity & confidentiality of the work, we opt only secure means to share these documents such as Smart Vault, FTP (File Transfer Protocol), Drop box, etc.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingFour">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                HOW DO YOU LOCATE THESE SOURCE DOCUMENTS WITHIN A PARTICULAR PORTAL?
                              </a>
                          </div>
                          <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">In the first mail regarding assignment of tax returns, we request you to mention the path where the Client Folder containing the source documents are located.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingFive">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                HOW WILL YOU COME TO KNOW WHETHER THE AVAILABLE SOURCE DOCUMENTS ARE COMPLETE IN ALL RESPECT?
                              </a>
                          </div>
                          <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">For this purpose we will require the Tax Return of previous year. We do a comprehensive study of Last Year’s Tax Return, which facilitates us to identify which Source Documents are missing and will be needed to complete the tax return.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingSex">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSex" aria-expanded="false" aria-controls="collapseSex">
                                HOW WILL YOU OBTAIN THE MISSING SOURCE DOCUMENTS?
                              </a>
                          </div>
                          <div id="collapseSex" class="collapse" aria-labelledby="headingSex" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> Firstly, we will input all the available data in respective Tax Software and once this is done we will convey the missing documents along with open points, if any. This process will not take more than one working day.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingSeven">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                WHICH TAX SOFTWARE DO YOU USE?
                              </a>
                          </div>
                          <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">We have already worked on Tax Software’s viz. Ultra Tax, Intuit Tax Online, ATX, CCH, Lacerte etc. Working on any new Tax Software is never a bottleneck for BFAG as all our staffs are highly adaptable when it comes to software.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingEight">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                HOW YOU DEAL WITH THOSE TAX RETURNS FOR WHICH NO PRIOR YEAR RETURN IS AVAILABLE?
                              </a>
                          </div>
                          <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">As CPA will be in direct contact with all the Clients, in such type of Tax Returns CPA will provide some insights on nature of client, a variety of income sources, any particular requirements by client, etc. We will take this as a base and on the same guidelines we will prepare the Tax Return.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingNine">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                HOW DO YOU DEAL WITH THE TAX RETURNS FOR WHICH NO SOURCE DOCUMENTS ARE PENDING AND NO OPEN POINTS ARE THERE?
                              </a>
                          </div>
                          <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> As said earlier, firstly we will input all the available source documents in respective Tax Software. After conveying the required information to CPA, he/she will ask the same from client. As soon as CPA receives all the required information from the client, it will be uploaded to secured portal and will be conveyed to us. From this point we will take it forward and complete the return & draft copy of the tax return will be uploaded to client’s folder.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingTen">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                HOW MUCH TIME THIS WHOLE PROCESS WILL TAKE?
                              </a>
                          </div>
                          <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">It all depends on the availability of source documents. If all the documents are in hand, return preparation will not take more than one working day. On the other hand if any documents are missing, then it all depends on the response of the client to a CPA. From our experience, generally clients do respond in one or two working days. So in such cases the whole process will take three working days.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                        <!-- card -->
                        <div class="card">
                          <div class="card-header" id="headingElev">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseElev" aria-expanded="false" aria-controls="collapseElev">
                                IF AT ANY POINT OF TIME CPA WANTS TO KNOW THE REAL TIME STATUS OF THEIR WORK, THEN HOW CAN HE OBTAIN THE SAME?
                              </a>
                          </div>
                          <div id="collapseElev" class="collapse" aria-labelledby="headingElev" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> While dealing with numerous CPA’s, we understood that though everyone is specialized in diversified activities, their pain area is similar; Status of the Outsourced Activity. To overcome this problem, we have come forward with the simple solution of preparation of real time Activity/Status Report. This report will be prepared in Excel spreadsheet of Google Drive so that it will be accessible to both the parties (CPA & BFAG) at any point of time. This report will mention all the activities viz. Date of Assignment, Starting Date of an assignment, Completion Date of an assignment, Current status such as WIP, Missing Documents, Open Points, etc. which will give the real time status of any particular return.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                         <!-- card -->
                         <div class="card">
                          <div class="card-header" id="headingTlve">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTlve" aria-expanded="false" aria-controls="collapse">
                                WHAT ABOUT THE SECURITY OF DATA AND CONFIDENTIALITY MEASURES?
                              </a>
                          </div>
                          <div id="collapseTlve" class="collapse" aria-labelledby="headingTlve" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> for details please visit our website www.bostonfagroup.com and refer our data security page.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                       <!-- card -->
                       <div class="card">
                        <div class="card-header" id="headingTeen">
                            <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTeen" aria-expanded="false" aria-controls="collapseTeen">
                              HOW TO GET IN TOUCH?
                            </a>
                        </div>
                        <div id="collapseTeen" class="collapse" aria-labelledby="headingTeen" data-parent="#accordionExample">
                            <div class="card-body">
                                <p class="pb-15"> for details please visit our website www.bostonfagroup.com , alternatively you can also write to; <br>
                                  bfagconnect@bostonfagroup.com / sameer.p@bostonfagroup.com <br>
                                  or call us on +1 609 937 7291.</p>
                            </div>
                        </div>
                    </div>
                    <!-- card -->
                    </div>
              </div>
              <!-- faq accordion -->
          </div>
      </div>
      <!-- container -->
  </div>
</div>
<!-- USA end -->


<!-- India started -->
<div *ngIf="selectCountryName == 'India'"  class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing min-vh-100 mt-130" >
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="experience-item">
                  <h2 class="title pb-15">FAQ on CPA Solution</h2>
              </div>
              <div class="faq-accordion-3 pt-0">
                  <div class="accordion" id="accordionExample">
                      <div class="card">
                          <div class="card-header" id="headingOne">
                              <a class="w-100" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                HOW THE ACTUAL TAX RETURN PREPARATION WORK WILL INITIATE?
                              </a>
                          </div>
                          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                              <div class="card-body">

                                  <p class="pb-15">CPA will assign the Tax Returns to be prepared via e-Mail.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingTwo">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                WILL THESE RETURNS BE ASSIGNED ONE-BY-ONE OR IN BULK?
                              </a>
                          </div>
                          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> This is purely dependent on the Workload of a CPA & at the same time, availability of Source Documents for preparation and completion of a particular return.</p>

                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingThree">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                TALKING ABOUT THE 'SOURCE DOCUMENTS', WHAT IS THE PROCESS OF SHARING THE SAME?
                              </a>
                          </div>
                          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">Knowing the sensitivity & confidentiality of the work, we opt only secure means to share these documents such as Smart Vault, FTP (File Transfer Protocol), Drop box, etc.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingFour">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                HOW DO YOU LOCATE THESE SOURCE DOCUMENTS WITHIN A PARTICULAR PORTAL?
                              </a>
                          </div>
                          <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">In the first mail regarding assignment of tax returns, we request you to mention the path where the Client Folder containing the source documents are located.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingFive">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                HOW WILL YOU COME TO KNOW WHETHER THE AVAILABLE SOURCE DOCUMENTS ARE COMPLETE IN ALL RESPECT?
                              </a>
                          </div>
                          <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">For this purpose we will require the Tax Return of previous year. We do a comprehensive study of Last Year’s Tax Return, which facilitates us to identify which Source Documents are missing and will be needed to complete the tax return.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingSex">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSex" aria-expanded="false" aria-controls="collapseSex">
                                HOW WILL YOU OBTAIN THE MISSING SOURCE DOCUMENTS?
                              </a>
                          </div>
                          <div id="collapseSex" class="collapse" aria-labelledby="headingSex" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> Firstly, we will input all the available data in respective Tax Software and once this is done we will convey the missing documents along with open points, if any. This process will not take more than one working day.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingSeven">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                WHICH TAX SOFTWARE DO YOU USE?
                              </a>
                          </div>
                          <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">We have already worked on Tax Software’s viz. Ultra Tax, Intuit Tax Online, ATX, CCH, Lacerte  etc. Working on any new Tax Software is never a bottleneck for BFAG as all our staffs are highly adaptable when it comes to software.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingEight">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                HOW YOU DEAL WITH THOSE TAX RETURNS FOR WHICH NO PRIOR YEAR RETURN IS AVAILABLE?
                              </a>
                          </div>
                          <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">As CPA will be in direct contact with all the Clients, in such type of Tax Returns CPA will provide some insights on nature of client, a variety of income sources, any particular requirements by client, etc. We will take this as a base and on the same guidelines we will prepare the Tax Return.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingNine">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                HOW DO YOU DEAL WITH THE TAX RETURNS FOR WHICH NO SOURCE DOCUMENTS ARE PENDING AND NO OPEN POINTS ARE THERE?
                              </a>
                          </div>
                          <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> As said earlier, firstly we will input all the available source documents in respective Tax Software. After conveying the required information to CPA, he/she will ask the same from client. As soon as CPA receives all the required information from the client, it will be uploaded to secured portal and will be conveyed to us. From this point we will take it forward and complete the return & draft copy of the tax return will be uploaded to client’s folder.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingTen">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                HOW MUCH TIME THIS WHOLE PROCESS WILL TAKE?
                              </a>
                          </div>
                          <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">It all depends on the availability of source documents. If all the documents are in hand, return preparation will not take more than one working day. On the other hand if any documents are missing, then it all depends on the response of the client to a CPA. From our experience, generally clients do respond in one or two working days. So in such cases the whole process will take three working days.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                        <!-- card -->
                        <div class="card">
                          <div class="card-header" id="headingElev">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseElev" aria-expanded="false" aria-controls="collapseElev">
                                IF AT ANY POINT OF TIME CPA WANTS TO KNOW THE REAL TIME STATUS OF THEIR WORK, THEN HOW CAN HE OBTAIN THE SAME?
                              </a>
                          </div>
                          <div id="collapseElev" class="collapse" aria-labelledby="headingElev" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> While dealing with numerous CPA’s, we understood that though everyone is specialized in diversified activities, their pain area is similar; Status of the Outsourced Activity. To overcome this problem, we have come forward with the simple solution of preparation of real time Activity/Status Report. This report will be prepared in Excel spreadsheet of Google Drive so that it will be accessible to both the parties (CPA & BFAG) at any point of time. This report will mention all the activities viz. Date of Assignment, Starting Date of an assignment, Completion Date of an assignment, Current status such as WIP, Missing Documents, Open Points, etc. which will give the real time status of any particular return.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                         <!-- card -->
                         <div class="card">
                          <div class="card-header" id="headingTlve">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTlve" aria-expanded="false" aria-controls="collapse">
                                WHAT ABOUT THE SECURITY OF DATA AND CONFIDENTIALITY MEASURES?
                              </a>
                          </div>
                          <div id="collapseTlve" class="collapse" aria-labelledby="headingTlve" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> for details please visit our website www.bostonfagroup.com and refer our data security page.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                       <!-- card -->
                       <div class="card">
                        <div class="card-header" id="headingTeen">
                            <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTeen" aria-expanded="false" aria-controls="collapseTeen">
                              HOW TO GET IN TOUCH?
                            </a>
                        </div>
                        <div id="collapseTeen" class="collapse" aria-labelledby="headingTeen" data-parent="#accordionExample">
                            <div class="card-body">
                                <p class="pb-15"> for details please visit our website www.bostonfagroup.com , alternatively you can also write to; <br>
                                  bfagconnect@bostonfagroup.com / sameer.p@bostonfagroup.com <br>
                                  or call us on +1 609 937 7291.</p>
                            </div>
                        </div>
                    </div>
                    <!-- card -->
                    </div>
              </div>
              <!-- faq accordion -->
          </div>
      </div>
      <!-- container -->
  </div>
</div>
<!-- India end -->

<!-- Dubai started -->
<div *ngIf="selectCountryName == 'Dubai'"  class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing min-vh-100 mt-130" >
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="experience-item">
                  <h2 class="title pb-15">FAQ on CPA Solution</h2>
              </div>
              <div class="faq-accordion-3 pt-0">
                  <div class="accordion" id="accordionExample">
                      <div class="card">
                          <div class="card-header" id="headingOne">
                              <a class="w-100" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                HOW THE ACTUAL TAX RETURN PREPARATION WORK WILL INITIATE?
                              </a>
                          </div>
                          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                              <div class="card-body">

                                  <p class="pb-15">CPA will assign the Tax Returns to be prepared via e-Mail.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingTwo">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                WILL THESE RETURNS BE ASSIGNED ONE-BY-ONE OR IN BULK?
                              </a>
                          </div>
                          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> This is purely dependent on the Workload of a CPA & at the same time, availability of Source Documents for preparation and completion of a particular return.</p>

                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingThree">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                TALKING ABOUT THE 'SOURCE DOCUMENTS', WHAT IS THE PROCESS OF SHARING THE SAME?
                              </a>
                          </div>
                          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">Knowing the sensitivity & confidentiality of the work, we opt only secure means to share these documents such as Smart Vault, FTP (File Transfer Protocol), Drop box, etc.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingFour">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                HOW DO YOU LOCATE THESE SOURCE DOCUMENTS WITHIN A PARTICULAR PORTAL?
                              </a>
                          </div>
                          <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">In the first mail regarding assignment of tax returns, we request you to mention the path where the Client Folder containing the source documents are located.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingFive">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                HOW WILL YOU COME TO KNOW WHETHER THE AVAILABLE SOURCE DOCUMENTS ARE COMPLETE IN ALL RESPECT?
                              </a>
                          </div>
                          <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">For this purpose we will require the Tax Return of previous year. We do a comprehensive study of Last Year’s Tax Return, which facilitates us to identify which Source Documents are missing and will be needed to complete the tax return.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingSex">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSex" aria-expanded="false" aria-controls="collapseSex">
                                HOW WILL YOU OBTAIN THE MISSING SOURCE DOCUMENTS?
                              </a>
                          </div>
                          <div id="collapseSex" class="collapse" aria-labelledby="headingSex" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> Firstly, we will input all the available data in respective Tax Software and once this is done we will convey the missing documents along with open points, if any. This process will not take more than one working day.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingSeven">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                WHICH TAX SOFTWARE DO YOU USE?
                              </a>
                          </div>
                          <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">We have already worked on Tax Software’s viz. Ultra Tax, Intuit Tax Online, ATX, CCH, Lacerte  etc. Working on any new Tax Software is never a bottleneck for BFAG as all our staffs are highly adaptable when it comes to software.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingEight">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                 HOW YOU DEAL WITH THOSE TAX RETURNS FOR WHICH NO PRIOR YEAR RETURN IS AVAILABLE?
                              </a>
                          </div>
                          <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">As CPA will be in direct contact with all the Clients, in such type of Tax Returns CPA will provide some insights on nature of client, a variety of income sources, any particular requirements by client, etc. We will take this as a base and on the same guidelines we will prepare the Tax Return.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingNine">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                HOW DO YOU DEAL WITH THE TAX RETURNS FOR WHICH NO SOURCE DOCUMENTS ARE PENDING AND NO OPEN POINTS ARE THERE?
                              </a>
                          </div>
                          <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> As said earlier, firstly we will input all the available source documents in respective Tax Software. After conveying the required information to CPA, he/she will ask the same from client. As soon as CPA receives all the required information from the client, it will be uploaded to secured portal and will be conveyed to us. From this point we will take it forward and complete the return & draft copy of the tax return will be uploaded to client’s folder.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingTen">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                HOW MUCH TIME THIS WHOLE PROCESS WILL TAKE?
                              </a>
                          </div>
                          <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">It all depends on the availability of source documents. If all the documents are in hand, return preparation will not take more than one working day. On the other hand if any documents are missing, then it all depends on the response of the client to a CPA. From our experience, generally clients do respond in one or two working days. So in such cases the whole process will take three working days.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                        <!-- card -->
                        <div class="card">
                          <div class="card-header" id="headingElev">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseElev" aria-expanded="false" aria-controls="collapseElev">
                                IF AT ANY POINT OF TIME CPA WANTS TO KNOW THE REAL TIME STATUS OF THEIR WORK, THEN HOW CAN HE OBTAIN THE SAME?
                              </a>
                          </div>
                          <div id="collapseElev" class="collapse" aria-labelledby="headingElev" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> While dealing with numerous CPA’s, we understood that though everyone is specialized in diversified activities, their pain area is similar; Status of the Outsourced Activity. To overcome this problem, we have come forward with the simple solution of preparation of real time Activity/Status Report. This report will be prepared in Excel spreadsheet of Google Drive so that it will be accessible to both the parties (CPA & BFAG) at any point of time. This report will mention all the activities viz. Date of Assignment, Starting Date of an assignment, Completion Date of an assignment, Current status such as WIP, Missing Documents, Open Points, etc. which will give the real time status of any particular return.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                         <!-- card -->
                         <div class="card">
                          <div class="card-header" id="headingTlve">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTlve" aria-expanded="false" aria-controls="collapse">
                                WHAT ABOUT THE SECURITY OF DATA AND CONFIDENTIALITY MEASURES?
                              </a>
                          </div>
                          <div id="collapseTlve" class="collapse" aria-labelledby="headingTlve" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> for details please visit our website www.bostonfagroup.com and refer our data security page.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                       <!-- card -->
                       <div class="card">
                        <div class="card-header" id="headingTeen">
                            <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTeen" aria-expanded="false" aria-controls="collapseTeen">
                              HOW TO GET IN TOUCH?
                            </a>
                        </div>
                        <div id="collapseTeen" class="collapse" aria-labelledby="headingTeen" data-parent="#accordionExample">
                            <div class="card-body">
                                <p class="pb-15"> for details please visit our website www.bostonfagroup.com , alternatively you can also write to; <br>
                                  bfagconnect@bostonfagroup.com / sameer.p@bostonfagroup.com <br>
                                  or call us on +1 609 937 7291.</p>
                            </div>
                        </div>
                    </div>
                    <!-- card -->
                    </div>
              </div>
              <!-- faq accordion -->
          </div>
      </div>
      <!-- container -->
  </div>
</div>
<!-- Dubai end -->
