import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import APIURL from 'src/app/helper/apiUrl';

@Injectable({
  providedIn: 'root'
})
export class DsarService {

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
    'X-Skip-SSL-Validation' : 'true'
    })
  };

  saveDsar(dsar: any) {
    return this.http.post(`${APIURL}/dsar/save`, dsar,this.httpOptions);
  }

  getAllDsar() {  
    return this.http.get(`${APIURL}/dsar/getAll`,this.httpOptions);
  }

  addContact(contactForm:any) {
    return this.http.post(`${APIURL}/saveContactForm`,contactForm);
  }
}
