
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area fixed-asset-management customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Fixed Asset Management</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Fixed Asset Management</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Fixed Asset Management PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Fixed Asset Solutions</span>
                    <h2 class="title pb-15">Fixed Asset Management</h2>
                    <p>Fixed Assets module of Horizon provides you a comprehensive system for tracking your assets and for calculating and posting depreciation at the end of each accounting period. Fixed Assets allows you to take depreciation on an unlimited
                        number of assets with an unlimited number of books for each asset.</p>
                    <p>It enables you to maintain and control the complete lifecycle across depreciating or non-depreciating assets from creation to depreciation, revaluation, and disposal. Our Fixed Asset Management supports detailed PMV management link,
                        including maintenance schedules and insurance, while providing tight accounting integration so that your fixed asset information is always presented accurately on the financial statements.</p>
                    <p>Run projection depreciation calculations and move fixed assets between business units with ease. Fixed Assets provides you with the additional ability to execute partial and whole transfers and disposals, conduct bulk disposals with
                        automatic gain/loss calculations, and track transfer activity with as little as a single keystroke.</p>


                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Fixed Asset Management PART END ======-->

<!--====== Key Features PART START ======-->
<div class="about-faq-area about-faq-area-custom horizon-hrms dec-spacing bg_cover gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Highlights</span>
                    <h2 class="title">Key Features</h2>
                </div>
            </div>
            <div class="faq-accordion-3">
                <div class="accordion" id="accordionExample">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOne">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Multidimensional Asset Grouping
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>It allows you define groups of assets as you go. Definitions can be based on any characteristic, including type, location, custodian, and acquisition date etc.</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwo">
                                <a class="remove-icon collapsed change-line-height" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Category and Sub Category definition for Assets and Sub Assets
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Integration with the Purchase Order Module allows you to easily convert purchases into fixed assets without re-entering data. Eligible purchases are automatically displayed to simplify the conversion process.</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="remove-icon collapsed change-line-height" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Sophisticated ledger setup for provision, depreciation, and expense
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Execute partial and whole asset transfers and disposals individually to user-defined depreciation schedules. For bulk disposals, the system automatically calculates gain/loss on individual assets.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingFour">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseFour">
                                    <i class="fal fa-magic"></i>Assets Requisition and Purchase Order Integration
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Execute partial and whole asset transfers and disposals individually to user-defined depreciation schedules. For bulk disposals, the system automatically calculates gain/loss on individual assets.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingFive">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseFive">
                                    <i class="fal fa-magic"></i>Asset Creation with all the required information
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Execute partial and whole asset transfers and disposals individually to user-defined depreciation schedules. For bulk disposals, the system automatically calculates gain/loss on individual assets.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSix">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseSix">
                                    <i class="fal fa-magic"></i>Selection of depreciation method from multiple methods
                                </a>
                            </div>
                            <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Execute partial and whole asset transfers and disposals individually to user-defined depreciation schedules. For bulk disposals, the system automatically calculates gain/loss on individual assets.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSev">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseSev">
                                    <i class="fal fa-magic"></i>Assign Assets to Job or Projects, Department and Employee
                                </a>
                            </div>
                            <div id="collapseSev" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Select a predefined depreciation schedules. You can schedule the depreciation using any of the available standard methods (e.g., straight-line, fixed declining, sum-of-the-years-digit, etc.). Each depreciation method -->
                                    <!-- can include different averaging conventions as well as useful life and recovery periods.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEight">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseEight">
                                    <i class="fal fa-magic"></i>Project or Job wise allocation of depreciation
                                </a>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Store asset photos, purchase orders, maintenance agreements, warranties, invoices, and other documentation in the asset file and view them on screen at any time. These images provide you with a complete and readily -->
                                    <!-- accessible record of all asset data.</p> -->
                                </div>
                            </div>
                        </div>

                        <!-- card -->
                    </div>

                    <div class="col-lg-6">

                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOneb">
                                <a class="remove-icon collapsed change-line-height" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseOneb">
                                    <i class="fal fa-magic"></i>Link with PMV Timesheet and allocation of depreciation based on usage
                                </a>
                            </div>
                            <div id="collapseOneb" class="collapse" aria-labelledby="headingOneb" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>It allows you define groups of assets as you go. Definitions can be based on any characteristic, including type, location, custodian, and acquisition date etc.</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwob">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseTwob">
                                    <i class="fal fa-magic"></i>Assets Transfers, Sales and Disposals with gain and loss
                                </a>
                            </div>
                            <div id="collapseTwob" class="collapse" aria-labelledby="headingTwob" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Integration with the Purchase Order Module allows you to easily convert purchases into fixed assets without re-entering data. Eligible purchases are automatically displayed to simplify the conversion process.</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThreeb">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseThreeb">
                                    <i class="fal fa-magic"></i>Multiple Depreciation Methods with the age setup
                                </a>
                            </div>
                            <div id="collapseThreeb" class="collapse" aria-labelledby="headingThreeb" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Execute partial and whole asset transfers and disposals individually to user-defined depreciation schedules. For bulk disposals, the system automatically calculates gain/loss on individual assets.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingFourb">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseFourb">
                                    <i class="fal fa-magic"></i>Scan and Store Documentation Asset wise
                                </a>
                            </div>
                            <div id="collapseFourb" class="collapse" aria-labelledby="headingFourb" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Execute partial and whole asset transfers and disposals individually to user-defined depreciation schedules. For bulk disposals, the system automatically calculates gain/loss on individual assets.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingFiveb">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseFiveb">
                                    <i class="fal fa-magic"></i>One-click Monthly Depreciation Generation
                                </a>
                            </div>
                            <div id="collapseFiveb" class="collapse" aria-labelledby="headingFiveb" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Execute partial and whole asset transfers and disposals individually to user-defined depreciation schedules. For bulk disposals, the system automatically calculates gain/loss on individual assets.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSixb">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseSixb">
                                    <i class="fal fa-magic"></i>Integrated Financial Reporting
                                </a>
                            </div>
                            <div id="collapseSixb" class="collapse" aria-labelledby="headingSixb" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Execute partial and whole asset transfers and disposals individually to user-defined depreciation schedules. For bulk disposals, the system automatically calculates gain/loss on individual assets.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSevb">
                                <a class="remove-icon collapsed change-line-height" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseSevb">
                                    <i class="fal fa-magic"></i>Powerful MIS, Analytical and Business Intelligence Reporting
                                </a>
                            </div>
                            <div id="collapseSevb" class="collapse" aria-labelledby="headingSevb" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Select a predefined depreciation schedules. You can schedule the depreciation using any of the available standard methods (e.g., straight-line, fixed declining, sum-of-the-years-digit, etc.). Each depreciation method -->
                                    <!-- can include different averaging conventions as well as useful life and recovery periods.</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->

                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== Key Features PART END ======-->

<!--====== Benefits at a glances PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>

                    <p class="add-arrow">It allows you to define groups of assets as you go. Definitions can be based on any characteristics, including type, location, custodian, and acquisition date, etc.</p>
                    <p class="add-arrow">Integration with the Purchase Order Module allows you to easily convert purchases into fixed assets without re-entering data. Eligible purchases are automatically displayed to simplify the conversion process.</p>
                    <p class="add-arrow">Store asset photos, purchase orders, maintenance agreements, warranties, invoices, and other documentation in the asset file and view them on screen at any time. </p>
                    <p class="add-arrow">Select a predefined depreciation schedule. You can schedule the depreciation using any of the available standard methods e.g., straight-line, fixed declining, sum-of-the-years-digit, etc. </p>
                    <p class="add-arrow">Lifecycle management tracks assets from acquisition through final disposition</p>
                    <p class="add-arrow">A detailed asset profile provides complete depreciation history to assist with asset retirement management.</p>
                    <p class="add-arrow">Group assets to depreciate all the assets in each account as a single item.</p>
                    <p class="add-arrow">Automatic calculation of the depreciation according to the predetermined plan</p>
                    <p class="add-arrow">Automatic links to financial modules enable calculated depreciation entries to be posted directly to the general ledger.</p>
                    <p class="add-arrow">Execute partial and whole asset transfers and disposals individually to user-defined depreciation schedules. For bulk disposals, the system automatically calculates gain/loss on individual assets.
                    </p>
                    <p class="add-arrow">Drill-down capabilities to see the underlying detail from the financial statements to the related fixed asset transaction. Out-of-the-box asset summary reporting to show beginning balances, changes during the period, and ending balances.</p>

                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Benefits at a glances PART END ======-->
