<!-- USA Started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/CPA back-office/CPAsolutions1.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Accounting</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
    </div>
  </div>

  <div class="container">
    <div class="row" style="margin-top: 5%; margin-bottom: 4%">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPAsolutions6.png"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0" style="padding-left: 4%">
        <div>
          <span class="subSpan">Book keeping and Accounting</span>
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>General ledger maintenance: </b> We ensure accurate and
              up-to-date record-keeping, including journal entries,
              reconciliations, and adjustments.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify;"
            >
              <b>Accounts payable and receivable: </b> Our team manages vendor
              invoices, bill payments, customer invoicing, and collections.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Financial statement preparation:</b> We compile financial
              statements, including balance sheets, income statements, and cash
              flow statements, adhering to accounting standards.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-7" style="padding-right: 4% !important">
        <div>
          <span class="subSpan">Financial Analysis and Reporting</span>
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Ratio analysis: </b> We analyze financial ratios to assess your
              clients' financial health, performance, and profitability.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify;"
            >
              <b>Budgeting and forecasting: </b> Our team helps in developing
              financial budgets and forecasts, providing insights into future
              financial performance.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Management reporting: </b> We create customized reports and
              dashboards to communicate financial information effectively to
              your clients.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPAsolutions7.png"
            class="img-fluid"
          />
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPAsolutions8.png"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0" style="padding-left: 4%">
        <div>
          <span class="subSpan">Payroll Processing</span>
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Payroll administration: </b> We handle payroll processing,
              including calculation of employee salaries, deductions, and tax
              withholdings.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Payroll tax filings: </b> Our team prepares and files quarterly
              and annual payroll tax returns, including W-2 and 1099 forms.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-7 pr-0" style="padding-right: 4%">
        <div>
          <span class="subSpan">Practice Management Support</span>
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Client onboarding and engagement: </b> We assist in client
              onboarding processes, including engagement letters, client
              agreements, and client database management.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Time and billing: </b>
              Our team helps track billable hours, prepare client invoices, and manage accounts receivable.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify;"
            >
              <b>Workflow management: </b> We streamline workflow processes,
              ensuring efficient project management and resource allocation
              within your practice.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPAsolutions5.png"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- USA end -->

<!-- Dubai Started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/CPA back-office/CPAsolutions1.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Accounting</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
    </div>
  </div>

  <div class="container">
    <div class="row" style="margin-top: 5%; margin-bottom: 4%">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPAsolutions6.png"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0" style="padding-left: 4%">
        <div>
          <span class="uaeSpan">Book keeping and Accounting</span>
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>General ledger maintenance: </b> We ensure accurate and
              up-to-date record-keeping, including journal entries,
              reconciliations, and adjustments.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify;"
            >
              <b>Accounts payable and receivable: </b> Our team manages vendor
              invoices, bill payments, customer invoicing, and collections.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Financial statement preparation:</b> We compile financial
              statements, including balance sheets, income statements, and cash
              flow statements, adhering to accounting standards.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-7" style="padding-right: 4% !important">
        <div>
          <span class="uaeSpan">Financial Analysis and Reporting</span>
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Ratio analysis: </b> We analyze financial ratios to assess your
              clients' financial health, performance, and profitability.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify;"
            >
              <b>Budgeting and forecasting: </b> Our team helps in developing
              financial budgets and forecasts, providing insights into future
              financial performance.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Management reporting: </b> We create customized reports and
              dashboards to communicate financial information effectively to
              your clients.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPAsolutions7.png"
            class="img-fluid"
          />
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPAsolutions8.png"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0" style="padding-left: 4%">
        <div>
          <span class="uaeSpan">Payroll Processing</span>
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Payroll administration: </b> We handle payroll processing,
              including calculation of employee salaries, deductions, and tax
              withholdings.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Payroll tax filings: </b> Our team prepares and files quarterly
              and annual payroll tax returns, including W-2 and 1099 forms.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-7 pr-0" style="padding-right: 4%">
        <div>
          <span class="uaeSpan">Practice Management Support</span>
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Client onboarding and engagement: </b> We assist in client
              onboarding processes, including engagement letters, client
              agreements, and client database management.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Time and billing: </b>  Our team helps track billable hours, prepare client invoices, and manage accounts receivable.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify;"
            >
              <b>Workflow management: </b> We streamline workflow processes,
              ensuring efficient project management and resource allocation
              within your practice.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPAsolutions5.png"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Dubai end -->


<!-- India Started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/CPA back-office/CPAsolutions1.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Accounting</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
    </div>
  </div>

  <div class="container">
    <div class="row" style="margin-top: 5%; margin-bottom: 4%">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPAsolutions6.png"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0" style="padding-left: 4%">
        <div>
          <span class="subSpan">Book keeping and Accounting</span>
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>General ledger maintenance: </b> We ensure accurate and
              up-to-date record-keeping, including journal entries,
              reconciliations, and adjustments.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify;"
            >
              <b>Accounts payable and receivable: </b> Our team manages vendor
              invoices, bill payments, customer invoicing, and collections.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Financial statement preparation:</b> We compile financial
              statements, including balance sheets, income statements, and cash
              flow statements, adhering to accounting standards.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-7" style="padding-right: 4% !important">
        <div>
          <span class="subSpan">Financial Analysis and Reporting</span>
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Ratio analysis: </b> We analyze financial ratios to assess your
              clients' financial health, performance, and profitability.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify;"
            >
              <b>Budgeting and forecasting: </b> Our team helps in developing
              financial budgets and forecasts, providing insights into future
              financial performance.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Management reporting: </b> We create customized reports and
              dashboards to communicate financial information effectively to
              your clients.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPAsolutions7.png"
            class="img-fluid"
          />
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPAsolutions8.png"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0" style="padding-left: 4%">
        <div>
          <span class="subSpan">Payroll Processing</span>
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Payroll administration: </b> We handle payroll processing,
              including calculation of employee salaries, deductions, and tax
              withholdings.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Payroll tax filings: </b> Our team prepares and files quarterly
              and annual payroll tax returns, including W-2 and 1099 forms.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-7 pr-0" style="padding-right: 4%">
        <div>
          <span class="subSpan">Practice Management Support</span>
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Client onboarding and engagement: </b> We assist in client
              onboarding processes, including engagement letters, client
              agreements, and client database management.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; "
            >
              <b>Time and billing: </b>  Our team helps track billable hours, prepare client invoices, and manage accounts receivable.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify;"
            >
              <b>Workflow management: </b> We streamline workflow processes,
              ensuring efficient project management and resource allocation
              within your practice.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPAsolutions5.png"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- India end -->
