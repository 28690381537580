import { TaxRegulatoryCompliancesComponent } from './components/pages/tax-regulatory-compliances/tax-regulatory-compliances.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule ,ExtraOptions } from '@angular/router';
import { ErrorComponent } from './components/pages/error/error.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { MepContractingComponent } from './components/pages/industries/mep-contracting/mep-contracting.component';
import { ConstructionComponent } from './components/pages/industries/construction/construction.component';
import { FacilityComponent } from './components/pages/industries/facility/facility.component';
import { RealestateComponent } from './components/pages/industries/realestate/realestate.component';
import { GeneralContractingComponent } from './components/pages/industries/general-contracting/general-contracting.component';
import { TradingDistributionComponent } from './components/pages/industries/trading-distribution/trading-distribution.component';
import { ManufacturingComponent } from './components/pages/industries/manufacturing/manufacturing.component';
import { HorizonEbsComponent } from './components/pages/products/horizon-ebs/horizon-ebs.component';
import { HorizonHrmsComponent } from './components/pages/products/horizon-hrms/horizon-hrms.component';
import { HorizonFmsComponent } from './components/pages/products/horizon-fms/horizon-fms.component';
import { ErpConsultancyComponent } from './components/pages/services-and-supports/erp-consultancy/erp-consultancy.component';
import { HorizonImplementationComponent } from './components/pages/services-and-supports/horizon-implementation/horizon-implementation.component';
import { CustomizedSolutionsComponent } from './components/pages/services-and-supports/customized-solutions/customized-solutions.component';
import { HorizonTrainingComponent } from './components/pages/services-and-supports/horizon-training/horizon-training.component';
import { HorizonSupportComponent } from './components/pages/services-and-supports/horizon-support/horizon-support.component';
import { FinancialAccountingComponent } from './components/pages/solutions/financial-accounting/financial-accounting.component';
import { InventoryManagementComponent } from './components/pages/solutions/inventory-management/inventory-management.component';
import { SalesManagementComponent } from './components/pages/solutions/sales-management/sales-management.component';
import { HumanResourceManagementSystemComponent } from './components/pages/solutions/human-resource-management-system/human-resource-management-system.component';
import { ManufacturingManagementComponent } from './components/pages/solutions/manufacturing-management/manufacturing-management.component';
import { FacilityManagementComponent } from './components/pages/solutions/facility-management/facility-management.component';
import { PmvManagementComponent } from './components/pages/solutions/pmv-management/pmv-management.component';
import { FixedAssetManagementComponent } from './components/pages/solutions/fixed-asset-management/fixed-asset-management.component';
import { BankFacilityManagementComponent } from './components/pages/solutions/bank-facility-management/bank-facility-management.component';
import { RealEstateManagementComponent } from './components/pages/solutions/real-estate-management/real-estate-management.component';
import { ProjectAccountingComponent } from './components/pages/solutions/project-accounting/project-accounting.component';
import { ApprovalManagementComponent } from './components/pages/solutions/approval-management/approval-management.component';
import { TermOfUseComponent } from './components/pages/term-of-use/term-of-use.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { QuicklinkStrategy } from 'ngx-quicklink';
// boston components
import { WhyBfagComponent } from './components/pages/about/why-bfag/why-bfag.component';
import { OurTeamComponent } from './components/pages/about/our-team/our-team.component';
import { ClientSpeaksComponent } from './components/pages/about/client-speaks/client-speaks.component';
import { MissionAndVisionComponent } from './components/pages/about/mission-and-vision/mission-and-vision.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { FinancialServicesComponent } from './components/pages/financial-services/financial-services.component';
import { SourceToPayComponent } from './components/pages/source-to-pay/source-to-pay.component';
import { OrderToCashComponent } from './components/pages/order-to-cash/order-to-cash.component';
import { CpaSolutionComponent } from './components/pages/cpa-solution/cpa-solution.component';
import { AccountingForTheAccountantsComponent } from './components/pages/accounting-for-the-accountants/accounting-for-the-accountants.component';
import { TaxReturnPreparationComponent } from './components/pages/tax-return-preparation/tax-return-preparation.component';
import { ComplianceInboxComponent } from './components/pages/compliance-inbox/compliance-inbox.component';
import { ExpenseInboxComponent } from './components/pages/expense-inbox/expense-inbox.component';
import { AnalyticsInboxComponent } from './components/pages/analytics-inbox/analytics-inbox.component';
import { NewsletterComponent } from './components/pages/newsletter/newsletter.component';
import { CaseStudiesComponent } from './components/pages/case-studies/case-studies.component';
import { BlogsComponent } from './components/pages/blogs/blogs.component';
import { CareersComponent } from './components/pages/careers/careers.component';
import { ConsultingAndAdvisoryServicesComponent } from './components/pages/consulting-and-advisory-services/consulting-and-advisory-services.component';
import { AuditAndAssuranceSupportComponent } from './components/pages/audit-and-assurance-support/audit-and-assurance-support.component';
import { StartupSolutionComponent } from './components/pages/startup-solution/startup-solution.component';
import { TechnologyComponent } from './components/pages/technology/technology.component';
import { CorporateStructuringComponent } from './components/pages/corporate-structuring/corporate-structuring.component';
import { RegistrationUnderStartUpIndiaComponent } from './components/pages/registration-under-start-up-india/registration-under-start-up-india.component';
import { BusinessPlansComponent } from './components/pages/business-plans/business-plans.component';
import { FundRaisingComponent } from './components/pages/fund-raising/fund-raising.component';
import { MaSupportComponent } from './components/pages/ma-support/ma-support.component';
import { FaqOnGstComponent } from './components/pages/faq-on-gst/faq-on-gst.component';import { FaqOnPayrollComponent } from './components/pages/faq-on-payroll/faq-on-payroll.component';
import { FaqOnFemaComponent } from './components/pages/faq-on-fema/faq-on-fema.component';
import { FaqOnCpaSolutionComponent } from './components/pages/faq-on-cpa-solution/faq-on-cpa-solution.component';
import { FinancialAnalysisComponent } from './components/pages/financial-analysis/financial-analysis.component';
import { GlobalReportComponent } from './components/pages/global-report/global-report.component';
import { DirectTaxServicesComponent } from './components/pages/direct-tax-services/direct-tax-services.component';
import { IndirectTaxServicesComponent } from './components/pages/indirect-tax-services/indirect-tax-services.component';
import { CorporateLawComplianceComponent } from './components/pages/corporate-law-compliance/corporate-law-compliance.component';
import { PayrollCompensationAndBenefitManagementComponent } from './components/pages/payroll-compensation-and-benefit-management/payroll-compensation-and-benefit-management.component';
import { BusinessSetupAndCorporateStructuringComponent } from './components/pages/business-setup-and-corporate-structuring/business-setup-and-corporate-structuring.component';
import { GlobalComplianceManagementComponent } from './components/pages/global-compliance-management/global-compliance-management.component';
import { CfoServicesAndDebitComponent } from './components/pages/cfo-services-and-debit/cfo-services-and-debit.component';
import { FemaAdvisoryComponent } from './components/pages/fema-advisory/fema-advisory.component';
import { HowWeDoItComponent } from './components/pages/how-we-do-it/how-we-do-it.component';
import { ExecutionMethodologyComponent } from './components/pages/how-we-do-it/execution-methodology/execution-methodology.component';
import { DataSecurityAndConfidentialityComponent } from './components/pages/how-we-do-it/data-security-and-confidentiality/data-security-and-confidentiality.component';
import { EngagementProcessComponent } from './components/pages/how-we-do-it/engagement-process/engagement-process.component';
import { LawFirmCaseStudyComponent } from './components/pages/case-studies/law-firm-case-study/law-firm-case-study.component';
import { ItCompanyCaseStudyComponent } from './components/pages/case-studies/it-company-case-study/it-company-case-study.component';
import { CpaCaseStudyComponent } from './components/pages/case-studies/cpa-case-study/cpa-case-study.component';
import { VcFirmCaseStudyComponent } from './components/pages/case-studies/vc-firm-case-study/vc-firm-case-study.component';
import { FraudAlertComponent } from './components/pages/fraud-alert/fraud-alert.component';
import { MediaHitsComponent } from './components/pages/media-hits/media-hits.component';
import { CoordinationAndAssistanceForTaxAuditComponent } from './components/pages/audit-and-assurance-support/coordination-and-assistance-for-tax-audit/coordination-and-assistance-for-tax-audit.component';
import { CoordinationAndAssistanceForVatAuditComponent } from './components/pages/audit-and-assurance-support/coordination-and-assistance-for-vat-audit/coordination-and-assistance-for-vat-audit.component';
import { BusinessPlanPreparationComponent } from './components/pages/consulting-and-advisory-services/business-plan-preparation/business-plan-preparation.component';
import { BusinessValuationServiceComponent } from './components/pages/consulting-and-advisory-services/business-valuation-service/business-valuation-service.component';
import { FinancialDueDiligenceServicesComponent } from './components/pages/consulting-and-advisory-services/financial-due-diligence-services/financial-due-diligence-services.component';
import { MergersAndAcquisitionsComponent } from './components/pages/consulting-and-advisory-services/mergers-and-acquisitions/mergers-and-acquisitions.component';
import { StrategicCfoServicesComponent } from './components/pages/consulting-and-advisory-services/strategic-cfo-services/strategic-cfo-services.component';
import { FundRaiseComponent } from './components/pages/consulting-and-advisory-services/fund-raise/fund-raise.component';
import { AccountingServicesComponent } from './components/pages/accounting-services/accounting-services.component';
import { PrafullaProfileComponent } from './components/pages/profile/prafulla-profile/prafulla-profile.component';
import { SameerProfileComponent } from './components/pages/profile/sameer-profile/sameer-profile.component';
import { SanjayProfileComponent } from './components/pages/profile/sanjay-profile/sanjay-profile.component';
import { GaneshProfileComponent } from './components/pages/profile/ganesh-profile/ganesh-profile.component';
import { AmitProfileComponent } from './components/pages/profile/amit-profile/amit-profile.component';
import { KartikProfileComponent } from './components/pages/profile/kartik-profile/kartik-profile.component';
import { AmmarProfileComponent } from './components/pages/profile/ammar-profile/ammar-profile.component';
import { PayrollAdvisoryComponent } from './components/pages/payroll-advisory/payroll-advisory.component';
import { AccountingAndTransactionProcessingComponent } from './components/pages/accounting-and-transaction-processing/accounting-and-transaction-processing.component';
import { AccountsPayableComponent } from './components/pages/accounting-and-transaction-processing/accounts-payable/accounts-payable.component';
import { AccountsReceivableComponent } from './components/pages/accounting-and-transaction-processing/accounts-receivable/accounts-receivable.component';
import { FixedAssetsAccountingComponent } from './components/pages/accounting-and-transaction-processing/fixed-assets-accounting/fixed-assets-accounting.component';
import { FinancialReportingComponent } from './components/pages/accounting-and-transaction-processing/financial-reporting/financial-reporting.component';
import { BudgetingAndVarianceAnalysisComponent } from './components/pages/accounting-and-transaction-processing/budgeting-and-variance-analysis/budgeting-and-variance-analysis.component';
import { ReconciliationServicesComponent } from './components/pages/accounting-and-transaction-processing/reconciliation-services/reconciliation-services.component';
import { ConsolidationReportingGaapConversionComponent } from './components/pages/accounting-and-transaction-processing/consolidation-reporting-gaap-conversion/consolidation-reporting-gaap-conversion.component';
import { SmallBusinessAccountingComponent } from './components/pages/accounting-and-transaction-processing/small-business-accounting/small-business-accounting.component';
import { IndividualTaxReturnComponent } from './components/pages/tax-regulatory-compliances/individual-tax-return/individual-tax-return.component';
import { CorporateTaxReturnComponent } from './components/pages/tax-regulatory-compliances/corporate-tax-return/corporate-tax-return.component';
import { SalesAndUseTaxComponent } from './components/pages/tax-regulatory-compliances/sales-and-use-tax/sales-and-use-tax.component';
import { PayrollProcessingComponent } from './components/pages/payroll-processing/payroll-processing.component';
import { BusinessSetupComponent } from './components/pages/consulting-and-advisory-services/business-setup/business-setup.component';
import { UnderDevelopmentComponent } from './components/pages/under-development/under-development.component';
import { CpaBackOfficeComponent } from './components/pages/cpa-back-office/cpa-back-office.component';
import { FinancialAccountingSolutionComponent } from './components/pages/financial-accounting-solution/financial-accounting-solution.component';
import { AccountingComponent } from './components/pages/accounting/accounting.component';
import { AuditSupportComponent } from './components/pages/audit-support/audit-support.component';
import { GlobalCorporateStructuringComponent } from './components/pages/consulting-and-advisory-services/global-corporate-structuring/global-corporate-structuring.component';
import { IncomeTaxComponent } from './components/pages/income-tax/income-tax.component';
import { GoodsServiceTaxComponent } from './components/pages/goods-service-tax/goods-service-tax.component';
import { CorporateSecretarialComplianceComponent } from './components/pages/corporate-secretarial-compliance/corporate-secretarial-compliance.component';
import { InternalAuditComponent } from './components/pages/internal-audit/internal-audit.component';
import { StatutoryAuditComponent } from './components/pages/statutory-audit/statutory-audit.component';
import { StartupSolutionServicesComponent } from './components/pages/startup-solution-services/startup-solution-services.component';
import { FaqDubaiComponent } from './components/pages/faq-dubai/faq-dubai.component';
import { FaqIndiaComponent } from './components/pages/faq-india/faq-india.component';
import { FaqUsaComponent } from './components/pages/faq-usa/faq-usa.component';
import { FaqOdiComponent } from './components/pages/faq-odi/faq-odi.component';
import { FaqFdiComponent } from './components/pages/faq-fdi/faq-fdi.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { FirstBlogComponent } from './components/pages/first-blog/first-blog.component';
import { NewsletterDetailsComponent } from './components/pages/newsletter-details/newsletter-details.component';
import { AdminHomeComponent } from './components/pages/admin/admin-home/admin-home.component';
import { TrustCenterComponent } from './components/pages/trust-center/trust-center.component';
import { CcpaPageComponent } from './components/pages/ccpa-page/ccpa-page.component';
import { DsarPageComponent } from './components/pages/dsar-page/dsar-page.component';
import { IsoPageComponent } from './components/pages/iso-page/iso-page.component';
import { CookiesPolicyComponent } from './components/pages/cookies-policy/cookies-policy.component';
import { DsarViewComponent } from './dsar-view/dsar-view.component';
import { CookiesViewComponent } from './components/pages/cookies-view/cookies-view.component';
import { EndPointSecurityComponent } from './components/pages/end-point-security/end-point-security.component';
import { KetanChokshiComponent } from './components/pages/profile/ketan-chokshi/ketan-chokshi.component';
import { AnoopMehtaComponent } from './components/pages/profile/anoop-mehta/anoop-mehta.component';

const extraOptions :ExtraOptions={
  scrollPositionRestoration:'enabled',
  anchorScrolling:'enabled',
}

const routes: Routes = [
  {
    path: '',
    // component:HomeComponent,
    loadChildren: () =>
      import('./components/pages/home/home.module').then((m) => m.HomeModule),
    data: {
      title: 'Boston Financial ',
      description: 'Outsourcing, Consulting, Technology',
      keywords: 'Outsourcing, Consulting, Technology',
    },
  },
  {
    path: 'home',
    // component:HomeComponent,
    loadChildren: () =>
      import('./components/pages/home/home.module').then((m) => m.HomeModule),
    data: {
      title: 'Boston Financial ',
      description: 'Outsourcing, Consulting, Technology',
      keywords: 'Outsourcing, Consulting, Technology',
    },
  },
 
  {
    path: 'horizon-ebs',
    component: HorizonEbsComponent,
    data: {
      title: 'Horizon - EBS',
      description:
        'Horizon – EBS is a complete Enterprise Resource Management (ERP) solution designed to streamlines your financial, CRM and supply chain processes.',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },

  {
    path: 'horizon-hrms',
    component: HorizonHrmsComponent,
    data: {
      title: 'Horizon – Human Resource Management Solution (HRMS)',
      description:
        'Horizon – HRMS is a comprehensive system which handles HR Functions like Recruitment, Offer Letter,Training,Performance Management , Benefits & Payroll Management.',
      keywords:
        'hrms, hrms software,hr management software, hr erp, hr module in erp, hr erp software,erp hrms, hr software solution',
    },
  },

  {
    path: 'horizon-fms',
    component: HorizonFmsComponent,
    data: {
      title: 'Horizon – Facility Management Solution (FMS)',
      description:
        'Horizon – FMS is a complete web-based Computer Aided Facility Management which helps to computerize organize and enhance maintenance activities. ',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },

  {
    path: 'inventory-management',
    component: InventoryManagementComponent,
    data: {
      title: 'Frontline | ERP System For Inventory Control',
      description:
        'Procurement and Inventory Control module offers a host of advanced procurement and inventory features such as material requisition, purchase inquiry, supplier quotation analysis, purchase orders, good receipt, purchase invoice, store issue, stock adjustment, and stock transfer. ',
      keywords:
        'inventory management erp, erp inventory management module, erp system for inventory control, erp software',
    },
  },
  {
    path: 'sales-management',
    component: SalesManagementComponent,
    data: {
      title: 'Frontline | Sales Management',
      description:
        'Sales Management can strengthen your ability to increase revenue by enabling you to convert more prospects to customers and target the highest value opportunities.',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp,sales management erp',
    },
  },
  {
    path: 'human-resource-management-system',
    component: HumanResourceManagementSystemComponent,
    data: {
      title: 'Frontline | HR Management ERP',
      description:
        'Simplify & optimize day-to-day HR activities with no hassles. Horizon – HRMS software empowers organizational leaders with the critical tools and information they need to optimize the workforce and HR.',
      keywords:
        'hrms, hrms software,hr management software, hr erp, hr module in erp, hr erp software,erp hrms, hr software solution,erp',
    },
  },
  {
    path: 'manufacturing-management',
    component: ManufacturingManagementComponent,
    data: {
      title: 'Frontline | ERP Manufacturing Management',
      description:
        'Our Manufacturing Management Solution steps in as an intelligent link between the sales order module, the production-planning module, and the procurement department.',
      keywords:
        'erp manufacturing,manufacturing erp software,erp for manufacturing industry,best erp for manufacturing, erp for manufacturing company, erp production planning,best erp system for manufacturing',
    },
  },
  {
    path: 'facility-management',
    component: FacilityManagementComponent,
    data: {
      title: 'Frontline | Facility Management ERP',
      description:
        'Horizon – FMS is a modular facilities management system that works together with your business and allows day-to-day management of the processes, automates operations, & provides a management dashboard for critical analysis',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  {
    path: 'pmv-management',
    component: PmvManagementComponent,
    data: {
      title: 'Frontline | Plant, Machinery and Vehicle (PMV) Management',
      description:
        'Plant, Machinery, and Vehicle (PMV) Management module provides an integrated solution for supporting the operational needs of an enterprise-wide system. Covers all maintenance tasks such as Inspection, Servicing and Repair activities.',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  {
    path: 'fixed-asset-management',
    component: FixedAssetManagementComponent,
    data: {
      title: 'Frontline | Fixed Asset Management',
      description:
        'Fixed Assets Management designed to providing you with fast, dependable, customizable fixed asset management to maximize your bottom line. It combines acknowledged depreciation expertise and user-friendliness with the added power, data security, and functionality that your business situation demands.',
      keywords:
        'fixed asset management, fixed asset management software,fixed asset management system, fixed asset tracking software,erp',
    },
  },
  {
    path: 'bank-facility-management',
    component: BankFacilityManagementComponent,
    data: {
      title: 'Frontline | Bank Facility Management',
      description:
        'Bank Facility Management serves as decision making tool for higher management for their groups’ financial activities treasury activities .',
      keywords:
        'bank facility erp, ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  {
    path: 'real-estate-management',
    component: RealEstateManagementComponent,
    data: {
      title: 'Frontline | Real Estate ERP',
      description:
        'Real Estate ERP Property Management Solution of Horizon helps to manage properties, leases, renewals, and appraisals across your organization more effectively.',
      keywords:
        'erp software for real estate company, real estate erp,property management erp, real estate erp solutions, erp for real estate industry, erp software for real estate company',
    },
  },
  {
    path: 'approval-management',
    component: ApprovalManagementComponent,
    data: {
      title: 'Frontline | Approval Management',
      description:
        'Approval management functionality enhances Horizon by enabling users to automate and thus manage business processes more efficiently and cost-effectively.',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },

  {
    path: 'mep-contracting',
    component: MepContractingComponent,
    data: {
      title: 'Frontline | Horizon for MEP Contracting',
      description:
        'Horizon for Mechanical, Electrical, and Plumbing (MEP) is an innovative business management software solution specifically designed for MEP management and contracting industry.',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  {
    path: 'construction',
    component: ConstructionComponent,
    data: {
      title: 'Frontline | Horizon Construction ERP Software',
      description:
        'The Horizon for Construction enables construction companies to manage all aspects of construction management including Project Management & Control, Budgeting, Stores, Billing, on-site management, estimation, purchase, etc. ',
      keywords:
        'erp for construction industry, construction erp software, construction erp,erp software for construction industry, construction erp system, erp for contractors, erp system for construction company, best construction erp',
    },
  },
  {
    path: 'facility',
    component: FacilityComponent,
    data: {
      title: 'Frontline | Facility Management ERP',
      description:
        'Horizon – FMS is a modular facilities management system that works together with your business and allows day-to-day management of the processes, automates operations, & provides a management dashboard for critical analysis',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  {
    path: 'realestate',
    component: RealestateComponent,
    data: {
      title: 'Frontline | Real Estate ERP',
      description:
        'Real Estate ERP Property Management Solution of Horizon helps to manage properties, leases, renewals, and appraisals across your organization more effectively.',
      keywords:
        'erp software for real estate company, real estate erp,property management erp, real estate erp solutions, erp for real estate industry, erp software for real estate company',
    },
  },

  {
    path: 'general-contracting',
    component: GeneralContractingComponent,
    data: {
      title: 'Frontline | General Contracting',
      description:
        'Frontline is the leading ERP Software company in dubai provides ERP for small & medium enterprises. Our solutions include mep, mep contracting, facilities management software.',
      keywords:
        'general conttracting, ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  {
    path: 'trading-distribution',
    component: TradingDistributionComponent,
    data: {
      title: 'Frontline | ERP For Trading Company',
      description:
        'Horizon for Trading Industry provides intuitive solutions for optimized inventory management and distribution process. It helps drive the profitability of the organization.',
      keywords:
        'erp for trading,trading erp software,erp software for trading company, erp for trading company, erp for trading industry',
    },
  },
  {
    path: 'erp-consultancy',
    component: ErpConsultancyComponent,
    data: {
      title: 'Frontline | ERP Consultancy',
      description:
        'Our team offers local know-how coupled with a global outlook to ensure tangible results for our customers always with a focus on profitable growth and long-term business success.',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  {
    path: 'horizon-implementation',
    component: HorizonImplementationComponent,
    data: {
      title: 'Frontline | Horizon Implementation',
      description:
        'Horizon’s specially designed ERP modules will help companies boost their common business functionalities such as finance, HR, sales, operations & project management.',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  {
    path: 'customized-solutions',
    component: CustomizedSolutionsComponent,
    data: {
      title: 'Frontline | Customiszed ERP Solutions',
      description:
        'Why to go for non-customizable ERP software solution when you have opportunity to have a unique one that fits perfectly to your present and future organizational requirements? ',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  {
    path: 'horizon-training',
    component: HorizonTrainingComponent,
    data: {
      title: 'Frontline | Horizon Training',
      description:
        'Horizon training program  include  overview training for managers and executives, configuration training for the project team members, and functional training for the end users.',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  {
    path: 'horizon-support',
    component: HorizonSupportComponent,
    data: {
      title: 'Frontline | Support & ERP Services',
      description:
        'Frontline is the leading ERP Software company in dubai provides ERP for small & medium enterprises. We offer 24X7 customer and service support.',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  {
    path: 'manufacturing',
    component: ManufacturingComponent,
    data: {
      title: 'Frontline | ERP  For Manufacturing',
      description:
        'Manufacturing ERP helps to  improve on-time deliveries and the management of materials, job inventory, customer orders, direct cost, sales reporting, estimating, engineering, production, scheduling, and finance.',
      keywords:
        'erp manufacturing,manufacturing erp software,erp for manufacturing industry,best erp for manufacturing, erp for manufacturing company, erp production planning,best erp system for manufacturing',
    },
  },

  {
    path: 'contact-us',
    component: ContactComponent,
    data: {
      title: 'Contact-Us',
      description:
        '',
      keywords:
        '',
    },
  },

  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      title: 'Privacy Policy',

    },
  },
  {
    path: 'cookies-policy',
    component: CookiesPolicyComponent,
    data: {
      title: 'cookies policy',

    },
  },
  {
    path: 'trust-center',
    component: TrustCenterComponent,
    data: {
      title: 'Trust Center',

    },
  },
  {
    path: 'ccpa-page',
    component: CcpaPageComponent,
    data: {
      title: 'CCPA',

    },
  },
  {
    path: 'dsar-page',
    component: DsarPageComponent,
    data: {
      title: 'DSAR',

    },
  },
  {
    path: 'endpointsecurity-page',
    component: EndPointSecurityComponent,
    data: {
      title: 'end Point',

    },
  },
  {
    path: 'iso-page',
    component: IsoPageComponent,
    data: {
      title: 'ISO',

    },
  },

  {
    path: 'term-of-use',
    component: TermOfUseComponent,
    data: {
      title: 'Term Of Use',
      description: 'Frontline Information Technology',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  // Boston code
  {
    path: 'why-bfag',
    component: WhyBfagComponent,
    data: {
      title: 'Why BFAG',
      description: 'Boston Finance',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  {
    path: 'our-team',
    component: OurTeamComponent,
    data: {
      title: 'Our Team',
      description: 'Boston Finance',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  {
    path: 'mission-and-vision',
    component: MissionAndVisionComponent,
    data: {
      title: 'Mission And Vision',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'client-speaks',
    component: ClientSpeaksComponent,
    data: {
      title: 'client Speaks',
      description: 'Boston Finance',
      keywords:
        'ERP Software, Project Management Software, erp software company in dubai, emirates facility management, IT Companies in UAE,Facility Management Company,CRM System, HR Payrol Software, Bank Facility Management, ERP Software for manufacturing, ERP,Property management erp',
    },
  },
  {
    path: 'app-services',
    component: ServicesComponent,
    data: {
      title: 'Services',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'financial-services',
    component: FinancialServicesComponent,
    data: {
      title: 'Financial Service',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'source-to-pay',
    component: SourceToPayComponent,
    data: {
      title: 'Source To Pay',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'order-to-cash',
    component: OrderToCashComponent,
    data: {
      title: 'Order To Cash',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'financial-analysis',
    component: FinancialAnalysisComponent,
    data: {
      title: 'Financial Analysis',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'global-report',
    component: GlobalReportComponent,
    data: {
      title: 'Global Report',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'direct-tax-services',
    component: DirectTaxServicesComponent,
    data: {
      title: 'Direct Tax Services',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'indirect-tax-services',
    component: IndirectTaxServicesComponent,
    data: {
      title: 'indirect Tax Services',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'corporate-law-compliance',
    component: CorporateLawComplianceComponent,
    data: {
      title: 'Corporate Law Compliance',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'payroll-compensation-and-benefit-management',
    component: PayrollCompensationAndBenefitManagementComponent,
    data: {
      title: 'Payroll Compensation And Benefit Management',
      description: 'Boston Finance',
      keywords: '',
    },
  },

  // Global Structuring and advisory

  {
    path: 'business-setup-and-corporate-structuring',
    component: BusinessSetupAndCorporateStructuringComponent,
    data: {
      title: 'Business Setup And Corporate Structuring',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'global-compliance-management',
    component: GlobalComplianceManagementComponent,
    data: {
      title: 'Global Compliance Management',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'cfo-service-and-debit-syndication',
    component: CfoServicesAndDebitComponent,
    data: {
      title: 'CFO Service and Debit Syndication ',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'fema-advisory',
    component: FemaAdvisoryComponent,
    data: {
      title: 'FEMA Advisory',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  // CPA Solution
  {
    path: 'cpa-solution',
    component: CpaSolutionComponent,
    data: {
      title: 'CPA Solution',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'accounting-for-the-accountants',
    component: AccountingForTheAccountantsComponent,
    data: {
      title: 'Accounting for the Accountants',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'tax-return-preparation',
    component: TaxReturnPreparationComponent,
    data: {
      title: 'Tax Return Preparation',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'compliance-inbox',
    component: ComplianceInboxComponent,
    data: {
      title: 'Compliance Inbox',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'expense-inbox',
    component: ExpenseInboxComponent,
    data: {
      title: 'Expense Inbox',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'analytics-inbox',
    component: AnalyticsInboxComponent,
    data: {
      title: 'Analytics Inbox',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'newsletter',
    component: NewsletterComponent,
    data: {
      title: 'Newsletter',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'case-studies',
    component: CaseStudiesComponent,
    data: {
      title: 'Case Studies',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'law-firm-case-study',
    component: LawFirmCaseStudyComponent,
    data: {
      title: 'Law Firm Case Study',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'it-company-case-study',
    component: ItCompanyCaseStudyComponent,
    data: {
      title: 'IT Company Case Study',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'cpa-case-study',
    component: CpaCaseStudyComponent,
    data: {
      title: 'CPA Case Study',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'vc-firm-case-study',
    component: VcFirmCaseStudyComponent,
    data: {
      title: 'VC Firm Case Study',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'blogs',
    component: BlogsComponent,
    data: {
      title: 'Blogs',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'careers',
    component: CareersComponent,
    data: {
      title: 'Careers',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'fraud-alert',
    component: FraudAlertComponent,
    data: {
      title: 'Fraud Alert',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'media-hits',
    component: MediaHitsComponent,
    data: {
      title: 'Media Hits',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'consulting-and-advisory-services',
    component: ConsultingAndAdvisoryServicesComponent,
    data: {
      title: 'Consulting And Advisory Services',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'audit-and-assurance-support',
    component: AuditAndAssuranceSupportComponent,
    data: {
      title: 'Audit And Assurance Support',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'startup-solution',
    component: StartupSolutionComponent,
    data: {
      title: 'Startup Solution',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'technology',
    component: TechnologyComponent,
    data: {
      title: 'Technology',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'corporate-structuring',
    component: CorporateStructuringComponent,
    data: {
      title: 'Corporate Structuring',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'registration-under-start-up-india',
    component: RegistrationUnderStartUpIndiaComponent,
    data: {
      title: 'Registration Under Start Up India',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'business-plans',
    component: BusinessPlansComponent,
    data: {
      title: 'Business Plans',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'global-corporate-structuring',
    component: GlobalCorporateStructuringComponent,
    data: {
      title: 'Global Corporate Structuring',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'fund-raising',
    component: FundRaisingComponent,
    data: {
      title: 'Fund Raising',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'ma-support',
    component: MaSupportComponent,
    data: {
      title: 'Ma Support',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'faq-on-gst',
    component: FaqOnGstComponent,
    data: {
      title: 'FAQ on GST',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'faq-on-payroll',
    component: FaqOnPayrollComponent,
    data: {
      title: 'FAQ on Payroll',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'faq-on-fema',
    component: FaqOnFemaComponent,
    data: {
      title: 'FAQ on FEMA',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  {
    path: 'faq-on-cpa-solution',
    component: FaqOnCpaSolutionComponent,
    data: {
      title: 'FAQ on CPA Solution',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  // how do we do that

  {
    path: 'how-we-do-it',
    component: HowWeDoItComponent,
    data: {
      title: 'How We Do It',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'execution-methodology',
    component: ExecutionMethodologyComponent,
    data: {
      title: 'Execution Methodology',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'data-security-and-confidentiality',
    component: DataSecurityAndConfidentialityComponent,
    data: {
      title: 'Data Security And Confidentiality',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'engagement-process',
    component: EngagementProcessComponent,
    data: {
      title: 'Engagement Process',
      description: '',
      keywords: '',
    },
  },

  {
    path: 'coordination-and-assistance-for-tax-audit',
    component: CoordinationAndAssistanceForTaxAuditComponent,
    data: {
      title: 'Corporate Tax Advisory',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'coordination-and-assistance-for-vat-audit',
    component: CoordinationAndAssistanceForVatAuditComponent,
    data: {
      title: 'Vat Advisory',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'business-plan-preparation',
    component: BusinessPlanPreparationComponent,
    data: {
      title: 'Business Plan Preparation',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'business-valuation-service',
    component: BusinessValuationServiceComponent,
    data: {
      title: 'Business Valuation Service ',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'financial-due-diligence-services',
    component: FinancialDueDiligenceServicesComponent,
    data: {
      title: 'Financial Due Diligence Services ',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'consulting-fund-raise',
    component: FundRaiseComponent,
    data: {
      title: 'Fund Raising',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'mergers-and-acquisitions',
    component: MergersAndAcquisitionsComponent,
    data: {
      title: 'Mergers And Acquisitions ',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'strategic-cfo-services',
    component: StrategicCfoServicesComponent,
    data: {
      title: 'Strategic CFO Services',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'accounting-and-transition-processing',
    component: FinancialAccountingSolutionComponent,
    data: {
      title: 'Accounting and Transition Processing',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'tax-regulatory-compliances',
    component: TaxRegulatoryCompliancesComponent,
    data: {
      title: 'Tax & Regulatory Compliances',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'accounting-services',
    component: AccountingServicesComponent,
    data: {
      title: 'Accounting Services',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'prafulla-chhajed',
    component: PrafullaProfileComponent,
    data: {
      title: 'Profile',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'anoop-mehta',
    component: AnoopMehtaComponent,
    data: {
      title: 'Profile',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'sameer-paddalwar',
    component: SameerProfileComponent,
    data: {
      title: 'Profile',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'ketan-chokshi',
    component: KetanChokshiComponent,
    data: {
      title: 'Profile',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'sanjay-kapadia',
    component: SanjayProfileComponent,
    data: {
      title: 'Profile',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'ganesh-padakannayaa',
    component: GaneshProfileComponent,
    data: {
      title: 'Profile',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'amit-mulgund',
    component: AmitProfileComponent,
    data: {
      title: 'Profile',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'kartik-mehta',
    component: KartikProfileComponent,
    data: {
      title: 'Profile',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'ammar-tumpalliwar',
    component: AmmarProfileComponent,
    data: {
      title: 'Profile',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'payroll-advisory',
    component: PayrollAdvisoryComponent,
    data: {
      title: 'Payroll Advisory',
      description: '',
      keywords: '',
    },
  },

  // service new
  // {
  //   path: 'accounting-and-transaction-processing',
  //   component: AccountingAndTransactionProcessingComponent,
  //   data: {
  //     title: 'Accounting And Transaction Processing',
  //     description: '',
  //     keywords: '',
  //   },
  // },
  {
    path: 'accounts-payable',
    component: AccountsPayableComponent,
    data: {
      title: 'Accounts Payable',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'accounts-receivable',
    component: AccountsReceivableComponent,
    data: {
      title: 'Accounts Receivable',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'fixed-assets-accounting',
    component: FixedAssetsAccountingComponent,
    data: {
      title: 'Fixed Assets Accounting',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'financial-reporting',
    component: FinancialReportingComponent,
    data: {
      title: 'Financial Reporting ',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'budgeting-and-variance-analysis',
    component: BudgetingAndVarianceAnalysisComponent,
    data: {
      title: 'budgeting-and-variance-analysis',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'reconciliation-services',
    component: ReconciliationServicesComponent,
    data: {
      title: 'Reconciliation Services',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'consolidation-reporting-gaap-conversion',
    component: ConsolidationReportingGaapConversionComponent,
    data: {
      title: 'Consolidation Reporting & GAAP Conversion',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'small-business-accounting',
    component: SmallBusinessAccountingComponent,
    data: {
      title: 'Small Business Accounting',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'individual-tax-return',
    component: IndividualTaxReturnComponent,
    data: {
      title: 'Individual Tax Return',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'corporate-tax-return',
    component: CorporateTaxReturnComponent,
    data: {
      title: 'Corporate Tax Return',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'sales-and-use-tax',
    component: SalesAndUseTaxComponent,
    data: {
      title: 'Sales and Use Tax',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'payroll-processing',
    component: PayrollProcessingComponent,
    data: {
      title: 'Payroll Processing',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'business-setup',
    component: BusinessSetupComponent,
    data: {
      title: 'Business Setup',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'startup-solution-services',
    component: StartupSolutionServicesComponent,
    data: {
      title: 'Startup Solution',
      description: 'Boston Finance',
      keywords: '',
    },
  },
  // under development
  {
    path: 'under-development',
    component: UnderDevelopmentComponent,
    data: {
      title: 'Under Development',
      description: '',
      keywords: '',
    },
  },
  // CPA back office
  {
    path: 'cpa-back-office',
    component: CpaBackOfficeComponent,
    data: {
      title: 'CPA Back Office',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'accounting',
    component: AccountingComponent,
    data: {
      title: 'Accounting',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'audit-support',
    component: AuditSupportComponent,
    data: {
      title: 'Audit Support',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'income-tax',
    component: IncomeTaxComponent,
    data: {
      title: 'Tax and Regulatory Compliance',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'goods-and-service-tax',
    component: GoodsServiceTaxComponent,
    data: {
      title: 'Goods and Service Tax',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'corporate-secretarial-compliance',
    component: CorporateSecretarialComplianceComponent,
    data: {
      title: 'Corporate Secretarial Compliance',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'internal-audit',
    component: InternalAuditComponent,
    data: {
      title: 'Internal Audit',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'statutory-audit',
    component: StatutoryAuditComponent,
    data: {
      title: 'Statutory Audit',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'faq-dubai-entity-setup',
    component: FaqDubaiComponent,
    data: {
      title: 'FAQ Dubai Entity Setup',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'faq-india-entity-setup',
    component: FaqIndiaComponent,
    data: {
      title: 'FAQ India Entity Setup',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'faq-usa-entity-setup',
    component: FaqUsaComponent,
    data: {
      title: 'FAQ USA Entity Setup',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'faq-odi',
    component: FaqOdiComponent,
    data: {
      title: 'FAQ ODI',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'faq-fdi',
    component: FaqFdiComponent,
    data: {
      title: 'FAQ fdi',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'blog-details/:_id',
    component: BlogDetailsComponent,
    data: {
      title: 'Blog',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'newsletter-details/:id',
    component: NewsletterDetailsComponent,
    data: {
      title: 'NewsLetter Details',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'first-blog',
    component: FirstBlogComponent,
    data: {
      title: 'India Digital Personal Data Protection Bill ',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'dsar-view',
    component: DsarViewComponent,
    data: {
      title: 'DSAR DATA ',
      description: '',
      keywords: '',
    },
  },
  {
    path: 'cookies-view',
    component: CookiesViewComponent,
    data: {
      title: 'COOKIES DATA ',
      description: '',
      keywords: '',
    },
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy,...extraOptions }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const routingComponents = [
  ContactComponent,
  ErrorComponent,
  PreloaderComponent,
  WhyBfagComponent,
  OurTeamComponent,
  ClientSpeaksComponent,
  HeaderComponent,
  FooterComponent,
  MepContractingComponent,
  ConstructionComponent,
  FacilityComponent,
  RealestateComponent,
  GeneralContractingComponent,
  ManufacturingComponent,
  TradingDistributionComponent,
  HorizonEbsComponent,
  HorizonHrmsComponent,
  HorizonFmsComponent,
  ErpConsultancyComponent,
  HorizonImplementationComponent,
  CustomizedSolutionsComponent,
  HorizonTrainingComponent,
  HorizonSupportComponent,
  FinancialAccountingComponent,
  InventoryManagementComponent,
  SalesManagementComponent,
  HumanResourceManagementSystemComponent,
  ManufacturingManagementComponent,
  FacilityManagementComponent,
  PmvManagementComponent,
  FixedAssetManagementComponent,
  BankFacilityManagementComponent,
  RealEstateManagementComponent,
  ProjectAccountingComponent,
  ApprovalManagementComponent,
  TermOfUseComponent,
  PrivacyPolicyComponent,
];
