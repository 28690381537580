<!-- Dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center text-uppercase"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      M&A Support
    </h2>
    <small class="mt-10 text-uppercase" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/startup-solution"
        >Home <i class="fas fa-arrow-circle-right"></i> M&A Support</a
      ></small
    >
  </div>
  <div class="row mt-50 min-vh-100">
    <div class="col-lg-5 justify-content-center align-items-center imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 420px; height: 100px"
      >
        <img
          src="../../../../assets/images/cpa solutions/accounts.png"
          class="img-fluid"
          style="max-width: 350px; position: relative; top: 12rem"
        />
        <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Mergers and acquisitions are happening the most in the startup segment.
        A lot of investments are happening in the startup segment and this has
        increased the pace of mergers and acquisitions. They are usually aimed
        at achieving:
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      However, many mergers and acquisitions fail to bear fruit due to inadequate planning, incorrect valuation, or improper post-merger integration. BFAG has decades of experience and expertise to guide and support mergers and acquisitions at every stage of planning, execution, and integration.
      </p>
    </div>
  </div>
  <div class="col mb-50">
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10">
            <li
              class="mb-2 text-dark"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: green"></i>
              Operational Economics
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: green"></i>
              Eliminating Competition
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: green"></i>
              Value creation
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: green"></i>
              Greater Synergy
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: green"></i>
              Growth
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: green"></i>
              Diversification
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: green"></i>
              Tax Shield benefits
            </li>

            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: green"></i>
              Effective Financial planning
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/cpa solutions/accounts.png"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Dubai end -->

<!-- India started -->
<div  *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center text-uppercase"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      M&A Support
    </h2>
    <small class="mt-10 text-uppercase" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/startup-solution"
        >Home <i class="fas fa-arrow-circle-right"></i> M&A Support</a
      ></small
    >
  </div>
  <div class="row mt-50 min-vh-100">
    <div class="col-lg-5 justify-content-center align-items-center imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 420px; height: 100px"
      >
        <img
          src="../../../../assets/images/cpa solutions/accounts.png"
          class="img-fluid"
          style="max-width: 350px; position: relative; top: 12rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Mergers and acquisitions are happening the most in the startup segment.
        A lot of investments are happening in the startup segment and this has
        increased the pace of mergers and acquisitions. They are usually aimed
        at achieving:
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      However, many mergers and acquisitions fail to bear fruit due to inadequate planning, incorrect valuation, or improper post-merger integration. BFAG has decades of experience and expertise to guide and support mergers and acquisitions at every stage of planning, execution, and integration.
      </p>
    </div>
  </div>
  <div class="col mb-50">
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10">
            <li
              class="mb-2 text-dark"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Operational Economics
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Eliminating Competition
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Value creation
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Greater Synergy
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Growth
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Diversification
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Tax Shield benefits
            </li>

            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Effective Financial planning
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/cpa solutions/accounts.png"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- India end -->

<!-- USA started -->
<div  *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center text-uppercase"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      M&A Support
    </h2>
    <small class="mt-10 text-uppercase" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/startup-solution"
        >Home <i class="fas fa-arrow-circle-right"></i> M&A Support</a
      ></small
    >
  </div>
  <div class="row mt-50 min-vh-100">
    <div class="col-lg-5 justify-content-center align-items-center imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 420px; height: 100px"
      >
        <img
          src="../../../../assets/images/cpa solutions/accounts.png"
          class="img-fluid"
          style="max-width: 350px; position: relative; top: 12rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Mergers and acquisitions are happening the most in the startup segment.
        A lot of investments are happening in the startup segment and this has
        increased the pace of mergers and acquisitions. They are usually aimed
        at achieving:
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      However, many mergers and acquisitions fail to bear fruit due to inadequate planning, incorrect valuation, or improper post-merger integration. BFAG has decades of experience and expertise to guide and support mergers and acquisitions at every stage of planning, execution, and integration.
      </p>
    </div>
  </div>
  <div class="col mb-50">
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10">
            <li
              class="mb-2 text-dark"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Operational Economics
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Eliminating Competition
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Value creation
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Greater Synergy
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Growth
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Diversification
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Tax Shield benefits
            </li>

            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Effective Financial planning
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/cpa solutions/accounts.png"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- USA end -->