
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area construction customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Construction</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Construction</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Horizon for Construction  PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Construction Industries</span>
                    <h2 class="title pb-15">Horizon - EBS for Construction</h2>
                    <p>How do you keep track of your subcontractors, materials, plant, overhead, and human resource costs and spend across multiple sites? Also, staying on budget remains vital to deliver today’s narrow project margins.</p>
                    <p>Horizon - EBS is a comprehensive VAT Enabled Construction ERP Software Solution that ensures high efficiency by monitoring & supporting projects for construction industries. Horizon – EBS for Construction provides Contractors with
                        a modern, single, unified construction software solution. </p>
                    <p>It covers the whole life cycle of construction projects and helps you to manage and control all your costs, budgets, projects, inventory/material, accounts, assets, human resources, and finances for successful project execution within
                        the scheduled estimate.</p>
                    <p>Horizon - EBS for Construction consolidates all your business-critical data in one system so you can see exactly what’s going on in real-time. It offers you instant access to the information you need to deliver your construction projects
                        more cost-effectively. It provides real-time data that help contractors reduce risk, improve profitability, and maintain compliance.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Horizon for Construction  PART END ======-->

<!--====== CLIENTS PART START ======-->
<div class="brand-2-area pb-120  dec-spacing  gray-bg">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-12">
                <div class="experience-item">
                    <span>Damy Text</span>
                    <h2 class="title pb-15">Damy Text</h2>
                </div>
            </div> -->
            <div class="col-lg-12">
                <div class="brand-active">
                    <div class="brand-item">
                        <img src="assets/images/clients/lamirada.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/hssg.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/guc.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/ewfi.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/dar.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/bhp.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/bhe.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/atc.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/aac.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/a&m.jpg" alt="brand name">
                    </div>
                </div>
                <!-- brand item -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== CLIENTS PART ENDS ======-->

<!--====== Components PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="experience-item">
                    <span>Solution Modules</span>
                    <h2 class="title pb-15">Components of Construction</h2>
                </div>
            </div>
            <div class="col-lg-6 add-arrow-circle">
                <h5 class="title pb-15">Main Components</h5>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/project-accounting">Project Accounting</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/financial-accounting">Financial Accounting</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/fixed-asset-management">Fixed Asset Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/inventory-management">Procurement and Inventory Control</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/human-resource-management-system">Human Resource Management System (Standard)</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/approval-management">Approval Management System</a></p>
                <!-- experience item -->
            </div>
            <div class="col-lg-6 add-arrow-circle">
                <h5 class="title pb-15">Additional Components</h5>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/construction">Supplier Self Service Portal</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/construction">Approval Management App</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/human-resource-management-system">Human Resource Management System (Advance)</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/pmv-management">Plant, Machinery and Vehicle Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/bank-facility-management">Bank Facility Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/construction">Dashboard Management</a></p>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Components PART END ======-->

<!--====== Benefits at a glance PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom  gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                    <p class="add-arrow"> Unify management of construction accounting, financial management, payroll, projects, and service operations into one database.</p>
                    <p class="add-arrow"> Capture all business content electronically. Eliminate paper and manual process inefficiencies.</p>
                    <p class="add-arrow"> Reduce delays due to shortage of material and equipment by integrating core project management activities with material management, purchase, subcontracting and transportation solutions.</p>
                    <p class="add-arrow"> Gives real-time visibility and control over costs with accurate Budgeting & Accounting which automatically Improves profitability.</p>
                    <p class="add-arrow"> Schedule, deploy and ensure higher utilization of manpower with effective project management.</p>
                    <p class="add-arrow"> Complete visibility of project based resources including assets, inventory, materials and labor.</p>
                    <p class="add-arrow"> Unifies management of construction jobs, work orders, and maintenance contracts</p>
                    <p class="add-arrow"> Simplifies multi-location, multi-company and inter-company accounting</p>
                    <p class="add-arrow"> Empowers decision-makers by placing daily management information at their fingertips in an analytical, intuitive format</p>
                    <p class="add-arrow"> Facilitates union payrolls, including extensive capabilities to define reciprocity rules to accurately pay employees and fund union benefits</p>
                    <p class="add-arrow"> Enter original budgets and maintain revisions in detail; use them as the basis for job costing, revenue, quantities and cash</p>
                    <p class="add-arrow"> Implement alerts and workflow to keep your project managers updated on changes that will affect the success of their projects</p>
                    <p class="add-arrow"> Use reports and business intelligence dashboards to focus on the information and key performance indicators that matter</p>
                    <p>Horizon – EBS for the Construction industry can be deployed on-premise or on the cloud with aligned mobile applications. If you are looking for more details or having queries about Horizon – EBS for Construction, please feel free to
                        Contact Us.</p>

                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Benefits at a glance PART END ======-->
