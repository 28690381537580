<!-- dubai started -->
<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase text-center">
        CPA Case <br />
        Study
      </h3>
    </div>
  </div>
  <div class="container-fluid">
  <div
    class="d-flex row justify-content-center align-items-start pl-4 pr-4"
    style="gap: 2rem"
  >
    <div class="col-lg-8 mt-50 mb-50 border shadow" style="padding: 2rem">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img
          class="img-fluid"
          src="../../../../assets/images/case-studies/CPA.png"
          alt=""
          style="margin-bottom: 2rem"
        />
        <div>
          <div
            style="
              background: #418052;
              display: inline;
              width: fit-content;
              padding: 0.2rem 1rem 0.2rem 1rem;
            "
          >
            <small
              class="text-uppercase text-white"
              style="font-weight: 600 !important; font-size: 15px !important"
              >case details</small
            >
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >overview</span
              >
            </div>
            <p class="text-dark servicep">
              When a Business expands its operations, managing data & documents
              emerge as one of the major problem. A Business also need to
              identify the areas that need to be reworked such as cutting the
              cost in certain field and adding more to another & It is possible
              to establish the long-term goals in Business when you outsource
              some of the back-end accounting operations. By outsourcing all
              your routine accounting functions, you’re able to focus on
              managing and growing your business.
            </p>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >Client</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>CPA firm in Florida</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >Client's Challenges</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Recruiting and retaining qualified resource for tax return
                preparation
              </li>
              <li>Timely & quality delivery</li>
              <li>Reducing the overall payroll cost</li>
              <li>Administrative time & control mechanism</li>
              <li>Providing value added services to the client</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >BFAG Back Office Services</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>Providing & maintaining a steady qualified resource pool</li>
              <li>
                Drafting & implementing a process for activities such as tax
                return preparation, accounting, compilation of financial
                statements
              </li>
              <li>
                Ensuring that a control mechanism is in place there by the
                client good exercise control from anywhere & at all times
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >Impact on Client Business</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                No longer has the client had to worry about hiring and retaining
                the talent
              </li>
              <li>Significant savings in payroll & administrative cost</li>
              <li>Timely & quality delivery</li>
            </ul>
          </div>

        </div>
      </div>
    </div>
    <!--<div
      class="mt-50 border d-flex flex-column justify-content-start align-items-start"
      style="padding: 2rem"
    >
      <div
        class="mt-15 mb-10 d-flex justify-content-center align-items-center"
        style="gap: 5px"
      >
        <div
          class=""
          style="border: 3px solid #072f5f; width: 1.5rem; height: 1px"
        ></div>
        <div
          class=""
          style="border: 3px solid #072f5f; width: 0.5rem; height: 1px"
        ></div>
        <div>
          <span
            class="text-capitalize"
            style="
              font-size: 1.5rem !important;
              font-weight: 700 !important;
              color: #002249 !important;
            "
            >Case Studies</span
          >
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img1.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Facilities <br />
            Management</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 19, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img2.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Specialities Co. <br />
            L.L.C</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 7, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img3.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Specon LLC</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jun 17, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img4.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Al Ahmadiah <br />
            Contracting & Trading</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jun 13, 2020</small
            >
          </div>
        </div>
      </div>
    </div>-->
  </div></div>
</section>
<!-- dubai end -->

<!-- India started -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase text-center">
        CPA Case <br />
        Study
      </h3>
    </div>
  </div>
  <div class="container-fluid">
  <div
    class="d-flex row justify-content-center align-items-start pl-4 pr-4"
    style="gap: 2rem"
  >
    <div class="col-lg-8 mt-50 mb-50 border shadow" style="padding: 2rem">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img
          class="img-fluid"
          src="../../../../assets/images/case-studies/CPA.png"
          alt=""
          style="margin-bottom: 2rem"
        />
        <div>
          <div
            style="
              background: #072f5f;
              display: inline;
              width: fit-content;
              padding: 0.2rem 1rem 0.2rem 1rem;
            "
          >
            <small
              class="text-uppercase text-white"
              style="font-weight: 600 !important; font-size: 15px !important"
              >case details</small
            >
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >overview</span
              >
            </div>
            <p class="text-dark servicep">
              When a Business expands its operations, managing data & documents
              emerge as one of the major problem. A Business also need to
              identify the areas that need to be reworked such as cutting the
              cost in certain field and adding more to another & It is possible
              to establish the long-term goals in Business when you outsource
              some of the back-end accounting operations. By outsourcing all
              your routine accounting functions, you’re able to focus on
              managing and growing your business.
            </p>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Client</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>CPA firm in Florida</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Client's Challenges</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Recruiting and retaining qualified resource for tax return
                preparation
              </li>
              <li>Timely & quality delivery</li>
              <li>Reducing the overall payroll cost</li>
              <li>Administrative time & control mechanism</li>
              <li>Providing value added services to the client</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >BFAG Back Office Services</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>Providing & maintaining a steady qualified resource pool</li>
              <li>
                Drafting & implementing a process for activities such as tax
                return preparation, accounting, compilation of financial
                statements
              </li>
              <li>
                Ensuring that a control mechanism is in place there by the
                client good exercise control from anywhere & at all times
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Impact on Client Business</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                No longer has the client had to worry about hiring and retaining
                the talent
              </li>
              <li>Significant savings in payroll & administrative cost</li>
              <li>Timely & quality delivery</li>
            </ul>
          </div>

        </div>
      </div>
    </div>
    <!--<div
      class="mt-50 border d-flex flex-column justify-content-start align-items-start"
      style="padding: 2rem"
    >
      <div
        class="mt-15 mb-10 d-flex justify-content-center align-items-center"
        style="gap: 5px"
      >
        <div
          class=""
          style="border: 3px solid #072f5f; width: 1.5rem; height: 1px"
        ></div>
        <div
          class=""
          style="border: 3px solid #072f5f; width: 0.5rem; height: 1px"
        ></div>
        <div>
          <span
            class="text-capitalize"
            style="
              font-size: 1.5rem !important;
              font-weight: 700 !important;
              color: #002249 !important;
            "
            >Case Studies</span
          >
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img1.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Facilities <br />
            Management</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 19, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img2.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Specialities Co. <br />
            L.L.C</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 7, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img3.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Specon LLC</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jun 17, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img4.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Al Ahmadiah <br />
            Contracting & Trading</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jun 13, 2020</small
            >
          </div>
        </div>
      </div>
    </div>-->
  </div></div>
</section>
<!-- India end -->

<!-- USA started -->
<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase text-center">
        CPA Case <br />
        Study
      </h3>
    </div>
  </div>
  <div class="container-fluid">
  <div
    class="d-flex row justify-content-center align-items-start pl-4 pr-4"
    style="gap: 2rem"
  >
    <div class="col-lg-8 mt-50 mb-50 border shadow" style="padding: 2rem">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img
          class="img-fluid"
          src="../../../../assets/images/case-studies/CPA.png"
          alt=""
          style="margin-bottom: 2rem"
        />
        <div>
          <div
            style="
              background: #072f5f;
              display: inline;
              width: fit-content;
              padding: 0.2rem 1rem 0.2rem 1rem;
            "
          >
            <small
              class="text-uppercase text-white"
              style="font-weight: 600 !important; font-size: 15px !important"
              >case details</small
            >
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >overview</span
              >
            </div>
            <p class="text-dark servicep">
              When a Business expands its operations, managing data & documents
              emerge as one of the major problem. A Business also need to
              identify the areas that need to be reworked such as cutting the
              cost in certain field and adding more to another & It is possible
              to establish the long-term goals in Business when you outsource
              some of the back-end accounting operations. By outsourcing all
              your routine accounting functions, you’re able to focus on
              managing and growing your business.
            </p>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Client</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>CPA firm in Florida</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Client's Challenges</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Recruiting and retaining qualified resource for tax return
                preparation
              </li>
              <li>Timely & quality delivery</li>
              <li>Reducing the overall payroll cost</li>
              <li>Administrative time & control mechanism</li>
              <li>Providing value added services to the client</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >BFAG Back Office Services</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>Providing & maintaining a steady qualified resource pool</li>
              <li>
                Drafting & implementing a process for activities such as tax
                return preparation, accounting, compilation of financial
                statements
              </li>
              <li>
                Ensuring that a control mechanism is in place there by the
                client good exercise control from anywhere & at all times
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Impact on Client Business</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                No longer has the client had to worry about hiring and retaining
                the talent
              </li>
              <li>Significant savings in payroll & administrative cost</li>
              <li>Timely & quality delivery</li>
            </ul>
          </div>

        </div>
      </div>
    </div>
    <!--<div
      class="mt-50 border d-flex flex-column justify-content-start align-items-start"
      style="padding: 2rem"
    >
      <div
        class="mt-15 mb-10 d-flex justify-content-center align-items-center"
        style="gap: 5px"
      >
        <div
          class=""
          style="border: 3px solid #072f5f; width: 1.5rem; height: 1px"
        ></div>
        <div
          class=""
          style="border: 3px solid #072f5f; width: 0.5rem; height: 1px"
        ></div>
        <div>
          <span
            class="text-capitalize"
            style="
              font-size: 1.5rem !important;
              font-weight: 700 !important;
              color: #002249 !important;
            "
            >Case Studies</span
          >
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img1.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Facilities <br />
            Management</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 19, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img2.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Specialities Co. <br />
            L.L.C</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 7, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img3.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Specon LLC</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jun 17, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="
          width: 18rem;
          gap: 1rem;
          padding: 1rem;
          border-bottom: 2px solid #e3eeff;
        "
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img4.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Al Ahmadiah <br />
            Contracting & Trading</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jun 13, 2020</small
            >
          </div>
        </div>
      </div>
    </div>-->
  </div></div>
</section>
<!-- USA ends -->
<!-- USA ends -->
<!-- USA end -->
