<!-- dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
        <div class="" style="padding: 1%">
          <img
            style="border-radius: 10px; width: 500px;"
            class="img-fluid"
            src="../../../../../assets/images/teams/sanjaycircle.jpeg"
            alt="SANJAY KAPADIA"
          />
        </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start"
        >
        <h3
        class="text-uppercase text-dark title"
        style="font-size: 20px; font-weight: 700;"
      >
            SANJAY KAPADIA
          </h3>
          <span
            class="text-uppercase text-dark"
            style="font-size: 16px; font-weight: 600"
          >
            ADVISOR
            <a
            style="color: #fff"
            href="https://www.linkedin.com/in/sanjaykapadia/"
            target="_blank"
          >
            <i
              class="fab fa-linkedin-in"
              style="
                margin-left: 10px;
                background-color: #0072b1;
                padding: 4px;
                border-radius: 5px;
              "
            ></i
          ></a>
          </span>


          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
            Sanjay Kapadia is a highly accomplished finance and taxation
            professional with expertise in both accounting and law. As a
            distinguished Member of the ICAI and a Law degree holder from Mumbai
            University, he has established himself as a prominent figure in
            financial advisory.He started his career as an Article with and
            later as a Partner with Bansi S. Mehta &amp; Co (1988-1997),
            followed by a stint with the erstwhile Arthur Andersen (1997-1998).
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
            Currently, Sanjay serves as an Advisor at Boston Financial Advisory
            Group, specializing in comprehensive Cross Border Advisory and
            Transfer Pricing solutions. His role involves providing strategic
            guidance and counsel to clients seeking international financial
            assistance. With over 30 years of experience, he has advised
            multinational corporations and Indian businesses on complex tax and
            regulatory issues. Over the course of his career, Sanjay has been
            active in sharing his expertise with the professional community. His
            areas of expertise include Tax Treaties, Permanent Establishment
            concerns, Corporate Restructuring, Transfer Pricing Strategy, and
            more.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        Sanjay is also the Founding Partner of S K M S & Co. LLP, Chartered
        Accountants, a highly regarded firm offering expert guidance on Tax &
        Regulatory matters across industries.
      </p>

      <p
      class="mt-10 text-dark text-justify"
      style="font-size: 16px !important"
    >
    Formerly, Sanjay was a Senior Advisor for India Tax Practice, KNAV
    &amp; Co., partner with B S R &amp; Associates LLP (2016-2019)
  </p>
        </div>
      </div>

      <div class="col-lg-12 pl-0 pr-0">

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        (KPMG), Ernst &amp; Young’s (EY) (2010-2016) International Tax and Transfer
          Pricing practice in Mumbai as lead transfer pricing partner within
          EY’s Mumbai’s Telecommunication, Technology, and Entertainment &amp;
          Media practice, RSM &amp; Co (1998-2007) (the firm, which merged with
          PwC India).
        </p>
        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Sanjay also provides insights on Indian Exchange Control advisory, Tax
          & Regulatory Due Diligence, and Audits. His vast experience led him to
          conduct training sessions and presentations at the Indian Tax
          Department's National Tax Academy. He has also been a presenter and
          panel speaker at various industry and professional forums, discussing
          tax and transfer pricing matters. Additionally, he co-authored the
          Transfer Pricing Manual of the Bombay Chartered Accountants' Society
          and served on the Editorial Board of Taxmann's Monthly Journal on
          International Taxation.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          He has served on Pannell Kerr Foster's International Tax Committee, as
          well as the Corporate and Allied Laws Committee and the Direct Tax
          Committee of the Institute of Chartered Accountants of India. Sanjay
          Kapadia is recognized as one of the premier tax and regulatory
          advisor, establishing successful businesses within and outside the
          country wealth of experience and unwavering commitment to excellence
          in finance, taxation, and law.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- dubai ends -->

<!-- India started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
        <div class="" style="padding: 1%">
          <img
            style="border-radius: 10px; width: 500px;"
            class="img-fluid"
            src="../../../../../assets/images/teams/sanjaycircle.jpeg"
            alt="SANJAY KAPADIA"
          />
        </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start"
        >
        <h3
        class="text-uppercase text-dark title"
        style="font-size: 20px; font-weight: 700;"
      >
            SANJAY KAPADIA
          </h3>
          <span
            class="text-uppercase text-dark"
            style="font-size: 16px; font-weight: 600"
          >
            ADVISOR
            <a
            style="color: #fff"
            href="https://www.linkedin.com/in/sanjaykapadia/"
            target="_blank"
          >
            <i
              class="fab fa-linkedin-in"
              style="
                margin-left: 10px;
                background-color: #0072b1;
                padding: 4px;
                border-radius: 5px;
              "
            ></i
          ></a>
          </span>


          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
            Sanjay Kapadia is a highly accomplished finance and taxation
            professional with expertise in both accounting and law. As a
            distinguished Member of the ICAI and a Law degree holder from Mumbai
            University, he has established himself as a prominent figure in
            financial advisory.He started his career as an Article with and
            later as a Partner with Bansi S. Mehta &amp; Co (1988-1997),
            followed by a stint with the erstwhile Arthur Andersen (1997-1998).
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
            Currently, Sanjay serves as an Advisor at Boston Financial Advisory
            Group, specializing in comprehensive Cross Border Advisory and
            Transfer Pricing solutions. His role involves providing strategic
            guidance and counsel to clients seeking international financial
            assistance. With over 30 years of experience, he has advised
            multinational corporations and Indian businesses on complex tax and
            regulatory issues. Over the course of his career, Sanjay has been
            active in sharing his expertise with the professional community. His
            areas of expertise include Tax Treaties, Permanent Establishment
            concerns, Corporate Restructuring, Transfer Pricing Strategy, and
            more.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        Sanjay is also the Founding Partner of S K M S & Co. LLP, Chartered
        Accountants, a highly regarded firm offering expert guidance on Tax &
        Regulatory matters across industries.
      </p>

      <p
      class="mt-10 text-dark text-justify"
      style="font-size: 16px !important"
    >
    Formerly, Sanjay was a Senior Advisor for India Tax Practice, KNAV
    &amp; Co., partner with B S R &amp; Associates LLP (2016-2019)
  </p>
        </div>
      </div>

      <div class="col-lg-12 pl-0 pr-0">

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        (KPMG), Ernst &amp; Young’s (EY) (2010-2016) International Tax and Transfer
          Pricing practice in Mumbai as lead transfer pricing partner within
          EY’s Mumbai’s Telecommunication, Technology, and Entertainment &amp;
          Media practice, RSM &amp; Co (1998-2007) (the firm, which merged with
          PwC India).
        </p>
        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Sanjay also provides insights on Indian Exchange Control advisory, Tax
          & Regulatory Due Diligence, and Audits. His vast experience led him to
          conduct training sessions and presentations at the Indian Tax
          Department's National Tax Academy. He has also been a presenter and
          panel speaker at various industry and professional forums, discussing
          tax and transfer pricing matters. Additionally, he co-authored the
          Transfer Pricing Manual of the Bombay Chartered Accountants' Society
          and served on the Editorial Board of Taxmann's Monthly Journal on
          International Taxation.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          He has served on Pannell Kerr Foster's International Tax Committee, as
          well as the Corporate and Allied Laws Committee and the Direct Tax
          Committee of the Institute of Chartered Accountants of India. Sanjay
          Kapadia is recognized as one of the premier tax and regulatory
          advisor, establishing successful businesses within and outside the
          country wealth of experience and unwavering commitment to excellence
          in finance, taxation, and law.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- india ends -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
        <div class="" style="padding: 1%">
          <img
            style="border-radius: 10px; width: 500px;"
            class="img-fluid"
            src="../../../../../assets/images/teams/sanjaycircle.jpeg"
            alt="SANJAY KAPADIA"
          />
        </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start"
        >
        <h3
        class="text-uppercase text-dark title"
        style="font-size: 20px; font-weight: 700;"
      >
            SANJAY KAPADIA
          </h3>
          <span
            class="text-uppercase text-dark"
            style="font-size: 16px; font-weight: 600"
          >
            ADVISOR
            <a
            style="color: #fff"
            href="https://www.linkedin.com/in/sanjaykapadia/"
            target="_blank"
          >
            <i
              class="fab fa-linkedin-in"
              style="
                margin-left: 10px;
                background-color: #0072b1;
                padding: 4px;
                border-radius: 5px;
              "
            ></i
          ></a>
          </span>


          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
            Sanjay Kapadia is a highly accomplished finance and taxation
            professional with expertise in both accounting and law. As a
            distinguished Member of the ICAI and a Law degree holder from Mumbai
            University, he has established himself as a prominent figure in
            financial advisory.He started his career as an Article with and
            later as a Partner with Bansi S. Mehta &amp; Co (1988-1997),
            followed by a stint with the erstwhile Arthur Andersen (1997-1998).
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
            Currently, Sanjay serves as an Advisor at Boston Financial Advisory
            Group, specializing in comprehensive Cross Border Advisory and
            Transfer Pricing solutions. His role involves providing strategic
            guidance and counsel to clients seeking international financial
            assistance. With over 30 years of experience, he has advised
            multinational corporations and Indian businesses on complex tax and
            regulatory issues. Over the course of his career, Sanjay has been
            active in sharing his expertise with the professional community. His
            areas of expertise include Tax Treaties, Permanent Establishment
            concerns, Corporate Restructuring, Transfer Pricing Strategy, and
            more.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        Sanjay is also the Founding Partner of S K M S & Co. LLP, Chartered
        Accountants, a highly regarded firm offering expert guidance on Tax &
        Regulatory matters across industries.
      </p>

      <p
      class="mt-10 text-dark text-justify"
      style="font-size: 16px !important"
    >
    Formerly, Sanjay was a Senior Advisor for India Tax Practice, KNAV
    &amp; Co., partner with B S R &amp; Associates LLP (2016-2019)
  </p>
        </div>
      </div>

      <div class="col-lg-12 pl-0 pr-0">

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        (KPMG), Ernst &amp; Young’s (EY) (2010-2016) International Tax and Transfer
          Pricing practice in Mumbai as lead transfer pricing partner within
          EY’s Mumbai’s Telecommunication, Technology, and Entertainment &amp;
          Media practice, RSM &amp; Co (1998-2007) (the firm, which merged with
          PwC India).
        </p>
        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Sanjay also provides insights on Indian Exchange Control advisory, Tax
          & Regulatory Due Diligence, and Audits. His vast experience led him to
          conduct training sessions and presentations at the Indian Tax
          Department's National Tax Academy. He has also been a presenter and
          panel speaker at various industry and professional forums, discussing
          tax and transfer pricing matters. Additionally, he co-authored the
          Transfer Pricing Manual of the Bombay Chartered Accountants' Society
          and served on the Editorial Board of Taxmann's Monthly Journal on
          International Taxation.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          He has served on Pannell Kerr Foster's International Tax Committee, as
          well as the Corporate and Allied Laws Committee and the Direct Tax
          Committee of the Institute of Chartered Accountants of India. Sanjay
          Kapadia is recognized as one of the premier tax and regulatory
          advisor, establishing successful businesses within and outside the
          country wealth of experience and unwavering commitment to excellence
          in finance, taxation, and law.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- USA ends -->
