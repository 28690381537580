<!-- dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150">
  <div
    class="col-lg-12 m-0 d-flex flex-column justify-content-center align-items-center"
    style="background: #21ac5133; height: 25vh"
  >
  <h4 style="border-bottom: 4px solid #418052; display: inline-block">
    NOTICE
  </h4>
  <small style="font-size: 10px"
    ><a class="text-dark" routerLink="/"
      >HOME
      <i class="fas fa-arrow-circle-right"></i>
      NOTICE</a
    ></small
  >
  </div>
  <div class="p-5 d-flex flex-column justify-content-center align-items-center">
    <img class="img-fluid" src="../../../../assets/images/Fraud.png" alt="">
    <img class="img-fluid" src="../../../../assets/images/LCA-Posting-Notice-USA-1.jpg" alt="">
  </div>
</div>
<!-- dubai end -->


<!-- dubai started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150">
  <div
    class="col-lg-12 m-0 d-flex flex-column justify-content-center align-items-center"
    style="background: #dcecff; height: 25vh"
  >
  <h4 style="border-bottom: 4px solid #072F5F; display: inline-block">
    NOTICE
  </h4>
  <small style="font-size: 10px"
    ><a class="text-dark" routerLink="/"
      >HOME
      <i class="fas fa-arrow-circle-right"></i>
      NOTICE</a
    ></small
  >
  </div>
  <div class="p-5 d-flex flex-column justify-content-center align-items-center">
    <img class="img-fluid" src="../../../../assets/images/Fraud.png" alt="">
    <img class="img-fluid" src="../../../../assets/images/LCA-Posting-Notice-USA-1.jpg" alt="">
  </div>
</div>
<!-- dubai end -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150">
  <div
    class="col-lg-12 m-0 d-flex flex-column justify-content-center align-items-center"
    style="background: #dcecff; height: 25vh"
  >
  <h4 style="border-bottom: 4px solid #072F5F; display: inline-block">
    NOTICE
  </h4>
  <small style="font-size: 10px"
    ><a class="text-dark" routerLink="/"
      >HOME
      <i class="fas fa-arrow-circle-right"></i>
      NOTICE</a
    ></small
  >
  </div>
  <div class="p-5 d-flex flex-column justify-content-center align-items-center">
    <img class="img-fluid" src="../../../../assets/images/Fraud.png" alt="">
    <img class="img-fluid" src="../../../../assets/images/LCA-Posting-Notice-USA-1.jpg" alt="">
  </div>
</div>
<!-- USA end -->
