<!-- Dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100">
  <div class="min-vh-100 mt-150 divmart">
    <div class="d-flex justify-content-center align-items-center banner-image" style="
      background: url('../../../../assets/images/media\ hits/media.png') no-repeat center;
      height: 430px;
      background-size: cover;
    ">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase">Media Hits</h3>

      </div>
    </div>

    <div class="container-fluid pt-4">
      <div class="row  d-flex justify-content-start" style="margin-right:0px !important; padding-right:0px !important">


        <!-- //Before
          <div class=" col-lg-8" style="
          overflow: hidden;
          border-radius: 17px;
          padding-left: 15px;
          padding-right: 15px;"
      ><img class="img-fluid"src="../../../../assets/images/media hits/Media hits 1.png" alt="img"/>
       <button style="background-color:#418052; color: white;" class="btn  btn-lg float-right mt-15 mb-15" type="submit">Next</button>
        </div>  -->

        <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12" id="vendor_chk">

          <div id="borderlinestyle" style="margin-bottom: 10px;"><img alt="Separator" title="Separator"
              style="width: 100%;" src="../../../../assets/images/media hits/line.png"></div>
          <div class="col-md-12" id="company-title">
            <h1 style="font-size: 24px;">{{data.heading}}
            </h1>
          </div>
          <div id="borderlinestyle" style="margin-bottom: 10px;"><img alt="Separator" title="Separator"
              style="width: 100%;" src="../../../../assets/images/media hits/line.png"></div>

          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-lg-4 d-flex justify-content-center align-items-center justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3">
              <div class="text_style">
                <div class="text-center">
                  <img alt="Sameer Paddalwar,Executive Director&amp;CEO"
                  title="Sameer Paddalwar,Executive Director&amp;CEO"
                  [src]="data.img">
              <div class="profile-text">
                <a routerLink="/sameer-paddalwar">
                  <h3 class="profile-img-head-text" style="margin-top: 5px;margin-bottom: 10px;">{{data.profile_header}}</h3>
                </a>
                <h4 class="profile-img-head-p">{{data.profile_post}}</h4>
              </div>
                </div>
                
              </div>
            </div>
            <div  class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start">
              <p style="color:#333; text-align: justify;">
                {{data.first_description}}
              </p>
            </div>
          </div>
          <ng-container *ngIf="!subarray">
            <div>
              <p style="color:#333; text-align: justify;">
                {{data.second_description}}
              </p>
            </div>
          </ng-container>
          <ng-container *ngIf="subarray">
            <div *ngFor="let subtitle of subarray">
              <div class="mt-3">
                <span
                  class="text-capitalize"
                  style="
                    font-size: 1.5rem !important;
                    font-weight: 700 !important;
                    color: #0951A4;
                  "
                  >{{subtitle.title}}</span
                >
              </div>
              <p class="text-dark servicep mb-2 mt-2" style="white-space: pre-line;">
                {{subtitle.description}}
              </p>
            </div>
          </ng-container>
        </div>

        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
          <h5 class="pb-2">RELATED NEWS</h5>
          <div class="d-flex">
            <div class="justify-content-center" style="width: 100%;">

              <!-- <a href="https://consultants.siliconindia.com/vendor/boston-financial-holding-smes--fa-strings-right-enough-to-let-them-fly-cid-256.html" >
            <div class="d-flex flex-row mb-15" style="gap: 0.5rem">
              <div>
                <img
                  class="img-fluid"
                  src="../../../../assets/images/media hits/PT-26-1.jpg"
                  alt="image"
                  style="width: 17rem"
                />
              </div>
              <div class="">
                <small  style="color: #418052;"><a>Posted On 27 Apr 2022  <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/ admin@bfag</a></small>
                <p style="line-height: normal; color:#418052A1">
                  Some quick example text to build on the card title and make up the
                  bulk of the card's content.
                </p>
              </div>
            </div></a> -->
            <ng-container *ngFor="let news of relatedNews">
              <a href="{{news.news_link}}"
                        target="_blank">  <div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" [src]="news.news_img" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color:#418052;">
                        <a href="{{news.news_link}}"
                          target="_blank" style="
                        color: #418052;
                      ">Posted On {{news.posted_on}} <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          {{news.author}}</a>
                      </small>
                      <a href="{{news.news_link}}"
                        target="_blank" style="
                      color: #418052A1;
                    ">
                        <p class="rn-text" style="line-height: normal;  color: #418052A1">
                          {{news.news_title}}
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a>
            </ng-container>
            <ng-container *ngFor="let news of mediahits">
                <div class="mb-15">
                <div class="cardsnews" (click)="selectMediaHit(news)">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" [src]="news.imgFile.secure_url" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color:#418052;">
                        Posted On {{news.createdAt}} <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          {{news.author}} 
                      </small>
                      
                        <p class="rn-text" style="line-height: normal;  color: #418052A1">
                          {{news.title}}
                        </p>
                       
                    </div>
                  </div>
                </div>
              </div> 
            </ng-container>

            <!-- <a href="https://consultants.siliconindia.com/vendor/boston-financial-holding-smes--fa-strings-right-enough-to-let-them-fly-cid-256.html"
                        target="_blank">  <div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" src="../../../../assets/images/media hits/PT-26-1.jpg" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color:#418052;">
                        <a href="https://consultants.siliconindia.com/vendor/boston-financial-holding-smes--fa-strings-right-enough-to-let-them-fly-cid-256.html"
                          target="_blank" style="
                        color: #418052;
                      ">Posted On 27 Apr 2022 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          admin@bfag</a>
                      </small>
                      <a href="https://consultants.siliconindia.com/vendor/boston-financial-holding-smes--fa-strings-right-enough-to-let-them-fly-cid-256.html"
                        target="_blank" style="
                      color: #418052A1;
                    ">
                        <p class="rn-text" style="line-height: normal;  color: #418052A1">
                          Boston Financial: Holding SMEs' F&A Strings Right Enough to Let Them Fly
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a>


              <a href="https://themanifest.com/accounting/financial/firms/boston" target="_blank"><div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" src="../../../../assets/images/media hits/all-media.jpg" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <p style="color:#418052;">
                        <a href="https://themanifest.com/accounting/financial/firms/boston" target="_blank" style="
                      color: #418052;
                    ">Posted On June 2023 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          admin@bfag</a>
                      </p>
                      <a href="https://themanifest.com/accounting/financial/firms/boston" target="_blank" style="
                    color: #418052A1;
                  ">
                        <p class="rn-text" style="line-height: normal;  color: #418052A1">
                          Listed as top 25th accounting firm in Boston
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a>


              <a href="https://www.prnewswire.com/in/news-releases/tech-driven-accounting-amp-financial-consulting-services-to-help-brands-during-crisis-832660697.html"
              target="_blank"><div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" src="../../../../assets/images/media hits/PT-27-1.jpg" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color: #418052;">
                        <a href="https://www.prnewswire.com/in/news-releases/tech-driven-accounting-amp-financial-consulting-services-to-help-brands-during-crisis-832660697.html"
                          target="_blank" style="
              color: #418052;
            ">Posted On 24 March 2020 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          admin@bfag</a>
                      </small>
                      <a href="https://www.prnewswire.com/in/news-releases/tech-driven-accounting-amp-financial-consulting-services-to-help-brands-during-crisis-832660697.html"
                        target="_blank" style="
            color: #418052A1;
          ">
                        <p class="rn-text" style="line-height: normal;  color: #418052A1">
                          Tech-driven Accounting & Financial Consulting Services to Help Brands During Crisis
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a>

              <a href="https://www.tmcnet.com/usubmit/2020/03/24/9119676.htm" target="_blank"><div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem;">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" src="../../../../assets/images/media hits/PT-11.jpg" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color: #418052;">
                        <a href="https://www.tmcnet.com/usubmit/2020/03/24/9119676.htm" target="_blank" style="
                  color: #418052;
                ">Posted On 24 March 2020 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          admin@bfag</a>
                      </small>
                      <a href="https://www.tmcnet.com/usubmit/2020/03/24/9119676.htm" target="_blank" style="
                color: #418052A1;
              ">
                        <p class="rn-text" style="line-height: normal;  color: #418052A1">
                          Tech-driven Accounting & Financial Consulting Services to Help Brands During Crisis
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a> -->
            </div>
          </div>


        </div>
      </div>
    </div>

  </div>
</div>
<!-- Dubai end -->

<!-- India started #072F5F -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 ">
  <div class="min-vh-100 mt-150 divmart">
    <div class="d-flex justify-content-center align-items-center banner-image" style="
      background: url('../../../../assets/images/media\ hits/media.png') no-repeat center;
      height: 430px;
      background-size: cover;
    ">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase">Media Hits</h3>

      </div>
    </div>

    <div class="container-fluid pt-4">
      <div class="row  d-flex justify-content-start" style="margin-right:0px !important; padding-right:0px !important">


        <!-- //Before
          <div class=" col-lg-8" style="
          overflow: hidden;
          border-radius: 17px;
          padding-left: 15px;
          padding-right: 15px;"
      ><img class="img-fluid"src="../../../../assets/images/media hits/Media hits 1.png" alt="img"/>
       <button style="background-color:#418052; color: white;" class="btn  btn-lg float-right mt-15 mb-15" type="submit">Next</button>
        </div>  -->

        <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12" id="vendor_chk">

          <div id="borderlinestyle" style="margin-bottom: 10px;"><img alt="Separator" title="Separator"
              style="width: 100%;" src="../../../../assets/images/media hits/line.png"></div>
          <div class="col-md-12" id="company-title">
            <h1 style="font-size: 24px;">{{data.heading}}
            </h1>
          </div>
          <div id="borderlinestyle" style="margin-bottom: 10px;"><img alt="Separator" title="Separator"
              style="width: 100%;" src="../../../../assets/images/media hits/line.png"></div>

          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-lg-4 d-flex justify-content-center align-items-center justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3">
              <div class="text_style">
                <div class="text-center">
                  <img alt="Sameer Paddalwar,Executive Director&amp;CEO"
                  title="Sameer Paddalwar,Executive Director&amp;CEO"
                  [src]="data.img">
              <div class="profile-text">
                <a routerLink="/sameer-paddalwar">
                  <h3 class="profile-img-head-text" style="margin-top: 5px;margin-bottom: 10px;">{{data.profile_header}}</h3>
                </a>
                <h4 class="profile-img-head-p">{{data.profile_post}}</h4>
              </div>
                </div>
                
              </div>
            </div>
            <div  class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start">
              <p style="color:#333; text-align: justify;">
                {{data.first_description}}
              </p>
            </div>
          </div>
          <ng-container *ngIf="!subarray">
            <div>
              <p style="color:#333; text-align: justify;">
                {{data.second_description}}
              </p>
            </div>
          </ng-container>
          <ng-container *ngIf="subarray">
            <div *ngFor="let subtitle of subarray">
              <div class="mt-3">
                <span
                  class="text-capitalize"
                  style="
                    font-size: 1.5rem !important;
                    font-weight: 700 !important;
                    color: #0951A4;
                  "
                  >{{subtitle.title}}</span
                >
              </div>
              <p class="text-dark servicep mb-2 mt-2" style="white-space: pre-line;">
                {{subtitle.description}}
              </p>
            </div>
          </ng-container>
        </div>

        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
          <h5 class="pb-2">RELATED NEWS</h5>
          <div class="d-flex">
            <div class="justify-content-center" style="width: 100%;">

              <!-- <a href="https://consultants.siliconindia.com/vendor/boston-financial-holding-smes--fa-strings-right-enough-to-let-them-fly-cid-256.html" >
            <div class="d-flex flex-row mb-15" style="gap: 0.5rem">
              <div>
                <img
                  class="img-fluid"
                  src="../../../../assets/images/media hits/PT-26-1.jpg"
                  alt="image"
                  style="width: 17rem"
                />
              </div>
              <div class="">
                <small  style="color: #418052;"><a>Posted On 27 Apr 2022  <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/ admin@bfag</a></small>
                <p style="line-height: normal; color:#418052A1">
                  Some quick example text to build on the card title and make up the
                  bulk of the card's content.
                </p>
              </div>
            </div></a> -->
            <ng-container *ngFor="let news of relatedNews">
              <a href="{{news.news_link}}"
                        target="_blank">  <div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" [src]="news.news_img" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color:#072F5F;">
                        <a href="{{news.news_link}}"
                          target="_blank" style="
                        color: #072F5F;
                      ">Posted On {{news.posted_on}} <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          {{news.author}}</a>
                      </small>
                      <a href="{{news.news_link}}"
                        target="_blank" style="
                      color: #072F5FA1;
                    ">
                        <p class="rn-text" style="line-height: normal;  color: #072F5FA1">
                          {{news.news_title}}
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a>
            </ng-container>
            <ng-container *ngFor="let news of mediahits">
                <div class="mb-15">
                <div class="cardsnews" (click)="selectMediaHit(news)">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" [src]="news.imgFile.secure_url" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color:#072F5F;">
                        Posted On {{news.createdAt}} <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          {{news.author}} 
                      </small>
                      
                        <p class="rn-text" style="line-height: normal;  color: #072F5FA1">
                          {{news.title}}
                        </p>
                       
                    </div>
                  </div>
                </div>
              </div> 
            </ng-container>

            <!-- <a href="https://consultants.siliconindia.com/vendor/boston-financial-holding-smes--fa-strings-right-enough-to-let-them-fly-cid-256.html"
                        target="_blank">  <div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" src="../../../../assets/images/media hits/PT-26-1.jpg" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color:#418052;">
                        <a href="https://consultants.siliconindia.com/vendor/boston-financial-holding-smes--fa-strings-right-enough-to-let-them-fly-cid-256.html"
                          target="_blank" style="
                        color: #418052;
                      ">Posted On 27 Apr 2022 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          admin@bfag</a>
                      </small>
                      <a href="https://consultants.siliconindia.com/vendor/boston-financial-holding-smes--fa-strings-right-enough-to-let-them-fly-cid-256.html"
                        target="_blank" style="
                      color: #418052A1;
                    ">
                        <p class="rn-text" style="line-height: normal;  color: #418052A1">
                          Boston Financial: Holding SMEs' F&A Strings Right Enough to Let Them Fly
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a>


              <a href="https://themanifest.com/accounting/financial/firms/boston" target="_blank"><div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" src="../../../../assets/images/media hits/all-media.jpg" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <p style="color:#418052;">
                        <a href="https://themanifest.com/accounting/financial/firms/boston" target="_blank" style="
                      color: #418052;
                    ">Posted On June 2023 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          admin@bfag</a>
                      </p>
                      <a href="https://themanifest.com/accounting/financial/firms/boston" target="_blank" style="
                    color: #418052A1;
                  ">
                        <p class="rn-text" style="line-height: normal;  color: #418052A1">
                          Listed as top 25th accounting firm in Boston
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a>


              <a href="https://www.prnewswire.com/in/news-releases/tech-driven-accounting-amp-financial-consulting-services-to-help-brands-during-crisis-832660697.html"
              target="_blank"><div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" src="../../../../assets/images/media hits/PT-27-1.jpg" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color: #418052;">
                        <a href="https://www.prnewswire.com/in/news-releases/tech-driven-accounting-amp-financial-consulting-services-to-help-brands-during-crisis-832660697.html"
                          target="_blank" style="
              color: #418052;
            ">Posted On 24 March 2020 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          admin@bfag</a>
                      </small>
                      <a href="https://www.prnewswire.com/in/news-releases/tech-driven-accounting-amp-financial-consulting-services-to-help-brands-during-crisis-832660697.html"
                        target="_blank" style="
            color: #418052A1;
          ">
                        <p class="rn-text" style="line-height: normal;  color: #418052A1">
                          Tech-driven Accounting & Financial Consulting Services to Help Brands During Crisis
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a>

              <a href="https://www.tmcnet.com/usubmit/2020/03/24/9119676.htm" target="_blank"><div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem;">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" src="../../../../assets/images/media hits/PT-11.jpg" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color: #418052;">
                        <a href="https://www.tmcnet.com/usubmit/2020/03/24/9119676.htm" target="_blank" style="
                  color: #418052;
                ">Posted On 24 March 2020 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          admin@bfag</a>
                      </small>
                      <a href="https://www.tmcnet.com/usubmit/2020/03/24/9119676.htm" target="_blank" style="
                color: #418052A1;
              ">
                        <p class="rn-text" style="line-height: normal;  color: #418052A1">
                          Tech-driven Accounting & Financial Consulting Services to Help Brands During Crisis
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a> -->
            </div>
          </div>


        </div>
      </div>
    </div>

  </div>
</div>
<!-- India end -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 ">
  <div class="min-vh-100 mt-150 divmart">
    <div class="d-flex justify-content-center align-items-center banner-image" style="
      background: url('../../../../assets/images/media\ hits/media.png') no-repeat center;
      height: 430px;
      background-size: cover;
    ">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase">Media Hits</h3>

      </div>
    </div>

    <div class="container-fluid pt-4">
      <div class="row  d-flex justify-content-start" style="margin-right:0px !important; padding-right:0px !important">


        <!-- //Before
          <div class=" col-lg-8" style="
          overflow: hidden;
          border-radius: 17px;
          padding-left: 15px;
          padding-right: 15px;"
      ><img class="img-fluid"src="../../../../assets/images/media hits/Media hits 1.png" alt="img"/>
       <button style="background-color:#418052; color: white;" class="btn  btn-lg float-right mt-15 mb-15" type="submit">Next</button>
        </div>  -->

        <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12" id="vendor_chk">

          <div id="borderlinestyle" style="margin-bottom: 10px;"><img alt="Separator" title="Separator"
              style="width: 100%;" src="../../../../assets/images/media hits/line.png"></div>
          <div class="col-md-12" id="company-title">
            <h1 style="font-size: 24px;">{{data.heading}}
            </h1>
          </div>
          <div id="borderlinestyle" style="margin-bottom: 10px;"><img alt="Separator" title="Separator"
              style="width: 100%;" src="../../../../assets/images/media hits/line.png"></div>

          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-lg-4 d-flex justify-content-center align-items-center justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3">
              <div class="text_style">
                <div class="text-center">
                  <img alt="Sameer Paddalwar,Executive Director&amp;CEO"
                  title="Sameer Paddalwar,Executive Director&amp;CEO"
                  [src]="data.img">
              <div class="profile-text">
                <a routerLink="/sameer-paddalwar">
                  <h3 class="profile-img-head-text" style="margin-top: 5px;margin-bottom: 10px;">{{data.profile_header}}</h3>
                </a>
                <h4 class="profile-img-head-p">{{data.profile_post}}</h4>
              </div>
                </div>
                
              </div>
            </div>
            <div  class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start">
              <p style="color:#333; text-align: justify;">
                {{data.first_description}}
              </p>
            </div>
          </div>
          <ng-container *ngIf="!subarray">
            <div>
              <p style="color:#333; text-align: justify;">
                {{data.second_description}}
              </p>
            </div>
          </ng-container>
          <ng-container *ngIf="subarray">
            <div *ngFor="let subtitle of subarray">
              <div class="mt-3">
                <span
                  class="text-capitalize"
                  style="
                    font-size: 1.5rem !important;
                    font-weight: 700 !important;
                    color: #0951A4;
                  "
                  >{{subtitle.title}}</span
                >
              </div>
              <p class="text-dark servicep mb-2 mt-2" style="white-space: pre-line;">
                {{subtitle.description}}
              </p>
            </div>
          </ng-container>
        </div>

        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
          <h5 class="pb-2">RELATED NEWS</h5>
          <div class="d-flex">
            <div class="justify-content-center" style="width: 100%;">

              <!-- <a href="https://consultants.siliconindia.com/vendor/boston-financial-holding-smes--fa-strings-right-enough-to-let-them-fly-cid-256.html" >
            <div class="d-flex flex-row mb-15" style="gap: 0.5rem">
              <div>
                <img
                  class="img-fluid"
                  src="../../../../assets/images/media hits/PT-26-1.jpg"
                  alt="image"
                  style="width: 17rem"
                />
              </div>
              <div class="">
                <small  style="color: #418052;"><a>Posted On 27 Apr 2022  <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/ admin@bfag</a></small>
                <p style="line-height: normal; color:#418052A1">
                  Some quick example text to build on the card title and make up the
                  bulk of the card's content.
                </p>
              </div>
            </div></a> -->
            <ng-container *ngFor="let news of relatedNews">
              <a href="{{news.news_link}}"
                        target="_blank">  <div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" [src]="news.news_img" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color:#072F5F;">
                        <a href="{{news.news_link}}"
                          target="_blank" style="
                        color: #072F5F;
                      ">Posted On {{news.posted_on}} <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          {{news.author}}</a>
                      </small>
                      <a href="{{news.news_link}}"
                        target="_blank" style="
                      color: #072F5FA1;
                    ">
                        <p class="rn-text" style="line-height: normal;  color: #072F5FA1">
                          {{news.news_title}}
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a>
            </ng-container>
            <ng-container *ngFor="let news of mediahits">
                <div class="mb-15">
                <div class="cardsnews" (click)="selectMediaHit(news)">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" [src]="news.imgFile.secure_url" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color:#072F5F;">
                        Posted On {{news.createdAt}} <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          {{news.author}} 
                      </small>
                      
                        <p class="rn-text" style="line-height: normal;  color: #072F5FA1">
                          {{news.title}}
                        </p>
                       
                    </div>
                  </div>
                </div>
              </div> 
            </ng-container>

            <!-- <a href="https://consultants.siliconindia.com/vendor/boston-financial-holding-smes--fa-strings-right-enough-to-let-them-fly-cid-256.html"
                        target="_blank">  <div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" src="../../../../assets/images/media hits/PT-26-1.jpg" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color:#418052;">
                        <a href="https://consultants.siliconindia.com/vendor/boston-financial-holding-smes--fa-strings-right-enough-to-let-them-fly-cid-256.html"
                          target="_blank" style="
                        color: #418052;
                      ">Posted On 27 Apr 2022 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          admin@bfag</a>
                      </small>
                      <a href="https://consultants.siliconindia.com/vendor/boston-financial-holding-smes--fa-strings-right-enough-to-let-them-fly-cid-256.html"
                        target="_blank" style="
                      color: #418052A1;
                    ">
                        <p class="rn-text" style="line-height: normal;  color: #418052A1">
                          Boston Financial: Holding SMEs' F&A Strings Right Enough to Let Them Fly
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a>


              <a href="https://themanifest.com/accounting/financial/firms/boston" target="_blank"><div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" src="../../../../assets/images/media hits/all-media.jpg" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <p style="color:#418052;">
                        <a href="https://themanifest.com/accounting/financial/firms/boston" target="_blank" style="
                      color: #418052;
                    ">Posted On June 2023 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          admin@bfag</a>
                      </p>
                      <a href="https://themanifest.com/accounting/financial/firms/boston" target="_blank" style="
                    color: #418052A1;
                  ">
                        <p class="rn-text" style="line-height: normal;  color: #418052A1">
                          Listed as top 25th accounting firm in Boston
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a>


              <a href="https://www.prnewswire.com/in/news-releases/tech-driven-accounting-amp-financial-consulting-services-to-help-brands-during-crisis-832660697.html"
              target="_blank"><div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem; ">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" src="../../../../assets/images/media hits/PT-27-1.jpg" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color: #418052;">
                        <a href="https://www.prnewswire.com/in/news-releases/tech-driven-accounting-amp-financial-consulting-services-to-help-brands-during-crisis-832660697.html"
                          target="_blank" style="
              color: #418052;
            ">Posted On 24 March 2020 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          admin@bfag</a>
                      </small>
                      <a href="https://www.prnewswire.com/in/news-releases/tech-driven-accounting-amp-financial-consulting-services-to-help-brands-during-crisis-832660697.html"
                        target="_blank" style="
            color: #418052A1;
          ">
                        <p class="rn-text" style="line-height: normal;  color: #418052A1">
                          Tech-driven Accounting & Financial Consulting Services to Help Brands During Crisis
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a>

              <a href="https://www.tmcnet.com/usubmit/2020/03/24/9119676.htm" target="_blank"><div class="mb-15">
                <div class="cardsnews">
                  <div class="d-flex flex-row p-2" style="gap: 0.10rem;">
                    <div class="col-4 col-xl-4 col-sm-4 col-md-3 col-lg-4 pl-0 pr-0 ">
                      <img class="img-fluid" src="../../../../assets/images/media hits/PT-11.jpg" alt="image"
                        style="width: 100%" />
                    </div>
                    <div class="col-8 col-xl-8 col-sm-8 col-md-9 col-lg-8 pl-2 ">
                      <small style="color: #418052;">
                        <a href="https://www.tmcnet.com/usubmit/2020/03/24/9119676.htm" target="_blank" style="
                  color: #418052;
                ">Posted On 24 March 2020 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/
                          admin@bfag</a>
                      </small>
                      <a href="https://www.tmcnet.com/usubmit/2020/03/24/9119676.htm" target="_blank" style="
                color: #418052A1;
              ">
                        <p class="rn-text" style="line-height: normal;  color: #418052A1">
                          Tech-driven Accounting & Financial Consulting Services to Help Brands During Crisis
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div></a> -->
            </div>
          </div>


        </div>
      </div>
    </div>

  </div>
</div>
<!-- USA end -->
