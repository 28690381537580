import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-horizon-ebs',
  templateUrl: './horizon-ebs.component.html',
  styleUrls: ['./horizon-ebs.component.css']
})
export class HorizonEbsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
