<!-- dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      CFO SERVICES & DEBT SYNDICATION
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> CFO SERVICES & DEBT
        SYNDICATION</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/CFO_Services.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 8rem"
        />
        <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        CFOs today play a vital role in any company and the role of a CFO reach
        now extends far beyond the traditional role of reporting finances. Our
        services in this regard are fully customized to ensure priority
        organizational needs such as strategic planning, process development,
        process optimization, internal controls, fund raising are well taken
        care.
      </p>
      <div>
        <h5 class="mb-20" style="color: green; ">
          TODAY, CFOS RESPONSIBILITIES INCLUDE:
        </h5>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Forging proactive and value-added partnerships that support
            decision-making throughout the company
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Maximizing utilization of resources to continually improve the
            company’s efficiency
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Developing and managing your company’s business intelligence
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Strategic Planning, Profitability Improvement, Cash Flow Management
            & Forecasting
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Operating Plan & Performance Management, Board reporting
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Charting the future course of the company in terms of growth, both
            organically and inorganically
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <p
            class="text-dark mb-30"
            style="font-size: 20px; line-height: normal; font-weight: 500"
          >
            The senior leadership team at BFAG has developed a unique set of
            services addressing these growing demands in the form of strategies
            blended with technology that enable CFOs to:
          </p>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Capitalize on current IT infrastructure to create a single source
              for integrating financial and operating information
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Reduce the total cost of the finance function by increasing the
              time spent on analysis as against transaction processing
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Improve overall corporate performance by improving core finance
              process
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              SGain access to real-time management information to aid decision
              making at the click of a button.
            </li>
          </ul>
          <p
            class="text-dark mb-30"
            style="font-size: 20px; line-height: normal; font-weight: 500"
          >
            Our solution includes Virtual CFO or part time CFO services which
            prove to be effective as well as cost cautious in the long run.
          </p>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/CFO_Services_1.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h2
        class="text-center"
        style="border-bottom: 3px solid #21ac51; display: inline-block"
      >
      DEBT SYNDICATION
      </h2>
    </div>
    <div class="row">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/debt.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0">
        <div>
         
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              BFAG has strong and long-standing relationships with financial institutions, banks and NBFCs, mutual funds and insurance companies both in India and globally.
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Our experienced professionals, advise and serve clients in the debt syndication and structured corporate finance space. We have been instrumental in leading debt syndication programs across industries like manufacturing, infrastructure, real estate, hospitality and healthcare.
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              We have been assisting corporates for their requirement of working capital, offshore finance, acquisition finance and cross-border projects funding. Our expertise spans raising debt financing both in domestic and international markets, with cross-border experience.
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Evaluating better sources of funds and exploring refinancing options.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70 mb-3">
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
         
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Preparation of DPR (Detailed Project Report)
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Appropriate lender selection
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Assisting in filing applications for financial assistance
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Obtaining necessary sanctions / approvals
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              OAssisting in completion of documentation and disbursal process
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/debt_2.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      
    </div>
  </div>
</div>
<!-- dubai end -->

<!-- dubai started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      CFO SERVICES & DEBT SYNDICATION
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> CFO SERVICES & DEBT
        SYNDICATION</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/CFO_Services.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 8rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        CFOs today play a vital role in any company and the role of a CFO reach
        now extends far beyond the traditional role of reporting finances. Our
        services in this regard are fully customized to ensure priority
        organizational needs such as strategic planning, process development,
        process optimization, internal controls, fund raising are well taken
        care.
      </p>
      <div>
        <h5 class="mb-20" style="color: #0951A4; ">
          TODAY, CFOS RESPONSIBILITIES INCLUDE:
        </h5>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Forging proactive and value-added partnerships that support
            decision-making throughout the company
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Maximizing utilization of resources to continually improve the
            company’s efficiency
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Developing and managing your company’s business intelligence
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Strategic Planning, Profitability Improvement, Cash Flow Management
            & Forecasting
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Operating Plan & Performance Management, Board reporting
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Charting the future course of the company in terms of growth, both
            organically and inorganically
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <p
            class="text-dark mb-30"
            style="font-size: 20px; line-height: normal; font-weight: 500"
          >
            The senior leadership team at BFAG has developed a unique set of
            services addressing these growing demands in the form of strategies
            blended with technology that enable CFOs to:
          </p>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Capitalize on current IT infrastructure to create a single source
              for integrating financial and operating information
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Reduce the total cost of the finance function by increasing the
              time spent on analysis as against transaction processing
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Improve overall corporate performance by improving core finance
              process
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              SGain access to real-time management information to aid decision
              making at the click of a button.
            </li>
          </ul>
          <p
            class="text-dark mb-30"
            style="font-size: 20px; line-height: normal; font-weight: 500"
          >
            Our solution includes Virtual CFO or part time CFO services which
            prove to be effective as well as cost cautious in the long run.
          </p>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/CFO_Services_1.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h2
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
      DEBT SYNDICATION
      </h2>
    </div>
    <div class="row">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/debt.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0">
        <div>
         
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              BFAG has strong and long-standing relationships with financial institutions, banks and NBFCs, mutual funds and insurance companies both in India and globally.
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Our experienced professionals, advise and serve clients in the debt syndication and structured corporate finance space. We have been instrumental in leading debt syndication programs across industries like manufacturing, infrastructure, real estate, hospitality and healthcare.
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              We have been assisting corporates for their requirement of working capital, offshore finance, acquisition finance and cross-border projects funding. Our expertise spans raising debt financing both in domestic and international markets, with cross-border experience.
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Evaluating better sources of funds and exploring refinancing options.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70 mb-3">
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
         
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Preparation of DPR (Detailed Project Report)
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Appropriate lender selection
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Assisting in filing applications for financial assistance
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Obtaining necessary sanctions / approvals
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              OAssisting in completion of documentation and disbursal process
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/debt_2.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      
    </div>
  </div>
</div>
<!-- dubai end -->

<!-- dubai started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      CFO SERVICES & DEBT SYNDICATION
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> CFO SERVICES & DEBT
        SYNDICATION</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/CFO_Services.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 8rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        CFOs today play a vital role in any company and the role of a CFO reach
        now extends far beyond the traditional role of reporting finances. Our
        services in this regard are fully customized to ensure priority
        organizational needs such as strategic planning, process development,
        process optimization, internal controls, fund raising are well taken
        care.
      </p>
      <div>
        <h5 class="mb-20" style="color: #0951A4; ">
          TODAY, CFOS RESPONSIBILITIES INCLUDE:
        </h5>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Forging proactive and value-added partnerships that support
            decision-making throughout the company
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Maximizing utilization of resources to continually improve the
            company’s efficiency
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Developing and managing your company’s business intelligence
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Strategic Planning, Profitability Improvement, Cash Flow Management
            & Forecasting
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Operating Plan & Performance Management, Board reporting
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Charting the future course of the company in terms of growth, both
            organically and inorganically
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <p
            class="text-dark mb-30"
            style="font-size: 20px; line-height: normal; font-weight: 500"
          >
            The senior leadership team at BFAG has developed a unique set of
            services addressing these growing demands in the form of strategies
            blended with technology that enable CFOs to:
          </p>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Capitalize on current IT infrastructure to create a single source
              for integrating financial and operating information
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Reduce the total cost of the finance function by increasing the
              time spent on analysis as against transaction processing
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Improve overall corporate performance by improving core finance
              process
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              SGain access to real-time management information to aid decision
              making at the click of a button.
            </li>
          </ul>
          <p
            class="text-dark mb-30"
            style="font-size: 20px; line-height: normal; font-weight: 500"
          >
            Our solution includes Virtual CFO or part time CFO services which
            prove to be effective as well as cost cautious in the long run.
          </p>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/CFO_Services_1.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h2
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
      DEBT SYNDICATION
      </h2>
    </div>
    <div class="row">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/debt.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0">
        <div>
         
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              BFAG has strong and long-standing relationships with financial institutions, banks and NBFCs, mutual funds and insurance companies both in India and globally.
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Our experienced professionals, advise and serve clients in the debt syndication and structured corporate finance space. We have been instrumental in leading debt syndication programs across industries like manufacturing, infrastructure, real estate, hospitality and healthcare.
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              We have been assisting corporates for their requirement of working capital, offshore finance, acquisition finance and cross-border projects funding. Our expertise spans raising debt financing both in domestic and international markets, with cross-border experience.
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Evaluating better sources of funds and exploring refinancing options.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70 mb-3">
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
         
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Preparation of DPR (Detailed Project Report)
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Appropriate lender selection
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Assisting in filing applications for financial assistance
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Obtaining necessary sanctions / approvals
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              OAssisting in completion of documentation and disbursal process
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/debt_2.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      
    </div>
  </div>
</div>
<!-- dubai end -->

