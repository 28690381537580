<!-- Dubai Started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      PAYROLL – COMPENSATION AND BENEFIT MANAGEMENT
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> PAYROLL – COMPENSATION
        AND BENEFIT MANAGEMENT</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/payroll.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 8rem"
        />
        <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        BFAG provides a full-fledged payroll solution with objective of
        reduction in processing cost and increase accuracy and effectiveness in
        execution of payroll and compliances.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Starting from the core payroll function of Payroll processing all the
        way to Tax Proof Management, Flexi Benefit Administration, Leave/Absence
        Management, Client Self Service, Employee Self Service, Query
        Management, Reports & Analytics are offered under Managed Payroll
        Services.
      </p>
    </div>
  </div>

  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h2
        class="text-center"
        style="border-bottom: 3px solid #21ac51; display: inline-block"
      >
      EMPLOYEE SELF SERVICE MODULE:
      </h2>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      BFAG provides Employee Self Service Portal wherein employee can access all the information related to payroll, it enhances employee experience while minimizing time and effort.  Employees will be able to view their personal details, change or update their information in real time and serves as a powerful medium for Employee communication.F
      </p>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Employees can view / download their payslips and their tax computation sheets.
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Employees can submit their investment declarations via the Online Employee Self Service Portal
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Act as interface and query management tool.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/selfservices.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Dubai end -->

<!-- india Started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      PAYROLL – COMPENSATION AND BENEFIT MANAGEMENT
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> PAYROLL – COMPENSATION
        AND BENEFIT MANAGEMENT</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/payroll.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 8rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        BFAG provides a full-fledged payroll solution with objective of
        reduction in processing cost and increase accuracy and effectiveness in
        execution of payroll and compliances.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Starting from the core payroll function of Payroll processing all the
        way to Tax Proof Management, Flexi Benefit Administration, Leave/Absence
        Management, Client Self Service, Employee Self Service, Query
        Management, Reports & Analytics are offered under Managed Payroll
        Services.
      </p>
    </div>
  </div>

  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h2
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
      EMPLOYEE SELF SERVICE MODULE:
      </h2>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      BFAG provides Employee Self Service Portal wherein employee can access all the information related to payroll, it enhances employee experience while minimizing time and effort.  Employees will be able to view their personal details, change or update their information in real time and serves as a powerful medium for Employee communication.F
      </p>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Employees can view / download their payslips and their tax computation sheets.
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Employees can submit their investment declarations via the Online Employee Self Service Portal
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Act as interface and query management tool.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/selfservices.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- india end -->

<!-- USA Started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      PAYROLL – COMPENSATION AND BENEFIT MANAGEMENT
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> PAYROLL – COMPENSATION
        AND BENEFIT MANAGEMENT</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/payroll.jpg"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 8rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        BFAG provides a full-fledged payroll solution with objective of
        reduction in processing cost and increase accuracy and effectiveness in
        execution of payroll and compliances.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Starting from the core payroll function of Payroll processing all the
        way to Tax Proof Management, Flexi Benefit Administration, Leave/Absence
        Management, Client Self Service, Employee Self Service, Query
        Management, Reports & Analytics are offered under Managed Payroll
        Services.
      </p>
    </div>
  </div>

  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h2
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
      EMPLOYEE SELF SERVICE MODULE:
      </h2>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      BFAG provides Employee Self Service Portal wherein employee can access all the information related to payroll, it enhances employee experience while minimizing time and effort.  Employees will be able to view their personal details, change or update their information in real time and serves as a powerful medium for Employee communication.F
      </p>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Employees can view / download their payslips and their tax computation sheets.
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Employees can submit their investment declarations via the Online Employee Self Service Portal
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Act as interface and query management tool.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/selfservices.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- USA end -->