
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area sales-management customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Sales Management</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Sales Management</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Sales Management PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Sales Solutions</span>
                    <h2 class="title pb-15">Sales Management</h2>
                    <p>Sales management can streamline your entire fulfillment process. Reducing paperwork for your sales force, allowing them to focus their efforts on selling. It also ensures that customers get deliveries on time, boosting customer satisfaction
                        and long-term loyalty.</p>
                    <p>The primary goal of any business is to grow profitably. Whether through attracting new customers or retaining existing ones, strong sales management is essential. Sales Management can strengthen your ability to increase revenue by
                        enabling you to convert more prospects to customers and target the highest value opportunities.</p>
                    <p>During sales order-processing the basic functions are carried out are enquiry handlings, quotation preparation and processing, order management, monitoring the sales transaction, checking for availability, scheduling the delivery,
                        calculating pricing, checking credit limits, sales invoicing/billing, creating printed or electronically transmitted documents.</p>
                    <p>Depending on how your system is configured, these functions may be completely automated. Sales Management module very actively interacts with the material management and financial accounting module for delivery and billing.</p>
                    <p>Quotation and sales orders enable the vital coordination of activities between your sales people, warehouses, shipping/delivery and accounting teams. They facilitate efficient management of accounts, shipping, invoicing and commissions.
                        Sales management provides the tools—including sophisticated product configuration—to produce accurate quotation, streamline your quote-to-cash cycle and improve customer service. The Sales pipeline management system is designed
                        to improve customer service, evaluate sales activities, capture trends, and perform advanced statistical analysis.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Sales Management PART END ======-->
<!--====== Features PART START ======-->
<div class="about-faq-area about-faq-area-custom horizon-hrms dec-spacing bg_cover gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Highlights</span>
                    <h2 class="title">Features</h2>
                </div>
            </div>
            <div class="faq-accordion-3">
                <div class="accordion" id="accordionExample">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Quotation Management
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Optimize your profit margin with an efficient and accurate quoting module. Price for each item of the quotation may be obtained automatically from pricing sheet. If the quotation is successful, it is processed for generation
                                        of sales order. If the quotation is unsuccessful, the reason of failure and name of successful vendor can be recorded in the system for future reference.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Order Management
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>This allows a company to manage sales operations quickly and efficiently. This process handles regular sales order, cost order, customer return and collect order. Rebate and commission process enables automatic calculation
                                        of commission to be paid to sales employees.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Sales invoicing
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>This process facilitate billing functions such as issuing of invoices based on goods / services provided, generating proforma invoices, issuing credit notes resulting corresponding entries in accounts receivable and
                                        control account of general ledger.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                    </div>

                    <div class="col-lg-6">

                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSev">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSev" aria-expanded="false" aria-controls="collapseSev">
                                    <i class="fal fa-magic"></i>Credit Rating and Credit Limits
                                </a>
                            </div>
                            <div id="collapseSev" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Credit Limits for each customer can be set to have tight control over credit sales. The rating can be given at the discretion and terms of payment is suggested by the system from the credit rating of the customer.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEight">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    <i class="fal fa-magic"></i>Integration with other modules
                                </a>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>It draws most of its input from Inventory module for verifying available and projected stock, purchasing module for generation of direct delivery purchase order, delivery module for issuing order and shipment and accounts
                                        module where sales receivable are recorded.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                    </div>
                </div>
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== Features PART START ======-->

<!--====== Benefits at a glance  PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                    <p class="add-arrow"> Create offer quotations based on customer past history Sales order. Optimize your profit margin with an efficient and accurate quoting module.</p>
                    <p class="add-arrow"> Extensive sales analytic tools help identify sales trends and profitability by product, business unit, customer and sales person</p>
                    <p class="add-arrow"> Quick and easy order entry with pull down menus and previous order history including, pricing/quantity discounts, and more</p>
                    <p class="add-arrow"> Flexible pricing and discount options including customer specific pricing, tier pricing and other discount terms</p>
                    <p class="add-arrow"> Complete credit management tools monitors credit limits, credit holds and other hold conditions</p>
                    <p class="add-arrow"> Complete scheduling of orders, pending Sales order tracking. Dispatch Instructions, packing and dispatch of order.</p>
                    <p class="add-arrow"> Creation of invoice based on sales order/ dispatches considering all compliances.</p>
                    <p class="add-arrow"> Ageing and outstanding tracking with reminders to customers.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Benefits at a glance  PART END ======-->
