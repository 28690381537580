<!-- india started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
          <div class="" style="padding: 1%">
            <img
              style="border-radius: 10px; max-width: 95%"
              class="img-fluid"
              src="../../../../../assets/images/teams/ammarCircle.jpeg"
              alt="ammar"
            />
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start align-items-start"
        >
          <h3
            class="text-uppercase text-dark title"
            style="font-size: 20px; font-weight: 700;"
          >
          Ammar Tumpalliwar
          </h3>
          <div>
            <span
              class="text-uppercase text-dark"
              style="font-size: 16px; font-weight: 600"
            >
            VP – Business Development
            </span>
            <a
              style="color: #fff"
              href="https://www.linkedin.com/in/amartumplwar/"
              target="_blank"
            >
              <i
                class="fab fa-linkedin-in"
                style="
                  margin-left: 10px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                "
              ></i>
            </a>
          </div>
          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Ammar, the VP of Business Development at Boston Financial Advisory Group (BFAG), has a remarkable record in consulting startups and SMEs on accounting systems, driving sales for lead generation, and facilitating market expansion. With extensive experience spanning over three decades in finance, accounts, operations, and business development, Ammar is a highly
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Ammar's educational background is diverse, including a graduate degree in Commerce and Law, as well as a postgraduate degree in Management. He previously worked as a project manager, contributing to the implementation of SAP R3 in a complex environment. Leveraging his expertise in business development, finance, operations, and project management, Ammar drives organizational growth, fosters strong client relationships, and achieves success.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Throughout his career, Ammar has provided consultancy on accounting systems, processes, and controls to SMEs. He played a crucial role in establishing business development back office and ensuring effective communication between sales force and customers. His accomplishments include identifying and implementing marketing tools, facilitating entry into new markets, and establishing a solid market presence.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        As a specialist in Business Development, Ammar excels in identifying sales channel partners, seamless integration, expansion into new geographies through strategic alliances, and identifying revenue streams.
      </p>
        </div>
        <div class="col-lg-12">
          <p class="mt-1 text-dark text-justify"
          style="font-size: 16px !important">
             His strong data mining and analytical skills contribute to reaching prospects with a comprehensive services portfolio. Ammar finds great satisfaction in thoroughly exploring the market for COMPLIANCE iNBOX (CiB), the product he now represents.
          </p>

          <p class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
            Ammar actively participates in BNI – BARONS Pune chapter, a prestigious global business networking group. Through connections, collaboration, and referrals, he drives business growth and cultivates meaningful professional relationships. By engaging in weekly meetings, sharing insights, and contributing to fellow members' success, Ammar epitomizes the spirit of entrepreneurship and collective achievement.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- india ends -->

<!-- usa started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
          <div class="" style="padding: 1%">
            <img
              style="border-radius: 10px; max-width: 95%"
              class="img-fluid"
              src="../../../../../assets/images/teams/ammarCircle.jpeg"
              alt="ammar"
            />
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start align-items-start"
        >
          <h3
            class="text-uppercase text-dark title"
            style="font-size: 20px; font-weight: 700;"
          >
          Ammar Tumpalliwar
          </h3>
          <div>
            <span
              class="text-uppercase text-dark"
              style="font-size: 16px; font-weight: 600"
            >
            VP – Business Development
            </span>
            <a
              style="color: #fff"
              href="https://www.linkedin.com/in/amartumplwar/"
              target="_blank"
            >
              <i
                class="fab fa-linkedin-in"
                style="
                  margin-left: 10px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                "
              ></i>
            </a>
          </div>
          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Ammar, the VP of Business Development at Boston Financial Advisory Group (BFAG), has a remarkable record in consulting startups and SMEs on accounting systems, driving sales for lead generation, and facilitating market expansion. With extensive experience spanning over three decades in finance, accounts, operations, and business development, Ammar is a highly
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Ammar's educational background is diverse, including a graduate degree in Commerce and Law, as well as a postgraduate degree in Management. He previously worked as a project manager, contributing to the implementation of SAP R3 in a complex environment. Leveraging his expertise in business development, finance, operations, and project management, Ammar drives organizational growth, fosters strong client relationships, and achieves success.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Throughout his career, Ammar has provided consultancy on accounting systems, processes, and controls to SMEs. He played a crucial role in establishing business development back office and ensuring effective communication between sales force and customers. His accomplishments include identifying and implementing marketing tools, facilitating entry into new markets, and establishing a solid market presence.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        As a specialist in Business Development, Ammar excels in identifying sales channel partners, seamless integration, expansion into new geographies through strategic alliances, and identifying revenue streams.
      </p>
        </div>
        <div class="col-lg-12">
          <p class="mt-1 text-dark text-justify"
          style="font-size: 16px !important">
             His strong data mining and analytical skills contribute to reaching prospects with a comprehensive services portfolio. Ammar finds great satisfaction in thoroughly exploring the market for COMPLIANCE iNBOX (CiB), the product he now represents.
          </p>

          <p class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
            Ammar actively participates in BNI – BARONS Pune chapter, a prestigious global business networking group. Through connections, collaboration, and referrals, he drives business growth and cultivates meaningful professional relationships. By engaging in weekly meetings, sharing insights, and contributing to fellow members' success, Ammar epitomizes the spirit of entrepreneurship and collective achievement.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- usa ends -->

<!-- Dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
          <div class="" style="padding: 1%">
            <img
              style="border-radius: 10px; max-width: 95%"
              class="img-fluid"
              src="../../../../../assets/images/teams/ammarCircle.jpeg"
              alt="ammar"
            />
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start align-items-start"
        >
          <h3
            class="text-uppercase text-dark title"
            style="font-size: 20px; font-weight: 700;"
          >
          Ammar Tumpalliwar
          </h3>
          <div>
            <span
              class="text-uppercase text-dark"
              style="font-size: 16px; font-weight: 600"
            >
            VP – Business Development
            </span>
            <a
              style="color: #fff"
              href="https://www.linkedin.com/in/amartumplwar/"
              target="_blank"
            >
              <i
                class="fab fa-linkedin-in"
                style="
                  margin-left: 10px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                "
              ></i>
            </a>
          </div>
          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Ammar, the VP of Business Development at Boston Financial Advisory Group (BFAG), has a remarkable record in consulting startups and SMEs on accounting systems, driving sales for lead generation, and facilitating market expansion. With extensive experience spanning over three decades in finance, accounts, operations, and business development, Ammar is a highly
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Ammar's educational background is diverse, including a graduate degree in Commerce and Law, as well as a postgraduate degree in Management. He previously worked as a project manager, contributing to the implementation of SAP R3 in a complex environment. Leveraging his expertise in business development, finance, operations, and project management, Ammar drives organizational growth, fosters strong client relationships, and achieves success.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Throughout his career, Ammar has provided consultancy on accounting systems, processes, and controls to SMEs. He played a crucial role in establishing business development back office and ensuring effective communication between sales force and customers. His accomplishments include identifying and implementing marketing tools, facilitating entry into new markets, and establishing a solid market presence.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        As a specialist in Business Development, Ammar excels in identifying sales channel partners, seamless integration, expansion into new geographies through strategic alliances, and identifying revenue streams.
      </p>
        </div>
        <div class="col-lg-12">
          <p class="mt-1 text-dark text-justify"
          style="font-size: 16px !important">
             His strong data mining and analytical skills contribute to reaching prospects with a comprehensive services portfolio. Ammar finds great satisfaction in thoroughly exploring the market for COMPLIANCE iNBOX (CiB), the product he now represents.
          </p>

          <p class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
            Ammar actively participates in BNI – BARONS Pune chapter, a prestigious global business networking group. Through connections, collaboration, and referrals, he drives business growth and cultivates meaningful professional relationships. By engaging in weekly meetings, sharing insights, and contributing to fellow members' success, Ammar epitomizes the spirit of entrepreneurship and collective achievement.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Dubai ends -->
