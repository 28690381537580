<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'"  class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing min-vh-100 mt-130" >
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="experience-item">
                  <h2 class="title pb-15">FAQS on FOREIGN DIRECT INVESTMENT (FDI)</h2>
              </div>
              <div class="faq-accordion-3 pt-0">
                  <div class="accordion" id="accordionExample">
                      <div class="card">
                          <div class="card-header" id="headingOne">
                              <a class="w-100" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                  What is Foreign Direct Investment (FDI)?
                              </a>
                          </div>
                          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"><b>FOREIGN DIRECT INVESTMENT (‘FDI’)</b> means investment through capital instruments by a person resident outside India in:</p>

                                  <ul >
                                      <li><p>Unlisted Indian company.</p></li>
                                      <li><p>10 % or more of the post-issue paid-up equity capital on a fully diluted basis of a listed Indian company.</p></li>
                                  </ul>
                                  <p class="pb-15">Foreign Investment in India is governed by the FDI policy announced by the Government of India and the provisions of the Foreign Exchange Management Act (FEMA) 1999.</p>

                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingTwo">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                  What is the regulatory and governing framework for FDI in India?
                              </a>
                          </div>
                          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">Primarily, foreign investment is regulated through the Foreign Exchange Management Act (FEMA), 1999 as amended from time to time (FEMA) and rules/regulations issued thereunder. </p>

                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingThree">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                 Who is the concerned authority to deal with violations of FDI Policy/regulations?
                              </a>
                          </div>
                          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">Reserve Bank of India administers the Foreign Exchange Management Act (FEMA) and the Directorate of Enforcement under the Ministry of Finance is the authority for the enforcement of FEMA. The Directorate takes up investigations in any contravention of FEMA.</p>

                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingFour">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                  Who are the Eligible Investors for FDI?
                              </a>
                          </div>
                          <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                              <div class="card-body">
                                  <ul >
                                      <li><p>Non-resident entities can invest in India, subject to the FDI Policy except in those sectors/activities which are prohibited. </p></li>
                                      <li><p>Non-Resident Indian (NRI).</p></li>
                                      <li><p>Overseas Corporate Body (OCBs).</p></li>
                                      <li><p>Company/ Trust/ Partnership.</p></li>
                                      <li><p>Only registered FIIs/FPIs and NRIs.</p></li>
                                      <li><p>National Pension Fund System.</p></li>
                                      <li><p>SEBI registered Foreign Venture Capital Investor (FVCI).</p></li>
                                      <li><p>Foreign Institutional Investors (FII) and Foreign Portfolio Investors (FPI).</p></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingFive">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                  Who are the Eligible Investee Entities for FDI?
                              </a>
                          </div>
                          <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                              <div class="card-body">
                                  <ul>
                                      <li><p>Indian Company.</p></li>
                                      <li><p>Limited Liability Partnership.</p></li>
                                      <li><p>Trust.</p></li>
                                      <li><p>Investment Vehicle.</p></li>
                                      <li><p>Startup Companies.</p></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingSex">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSex" aria-expanded="false" aria-controls="collapseSex">
                                  What are the types of Securities Issued FDI Policy?
                              </a>
                          </div>
                          <div id="collapseSex" class="collapse" aria-labelledby="headingSex" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">Type of Securities Issued under FDI Policy: </p>

                                  <ul>
                                      <li>
                                          <p><b>The equity shares </b>issued in accordance with the provisions of the Companies Act, as applicable, shall include equity shares that have been partly paid.</p>
                                      </li>
                                      <li>
                                          <p><b>Preference Shares: </b>Fully, compulsorily & mandatorily convertible preference shares </p>
                                      </li>
                                      <li>
                                          <p>Preference shares shall be required to be fully paid and should be <b>mandatorily and fully convertible.</b> </p>
                                      </li>
                                      <li>
                                          <p><b>Debenture:</b> Fully, compulsorily & mandatorily convertible Debentures. Debentures shall be required to be fully paid and should be mandatorily and fully convertible. </p>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingSeven">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                  Which are the prohibited sectors for FDI?
                              </a>
                          </div>
                          <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">The present policy prohibits FDI in the following sectors:</p>
                                  <ul>
                                      <li><p><b>Gambling and Betting.</b></p></li>
                                      <li><p><b>Lottery business </b>(including government/ private lottery, online lotteries etc).</p></li>
                                      <li><p>Activities /sectors are not open to private sector investment (eg. atomic energy /railways).</p></li>
                                      <li><p><b>Retail trading </b>(except single-brand product retailing).</p></li>
                                      <li><p>Business of <b>chit fund.</b></p></li>
                                      <li><p><b>Nidhi Company.</b></p></li>
                                      <li><p><b>Real estate business </b>or construction of farmhouses.</p></li>
                                      <li><p>Trading in transferable development rights (TDRs).</p></li>
                                      <li><p><b>Manufacturing of tobacco, cigars, cheroots, cigarillos, cigarettes and other tobacco substitutes.</b></p></li>
                                      <li><p>Agriculture (excluding floriculture, horticulture, apiculture and cultivation of vegetables and mushrooms under controlled conditions, the development and production of seeds & planting materials, animals husbandry including the breeding of dogs, viniculture & aquaculture under controlled conditions and services related to the agro and allied sector).</p></li>
                                      <li><p>Warrant: Fully, compulsorily & mandatorily convertible Warrant. Further, ‘warrant’ includes a Share Warrant issued by an Indian Company in accordance to provisions of the Companies Act, as applicable.</p></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingEight">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                  How can a foreign investor set up business operations in India through a company?
                              </a>
                          </div>
                          <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">A foreign investor can set up business operations in India by incorporating a company under the Companies Act, 2013 such as Joint Venture/Wholly Owned Subsidiary/Holding Company in compliance with the entry route/sectoral cap and other conditions under the FDI Policy and Foreign Exchange Management (Non-Debt Instruments) Rules, 2019.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingNine">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                 Can foreign entities set up a branch office/liaison office/project office or any other place of business in India?
                              </a>
                          </div>
                          <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">A person resident outside India can establish a branch office/liaison office/project office or any other place of business in India in accordance with the Foreign Exchange Management rules.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingTen">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                  What is “downstream investment”?
                              </a>
                          </div>
                          <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">“Downstream investment” means investment made by an Indian entity which has total foreign investment in it, or an Investment Vehicle in the capital instruments or the capital, as the case may be, of another Indian entity.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                        <!-- card -->
                        <div class="card">
                          <div class="card-header" id="headingElev">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseElev" aria-expanded="false" aria-controls="collapseElev">
                                  How does FDI differ from portfolio investment?
                              </a>
                          </div>
                          <div id="collapseElev" class="collapse" aria-labelledby="headingElev" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">FDI differs from portfolio investment in terms of the level of control and ownership. In FDI, the investor obtains substantial control over the foreign enterprise, often through equity ownership. Portfolio investment, on the other hand, involves the purchase of securities such as stocks and bonds without gaining control or actively participating in the management of the company.</p>

                              </div>
                          </div>
                      </div>
                      <!-- card -->
                         <!-- card -->
                         <div class="card">
                          <div class="card-header" id="headingTlve">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTlve" aria-expanded="false" aria-controls="collapse">
                                  What is Indirect foreign investment?
                              </a>
                          </div>
                          <div id="collapseTlve" class="collapse" aria-labelledby="headingTlve" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">“Indirect foreign investment” means downstream investment received by an Indian entity from-</p>
                                  <ul>
                                      <li>
                                          <p>Another Indian entity (IE) which has received foreign investment and (i) the IE is not owned and not controlled by resident Indian citizens or (ii) is owned or controlled by persons resident outside India.</p>
                                      </li>
                                      <li>
                                          <p>An investment vehicle whose sponsor or manager or investment manager (i) is not owned and not controlled by resident Indian citizens or (ii) is owned or controlled by persons resident outside India: Indian entity which has received indirect foreign investment shall comply with the entry route, sectoral caps, pricing guidelines and other attendant conditions as applicable for foreign investment.</p>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                       <!-- card -->
                       <div class="card">
                        <div class="card-header" id="headingTeen">
                            <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTeen" aria-expanded="false" aria-controls="collapseTeen">
                              What are the entry routes for FDI?
                            </a>
                        </div>
                        <div id="collapseTeen" class="collapse" aria-labelledby="headingTeen" data-parent="#accordionExample">
                            <div class="card-body">
                                <p class="pb-15">Permissible FDI can be made under “Automatic route” or “Government route”.</p>
                                <p><b>“Automatic route”</b> means the entry route through which investment by a person resident outside India does not require the prior approval of the Reserve Bank of India or the Central Government.</p>
                                <br>
                                <p><b>“Government Route”</b> means the entry route through which investment by a person resident outside India requires prior Government approval and foreign investment received under this route shall be in accordance with the conditions stipulated by the Government in its approval. Besides the entry conditions on foreign investment, the investment/investors are required to comply with all relevant sectoral laws, regulations, rules, security conditions, and state/local laws/regulations.</p>
                            </div>
                        </div>
                    </div>
                    <!-- card -->
                    <!-- card -->
                    <div class="card">
                      <div class="card-header" id="headingFrTeen">
                          <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFrTeen" aria-expanded="false" aria-controls="collapseFrTeen">
                              What is round tripping?
                          </a>
                      </div>
                      <div id="collapseFrTeen" class="collapse" aria-labelledby="headingFrTeen" data-parent="#accordionExample">
                          <div class="card-body">
                              <p class="pb-15">Round-tripping refers to the process of routing domestic funds through various channels to make them appear as foreign investments and subsequently bringing them back into India. It involves a circular movement of funds, where money originating in India is sent abroad and then reinvested in India to take advantage of certain benefits or to bypass restrictions. Simply, for example, an Indian entity sets up a subsidiary outside in the USA and that subsidiary invests back in India.</p>
                          </div>
                      </div>
                  </div>
                  <!-- card -->
                  <!-- card -->
                  <div class="card">
                      <div class="card-header" id="headingFvTeen">
                          <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFvTeen" aria-expanded="false" aria-controls="collapseFvTeen">
                              What kind of business structure should I have?
                          </a>
                      </div>
                      <div id="collapseFvTeen" class="collapse" aria-labelledby="headingFvTeen" data-parent="#accordionExample">
                          <div class="card-body">
                              <p class="pb-15">When considering foreign investment in your business, you have several options for the business structure you can adopt. The most common business structures that allow foreign investment in many countries, including India, are:</p>
                              <ul>
                                  <li><p><u>Private Limited Company:</u> Forming a private limited company is a popular choice for businesses seeking foreign investment. This structure provides limited liability protection to shareholders and allows for equity participation by foreign investors. In India, a private limited company can have up to 200 shareholders and is governed by the Companies Act.</p></li>
                                  <li><p><u>Public Limited Company:</u> Similar to a private limited company, a public limited company offers the advantage of raising capital from the public through the issuance of shares. Foreign investors can acquire shares in the company. However, the compliance requirements for a public limited company are typically more stringent than those for a private limited company.</p></li>
                                  <li><p><u>Limited Liability Partnership (LLP): </u> An LLP is a hybrid structure that combines elements of a partnership and a limited liability company. It offers limited liability protection to its partners while allowing flexibility in management. Foreign investment in an LLP can be subject to certain conditions and restrictions, depending on the country's regulations.</p></li>
                                  <li><p><u>Joint Venture: </u> A joint venture involves partnering with a foreign entity to establish a new business entity or collaborate on a specific project. This structure allows for shared investment, risks, and profits. Joint ventures often require detailed agreements to govern the relationship between the partners and may involve additional legal and regulatory considerations.</p></li>
                                  <li><p><u>Branch Office or Liaison Office: </u> Some countries permit the establishment of branch offices or liaison offices of foreign companies. A branch office operates as an extension of the parent company, while a liaison office serves as a communication channel between the foreign company and local entities. These structures can facilitate foreign investment and business operations while maintaining a direct link to the parent company.</p></li>
                              </ul>
                              <p class="pb-15">Before deciding on a specific business structure, it's advisable to consult with legal and financial professionals who are well-versed in the regulations of the country in which you intend to operate. They can provide guidance based on your specific circumstances, the nature of your business, and the regulatory requirements governing foreign investment.</p>
                          </div>
                      </div>
                  </div>
                  <!-- card -->
                    </div>
              </div>
              <!-- faq accordion -->
          </div>
      </div>
      <!-- container -->
  </div>
</div>
<!-- USA end -->

<!-- india started -->
<div *ngIf="selectCountryName == 'India'"  class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing min-vh-100 mt-130" >
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="experience-item">
                  <h2 class="title pb-15">FAQS on FOREIGN DIRECT INVESTMENT (FDI)</h2>
              </div>
              <div class="faq-accordion-3 pt-0">
                  <div class="accordion" id="accordionExample">
                      <div class="card">
                          <div class="card-header" id="headingOne">
                              <a class="w-100" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                  What is Foreign Direct Investment (FDI)?
                              </a>
                          </div>
                          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"><b>FOREIGN DIRECT INVESTMENT (‘FDI’)</b> means investment through capital instruments by a person resident outside India in:</p>

                                  <ul >
                                      <li><p>Unlisted Indian company.</p></li>
                                      <li><p>10 % or more of the post-issue paid-up equity capital on a fully diluted basis of a listed Indian company.</p></li>
                                  </ul>
                                  <p class="pb-15">Foreign Investment in India is governed by the FDI policy announced by the Government of India and the provisions of the Foreign Exchange Management Act (FEMA) 1999.</p>

                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingTwo">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                  What is the regulatory and governing framework for FDI in India?
                              </a>
                          </div>
                          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">Primarily, foreign investment is regulated through the Foreign Exchange Management Act (FEMA), 1999 as amended from time to time (FEMA) and rules/regulations issued thereunder. </p>

                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingThree">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                 Who is the concerned authority to deal with violations of FDI Policy/regulations?
                              </a>
                          </div>
                          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">Reserve Bank of India administers the Foreign Exchange Management Act (FEMA) and the Directorate of Enforcement under the Ministry of Finance is the authority for the enforcement of FEMA. The Directorate takes up investigations in any contravention of FEMA.</p>

                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingFour">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                  Who are the Eligible Investors for FDI?
                              </a>
                          </div>
                          <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                              <div class="card-body">
                                  <ul >
                                      <li><p>Non-resident entities can invest in India, subject to the FDI Policy except in those sectors/activities which are prohibited. </p></li>
                                      <li><p>Non-Resident Indian (NRI).</p></li>
                                      <li><p>Overseas Corporate Body (OCBs).</p></li>
                                      <li><p>Company/ Trust/ Partnership.</p></li>
                                      <li><p>Only registered FIIs/FPIs and NRIs.</p></li>
                                      <li><p>National Pension Fund System.</p></li>
                                      <li><p>SEBI registered Foreign Venture Capital Investor (FVCI).</p></li>
                                      <li><p>Foreign Institutional Investors (FII) and Foreign Portfolio Investors (FPI).</p></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingFive">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                  Who are the Eligible Investee Entities for FDI?
                              </a>
                          </div>
                          <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                              <div class="card-body">
                                  <ul>
                                      <li><p>Indian Company.</p></li>
                                      <li><p>Limited Liability Partnership.</p></li>
                                      <li><p>Trust.</p></li>
                                      <li><p>Investment Vehicle.</p></li>
                                      <li><p>Startup Companies.</p></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingSex">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSex" aria-expanded="false" aria-controls="collapseSex">
                                  What are the types of Securities Issued FDI Policy?
                              </a>
                          </div>
                          <div id="collapseSex" class="collapse" aria-labelledby="headingSex" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">Type of Securities Issued under FDI Policy: </p>

                                  <ul>
                                      <li>
                                          <p><b>The equity shares </b>issued in accordance with the provisions of the Companies Act, as applicable, shall include equity shares that have been partly paid.</p>
                                      </li>
                                      <li>
                                          <p><b>Preference Shares: </b>Fully, compulsorily & mandatorily convertible preference shares </p>
                                      </li>
                                      <li>
                                          <p>Preference shares shall be required to be fully paid and should be <b>mandatorily and fully convertible.</b> </p>
                                      </li>
                                      <li>
                                          <p><b>Debenture:</b> Fully, compulsorily & mandatorily convertible Debentures. Debentures shall be required to be fully paid and should be mandatorily and fully convertible. </p>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingSeven">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                  Which are the prohibited sectors for FDI?
                              </a>
                          </div>
                          <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">The present policy prohibits FDI in the following sectors:</p>
                                  <ul>
                                      <li><p><b>Gambling and Betting.</b></p></li>
                                      <li><p><b>Lottery business </b>(including government/ private lottery, online lotteries etc).</p></li>
                                      <li><p>Activities /sectors are not open to private sector investment (eg. atomic energy /railways).</p></li>
                                      <li><p><b>Retail trading </b>(except single-brand product retailing).</p></li>
                                      <li><p>Business of <b>chit fund.</b></p></li>
                                      <li><p><b>Nidhi Company.</b></p></li>
                                      <li><p><b>Real estate business </b>or construction of farmhouses.</p></li>
                                      <li><p>Trading in transferable development rights (TDRs).</p></li>
                                      <li><p><b>Manufacturing of tobacco, cigars, cheroots, cigarillos, cigarettes and other tobacco substitutes.</b></p></li>
                                      <li><p>Agriculture (excluding floriculture, horticulture, apiculture and cultivation of vegetables and mushrooms under controlled conditions, the development and production of seeds & planting materials, animals husbandry including the breeding of dogs, viniculture & aquaculture under controlled conditions and services related to the agro and allied sector).</p></li>
                                      <li><p>Warrant: Fully, compulsorily & mandatorily convertible Warrant. Further, ‘warrant’ includes a Share Warrant issued by an Indian Company in accordance to provisions of the Companies Act, as applicable.</p></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingEight">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                  How can a foreign investor set up business operations in India through a company?
                              </a>
                          </div>
                          <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">A foreign investor can set up business operations in India by incorporating a company under the Companies Act, 2013 such as Joint Venture/Wholly Owned Subsidiary/Holding Company in compliance with the entry route/sectoral cap and other conditions under the FDI Policy and Foreign Exchange Management (Non-Debt Instruments) Rules, 2019.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingNine">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                 Can foreign entities set up a branch office/liaison office/project office or any other place of business in India?
                              </a>
                          </div>
                          <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">A person resident outside India can establish a branch office/liaison office/project office or any other place of business in India in accordance with the Foreign Exchange Management rules.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                      <div class="card">
                          <div class="card-header" id="headingTen">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                  What is “downstream investment”?
                              </a>
                          </div>
                          <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">“Downstream investment” means investment made by an Indian entity which has total foreign investment in it, or an Investment Vehicle in the capital instruments or the capital, as the case may be, of another Indian entity.</p>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                        <!-- card -->
                        <div class="card">
                          <div class="card-header" id="headingElev">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseElev" aria-expanded="false" aria-controls="collapseElev">
                                  How does FDI differ from portfolio investment?
                              </a>
                          </div>
                          <div id="collapseElev" class="collapse" aria-labelledby="headingElev" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">FDI differs from portfolio investment in terms of the level of control and ownership. In FDI, the investor obtains substantial control over the foreign enterprise, often through equity ownership. Portfolio investment, on the other hand, involves the purchase of securities such as stocks and bonds without gaining control or actively participating in the management of the company.</p>

                              </div>
                          </div>
                      </div>
                      <!-- card -->
                         <!-- card -->
                         <div class="card">
                          <div class="card-header" id="headingTlve">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTlve" aria-expanded="false" aria-controls="collapse">
                                  What is Indirect foreign investment?
                              </a>
                          </div>
                          <div id="collapseTlve" class="collapse" aria-labelledby="headingTlve" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15">“Indirect foreign investment” means downstream investment received by an Indian entity from-</p>
                                  <ul>
                                      <li>
                                          <p>Another Indian entity (IE) which has received foreign investment and (i) the IE is not owned and not controlled by resident Indian citizens or (ii) is owned or controlled by persons resident outside India.</p>
                                      </li>
                                      <li>
                                          <p>An investment vehicle whose sponsor or manager or investment manager (i) is not owned and not controlled by resident Indian citizens or (ii) is owned or controlled by persons resident outside India: Indian entity which has received indirect foreign investment shall comply with the entry route, sectoral caps, pricing guidelines and other attendant conditions as applicable for foreign investment.</p>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- card -->
                       <!-- card -->
                       <div class="card">
                        <div class="card-header" id="headingTeen">
                            <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTeen" aria-expanded="false" aria-controls="collapseTeen">
                              What are the entry routes for FDI?
                            </a>
                        </div>
                        <div id="collapseTeen" class="collapse" aria-labelledby="headingTeen" data-parent="#accordionExample">
                            <div class="card-body">
                                <p class="pb-15">Permissible FDI can be made under “Automatic route” or “Government route”.</p>
                                <p><b>“Automatic route”</b> means the entry route through which investment by a person resident outside India does not require the prior approval of the Reserve Bank of India or the Central Government.</p>
                                <br>
                                <p><b>“Government Route”</b> means the entry route through which investment by a person resident outside India requires prior Government approval and foreign investment received under this route shall be in accordance with the conditions stipulated by the Government in its approval. Besides the entry conditions on foreign investment, the investment/investors are required to comply with all relevant sectoral laws, regulations, rules, security conditions, and state/local laws/regulations.</p>
                            </div>
                        </div>
                    </div>
                    <!-- card -->
                    <!-- card -->
                    <div class="card">
                      <div class="card-header" id="headingFrTeen">
                          <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFrTeen" aria-expanded="false" aria-controls="collapseFrTeen">
                              What is round tripping?
                          </a>
                      </div>
                      <div id="collapseFrTeen" class="collapse" aria-labelledby="headingFrTeen" data-parent="#accordionExample">
                          <div class="card-body">
                              <p class="pb-15">Round-tripping refers to the process of routing domestic funds through various channels to make them appear as foreign investments and subsequently bringing them back into India. It involves a circular movement of funds, where money originating in India is sent abroad and then reinvested in India to take advantage of certain benefits or to bypass restrictions. Simply, for example, an Indian entity sets up a subsidiary outside in the USA and that subsidiary invests back in India.</p>
                          </div>
                      </div>
                  </div>
                  <!-- card -->
                  <!-- card -->
                  <div class="card">
                      <div class="card-header" id="headingFvTeen">
                          <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFvTeen" aria-expanded="false" aria-controls="collapseFvTeen">
                              What kind of business structure should I have?
                          </a>
                      </div>
                      <div id="collapseFvTeen" class="collapse" aria-labelledby="headingFvTeen" data-parent="#accordionExample">
                          <div class="card-body">
                              <p class="pb-15">When considering foreign investment in your business, you have several options for the business structure you can adopt. The most common business structures that allow foreign investment in many countries, including India, are:</p>
                              <ul>
                                  <li><p><u>Private Limited Company:</u> Forming a private limited company is a popular choice for businesses seeking foreign investment. This structure provides limited liability protection to shareholders and allows for equity participation by foreign investors. In India, a private limited company can have up to 200 shareholders and is governed by the Companies Act.</p></li>
                                  <li><p><u>Public Limited Company:</u> Similar to a private limited company, a public limited company offers the advantage of raising capital from the public through the issuance of shares. Foreign investors can acquire shares in the company. However, the compliance requirements for a public limited company are typically more stringent than those for a private limited company.</p></li>
                                  <li><p><u>Limited Liability Partnership (LLP): </u> An LLP is a hybrid structure that combines elements of a partnership and a limited liability company. It offers limited liability protection to its partners while allowing flexibility in management. Foreign investment in an LLP can be subject to certain conditions and restrictions, depending on the country's regulations.</p></li>
                                  <li><p><u>Joint Venture: </u> A joint venture involves partnering with a foreign entity to establish a new business entity or collaborate on a specific project. This structure allows for shared investment, risks, and profits. Joint ventures often require detailed agreements to govern the relationship between the partners and may involve additional legal and regulatory considerations.</p></li>
                                  <li><p><u>Branch Office or Liaison Office: </u> Some countries permit the establishment of branch offices or liaison offices of foreign companies. A branch office operates as an extension of the parent company, while a liaison office serves as a communication channel between the foreign company and local entities. These structures can facilitate foreign investment and business operations while maintaining a direct link to the parent company.</p></li>
                              </ul>
                              <p class="pb-15">Before deciding on a specific business structure, it's advisable to consult with legal and financial professionals who are well-versed in the regulations of the country in which you intend to operate. They can provide guidance based on your specific circumstances, the nature of your business, and the regulatory requirements governing foreign investment.</p>
                          </div>
                      </div>
                  </div>
                  <!-- card -->
                    </div>
              </div>
              <!-- faq accordion -->
          </div>
      </div>
      <!-- container -->
  </div>
</div>
<!-- india end -->
