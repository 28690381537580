<div *ngIf="selectCountryName == 'Dubai'"  class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing min-vh-100 mt-130" >
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="experience-item">
                    <h2 class="title pb-15">FAQ ON SETTING UP AN ENTITY IN DUBAI, UAE</h2>
                </div>
                <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a class="w-100" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                WHY CHOOSE UAE TO INCORPORATE A COMPANY?
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">

                                    <p class="pb-15">The UAE, and Dubai particularly, has, for good reason, garnered a lot of media attention recently. Dubai enjoys a central location between Europe, Africa, and Asia. This makes it a prime business destination for many sectors and industries. You will not find a city as cosmopolitan or as advanced anywhere within a five-hour flight from Dubai. The city’s highly regulated environment adheres to international best practices operating with minimal rates for personal and corporate taxes. Businesses in Dubai enjoy 100% capital repatriation with no currency restrictions. This level of financial freedom is rarely found in areas with such a developed business environment. One can find numerous opportunities in the UAE and across a wide range of sectors. Dubai is a leading commercial center with state-of-the-art infrastructure and outstanding business environment. Other reasons include:</p>
                                     <p class="pb-15 pl-3">•	A liberal economy fully integrated with the world economy. <br>
                                        •	The city is central, located between Europe, Asia, and Africa, attracting approximately five million investors and tourists each year.
                                        <br>
                                        •	One of the safest cities in the world.
                                        <br>
                                        •	More than 200 nationalities live in harmony and call Dubai their home.
                                        <br>
                                        •	Dubai is well-serviced by international and local financial institutions.
                                        <br>
                                        •	The education system in Dubai is suitable for all cultures and languages.
                                        <br>
                                        •	Dubai enjoys world class healthcare.
                                        <br>
                                        </p>


                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                   	WHAT TYPES OF UAE COMPANIES CAN I START?
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15"> In the UAE, companies can be started as mainland businesses, free zone, or offshore businesses. For a business setup in the UAE mainland, a local service agent is required, and the license is provided by the Department of Economic Department in Dubai. In a free zone, you can operate within a specific geographical region & outside UAE without local service agent, Tax-free policies for both corporate and income tax and the business is licensed by the respective free zone authority.
                                        <br>
                                        Note that you can enjoy 100% foreign ownership of your business in both these business zones, i.e., mainland and free zones. The company formation experts at BFAG help you incorporate your dream venture in the UAE by taking care of licensing, documentation, translation work, and much more.
                                        <br>
                                        An offshore business in UAE allows you to safeguard your wealth and assets, but you can only trade outside UAE. A certificate of incorporation is provided to the company and not a trade license.
                                        <br>
                                        Mostly all types of companies can be started in the UAE and there are more than thousands of activities registered and allowed by the Economic Department. Yet, it is best to be sure before getting started.

                                        </p>

                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                HOW TO START AN LLC IN DUBAI?
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">To establish a limited liability company in Dubai Mainland or anywhere else in the UAE, you need to reserve a trade name and obtain preliminary approvals. You also need to get a memorandum of association of the company and submit the lease contract and other necessary documents to the Department of Economic Development.
                                        <br>
                                        You will also need to partner with a UAE national and may require additional certifications from authorities, a rented office space also is a must.
                                        With BFAG, we can guide you through full setup and you can start a business in Dubai and relax about the requirements.
                                        </p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                WHAT ARE THE BENEFITS OF A BUSINESS SETUP IN MAINLAND UAE?
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">As a mainland UAE company, you can do business with any country and trade freely. You get access to affordable rental office spaces as you are not geographically limited, compared to free zone companies who can rent office space only inside the free zones and have limited business scope.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                WHAT IS A FREE ZONE?
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">Free Zones are areas designated for international businesses & low-cost setup, which enables 100% foreign ownership. Companies in each free zone can trade outside UAE as well as trade with business in UAE with limited scope.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingSex">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSex" aria-expanded="false" aria-controls="collapseSex">
                               WHAT ARE THE BENEFITS OF A BUSINESS SETUP IN UAE FREE ZONES?
                                </a>
                            </div>
                            <div id="collapseSex" class="collapse" aria-labelledby="headingSex" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15"> As a freezone company, you can have full ownership of your company with low-cost set-ups and get access to free-hold property anywhere in Dubai. Not all but some of the free zone business setups in Dubai are exempted from paying taxes and import or export duties.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingSeven">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                HOW MUCH TIME DOES IT TAKE TO START A BUSINESS IN DUBAI?
                                </a>
                            </div>
                            <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">With BFAG, company formation in Dubai Mainland or Free Zone can take around 8-10 business days, however, it is a must that you have the right documents with you. Being trusted partners and over 15 years of association with the Free Zones & Department of Economic Development in Dubai, we can establish businesses in UAE 8-10 business days.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingEight">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                WHAT ARE THE TYPES OF UAE BUSINESS LICENSE OR TRADE LICENSES IN DUBAI?
                                </a>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">There are four types of Business License or Trade Licenses in the UAE; Commercial License –issued to a company engaged in any kind of trading activity, Industrial License –issued to a company engaged any kind of manufacturing or industrial activity, Professional License – issued to service providers, artisans, professionals and craftsmen and the last one is Tourism License – issued to travel, tourism and hospitality companies in the UAE.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingNine">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                WHAT ARE THE DOCUMENTS REQUIRED FOR BUSINESS LICENSE RENEWAL IN UAE?
                                </a>
                            </div>
                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">To renew your trade license, you need to provide a certificate of authenticity for the lease contract issued from the Real Estate Regulatory Agency as well as a new Memorandum of Association, with the necessary modifications.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingTen">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                DO I NEED TO VISIT THE UAE TO INCORPORATE THE COMPANY?
                                </a>
                            </div>
                            <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">We can incorporate offshore companies without needing your physical presence. However, in the event of incorporation in the free zones and onshore jurisdictions, we can start the process without you, but your presence will eventually be required. You are expected to sign formal incorporation documents at the notary public here in the UAE.
                                        <br>
                                        However, if you were to provide us with a power of attorney, certified and attested by a UAE embassy or consulate abroad, we can complete the incorporation procedures without requiring you to visit the UAE.
                                        </p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                          <!-- card -->
                          <div class="card">
                            <div class="card-header" id="headingElev">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseElev" aria-expanded="false" aria-controls="collapseElev">
                                DO I REQUIRE A RESIDENCE VISA?
                                </a>
                            </div>
                            <div id="collapseElev" class="collapse" aria-labelledby="headingElev" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">Anyone wishing to live and work in the UAE needs a residence visa. To employ staff in your organization, a manager of the entity must have a residence visa. This will then enable the company to recruit staff. Offshore incorporations do not require a residence visa as they do not entitle the holder to do business in the UAE. The free zone incorporations allow for issuing residence visas depending upon the size of the rented or purchased premises of the establishment.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                           <!-- card -->
                           <div class="card">
                            <div class="card-header" id="headingTlve">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTlve" aria-expanded="false" aria-controls="collapse">
                                HOW CAN I OPEN A COMPANY’S BANK ACCOUNT IN DUBAI?
                                </a>
                            </div>
                            <div id="collapseTlve" class="collapse" aria-labelledby="headingTlve" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">Opening a bank account in the UAE can be challenging due to the Anti-Money Laundering and Combatting Financing of Terrorism (AML-CFT) laws. UAE residents with a residence visa find it easier to open an account, while non-residents face additional KYC procedures. Offshore bank accounts in the UAE offer advantages such as convenience, wealth management services, and easy international payments. The AML-CFT laws have increased due diligence requirements, including background checks on shareholders, scrutiny of high-risk business activities, verification of physical office addresses, and checks on business dealings with high-risk countries and politically exposed persons. We can assist with navigating these challenges and opening a bank account in UAE.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                         <!-- card -->
                         <!-- <div class="card">
                          <div class="card-header" id="headingTeen">
                              <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTeen" aria-expanded="false" aria-controls="collapseTeen">
                                HOW TO GET IN TOUCH?
                              </a>
                          </div>
                          <div id="collapseTeen" class="collapse" aria-labelledby="headingTeen" data-parent="#accordionExample">
                              <div class="card-body">
                                  <p class="pb-15"> for details please visit our website www.bostonfagroup.com , alternatively you can also write to; <br>
                                    bfagconnect@bostonfagroup.com / sameer.p@bostonfagroup.com <br>
                                    or call us on +1 609 937 7291.</p>
                              </div>
                          </div>
                      </div> -->
                      <!-- card -->
                      </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- container -->
    </div>
  </div>
  <!-- Dubai end -->
