<!-- dubai starts -->

<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div
      class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="
        background: url('../../../../assets/images/service/consulting.png') no-repeat center;
      background-size: cover;
      height: 430px;
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase text-center ">
          Consulting & Advisory
        </h3>
        <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
      </div>
    </div>
    <div
      class="container-fluid d-flex justify-content-center align-content-center bg-white"
    >
      <div class="about-faq-area-custom horizon-hrms bg_cover">

          <div class="row">
            <div class="col-md-4">
              <div class="faq-accordion-3 pt-0">
              <div class="accordion" id="accordionExample1">
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px; background-color: #4180521A;">
                <div class="card-header" id="headingSev">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                    aria-expanded="true" aria-controls="collapseSevv" style="color: #418052;">
                    <span (click)="onAccounting()">Accounting & Transaction Processing</span>
                  </a>
                </div>
                <div id="collapseSevv" class="collapse " aria-labelledby="headingSev" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark serviceul">
                        <li routerLink="/accounting-services" style="color: black">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Accounts Payable
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingEight">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                    aria-expanded="false" aria-controls="collapseEight" style="color: #418052;">
                    <span (click)="onTax()">Tax & Regulatory Compliances</span>
                  </a>
                </div>
                <div id="collapseEight" class="collapse " aria-labelledby="headingEight" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark serviceul">
                        <li class="shli" routerLink="/coordination-and-assistance-for-vat-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         VAT Advisory
                        </li>
                        <li  routerLink="/coordination-and-assistance-for-tax-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Corporate Tax Advisory
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingNine">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                    aria-expanded="false" aria-controls="collapseNine" style="color: #418052;">
                    <span (click)="onAudit()"> Audit & Assurance Support</span>
                  </a>
                </div>
                <div id="collapseNine" class="collapse " aria-labelledby="headingNine" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark pt-4 serviceul">
                        <li  routerLink="/statutory-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Statutory Audit
                        </li>
                        <li  routerLink="/internal-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Internal Audit
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->

              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingTen">
                  <a  href="" data-toggle="collapse" data-target="#collapseTen"
                    aria-expanded="false" aria-controls="collapseTen" style="color: #418052;">
                    <span (click)="onConsulting()">Consulting & Advisory</span>
                  </a>
                </div>
                <div id="collapseTen" class="collapse show" aria-labelledby="headingTen" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark pt-4 serviceul">
                        <li  routerLink="/business-setup">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Setting up an entity in Dubai
                        </li>
                        <li class="shli" routerLink="/business-plan-preparation">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Business Plan Preparation
                        </li>
                        <li routerLink="/business-valuation-service">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Business Valuation
                        </li>
                        <li routerLink="/mergers-and-acquisitions">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Mergers and Acquisitions (M&A)
                        </li>
                        <li routerLink="/strategic-cfo-services">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Strategic CFO
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->
              </div>
              </div>

            </div>
            <div class="col-md-8">
              <div style="margin-top: 1rem">
                <h3 id="business-plan-preparation" style=" color: #418052 ;">Business Plan Preparation</h3>
                <p class="mb-15 mt-20 text-dark servicep">
                  A well-crafted business plan is essential for guiding your company's
                  growth, attracting investors, and securing financing. At BFAG, we
                  understand the importance of a strategic roadmap for your business
                  success.
                </p>
                <p class="mb-15 mt-20 text-dark servicep">
                  A professional business plan should be curated with an understanding
                  about the purpose of the business, extensive market research,
                  competitor analysis, financial projections and convey its growth
                  potential which can be presented to investors or lenders. BFAG can
                  help you develop a winning business plan to drive your company
                  forward.
                </p>
              </div>
              <div class="about-faq-area-custom horizon-hrms gray-bg clearfix" style="background-color: #4180521A;">
                <div class="container">
                  <div class="row" style="display: block">
                    <div
                      class="faq-accordion-2"
                      style="padding-bottom: 20px; padding-top: 20px"
                    >
                      <div class="accordion" id="accordionExample2">
                        <div class="col-lg-6">
                          <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".2s"
                          >
                            <div class="card-header" id="headingEleven">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseEleven"
                                aria-expanded="false"
                                aria-controls="collapseEleven"
                                style=" color: #418052A1;"
                              >
                                Customized Business Plans
                              </a>
                            </div>
                            <div
                              id="collapseEleven"
                              class="collapse"
                              aria-labelledby="headingEleven"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;">
                                  BFAG understands that every business is unique. Our
                                  business plan preparation services are tailored to
                                  your specific goals, industry, and target audience.
                                  We collaborate closely with you to understand your
                                  vision, analyze market trends, and develop a
                                  strategic plan that sets you apart from the
                                  competition.
                                </p>
                              </div>
                            </div>
                          </div>
                          <!-- card -->
                          <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".3s"
                          >
                            <div class="card-header" id="headingTwelve">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseTwelve"
                                aria-expanded="false"
                                aria-controls="collapseTwelve"
                                style=" color: #418052A1;"
                              >
                                Market Research and Competitive Analysis
                              </a>
                            </div>
                            <div
                              id="collapseTwelve"
                              class="collapse"
                              aria-labelledby="headingTwelve"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;">
                                  Our team conducts thorough market research and
                                  competitive analysis to gather valuable insights
                                  about your industry. We identify market trends,
                                  customer preferences, and key competitors, enabling
                                  you to make informed decisions and position your
                                  business for success.
                                </p>
                              </div>
                            </div>
                          </div>
                          <!-- card -->
                          <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".4s"
                          >
                            <div class="card-header" id="headingThirteen">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseThirteen"
                                aria-expanded="false"
                                aria-controls="collapseThirteen"
                                style=" color: #418052A1;"
                              >
                                Financial Projections and Forecasting
                              </a>
                            </div>
                            <div
                              id="collapseThirteen"
                              class="collapse"
                              aria-labelledby="headingThirteen"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;">
                                  BFAG helps you develop accurate financial
                                  projections and forecasting models that showcase
                                  your business's growth potential. We analyze your
                                  financial data, market conditions, and industry
                                  benchmarks to create realistic revenue forecasts,
                                  expense budgets, and cash flow projections,
                                  providing a solid foundation for your business plan.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".5s"
                          >
                            <div class="card-header" id="headingFourteen">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseFourteen"
                                aria-expanded="false"
                                aria-controls="collapseFourteen"
                                style=" color: #418052A1;"
                              >
                                Investor-Ready Presentations
                              </a>
                            </div>
                            <div
                              id="collapseFourteen"
                              class="collapse"
                              aria-labelledby="headingFourteen"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;" >
                                  If you're seeking funding or investment, BFAG's
                                  business plan preparation services can help you
                                  create investor-ready presentations. We craft
                                  compelling narratives, showcasing your unique value
                                  proposition, market opportunities, and financial
                                  projections. Our goal is to make your business plan
                                  stand out and attract potential investors.
                                </p>
                              </div>
                            </div>
                          </div>

                          <!-- card -->
                        </div>

                        <div class="col-lg-6">
                          <!-- card -->
                          <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".5s"
                          >
                            <div class="card-header" id="headingSixteen">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseSixteen"
                                aria-expanded="false"
                                aria-controls="collapseSixteen" style=" color: #418052A1;"
                              >
                                Strategic Business Roadmap
                              </a>
                            </div>
                            <div
                              id="collapseSixteen"
                              class="collapse"
                              aria-labelledby="headingSixteen"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;">
                                  A well-designed business plan serves as a roadmap
                                  for your company's future. BFAG helps you define
                                  clear goals, objectives, and actionable strategies.
                                  We assist you in outlining marketing plans,
                                  operational frameworks, and growth initiatives,
                                  providing a step-by-step guide to achieve your
                                  long-term success.
                                </p>
                              </div>
                            </div>
                          </div>
                          <!-- card -->
                          <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".6s"
                          >
                            <div class="card-header" id="headingSeventeen">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseSeventeen"
                                aria-expanded="false"
                                aria-controls="collapseSeventeen" style="   color: #418052A1;"
                              >
                                Collaboration and Expert Guidance
                              </a>
                            </div>
                            <div
                              id="collapseSeventeen"
                              class="collapse"
                              aria-labelledby="headingSeventeen"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;">
                                  BFAG believes in collaborative partnerships. We work
                                  closely with you throughout the business plan
                                  preparation process, ensuring that your insights and
                                  expertise are incorporated. Our experienced
                                  professionals provide expert guidance, offer
                                  strategic recommendations, and help you refine your
                                  business model for optimal results.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".7s"
                          >
                            <div class="card-header" id="headingEightteen">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseEightteen"
                                aria-expanded="false"
                                aria-controls="collapseEightteen" style=" color: #418052A1;"
                              >
                                Confidentiality and Data Security
                              </a>
                            </div>
                            <div
                              id="collapseEightteen"
                              class="collapse"
                              aria-labelledby="headingEightteen"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;">
                                  BFAG prioritizes the confidentiality and security of
                                  your business information. We adhere to strict data
                                  protection protocols, safeguarding your sensitive
                                  data throughout the business plan preparation
                                  process. You can trust us to handle your proprietary
                                  information with utmost care and professionalism.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- faq accordion -->
                    </div>
                  </div>
                  <!-- row -->
                </div>
                <!-- container -->
              </div>
              <p class="mb-15 mt-20 text-dark servicep">
                Drive the success of your business with BFAG's professional business
                plan preparation services. Our experienced team will help you develop
                a customized business plan that aligns with your goals, attracts
                investors, and sets a clear roadmap for your company's growth. Benefit
                from our market research, financial expertise, and strategic guidance
                to position your business for long-term success. Contact BFAG today to
                embark on your journey towards a winning business plan.
              </p>
            </div>
          </div>
          <!-- row -->

      </div>

    </div>
  </div>
</section>
<!-- Dubai Ends -->

<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div
      class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="
        background: url('../../../../assets/images/service/consulting.png') no-repeat center;
      background-size: cover;
      height: 430px;
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase text-center">Consulting & Advisory</h3>
        <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
      </div>
    </div>
    <div
      class="container-fluid d-flex justify-content-center align-content-center bg-white"
    >
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                  <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev" >
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                      aria-expanded="true" aria-controls="collapseSevv" >
                       <span (click)="onAccounting()"> Accounting & Transaction Processing</span>
                      </a>
                    </div>
                    <div id="collapseSevv" class="collapse " aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div class="list-group">
                          <ul class="text-dark serviceul">

                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Receivable
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Fixed Assets Accounting
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Budgeting & Variance Analysis
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Consolidation Reporting & GAAP Conversion
                            </li>
                            <li routerLink="/small-business-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Small Business Accounting
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li routerLink="/individual-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Individual Tax Return
                            </li>
                            <li routerLink="/corporate-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Tax Return
                            </li>
                            <li routerLink="/sales-and-use-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Sales & Use Tax
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span (click)="onPayroll()">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Payroll Processing
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a  href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span (click)="onConsulting()">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse show" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Setting up an entity in the US
                            </li>
                            <li routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Global Corporate Structuring
                            </li>
                            <li class="shli" routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Plan Preparation
                            </li>
                            <li routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#business-valuation-service"> Business Valuation</a>
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div id="business-plan-preparation" style="margin-top: 1rem">
                    <h3>Business Plan Preparation</h3>
                    <p class="mb-15 mt-20 text-dark servicep">
                      A well-crafted business plan is essential for guiding your company's
                      growth, attracting investors, and securing financing. At BFAG, we
                      understand the importance of a strategic roadmap for your business
                      success.
                    </p>
                    <p class="mb-15 mt-20 text-dark servicep">
                      A professional business plan should be curated with an understanding
                      about the purpose of the business, extensive market research,
                      competitor analysis, financial projections and convey its growth
                      potential which can be presented to investors or lenders. BFAG can
                      help you develop a winning business plan to drive your company
                      forward.
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                      <div class="row" style="display: block">
                        <div
                          class="faq-accordion-2"
                          style="padding-bottom: 20px; padding-top: 20px"
                        >
                          <div class="accordion" id="accordionExample2">
                            <div class="col-lg-6">
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".2s"
                              >
                                <div class="card-header" id="headingEleven">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseEleven"
                                    aria-expanded="false"
                                    aria-controls="collapseEleven"
                                  >
                                    Customized Business Plans
                                  </a>
                                </div>
                                <div
                                  id="collapseEleven"
                                  class="collapse"
                                  aria-labelledby="headingEleven"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG understands that every business is unique. Our
                                      business plan preparation services are tailored to
                                      your specific goals, industry, and target audience.
                                      We collaborate closely with you to understand your
                                      vision, analyze market trends, and develop a
                                      strategic plan that sets you apart from the
                                      competition.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".3s"
                              >
                                <div class="card-header" id="headingTwelve">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseTwelve"
                                    aria-expanded="false"
                                    aria-controls="collapseTwelve"
                                  >
                                    Market Research and Competitive Analysis
                                  </a>
                                </div>
                                <div
                                  id="collapseTwelve"
                                  class="collapse"
                                  aria-labelledby="headingTwelve"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      Our team conducts thorough market research and
                                      competitive analysis to gather valuable insights
                                      about your industry. We identify market trends,
                                      customer preferences, and key competitors, enabling
                                      you to make informed decisions and position your
                                      business for success.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".4s"
                              >
                                <div class="card-header" id="headingThirteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseThirteen"
                                    aria-expanded="false"
                                    aria-controls="collapseThirteen"
                                  >
                                    Financial Projections and Forecasting
                                  </a>
                                </div>
                                <div
                                  id="collapseThirteen"
                                  class="collapse"
                                  aria-labelledby="headingThirteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG helps you develop accurate financial
                                      projections and forecasting models that showcase
                                      your business's growth potential. We analyze your
                                      financial data, market conditions, and industry
                                      benchmarks to create realistic revenue forecasts,
                                      expense budgets, and cash flow projections,
                                      providing a solid foundation for your business plan.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".4s"
                              >
                                <div class="card-header" id="headingFourteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseFourteen"
                                    aria-expanded="false"
                                    aria-controls="collapseFourteen"
                                  >
                                    Investor-Ready Presentations
                                  </a>
                                </div>
                                <div
                                  id="collapseFourteen"
                                  class="collapse"
                                  aria-labelledby="headingFourteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      If you're seeking funding or investment, BFAG's
                                      business plan preparation services can help you
                                      create investor-ready presentations. We craft
                                      compelling narratives, showcasing your unique value
                                      proposition, market opportunities, and financial
                                      projections. Our goal is to make your business plan
                                      stand out and attract potential investors.
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <!-- card -->
                            </div>

                            <div class="col-lg-6">
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".6s"
                              >
                                <div class="card-header" id="headingSixteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseSixteen"
                                    aria-expanded="false"
                                    aria-controls="collapseSixteen"
                                  >
                                    Strategic Business Roadmap
                                  </a>
                                </div>
                                <div
                                  id="collapseSixteen"
                                  class="collapse"
                                  aria-labelledby="headingSixteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      A well-designed business plan serves as a roadmap
                                      for your company's future. BFAG helps you define
                                      clear goals, objectives, and actionable strategies.
                                      We assist you in outlining marketing plans,
                                      operational frameworks, and growth initiatives,
                                      providing a step-by-step guide to achieve your
                                      long-term success.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".4s"
                              >
                                <div class="card-header" id="headingSeventeen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseSeventeen"
                                    aria-expanded="false"
                                    aria-controls="collapseSeventeen"
                                  >
                                    Collaboration and Expert Guidance
                                  </a>
                                </div>
                                <div
                                  id="collapseSeventeen"
                                  class="collapse"
                                  aria-labelledby="headingSeventeen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG believes in collaborative partnerships. We work
                                      closely with you throughout the business plan
                                      preparation process, ensuring that your insights and
                                      expertise are incorporated. Our experienced
                                      professionals provide expert guidance, offer
                                      strategic recommendations, and help you refine your
                                      business model for optimal results.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".5s"
                              >
                                <div class="card-header" id="headingEightteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseEightteen"
                                    aria-expanded="false"
                                    aria-controls="collapseEightteen"
                                  >
                                    Confidentiality and Data Security
                                  </a>
                                </div>
                                <div
                                  id="collapseEightteen"
                                  class="collapse"
                                  aria-labelledby="headingEightteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG prioritizes the confidentiality and security of
                                      your business information. We adhere to strict data
                                      protection protocols, safeguarding your sensitive
                                      data throughout the business plan preparation
                                      process. You can trust us to handle your proprietary
                                      information with utmost care and professionalism.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- faq accordion -->
                        </div>
                      </div>
                      <!-- row -->
                    </div>
                    <!-- container -->
                  </div>
                  <p class="mb-15 mt-20 text-dark servicep">
                    Drive the success of your business with BFAG's professional business
                    plan preparation services. Our experienced team will help you develop
                    a customized business plan that aligns with your goals, attracts
                    investors, and sets a clear roadmap for your company's growth. Benefit
                    from our market research, financial expertise, and strategic guidance
                    to position your business for long-term success. Contact BFAG today to
                    embark on your journey towards a winning business plan.
                  </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>
  </div>
</section>
<!-- USA ENDS -->
<!-- INDIA STARTS -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div
      class="d-flex justify-content-center align-items-center banner-image bmTop"
      style="
        background: url('../../../../assets/images/service/consulting.png') no-repeat center;
      background-size: cover;
      height: 430px;
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase text-center">Consulting & Advisory</h3>
        <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
      </div>
    </div>
    <div
      class="container-fluid d-flex justify-content-center align-content-center bg-white"
    >
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                  <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev" >
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                      aria-expanded="true" aria-controls="collapseSevv" >
                       <span (click)="onAccounting()"> Accounting & Transaction Processing</span>
                      </a>
                    </div>
                    <div id="collapseSevv" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div class="list-group">
                          <ul class="text-dark serviceul">

                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Receivable
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Fixed Assets Accounting
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Budgeting & Variance Analysis
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Consolidation Reporting & GAAP Conversion
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li routerLink="/income-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Income Tax
                            </li>
                            <li  routerLink="/goods-and-service-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Goods & Service Tax
                            </li>
                            <li routerLink="/corporate-secretarial-compliance">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Corporate Secretarial Compliance
                            </li>
                            <li  routerLink="/fema-advisory">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             FEMA Compliance
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span (click)="onPayroll()">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Payroll - Compensation & Benefit Management
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a  href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span (click)="onConsulting()">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse show" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Setting up an entity in the India
                            </li>
                            <li routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Global Corporate Structuring
                            </li>
                            <li class="shli" routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Plan Preparation
                            </li>
                            <li routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Valuation
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingElevenStartup">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseElevenStartup"
                        aria-expanded="false" aria-controls="collapseElevenStartup">
                        <span (click)="onStartup()">Startup Solution</span>
                      </a>
                    </div>
                    <div id="collapseElevenStartup" class="collapse" aria-labelledby="headingElevenStartup" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/startup-solution">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Startup Solution
                            </li>
                            <li routerLink="/fund-raising">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Fund Raise
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3 id="business-plan-preparation">Business Plan Preparation</h3>
                    <p class="mb-15 mt-20 text-dark servicep">
                      A well-crafted business plan is essential for guiding your company's
                      growth, attracting investors, and securing financing. At BFAG, we
                      understand the importance of a strategic roadmap for your business
                      success.
                    </p>
                    <p class="mb-15 mt-20 text-dark servicep">
                      A professional business plan should be curated with an understanding
                      about the purpose of the business, extensive market research,
                      competitor analysis, financial projections and convey its growth
                      potential which can be presented to investors or lenders. BFAG can
                      help you develop a winning business plan to drive your company
                      forward.
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                      <div class="row" style="display: block">
                        <div
                          class="faq-accordion-2"
                          style="padding-bottom: 20px; padding-top: 20px"
                        >
                          <div class="accordion" id="accordionExample2">
                            <div class="col-lg-6">
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".2s"
                              >
                                <div class="card-header" id="headingEleven">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseEleven"
                                    aria-expanded="false"
                                    aria-controls="collapseEleven"
                                  >
                                    Customized Business Plans
                                  </a>
                                </div>
                                <div
                                  id="collapseEleven"
                                  class="collapse"
                                  aria-labelledby="headingEleven"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG understands that every business is unique. Our
                                      business plan preparation services are tailored to
                                      your specific goals, industry, and target audience.
                                      We collaborate closely with you to understand your
                                      vision, analyze market trends, and develop a
                                      strategic plan that sets you apart from the
                                      competition.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".3s"
                              >
                                <div class="card-header" id="headingTwelve">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseTwelve"
                                    aria-expanded="false"
                                    aria-controls="collapseTwelve"
                                  >
                                    Market Research and Competitive Analysis
                                  </a>
                                </div>
                                <div
                                  id="collapseTwelve"
                                  class="collapse"
                                  aria-labelledby="headingTwelve"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      Our team conducts thorough market research and
                                      competitive analysis to gather valuable insights
                                      about your industry. We identify market trends,
                                      customer preferences, and key competitors, enabling
                                      you to make informed decisions and position your
                                      business for success.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".4s"
                              >
                                <div class="card-header" id="headingThirteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseThirteen"
                                    aria-expanded="false"
                                    aria-controls="collapseThirteen"
                                  >
                                    Financial Projections and Forecasting
                                  </a>
                                </div>
                                <div
                                  id="collapseThirteen"
                                  class="collapse"
                                  aria-labelledby="headingThirteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG helps you develop accurate financial
                                      projections and forecasting models that showcase
                                      your business's growth potential. We analyze your
                                      financial data, market conditions, and industry
                                      benchmarks to create realistic revenue forecasts,
                                      expense budgets, and cash flow projections,
                                      providing a solid foundation for your business plan.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".5s"
                              >
                                <div class="card-header" id="headingFourteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseFourteen"
                                    aria-expanded="false"
                                    aria-controls="collapseFourteen"
                                  >
                                    Investor-Ready Presentations
                                  </a>
                                </div>
                                <div
                                  id="collapseFourteen"
                                  class="collapse"
                                  aria-labelledby="headingFourteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      If you're seeking funding or investment, BFAG's
                                      business plan preparation services can help you
                                      create investor-ready presentations. We craft
                                      compelling narratives, showcasing your unique value
                                      proposition, market opportunities, and financial
                                      projections. Our goal is to make your business plan
                                      stand out and attract potential investors.
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <!-- card -->
                            </div>

                            <div class="col-lg-6">
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".5s"
                              >
                                <div class="card-header" id="headingSixteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseSixteen"
                                    aria-expanded="false"
                                    aria-controls="collapseSixteen"
                                  >
                                    Strategic Business Roadmap
                                  </a>
                                </div>
                                <div
                                  id="collapseSixteen"
                                  class="collapse"
                                  aria-labelledby="headingSixteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      A well-designed business plan serves as a roadmap
                                      for your company's future. BFAG helps you define
                                      clear goals, objectives, and actionable strategies.
                                      We assist you in outlining marketing plans,
                                      operational frameworks, and growth initiatives,
                                      providing a step-by-step guide to achieve your
                                      long-term success.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".6s"
                              >
                                <div class="card-header" id="headingSeventeen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseSeventeen"
                                    aria-expanded="false"
                                    aria-controls="collapseSeventeen"
                                  >
                                    Collaboration and Expert Guidance
                                  </a>
                                </div>
                                <div
                                  id="collapseSeventeen"
                                  class="collapse"
                                  aria-labelledby="headingSeventeen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG believes in collaborative partnerships. We work
                                      closely with you throughout the business plan
                                      preparation process, ensuring that your insights and
                                      expertise are incorporated. Our experienced
                                      professionals provide expert guidance, offer
                                      strategic recommendations, and help you refine your
                                      business model for optimal results.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".7s"
                              >
                                <div class="card-header" id="headingEightteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseEightteen"
                                    aria-expanded="false"
                                    aria-controls="collapseEightteen"
                                  >
                                    Confidentiality and Data Security
                                  </a>
                                </div>
                                <div
                                  id="collapseEightteen"
                                  class="collapse"
                                  aria-labelledby="headingEightteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG prioritizes the confidentiality and security of
                                      your business information. We adhere to strict data
                                      protection protocols, safeguarding your sensitive
                                      data throughout the business plan preparation
                                      process. You can trust us to handle your proprietary
                                      information with utmost care and professionalism.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- faq accordion -->
                        </div>
                      </div>
                      <!-- row -->
                    </div>
                    <!-- container -->
                  </div>
                  <p class="mb-15 mt-20 text-dark servicep">
                    Drive the success of your business with BFAG's professional business
                    plan preparation services. Our experienced team will help you develop
                    a customized business plan that aligns with your goals, attracts
                    investors, and sets a clear roadmap for your company's growth. Benefit
                    from our market research, financial expertise, and strategic guidance
                    to position your business for long-term success. Contact BFAG today to
                    embark on your journey towards a winning business plan.
                  </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>
  </div>
</section>
<!-- INDIA ENDS -->
