<!-- start dubai -->
<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 ">
  <div class="min-vh-100 mt-150 divmart">
    <div class="d-flex justify-content-center align-items-center banner-image" style="
      background: url('../../../../assets/images/media\ hits/media.png') no-repeat center;
      height: 430px;
      background-size: cover;
    ">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase">NewsLetters</h3>

      </div>
    </div>
    <div class="container pt-4">
     <div class="d-flex flex-column justify-content-center align-items-center" style="width: 100%;">
        <div class="col-lg-12 mb-100">
          <div class="mb-30">
            <h3>All Time News Letters</h3>
          </div>
          <ng-container *ngIf="newsletter">
            <ng-container *ngFor="let news of newsletter" >
              <div
                class="d-flex flex-column justify-content-center align-items-center shadow mb-30"
                style="
                  background: url({{news.imgFile.secure_url}});
                  background-repeat: no-repeat;
                  background-size: cover;
                  border-radius: 17px;
                "
              >
                <div
                  class="row d-flex justify-content-between  pl-3 pr-3"
                  style="min-width: 100%"
                >
                  <div
                    class="bg-white mt-30"
                    style="border-radius: 40px; padding: 20px 35px"
                  >
                    <h4>{{news.title}}</h4>
                  </div>
                  <span
                      class="bg-transparent border text-white d-flex justify-content-center align-items-center mt-30"
                      style="border-radius: 40px; width:12rem"
                    >
                    {{news.createdAt | date: 'yyyy/MM/dd'}}
                    </span>
                </div>
                <div
                  class="bg-white text-dark border-0 p-3 text-left"
                  style="
                    max-width: 100%;
                    border-radius: 0 0 17px 17px;
                    margin-top: 24rem;
                    font-size: 21px;
                    font-weight: 600;
                  "
                >
                  {{news.shortDesc}} <a style="color: #21ac51;" [routerLink]="['/newsletter-details', news._id]">Read More.....</a>
                </div>
              </div>
            </ng-container>
          </ng-container>



        </div>
      </div>
    </div>



  </div>
</section>

<!-- dubai end -->

<!-- start INdia -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 ">
  <div class="min-vh-100 mt-150 divmart">
    <div class="d-flex justify-content-center align-items-center banner-image" style="
      background: url('../../../../assets/images/media\ hits/media.png') no-repeat center;
      height: 430px;
      background-size: cover;
    ">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase">NewsLetters</h3>

      </div>
    </div>
    <div class="container pt-4">
      <!-- <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="row mb-100">
          <div class="col-lg-8">
            <div class="mb-30">
              <h1>Best Of The Month</h1>
            </div>
            <div routerLink="/faq-on-gst"
              class="d-flex flex-column justify-content-center align-items-center"
              style="
                background: url('../../../../assets/images/newsletter/img 1.png');
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 17px;
                cursor: pointer;
              "
            >
            <div
            class="row d-flex justify-content-between mt-30 pl-3 pr-3"
            style="min-width: 100%"
          >
            <div
              class="bg-white"
              style="border-radius: 40px; padding: 20px 35px"
            >
              <h4>FAQ ON GST</h4>
            </div>
            <div
              class="bg-transparent text-white d-flex justify-content-center align-items-center"
              style="border-radius: 40px"
            >

            </div>
          </div>
              <div
                class="bg-white text-dark border-0 p-3  text-left"
                style="
                  max-width: 95%;
                  margin-bottom: 30px;
                  margin-top: 315px;
                  border-radius: 17px;
                "
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of
                type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                <a style="color: #0951A4;">Read More.....</a>

              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex flex-column">
            <div
              class="d-flex flex-column justify-content-center align-items-center"
              style="
                background: url(../../../../assets/images/newsletter/img\ 2.png);
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 17px;
              "
            >
              <div
                class="bg-white mt-3"
                style="
                  border-radius: 17px;
                  max-width: 18rem;
                  margin: 2rem 1rem 0 1rem;
                  padding: 10px 60px;
                "
              >
                <h6 class="text-center" style="font-weight: 600">
                  NCLT AND NCLAT
                </h6>
              </div>
              <div
                class="bg-white text-dark border-0 p-3"
                style="
                  border-radius: 17px;
                  font-size: 15px;
                  max-width: 18rem;
                  margin: 1rem;
                "
              >
                Overview of Goods and Services Tax (GST) Q 1. What is Goods and
                Service Tax (GST)? Ans. It is a destination based tax on
                consumption of goods and services. It is proposed to be levied at
                all stages right from manufacture <a style="color: #0951A4;">Read More.....</a>
              </div>
            </div>
            <div
              class="d-flex flex-column justify-content-center align-items-center"
              style="
                background: url(../../../../assets/images/newsletter/img\ 3.png);
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 17px;
                margin-top: 2.2rem;
              "
            >
              <div
                class="bg-transparent border-white border"
                style="
                  border-radius: 17px;
                  max-width: 18rem;
                  margin: 13rem 2rem 0 2rem;
                  padding: 10px 30px;
                "
              >
                <h6 class="text-center text-white" style="font-weight: 600">
                  NCLT AND NCLAT
                </h6>
              </div>
              <div
                class="bg-white mt-3"
                style="
                  border-radius: 17px;
                  max-width: 18rem;
                  margin: 1rem;
                  padding: 10px 60px;
                "
              >
                <h6 class="text-center" style="font-weight: 600">
                  View All <i class="far fa-arrow-right"></i>
                </h6>
              </div>

            </div>
          </div>
        </div>
      </div> -->

      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="col-lg-12 mb-100">
          <div class="mb-30">
            <h3>All Time News Letters</h3>
          </div>
          <ng-container *ngIf="newsletter">
            <ng-container *ngFor="let news of newsletter" >
              <div
                class="d-flex flex-column justify-content-center align-items-center shadow mb-30"
                style="
                  background: url({{news.imgFile.secure_url}});
                  background-repeat: no-repeat;
                  background-size: cover;
                  border-radius: 17px;
                "
              >
                <div
                  class="row d-flex justify-content-between  pl-3 pr-3"
                  style="min-width: 100%"
                >
                  <div
                    class="bg-white mt-30"
                    style="border-radius: 40px; padding: 20px 35px"
                  >
                    <h4>{{news.title}}</h4>
                  </div>
                  <span
                      class="bg-transparent border text-white d-flex justify-content-center align-items-center mt-30"
                      style="border-radius: 40px; width:12rem"
                    >
                    {{news.createdAt | date: 'yyyy/MM/dd'}}
                    </span>
                </div>
                <div
                  class="bg-white text-dark border-0 p-3 text-left"
                  style="
                    max-width: 100%;
                    border-radius: 0 0 17px 17px;
                    margin-top: 24rem;
                    font-size: 21px;
                    font-weight: 600;
                  "
                >
                  {{news.shortDesc}} <a style="color: #0951A4;" [routerLink]="['/newsletter-details', news._id]">Read More.....</a>
                </div>
              </div>
            </ng-container>
          </ng-container>

        </div>
      </div>
    </div>



  </div>
</section>
<!-- India end -->

<!-- start USA -->
<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 ">
  <div class="min-vh-100 mt-150 divmart">
    <div class="d-flex justify-content-center align-items-center banner-image" style="
      background: url('../../../../assets/images/media\ hits/media.png') no-repeat center;
      height: 430px;
      background-size: cover;
    ">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase">NewsLetters</h3>

      </div>
    </div>
    <div class="container pt-4">
      <!-- <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="row mb-100">
          <div class="col-lg-8">
            <div class="mb-30">
              <h1>Best Of The Month</h1>
            </div>
            <div routerLink="/faq-on-gst"
              class="d-flex flex-column justify-content-center align-items-center"
              style="
                background: url('../../../../assets/images/newsletter/img 1.png');
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 17px;
                cursor: pointer;
              "
            >
            <div
            class="row d-flex justify-content-between mt-30 pl-3 pr-3"
            style="min-width: 100%"
          >
            <div
              class="bg-white"
              style="border-radius: 40px; padding: 20px 35px"
            >
              <h4>FAQ ON GST</h4>
            </div>
            <div
              class="bg-transparent text-white d-flex justify-content-center align-items-center"
              style="border-radius: 40px"
            >

            </div>
          </div>
              <div
                class="bg-white text-dark border-0 p-3  text-left"
                style="
                  max-width: 95%;
                  margin-bottom: 30px;
                  margin-top: 315px;
                  border-radius: 17px;
                "
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s, when an unknown printer took a galley of
                type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                <a style="color: #0951A4;">Read More.....</a>

              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex flex-column">
            <div
              class="d-flex flex-column justify-content-center align-items-center"
              style="
                background: url(../../../../assets/images/newsletter/img\ 2.png);
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 17px;
              "
            >
              <div
                class="bg-white mt-3"
                style="
                  border-radius: 17px;
                  max-width: 18rem;
                  margin: 2rem 1rem 0 1rem;
                  padding: 10px 60px;
                "
              >
                <h6 class="text-center" style="font-weight: 600">
                  NCLT AND NCLAT
                </h6>
              </div>
              <div
                class="bg-white text-dark border-0 p-3"
                style="
                  border-radius: 17px;
                  font-size: 15px;
                  max-width: 18rem;
                  margin: 1rem;
                "
              >
                Overview of Goods and Services Tax (GST) Q 1. What is Goods and
                Service Tax (GST)? Ans. It is a destination based tax on
                consumption of goods and services. It is proposed to be levied at
                all stages right from manufacture <a style="color: #0951A4;">Read More.....</a>
              </div>
            </div>
            <div
              class="d-flex flex-column justify-content-center align-items-center"
              style="
                background: url(../../../../assets/images/newsletter/img\ 3.png);
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 17px;
                margin-top: 2.2rem;
              "
            >
              <div
                class="bg-transparent border-white border"
                style="
                  border-radius: 17px;
                  max-width: 18rem;
                  margin: 13rem 2rem 0 2rem;
                  padding: 10px 30px;
                "
              >
                <h6 class="text-center text-white" style="font-weight: 600">
                  NCLT AND NCLAT
                </h6>
              </div>
              <div
                class="bg-white mt-3"
                style="
                  border-radius: 17px;
                  max-width: 18rem;
                  margin: 1rem;
                  padding: 10px 60px;
                "
              >
                <h6 class="text-center" style="font-weight: 600">
                  View All <i class="far fa-arrow-right"></i>
                </h6>
              </div>

            </div>
          </div>
        </div>
      </div> -->

      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="col-lg-12 mb-100">
          <div class="mb-30">
            <h3>All Time News Letters</h3>
          </div>
          <ng-container *ngIf="newsletter">
            <ng-container *ngFor="let news of newsletter" >
              <div
                class="d-flex flex-column justify-content-center align-items-center shadow mb-30"
                style="
                  background: url({{news.imgFile.secure_url}});
                  background-repeat: no-repeat;
                  background-size: cover;
                  border-radius: 17px;
                "
              >
                <div
                  class="row d-flex justify-content-between  pl-3 pr-3"
                  style="min-width: 100%"
                >
                  <div
                    class="bg-white mt-30"
                    style="border-radius: 40px; padding: 20px 35px"
                  >
                    <h4>{{news.title}}</h4>
                  </div>
                  <span
                      class="bg-transparent border text-white d-flex justify-content-center align-items-center mt-30"
                      style="border-radius: 40px; width:12rem"
                    >
                    {{news.createdAt | date: 'yyyy/MM/dd'}}
                    </span>
                </div>
                <div
                  class="bg-white text-dark border-0 p-3 text-left"
                  style="
                    max-width: 100%;
                    border-radius: 0 0 17px 17px;
                    margin-top: 24rem;
                    font-size: 21px;
                    font-weight: 600;
                  "
                >
                  {{news.shortDesc}} <a style="color: #0951A4;" [routerLink]="['/newsletter-details', news._id]">Read More.....</a>
                </div>
              </div>
            </ng-container>
          </ng-container>

        </div>
      </div>
    </div>



  </div>
</section>
<!-- USA end -->
