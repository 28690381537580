detailed newsletters:
<!-- DUBAI -->
<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 ">
  <div class="min-vh-100 mt-150">
    <div class="d-flex justify-content-center align-items-center banner-image" style="
        background: url('../../../../assets/images/media\ hits/media.png') no-repeat center;
        height: 430px;
        background-size: cover;
      ">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase">NewsLetters Details</h3>

        </div>
      </div>
      <ng-container *ngIf="news">
        <div class="container-fluid pt-4">
          <div class="row">
            <!-- Main Content Column -->
            <div class="col-lg-9 col-md-9 col-sm-12">
              <div style="border-radius: 17px; padding-left: 0; padding-right: 0;">
                <img class="img-fluid" [src]="news.imgFile.secure_url" alt="img" />
                <br>
                <small style="color: #21ac51;">
                  <a>{{ news.createdAt | date: 'yyyy/MM/dd' }} <i class="fas fa-comments-alt"></i></a>
                </small>

                <h4 class="pt-2" style="color: #21ac51">{{ news.title }}</h4>
                <p class="text-dark">{{ news.description }}</p>

                <div *ngFor="let subtitle of subarray">
                  <div class="mt-3">
                    <span
                      class="text-capitalize"
                      style="font-size: 1.5rem !important; font-weight: 700 !important; color: #21ac51;"
                    >{{ subtitle.title }}</span>
                  </div>
                  <p class="text-dark servicep mb-2 mt-2" style="white-space: pre-line;">
                    {{ subtitle.description }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Related Posts Column -->
            <div class="col-lg-3 col-md-3 col-sm-12" [routerLink]="['/newsletter-details', news._id]">
              <div class="related-post" style="max-height: 78vh;">
                <h5 class="pb-2" style="color: #21ac51;">RELATED POST</h5>
                <div style="max-height: calc(78vh - 50px); overflow-y: auto;">
                  <ng-container *ngFor="let related of relatedNewsList">
                    <div class="d-flex flex-row pb-2" style="gap: 0.5rem">
                      <div style="width: 80px; height: 80px;">
                        <img class="img-fluid" [src]="related.imgFile.secure_url" alt="image" style="width: 100%; height: 100%; object-fit: cover;" />
                      </div>
                      <div class="">
                        <small style="color: #21ac51;">
                          <a>{{ related.createdAt | date }} <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i></a>
                        </small>
                        <p style="line-height: normal">{{ related.title }}</p>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>


          </div>
        </div>
      </ng-container>

  </div>
</section>

  <!-- USA -->
<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 ">
  <div class="min-vh-100 mt-150">
    <div class="d-flex justify-content-center align-items-center banner-image" style="
        background: url('../../../../assets/images/media\ hits/media.png') no-repeat center;
        height: 430px;
        background-size: cover;
      ">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase">NewsLetters Details</h3>

        </div>
      </div>
      <ng-container *ngIf="news">
        <div class="container-fluid pt-4">
          <div class="row">
            <!-- Main Content Column -->
            <div class="col-lg-9 col-md-9 col-sm-12">
              <div style="border-radius: 17px; padding-left: 0; padding-right: 0;">
                <img class="img-fluid" [src]="news.imgFile.secure_url" alt="img" />
                <br>
                <small style="color: #0951A4;">
                  <a>{{ news.createdAt | date: 'yyyy/MM/dd' }} <i class="fas fa-comments-alt"></i></a>
                </small>

                <h4 class="pt-2" style="color: #0951A4">{{ news.title }}</h4>
                <p class="text-dark">{{ news.description }}</p>

                <div *ngFor="let subtitle of subarray">
                  <div class="mt-3">
                    <span
                      class="text-capitalize"
                      style="font-size: 1.5rem !important; font-weight: 700 !important; color: #0951A4;"
                    >{{ subtitle.title }}</span>
                  </div>
                  <p class="text-dark servicep mb-2 mt-2" style="white-space: pre-line;">
                    {{ subtitle.description }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Related Posts Column -->
            <div class="col-lg-3 col-md-3 col-sm-12" >
              <div class="related-post" style="max-height: 78vh;">
                <h5 class="pb-2">RELATED POST</h5>
                <div style="max-height: calc(78vh - 50px); overflow-y: auto;">
                  <ng-container *ngFor="let related of relatedNewsList">
                    <div class="d-flex flex-row pb-2" style="gap: 0.5rem" [routerLink]="['/newsletter-details', news._id]">
                      <div style="width: 80px; height: 80px;">
                        <img class="img-fluid" [src]="related.imgFile.secure_url" alt="image" style="width: 100%; height: 100%; object-fit: cover;" />
                      </div>
                      <div class="">
                        <small style="color: #0951A4;">
                          <a>{{ related.createdAt | date }} <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i></a>
                        </small>
                        <p style="line-height: normal">{{ related.title }}</p>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>


          </div>
        </div>
      </ng-container>


  </div>
</section>

<!-- India -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 ">
  <div class="min-vh-100 mt-150">
    <div class="d-flex justify-content-center align-items-center banner-image" style="
        background: url('../../../../assets/images/media\ hits/media.png') no-repeat center;
        height: 430px;
        background-size: cover;
      ">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase">NewsLetters Details</h3>

        </div>
      </div>
      <ng-container *ngIf="news">
        <div class="container-fluid pt-4">
          <div class="row">
            <!-- Main Content Column -->
            <div class="col-lg-9 col-md-9 col-sm-12">
              <div style="border-radius: 17px; padding-left: 0; padding-right: 0;">
                <img class="img-fluid" [src]="news.imgFile.secure_url" alt="img" />
                <br>
                <small style="color: #0951A4;">
                  <a>{{ news.createdAt | date: 'yyyy/MM/dd' }} <i class="fas fa-comments-alt"></i></a>
                </small>

                <h4 class="pt-2" style="color: #0951A4">{{ news.title }}</h4>
                <p class="text-dark">{{ news.description }}</p>

                <div *ngFor="let subtitle of subarray">
                  <div class="mt-3">
                    <span
                      class="text-capitalize"
                      style="font-size: 1.5rem !important; font-weight: 700 !important; color: #0951A4;"
                    >{{ subtitle.title }}</span>
                  </div>
                  <p class="text-dark servicep mb-2 mt-2" style="white-space: pre-line;">
                    {{ subtitle.description }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Related Posts Column -->
            <div class="col-lg-3 col-md-3 col-sm-12" [routerLink]="['/newsletter-details', news._id]">
              <div class="related-post" style="max-height: 78vh;">
                <h5 class="pb-2">RELATED POST</h5>
                <div style="max-height: calc(78vh - 50px); overflow-y: auto;">
                  <ng-container *ngFor="let related of relatedNewsList">
                    <div class="d-flex flex-row pb-2" style="gap: 0.5rem">
                      <div style="width: 80px; height: 80px;">
                        <img class="img-fluid" [src]="related.imgFile.secure_url" alt="image" style="width: 100%; height: 100%; object-fit: cover;" />
                      </div>
                      <div class="">
                        <small style="color: #0951A4;">
                          <a>{{ related.createdAt | date }} <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i></a>
                        </small>
                        <p style="line-height: normal">{{ related.title }}</p>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>


          </div>
        </div>
      </ng-container>

  </div>
</section>