
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area real-estate customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Real Estate Management</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Real Estate Management</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Real Estate Management Module  PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Real Estate Solution</span>
                    <h2 class="title pb-15">Real Estate Management</h2>
                    <p>Real Estate Management Module integrates intuitive management with complete accounting functions, making it a comprehensive solution for managing all types of real estate. It gives you greater insight into your real estate portfolio
                        and hence better control over your real estate investments. Managing your accounting is also a lot easier as our Real estate software is completely integrated with your back office.</p>
                    <p>Real Estate Management module is custom made for the regional market with multi-currency, post dated check and billing receivables module and many other regional features. It is especially suitable for local and regional property managers,
                        principal investors, third party managers, property rental agencies, owners with commercial and residential property, and other real estate organizations.</p>
                    <p>Our aim is to adapt to your business requirements and become a one-time investment for all your present and future business needs. No longer do you need to invest in multiple software and additional manpower. No longer do you need
                        to worry about multiple data entry and errors in reporting. Our Real Estate Accounting and Management System will provide you with the most accurate accounting and management information to maximize the performance of your organization</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Real Estate Management Module  PART END ======-->

<!--====== Key Features  PART START ======-->
<div class="about-faq-area about-faq-area-custom horizon-hrms dec-spacing bg_cover gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Highlights</span>
                    <h2 class="title">Key Features</h2>
                </div>
            </div>
            <div class="faq-accordion-3">
                <div class="accordion" id="accordionExample">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Property Info
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>All the properties for lease can be defined featuring its facilities with various grouping available. Option to define various property statuses based on the availability. Powerful and multilevel feature wise search
                                        option available. Manage upcoming vacant units and its availability details</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Lease Management
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Complete lease management for new tenancy agreement and renewal of the same with option to upload related documents and print the prepared agreement. Provisions to have partial payments, cancelling the existing lease
                                        etc. are available. Provision for rental increments, property wise and unit wise is available. Lease renewal letters can be generated from the system.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Tenant Info
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Handles tenant details and its related documents. Also option to keep track of the rent received and outstanding statements of each tenant.</p>
                                </div>
                            </div>
                        </div>

                        <!-- card -->
                    </div>

                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSev">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSev" aria-expanded="false" aria-controls="collapseSev">
                                    <i class="fal fa-magic"></i>Alerts and expiry
                                </a>
                            </div>
                            <div id="collapseSev" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Provision to track the expiry of agreement and exhausting of cheque leaves in prior dates and alerts for the same. Alerts for cheque bounces and delayed payments. Provision to have alert for expiry of tenant’s documents.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEight">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    <i class="fal fa-magic"></i>Financial Analysis
                                </a>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Credibility is built upon accurate financial information. Which includes accounting, banking, cash transactions, advance payments, material and maintenance costing, stock analysis etc.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingNine">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    <i class="fal fa-magic"></i>Payment Tracking
                                </a>
                            </div>
                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Traces facility payments and manages maturity and payment dates, amounts, currency, fees deducted and accounts credited.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->

                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== Key Features  PART END ======-->

<!--====== Benefits at a glance  PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                    <p class="add-arrow"> Ready-to-use and the user-friendly interface let you easily and quickly organize your listings, clients, business contacts.</p>
                    <p class="add-arrow"> Maintain a central database for the access of data from every office and by every assigned employee.</p>
                    <p class="add-arrow"> Helps transform strategies, operational processes and business functions in order to retain customers and increase customer loyalty and profitability.</p>
                    <p class="add-arrow"> Auto-generated reports for lease and occupied units, reports for rent analysis, rent variance and rent fluctuation and many more</p>
                    <p class="add-arrow"> Integrative, User friendly and secured, Simple management of entire rental/lease</p>
                    <p class="add-arrow"> Analyze of Information, statistics, ability to manage all Property activities</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Benefits at a glance  PART END ======-->
