<!-- Dubai started -->

<!-- dubai end -->

<!-- india started -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
    <div class="col p-0 m-0">
      <div class="d-flex justify-content-center align-items-center"
        style="background: url('../../../../assets/images/service/StartupBanner.png')
            no-repeat center;background-size: cover;height: 430px;">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <h3 class="text-white text-uppercase">
              Startup Solution
            </h3>
            <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
          </div>
      </div>
      <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
        <div class="about-faq-area-custom horizon-hrms bg_cover">
          
            
              <div >
                <div >
                  <div class="row">
                  <div class="col-md-4">
                    <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                   <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                     <div class="card-header" id="headingSev" >
                       <a class="collapsed"  data-toggle="collapse" data-target="#collapseSevv"
                       aria-expanded="true" aria-controls="collapseSevv" >
                        <span (click)="onAccounting()"> Accounting & Transaction Processing </span>
                       </a>
                     </div>
                     <div id="collapseSevv" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample1">
                       <div class="card-body bg-white">
                         <div class="list-group">
                           <ul class="text-dark serviceul">
                           
                             <li  routerLink="/accounts-payable">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Accounts Payable
                             </li>
                             <li routerLink="/accounts-receivable">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Accounts Receivable
                             </li>
                             <li routerLink="/fixed-assets-accounting">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Fixed Assets Accounting
                             </li>
                             <li routerLink="/financial-reporting">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Financial Reporting
                             </li>
                             <li routerLink="/budgeting-and-variance-analysis">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Budgeting & Variance Analysis
                             </li>
                             <li routerLink="/reconciliation-services">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Reconciliation Services
                             </li>
                             <li routerLink="/consolidation-reporting-gaap-conversion">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Consolidation Reporting & GAAP Conversion
                             </li>
                            
                           </ul>
                         </div>
                       </div>
                     </div>
                   </div>
                   <!-- card -->
                   <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                     <div class="card-header" id="headingEight">
                       <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                         aria-expanded="false" aria-controls="collapseEight">
                         <span (click)="onTax()">Tax & Regulatory Compliances</span>
                       </a>
                     </div>
                     <div id="collapseEight" class="collapse " aria-labelledby="headingEight" data-parent="#accordionExample1">
                       <div class="card-body bg-white">
                         <div>
                           <ul class="text-dark serviceul">
                             <li routerLink="/income-tax">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Income Tax
                             </li>
                             <li  routerLink="/goods-and-service-tax">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Goods & Service Tax
                             </li>
                             <li routerLink="/corporate-secretarial-compliance">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Secretarial Compliance
                             </li>
                             <li  routerLink="/fema-advisory">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              FEMA Compliance
                             </li>
                           </ul>
                         </div>
                       </div>
                     </div>
                   </div>
  
                   <!-- card -->
                   <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                     <div class="card-header" id="headingNine">
                       <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                         aria-expanded="false" aria-controls="collapseNine">
                         <span (click)="onPayroll()">Payroll Advisory</span>
                       </a>
                     </div>
                     <div id="collapseNine" class="collapse " aria-labelledby="headingNine" data-parent="#accordionExample1">
                       <div class="card-body bg-white">
                         <div>
                           <ul class="text-dark pt-4 serviceul">
                             <li routerLink="/payroll-processing">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Payroll - Compensation & Benefit Management
                             </li>
                           </ul>
                         </div>
                       </div>
                     </div>
                   </div>
                   <!-- card -->
  
                   <!-- card -->
                   <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                     <div class="card-header" id="headingTen">
                       <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                         aria-expanded="false" aria-controls="collapseTen">
                         <span (click)="onConsulting()">Consulting & Advisory</span>
                       </a>
                     </div>
                     <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                       <div class="card-body bg-white">
                         <div>
                           <ul class="text-dark pt-4 serviceul">
                             <li routerLink="/business-setup">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Setting up an entity in the India
                             </li>
                             <li routerLink="/global-corporate-structuring">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Global Corporate Structuring
                             </li>
                             <li routerLink="/business-plan-preparation">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Business Plan Preparation
                             </li>
                             <li routerLink="/business-valuation-service">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Business Valuation
                             </li>
                             <li routerLink="/mergers-and-acquisitions">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Mergers and Acquisitions
                             </li>
                           </ul>
                         </div>
                       </div>
                     </div>
                   </div>
                   <!-- card -->
                   <!-- card -->
                   <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                     <div class="card-header" id="headingElevenStartup">
                       <a  href="#startup-solution-services" data-toggle="collapse" data-target="#collapseElevenStartup"
                         aria-expanded="false" aria-controls="collapseElevenStartup">
                         <span routerLink="/startup-solution-services">Startup Solution</span>
                       </a>
                     </div>
                     <div id="collapseElevenStartup" class="collapse show" aria-labelledby="headingElevenStartup" data-parent="#accordionExample1">
                       <div class="card-body bg-white">
                         <div>
                           <ul class="text-dark pt-4 serviceul">
                             <li routerLink="/startup-solution">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Startup Solution
                             </li>
                             <li routerLink="/fund-raising">
                               <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Fund Raise
                             </li>
                           </ul>
                         </div>
                       </div>
                     </div>
                   </div>
                   <!-- card -->
                    </div>
                    </div>
                 </div>
                 <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3>Startup Solution</h3>
                    <!--<p class="mb-15 mt-20 text-dark servicep">
                      Establish Your Business Presence with Expert Entity Setup Services by BFAG
                    </p>-->
                    <p class="mb-15 mt-20 text-dark servicep">
                        At BFAG, we believe in empowering start-ups in India by providing comprehensive finance, compliance accounting, fundraising, and business plan preparation solutions. We understand the challenges faced by budding entrepreneurs and strive to be their trusted partner on their journey to success.
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                    Innovation and Creativity
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We embrace the spirit of innovation and encourage our clients to think creatively. </p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                            <div class="card-header" id="headingTwelve">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                    Simplifying Finance and Compliance
                                                </a>
                                            </div>
                                            <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We recognize that finance and compliance can be daunting for start-ups. Our philosophy revolves around simplifying these complex processes. </p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                            <div class="card-header" id="headingThirteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                    Tailored Solutions
                                                </a>
                                            </div>
                                            <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We understand that every start-up is unique, with its own set of challenges and goals. Our philosophy revolves around providing tailored solutions that address the specific needs of each client.</p>
                                                </div>
                                            </div>
                                        </div>
          
                                        <!-- card -->
                                    </div>
          
                                    <div class="col-lg-6">
          
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                            <div class="card-header" id="headingSixteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                    Holistic Approach
                                                </a>
                                            </div>
                                            <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We believe in taking a holistic approach to support start-ups. Our services extend beyond traditional accounting and compliance functions. We offer comprehensive business planning solutions, helping entrepreneurs craft robust strategies for growth and expansion. </p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".7s">
                                          <div class="card-header" id="headingSeventeen">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                                Long-Term Partnership
                                              </a>
                                          </div>
                                          <div id="collapseSeventeen" class="collapse " aria-labelledby="headingSeventeen" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p>Our philosophy centers around building long-term partnerships with our clients. We strive to be more than just a service provider; we aim to be a trusted advisor and mentor to start-ups. </p>
                                              </div>
                                          </div>
                                      </div>
                                    <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".8s">
                                            <div class="card-header" id="headingEightteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEightteen" aria-expanded="false" aria-controls="collapseEightteen">
                                                    Ethical and Transparent Practices   
                                                </a>
                                            </div>
                                            <div id="collapseEightteen" class="collapse " aria-labelledby="headingEightteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Integrity is at the core of our philosophy. We adhere to the highest ethical standards and maintain transparency in all our interactions.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                    In Short our philosophy revolves around empowering start-ups in India by providing comprehensive finance, compliance accounting, fundraising, and business plan preparation solutions. We strive to simplify complex processes, offer tailored and holistic approaches, build long-term partnerships, and maintain ethical practices. At BFAG, we are dedicated to supporting start-ups on their path to success and contributing to the growth of the Indian start-up ecosystem.
                </p>
                </div>
                </div>
                <!-- faq accordion -->
              </div>
            </div>
            <!-- row -->
          
        </div>
       
      </div>
  
    </div>
  </section>
  <!-- India Ends -->
  