<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/Tax-and-compliance-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Tax & Regulatory Compliances
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4" >
                  <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev" >
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                      aria-expanded="true" aria-controls="collapseSevv" >
                       <span (click)="onAccounting()"> Accounting & Transaction Processing</span>
                      </a>
                    </div>
                    <div id="collapseSevv" class="collapse " aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div class="list-group">
                          <ul class="text-dark serviceul">

                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Receivable
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Fixed Assets Accounting
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Budgeting & Variance Analysis
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Consolidation Reporting & GAAP Conversion
                            </li>
                            <li routerLink="/small-business-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Small Business Accounting
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a  href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse show" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li  routerLink="/individual-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Individual Tax Return
                            </li>
                            <li class="shli" routerLink="/corporate-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Tax Return
                            </li>
                            <li routerLink="/sales-and-use-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Sales & Use Tax
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span (click)="onPayroll()">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Payroll Processing
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span (click)="onConsulting()">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Setting up an entity in the US
                            </li>
                            <li routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Global Corporate Structuring
                            </li>
                            <li routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Plan Preparation
                            </li>
                            <li routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#business-valuation-service"> Business Valuation</a>
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                    </div>
                  </div>
                  </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3 id="corporate-tax-return">Corporate Tax Return</h3>
                    <!--<p class="mb-15 mt-20 text-dark servicep">
                      Optimize Your Corporate Tax Returns with Professional Services from BFAG
                    </p>-->
                    <p class="mb-15 mt-20 text-dark servicep">
                      Filing corporate tax returns accurately and in compliance with tax regulations is crucial for businesses of all sizes. However, navigating the complex landscape of corporate taxes, maximizing deductions, and ensuring compliance can be overwhelming for business owners. At BFAG we offer specialized Corporate Tax Return Services designed to help businesses optimize their tax returns while ensuring accuracy and adherence to tax laws. Our expert team can assist you in preparing and filing your corporate tax returns efficiently and effectively.
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                  Expert Corporate Tax Preparation
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Our team of experienced professionals specializes in corporate tax preparation. We possess comprehensive knowledge of tax laws and regulations, staying up-to-date with the latest changes to ensure accurate and compliant tax return preparation for your business. With our expertise, you can have confidence that your corporate tax return will be prepared with precision and attention to detail.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                            <div class="card-header" id="headingTwelve">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                  Maximizing Deductions and Credits
                                                </a>
                                            </div>
                                            <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Identifying and maximizing deductions and credits is essential for minimizing your business's tax liability and optimizing your tax benefits. Our team conducts a thorough review of your financial records and identifies potential deductions and credits that apply to your business. We work diligently to ensure that you take advantage of every available opportunity to reduce your tax burden and maximize your tax savings.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingThirteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                  Strategic Tax Planning
                                                </a>
                                            </div>
                                            <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Beyond tax return preparation, our Corporate Tax Return Services include strategic tax planning. We work closely with you to understand your business's unique circumstances and goals, developing a customized tax strategy to optimize your tax position. By implementing proactive tax planning strategies, we help you make informed decisions throughout the year that can significantly impact your tax liability.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- card -->
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingFourteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                  Compliance with Tax Laws and Regulations
                                                </a>
                                            </div>
                                            <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Staying compliant with tax laws and regulations is critical to avoid penalties and legal issues. Our team is well-versed in corporate tax regulations, ensuring that your tax returns adhere to all necessary requirements. We assist you in navigating complex tax codes, keeping your business in good standing with tax authorities.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                            <div class="card-header" id="headingFifteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                  Timely and Accurate Filing
                                                </a>
                                            </div>
                                            <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Meeting tax filing deadlines is essential to avoid penalties and interest charges. With our Corporate Tax Return Services, you can rely on our efficient processes and advanced tax software to ensure timely and accurate filing of your corporate tax returns. We take care of all the necessary paperwork and ensure that your tax returns are submitted on time, giving you peace of mind.</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                  Preparing and filing corporate tax returns can be a complex and time-consuming task. With BFAG's Corporate Tax Return Services, you can entrust the responsibility to our expert team. We provide accurate and compliant tax return preparation, maximize deductions and credits, offer strategic tax planning, and ensure timely filing. Contact us today.
                </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>
