<!-- dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      BUSINESS SET UP & CORPORATE STRUCTURING
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> BUSINESS SET UP &
        CORPORATE STRUCTURING</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/business-setup.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 9rem"
        />
        <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        The most important thing before starting any business is deciding on the
        form of legal entity which is best suited taking into consideration
        ownership protection, formation time, funding, tax treatment, promoters
        liability, purpose.
      </p>
      <div>
        <h5 class="mb-20" style="color: green;">
          Globally, BFAG assists in incorporating the following types of legal
          entity:
        </h5>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Limited Liability Partnership (LLP)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            One Person Company (OPC)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Private Limited Company (Pvt Ltd)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Public Limited Company
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Section 25 Company
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Company
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Limited Partnership
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Companies limited by Guarantee
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Business Corporation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Limited Liability Company (LLC)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            S Corporation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            C Corporation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Branches
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Representative Offices
          </li>
        </ul>
        <p
          class="text-dark mb-30"
          style="font-size: 20px; line-height: normal; font-weight: 500"
        >
          Till date BFAG has incorporated entities in India, Singapore, USA,
          Australia, Malaysia, Canada, UAE, Mauritius for its global clientele.
        </p>
        <p
          class="text-dark mb-30"
          style="font-size: 20px; line-height: normal; font-weight: 500"
        >
          Choosing the right type of entity is a matter of professional
          expertise and experience and as such best left in the hands of
          experts. Each of the legal structure has its own advantages &
          disadvantages as well as certain attributes.
        </p>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h2
        class="text-center"
        style="border-bottom: 3px solid #21ac51; display: inline-block"
      >
        CORPORATE STRUCTURING
      </h2>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <p
            class="text-dark mb-30"
            style="font-size: 20px; line-height: normal; font-weight: 500"
          >
            Having a customer base spread across United States, United Kingdom,
            Australia, Singapore, Malaysia, Middle East, India and each customer
            having subsidiaries across various geographies has helped us a lot
            in achieving considerable expertise in advising and implementing
            complex cross-border corporate structuring projects.
          </p>
          <p
            class="text-dark mb-30"
            style="font-size: 20px; line-height: normal; font-weight: 500"
          >
            We advise on appropriate form of entity to conduct business in order
            to maximize value creation while minimizing the risks. We have
            advised and implemented corporate structuring projects for clients
            ranging from start-ups to multi nationals to Investors with due
            regard to business goals, tax goals, investment criteria and
            associated risks.
          </p>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/corporate_structuring.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h2
        class="text-center"
        style="border-bottom: 3px solid #21ac51; display: inline-block"
      >
        OUR EXPERTISE IN THIS AREA INCLUDES:
      </h2>
    </div>
    <div class="row">
      <div class="col-lg-6 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Cost, Tax and Transaction driven structuring
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Contribution by Shareholders
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Distribution to Shareholders
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Directors / Officers liability
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Corporate Governance
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Share Holder Agreements
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Structuring to achieve maximum management and operational efficiency
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- dubai end -->

<!-- india started -->
<div  *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      BUSINESS SET UP & CORPORATE STRUCTURING
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> BUSINESS SET UP &
        CORPORATE STRUCTURING</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/business-setup.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 9rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        The most important thing before starting any business is deciding on the
        form of legal entity which is best suited taking into consideration
        ownership protection, formation time, funding, tax treatment, promoters
        liability, purpose.
      </p>
      <div>
        <h5 class="mb-20" style="color: #0951A4;">
          Globally, BFAG assists in incorporating the following types of legal
          entity:
        </h5>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Limited Liability Partnership (LLP)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            One Person Company (OPC)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Private Limited Company (Pvt Ltd)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Public Limited Company
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Section 25 Company
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Company
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Limited Partnership
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Companies limited by Guarantee
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Business Corporation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Limited Liability Company (LLC)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            S Corporation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            C Corporation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Branches
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Representative Offices
          </li>
        </ul>
        <p
          class="text-dark mb-30"
          style="font-size: 20px; line-height: normal; font-weight: 500"
        >
          Till date BFAG has incorporated entities in India, Singapore, USA,
          Australia, Malaysia, Canada, UAE, Mauritius for its global clientele.
        </p>
        <p
          class="text-dark mb-30"
          style="font-size: 20px; line-height: normal; font-weight: 500"
        >
          Choosing the right type of entity is a matter of professional
          expertise and experience and as such best left in the hands of
          experts. Each of the legal structure has its own advantages &
          disadvantages as well as certain attributes.
        </p>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h2
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
        CORPORATE STRUCTURING
      </h2>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <p
            class="text-dark mb-30"
            style="font-size: 20px; line-height: normal; font-weight: 500"
          >
            Having a customer base spread across United States, United Kingdom,
            Australia, Singapore, Malaysia, Middle East, India and each customer
            having subsidiaries across various geographies has helped us a lot
            in achieving considerable expertise in advising and implementing
            complex cross-border corporate structuring projects.
          </p>
          <p
            class="text-dark mb-30"
            style="font-size: 20px; line-height: normal; font-weight: 500"
          >
            We advise on appropriate form of entity to conduct business in order
            to maximize value creation while minimizing the risks. We have
            advised and implemented corporate structuring projects for clients
            ranging from start-ups to multi nationals to Investors with due
            regard to business goals, tax goals, investment criteria and
            associated risks.
          </p>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/corporate_structuring.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h2
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
        OUR EXPERTISE IN THIS AREA INCLUDES:
      </h2>
    </div>
    <div class="row">
      <div class="col-lg-6 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Cost, Tax and Transaction driven structuring
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Contribution by Shareholders
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Distribution to Shareholders
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Directors / Officers liability
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Corporate Governance
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Share Holder Agreements
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Structuring to achieve maximum management and operational efficiency
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- india end -->

<!-- india started -->
<div  *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      BUSINESS SET UP & CORPORATE STRUCTURING
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> BUSINESS SET UP &
        CORPORATE STRUCTURING</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/business-setup.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 9rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        The most important thing before starting any business is deciding on the
        form of legal entity which is best suited taking into consideration
        ownership protection, formation time, funding, tax treatment, promoters
        liability, purpose.
      </p>
      <div>
        <h5 class="mb-20" style="color: #0951A4;">
          Globally, BFAG assists in incorporating the following types of legal
          entity:
        </h5>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Limited Liability Partnership (LLP)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            One Person Company (OPC)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Private Limited Company (Pvt Ltd)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Public Limited Company
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Section 25 Company
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Company
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Limited Partnership
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Companies limited by Guarantee
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Business Corporation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Limited Liability Company (LLC)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            S Corporation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            C Corporation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Branches
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Representative Offices
          </li>
        </ul>
        <p
          class="text-dark mb-30"
          style="font-size: 20px; line-height: normal; font-weight: 500"
        >
          Till date BFAG has incorporated entities in India, Singapore, USA,
          Australia, Malaysia, Canada, UAE, Mauritius for its global clientele.
        </p>
        <p
          class="text-dark mb-30"
          style="font-size: 20px; line-height: normal; font-weight: 500"
        >
          Choosing the right type of entity is a matter of professional
          expertise and experience and as such best left in the hands of
          experts. Each of the legal structure has its own advantages &
          disadvantages as well as certain attributes.
        </p>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h2
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
        CORPORATE STRUCTURING
      </h2>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <p
            class="text-dark mb-30"
            style="font-size: 20px; line-height: normal; font-weight: 500"
          >
            Having a customer base spread across United States, United Kingdom,
            Australia, Singapore, Malaysia, Middle East, India and each customer
            having subsidiaries across various geographies has helped us a lot
            in achieving considerable expertise in advising and implementing
            complex cross-border corporate structuring projects.
          </p>
          <p
            class="text-dark mb-30"
            style="font-size: 20px; line-height: normal; font-weight: 500"
          >
            We advise on appropriate form of entity to conduct business in order
            to maximize value creation while minimizing the risks. We have
            advised and implemented corporate structuring projects for clients
            ranging from start-ups to multi nationals to Investors with due
            regard to business goals, tax goals, investment criteria and
            associated risks.
          </p>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/corporate_structuring.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h2
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
        OUR EXPERTISE IN THIS AREA INCLUDES:
      </h2>
    </div>
    <div class="row">
      <div class="col-lg-6 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Cost, Tax and Transaction driven structuring
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Contribution by Shareholders
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Distribution to Shareholders
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Directors / Officers liability
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Corporate Governance
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Share Holder Agreements
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Structuring to achieve maximum management and operational efficiency
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- india end -->
