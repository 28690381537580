<!-- Dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop">
  <div
  class="d-flex justify-content-center align-items-center banner-image"
  style="
    background: url('../../../../assets/images/how we do it/HowWeDoItNew\ .png') no-repeat center;
    background-size: cover;
    height: 430px;
  "
>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h3 class="text-white text-uppercase">How We Do It</h3>
  </div>
</div>
<div style="overflow: hidden; position: relative;">
  <img src="../../../../assets/images/how we do it/home-vector-left.png" alt="shape" style="position: absolute;  overflow: clip;   max-width:21%;"/>
  <img src="../../../../assets/images/how we do it/home-vector-right.png" alt="shape" style="position: absolute; overflow: clip; right: -2%;  max-width:21%;"/>
  <div class="min-vh-100 container d-flex flex-column justify-content-center align-items-center" >

    <!-- <div
      class="row pt-30 justify-content-center align-items-center"
      style="gap: 4rem"
    >
    <div data-wow-duration=".1.5s" data-wow-delay=".3s"
    class="wow fadeInDown card justify-content-center align-items-center shadow border-0"
    style="width: 15rem"
  >
        <div class="icon shadow-sm mt-10">
          <i class="far fa-badge-check fa-2x" style="color: #418052"></i>
        </div>
        <div class="card-body text-center">
          <h4 class="text-uppercase" style="font-weight: 600">Execution Methodology</h4>
          <a
            routerLink="/execution-methodology"
            class="cardBtn text-white mt-40 mb-30"
            >Know More</a
          >
        </div>
      </div>

      <div data-wow-duration=".1.5s" data-wow-delay=".4s"
        class="wow fadeInDown card justify-content-center align-items-center shadow border-0"
        style="width: 15rem"
      >
        <div class="icon shadow-sm mt-10">
          <i class="fas fa-shield-alt fa-2x" style="color: #418052"></i>
        </div>
        <div class="card-body text-center">
          <h4 class="text-uppercase" style="font-weight: 600">Data Security &
              Confidentiality</h4>
          <a
            routerLink="/data-security-and-confidentiality"
            class="cardBtn text-white mt-40 mb-30"
            >Know More</a
          >
        </div>
      </div>

      <div data-wow-duration=".1.5s" data-wow-delay=".5s"
      class="wow fadeInDown card justify-content-center align-items-center shadow border-0"
      style="width: 15rem"
    >
        <div class="icon shadow-sm mt-10">
          <i class="far fa-badge-check fa-2x" style="color: #418052"></i>
        </div>
        <div class="card-body text-center">
          <h4 class="text-uppercase" style="font-weight: 600">Engagement Process</h4>
          <a
          routerLink="/engagement-process"
            class="cardBtn text-white mt-40 mb-30"
            >Know More</a
          >
        </div>
      </div>
    </div> -->

    <div class="min-vh-100 container d-flex flex-column justify-content-center align-items-center">

  <div class="row pt-30 justify-content-center align-items-center" style="gap: 4rem; margin-bottom: 50px;">

    <div data-wow-duration=".1.5s" data-wow-delay=".3s" class="wow fadeInDown col-md-6 col-lg-4 card justify-content-center align-items-center shadow border-0">
      <div class="icon shadow-sm mt-10">
        <i class="far fa-badge-check fa-2x" style="color: #418052"></i>
      </div>
      <div class="card-body text-center">
        <h4 class="text-uppercase" style="font-weight: 600">Execution Methodology</h4>
        <a routerLink="/execution-methodology" class="cardBtn text-white mt-40 mb-30">Know More</a>
      </div>
    </div>

    <div data-wow-duration=".1.5s" data-wow-delay=".4s" class="wow fadeInDown col-md-6 col-lg-4 card justify-content-center align-items-center shadow border-0">
      <div class="icon shadow-sm mt-10">
        <i class="fas fa-shield-alt fa-2x" style="color: #418052"></i>
      </div>
      <div class="card-body text-center">
        <h4 class="text-uppercase" style="font-weight: 600">Data Security & Confidentiality</h4>
        <a routerLink="/data-security-and-confidentiality" class="cardBtn text-white mt-40 mb-30">Know More</a>
      </div>
    </div>

    <div data-wow-duration=".1.5s" data-wow-delay=".5s" class="wow fadeInDown col-md-6 col-lg-4 card justify-content-center align-items-center shadow border-0">
      <div class="icon shadow-sm mt-10">
        <i class="fas fa-user-circle fa-2x" style="color: #418052"></i>

      </div>
      <div class="card-body text-center">
        <h4 class="text-uppercase" style="font-weight: 600">Engagement Process</h4>
        <a routerLink="/engagement-process" class="cardBtn text-white mt-40 mb-30">Know More</a>
      </div>
    </div>

  </div>
</div>

  </div>
</div>


</div>
<!-- dubai end -->

<!-- india started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div
  class="d-flex justify-content-center align-items-center banner-image"
  style="
    background: url('../../../../assets/images/how we do it/HowWeDoItNew\ .png') no-repeat center;
    background-size: cover;
    height: 430px;
  "
>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h3 class="text-white text-uppercase">How We Do It</h3>
  </div>
</div>
<div style="overflow: hidden; position: relative;">
  <img src="../../../../assets/images/how we do it/home-vector-left.png" alt="shape" style="position: absolute;  overflow: clip;   max-width:21%;"/>
  <img src="../../../../assets/images/how we do it/home-vector-right.png" alt="shape" style="position: absolute; overflow: clip; right: -2%;  max-width:21%;"/>
  <div class="min-vh-100 container d-flex flex-column justify-content-center align-items-center" >

    <div
      class="row pt-30 justify-content-center align-items-center"
      style="gap: 4rem; margin-bottom: 50px;"
    >
    <div data-wow-duration=".1.5s" data-wow-delay=".3s"
    class="wow fadeInDown card justify-content-center align-items-center shadow border-0"
    style="width: 15rem"
  >
        <div class="iconIndia shadow-sm mt-10">
          <i class="far fa-badge-check fa-2x" style="color: #072F5F"></i>
        </div>
        <div class="card-body text-center">
          <h4 class="text-uppercase" style="font-weight: 600">Execution Methodology</h4>
          <a
            routerLink="/execution-methodology"
            class="cardBtnIndia text-white mt-40 mb-30"
            >Know More</a
          >
        </div>
      </div>

      <div data-wow-duration=".1.5s" data-wow-delay=".4s"
      class="wow fadeInDown card justify-content-center align-items-center shadow border-0"
      style="width: 15rem"
    >
        <div class="iconIndia shadow-sm mt-10">
          <i class="fas fa-shield-alt fa-2x" style="color: #072F5F"></i>
        </div>
        <div class="card-body text-center">
          <h4 class="text-uppercase" style="font-weight: 600">Data Security &
              Confidentiality</h4>
          <a
            routerLink="/data-security-and-confidentiality"
            class="cardBtnIndia text-white mt-40 mb-30"
            >Know More</a
          >
        </div>
      </div>

      <div data-wow-duration=".1.5s" data-wow-delay=".5s"
      class="wow fadeInDown card justify-content-center align-items-center shadow border-0 "
      style="width: 15rem"
    >
        <div class="iconIndia shadow-sm mt-10">
          <i class="fas fa-user-circle fa-2x" style="color: #072F5F"></i>
        </div>
        <div class="card-body text-center">
          <h4 class="text-uppercase" style="font-weight: 600">Engagement Process</h4>
          <a
          routerLink="/engagement-process"
            class="cardBtnIndia text-white mt-40 mb-30"
            >Know More</a
          >
        </div>
      </div>
    </div>
  </div>
</div>


</div>
<!-- india end -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div
  class="d-flex justify-content-center align-items-center banner-image"
  style="
    background: url('../../../../assets/images/how we do it/HowWeDoItNew\ .png') no-repeat center;
    background-size: cover;
    height: 430px;
  "
>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h3 class="text-white text-uppercase">How We Do It</h3>
  </div>
</div>
<div style="overflow: hidden; position: relative;">
  <img src="../../../../assets/images/how we do it/home-vector-left.png" alt="shape" style="position: absolute;  overflow: clip;   max-width:21%;"/>
  <img src="../../../../assets/images/how we do it/home-vector-right.png" alt="shape" style="position: absolute; overflow: clip; right: -2%;  max-width:21%;"/>
  <div class="min-vh-100 container d-flex flex-column justify-content-center align-items-center" >
    <!-- <div class="justify-content-center align-items-center">
      <h3
        class="text-center"
        style="border-bottom: 4px solid #072F5F; display: inline-block"
      >
        HOW WE DO IT
      </h3>
    </div> -->
    <div
      class="row pt-30 justify-content-center align-items-center"
      style="gap: 4rem; margin-bottom: 50px;"
    >
      <div
      data-wow-duration=".1.5s" data-wow-delay=".3s"
      class="wow fadeInDown card justify-content-center align-items-center shadow border-0"
        style="width: 15rem"
      >
        <div class="iconIndia shadow-sm mt-10">
          <i class="far fa-badge-check fa-2x" style="color: #072F5F"></i>
        </div>
        <div class="card-body text-center">
          <h4 class="text-uppercase" style="font-weight: 600">Execution Methodology</h4>
          <a
            routerLink="/execution-methodology"
            class="cardBtnIndia text-white mt-40 mb-30"
            >Know More</a
          >
        </div>
      </div>

      <div
      data-wow-duration=".1.5s" data-wow-delay=".4s"
      class="wow fadeInDown card justify-content-center align-items-center shadow border-0"
        style="width: 15rem"
      >
        <div class="iconIndia shadow-sm mt-10">
          <i class="fas fa-shield-alt fa-2x" style="color: #072F5F"></i>
        </div>
        <div class="card-body text-center">
          <h4 class="text-uppercase" style="font-weight: 600">Data Security &
              Confidentiality</h4>
          <a
            routerLink="/data-security-and-confidentiality"
            class="cardBtnIndia text-white mt-40 mb-30"
            >Know More</a
          >
        </div>
      </div>

      <div
      data-wow-duration=".1.5s" data-wow-delay=".5s"
      class="wow fadeInDown card justify-content-center align-items-center shadow border-0 "
        style="width: 15rem; "
      >
        <div class="iconIndia shadow-sm mt-10">
          <i class="fas fa-user-circle fa-2x" style="color: #072F5F"></i>
        </div>
        <div class="card-body text-center">
          <h4 class="text-uppercase" style="font-weight: 600">Engagement Process</h4>
          <a
          routerLink="/engagement-process"
            class="cardBtnIndia text-white mt-40 mb-30"
            >Know More</a
          >
        </div>
      </div>
    </div>
  </div>
</div>


</div>
<!-- USA end -->
