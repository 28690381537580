<!-- Dubai Starts -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/CPA back-office/CPAsolutions1.png')
        no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Audit Support</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
    </div>
  </div>

  <div class="container">
    <div class="row" style="margin-top: 5%; margin-bottom: 4%">
      <p
        class="text-dark"
        style="margin-bottom: 3%; padding: 0px 15px 0px; text-align: justify"
      >
        We specialize in providing comprehensive back-office support services
        for Certified Public Accountants (CPAs) and accounting firms
        specifically focused on audit engagements. Our aim is to assist you in
        efficiently managing your audit processes, ensuring compliance with
        regulatory requirements, and delivering high-quality audit services to
        your clients.
      </p>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPA2.png"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0" style="padding-left: 4%; padding-right: 4%">
        <div>
          <span class="subSpan" style="color: #418052 !important"
            >Audit Planning and Preparation</span
          >
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Risk assessment and scoping:</b> We assist in identifying and
              assessing audit risks, determining materiality thresholds, and
              developing an audit plan tailored to your client's business.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Audit program development: </b> Our team helps in creating
              detailed audit programs, outlining procedures and testing
              methodologies for each audit area.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Documentation management:</b> We organize and maintain audit
              documentation, including workpapers, supporting schedules, and
              client correspondence.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-7" style="padding-right: 4% !important">
        <div>
          <span class="subSpan" style="color: #418052 !important"
            >Fieldwork Assistance</span
          >
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Testing and sampling: </b> We support the execution of audit
              procedures, including substantive testing, analytical procedures,
              and control testing, ensuring adherence to auditing standards.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Audit evidence analysis: </b> Our team assists in evaluating
              audit findings, identifying potential errors, irregularities, or
              non-compliance with accounting principles and regulations.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Communication with client personnel: </b> We liaise with client
              personnel to request and gather necessary documentation, resolve
              queries, and obtain information relevant to the audit.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPA3.png"
            class="img-fluid"
          />
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPA4.png"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0" style="padding-left: 4%">
        <div>
          <span class="subSpan" style="color: #418052 !important"
            >Financial Reporting and Disclosure</span
          >
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Financial statement preparation: </b> We help prepare and
              review financial statements in accordance with Generally Accepted
              Accounting Principles (GAAP) or International Financial Reporting
              Standards (IFRS).
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Notes to financial statements: </b> Our experts assist in
              drafting comprehensive notes to the financial statements, ensuring
              proper disclosure of significant accounting policies, estimates,
              and other relevant information.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Disclosure checklist review: </b> We review financial
              statements and related disclosures against disclosure checklists
              to ensure completeness and compliance with accounting standards.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-7 pr-0" style="padding-right: 4%">
        <div>
          <span class="subSpan" style="color: #418052 !important"
            >Compliance and Regulatory Support</span
          >
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Audit compliance review: </b> We perform an independent review
              of audit documentation, workpapers, and procedures to ensure
              compliance with auditing standards and regulatory requirements.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Peer review assistance: </b> Our team supports you in preparing
              for external peer reviews, including gathering required
              documentation and addressing review comments.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Regulatory reporting assistance: </b> We assist in preparing
              and filing necessary regulatory reports, such as audited financial
              statements for regulatory bodies or industry-specific
              requirements.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPA1.png"
            class="img-fluid"
          />
        </div>
      </div>
      <p class="text-dark" style="padding: 0px 15px 0px; text-align: justify">
        At BFAG, we understand the importance of providing accurate, thorough,
        and reliable tax return preparation, compilation and review, and audit
        services. Our dedicated back-office support team is committed to
        assisting you in delivering high-quality audits, meeting regulatory
        requirements, exceeding client expectations along with confidential
        services to help streamline your operations and enhance your client
        service delivery. Contact us today to learn more about our CPA Back
        Office Support services and how we can assist you in enhancing the
        efficiency and effectiveness of your tax return preparation, compilation
        and review, and audit engagements.
      </p>
    </div>
  </div>
</div>
<!-- Dubai Ends -->

<!-- India Starts -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/CPA back-office/CPAsolutions1.png')
        no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Audit Support</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
    </div>
  </div>

  <div class="container">
    <div class="row" style="margin-top: 5%; margin-bottom: 4%">
      <p
        class="text-dark"
        style="margin-bottom: 3%; padding: 0px 15px 0px; text-align: justify"
      >
        We specialize in providing comprehensive back-office support services
        for Certified Public Accountants (CPAs) and accounting firms
        specifically focused on audit engagements. Our aim is to assist you in
        efficiently managing your audit processes, ensuring compliance with
        regulatory requirements, and delivering high-quality audit services to
        your clients.
      </p>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPA2.png"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0" style="padding-left: 4%; padding-right: 4%">
        <div>
          <span class="subSpan" style="color: #072f5f !important"
            >Audit Planning and Preparation</span
          >
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Risk assessment and scoping:</b> We assist in identifying and
              assessing audit risks, determining materiality thresholds, and
              developing an audit plan tailored to your client's business.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Audit program development: </b> Our team helps in creating
              detailed audit programs, outlining procedures and testing
              methodologies for each audit area.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Documentation management:</b> We organize and maintain audit
              documentation, including workpapers, supporting schedules, and
              client correspondence.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-7" style="padding-right: 4% !important">
        <div>
          <span class="subSpan" style="color: #072f5f !important"
            >Fieldwork Assistance</span
          >
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Testing and sampling: </b> We support the execution of audit
              procedures, including substantive testing, analytical procedures,
              and control testing, ensuring adherence to auditing standards.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Audit evidence analysis: </b> Our team assists in evaluating
              audit findings, identifying potential errors, irregularities, or
              non-compliance with accounting principles and regulations.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Communication with client personnel: </b> We liaise with client
              personnel to request and gather necessary documentation, resolve
              queries, and obtain information relevant to the audit.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPA3.png"
            class="img-fluid"
          />
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPA4.png"
            class="img-fluid"
          />
        </div>
      </div>
      <div
        class="col-lg-7 pr-0"
        style="padding-left: 4%; padding-right: 4% !important"
      >
        <div>
          <span class="subSpan" style="color: #072f5f !important"
            >Financial Reporting and Disclosure</span
          >
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Financial statement preparation: </b> We help prepare and
              review financial statements in accordance with Generally Accepted
              Accounting Principles (GAAP) or International Financial Reporting
              Standards (IFRS).
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Notes to financial statements: </b> Our experts assist in
              drafting comprehensive notes to the financial statements, ensuring
              proper disclosure of significant accounting policies, estimates,
              and other relevant information.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Disclosure checklist review: </b> We review financial
              statements and related disclosures against disclosure checklists
              to ensure completeness and compliance with accounting standards.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-7 pr-0" style="padding-right: 4%">
        <div>
          <span class="subSpan" style="color: #072f5f !important"
            >Compliance and Regulatory Support</span
          >
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Audit compliance review: </b> We perform an independent review
              of audit documentation, workpapers, and procedures to ensure
              compliance with auditing standards and regulatory requirements.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Peer review assistance: </b> Our team supports you in preparing
              for external peer reviews, including gathering required
              documentation and addressing review comments.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Regulatory reporting assistance: </b> We assist in preparing
              and filing necessary regulatory reports, such as audited financial
              statements for regulatory bodies or industry-specific
              requirements.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPA1.png"
            class="img-fluid"
          />
        </div>
      </div>
      <p class="text-dark" style="padding: 0px 15px 0px; text-align: justify">
        At BFAG, we understand the importance of providing accurate, thorough,
        and reliable tax return preparation, compilation and review, and audit
        services. Our dedicated back-office support team is committed to
        assisting you in delivering high-quality audits, meeting regulatory
        requirements, exceeding client expectations along with confidential
        services to help streamline your operations and enhance your client
        service delivery. Contact us today to learn more about our CPA Back
        Office Support services and how we can assist you in enhancing the
        efficiency and effectiveness of your tax return preparation, compilation
        and review, and audit engagements.
      </p>
    </div>
  </div>
</div>
<!-- India Ends -->

<!-- USA STARTS -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/CPA back-office/CPAsolutions1.png')
        no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Audit Support</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
    </div>
  </div>

  <div class="container">
    <div class="row" style="margin-top: 5%; margin-bottom: 4%">
      <p
        class="text-dark"
        style="margin-bottom: 3%; padding: 0px 15px 0px; text-align: justify"
      >
        We specialize in providing comprehensive back-office support services
        for Certified Public Accountants (CPAs) and accounting firms
        specifically focused on audit engagements. Our aim is to assist you in
        efficiently managing your audit processes, ensuring compliance with
        regulatory requirements, and delivering high-quality audit services to
        your clients.
      </p>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPA2.png"
            class="img-fluid"
          />
        </div>
      </div>
      <div
        class="col-lg-7 pr-0"
        style="padding-left: 4%; padding-right: 4% !important"
      >
        <div>
          <span class="subSpan" style="color: #072f5f !important"
            >Audit Planning and Preparation</span
          >
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Risk assessment and scoping:</b> We assist in identifying and
              assessing audit risks, determining materiality thresholds, and
              developing an audit plan tailored to your client's business.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Audit program development: </b> Our team helps in creating
              detailed audit programs, outlining procedures and testing
              methodologies for each audit area.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Documentation management:</b> We organize and maintain audit
              documentation, including workpapers, supporting schedules, and
              client correspondence.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-7" style="padding-right: 4% !important">
        <div>
          <span class="subSpan" style="color: #072f5f !important"
            >Fieldwork Assistance</span
          >
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Testing and sampling: </b> We support the execution of audit
              procedures, including substantive testing, analytical procedures,
              and control testing, ensuring adherence to auditing standards.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Audit evidence analysis: </b> Our team assists in evaluating
              audit findings, identifying potential errors, irregularities, or
              non-compliance with accounting principles and regulations.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Communication with client personnel: </b> We liaise with client
              personnel to request and gather necessary documentation, resolve
              queries, and obtain information relevant to the audit.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPA3.png"
            class="img-fluid"
          />
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPA4.png"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0" style="padding-left: 4%">
        <div>
          <span class="subSpan" style="color: #072f5f !important"
            >Financial Reporting and Disclosure</span
          >
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Financial statement preparation: </b> We help prepare and
              review financial statements in accordance with Generally Accepted
              Accounting Principles (GAAP) or International Financial Reporting
              Standards (IFRS).
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Notes to financial statements: </b> Our experts assist in
              drafting comprehensive notes to the financial statements, ensuring
              proper disclosure of significant accounting policies, estimates,
              and other relevant information.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Disclosure checklist review: </b> We review financial
              statements and related disclosures against disclosure checklists
              to ensure completeness and compliance with accounting standards.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 4%">
      <div class="col-lg-7 pr-0" style="padding-right: 4%">
        <div>
          <span class="subSpan" style="color: #072f5f !important"
            >Compliance and Regulatory Support</span
          >
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Audit compliance review: </b> We perform an independent review
              of audit documentation, workpapers, and procedures to ensure
              compliance with auditing standards and regulatory requirements.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Peer review assistance: </b> Our team supports you in preparing
              for external peer reviews, including gathering required
              documentation and addressing review comments.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Regulatory reporting assistance: </b> We assist in preparing
              and filing necessary regulatory reports, such as audited financial
              statements for regulatory bodies or industry-specific
              requirements.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/CPA1.png"
            class="img-fluid"
          />
        </div>
      </div>
      <p class="text-dark" style="padding: 0px 15px 0px; text-align: justify">
        At BFAG, we understand the importance of providing accurate, thorough,
        and reliable tax return preparation, compilation and review, and audit
        services. Our dedicated back-office support team is committed to
        assisting you in delivering high-quality audits, meeting regulatory
        requirements, exceeding client expectations along with confidential
        services to help streamline your operations and enhance your client
        service delivery. Contact us today to learn more about our CPA Back
        Office Support services and how we can assist you in enhancing the
        efficiency and effectiveness of your tax return preparation, compilation
        and review, and audit engagements.
      </p>
    </div>
  </div>
</div>
<!-- USA end -->
