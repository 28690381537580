<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop divmart">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/tax-regulatory-complance.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
              Audit & Assurance Support
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">

          <div class="row">
            <div class="col-md-4">
              <div class="faq-accordion-3 pt-0">
              <div class="accordion" id="accordionExample1">
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px; background-color: #4180521A;">
                <div class="card-header" id="headingSev">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                    aria-expanded="true" aria-controls="collapseSevv" style="color: #418052;">
                    <span (click)="onAccounting()">Accounting & Transaction Processing</span>
                  </a>
                </div>
                <div id="collapseSevv" class="collapse " aria-labelledby="headingSev" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark serviceul">
                        <li routerLink="/accounting-services" style="color: black">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Accounts Payable
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingEight">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                    aria-expanded="false" aria-controls="collapseEight" style="color: #418052;">
                    <span (click)="onTax()">Tax & Regulatory Compliances</span>
                  </a>
                </div>
                <div id="collapseEight" class="collapse " aria-labelledby="headingEight" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark serviceul">
                        <li class="shli" routerLink="/coordination-and-assistance-for-vat-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         VAT Advisory
                        </li>
                        <li  routerLink="/coordination-and-assistance-for-tax-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Corporate Tax Advisory
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingNine">
                  <a  href="" data-toggle="collapse" data-target="#collapseNine"
                    aria-expanded="false" aria-controls="collapseNine" style="color: #418052;">
                    <span (click)="onAudit()"> Audit & Assurance Support</span>
                  </a>
                </div>
                <div id="collapseNine" class="collapse show" aria-labelledby="headingNine" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark pt-4 serviceul">
                        <li class="shli" routerLink="/statutory-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Statutory Audit
                        </li>
                        <li routerLink="/internal-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Internal Audit
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->

              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingTen">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                    aria-expanded="false" aria-controls="collapseTen" style="color: #418052;">
                    <span (click)="onConsulting()">Consulting & Advisory</span>
                  </a>
                </div>
                <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark pt-4 serviceul">
                        <li routerLink="/business-setup">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Setting up an entity in Dubai
                        </li>
                        <li routerLink="/business-plan-preparation">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Business Plan Preparation
                        </li>
                        <li routerLink="/business-valuation-service">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Business Valuation
                        </li>
                        <li routerLink="/mergers-and-acquisitions">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Mergers and Acquisitions (M&A)
                        </li>
                        <li routerLink="/strategic-cfo-services">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Strategic CFO
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->
              </div>
              </div>

            </div>
            <div class="col-md-8">
              <div style="margin-top: 1rem">
                <h3 id="statutory-audit" style=" color: #418052 ;">Statutory Audit for Freezones & Mainland</h3>
                <!--<p class="mb-15 mt-20 text-dark servicep">
                  Streamline Your Finances with Expert Accounts Payable Services
                </p>-->
                <p class="mb-15 mt-20 text-dark servicep">
                    BFAG is a leading provider of Statutory Audit services for businesses operating in Freezones and the Mainland. With our deep understanding of local regulations and industry standards, we offer comprehensive and reliable audit solutions to ensure compliance and provide valuable insights into your financial operations. BFAG's Statutory Audit services caters specifically to companies operating in Freezones and the Mainland.
                </p>
                <p class="mb-15 mt-20 text-dark servicep">
                    BFAG specializes in conducting Statutory Audits for businesses operating in Freezones and Mainland. Our experienced auditors possess in-depth knowledge of regulations and auditing standards, enabling us to perform thorough assessments of your financial statements and internal controls.
                </p>
              </div>
              <div class="about-faq-area-custom horizon-hrms gray-bg clearfix" style="background-color: #4180521A;">
                <div class="container">
                    <div class="row" style="display: block;">
                        <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                            <div class="accordion" id="accordionExample2">
                                <div class="col-lg-6">
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s" >
                                        <div class="card-header" id="headingEleven">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven" style=" color: #418052A1;">
                                                Comprehensive Audit Procedures
                                            </a>
                                        </div>
                                        <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p style=" color: black;">We conduct detailed audit procedures to evaluate the accuracy and reliability of your financial statements, ensuring compliance with applicable accounting standards and Freezone regulations.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                        <div class="card-header" id="headingTwelve">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve" style=" color: #418052A1;">
                                                Compliance Verification
                                            </a>
                                        </div>
                                        <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p style="  color: black">Our auditors verify that your business operations and financial statements align with the rules and regulations set forth by the respective authorities.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <!-- card -->
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                        <div class="card-header" id="headingThirteen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen" style=" color: #418052A1;">
                                                Assurance of Financial Statements:
                                            </a>
                                        </div>
                                        <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p style=" color: black">We provide independent assurance on the fairness and reliability of your financial statements, enhancing the confidence of stakeholders, investors, and business partners.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                </div>

                                <div class="col-lg-6">
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                        <div class="card-header" id="headingFourteen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen" style=" color: #418052A1;">
                                                Thorough Financial Examination
                                            </a>
                                        </div>
                                        <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p style="color:black">We perform a comprehensive examination of your financial records, transactions, and internal controls to ensure compliance with the applicable accounting standards and regulations.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                        <div class="card-header" id="headingFifteen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen" style=" color: #418052A1;">
                                                Valuable Insights
                                            </a>
                                        </div>
                                        <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p style=" color:black;">Our audit process goes beyond compliance; we offer valuable insights into your financial operations, identifying areas for improvement, and providing recommendations to enhance efficiency and mitigate risks.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- card -->

                                </div>
                            </div>
                            <!-- faq accordion -->
                        </div>
                    </div>
                    <!-- row -->
                </div>
                <!-- container -->
            </div>
            <p class="mb-15 mt-20 text-dark servicep">
                BFAG's Statutory Audit services for Freezones and Mainland companies are designed to ensure compliance, enhance transparency, and provide valuable insights into your financial operations. Our team of experienced auditors possesses a deep understanding of Freezone and Mainland regulations, enabling us to deliver accurate, reliable, and independent audit services. Trust BFAG to conduct thorough assessments of your financial statements, verify compliance with regulations, and provide valuable recommendations to drive growth and success. Contact us today to benefit from our expert Statutory Audit services tailored specifically for Freezones and the Mainland.
            </p>
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>
