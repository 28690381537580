<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop divmart">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center banner-image bmTop"
      style="background: url('../../../../assets/images/service/Tax-and-compliance-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Tax & Regulatory Compliances
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">

          <div class="row">
            <div class="col-md-4">
              <div class="faq-accordion-3 pt-0">
              <div class="accordion" id="accordionExample1">
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px; background-color: #4180521A;">
                <div class="card-header" id="headingSev">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                    aria-expanded="true" aria-controls="collapseSevv" style="color: #418052;">
                    <span (click)="onAccounting()">Accounting & Transaction Processing</span>
                  </a>
                </div>
                <div id="collapseSevv" class="collapse " aria-labelledby="headingSev" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark serviceul">
                        <li routerLink="/accounting-services" style="color: black">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Accounts Payable
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingEight">
                  <a  href="" data-toggle="collapse" data-target="#collapseEight"
                    aria-expanded="false" aria-controls="collapseEight" style="color: #418052;">
                    <span (click)="onTax()">Tax & Regulatory Compliances</span>
                  </a>
                </div>
                <div id="collapseEight" class="collapse show" aria-labelledby="headingEight" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark serviceul">
                        <li  routerLink="/coordination-and-assistance-for-vat-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         VAT Advisory
                        </li>
                        <li class="shli" routerLink="/coordination-and-assistance-for-tax-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Corporate Tax Advisory
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingNine">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                    aria-expanded="false" aria-controls="collapseNine" style="color: #418052;">
                    <span (click)="onAudit()"> Audit & Assurance Support</span>
                  </a>
                </div>
                <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark pt-4 serviceul">
                        <li  routerLink="/statutory-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Statutory Audit
                        </li>
                        <li routerLink="/internal-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Internal Audit
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->

              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingTen">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                    aria-expanded="false" aria-controls="collapseTen" style="color: #418052;">
                    <span (click)="onConsulting()">Consulting & Advisory</span>
                  </a>
                </div>
                <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark pt-4 serviceul">
                        <li routerLink="/business-setup">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Setting up an entity in Dubai
                        </li>
                        <li routerLink="/business-plan-preparation">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Business Plan Preparation
                        </li>
                        <li routerLink="/business-valuation-service">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Business Valuation
                        </li>
                        <li routerLink="/mergers-and-acquisitions">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Mergers and Acquisitions (M&A)
                        </li>
                        <li routerLink="/strategic-cfo-services">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Strategic CFO
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->
              </div>
              </div>

            </div>
            <div class="col-md-8">
              <div id="coordination-and-assistance-for-tax-audit" style="margin-top: 1rem">
                <h3  style=" color: #418052;">Corporate Tax Advisory</h3>
                <!--<p class="mb-15 mt-20 text-dark servicep">
                  Streamline Your Finances with Expert Accounts Payable Services
                </p>-->
                <p class="mb-15 mt-20 text-dark servicep">
                  Navigating the corporate tax landscape can be complex and challenging, but with our expert guidance, you can ensure your business complies with corporate tax regulations. Our team of professionals is here to assist you with corporate tax assessment, implementation, and preparation services, as well as registration and filing services. Let us help you navigate the intricacies of corporate tax and optimize your tax position.
                </p>
              </div>
              <div class="about-faq-area-custom horizon-hrms gray-bg clearfix" style="background-color: #4180521A;">
                <div class="container">
                    <div class="row" style="display: block;">
                        <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                            <div class="accordion" id="accordionExample2">
                                <div class="col-lg-6">
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                        <div class="card-header" id="headingEleven">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven" style=" color: #418052A1;">
                                              Corporate Tax Assessment Study
                                            </a>
                                        </div>
                                        <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p style=" color: black;">We analyze your business operations and financials to assess the corporate tax implications and identify tax planning opportunities.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                        <div class="card-header" id="headingTwelve">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve" style=" color: #418052A1;">
                                              Corporate Tax Implementation & Preparation Services
                                            </a>
                                        </div>
                                        <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p style=" color: black">Our team assists in implementing effective corporate tax strategies, preparing accurate tax computations, and ensuring timely tax filings.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-lg-6">
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                        <div class="card-header" id="headingThirteen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen" style=" color: #418052A1;">
                                              Corporate Tax Registration & Filing Services:
                                            </a>
                                        </div>
                                        <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p style=" color: black;">We support businesses in the registration process for corporate tax purposes and handle the filing of corporate tax returns to ensure compliance.</p>
                                            </div>
                                        </div>
                                    </div>

                                <!-- card -->

                                </div>
                            </div>
                            <!-- faq accordion -->
                        </div>
                    </div>
                    <!-- row -->
                </div>
                <!-- container -->
            </div>
            <p class="mb-15 mt-20 text-dark servicep">
              BFAG is committed to providing comprehensive Corporate Tax Advisory services to ensure your business meets its corporate tax obligations. Our team of experts will conduct a thorough corporate tax assessment study, enabling us to identify tax planning opportunities and optimize your tax position. With our assistance in implementing effective corporate tax strategies, preparing accurate tax computations, and handling registration and filing services, you can rest assured that your business remains compliant with corporate tax regulations. Trust BFAG to guide you through the complexities of the corporate tax landscape and optimize your tax position for sustainable growth. Contact us today to benefit from our expert Corporate Tax Advisory services.
            </p>
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>
