<!-- dubai started -->
  <div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 container d-flex flex-column justify-content-center align-items-center">
    <div class="justify-content-center mt-150 align-items-center">
      <h2
        class="text-center"
        style="border-bottom: 4px solid #21ac51; display: inline-block"
      >
        TECHNOLOGY
      </h2>
    </div>
    <div
      class="row mb-100 mt-100 justify-content-center align-items-center"
      style="gap: 4rem"
    >
      <div
        class="card justify-content-center align-items-center shadow border-0"
        style="width: 15rem"
      >
        <div class="icon shadow-sm mt-10">
          <i class="far fa-globe fa-2x" style="color: #21ac51"></i>
        </div>
        <div class="card-body text-center">
          <h5 style="font-weight: 600">Compliance iNBOX
          </h5>
          <a
            routerLink="/compliance-inbox"
            routerLinkActive="active"
            class="cardBtn text-white mt-40 mb-30"
            >Know More</a
          >
        </div>
      </div>
  
      <div
        class="card justify-content-center align-items-center shadow border-0"
        style="width: 15rem"
      >
        <div class="icon shadow-sm mt-10">
          <i class="fas fa-search fa-2x" style="color: #21ac51"></i>
        </div>
        <div class="card-body text-center">
          <h5 style="font-weight: 600">Expense iNBOX
          </h5>
          <a
            routerLink="/expense-inbox"
            routerLinkActive="active"
            class="cardBtn text-white mt-40 mb-30"
            >Know More</a
          >
        </div>
      </div>
      <div
        class="card justify-content-center align-items-center shadow border-0"
        style="width: 15rem"
      >
        <div class="icon shadow-sm mt-10">
          <i class="fas fa-search fa-2x" style="color: #21ac51"></i>
        </div>
        <div class="card-body text-center">
          <h5 style="font-weight: 600">Analytics iNBOX
  
          </h5>
          <a
            routerLink="/analytics-inbox"
            routerLinkActive="active"
            class="cardBtn text-white mt-40 mb-30"
            >Know More</a
          >
        </div>
    </div>
  </div>
</div>
<!-- dubai end -->

<!-- India started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 container d-flex flex-column justify-content-center align-items-center">
  <div class="justify-content-center mt-150 align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      TECHNOLOGY
    </h2>
  </div>
  <div
    class="row mb-100 mt-100 justify-content-center align-items-center"
    style="gap: 4rem"
  >
    <div
      class="card justify-content-center align-items-center shadow border-0"
      style="width: 15rem"
    >
      <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
        <i class="far fa-globe fa-2x" style="color: #0951A4"></i>
      </div>
      <div class="card-body text-center">
        <h5 style="font-weight: 600">Compliance iNBOX
        </h5>
        <a
          routerLink="/compliance-inbox"
          routerLinkActive="active"
          class="cardBtn-india text-white mt-40 mb-30"
          >Know More</a
        >
      </div>
    </div>

    <div
      class="card justify-content-center align-items-center shadow border-0"
      style="width: 15rem"
    >
      <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
        <i class="fas fa-search fa-2x" style="color: #0951A4"></i>
      </div>
      <div class="card-body text-center">
        <h5 style="font-weight: 600">Expense iNBOX
        </h5>
        <a
          routerLink="/expense-inbox"
          routerLinkActive="active"
          class="cardBtn-india text-white mt-40 mb-30"
          >Know More</a
        >
      </div>
    </div>
    <div
      class="card justify-content-center align-items-center shadow border-0"
      style="width: 15rem"
    >
      <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
        <i class="fas fa-search fa-2x" style="color: #0951A4"></i>
      </div>
      <div class="card-body text-center">
        <h5 style="font-weight: 600">Analytics iNBOX</h5>
        <a
          routerLink="/analytics-inbox"
          routerLinkActive="active"
          class="cardBtn-india text-white mt-40 mb-30"
          >Know More</a
        >
      </div>
  </div>
</div>
</div>
<!-- India end -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 container d-flex flex-column justify-content-center align-items-center">
  <div class="justify-content-center mt-150 align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      TECHNOLOGY
    </h2>
  </div>
  <div
    class="row mb-100 mt-100 justify-content-center align-items-center"
    style="gap: 4rem"
  >
    <div
      class="card justify-content-center align-items-center shadow border-0"
      style="width: 15rem"
    >
      <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
        <i class="far fa-globe fa-2x" style="color: #0951A4"></i>
      </div>
      <div class="card-body text-center">
        <h5 style="font-weight: 600">Compliance iNBOX
        </h5>
        <a
          routerLink="/compliance-inbox"
          routerLinkActive="active"
          class="cardBtn-india text-white mt-40 mb-30"
          >Know More</a
        >
      </div>
    </div>

    <div
      class="card justify-content-center align-items-center shadow border-0"
      style="width: 15rem"
    >
      <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
        <i class="fas fa-search fa-2x" style="color: #0951A4"></i>
      </div>
      <div class="card-body text-center">
        <h5 style="font-weight: 600">Expense iNBOX
        </h5>
        <a
          routerLink="/expense-inbox"
          routerLinkActive="active"
          class="cardBtn-india text-white mt-40 mb-30"
          >Know More</a
        >
      </div>
    </div>
    <div
      class="card justify-content-center align-items-center shadow border-0"
      style="width: 15rem"
    >
      <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
        <i class="fas fa-search fa-2x" style="color: #0951A4"></i>
      </div>
      <div class="card-body text-center">
        <h5 style="font-weight: 600">Analytics iNBOX</h5>
        <a
          routerLink="/analytics-inbox"
          routerLinkActive="active"
          class="cardBtn-india text-white mt-40 mb-30"
          >Know More</a
        >
      </div>
  </div>
</div>
</div>
<!-- USA end -->