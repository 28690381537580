
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area financial-accounting customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Financial Accounting</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Financial Accounting</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->

<!--====== Financial Accounting Module  PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Financial Accounting Solutions</span>
                    <h2 class="title pb-15">Financial Accounting</h2>
                    <p>Finance Accounting Module of Horizon is the heart of our ERP system. </p>
                    <p>Our module is designed specially to match the UAE / Middle East accounting standards and practices with complete VAT Enabled. This module is simple, flexible, scalable, and excellent in performance. It covers business accounting features
                        such as General Ledger, Accounts Receivable, Accounts Payable, Management Accounting, Budgetary Accounting, etc and offers all essential functionalities as per IFRS Standard. </p>
                    <p>The Module also includes Cost Centers, which is completely flexible in terms of defining branch location, division, etc, and their components. </p>
                    <p>Outstanding of Payables and Receivables with Ageing Analysis of both debtors and creditors with multilevel are some of the sophisticated features of this module.</p>
                    <p>It is extremely flexible and can either be used as an independent accounting module or integrated with other modules for maximum potential. It is completely integrated with other modules such as projects, procurement, inventory, sales,
                        operation, human resources, etc. </p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Financial Accounting Module  END START ======-->

<!--====== Features  PART START ======-->
<div class="about-faq-area about-faq-area-custom horizon-hrms dec-spacing bg_cover gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Highlights</span>
                    <h2 class="title pb-20">Features</h2>
                </div>
            </div>
            <div class="faq-accordion-3 pt-0">
                <div class="accordion" id="accordionExample">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOne">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="" aria-expanded="false" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Multi-level Chart of Accounts
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse hide" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Enable accounting for multiple reporting standards and industries.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwo">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Multi-dimensional Cost Centers
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts payable to vendors.</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>General Ledgers and Sub Ledgers
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts receivable for customers.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSev">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseSev">
                                    <i class="fal fa-magic"></i>Accounts Payable and Receivable
                                </a>
                            </div>
                            <div id="collapseSev" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage fixed assets for multiple reporting standards.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingfour">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapsefour">
                                    <i class="fal fa-magic"></i>User-Defined Vouchers
                                </a>
                            </div>
                            <div id="collapsefour" class="collapse" aria-labelledby="headingfour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts receivable for customers.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingfive">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapsefive">
                                    <i class="fal fa-magic"></i>Debit Notes and Credit Notes
                                </a>
                            </div>
                            <div id="collapsefive" class="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts receivable for customers.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingsix">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapsesix">
                                    <i class="fal fa-magic"></i>Petty Cash Management
                                </a>
                            </div>
                            <div id="collapsesix" class="collapse" aria-labelledby="headingsix" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts receivable for customers.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingEight">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseEight">
                                    <i class="fal fa-magic"></i>Matching and Un-matching Vouchers
                                </a>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts receivable for customers.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingNine">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseNine">
                                    <i class="fal fa-magic"></i>Bulk Payment Vouchers with Matching
                                </a>
                            </div>
                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts receivable for customers.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingTen">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseTen">
                                    <i class="fal fa-magic"></i>Cheque Payments and Bulk Cheque Printing
                                </a>
                            </div>
                            <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Record and manage accounts receivable for customers.</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                    </div>

                    <div class="col-lg-6">

                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEle">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseEle">
                                    <i class="fal fa-magic"></i>Electronic Transfers with Bank Upload / Direct Integration
                                </a>
                            </div>
                            <div id="collapseEle" class="collapse" aria-labelledby="headingEle" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Perform inventory valuation for multiple reporting standards</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingTwe">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseTwe">
                                    <i class="fal fa-magic"></i>Payment Notifications to Suppliers
                                </a>
                            </div>
                            <div id="collapseTwe" class="collapse" aria-labelledby="headingTwe" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Manage high-volume accounts receivable and accounts payable</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingThi">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseThi">
                                    <i class="fal fa-magic"></i>Bank Reconciliation
                                </a>
                            </div>
                            <div id="collapseThi" class="collapse" aria-labelledby="headingThi" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Manage revenues and costs for internal and external reporting.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingfou">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapsefou">
                                    <i class="fal fa-magic"></i>Cheque Management (CDC and PDC)
                                </a>
                            </div>
                            <div id="collapsefou" class="collapse" aria-labelledby="headingfou" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Manage revenues and costs for internal and external reporting.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingfif">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapsefif">
                                    <i class="fal fa-magic"></i>Cash flow analysis
                                </a>
                            </div>
                            <div id="collapsefif" class="collapse" aria-labelledby="headingfif" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Manage revenues and costs for internal and external reporting.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingsixt">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapsesixt">
                                    <i class="fal fa-magic"></i>Soft and Hard Month and Year Closing
                                </a>
                            </div>
                            <div id="collapsesixt" class="collapse" aria-labelledby="headingsixt" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Manage revenues and costs for internal and external reporting.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingsevent">
                                <a class="remove-icon collapsed change-line-height" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapsesevent">
                                    <i class="fal fa-magic"></i>Powerful MIS, Analytical and Business Intelligence Reporting
                                </a>
                            </div>
                            <div id="collapsesevent" class="collapse" aria-labelledby="headingsevent" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Manage revenues and costs for internal and external reporting.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingeighteen">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseeighteen" aria-expanded="false" aria-controls="collapseeighteen">
                                    <i class="fal fa-magic"></i>Integrated Accounting
                                </a>
                            </div>
                            <div id="collapseeighteen" class="collapse" aria-labelledby="headingeighteen" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Asset accounting </p>
                                    <p>Inventory accounting </p>
                                    <p>Sub Contract accounting</p>
                                    <p>Revenue and cost accounting </p>
                                    <p>Payroll Accounting </p>

                                    <!-- <p>Manage revenues and costs for internal and external reporting.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingnineteen">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapsenineteen" aria-expanded="false" aria-controls="collapsenineteen">
                                    <i class="fal fa-magic"></i>VAT Accounting
                                </a>
                            </div>
                            <div id="collapsenineteen" class="collapse" aria-labelledby="headingnineteen" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Input and Output VAT </p>
                                    <p>Recoverable and Non-recoverable VAT </p>
                                    <p>VAT on Advance Payment </p>
                                    <p>Reverse Charge Mechanism </p>
                                    <p>Manual VAT adjustment for complicated cases</p>
                                    <p>Sophisticated VAT Closing – Monthly or Quarterly as applicable </p>
                                    <p>VAT Return - Automatic generation of FTA audit file </p>
                                    <p>VAT Register Report </p>

                                    <!-- <p>Manage revenues and costs for internal and external reporting.</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== Features  PART END ======-->

<!--====== Benefits at a glance  PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                </div>
                <p class="add-arrow">Multilevel chart of accounts that enable you to take Trial Balance at any level with Micro and Macro level Analysis. </p>
                <p class="add-arrow">Powerful drill in - drill out enquires (e.g. From Balance Sheet to lowest level of voucher details). </p>
                <p class="add-arrow">Multi-dimensional Cost Centers, which are completely flexible in terms of defining Branch, Location, Division, and their components.</p>
                <p class="add-arrow">User-defined Transaction Types as well as Voucher Types with individual security.</p>
                <p class="add-arrow">Multiple sub-ledgers with online/offline matching facility. </p>
                <p class="add-arrow">Payment to suppliers with matching or on-account basis along with printing the cheque or bulk print the cheque at a later stage. </p>
                <p class="add-arrow">Bulk payment voucher creation with various criteria and facilities </p>
                <p class="add-arrow">Electronic Transfers of payments with Bank Upload or Integration </p>
                <p class="add-arrow">Complete cheque management for CDC and PDC with user-friendly PDC module for auto-generating JVs’ for Release, Bouncing & Cancelling of cheques and Re-Depositing’.</p>
                <p class="add-arrow">Powerful Enquiry with Extensive Search Facility in all modules along with filters such as Date, all the key fields, sorting sequence of any column, etc to be selected by the user.</p>
                <p class="add-arrow">Multi-company, Multi-branch, Multi-location, Multi-department, Multi-Currency with Consolidated Accounting</p>
                <p class="add-arrow">Preparing of Tax Invoice and Tax Credit Note for customers, Accounting of Tax Invoice and Tax Credit Note from suppliers, keeping of other complicated cases of VAT accounting, Preparing of VAT Return and Sophisticated VAT Closing –monthly
                    or quarterly as applicable </p>
                <p class="add-arrow">Auto Year-end Procedure. Auto-generation of closing JV & P&L items. Auto transfer of closing balance to next year. No need to remember the closing accounts balances to be cleared. </p>
                <p class="add-arrow">Year-End can be processed more than once, if required, with security locking. </p>
                <p class="add-arrow">Powerful Audit Trail available for any data updated in the system. </p>
                <p class="add-arrow">The complete log is maintained for any activities of the user such as data entry/editing/update/delete / Print etc by tracking the date, time, and system or IP-Address from which the activity is carried out.</p>

            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Benefits at a glance  PART END ======-->
