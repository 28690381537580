import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Dsar } from 'src/app/models/dsar.model';
import { DsarService } from 'src/app/services/dsar-page/dsar.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dsar-page',
  templateUrl: './dsar-page.component.html',
  styleUrls: ['./dsar-page.component.css']
})
export class DsarPageComponent implements OnInit {
  
  contact: FormGroup;
  submitted = false;
  dsarForm!:Dsar;

  constructor(
    private formBuilder: FormBuilder,
    private dsar: DsarService,
    private snackbar:MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.contact = this.formBuilder.group({
      userName: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.email]],
      requestAs: ['', Validators.required],
      ccpaOhters: ['', Validators.required],
      comment: [''],
      confirmed1: [false, Validators.requiredTrue],
      confirmed2: [false, Validators.requiredTrue],
      confirmed3: [false, Validators.requiredTrue]
    });
  }

  // Convenience getter for easy access to form fields
  get f() { return this.contact.controls; }

  // Define the submitForm method to handle form submission
  submitForm() {
    this.submitted = true;
    if (this.contact.invalid) {
      return;
    }
  
    this.dsarForm = this.contact.value;
    this.dsarForm.websiteUrl = 'www.bostonfagroup.com';
  
    this.dsar.saveDsar(this.dsarForm).subscribe({
      next: (data: any) => {
        console.log(data);
        Swal.fire({
          icon: "success",
          title: "Thank you",
          text: "Your request has been saved successfully ",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      },
      error: error => {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    });
  }
  
  validateForm(object:any) {
    for(let key in object) {
      if (object[key] == '' || object[key] == null) {
        this.snackbar.open(key + " is must be required.",'OK',{
          duration: 5000
        });
        return false;
      }
    }
    return true;
  }
}

