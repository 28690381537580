<!-- Dubai start -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      ORDER TO CASH
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> ORDER TO CASH</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/order to cash.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 14rem"
        />
        <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Our Order to Cash solution is designed to improve collections, reduce
        cash cycles, and optimize accounts receivables across all departments.
      </p>
      <div>
        <h5 class="mb-20" style="color: green">
          WE OFFER TO SET UP AND MANAGE THE FOLLOWING ACTIVITIES:
        </h5>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Information analysis and automation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Aging analysis of receivables
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Electronic invoicing
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Management of contracts and payment terms
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Management of collections and receipts in cash or through credit
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Root cause analysis
          </li>
        </ul>
        <p
          class="text-dark mb-30"
          style="font-size: 17px; line-height: normal; font-weight: 500"
        >
          Our domain experts also study your business flow and offer
          recommendations and customized solutions to manage portfolios more
          efficiently by developing closer and more flexible relationships with
          your customers
        </p>
        <h5 class="mb-20 mt-50" style="color: green">BENEFITS:</h5>
        <ul style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Optimum use of domain experts and technology
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Strict control over aging of receivables to ensure timely receipts
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Risk analysis and mitigation by closely monitoring overall exposure
            to and conducting credit analysis of specific customers
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- Dubai End -->

<!-- India start -->
<div  *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      ORDER TO CASH
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> ORDER TO CASH</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/order to cash.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 14rem"
        />
        <!-- <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a> -->
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Our Order to Cash solution is designed to improve collections, reduce
        cash cycles, and optimize accounts receivables across all departments.
      </p>
      <div>
        <h5 class="mb-20" style="color: #0951A4">
          WE OFFER TO SET UP AND MANAGE THE FOLLOWING ACTIVITIES:
        </h5>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Information analysis and automation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Aging analysis of receivables
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Electronic invoicing
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Management of contracts and payment terms
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Management of collections and receipts in cash or through credit
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Root cause analysis
          </li>
        </ul>
        <p
          class="text-dark mb-30"
          style="font-size: 17px; line-height: normal; font-weight: 500"
        >
          Our domain experts also study your business flow and offer
          recommendations and customized solutions to manage portfolios more
          efficiently by developing closer and more flexible relationships with
          your customers
        </p>
        <h5 class="mb-20 mt-50" style="color: #0951A4">BENEFITS:</h5>
        <ul style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Optimum use of domain experts and technology
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Strict control over aging of receivables to ensure timely receipts
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Risk analysis and mitigation by closely monitoring overall exposure
            to and conducting credit analysis of specific customers
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- India  End -->

<!-- USA start -->
<div  *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      ORDER TO CASH
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >HOME <i class="fas fa-arrow-circle-right"></i> ORDER TO CASH</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/order to cash.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 14rem"
        />
        <!-- <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a> -->
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Our Order to Cash solution is designed to improve collections, reduce
        cash cycles, and optimize accounts receivables across all departments.
      </p>
      <div>
        <h5 class="mb-20" style="color: #0951A4">
          WE OFFER TO SET UP AND MANAGE THE FOLLOWING ACTIVITIES:
        </h5>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Information analysis and automation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Aging analysis of receivables
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Electronic invoicing
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Management of contracts and payment terms
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Management of collections and receipts in cash or through credit
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Root cause analysis
          </li>
        </ul>
        <p
          class="text-dark mb-30"
          style="font-size: 17px; line-height: normal; font-weight: 500"
        >
          Our domain experts also study your business flow and offer
          recommendations and customized solutions to manage portfolios more
          efficiently by developing closer and more flexible relationships with
          your customers
        </p>
        <h5 class="mb-20 mt-50" style="color: #0951A4">BENEFITS:</h5>
        <ul style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Optimum use of domain experts and technology
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Strict control over aging of receivables to ensure timely receipts
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Risk analysis and mitigation by closely monitoring overall exposure
            to and conducting credit analysis of specific customers
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- USA  End -->
