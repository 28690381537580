<div class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="background-color: #808080; height: 430px;">
    <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase" style="margin-top: 103px;">DSAR DATA </h3>
    </div>
</div>

<div class="container mt-5">
    <form [formGroup]="dsarForm" (ngSubmit)="onSubmit()">
        <div class="row mt-5">
            <div class="table-responsive">
                <table class="table custom-table">
                    <thead>
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Website</th>
                            <th scope="col">User name</th>
                            <th scope="col">Email address</th>
                            <th scope="col">Request as</th>
                            <th scope="col">CCPA/Other</th>
                            <th scope="col">Comment</th>
                            <th scope="col">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let row of dsars; let i = index;">
                            <tr>
                                <th scope="row">{{i + 1}}</th>
                                <td>
                                    <input type="text" formControlName="websiteUrl" class="form-control">
                                </td>
                                <td>
                                    <input type="text" formControlName="userName" class="form-control">
                                </td>
                                <td>
                                    <input type="email" formControlName="emailAddress" class="form-control">
                                </td>
                                <td>
                                    <input type="text" formControlName="requestAs" class="form-control">
                                </td>
                                <td>
                                    <input type="text" formControlName="ccpaOhters" class="form-control">
                                </td>
                                <td>
                                    <textarea formControlName="comment" class="form-control"></textarea>
                                </td>
                                <td>
                                    <input type="date" formControlName="dateTime" class="form-control">
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td *ngIf="f.websiteUrl.touched && f.websiteUrl.invalid" class="text-danger">
                                    <small *ngIf="f.websiteUrl.errors?.required">Website URL is required.</small>
                                    <small *ngIf="f.websiteUrl.errors?.pattern">Invalid URL format.</small>
                                </td>
                                <td *ngIf="f.userName.touched && f.userName.invalid" class="text-danger">
                                    <small *ngIf="f.userName.errors?.required">User name is required.</small>
                                </td>
                                <td *ngIf="f.emailAddress.touched && f.emailAddress.invalid" class="text-danger">
                                    <small *ngIf="f.emailAddress.errors?.required">Email address is required.</small>
                                    <small *ngIf="f.emailAddress.errors?.email">Invalid email format.</small>
                                </td>
                                <td *ngIf="f.requestAs.touched && f.requestAs.invalid" class="text-danger">
                                    <small *ngIf="f.requestAs.errors?.required">Request as is required.</small>
                                </td>
                                <td *ngIf="f.ccpaOhters.touched && f.ccpaOhters.invalid" class="text-danger">
                                    <small *ngIf="f.ccpaOhters.errors?.required">CCPA/Other is required.</small>
                                </td>
                                <td *ngIf="f.comment.touched && f.comment.invalid" class="text-danger">
                                    <small *ngIf="f.comment.errors?.required">Comment is required.</small>
                                </td>
                                <td *ngIf="f.dateTime.touched && f.dateTime.invalid" class="text-danger">
                                    <small *ngIf="f.dateTime.errors?.required">Date is required.</small>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 text-center">
                <button type="submit" class="btn btn-primary" [disabled]="dsarForm.invalid">Submit</button>
            </div>
        </div>
    </form>
</div>
