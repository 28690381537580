<!-- dubai start -->
<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">case study</h3>
    </div>
  </div>

  <div class="mt-50 container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img
        class="img-fluid"
        src="../../../../assets/images/case-studies/IT.png"
        alt=""
        style="margin-bottom: 2rem"
      />
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #418052;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >case study</small
          >
        </div>
        <div>
          <div class="mt-15">
            <span
              class="text-capitalize"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #418052 !important;
              "
              >IT Company Case Study</span
            >
          </div>
          <p class="text-dark servicep">
            MClient is into providing customized & cost effective solutions in
            the areas of SAP/JD Edwards implementation such as Business Process
            Reengineering, Expert Consulting, Training, Solution reviews,
            Safeguarding Services, Post implementation maintenance support. The
            client is having operations spread over places such as Australia,
            USA, Malaysia, South America, Africa apart from India.
            <a
              class="font-weight-bold"
              routerLink="/it-company-case-study"
              style="color: #418052"
              >Read more....</a
            >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-30">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #418052;
              "
            >
              <i class="fal fa-user"></i> By BFAG</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-50 container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img
        class="img-fluid"
        src="../../../../assets/images/case-studies/law.png"
        alt=""
        style="margin-bottom: 2rem"
      />
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #418052;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >case study</small
          >
        </div>
        <div>
          <div class="mt-15">
            <span
              class="text-capitalize text-dark"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #418052 !important;
              "
              >Law Firm Case Study</span
            >
          </div>
          <p class="text-dark servicep">
            Law Firm specialized in real estate closing support services and
            advisory services. The team, The team comprises of best of the
            lawyers each heading a different expert area.

            <a
              class="font-weight-bold"
              routerLink="/law-firm-case-study"
              style="color: #418052"
              >Read more....</a
            >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-30">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #418052;
              "
            >
              <i class="fal fa-user"></i> By BFAG</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-50 mb-50 container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img
        class="img-fluid"
        src="../../../../assets/images/case-studies/CPA.png"
        alt=""
        style="margin-bottom: 2rem"
      />
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #418052;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >case study</small
          >
        </div>
        <div>
          <div class="mt-15">
            <span
              class="text-capitalize text-dark"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #418052 !important;
              "
              >CPA Case Study</span
            >
          </div>
          <p class="text-dark servicep">
            When a Business expands its operations, managing data & documents
            emerge as one of the major problem. A Business also need to identify
            the areas that need to be reworked such as cutting the cost in
            certain field and adding more to another & It is possible to
            establish the long-term goals in Business when you outsource some of
            the back-end accounting operations. By outsourcing all your routine
            accounting functions, you’re able to focus on managing and growing
            your business.
            <a
              class="font-weight-bold"
              routerLink="/cpa-case-study"
              style="color: #418052"
              >Read more....</a
            >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-30">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #418052;
              "
            >
              <i class="fal fa-user"></i> By BFAG</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-50 mb-50 container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img
        class="img-fluid"
        src="../../../../assets/images/case-studies/vc.png"
        alt=""
        style="margin-bottom: 2rem"
      />
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #418052;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >case study</small
          >
        </div>
        <div>
          <div class="mt-15">
            <span
              class="text-capitalize"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #418052 !important;
              "
              >Vc Firm Case Study</span
            >
          </div>
          <p class="text-dark servicep">
            MClient is into providing customized & cost effective solutions in
            the areas of SAP/JD Edwards implementation such as Business Process
            Reengineering, Expert Consulting, Training, Solution reviews,
            Safeguarding Services, Post implementation maintenance support. The
            client is having operations spread over places such as Australia,
            USA, Malaysia, South America, Africa apart from India.
            <a
              class="font-weight-bold"
              routerLink="/vc-firm-case-study"
              style="color: #418052"
              >Read more....</a
            >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-30">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #418052;
              "
            >
              <i class="fal fa-user"></i> By BFAG</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- dubai end -->

<!-- India start -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png')no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">case study</h3>
    </div>
  </div>
  <div class="mt-50 container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img
        class="img-fluid"
        src="../../../../assets/images/case-studies/IT.png"
        alt=""
        style="margin-bottom: 2rem"
      />
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #072f5f;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >case study</small
          >
        </div>
        <div>
          <div class="mt-15">
            <span
              class="text-capitalize"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #002249 !important;
              "
              >IT Company Case Study</span
            >
          </div>
          <p class="text-dark servicep">
            MClient is into providing customized & cost effective solutions in
            the areas of SAP/JD Edwards implementation such as Business Process
            Reengineering, Expert Consulting, Training, Solution reviews,
            Safeguarding Services, Post implementation maintenance support. The
            client is having operations spread over places such as Australia,
            USA, Malaysia, South America, Africa apart from India.
            <a
              class="font-weight-bold"
              routerLink="/it-company-case-study"
              style="color: #072f5f"
              >Read more....</a
            >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-30">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #072f5f;
              "
            >
              <i class="fal fa-user"></i> By BFAG</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-50 container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img
        class="img-fluid"
        src="../../../../assets/images/case-studies/law.png"
        alt=""
        style="margin-bottom: 2rem"
      />
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #072f5f;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >case study</small
          >
        </div>
        <div>
          <div class="mt-15">
            <span
              class="text-capitalize text-dark"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #002249 !important;
              "
              >Law Firm Case Study</span
            >
          </div>
          <p class="text-dark servicep">
            Law Firm specialized in real estate closing support services and
            advisory services. The team, The team comprises of best of the
            lawyers each heading a different expert area.

            <a
              class="font-weight-bold"
              routerLink="/law-firm-case-study"
              style="color: #072f5f"
              >Read more....</a
            >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-30">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #072f5f;
              "
            >
              <i class="fal fa-user"></i> By BFAG</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-50 mb-50 container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img
        class="img-fluid"
        src="../../../../assets/images/case-studies/CPA.png"
        alt=""
        style="margin-bottom: 2rem"
      />
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #072f5f;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >case study</small
          >
        </div>
        <div>
          <div class="mt-15">
            <span
              class="text-capitalize text-dark"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #002249 !important;
              "
              >CPA Case Study</span
            >
          </div>
          <p class="text-dark servicep">
            When a Business expands its operations, managing data & documents
            emerge as one of the major problem. A Business also need to identify
            the areas that need to be reworked such as cutting the cost in
            certain field and adding more to another & It is possible to
            establish the long-term goals in Business when you outsource some of
            the back-end accounting operations. By outsourcing all your routine
            accounting functions, you’re able to focus on managing and growing
            your business.
            <a
              class="font-weight-bold"
              routerLink="/cpa-case-study"
              style="color: #072f5f"
              >Read more....</a
            >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-30">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #072f5f;
              "
            >
              <i class="fal fa-user"></i> By BFAG</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-50 mb-50 container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img
        class="img-fluid"
        src="../../../../assets/images/case-studies/vc.png"
        alt=""
        style="margin-bottom: 2rem"
      />
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #072f5f;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >case study</small
          >
        </div>
        <div>
          <div class="mt-15">
            <span
              class="text-capitalize"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #002249 !important;
              "
              >Vc Firm Case Study</span
            >
          </div>
          <p class="text-dark servicep">
            MClient is into providing customized & cost effective solutions in
            the areas of SAP/JD Edwards implementation such as Business Process
            Reengineering, Expert Consulting, Training, Solution reviews,
            Safeguarding Services, Post implementation maintenance support. The
            client is having operations spread over places such as Australia,
            USA, Malaysia, South America, Africa apart from India.
            <a
              class="font-weight-bold"
              routerLink="/vc-firm-case-study"
              style="color: #072f5f"
              >Read more....</a
            >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-30">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #072f5f;
              "
            >
              <i class="fal fa-user"></i> By BFAG</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- INdia end -->

<!-- USA start -->
<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">case study</h3>
    </div>
  </div>
  <div class="mt-50 container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img
        class="img-fluid"
        src="../../../../assets/images/case-studies/IT.png"
        alt=""
        style="margin-bottom: 2rem"
      />
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #072f5f;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >case study</small
          >
        </div>
        <div>
          <div class="mt-15">
            <span
              class="text-capitalize"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #002249 !important;
              "
              >IT Company Case Study</span
            >
          </div>
          <p class="text-dark servicep">
            MClient is into providing customized & cost effective solutions in
            the areas of SAP/JD Edwards implementation such as Business Process
            Reengineering, Expert Consulting, Training, Solution reviews,
            Safeguarding Services, Post implementation maintenance support. The
            client is having operations spread over places such as Australia,
            USA, Malaysia, South America, Africa apart from India.
            <a
              class="font-weight-bold"
              routerLink="/it-company-case-study"
              style="color: #072f5f"
              >Read more....</a
            >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-30">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #072f5f;
              "
            >
              <i class="fal fa-user"></i> By BFAG</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-50 container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img
        class="img-fluid"
        src="../../../../assets/images/case-studies/law.png"
        alt=""
        style="margin-bottom: 2rem"
      />
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #072f5f;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >case study</small
          >
        </div>
        <div>
          <div class="mt-15">
            <span
              class="text-capitalize text-dark"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #002249 !important;
              "
              >Law Firm Case Study</span
            >
          </div>
          <p class="text-dark servicep">
            Law Firm specialized in real estate closing support services and
            advisory services. The team, The team comprises of best of the
            lawyers each heading a different expert area.

            <a
              class="font-weight-bold"
              routerLink="/law-firm-case-study"
              style="color: #072f5f"
              >Read more....</a
            >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-30">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #072f5f;
              "
            >
              <i class="fal fa-user"></i> By BFAG</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-50 mb-50 container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img
        class="img-fluid"
        src="../../../../assets/images/case-studies/CPA.png"
        alt=""
        style="margin-bottom: 2rem"
      />
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #072f5f;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >case study</small
          >
        </div>
        <div>
          <div class="mt-15">
            <span
              class="text-capitalize text-dark"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #002249 !important;
              "
              >CPA Case Study</span
            >
          </div>
          <p class="text-dark servicep">
            When a Business expands its operations, managing data & documents
            emerge as one of the major problem. A Business also need to identify
            the areas that need to be reworked such as cutting the cost in
            certain field and adding more to another & It is possible to
            establish the long-term goals in Business when you outsource some of
            the back-end accounting operations. By outsourcing all your routine
            accounting functions, you’re able to focus on managing and growing
            your business.
            <a
              class="font-weight-bold"
              routerLink="/cpa-case-study"
              style="color: #072f5f"
              >Read more....</a
            >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-30">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #072f5f;
              "
            >
              <i class="fal fa-user"></i> By BFAG</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-50 mb-50 container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img
        class="img-fluid"
        src="../../../../assets/images/case-studies/vc.png"
        alt=""
        style="margin-bottom: 2rem"
      />
      <div class="border" style="padding: 1rem">
        <div
          style="
            background: #072f5f;
            display: inline;
            width: fit-content;
            padding: 0.2rem 1rem 0.2rem 1rem;
          "
        >
          <small
            class="text-uppercase text-white"
            style="font-weight: 600 !important; font-size: 15px !important"
            >case study</small
          >
        </div>
        <div>
          <div class="mt-15">
            <span
              class="text-capitalize"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #002249 !important;
              "
              >Vc Firm Case Study</span
            >
          </div>
          <p class="text-dark servicep">
            MClient is into providing customized & cost effective solutions in
            the areas of SAP/JD Edwards implementation such as Business Process
            Reengineering, Expert Consulting, Training, Solution reviews,
            Safeguarding Services, Post implementation maintenance support. The
            client is having operations spread over places such as Australia,
            USA, Malaysia, South America, Africa apart from India.
            <a
              class="font-weight-bold"
              routerLink="/vc-firm-case-study"
              style="color: #072f5f"
              >Read more....</a
            >
          </p>
          <div class="d-flex justify-content-start align-items-start mt-30">
            <small
              style="
                font-weight: 600 !important;
                font-size: 14px !important;
                color: #072f5f;
              "
            >
              <i class="fal fa-user"></i> By BFAG</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- USA end -->
