import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { metaData } from './services/metaData';
import { CustomCaptcha } from './services/custom-captcha';
// import { CanonicalService } from './shared/canonical.service';
// import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { CookieService } from 'ngx-cookie-service';import APIURL from './helper/apiUrl';
import { MatDialog } from '@angular/material/dialog';
import APIURL from './helper/apiUrl';
import { GeoLocationService } from './services/geolocation/geolocation.service';
declare let $: any;


@Component({

  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit {
  httpOptions = {
    headers: new HttpHeaders({
    'X-Skip-SSL-Validation' : 'true'
    })
  };
  location: any;
  routerSubscription: any;
  locations: Location
  showHeader: boolean = true;
  showFooter: boolean = true;
  isEssential: boolean = false;
  isadvertising: boolean = false;
  isAandC: boolean = false;
  isPandF: boolean = false;
  isSandN: boolean = false;
  showBanner: any;
  coockiesFlag: boolean = false;
  geoLocation!: string;
  cookieData = {
    ipAddress: '',
    cookies: '',
    longiTude: '',
    latiTude: '',
    accuracy: ''
  };
  isunclassified: boolean = false;
  @ViewChild('cookieDialog') cookieDialog!: TemplateRef<any>;

  constructor(private router: Router,
    private metaData: metaData,
    private _customCaptcha: CustomCaptcha,
    private metaTagService: Meta, private title: Title,
    private geoLocationService: GeoLocationService,
    private dialog: MatDialog, private http: HttpClient
  ) {
    this.metaData.metaInfo();

  }

  ngOnInit() {

    this.location = this.getGeoLocation();
    let c_flag = localStorage.getItem('c_flag');
    if (c_flag == null || c_flag == 'false') {
      this.showBanner = true;
    } else if (c_flag == 'true') {
      this.showBanner = false;
      //  localStorage.setItem('c_flag','false');
    }
    else {
      this.showBanner = c_flag ? true : true;
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check the current route and update the header and sidebar visibility accordingly
        const currentRoute = event.url;
        console.log(currentRoute);
        this.showHeader = !currentRoute.includes('/admin');
        this.showFooter = !currentRoute.includes('/admin');
      }
    });

  }

  ngAfterViewInit(): void {
    this.recallJsFuntions();
  }

  recallJsFuntions() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          $('.preloader').fadeIn('slow');
        }
      });
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
        $.getScript('assets/js/main.js');
        $('.preloader').fadeOut('slow');
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }
  openAddDialog() {
    let dialogRef = this.dialog.open(this.cookieDialog);
  }
  getGeoLocation() {
    this.geoLocationService.getLocation().subscribe(
      (position: any) => {
        this.cookieData.longiTude = position.coords.longitude
        this.cookieData.latiTude = position.coords.latitude
        this.cookieData.accuracy = position.coords.accuracy
      },
      (error: any) => {
        console.error('Failed to get geolocation:', error);
        // Handle error appropriately
      }
    );
  }

    async acceptAll() {

      let data = document.cookie;
      this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
          this.cookieData.ipAddress = res.ip;
          console.log(this.cookieData.ipAddress);
          console.log(res);9
          this.cookieData.cookies = JSON.stringify(data);
          console.log(this.location);

          this.http.post(`${APIURL}/save`,this.cookieData,this.httpOptions)
          .subscribe({  
              next: (data:any) => {
                  localStorage.setItem('c_flag',JSON.stringify(this.showBanner));
                  console.log(data);
              },
              error: error => {
                  console.error(error);
              }
          });
      });

      localStorage.setItem('c_flag','true');

      // this._toaster.success("Cookies saved succesfully for " + expirationDate + " days");
  }

  closeDialog() {

  }

}

