<!-- dubai start -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      DIRECT TAX SERVICES
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >Home <i class="fas fa-arrow-circle-right"></i> DIRECT TAX SERVICES</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5  imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/order to cash.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 14rem"
        />
        <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <div>
        <h4 class="mb-20" style="color: green;">
          BFAG APPROACH:
        </h4>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Establish tax strategies based on domain expertise and industry
            standards
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Helps you assess, improve and monitor your tax function’s processes,
            controls and risk management ( BFAG Compliance iNbox helps in
            monitoring all the regulatory compliances related to Tax regulation
            and time lines www.complianceinbox.com)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Supports you in managing your relationships with tax authorities
            effectively
          </li>
          <li class="mb-2">
            We provide our clients with a complete spectrum of tax advisory
            services ranging from tax planning to advice –
          </li>
        </ul>
        <h4
          class="mb-20 text-uppercase"
          style="color: green; "
        >
          Business Tax Advisory and Compliance:
        </h4>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Identifying Tax exposures and Tax planning
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Documentation, preparation and review of tax returns
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Cross Border taxation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Providing Assistance in Transfer pricing study and Documentation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Tax accounting under IFRS, IN GAAP or US GAAP
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Assisting in building Organisational Strategy , process and System
            to manage complex Business environment.
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h3
        class="text-center"
        style="border-bottom: 3px solid #21ac51; display: inline-block"
      >
        EXPAT AND IMMIGRANTS TAXATION
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Apart from the complicated tax laws, expatriates and immigrants
              require a thorough knowledge of the various treaties governing
              their taxation. BFAG offers expat and immigrant taxation services
              to ensure that you need not be hassled by the various
              ever-changing policies associated with your taxation. Our services
              include the following:
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Short and long term tax planning as per US and Indian taxes to
              reduce overall tax liability
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Filing of both US and Indian tax returns
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Representation before IRS and Indian Income Tax Authorities for
              enquiries, notices, audits
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Filing of Indian wealth tax returns and wealth tax planning
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              US exit tax returns
            </li>
          </ul>
          <h4
            class="mb-20 text-uppercase"
            style="color: green; font-family: sans-serif"
          >
            Benefits:
          </h4>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Access to consultants who can manage both your short term and long
              term needs
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Hassle-free tax filing in the hands of domain experts who
              continuously keep track of changing regulations and treaties
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/Tax.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h3
        class="text-center"
        style="border-bottom: 3px solid #21ac51; display: inline-block"
      >
        BFAG INDIVIDUAL TAX ADVISORY AND COMPLIANCE
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/bfag_advisory.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Boston Financial is specialized in offering Tax Planning,
              Preparation and Filing of US Individual Income Tax Return services
              for US Citizens, Green Card Holders, Expatriates from other
              countries
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Our services, personalized for each individual:
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Calculation and optimization of Indian and US tax deductions and
              claims;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Treatment of expenses incurred abroad;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Claiming foreign tax credit in US for taxes paid in any other
              country;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Taxation for first year migrants to the US;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Taxation for resident/ non-resident tax payers with
              parents/spouse/children living abroad;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Taxation associated with ESOPs or similar plans
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Dual status tax returns filing.
            </li>
          </ul>
          <h4
            class="mb-20 text-uppercase"
            style="color: green; font-family: sans-serif"
          >
            Benefits:
          </h4>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Quick and accurate tax calculation taking cognizance of both
              Indian and US tax laws
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Support for filing of returnsto reduce the possibility of
              erroneous filings
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Access to consultants who can optimize tax payments and claims to
              your benefits
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h3
        class="text-center"
        style="border-bottom: 3px solid #21ac51; display: inline-block"
      >
        NON RESIDENTS’ TAXATION
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              India is a favorite investment destination for NRIs. The taxation associated with their investments in stocks, bonds, real estate etc can often be quite complicated. The number of US residents investing in India is also on the rise. The tax implications of the investments they make overseas are governed by another set of regulations. BFAG offers the following taxation services for US residents having income / property / assets in India and NRIs having income in India:

            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Filing of Indian income tax return;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Filing of Indian wealth tax return;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Tax planning to reduce current and future tax liabilities.
            </li>
          </ul>
          <h4
            class="mb-20 text-uppercase"
            style="color: green; font-family: sans-serif"
          >
            Benefits:
          </h4>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Access to domain experts who can help you with strategic investment and tax planning;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Tax consulting, calculation and filing at affordable prices.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/Non_resident_tax.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- dubai end -->
<!-- India start -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      DIRECT TAX SERVICES
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >Home <i class="fas fa-arrow-circle-right"></i> DIRECT TAX SERVICES</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5  imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/order to cash.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 14rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <div>
        <h4 class="mb-20" style="color: 0951A4;">
          BFAG APPROACH:
        </h4>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Establish tax strategies based on domain expertise and industry
            standards
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Helps you assess, improve and monitor your tax function’s processes,
            controls and risk management ( BFAG Compliance iNbox helps in
            monitoring all the regulatory compliances related to Tax regulation
            and time lines www.complianceinbox.com)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Supports you in managing your relationships with tax authorities
            effectively
          </li>
          <li class="mb-2">
            We provide our clients with a complete spectrum of tax advisory
            services ranging from tax planning to advice –
          </li>
        </ul>
        <h4
          class="mb-20 text-uppercase"
          style="color: #0951A4; "
        >
          Business Tax Advisory and Compliance:
        </h4>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Identifying Tax exposures and Tax planning
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Documentation, preparation and review of tax returns
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Cross Border taxation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Providing Assistance in Transfer pricing study and Documentation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Tax accounting under IFRS, IN GAAP or US GAAP
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Assisting in building Organisational Strategy , process and System
            to manage complex Business environment.
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h3
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
        EXPAT AND IMMIGRANTS TAXATION
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Apart from the complicated tax laws, expatriates and immigrants
              require a thorough knowledge of the various treaties governing
              their taxation. BFAG offers expat and immigrant taxation services
              to ensure that you need not be hassled by the various
              ever-changing policies associated with your taxation. Our services
              include the following:
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Short and long term tax planning as per US and Indian taxes to
              reduce overall tax liability
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Filing of both US and Indian tax returns
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Representation before IRS and Indian Income Tax Authorities for
              enquiries, notices, audits
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Filing of Indian wealth tax returns and wealth tax planning
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              US exit tax returns
            </li>
          </ul>
          <h4
            class="mb-20 text-uppercase"
            style="color: #0951A4; font-family: sans-serif"
          >
            Benefits:
          </h4>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Access to consultants who can manage both your short term and long
              term needs
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Hassle-free tax filing in the hands of domain experts who
              continuously keep track of changing regulations and treaties
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/Tax.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h3
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
        BFAG INDIVIDUAL TAX ADVISORY AND COMPLIANCE
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/bfag_advisory.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Boston Financial is specialized in offering Tax Planning,
              Preparation and Filing of US Individual Income Tax Return services
              for US Citizens, Green Card Holders, Expatriates from other
              countries
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Our services, personalized for each individual:
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Calculation and optimization of Indian and US tax deductions and
              claims;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Treatment of expenses incurred abroad;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Claiming foreign tax credit in US for taxes paid in any other
              country;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Taxation for first year migrants to the US;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Taxation for resident/ non-resident tax payers with
              parents/spouse/children living abroad;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Taxation associated with ESOPs or similar plans
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Dual status tax returns filing.
            </li>
          </ul>
          <h4
            class="mb-20 text-uppercase"
            style="color: #0951A4; font-family: sans-serif"
          >
            Benefits:
          </h4>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Quick and accurate tax calculation taking cognizance of both
              Indian and US tax laws
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Support for filing of returnsto reduce the possibility of
              erroneous filings
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Access to consultants who can optimize tax payments and claims to
              your benefits
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h3
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
        NON RESIDENTS’ TAXATION
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              India is a favorite investment destination for NRIs. The taxation associated with their investments in stocks, bonds, real estate etc can often be quite complicated. The number of US residents investing in India is also on the rise. The tax implications of the investments they make overseas are governed by another set of regulations. BFAG offers the following taxation services for US residents having income / property / assets in India and NRIs having income in India:

            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Filing of Indian income tax return;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Filing of Indian wealth tax return;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Tax planning to reduce current and future tax liabilities.
            </li>
          </ul>
          <h4
            class="mb-20 text-uppercase"
            style="color: #0951A4; font-family: sans-serif"
          >
            Benefits:
          </h4>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Access to domain experts who can help you with strategic investment and tax planning;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Tax consulting, calculation and filing at affordable prices.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/Non_resident_tax.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- India end -->

<!-- USA start -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      DIRECT TAX SERVICES
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0" routerLink="/financial-services"
        >Home <i class="fas fa-arrow-circle-right"></i> DIRECT TAX SERVICES</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5  imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/order to cash.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 14rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <div>
        <h4 class="mb-20" style="color: 0951A4;">
          BFAG APPROACH:
        </h4>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Establish tax strategies based on domain expertise and industry
            standards
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Helps you assess, improve and monitor your tax function’s processes,
            controls and risk management ( BFAG Compliance iNbox helps in
            monitoring all the regulatory compliances related to Tax regulation
            and time lines www.complianceinbox.com)
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Supports you in managing your relationships with tax authorities
            effectively
          </li>
          <li class="mb-2">
            We provide our clients with a complete spectrum of tax advisory
            services ranging from tax planning to advice –
          </li>
        </ul>
        <h4
          class="mb-20 text-uppercase"
          style="color: #0951A4; "
        >
          Business Tax Advisory and Compliance:
        </h4>
        <ul class="mt-10" style="line-height: 2rem">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Identifying Tax exposures and Tax planning
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Documentation, preparation and review of tax returns
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Cross Border taxation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Providing Assistance in Transfer pricing study and Documentation
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Tax accounting under IFRS, IN GAAP or US GAAP
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Assisting in building Organisational Strategy , process and System
            to manage complex Business environment.
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h3
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
        EXPAT AND IMMIGRANTS TAXATION
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Apart from the complicated tax laws, expatriates and immigrants
              require a thorough knowledge of the various treaties governing
              their taxation. BFAG offers expat and immigrant taxation services
              to ensure that you need not be hassled by the various
              ever-changing policies associated with your taxation. Our services
              include the following:
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Short and long term tax planning as per US and Indian taxes to
              reduce overall tax liability
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Filing of both US and Indian tax returns
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Representation before IRS and Indian Income Tax Authorities for
              enquiries, notices, audits
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Filing of Indian wealth tax returns and wealth tax planning
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              US exit tax returns
            </li>
          </ul>
          <h4
            class="mb-20 text-uppercase"
            style="color: #0951A4; font-family: sans-serif"
          >
            Benefits:
          </h4>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Access to consultants who can manage both your short term and long
              term needs
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Hassle-free tax filing in the hands of domain experts who
              continuously keep track of changing regulations and treaties
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/Tax.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h3
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
        BFAG INDIVIDUAL TAX ADVISORY AND COMPLIANCE
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/bfag_advisory.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Boston Financial is specialized in offering Tax Planning,
              Preparation and Filing of US Individual Income Tax Return services
              for US Citizens, Green Card Holders, Expatriates from other
              countries
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Our services, personalized for each individual:
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Calculation and optimization of Indian and US tax deductions and
              claims;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Treatment of expenses incurred abroad;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Claiming foreign tax credit in US for taxes paid in any other
              country;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Taxation for first year migrants to the US;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Taxation for resident/ non-resident tax payers with
              parents/spouse/children living abroad;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Taxation associated with ESOPs or similar plans
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Dual status tax returns filing.
            </li>
          </ul>
          <h4
            class="mb-20 text-uppercase"
            style="color: #0951A4; font-family: sans-serif"
          >
            Benefits:
          </h4>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Quick and accurate tax calculation taking cognizance of both
              Indian and US tax laws
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Support for filing of returnsto reduce the possibility of
              erroneous filings
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Access to consultants who can optimize tax payments and claims to
              your benefits
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70">
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-bottom: 45px !important"
    >
      <h3
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
        NON RESIDENTS’ TAXATION
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              India is a favorite investment destination for NRIs. The taxation associated with their investments in stocks, bonds, real estate etc can often be quite complicated. The number of US residents investing in India is also on the rise. The tax implications of the investments they make overseas are governed by another set of regulations. BFAG offers the following taxation services for US residents having income / property / assets in India and NRIs having income in India:

            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Filing of Indian income tax return;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Filing of Indian wealth tax return;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Tax planning to reduce current and future tax liabilities.
            </li>
          </ul>
          <h4
            class="mb-20 text-uppercase"
            style="color: #0951A4; font-family: sans-serif"
          >
            Benefits:
          </h4>
          <ul class="mt-10" style="line-height: 2rem">
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Access to domain experts who can help you with strategic investment and tax planning;
            </li>
            <li class="mb-2">
              <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
              Tax consulting, calculation and filing at affordable prices.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/financial service/Non_resident_tax.jpg"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- USA end -->

