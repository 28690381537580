<!-- Dubai start -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop">
  <div
  class="d-flex justify-content-center align-items-center banner-image bmTop"
  style="
    background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
  "
>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h3 class="text-white">ANALYTICS iNBOX</h3>
  </div>
</div>
<div style="overflow: hidden; position: relative;">
  <img src="../../../../../assets/images/products/products-abstract.png" alt="shape" style="position: absolute; overflow: clip;left: -3%; top: 0%; "/>

  <div class="d-flex justify-content-start align-items-start container mb-50 mt-50">
    <div class="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">

      <img src="../../../../assets/images/products/analytics.png" class="img-fluid" style="height: 23rem;" />

  </div>
    <div class="col-lg-8 col-md-12">
      <p
        class="text-dark mb-30 text-justify"
        style="line-height: 2.5rem; font-weight: 500"
      >
        Analytics iNBOX gives Information at the click of a button with 24 / 7
        availability from anywhere, Birds eye view of the financial position of
        the enterprise, Usual parameters such as receivables, parable's,
        statutory Dues, Segment wise profitability, Smartness built into it so
        that payment authorization can be done, Complete picture in one screen,
        Working Capital Management, Customizable to suit individual
        requirements.
      </p>

      <div class="navbar-btn contactUs-btn cursor-pointer" routerLink="/under-development">
        <a  class="text-white btn d-sm-mobile rounded contactBtn" style="background: #418052;"  ng-reflect-router-link-active="active" >Learn More</a>
      </div>
    </div>
  </div>
</div>

</div>
<!-- dubai end -->

<!-- india start -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div
  class="d-flex justify-content-center align-items-center banner-image bmTop"
  style="
    background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
  "
>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h3 class="text-white">ANALYTICS iNBOX</h3>
  </div>
</div>
<div style="overflow: hidden; position: relative;">
  <img src="../../../../../assets/images/products/products-abstract.png" alt="shape" style="position: absolute; overflow: clip;left: -3%; top: 0%; "/>


  <div class="d-flex justify-content-start align-items-start container mb-50 mt-50">
    <div class="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">

      <img src="../../../../assets/images/products/analytics.png" class="img-fluid" style="height: 23rem;" />

  </div>
    <div class="col-lg-8 col-md-12">
      <p
        class="text-dark mb-30 text-justify"
        style="line-height: 2.5rem; font-weight: 500"
      >
        Analytics iNBOX gives Information at the click of a button with 24 / 7
        availability from anywhere, Birds eye view of the financial position of
        the enterprise, Usual parameters such as receivables, parable's,
        statutory Dues, Segment wise profitability, Smartness built into it so
        that payment authorization can be done, Complete picture in one screen,
        Working Capital Management, Customizable to suit individual
        requirements.
      </p>

      <div class="navbar-btn contactUs-btn cursor-pointer" routerLink="/under-development">
        <a  class="text-white btn d-sm-mobile rounded contactBtn" style="background: #072F5F;"  ng-reflect-router-link-active="active" >Learn More</a>
      </div>
    </div>
  </div>
</div>

</div>
<!-- india end -->


<!-- USA start -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div
  class="d-flex justify-content-center align-items-center banner-image bmTop"
  style="
    background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
  "
>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h3 class="text-white">ANALYTICS iNBOX</h3>
  </div>
</div>
<div style="overflow: hidden; position: relative;">
  <img src="../../../../../assets/images/products/products-abstract.png" alt="shape" style="position: absolute; overflow: clip;left: -3%; top: 0%; "/>


  <div class="d-flex justify-content-start align-items-start container mb-50 mt-50">
    <div class="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">

      <img src="../../../../assets/images/products/analytics.png" class="img-fluid" style="height: 23rem;" />

  </div>
    <div class="col-lg-8 col-md-12">
      <p
        class="text-dark mb-30 text-justify"
        style="line-height: 2.5rem; font-weight: 500"
      >
        Analytics iNBOX gives Information at the click of a button with 24 / 7
        availability from anywhere, Birds eye view of the financial position of
        the enterprise, Usual parameters such as receivables, parable's,
        statutory Dues, Segment wise profitability, Smartness built into it so
        that payment authorization can be done, Complete picture in one screen,
        Working Capital Management, Customizable to suit individual
        requirements.
      </p>

      <div class="navbar-btn contactUs-btn cursor-pointer" routerLink="/under-development">
        <a  class="text-white btn d-sm-mobile rounded contactBtn" style="background: #072F5F;"  ng-reflect-router-link-active="active" >Learn More</a>
      </div>
    </div>
  </div>
</div>

</div>
<!-- USA end -->

