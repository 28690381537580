<!-- India started -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop divmart">
    <div
      class="d-flex justify-content-center align-items-center banner-image bmTop"
      style="
        background: url('../../../../assets/images/blogs/blog.png') no-repeat center;
        background-size: cover;
        height: 430px;
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase text-center">
          Blog Details
        </h3>
      </div>
    </div>
    <div class="container-fluid">
    <div
      class="d-flex row justify-content-center align-items-start pl-4 pr-4"
      style="gap: 2rem"
    >
      <div class="col-lg-8 mt-50 mb-50 border shadow" style="padding: 2rem">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <img
            class="img-fluid"
            src="../../../../assets/images/blogs/img1.png"
            alt=""
            style="margin-bottom: 2rem"
          />
          <div>
            <div
              style="
                background: #072f5f;
                display: inline;
                width: fit-content;
                padding: 0.2rem 1rem 0.2rem 1rem;
              "
            >
              <small
                class="text-uppercase text-white"
                style="font-weight: 600 !important; font-size: 15px !important"
                >business</small
              >
            </div>
            <div>
              <div class="mt-15">
                <span
                  class="text-capitalize"
                  style="
                    font-size: 1.5rem !important;
                    font-weight: 700 !important;
                    color: #002249 !important;
                  "
                  >Overview of the India Digital Personal Data Protection Bill </span
                >
              </div>
              <p class="text-dark servicep">
                The India Digital Personal Data Protection Bill 2023 is a legislative proposal that aims to regulate the processing, storage, and transfer of personal data in the digital realm. It draws inspiration from global data protection frameworks, such as the European Union's General Data Protection Regulation (GDPR). The bill seeks to establish a comprehensive legal framework that addresses the growing concerns surrounding data privacy and protection in India. It introduces several key provisions, including the classification of personal and sensitive personal data, the requirement for data fiduciaries to obtain consent for data processing, the establishment of a Data Protection Authority, and the provision of rights for individuals to access, correct, and erase their personal data. Additionally, the bill proposes penalties for non-compliance, which can range from monetary fines to imprisonment. 
              </p>
            </div>
            <div>
              <div class="mt-15">
                <span
                  class="text-capitalize"
                  style="
                    font-size: 1.5rem !important;
                    font-weight: 700 !important;
                    color: #002249 !important;
                  "
                  >Key provisions and implications of the Digital Personal Data Protection Bill </span
                >
              </div>
              <ul class="text-dark servicep" style="list-style-type: disc">
                <li>Consent: Businesses and government agencies must obtain consent from individuals before collecting their personal data. Consent must be freely given, specific, informed, and unambiguous.</li>
                <li>Purpose limitation: Personal data can only be collected and used for the purposes for which it was collected. Businesses and government agencies cannot use personal data for any other purposes without the individual's consent..</li>
                <li>Data minimization: Businesses and government agencies must only collect the personal data that is necessary for the purposes for which it is being collected. They cannot collect more personal data than they need.</li>
                <li>Storage limitation: Personal data must be stored for no longer than is necessary for the purposes for which it was collected.</li>
                <li>Accuracy: Businesses and government agencies must keep personal data accurate and up-to-date.</li>
                <li>Security: Businesses and government agencies must take appropriate measures to protect personal data from unauthorized access, use, disclosure, alteration, or destruction.</li>
                <li>Transparency: Businesses and government agencies must be transparent about how they collect, use, and store personal data. Individuals must be able to access their personal data and request that it be corrected or deleted.</li>
                <li>Right to data portability: Individuals have the right to receive their personal data in a structured, commonly used, and machine-readable format.</li>
                <li>Right to be forgotten: Individuals have the right to request that businesses and government agencies delete their personal data.</li>
                <li>Right to object: Individuals have the right to object to the processing of their personal data for certain purposes, such as direct marketing.</li>
                <li>Right to restrict processing: Individuals have the right to restrict the processing of their personal data in certain circumstances, such as if they believe that the data is inaccurate or that it is being processed unlawfully.</li>
                <li>Right to file a complaint: Individuals have the right to file a complaint with the Data Protection Authority if they believe that their privacy rights have been violated.</li>
              </ul>
            </div>
            <div>
              <div class="mt-15">
                <span
                  class="text-capitalize"
                  style="
                    font-size: 1.5rem !important;
                    font-weight: 700 !important;
                    color: #002249 !important;
                  "
                  >Understanding the importance of the Digital Personal Data Protection Bill. </span
                >
              </div>
              <p class="text-dark servicep mb-10">
                Understanding the importance of the Digital Personal Data Protection Bill is crucial in recognizing the significance of data privacy and protection in the digital age. With the rapid advancements in technology and the widespread use of digital platforms, the need for robust data protection measures has become paramount. This bill addresses this need by providing a comprehensive framework that aims to safeguard personal data, instill trust among individuals, and promote responsible data handling practices. By introducing provisions such as consent requirements, data access rights, and penalties for non-compliance, the bill seeks to create a transparent and accountable system that respects individuals' privacy rights. The importance of this bill extends to various stakeholders, including individuals who want greater control over their personal information, businesses that handle sensitive data, and the government that seeks to create a secure digital ecosystem for its citizens.
              </p>
              <p class="text-dark servicep mb-10">
                In conclusion, the India Digital Personal Data Protection Bill and its subsequent amendments mark a significant stride in data protection within the nation. This legislation strives to establish a comprehensive digital framework for safeguarding personal data, addressing privacy and security concerns. While the bill introduces vital provisions for enhanced data security and accountability, addressing criticisms is essential for successful execution. The bill's efficacy depends on its seamless implementation and evolution, considering the ever-changing digital landscape and emerging technologies. Balancing data protection with innovation and economic growth remains pivotal. By upholding responsible data practices and prioritizing privacy, this bill has the potential to cultivate a secure and trustworthy digital ecosystem for all stakeholders involved.
              </p>
              <p class="text-dark servicep">
                As Boston Financial Advisory Group, we align with this initiative by meticulously implementing data protection measures within our operations. By adhering to clear guidelines, definitions, and efficient redressal mechanisms, we foster trust among individuals, businesses, and the regulatory framework.
              </p>
            </div>
            <div class="d-flex justify-content-start align-items-start mt-30">
              <small
                style="
                  font-weight: 600 !important;
                  font-size: 14px !important;
                  color: #072f5f;
                "
              >
                <i class="fal fa-user"></i> By BFAG</small
              >
              <small
                style="
                  font-weight: 600 !important;
                  font-size: 14px !important;
                  color: #072f5f;
                  margin-left:1rem
                "
              >
              <i class="fal fa-calendar"></i> 6/9/2023</small
              >
            </div>
          </div>
        </div>
      </div>
      <!--<div
        class="mt-50 border d-flex flex-column justify-content-start align-items-start"
        style="padding: 2rem"
      >
        <div
          class="mt-15 mb-10 d-flex justify-content-center align-items-center"
          style="gap: 5px"
        >
          <div
            class=""
            style="border: 3px solid #072f5f; width: 1.5rem; height: 1px"
          ></div>
          <div
            class=""
            style="border: 3px solid #072f5f; width: 0.5rem; height: 1px"
          ></div>
          <div>
            <span
              class="text-capitalize"
              style="
                font-size: 1.5rem !important;
                font-weight: 700 !important;
                color: #002249 !important;
              "
              >Case Studies</span
            >
          </div>
        </div>
        <div
          class="d-flex justify-content-start align-items-start"
          style="
            width: 18rem;
            gap: 1rem;
            padding: 1rem;
            border-bottom: 2px solid #e3eeff;
          "
        >
          <img
            class="img-fluid"
            src="../../../../../assets/images/case-studies/img1.png"
            alt="Card image cap"
          />
          <div>
            <small
              class="text-capitalize text-dark"
              style="font-weight: 600 !important; font-size: 15px !important"
              >Emirates Facilities <br />
              Management</small
            >
            <div>
              <small
                class="text-capitalize"
                style="
                  font-weight: 600 !important;
                  font-size: 13px !important;
                  color: #69a1bb;
                "
                ><i class="fas fa-calendar-alt"></i> Jul 19, 2020</small
              >
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-start align-items-start"
          style="
            width: 18rem;
            gap: 1rem;
            padding: 1rem;
            border-bottom: 2px solid #e3eeff;
          "
        >
          <img
            class="img-fluid"
            src="../../../../../assets/images/case-studies/img2.png"
            alt="Card image cap"
          />
          <div>
            <small
              class="text-capitalize text-dark"
              style="font-weight: 600 !important; font-size: 15px !important"
              >Emirates Specialities Co. <br />
              L.L.C</small
            >
            <div>
              <small
                class="text-capitalize"
                style="
                  font-weight: 600 !important;
                  font-size: 13px !important;
                  color: #69a1bb;
                "
                ><i class="fas fa-calendar-alt"></i> Jul 7, 2020</small
              >
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-start align-items-start"
          style="
            width: 18rem;
            gap: 1rem;
            padding: 1rem;
            border-bottom: 2px solid #e3eeff;
          "
        >
          <img
            class="img-fluid"
            src="../../../../../assets/images/case-studies/img3.png"
            alt="Card image cap"
          />
          <div>
            <small
              class="text-capitalize text-dark"
              style="font-weight: 600 !important; font-size: 15px !important"
              >Specon LLC</small
            >
            <div>
              <small
                class="text-capitalize"
                style="
                  font-weight: 600 !important;
                  font-size: 13px !important;
                  color: #69a1bb;
                "
                ><i class="fas fa-calendar-alt"></i> Jun 17, 2020</small
              >
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-start align-items-start"
          style="
            width: 18rem;
            gap: 1rem;
            padding: 1rem;
            border-bottom: 2px solid #e3eeff;
          "
        >
          <img
            class="img-fluid"
            src="../../../../../assets/images/case-studies/img4.png"
            alt="Card image cap"
          />
          <div>
            <small
              class="text-capitalize text-dark"
              style="font-weight: 600 !important; font-size: 15px !important"
              >Al Ahmadiah <br />
              Contracting & Trading</small
            >
            <div>
              <small
                class="text-capitalize"
                style="
                  font-weight: 600 !important;
                  font-size: 13px !important;
                  color: #69a1bb;
                "
                ><i class="fas fa-calendar-alt"></i> Jun 13, 2020</small
              >
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </div>
  </section>
  <!-- India end -->