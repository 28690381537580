<!-- USA start -->
<div
  *ngIf="selectCountryName == 'USA'"
  class="min-vh-100 mTop divmart"
  style="min-width: 50vh !important"
>
  <div class="col p-0 m-0 ">
    <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/service/service.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h2 class="text-white text-uppercase">Services</h2>
      <!--<h4 routerLink="/" class="cursor-pointer pt-2 shade" style="color: #69a1bb">Home | Services</h4>-->
    </div>
  </div>
  <div class="container pt-5">
    <div class="row">
      <div class="col-lg-12">
        <div class="pb-5 d-flex justify-content-center align-items-center">
          <h3 class="text-center">
            OUR CLIENTS
          </h3>
        </div>
        <div class="brand-active row">

          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Archwell.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Avanze.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Berkower.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/BOAT.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Diligent.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/JioSaavn.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="max-width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/KIC.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/MSRDC.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 3rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Source Photonics.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Sproxil.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Univar.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Washburn.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 7rem"
            />
          </div>
        </div>
        <!-- brand item -->
      </div>
    </div>
    <!-- row -->
  </div>
  <div class="who-we-are-area pt-110 pb-120 dec-spacing">
    <div
      class="portfolio-3-area custom-portfolio-css pt-0 pb-0"
    >
    <div class="container">
      <div class="pb-4 d-flex justify-content-center align-items-center">
        <h3 class="text-center">
          OUR SERVICES
        </h3>
      </div>
      <div class="row " >
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 wow fadeInUp  mt-3">
        <div

          data-wow-duration="1s"
          data-wow-delay=".2s"
          class="firstCard"

        >
          <a
            class="shadow"
            routerLink="/accounting-and-transition-processing"
          >
            <div class="single-portfolio">
              <div class="portfolio-thumb">
                <img
                  src="../../../../assets/images/home-services/financial.png"
                  alt="ERP software company in dubai"
                />
              </div>
              <div
                class="portfolio-content d-flex flex-column justify-content-center align-items-center"
              >
                <span class="text-center">Accounting & Transaction Processing</span>
                <a
                  class="p-2 firstCardRead"
                  routerLink="/accounting-and-transition-processing"
                >
                  <h4 class="title">Read More</h4>
                </a>
              </div>
            </div>
          </a>
        </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12   wow fadeInUp mt-3">
        <div

          data-wow-duration="1s"
          data-wow-delay=".2s"
          class="secCard"
        >
          <a class="shadow" routerLink="/tax-regulatory-compliances">
            <div class="single-portfolio">
              <div class="portfolio-thumb">
                <img
                  src="../../../../assets/images/home-services/tax regulatory.png"
                  alt="ERP software company in dubai"
                />
              </div>
              <div
                class="portfolio-content d-flex flex-column justify-content-center align-items-center"
              >
                <span class="text-center">Tax & Regulatory Compliances</span>
                <a class="p-2" routerLink="/tax-regulatory-compliances">
                  <h4 class="title">Read More</h4>
                </a>
              </div>
            </div>
          </a>
        </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 wow fadeInUp mt-3">
        <div
          class="thirdCard"
          data-wow-duration="1s"
          data-wow-delay=".2s"

        >
          <a class="shadow" routerLink="/payroll-advisory">
            <div class="single-portfolio">
              <div class="portfolio-thumb">
                <img
                  src="../../../../assets/images/home-services/payroll.png"
                  alt="ERP software company in dubai"
                />
              </div>
              <div
                class="portfolio-content d-flex flex-column justify-content-center align-items-center"
              >
                <span class="text-center">Payroll Advisory</span>
                <a
                  style="margin-top: 55px !important"
                  class="p-2"
                  routerLink="/payroll-advisory"
                >
                  <h4 class="title">Read More</h4>
                </a>
              </div>
            </div>
          </a>
        </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12  wow fadeInUp mt-3">
        <div

          data-wow-duration="1s"
          data-wow-delay=".2s"
          class="fourthCard"
        >
          <a class="shadow" routerLink="/consulting-and-advisory-services">
            <div class="single-portfolio">
              <div class="portfolio-thumb">
                <img
                  src="../../../../assets/images/home-services/consulting.png"
                  alt="ERP software company in dubai"
                />
              </div>
              <div
                class="portfolio-content d-flex flex-column justify-content-center align-items-center"
              >
                <span class="text-center">Consulting & Advisory</span>
                <a class="p-2" routerLink="/consulting-and-advisory-services" style="margin-top: 55px !important;">
                  <h4 class="title">Read More</h4>
                </a>
              </div>
            </div>
          </a>
        </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp"></div>
      </div>

      <!--====== CLIENT Logos slider start======-->



      <!--====== CLIENT Logos slider end======-->
    </div>
    </div>

    <div class="what-we-are-shape-1">
      <img src="assets/images/what-we-are-shape-1.png" alt="shape" />
    </div>

    <!-- <div class="what-we-are-shape-2">
      <img src="assets/images/what-we-are-shape-2.png" alt="shape" />
    </div> -->
  </div>
  </div>

  <section class="testimonial-section2">
    <div class="what-we-are-shape-2">
      <img src="assets/images/contact-us-abstract-img.png" alt="shape" style="position: absolute;transform: rotate(180deg); top:0; overflow: clip;left: 0%;width: 21%; bottom: 9%; clip:rect(75px,auto,auto,auto);"/>
      <img src="assets/images/contact-us-abstract-img.png" alt="shape" style="position: absolute; overflow: clip;right: 1%;width: 20%; bottom: 8%; clip: rect(75px,auto,auto,auto);"/>
    </div>
    <div class="container">
      <div class="row text-center justify-content-center align-content-center">
        <div class="col-lg-12 ">
          <span class="text-white text-center">Testimonial</span>
          <h3 class="title text-white text-uppercase text-center testword ">
            Words From Our Clients
          </h3>
        </div>
      </div>
      <div
        id="myCarousel"
        class="carousel slide pt-4"
        data-ride="carousel"
        data-interval="5000"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                CEO of Leading Educational Institute in Southern India
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial has been our Outsourced Accounting & Regulatory
                compliance partner from the beginning. Our association is more
                than a decade long and I am still pretty much impressed by their
                adherence to service levels. They have proactively solved issues
                impacting the business and have been consistently providing
                inputs on key performance indicators. This has helped me in
                running the business more effectively. The problem solving
                ability of the team continues to amaze.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                A leading SAP solution provider
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial have been an asset to us and we consider them
                as an internal partner who has helped us on our growth path.
                Since we are working in multiple geographies, the biggest
                advantage of being associated with Boston Financial is that we
                have to talk to a single agency who does all our compliances and
                presents us with timely & accurate consolidated financial at all
                times
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                Sunanda Subramanyam - Manager (Finance), Ephicacy Lifescience
                Analytics
              </span>
              <p
                class="card-text text-center pt-3 text-white"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financials is associated with Ephicacy as its accounting
                partner for 6 years. Their team comprising experts in finance,
                legal domain, have done a splendid job in efficiently managing
                Ephicacy's compliance to statutory and regulatory requirements
                without compromising data security. Over the years, they have
                become an extended arm of Ephicacy Finance and have earned the
                tag of 'trustworthy and reliable partners. As we continue our
                association with Boston team, we strongly recommend the same to
                others too!
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                Atul Siddam - Director, Diligent Tech India Private Limited
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial have been an asset to us and we consider them
                as an internal partner who has helped us on our growth path.
                Since we are working in multiple geographies, the biggest
                advantage of being associated with Boston Financial is that we
                have to deal with a single agency who does all our compliances
                and presents us with timely & accurate consolidated financials.
                Their expertise in Corporate Structuring has helped us a lot in
                venturing overseas.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                A Communication & Data Connectivity Solution Provider
              </span>
              <p
                class="card-text text-center pt-3 text-white"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Your Outsourced Accounting & Reporting services have always been
                excellent. Delivering in a complex manufacturing set-up,
                post-merger integration support, Global reporting, everything on
                time and always meeting the expectations. Great team that never
                fails to come up with solutions.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                A Leading IT Architecture Solution Provider For Banks
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                I am continually pleased with Boston Financial, their
                professionalism, their service levels and their round the clock
                support. Would definitely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                JACKSON L. - Munsey-Chief Executive Office, Washburn Law, PLLC
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                We have utilized Boston Financial services for the past 18
                months. They have identified management and accounting issues
                that have resulted in improved processing and real time
                information. This has allowed management us to deal with issues
                immediately before they become problems. Timely, precise and
                correct information always has been the hallmark of Boston
                Financial. They have helped double our revenue and improve our
                operational cost. Great team and an asset to our firm.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                A Top Company in Wound Management
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                We have been associated with Boston Financial right from
                incorporating a legal entity in India. Though we are globally
                associated with the big fours we selected them for incorporation
                as well as on-going compliance support. They have an excellent
                team that always delivers.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                Director of a CRM Company
              </span>
              <p
                class="card-text text-center pt-3 text-white"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial has been our Outsourced F & A partner for the
                past many years. | am continually pleased with their
                professionalism, service levels and round the clock support.
                Would definitely recommend them.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                Florida Based Mid Sized CPA Firm
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial has been assisting in Accounting, Tax
                preparation for the past 3 years. Their delivery capability and
                resources are top notch. Ideal partners for back-office
                operations, would surely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                CEO Of Venture Capital And Healthcare Service PROVIDER
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                I am continually pleased with Boston Financial, their
                professionalism, their service levels and their round the clock
                support. Would definitely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                CEO of IT Service Company
              </span>
              <p
                class="card-text text-center pt-3 text-white"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Your service has always been spectacular. Whether it be
                incorporation, structuring, Compliance, always there to support.
                MIS and reporting are the hallmarks which have benefitted me a
                lot in making timely decisions. Never felt like Boston Financial
                is an Outsourced Accounting partner, we think of them as a team.
              </p>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#myCarousel"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#myCarousel"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <div class="what-we-are-shape-1">
        <img src="assets/images/what-we-are-shape-1.png" alt="shape" />
      </div>
    </div>
  </section>

  <div class="container-fluid pt-30 pb-20">
    <div class="row justify-content-center">
      <div class="col-lg-10 col-md-6">
        <div class=" text-center mb-4">
          <h3 class="title p-0 m-0" style="text-decoration-color: #072f5f;font-size: 28px;">
           FAQs For CPA Back Office
          </h3>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center " style="gap: 2rem">
      <div class="col-md-6 col-lg-3 mb-3" class="card border-0 wow fadeInUp">
      <div
        routerLink="/audit-support"
        class="card1"
        data-wow-duration="1s"
        data-wow-delay=".2s"
        style="width: 90%; cursor: pointer"
      >
        <div class="case-card">
          <img
            src="../../../../assets//images/CPA back-office/CPAsolutions4.png"
            alt="Image"
          />
          <div class="gradient"></div>
          <div class="text">
            <p>Audit Support</p>
          </div>
          <i class="fal fa-arrow-right case-icon"></i>
        </div>
      </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-3" class="card border-0 wow fadeInUp">

      <div
        routerLink="/tax-return-preparation"
        class="card1"
        data-wow-duration="1s"
        data-wow-delay=".4s"
        style="width: 90%; cursor: pointer"
      >
        <div class="case-card">
          <img
            src="../../../../assets//images/CPA back-office/tax.png"
            alt="Image"
          />
          <div class="gradient"></div>
          <div class="text">
            <p>Tax Preparation</p>
          </div>
          <i class="fal fa-arrow-right dubai-case-icon"></i>
        </div>
      </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-3" class="card border-0 wow fadeInUp">
      <div
        routerLink="/accounting"
        class="card1"
        data-wow-duration="1s"
        data-wow-delay=".6s"
        style="width: 90%; cursor: pointer"
      >
        <div class="case-card">
          <img
            src="../../../../assets//images/CPA back-office/audit.png"
            alt="Image"
          />
          <div class="gradient"></div>
          <div class="text">
            <p>Accounting</p>
          </div>
          <i class="fal fa-arrow-right dubai-case-icon"></i>
        </div>
      </div>
      </div>
    </div>
  </div>

  <!-- contact Us start-->
  <div class="contact-us-area bg_cover mt-10" >

    <div
      class="contact-overlay shadow justify-content-center dec-spacing"
      style="background: #072F5F; position: relative;">
      <img src="assets/images/contact-us-abstract-img.png" alt="shape" style="position: absolute; overflow: clip;left: 0%;width: 18%; bottom: 9%; clip:rect(75px,auto,auto,auto);"/>
      <img src="assets/images/contact-us-abstract-img.png" alt="shape" style="position: absolute; overflow: clip;right: 1%;width: 18%; bottom: 8%; clip: rect(75px,auto,auto,auto);"/>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="section-title text-center">
              <span class="text-white">contact us</span>
              <h3 class="title" style="font-size: 28px">
                Don't Hesitate to Contact Us, Say Hello!
              </h3>
            </div>
            <!-- sevtion title -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div
              class="contact-details d-flex shadow justify-content-center align-items-center contactUsImg"
              style="border-radius: 10px"
            >
            <div
            class="col-3"
            style="
              padding: 0 !important;
              margin-left: 2rem !important;
            "
          >
            <img
              class="img-fluid img-contact-us"
              src="assets/images/contactus.png"
              alt=""
              style="width: 85% !important;
              margin-left: 35px !important;"
            />
          </div>
              <div class="contact-form-area">
                <form [formGroup]="inqForm" (ngSubmit)="onSubmit()">


                  <div class="row home-text-danger" style="margin-bottom: 5%;">
                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin mt-20">
                        <input
                          type="text"
                          placeholder="Full Name *"
                          formControlName="name"
                          required
                        />
                        <i class="fal fa-user" style="color: black"></i>
                        <div
                          *ngIf="submitted && f.name.errors"
                          class="text-danger small"
                          [ngClass]="{
                            'is-invalid': submitted && f.name.errors
                          }"
                        >
                          <div *ngIf="f.name.errors?.required">
                            Name is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin mt-20">
                        <input
                          type="email"
                          placeholder="Email *"
                          formControlName="email_id"
                          required
                        />
                        <i
                          class="fal fa-envelope-open"
                          style="color: black"
                        ></i>
                        <div
                          *ngIf="submitted && f.email_id.errors"
                          class="text-danger small"
                          [ngClass]="{
                            'is-invalid': submitted && f.email_id.errors
                          }"
                        >
                          <div *ngIf="f.email_id.errors?.required">
                            Email is required
                          </div>
                          <div *ngIf="f.email_id.errors?.email">
                            Please enter valid email
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin mt-20">
                        <input
                          type="text"
                          placeholder="Phone No *"
                          pattern="\d*"
                          maxlength="15"
                          formControlName="mobile_number"
                          required
                        />
                        <i class="fal fa-phone" style="color: black"></i>
                        <div
                          *ngIf="submitted && f.mobile_number.errors"
                          class="text-danger small"
                          [ngClass]="{
                            'is-invalid': submitted && f.mobile_number.errors
                          }"
                        >
                          <div *ngIf="f.mobile_number.errors?.required">
                            Mobile number is required
                          </div>

                          <div *ngIf="f.mobile_number.errors?.pattern">
                            Invalid mobile number
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin mt-20">
                        <input
                          type="text"
                          placeholder="Subject"
                          formControlName="subject"
                        />
                        <i class="fal fa-edit" style="color: black"></i>
                      </div>
                    </div>
                    <div class="col-lg-10">
                      <div class="input-box input-box-Margin mt-20">
                        <textarea
                          name="#"
                          id="#"
                          cols="20"
                          rows="5"
                          placeholder="Message Us"
                          formControlName="message"
                          style="height: 65px"
                        ></textarea>
                        <i class="fal fa-pencil" style="color: black"></i>

                      </div>
                    </div>
                    <div class="col-lg-7 mt-15 captcha">
                      <div class="row">
                        <div class="col-lg-5">
                          <div class="input-box mt-20">
                            <input
                              type="text"
                              placeholder="Enter Captcha*"
                              formControlName="recaptchaReactive"
                            />
                            <span class="badge sync " (click)="reCaptcha()">
                              <i class="fal fa-sync"></i
                            ></span>
                          </div>
                        </div>
                        <div class="col-lg-3 mt-1 ml-2">
                          <div id="captcha"></div>
                        </div>

                        <div
                          *ngIf="submitted && f.recaptchaReactive.errors"
                          class="text-danger small"
                          [ngClass]="{
                            'is-invalid':
                              submitted && f.recaptchaReactive.errors
                          }"
                        >
                          <div
                            *ngIf="f.recaptchaReactive.errors?.required"
                            class="pl-3"
                          >
                            Captcha is required
                          </div>
                        </div>
                      </div>
                      <div class="row pt-4">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="termsCheckbox" formControlName="acceptTerms">
                                <label class="form-check-label" for="termsCheckbox">
                                    I accept the terms and conditions
                                </label>
                            </div>
                            <div *ngIf="submitted && f.acceptTerms.errors?.required" class="text-danger small">
                                You must accept the terms and conditions
                            </div>
                        </div>
                    </div>
                    </div>

                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin">
                        <button
                          class="btn border-0"
                          style="background: #0951a4; display: inline-block"
                          type="submit"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div
                  class="alert alert-success mt-3"
                  role="alert"
                  *ngIf="resultTrue"
                >
                  Thank You for reaching out . We would get back to you
                  shortly.
                </div>
                <div
                  class="alert alert-danger mt-3"
                  role="alert"
                  *ngIf="resultFalse"
                >
                  Something went wrong please try again
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- container -->
    </div>

  </div>

</div>
<!-- USA end -->

<!-- india start -->
<div
  *ngIf="selectCountryName == 'India'"
  class="min-vh-100 mTop divmart"
  style="min-width: 50vh !important"
>
  <div class="col p-0 m-0">
    <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/service/service.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >


    <div class="d-flex flex-column justify-content-center align-items-center">
      <h2 class="text-white text-uppercase">Services</h2>
      <!--<h4 routerLink="/" class="cursor-pointer pt-2 shade" style="color: #69a1bb">Home | Services</h4>-->
    </div>
  </div>
  <div class="container pt-5">
    <div class="row">
      <div class="col-lg-12">
        <div class="pb-5 d-flex justify-content-center align-items-center">
          <h3 class="text-center">
            OUR CLIENTS
          </h3>
        </div>
        <div class="brand-active row">

          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Archwell.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Avanze.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Berkower.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/BOAT.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Diligent.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/JioSaavn.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="max-width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/KIC.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/MSRDC.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 3rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Source Photonics.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Sproxil.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Univar.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Washburn.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 7rem"
            />
          </div>
        </div>
        <!-- brand item -->
      </div>
    </div>
    <!-- row -->
  </div>

  <img
  src="assets/images/what-we-are-shape-1.png"
  alt="shape"
  style="position: absolute; left: -12%; width: 30%; z-index: -1;"
/>

 <div class="pt-110 pb-120">
  <div class="container-fluid">
    <div class="pb-4 d-flex justify-content-center align-items-center">
      <h3 class="text-center">OUR SERVICES</h3>
    </div>
    <div class="row justify-content-center ">

      <!-- Card 1 -->

      <div class="col-lg-2 col-md-4 col-sm-6 col-12 mb-4 d-flex justify-content-center wow fadeInUp widthcentre mb-5 carddiv" data-wow-duration="1s" data-wow-delay=".2s">
        <a class="shadow" class="read-more-link" routerLink="/accounts-payable">
        <div class="custom-card text-center">
          <img src="../../../../assets/images/home-services/financial.png" alt="Accounting & Transaction Processing" class="img-fluid">
          <div class="mt-3 d-flex flex-column justify-content-between align-items-center inddiv">
            <h4 class="service-title">Accounting & Transaction Processing</h4>
            <a class="read-more-link" routerLink="/accounts-payable">
              <button class="read-more-btn">Read more</button>
            </a>
            <div class="blue-line"></div>
          </div>
        </div>
      </a>
      </div>

      <!-- Card 2 -->
      <div class="col-lg-2 col-md-4 col-sm-6 col-12 mb-4 d-flex justify-content-center wow fadeInUp widthcentre mb-5 carddiv" data-wow-duration="1s" data-wow-delay=".6s">
        <a class="read-more-link" routerLink="/income-tax">
        <div class="custom-card text-center">
          <img src="../../../../assets/images/home-services/tax regulatory.png" alt="Tax & Regulatory Compliances" class="img-fluid">
          <div class="mt-3 d-flex flex-column justify-content-between align-items-center inddiv">
            <h4 class="service-title">Tax & Regulatory Compliances</h4>
            <a class="read-more-link" routerLink="/income-tax">
              <button class="read-more-btn">Read more</button>
            </a>
            <div class="blue-line"></div>
          </div>
        </div>
      </a>
      </div>

      <!-- Card 3 -->
      <div class="col-lg-2 col-md-4 col-sm-6 col-12 mb-4 d-flex justify-content-center wow fadeInUp widthcentre mb-5 carddiv" data-wow-duration="1s" data-wow-delay=".4s">
        <a class="read-more-link" routerLink="/payroll-processing">
        <div class="custom-card text-center">
          <img src="../../../../assets/images/home-services/payroll.png" alt="Payroll Advisory" class="img-fluid">
          <div class="mt-3 d-flex flex-column justify-content-between align-items-center inddiv">
            <h4 class="service-title">Payroll Advisory</h4>
            <a class="read-more-link" routerLink="/payroll-processing">
              <button class="read-more-btn">Read More</button>
            </a>
            <div class="blue-line"></div>
          </div>
        </div>
      </a>
      </div>

      <!-- Card 4 -->
      <div class="col-lg-2 col-md-4 col-sm-6 col-12 mb-4 d-flex justify-content-center wow fadeInUp widthcentre mb-5 carddiv" data-wow-duration="1s" data-wow-delay=".8s">
        <a class="read-more-link" routerLink="/business-setup">
        <div class="custom-card text-center">
          <img src="../../../../assets/images/home-services/consulting.png" alt="Consulting & Advisory" class="img-fluid">
          <div class="mt-3 d-flex flex-column justify-content-between align-items-center inddiv">
            <h4 class="service-title">Consulting & Advisory</h4>
            <a class="read-more-link" routerLink="/business-setup">
              <button class="read-more-btn">Read more</button>
            </a>
            <div class="blue-line"></div>
          </div>
        </div>
      </a>
      </div>

      <!-- Card 5 -->
      <div class="col-lg-2 col-md-4 col-sm-6 col-12 mb-4 d-flex justify-content-center wow fadeInUp widthcentre mb-5 carddiv" data-wow-duration="1s" data-wow-delay=".8s">
        <a class="read-more-link" routerLink="/startup-solution">
        <div class="custom-card text-center">
          <img src="../../../../assets/images/home-services/Startup.png" alt="Startup Solution" class="img-fluid">
          <div class="mt-3 d-flex flex-column justify-content-between align-items-center inddiv">
            <h4 class="service-title">Startup Solution</h4>
            <a class="read-more-link" routerLink="/startup-solution">
              <button class="read-more-btn">Read more</button>
            </a>
            <div class="blue-line"></div>
          </div>
        </div>
      </a>
      </div>
    </div>
  </div>
</div>
  </div>

  <section class="testimonial-section2" >
    <div class="what-we-are-shape-2" >
      <img src="assets/images/contact-us-abstract-img.png" alt="shape" style="position: absolute;transform: rotate(180deg); top:0; overflow: clip;left: 0%;width: 21%; bottom: 9%; clip:rect(75px,auto,auto,auto);"/>
      <img src="assets/images/contact-us-abstract-img.png" alt="shape" style="position: absolute; overflow: clip;right: 1%;width: 20%; bottom: 8%; clip: rect(75px,auto,auto,auto);"/>
    </div>
    <div class="container">
      <div class="row text-center justify-content-center align-content-center">
        <div class="col-lg-12 ">
          <span class="text-white text-center">Testimonial</span>
          <h3 class="title text-white text-uppercase text-center testword ">
            Words From Our Clients
          </h3>
        </div>
      </div>
      <div
        id="myCarousel"
        class="carousel slide pt-4"
        data-ride="carousel"
        data-interval="5000"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                CEO of Leading Educational Institute in Southern India
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial has been our Outsourced Accounting & Regulatory
                compliance partner from the beginning. Our association is more
                than a decade long and I am still pretty much impressed by their
                adherence to service levels. They have proactively solved issues
                impacting the business and have been consistently providing
                inputs on key performance indicators. This has helped me in
                running the business more effectively. The problem solving
                ability of the team continues to amaze.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                A leading SAP solution provider
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial have been an asset to us and we consider them
                as an internal partner who has helped us on our growth path.
                Since we are working in multiple geographies, the biggest
                advantage of being associated with Boston Financial is that we
                have to talk to a single agency who does all our compliances and
                presents us with timely & accurate consolidated financial at all
                times
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                Sunanda Subramanyam - Manager (Finance), Ephicacy Lifescience
                Analytics
              </span>
              <p
                class="card-text text-center pt-3 text-white"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financials is associated with Ephicacy as its accounting
                partner for 6 years. Their team comprising experts in finance,
                legal domain, have done a splendid job in efficiently managing
                Ephicacy's compliance to statutory and regulatory requirements
                without compromising data security. Over the years, they have
                become an extended arm of Ephicacy Finance and have earned the
                tag of 'trustworthy and reliable partners. As we continue our
                association with Boston team, we strongly recommend the same to
                others too!
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                Atul Siddam - Director, Diligent Tech India Private Limited
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial have been an asset to us and we consider them
                as an internal partner who has helped us on our growth path.
                Since we are working in multiple geographies, the biggest
                advantage of being associated with Boston Financial is that we
                have to deal with a single agency who does all our compliances
                and presents us with timely & accurate consolidated financials.
                Their expertise in Corporate Structuring has helped us a lot in
                venturing overseas.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                A Communication & Data Connectivity Solution Provider
              </span>
              <p
                class="card-text text-center pt-3 text-white"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Your Outsourced Accounting & Reporting services have always been
                excellent. Delivering in a complex manufacturing set-up,
                post-merger integration support, Global reporting, everything on
                time and always meeting the expectations. Great team that never
                fails to come up with solutions.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                A Leading IT Architecture Solution Provider For Banks
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                I am continually pleased with Boston Financial, their
                professionalism, their service levels and their round the clock
                support. Would definitely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                JACKSON L. - Munsey-Chief Executive Office, Washburn Law, PLLC
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                We have utilized Boston Financial services for the past 18
                months. They have identified management and accounting issues
                that have resulted in improved processing and real time
                information. This has allowed management us to deal with issues
                immediately before they become problems. Timely, precise and
                correct information always has been the hallmark of Boston
                Financial. They have helped double our revenue and improve our
                operational cost. Great team and an asset to our firm.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                A Top Company in Wound Management
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                We have been associated with Boston Financial right from
                incorporating a legal entity in India. Though we are globally
                associated with the big fours we selected them for incorporation
                as well as on-going compliance support. They have an excellent
                team that always delivers.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                Director of a CRM Company
              </span>
              <p
                class="card-text text-center pt-3 text-white"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial has been our Outsourced F & A partner for the
                past many years. | am continually pleased with their
                professionalism, service levels and round the clock support.
                Would definitely recommend them.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                Florida Based Mid Sized CPA Firm
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial has been assisting in Accounting, Tax
                preparation for the past 3 years. Their delivery capability and
                resources are top notch. Ideal partners for back-office
                operations, would surely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                CEO Of Venture Capital And Healthcare Service PROVIDER
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                I am continually pleased with Boston Financial, their
                professionalism, their service levels and their round the clock
                support. Would definitely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                CEO of IT Service Company
              </span>
              <p
                class="card-text text-center pt-3 text-white"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Your service has always been spectacular. Whether it be
                incorporation, structuring, Compliance, always there to support.
                MIS and reporting are the hallmarks which have benefitted me a
                lot in making timely decisions. Never felt like Boston Financial
                is an Outsourced Accounting partner, we think of them as a team.
              </p>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#myCarousel"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#myCarousel"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <div class="what-we-are-shape-1">
        <img src="assets/images/what-we-are-shape-1.png" alt="shape" />
      </div>
    </div>
  </section>

  <div class="container-fluid pt-30 pb-20">
    <div class="row justify-content-center">
      <div class="col-lg-10 col-md-6">
        <div class=" text-center mb-4">
          <h3 class="title p-0 m-0" style="text-decoration-color: #072f5f;font-size: 28px;">
           FAQs For CPA Back Office
          </h3>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center " style="gap: 2rem">
      <div class="col-md-6 col-lg-3 mb-3" class="card border-0 wow fadeInUp">
      <div
        routerLink="/audit-support"
        class="card1"
        data-wow-duration="1s"
        data-wow-delay=".2s"
        style="width: 90%; cursor: pointer"
      >
        <div class="case-card">
          <img
            src="../../../../assets//images/CPA back-office/CPAsolutions4.png"
            alt="Image"
          />
          <div class="gradient"></div>
          <div class="text">
            <p>Audit Support</p>
          </div>
          <i class="fal fa-arrow-right case-icon"></i>
        </div>
      </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-3" class="card border-0 wow fadeInUp">

      <div
        routerLink="/tax-return-preparation"
        class="card1"
        data-wow-duration="1s"
        data-wow-delay=".4s"
        style="width: 90%; cursor: pointer"
      >
        <div class="case-card">
          <img
            src="../../../../assets//images/CPA back-office/tax.png"
            alt="Image"
          />
          <div class="gradient"></div>
          <div class="text">
            <p>Tax Preparation</p>
          </div>
          <i class="fal fa-arrow-right dubai-case-icon"></i>
        </div>
      </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-3" class="card border-0 wow fadeInUp">
      <div
        routerLink="/accounting"
        class="card1"
        data-wow-duration="1s"
        data-wow-delay=".6s"
        style="width: 90%; cursor: pointer"
      >
        <div class="case-card">
          <img
            src="../../../../assets//images/CPA back-office/audit.png"
            alt="Image"
          />
          <div class="gradient"></div>
          <div class="text">
            <p>Accounting</p>
          </div>
          <i class="fal fa-arrow-right dubai-case-icon"></i>
        </div>
      </div>
      </div>
    </div>
  </div>

  <!-- contact Us start-->
  <div class="contact-us-area bg_cover mt-10" >

    <div
      class="contact-overlay shadow justify-content-center dec-spacing"
      style="background: #072F5F; position: relative;">
      <img src="assets/images/contact-us-abstract-img.png" alt="shape" style="position: absolute; overflow: clip;left: 0%;width: 18%; bottom: 9%; clip:rect(75px,auto,auto,auto);"/>
      <img src="assets/images/contact-us-abstract-img.png" alt="shape" style="position: absolute; overflow: clip;right: 1%;width: 18%; bottom: 8%; clip: rect(75px,auto,auto,auto);"/>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="section-title text-center">
              <span class="text-white">contact us</span>
              <h3 class="title" style="font-size: 28px">
                Don't Hesitate to Contact Us, Say Hello!
              </h3>
            </div>
            <!-- sevtion title -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div
              class="contact-details d-flex shadow justify-content-center align-items-center contactUsImg"
              style="border-radius: 10px"
            >
            <div
            class="col-3"
            style="
              padding: 0 !important;
              margin-left: 2rem !important;
            "
          >
            <img
              class="img-fluid img-contact-us"
              src="assets/images/contactus.png"
              alt=""
              style="width: 85% !important;
              margin-left: 35px !important;"
            />
          </div>
              <div class="contact-form-area">
                <form [formGroup]="inqForm" (ngSubmit)="onSubmit()">


                  <div class="row home-text-danger" style="margin-bottom: 5%;">
                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin mt-20">
                        <input
                          type="text"
                          placeholder="Full Name *"
                          formControlName="name"
                          required
                        />
                        <i class="fal fa-user" style="color: black"></i>
                        <div
                          *ngIf="submitted && f.name.errors"
                          class="text-danger small"
                          [ngClass]="{
                            'is-invalid': submitted && f.name.errors
                          }"
                        >
                          <div *ngIf="f.name.errors?.required">
                            Name is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin mt-20">
                        <input
                          type="email"
                          placeholder="Email *"
                          formControlName="email_id"
                          required
                        />
                        <i
                          class="fal fa-envelope-open"
                          style="color: black"
                        ></i>
                        <div
                          *ngIf="submitted && f.email_id.errors"
                          class="text-danger small"
                          [ngClass]="{
                            'is-invalid': submitted && f.email_id.errors
                          }"
                        >
                          <div *ngIf="f.email_id.errors?.required">
                            Email is required
                          </div>
                          <div *ngIf="f.email_id.errors?.email">
                            Please enter valid email
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin mt-20">
                        <input
                          type="text"
                          placeholder="Phone No *"
                          pattern="\d*"
                          maxlength="15"
                          formControlName="mobile_number"
                          required
                        />
                        <i class="fal fa-phone" style="color: black"></i>
                        <div
                          *ngIf="submitted && f.mobile_number.errors"
                          class="text-danger small"
                          [ngClass]="{
                            'is-invalid': submitted && f.mobile_number.errors
                          }"
                        >
                          <div *ngIf="f.mobile_number.errors?.required">
                            Mobile number is required
                          </div>

                          <div *ngIf="f.mobile_number.errors?.pattern">
                            Invalid mobile number
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin mt-20">
                        <input
                          type="text"
                          placeholder="Subject"
                          formControlName="subject"
                        />
                        <i class="fal fa-edit" style="color: black"></i>
                      </div>
                    </div>
                    <div class="col-lg-10">
                      <div class="input-box input-box-Margin mt-20">
                        <textarea
                          name="#"
                          id="#"
                          cols="20"
                          rows="5"
                          placeholder="Message Us"
                          formControlName="message"
                          style="height: 65px"
                        ></textarea>
                        <i class="fal fa-pencil" style="color: black"></i>

                      </div>
                    </div>
                    <div class="col-lg-7 mt-15 captcha">
                      <div class="row">
                        <div class="col-lg-5">
                          <div class="input-box  mt-20">
                            <input
                              type="text"
                              placeholder="Enter Captcha*"
                              formControlName="recaptchaReactive"
                            />
                            <span class="badge sync" (click)="reCaptcha()">
                              <i class="fal fa-sync"></i
                            ></span>
                          </div>
                        </div>
                        <div class="col-lg-3 mt-1">
                          <div id="captcha"></div>
                        </div>

                        <div
                          *ngIf="submitted && f.recaptchaReactive.errors"
                          class="text-danger small"
                          [ngClass]="{
                            'is-invalid':
                              submitted && f.recaptchaReactive.errors
                          }"
                        >
                          <div
                            *ngIf="f.recaptchaReactive.errors?.required"
                            class="pl-3"
                          >
                            Captcha is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin">
                        <button
                          class="btn border-0"
                          style="background: #0951a4; display: inline-block"
                          type="submit"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div
                  class="alert alert-success mt-3"
                  role="alert"
                  *ngIf="resultTrue"
                >
                  Thank You for reaching out . We would get back to you
                  shortly.
                </div>
                <div
                  class="alert alert-danger mt-3"
                  role="alert"
                  *ngIf="resultFalse"
                >
                  Something went wrong please try again
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- container -->
    </div>

  </div>

</div>

<!-- india end -->


<!-- Dubai start -->
<div
  *ngIf="selectCountryName == 'Dubai'"
  class="min-vh-100 mTop divmart"
  style="min-width: 50vh !important"
>
  <div class="col p-0 m-0">
    <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/service/service.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >

  <img
  src="assets/images/what-we-are-shape-1.png"
  alt="shape"
  style="position: absolute; left: -12%; width: 30%; z-index: -1"
/>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h2 class="text-white text-uppercase">Services</h2>
      <!--<h4 routerLink="/" class="cursor-pointer pt-2 shade" style="color: #69a1bb">Home | Services</h4>-->
    </div>
  </div>
  <div class="container pt-5">
    <div class="row">
      <div class="col-lg-12">
        <div class="pb-5 d-flex justify-content-center align-items-center">
          <h3 class="text-center">
            OUR CLIENTS
          </h3>
        </div>
        <div class="brand-active row">

          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Archwell.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Avanze.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Berkower.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/BOAT.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Diligent.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/JioSaavn.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="max-width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/KIC.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/MSRDC.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 3rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Source Photonics.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 8rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Sproxil.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Univar.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 10rem"
            />
          </div>
          <div
            class="item col-md-2 col-sm-4 d-flex justify-content-center align-content-center"
          >
            <img
              src="../../../../assets/images/Client logos/Washburn.png"
              alt="brand name"
              class="img-fluid clientImg"
              style="width: 7rem"
            />
          </div>
        </div>
        <!-- brand item -->
      </div>
    </div>
    <!-- row -->
  </div>


  <div class="who-we-are-area pt-110 pb-120 dec-spacing">
    <div
      class="portfolio-3-area custom-portfolio-css pt-0 pb-0"
    >
      <div class="container">
        <div class="pb-4 d-flex justify-content-center align-items-center">
          <h3 class="text-center">
            OUR SERVICES
          </h3>
        </div>
        <div class="row " >
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 wow fadeInUp mt-3 ">
          <div
            class="firstCard"
            data-wow-duration="1s"
            data-wow-delay=".2s"

          >
            <a
              class="shadow"
              routerLink="/accounting-and-transition-processing"
            >
              <div class="single-portfolio">
                <div class="portfolio-thumb">
                  <img
                    src="../../../../assets/images/home-services/financial.png"
                    alt="ERP software company in dubai"
                  />
                </div>
                <div
                  class="portfolio-content d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="text-center">Accounting & Transaction Processing</span>
                  <a
                    class="p-2 firstCardRead"
                    routerLink="/accounting-and-transition-processing"
                    style="background: #418051;"
                  >
                    <h4 class="title">Read More</h4>
                  </a>
                </div>
              </div>
            </a>
          </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 wow fadeInUp mt-3">
          <div
            class="secCard"
            data-wow-duration="1s"
            data-wow-delay=".2s"

          >
            <a class="shadow" routerLink="/tax-regulatory-compliances">
              <div class="single-portfolio">
                <div class="portfolio-thumb">
                  <img
                    src="../../../../assets/images/home-services/tax regulatory.png"
                    alt="ERP software company in dubai"
                  />
                </div>
                <div
                  class="portfolio-content d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="text-center">Tax & Regulatory Compliances</span>
                  <a class="p-2 " routerLink="/tax-regulatory-compliances" style="background: #418051;">
                    <h4 class="title">Read More</h4>
                  </a>
                </div>
              </div>
            </a>
          </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 wow fadeInUp  mt-3">
          <div
            class="thirdCard"
            data-wow-duration="1s"
            data-wow-delay=".2s"

          >
            <a class="shadow" routerLink="/audit-and-assurance-support">
              <div class="single-portfolio">
                <div class="portfolio-thumb">
                  <img
                    src="../../../../assets/images/home-services/payroll.png"
                    alt="ERP software company in dubai"
                  />
                </div>
                <div
                  class="portfolio-content d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="text-center">Audit & Assurance Support</span>
                  <a
                    style="background: #418051;"
                    class="p-2 dubaiAuditAndAssuranceBtn"
                    routerLink="/audit-and-assurance-support"
                  >
                    <h4 class="title">Read More</h4>
                  </a>
                </div>
              </div>
            </a>
          </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 wow fadeInUp mt-3">
          <div

            data-wow-duration="1s"
            data-wow-delay=".2s"
            class="fourthCard"
          >
            <a class="shadow" routerLink="/consulting-and-advisory-services">
              <div class="single-portfolio">
                <div class="portfolio-thumb">
                  <img
                    src="../../../../assets/images/home-services/consulting.png"
                    alt="ERP software company in dubai"
                  />
                </div>
                <div
                  class="portfolio-content d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="text-center">Consulting & Advisory</span>
                  <a class="p-2 fourthCardDubai" routerLink="/consulting-and-advisory-services" style="margin-top: 55px ; background: #418051;">
                    <h4 class="title">Read More</h4>
                  </a>
                </div>
              </div>
            </a>
          </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp"></div>
        </div>

        <!--====== CLIENT Logos slider start======-->



        <!--====== CLIENT Logos slider end======-->
      </div>

    </div>

    <div class="what-we-are-shape-1">
      <img src="assets/images/what-we-are-shape-1.png" alt="shape" />
    </div>

    <!-- <div class="what-we-are-shape-2">
      <img src="assets/images/what-we-are-shape-2.png" alt="shape" />
    </div> -->
  </div>
  </div>

  <section class="testimonial-section2" style="background: #418051;">
    <div class="what-we-are-shape-2">
      <!-- <img src="assets/images/what-we-are-shape-2.png" alt="shape" style="position: absolute; transform: rotate(180deg); top:0; overflow: clip;left: -1%;width: 20%;"/> -->
      <!-- <img src="assets/images/what-we-are-shape-2.png" alt="shape" style="position: absolute; overflow: clip;right: -2%;width: 20%;bottom: -10%;"/> -->
      <img src="assets/images/contact-us-abstract-img.png" alt="shape" style="position: absolute;transform: rotate(180deg); top:0; overflow: clip;left: 0%;width: 21%; bottom: 9%; clip:rect(75px,auto,auto,auto);"/>
      <img src="assets/images/contact-us-abstract-img.png" alt="shape" style="position: absolute; overflow: clip;right: 1%;width: 20%; bottom: 8%; clip: rect(75px,auto,auto,auto);"/>
    </div>
    <div class="container">
      <div class="row text-center justify-content-center align-content-center">
        <div class="col-lg-12 ">
          <span class="text-white text-center">Testimonial</span>
          <h3 class="title text-white text-uppercase text-center testword ">
            Words From Our Clients
          </h3>
        </div>
      </div>
      <div
        id="myCarousel"
        class="carousel slide pt-4"
        data-ride="carousel"
        data-interval="5000"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                CEO of Leading Educational Institute in Southern India
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial has been our Outsourced Accounting & Regulatory
                compliance partner from the beginning. Our association is more
                than a decade long and I am still pretty much impressed by their
                adherence to service levels. They have proactively solved issues
                impacting the business and have been consistently providing
                inputs on key performance indicators. This has helped me in
                running the business more effectively. The problem solving
                ability of the team continues to amaze.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                A leading SAP solution provider
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial have been an asset to us and we consider them
                as an internal partner who has helped us on our growth path.
                Since we are working in multiple geographies, the biggest
                advantage of being associated with Boston Financial is that we
                have to talk to a single agency who does all our compliances and
                presents us with timely & accurate consolidated financial at all
                times
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                Sunanda Subramanyam - Manager (Finance), Ephicacy Lifescience
                Analytics
              </span>
              <p
                class="card-text text-center pt-3 text-white"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financials is associated with Ephicacy as its accounting
                partner for 6 years. Their team comprising experts in finance,
                legal domain, have done a splendid job in efficiently managing
                Ephicacy's compliance to statutory and regulatory requirements
                without compromising data security. Over the years, they have
                become an extended arm of Ephicacy Finance and have earned the
                tag of 'trustworthy and reliable partners. As we continue our
                association with Boston team, we strongly recommend the same to
                others too!
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                Atul Siddam - Director, Diligent Tech India Private Limited
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial have been an asset to us and we consider them
                as an internal partner who has helped us on our growth path.
                Since we are working in multiple geographies, the biggest
                advantage of being associated with Boston Financial is that we
                have to deal with a single agency who does all our compliances
                and presents us with timely & accurate consolidated financials.
                Their expertise in Corporate Structuring has helped us a lot in
                venturing overseas.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                A Communication & Data Connectivity Solution Provider
              </span>
              <p
                class="card-text text-center pt-3 text-white"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Your Outsourced Accounting & Reporting services have always been
                excellent. Delivering in a complex manufacturing set-up,
                post-merger integration support, Global reporting, everything on
                time and always meeting the expectations. Great team that never
                fails to come up with solutions.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                A Leading IT Architecture Solution Provider For Banks
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                I am continually pleased with Boston Financial, their
                professionalism, their service levels and their round the clock
                support. Would definitely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                JACKSON L. - Munsey-Chief Executive Office, Washburn Law, PLLC
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                We have utilized Boston Financial services for the past 18
                months. They have identified management and accounting issues
                that have resulted in improved processing and real time
                information. This has allowed management us to deal with issues
                immediately before they become problems. Timely, precise and
                correct information always has been the hallmark of Boston
                Financial. They have helped double our revenue and improve our
                operational cost. Great team and an asset to our firm.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                A Top Company in Wound Management
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                We have been associated with Boston Financial right from
                incorporating a legal entity in India. Though we are globally
                associated with the big fours we selected them for incorporation
                as well as on-going compliance support. They have an excellent
                team that always delivers.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                Director of a CRM Company
              </span>
              <p
                class="card-text text-center pt-3 text-white"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial has been our Outsourced F & A partner for the
                past many years. | am continually pleased with their
                professionalism, service levels and round the clock support.
                Would definitely recommend them.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                Florida Based Mid Sized CPA Firm
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Boston Financial has been assisting in Accounting, Tax
                preparation for the past 3 years. Their delivery capability and
                resources are top notch. Ideal partners for back-office
                operations, would surely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                CEO Of Venture Capital And Healthcare Service PROVIDER
              </span>
              <p
                class="card-text text-center pt-3"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                I am continually pleased with Boston Financial, their
                professionalism, their service levels and their round the clock
                support. Would definitely recommend.
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <div
              class="testimonial-container container d-flex flex-column justify-content-center align-items-center"
              style="width: 60rem"
            >
              <span
                class="text-center"
                style="font-size: 16px; color: white !important"
              >
                CEO of IT Service Company
              </span>
              <p
                class="card-text text-center pt-3 text-white"
                style="
                  line-height: normal;
                  color: white !important;
                  font-size: 16px;
                "
              >
                Your service has always been spectacular. Whether it be
                incorporation, structuring, Compliance, always there to support.
                MIS and reporting are the hallmarks which have benefitted me a
                lot in making timely decisions. Never felt like Boston Financial
                is an Outsourced Accounting partner, we think of them as a team.
              </p>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#myCarousel"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#myCarousel"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <div class="what-we-are-shape-1">
        <img src="assets/images/what-we-are-shape-1.png" alt="shape" />
      </div>
    </div>
  </section>

  <div class="container-fluid pt-30 pb-20">
    <div class="row justify-content-center">
      <div class="col-lg-10 col-md-6">
        <div class=" text-center mb-4">
          <h3 class="title p-0 m-0" style="text-decoration-color: #072f5f;font-size: 28px;">
           FAQs For CPA Back Office
          </h3>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center " style="gap: 2rem">
      <div class="col-md-6 col-lg-3 mb-3" class="card border-0 wow fadeInUp">
      <div
        routerLink="/audit-support"
        class="card1"
        data-wow-duration="1s"
        data-wow-delay=".2s"
        style="width: 90%; cursor: pointer"
      >
        <div class="case-card">
          <img
            src="../../../../assets//images/CPA back-office/CPAsolutions4.png"
            alt="Image"
          />
          <div class="gradient"></div>
          <div class="text">
            <p>Audit Support</p>
          </div>
          <i class="fal fa-arrow-right case-icon"></i>
        </div>
      </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-3" class="card border-0 wow fadeInUp">

      <div
        routerLink="/tax-return-preparation"
        class="card1"
        data-wow-duration="1s"
        data-wow-delay=".4s"
        style="width: 90%; cursor: pointer"
      >
        <div class="case-card">
          <img
            src="../../../../assets//images/CPA back-office/tax.png"
            alt="Image"
          />
          <div class="gradient"></div>
          <div class="text">
            <p>Tax Preparation</p>
          </div>
          <i class="fal fa-arrow-right dubai-case-icon"></i>
        </div>
      </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-3" class="card border-0 wow fadeInUp">
      <div
        routerLink="/accounting"
        class="card1"
        data-wow-duration="1s"
        data-wow-delay=".6s"
        style="width: 90%; cursor: pointer"
      >
        <div class="case-card">
          <img
            src="../../../../assets//images/CPA back-office/audit.png"
            alt="Image"
          />
          <div class="gradient"></div>
          <div class="text">
            <p>Accounting</p>
          </div>
          <i class="fal fa-arrow-right dubai-case-icon"></i>
        </div>
      </div>
      </div>
    </div>
  </div>

  <!-- contact Us start-->
  <div class="contact-us-area bg_cover mt-10" >

    <div
      class="contact-overlay shadow justify-content-center dec-spacing"
      style="background: #418052; position: relative;">
      <img src="assets/images/contact-us-abstract-img.png" alt="shape" style="position: absolute; overflow: clip;left: 0%;width: 18%; bottom: 9%; clip:rect(75px,auto,auto,auto);"/>
      <img src="assets/images/contact-us-abstract-img.png" alt="shape" style="position: absolute; overflow: clip;right: 1%;width: 18%; bottom: 8%; clip: rect(75px,auto,auto,auto);"/>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="section-title text-center">
              <span class="text-white">contact us</span>
              <h3 class="title" style="font-size: 28px">
                Don't Hesitate to Contact Us, Say Hello!
              </h3>
            </div>
            <!-- sevtion title -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div
              class="contact-details d-flex shadow justify-content-center align-items-center contactUsImg"
              style="border-radius: 10px"
            >
            <div
            class="col-3"
            style="
              padding: 0 !important;
              margin-left: 2rem !important;
            "
          >
            <img
              class="img-fluid img-contact-us"
              src="assets/images/contactus.png"
              alt=""
              style="width: 85% !important;
              margin-left: 35px !important;"
            />
          </div>
              <div class="contact-form-area">
                <form [formGroup]="inqForm" (ngSubmit)="onSubmit()">


                  <div class="row home-text-danger" style="margin-bottom: 5%;">
                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin mt-20">
                        <input
                          type="text"
                          placeholder="Full Name *"
                          formControlName="name"
                          required
                        />
                        <i class="fal fa-user" style="color: black"></i>
                        <div
                          *ngIf="submitted && f.name.errors"
                          class="text-danger small"
                          [ngClass]="{
                            'is-invalid': submitted && f.name.errors
                          }"
                        >
                          <div *ngIf="f.name.errors?.required">
                            Name is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin mt-20">
                        <input
                          type="email"
                          placeholder="Email *"
                          formControlName="email_id"
                          required
                        />
                        <i
                          class="fal fa-envelope-open"
                          style="color: black"
                        ></i>
                        <div
                          *ngIf="submitted && f.email_id.errors"
                          class="text-danger small"
                          [ngClass]="{
                            'is-invalid': submitted && f.email_id.errors
                          }"
                        >
                          <div *ngIf="f.email_id.errors?.required">
                            Email is required
                          </div>
                          <div *ngIf="f.email_id.errors?.email">
                            Please enter valid email
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin mt-20">
                        <input
                          type="text"
                          placeholder="Phone No *"
                          pattern="\d*"
                          maxlength="15"
                          formControlName="mobile_number"
                          required
                        />
                        <i class="fal fa-phone" style="color: black"></i>
                        <div
                          *ngIf="submitted && f.mobile_number.errors"
                          class="text-danger small"
                          [ngClass]="{
                            'is-invalid': submitted && f.mobile_number.errors
                          }"
                        >
                          <div *ngIf="f.mobile_number.errors?.required">
                            Mobile number is required
                          </div>

                          <div *ngIf="f.mobile_number.errors?.pattern">
                            Invalid mobile number
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin mt-20">
                        <input
                          type="text"
                          placeholder="Subject"
                          formControlName="subject"
                        />
                        <i class="fal fa-edit" style="color: black"></i>
                      </div>
                    </div>
                    <div class="col-lg-10">
                      <div class="input-box input-box-Margin mt-20">
                        <textarea
                          name="#"
                          id="#"
                          cols="20"
                          rows="5"
                          placeholder="Message Us"
                          formControlName="message"
                          style="height: 65px"
                        ></textarea>
                        <i class="fal fa-pencil" style="color: black"></i>

                      </div>
                    </div>
                    <div class="col-lg-7 mt-15 captcha">
                      <div class="row">
                        <div class="col-lg-5">
                          <div class="input-box mt-20">
                            <input
                              type="text"
                              placeholder="Enter Captcha*"
                              formControlName="recaptchaReactive"
                            />
                            <span class="badge sync" (click)="reCaptcha()">
                              <i class="fal fa-sync"></i
                            ></span>
                          </div>
                        </div>
                        <div class="col-lg-3 mt-1">
                          <div id="captcha"></div>
                        </div>

                        <div
                          *ngIf="submitted && f.recaptchaReactive.errors"
                          class="text-danger small"
                          [ngClass]="{
                            'is-invalid':
                              submitted && f.recaptchaReactive.errors
                          }"
                        >
                          <div
                            *ngIf="f.recaptchaReactive.errors?.required"
                            class="pl-3"
                          >
                            Captcha is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-5">
                      <div class="input-box input-box-Margin">
                        <button
                          class="btn border-0"
                          style="background: #418052; display: inline-block"
                          type="submit"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div
                  class="alert alert-success mt-3"
                  role="alert"
                  *ngIf="resultTrue"
                >
                  Thank You for reaching out . We would get back to you
                  shortly.
                </div>
                <div
                  class="alert alert-danger mt-3"
                  role="alert"
                  *ngIf="resultFalse"
                >
                  Something went wrong please try again
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- container -->
    </div>

  </div>

</div>
<!-- dubai end -->





