import { Component, OnInit } from '@angular/core';

import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';

@Component({
  selector: 'app-corporate-tax-return',
  templateUrl: './corporate-tax-return.component.html',
  styleUrls: ['./corporate-tax-return.component.css']
})
export class CorporateTaxReturnComponent implements OnInit {
  defaultCountry: string;
  selectCountryName: string;
  selectedFlag: string;

  constructor(private LocalStorageServiceService: LocalStorageServiceService,) { }

  ngOnInit(): void {
    this.defaultCountry = 'USA';

    this.selectCountryName = localStorage.getItem('selectedCountry');

    if (this.selectCountryName == null) {
      localStorage.setItem('selectedCountry', this.defaultCountry);
      window.location.reload();
    } else {
      this.selectCountryName = localStorage.getItem('selectedCountry');
    }
  }

  onAccounting(){
    window.location.href="#accounting-and-transition-processing";
  }

  onTax(){
    window.location.href="#tax-regulatory-compliances";
    //this.route.navigate(["tax-regulatory-compliances"]);
  }

  onPayroll(){
    window.location.href="#payroll-advisory";
  }
 
  onConsulting(){
    window.location.href="#consulting-and-advisory-services";
  }

  onStartup(){
    window.location.href="#startup-solution-services";
  }

  onAudit(){
    window.location.href="#audit-and-assurance-support";
  }


}
