<!-- Dubai Starts -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/CPA back-office/CPAsolutions1.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">CPA BACK OFFICE</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
    </div>
  </div>

  <div
    class="portfolio-3-area custom-portfolio-css pt-115 pb-120 dec-spacing d-flex flex-column justify-content-center align-items-center"
    style="overflow: hidden; position: relative"
  >
    <img
      src="../../../../../assets/images/teams/abstract-images/P-abstract-left.png"
      alt="shape"
      style="
        position: absolute;
        overflow: clip;
        left: 0%;
        bottom: 0%;
        width: 22%;
      "
    />
    <img
      src="../../../../../assets/images/teams/abstract-images/P-abstract-right.png"
      alt="shape"
      style="
        position: absolute;
        overflow: clip;
        right: 0%;
        bottom: 0%;
        width: 22%;
      "
    />
    <div
      class="container d-flex flex-column justify-content-center align-items-center"
      style="gap: 3rem"
    >
      <div class="row">
        <div class="col-md-4 col-sm-6 col-12 cbodiv wow fadeInUp mt-3">
          <div data-wow-duration="1s" data-wow-delay=".7s" class="firstCard">
            <a class="shadow" routerLink="/audit-support">
              <div class="single-portfolio" style="background-color: white">
                <div class="portfolio-thumb">
                  <img
                    src="../../../../assets/images/CPA back-office/CPAsolutions4.png"
                    alt="ERP software company in dubai"
                    height="200px"
                  />
                </div>
                <div
                  class="portfolio-content d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="text-center">Audit Support</span>
                  <a
                    style="
                      margin-top: 33px !important;
                      background-color: #418052;
                    "
                    class="p-2"
                    routerLink="/audit-support"
                  >
                    <h4 class="title">Read More</h4>
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 col-12 cbodiv wow fadeInUp mt-3">
          <div class="secCard" data-wow-duration="1s" data-wow-delay=".8s">
            <a class="shadow" routerLink="/tax-return-preparation">
              <div class="single-portfolio" style="background-color: white">
                <div class="portfolio-thumb">
                  <img
                    src="../../../../assets/images/CPA back-office/tax.png"
                    alt="ERP software company in dubai"
                    height="200px;"
                  />
                </div>
                <div
                  class="portfolio-content d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="text-center">Tax Preparation</span>
                  <a
                    class="p-2"
                    routerLink="/tax-return-preparation"
                    style="background-color: #418052"
                  >
                    <h4 class="title">Read more</h4>
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 col-12 cbodiv wow fadeInUp mt-3">
          <div class="thirdCard" data-wow-duration="1s" data-wow-delay=".9s">
            <a class="shadow" routerLink="/accounting">
              <div class="single-portfolio" style="background-color: white">
                <div class="portfolio-thumb">
                  <img
                    src="../../../../assets/images/CPA back-office/CPAsolutions3.png"
                    alt="ERP software company in dubai"
                    height="200px;"
                  />
                </div>
                <div
                  class="portfolio-content d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="text-center">Accounting</span>
                  <a
                    style="margin-top: 33px !important"
                    class="p-2"
                    routerLink="/accounting"
                    style="background-color: #418052"
                  >
                    <h4 class="title">Read More</h4>
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp"></div> -->
    </div>
  </div>
</div>
<!-- Dubai Ends -->
<!-- India Starts -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/CPA back-office/CPAsolutions1.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">CPA BACK OFFICE</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
    </div>
  </div>

  <div
    class="portfolio-3-area custom-portfolio-css pt-115 pb-120 dec-spacing d-flex flex-column justify-content-center align-items-center"
    style="overflow: hidden; position: relative"
  >
    <img
      src="../../../../../assets/images/teams/abstract-images/P-abstract-left.png"
      alt="shape"
      style="
        position: absolute;
        overflow: clip;
        left: 0%;
        bottom: 0%;
        width: 22%;
      "
    />
    <img
      src="../../../../../assets/images/teams/abstract-images/P-abstract-right.png"
      alt="shape"
      style="
        position: absolute;
        overflow: clip;
        right: 0%;
        bottom: 0%;
        width: 22%;
      "
    />
    <div
      class="container d-flex flex-column justify-content-center align-items-center"
      style="gap: 3rem"
    >
      <div class="row">
        <div class="col-md-4 col-sm-6 col-12 cbodiv wow fadeInUp mt-3">
          <div data-wow-duration="1s" data-wow-delay=".7s" class="firstCard">
            <a class="shadow" routerLink="/audit-support">
              <div class="single-portfolio" style="background-color: white">
                <div class="portfolio-thumb">
                  <img
                    src="../../../../assets/images/CPA back-office/CPAsolutions4.png"
                    alt="ERP software company in dubai"
                    height="200px"
                  />
                </div>
                <div
                  class="portfolio-content d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="text-center">Audit Support</span>
                  <a
                    style="
                      margin-top: 33px !important;
                      background-color: #072F5F;
                    "
                    class="p-2"
                    routerLink="/audit-support"
                  >
                    <h4 class="title">Read More</h4>
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 col-12 cbodiv wow fadeInUp mt-3">
          <div class="secCard" data-wow-duration="1s" data-wow-delay=".8s">
            <a class="shadow" routerLink="/tax-return-preparation">
              <div class="single-portfolio" style="background-color: white">
                <div class="portfolio-thumb">
                  <img
                    src="../../../../assets/images/CPA back-office/tax.png"
                    alt="ERP software company in dubai"
                    height="200px;"
                  />
                </div>
                <div
                  class="portfolio-content d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="text-center">Tax Preparation</span>
                  <a
                    class="p-2"
                    routerLink="/tax-return-preparation"
                    style="background-color: #072F5F"
                  >
                    <h4 class="title">Read more</h4>
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 col-12 cbodiv wow fadeInUp mt-3">
          <div class="thirdCard" data-wow-duration="1s" data-wow-delay=".9s">
            <a class="shadow" routerLink="/accounting">
              <div class="single-portfolio" style="background-color: white">
                <div class="portfolio-thumb">
                  <img
                    src="../../../../assets/images/CPA back-office/CPAsolutions3.png"
                    alt="ERP software company in dubai"
                    height="200px;"
                  />
                </div>
                <div
                  class="portfolio-content d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="text-center">Accounting</span>
                  <a
                    style="margin-top: 33px !important"
                    class="p-2"
                    routerLink="/accounting"
                    style="background-color: #072F5F"
                  >
                    <h4 class="title">Read More</h4>
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp"></div> -->
    </div>
  </div>
</div>
<!-- India Ends -->
<!-- USA STARTS -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/CPA back-office/CPAsolutions1.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">CPA BACK OFFICE</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
    </div>
  </div>

  <div
    class="portfolio-3-area custom-portfolio-css pt-115 pb-120 dec-spacing d-flex flex-column justify-content-center align-items-center"
    style="overflow: hidden; position: relative"
  >
    <img
      src="../../../../../assets/images/teams/abstract-images/P-abstract-left.png"
      alt="shape"
      style="
        position: absolute;
        overflow: clip;
        left: 0%;
        bottom: 0%;
        width: 22%;
      "
    />
    <img
      src="../../../../../assets/images/teams/abstract-images/P-abstract-right.png"
      alt="shape"
      style="
        position: absolute;
        overflow: clip;
        right: 0%;
        bottom: 0%;
        width: 22%;
      "
    />
    <div
      class="container d-flex flex-column justify-content-center align-items-center"
      style="gap: 3rem"
    >
      <div class="row">
        <div class="col-md-4 col-sm-6 col-12 cbodiv wow fadeInUp mt-3">
          <div data-wow-duration="1s" data-wow-delay=".7s" class="firstCard">
            <a class="shadow" routerLink="/audit-support">
              <div class="single-portfolio" style="background-color: white">
                <div class="portfolio-thumb">
                  <img
                    src="../../../../assets/images/CPA back-office/CPAsolutions4.png"
                    alt="ERP software company in dubai"
                    height="200px"
                  />
                </div>
                <div
                  class="portfolio-content d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="text-center">Audit Support</span>
                  <a
                    style="
                      margin-top: 33px !important;
                      background-color: #072F5F;
                    "
                    class="p-2"
                    routerLink="/audit-support"
                  >
                    <h4 class="title">Read More</h4>
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 col-12 cbodiv wow fadeInUp mt-3">
          <div class="secCard" data-wow-duration="1s" data-wow-delay=".8s">
            <a class="shadow" routerLink="/tax-return-preparation">
              <div class="single-portfolio" style="background-color: white">
                <div class="portfolio-thumb">
                  <img
                    src="../../../../assets/images/CPA back-office/tax.png"
                    alt="ERP software company in dubai"
                    height="200px;"
                  />
                </div>
                <div
                  class="portfolio-content d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="text-center">Tax Preparation</span>
                  <a
                    class="p-2"
                    routerLink="/tax-return-preparation"
                    style="background-color: #072F5F"
                  >
                    <h4 class="title">Read more</h4>
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-md-4 col-sm-6 col-12 cbodiv wow fadeInUp mt-3">
          <div class="thirdCard" data-wow-duration="1s" data-wow-delay=".9s">
            <a class="shadow" routerLink="/accounting">
              <div class="single-portfolio" style="background-color: white">
                <div class="portfolio-thumb">
                  <img
                    src="../../../../assets/images/CPA back-office/CPAsolutions3.png"
                    alt="ERP software company in dubai"
                    height="200px;"
                  />
                </div>
                <div
                  class="portfolio-content d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="text-center">Accounting</span>
                  <a
                    style="margin-top: 33px !important"
                    class="p-2"
                    routerLink="/accounting"
                    style="background-color: #072F5F"
                  >
                    <h4 class="title">Read More</h4>
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp"></div> -->
    </div>
  </div>
</div>
<!-- USA ENDS -->
