<!-- dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
        <div class="" style="padding: 1%">
          <img
            style="border-radius: 10px; width: 100%"
            class="img-fluid"
            src="../../../../../assets/images/teams/prafullacircle.jpeg"
            alt="PRAFULLA CHHAJED"
          />
        </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start"
        >
        <h3
        class="text-uppercase text-dark title"
        style="font-size: 20px; font-weight: 700;"
      >
        PRAFULLA CHHAJED
      </h3>
      <div>
        <span
        class="text-uppercase text-dark"
        style="font-size: 16px; font-weight: 600"
      >
        ADVISOR
      </span>
      <a
      style="color: #fff"
      href="https://www.linkedin.com/in/prafullachhajed/"
      target="_blank"
    >
      <i
        class="fab fa-linkedin-in"
        style="
          margin-left: 10px;
          background-color: #0072b1;
          padding: 4px;
          border-radius: 5px;
        "
      ></i
    >
  </a>
    </div>
      <p
        class="mt-20 text-dark text-justify"
        style="font-size: 16px !important"
      >
        CA. Prafulla P Chhajed is a fellow and practicing member of the
        Institute of Chartered Accountants of India (ICAI). He has made
        significant contributions to the field of accountancy and held
        prestigious leadership positions throughout his career. Prafulla has
        recently joined Boston Financial Advisory Group as an Advisor. With
        his expertise and guidance, the company is excited to embark on a
        journey towards becoming a globally recognized and esteemed
        Financial Services Solution provider.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
        In 2019-20, he served as the President of the Institute of Chartered
        Accountants of India which is the second largest accounting body in
        the world, demonstrating his exemplary leadership and commitment to
        the profession. Prior to that, he held the position of Chairman of
        the Western India Regional Council (WIRC) of ICAI in 2007-08.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
        His expertise and influence extend beyond national boundaries. He
        has been elected as the Deputy President of the Confederation of
        Asian & Pacific Accountants (CAPA), highlighting his global standing
        in the profession. He was also the Global Chairman of the Executive
        Committee of the World Congress of Accountants 2022 organized by the
        International Federation of Accountants (IFAC). The event witnessed
        the participation of over 6,500 delegates from 105 countries.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >

      Throughout his career, he has actively contributed to various national
      and international organizations, including SAFA, IFAC SMP committee,
      CA Worldwide, and the Integrated Reporting Council.
    </p>
        </div>
      </div>
      <div class="col-lg-12 pl-0 pr-0">
        <p
          class="mt-1 text-dark text-justify"
          style="font-size: 16px !important"
        > His involvement in
          these esteemed bodies showcases his dedication to advancing the
          profession and promoting best practices.
        </p>
        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Currently, he holds several esteemed positions, including Independent
          Director on the Central Board of State Bank of India, Member of the
          Board of Management of Mumbai School of Economics and Public Policy
          (Mumbai University), and Governing Council Member & Chairman of the
          Banking Finance & IT Committee of the Maharashtra Chamber of Commerce,
          Industry & Agriculture.
        </p>
        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Furthermore, he is a valued member of the Professional Accountancy
          Organization Development & Advisory Group (2021-2023) of IFAC, based
          in New York.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          In the past, he has served as Director of SBI Mutual Fund Trustee
          Company Private Limited, Director of GIC Housing Finance Limited, and
          Independent Director of the Insurance Regulatory & Development
          Authority (IRDA). He has also contributed his expertise as a Member of
          SEBI's Primary Market Advisory Committee, Director of IDBI Capital
          Market Services Limited, and Director of the Indian Institute of
          Insolvency Professionals of ICAI, among other significant roles.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Prafulla has also been a prominent figure in the media, having written
          a weekly column titled 'Paisa Jhala Motha' for the renowned Marathi
          daily 'Maharashtra Times' for almost two decades.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Additionally, Prafulla has been involved in various committees and
          organizations, such as the Extensible Business Reporting Language
          (XBRL) India, Banking & Finance Committee of IMC Chamber of Commerce,
          CII National Committee on Financial Reporting.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Through his diverse leadership roles, extensive industry
          contributions, and remarkable achievements, Prafulla has made an
          indelible impact on the accountancy profession and continues to be a
          respected figure in the field.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- dubai ends -->

<!-- india started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
        <div class="" style="padding: 1%">
          <img
            style="border-radius: 10px; width: 100%"
            class="img-fluid"
            src="../../../../../assets/images/teams/prafullacircle.jpeg"
            alt="PRAFULLA CHHAJED"
          />
        </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start"
        >
        <h3
        class="text-uppercase text-dark title"
        style="font-size: 20px; font-weight: 700;"
      >
        PRAFULLA CHHAJED
      </h3>
      <div>
        <span
        class="text-uppercase text-dark"
        style="font-size: 16px; font-weight: 600"
      >
        ADVISOR
      </span>
      <a
      style="color: #fff"
      href="https://www.linkedin.com/in/prafullachhajed/"
      target="_blank"
    >
      <i
        class="fab fa-linkedin-in"
        style="
          margin-left: 10px;
          background-color: #0072b1;
          padding: 4px;
          border-radius: 5px;
        "
      ></i
    >
  </a>
    </div>
      <p
        class="mt-20 text-dark text-justify"
        style="font-size: 16px !important"
      >
        CA. Prafulla P Chhajed is a fellow and practicing member of the
        Institute of Chartered Accountants of India (ICAI). He has made
        significant contributions to the field of accountancy and held
        prestigious leadership positions throughout his career. Prafulla has
        recently joined Boston Financial Advisory Group as an Advisor. With
        his expertise and guidance, the company is excited to embark on a
        journey towards becoming a globally recognized and esteemed
        Financial Services Solution provider.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
        In 2019-20, he served as the President of the Institute of Chartered
        Accountants of India which is the second largest accounting body in
        the world, demonstrating his exemplary leadership and commitment to
        the profession. Prior to that, he held the position of Chairman of
        the Western India Regional Council (WIRC) of ICAI in 2007-08.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
        His expertise and influence extend beyond national boundaries. He
        has been elected as the Deputy President of the Confederation of
        Asian & Pacific Accountants (CAPA), highlighting his global standing
        in the profession. He was also the Global Chairman of the Executive
        Committee of the World Congress of Accountants 2022 organized by the
        International Federation of Accountants (IFAC). The event witnessed
        the participation of over 6,500 delegates from 105 countries.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >

      Throughout his career, he has actively contributed to various national
      and international organizations, including SAFA, IFAC SMP committee,
      CA Worldwide, and the Integrated Reporting Council.
    </p>
        </div>
      </div>
      <div class="col-lg-12 pl-0 pr-0">
        <p
          class="mt-1 text-dark text-justify"
          style="font-size: 16px !important"
        > His involvement in
          these esteemed bodies showcases his dedication to advancing the
          profession and promoting best practices.
        </p>
        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Currently, he holds several esteemed positions, including Independent
          Director on the Central Board of State Bank of India, Member of the
          Board of Management of Mumbai School of Economics and Public Policy
          (Mumbai University), and Governing Council Member & Chairman of the
          Banking Finance & IT Committee of the Maharashtra Chamber of Commerce,
          Industry & Agriculture.
        </p>
        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Furthermore, he is a valued member of the Professional Accountancy
          Organization Development & Advisory Group (2021-2023) of IFAC, based
          in New York.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          In the past, he has served as Director of SBI Mutual Fund Trustee
          Company Private Limited, Director of GIC Housing Finance Limited, and
          Independent Director of the Insurance Regulatory & Development
          Authority (IRDA). He has also contributed his expertise as a Member of
          SEBI's Primary Market Advisory Committee, Director of IDBI Capital
          Market Services Limited, and Director of the Indian Institute of
          Insolvency Professionals of ICAI, among other significant roles.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Prafulla has also been a prominent figure in the media, having written
          a weekly column titled 'Paisa Jhala Motha' for the renowned Marathi
          daily 'Maharashtra Times' for almost two decades.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Additionally, Prafulla has been involved in various committees and
          organizations, such as the Extensible Business Reporting Language
          (XBRL) India, Banking & Finance Committee of IMC Chamber of Commerce,
          CII National Committee on Financial Reporting.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Through his diverse leadership roles, extensive industry
          contributions, and remarkable achievements, Prafulla has made an
          indelible impact on the accountancy profession and continues to be a
          respected figure in the field.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- india ends -->



<!-- USA Started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
        <div class="" style="padding: 1%">
          <img
            style="border-radius: 10px; width: 100%"
            class="img-fluid"
            src="../../../../../assets/images/teams/prafullacircle.jpeg"
            alt="PRAFULLA CHHAJED"
          />
        </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start"
        >
        <h3
        class="text-uppercase text-dark title"
        style="font-size: 20px; font-weight: 700;"
      >
        PRAFULLA CHHAJED
      </h3>
      <div>
        <span
        class="text-uppercase text-dark"
        style="font-size: 16px; font-weight: 600"
      >
        ADVISOR
      </span>
      <a
      style="color: #fff"
      href="https://www.linkedin.com/in/prafullachhajed/"
      target="_blank"
    >
      <i
        class="fab fa-linkedin-in"
        style="
          margin-left: 10px;
          background-color: #0072b1;
          padding: 4px;
          border-radius: 5px;
        "
      ></i
    >
  </a>
    </div>
      <p
        class="mt-20 text-dark text-justify"
        style="font-size: 16px !important"
      >
        CA. Prafulla P Chhajed is a fellow and practicing member of the
        Institute of Chartered Accountants of India (ICAI). He has made
        significant contributions to the field of accountancy and held
        prestigious leadership positions throughout his career. Prafulla has
        recently joined Boston Financial Advisory Group as an Advisor. With
        his expertise and guidance, the company is excited to embark on a
        journey towards becoming a globally recognized and esteemed
        Financial Services Solution provider.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
        In 2019-20, he served as the President of the Institute of Chartered
        Accountants of India which is the second largest accounting body in
        the world, demonstrating his exemplary leadership and commitment to
        the profession. Prior to that, he held the position of Chairman of
        the Western India Regional Council (WIRC) of ICAI in 2007-08.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
        His expertise and influence extend beyond national boundaries. He
        has been elected as the Deputy President of the Confederation of
        Asian & Pacific Accountants (CAPA), highlighting his global standing
        in the profession. He was also the Global Chairman of the Executive
        Committee of the World Congress of Accountants 2022 organized by the
        International Federation of Accountants (IFAC). The event witnessed
        the participation of over 6,500 delegates from 105 countries.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >

      Throughout his career, he has actively contributed to various national
      and international organizations, including SAFA, IFAC SMP committee,
      CA Worldwide, and the Integrated Reporting Council.
    </p>
        </div>
      </div>
      <div class="col-lg-12 pl-0 pr-0">
        <p
          class="mt-1 text-dark text-justify"
          style="font-size: 16px !important"
        > His involvement in
          these esteemed bodies showcases his dedication to advancing the
          profession and promoting best practices.
        </p>
        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Currently, he holds several esteemed positions, including Independent
          Director on the Central Board of State Bank of India, Member of the
          Board of Management of Mumbai School of Economics and Public Policy
          (Mumbai University), and Governing Council Member & Chairman of the
          Banking Finance & IT Committee of the Maharashtra Chamber of Commerce,
          Industry & Agriculture.
        </p>
        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Furthermore, he is a valued member of the Professional Accountancy
          Organization Development & Advisory Group (2021-2023) of IFAC, based
          in New York.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          In the past, he has served as Director of SBI Mutual Fund Trustee
          Company Private Limited, Director of GIC Housing Finance Limited, and
          Independent Director of the Insurance Regulatory & Development
          Authority (IRDA). He has also contributed his expertise as a Member of
          SEBI's Primary Market Advisory Committee, Director of IDBI Capital
          Market Services Limited, and Director of the Indian Institute of
          Insolvency Professionals of ICAI, among other significant roles.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Prafulla has also been a prominent figure in the media, having written
          a weekly column titled 'Paisa Jhala Motha' for the renowned Marathi
          daily 'Maharashtra Times' for almost two decades.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Additionally, Prafulla has been involved in various committees and
          organizations, such as the Extensible Business Reporting Language
          (XBRL) India, Banking & Finance Committee of IMC Chamber of Commerce,
          CII National Committee on Financial Reporting.
        </p>

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
          Through his diverse leadership roles, extensive industry
          contributions, and remarkable achievements, Prafulla has made an
          indelible impact on the accountancy profession and continues to be a
          respected figure in the field.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- USA End -->
