import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iso-page',
  templateUrl: './iso-page.component.html',
  styleUrls: ['./iso-page.component.css']
})
export class IsoPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
