<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop divmart">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/tax-regulatory-complance.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
              Audit & Assurance Support
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">

          <div class="row">
            <div class="col-md-4">
              <div class="faq-accordion-3 pt-0">
              <div class="accordion" id="accordionExample1">
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px; background-color: #4180521A;">
                <div class="card-header" id="headingSev">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                    aria-expanded="true" aria-controls="collapseSevv" style="color: #418052;">
                    <span (click)="onAccounting()">Accounting & Transaction Processing</span>
                  </a>
                </div>
                <div id="collapseSevv" class="collapse " aria-labelledby="headingSev" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark serviceul">
                        <li routerLink="/accounting-services" style="color: black">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Accounts Payable
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingEight">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                    aria-expanded="false" aria-controls="collapseEight" style="color: #418052;">
                    <span (click)="onTax()">Tax & Regulatory Compliances</span>
                  </a>
                </div>
                <div id="collapseEight" class="collapse " aria-labelledby="headingEight" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark serviceul">
                        <li class="shli" routerLink="/coordination-and-assistance-for-vat-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         VAT Advisory
                        </li>
                        <li  routerLink="/coordination-and-assistance-for-tax-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Corporate Tax Advisory
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingNine">
                  <a  href="" data-toggle="collapse" data-target="#collapseNine"
                    aria-expanded="false" aria-controls="collapseNine" style="color: #418052;">
                    <span (click)="onAudit()"> Audit & Assurance Support</span>
                  </a>
                </div>
                <div id="collapseNine" class="collapse show" aria-labelledby="headingNine" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark pt-4 serviceul">
                        <li  routerLink="/statutory-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Statutory Audit
                        </li>
                        <li class="shli" routerLink="/internal-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Internal Audit
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->

              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingTen">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                    aria-expanded="false" aria-controls="collapseTen" style="color: #418052;">
                    <span (click)="onConsulting()">Consulting & Advisory</span>
                  </a>
                </div>
                <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark pt-4 serviceul">
                        <li routerLink="/business-setup">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Setting up an entity in Dubai
                        </li>
                        <li routerLink="/business-plan-preparation">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Business Plan Preparation
                        </li>
                        <li routerLink="/business-valuation-service">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Business Valuation
                        </li>
                        <li routerLink="/mergers-and-acquisitions">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Mergers and Acquisitions (M&A)
                        </li>
                        <li routerLink="/strategic-cfo-services">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Strategic CFO
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->
              </div>
              </div>

            </div>
            <div class="col-md-8">
              <div style="margin-top: 1rem">
                <h3 id="internal-audit" style=" color: #418052 ;">Internal Audit</h3>
                <!--<p class="mb-15 mt-20 text-dark servicep">
                  Streamline Your Finances with Expert Accounts Payable Services
                </p>-->
                <p class="mb-15 mt-20 text-dark servicep">
                    BFAG is a leading provider of Internal Audit services. With our deep expertise and knowledge of local regulations, industry best practices, and risk management, we offer comprehensive and reliable internal audit solutions to help businesses enhance their governance, risk management, and control processes. In this article, we will explore BFAG's Internal Audit services, designed to meet the unique needs of organization’s operations.
                </p>
              </div>
              <div class="about-faq-area-custom horizon-hrms gray-bg clearfix"  style="background-color: #4180521A;">
                <div class="container">
                    <div class="row" style="display: block;">
                        <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                            <div class="accordion" id="accordionExample2">
                                <div class="col-lg-6">
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                        <div class="card-header" id="headingEleven">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven"  style=" color: #418052A1;">
                                                Comprehensive Internal Audit Services
                                            </a>
                                        </div>
                                        <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p  style=" color: black;">BFAG's Internal Audit services are tailored to assist businesses in evaluating and enhancing their internal control systems, risk management frameworks, and operational efficiencies.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                        <div class="card-header" id="headingTwelve">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve"  style=" color: #418052A1;">
                                                Risk Assessment
                                            </a>
                                        </div>
                                        <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p  style=" color: black">We conduct a comprehensive risk assessment to identify and prioritize potential risks and vulnerabilities specific to your organization, allowing us to tailor our audit procedures accordingly.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <!-- card -->
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                        <div class="card-header" id="headingThirteen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen"  style=" color: #418052A1;">
                                                Internal Control Evaluation
                                            </a>
                                        </div>
                                        <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p  style=" color: black;">Our experts assess your internal control systems and processes to identify gaps, weaknesses, and areas for improvement, helping you strengthen your control environment and minimize operational risks.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <!-- card -->
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                        <div class="card-header" id="headingSixteen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen"  style=" color: #418052A1;">
                                                Compliance Review
                                            </a>
                                        </div>
                                        <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p  style=" color: black;">We review your organization's compliance with applicable laws, regulations, and internal policies, ensuring adherence to legal and regulatory requirements and mitigating compliance risks.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                </div>

                                <div class="col-lg-6">
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                        <div class="card-header" id="headingFourteen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen"  style=" color: #418052A1;">
                                                Process Efficiency
                                            </a>
                                        </div>
                                        <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p  style=" color: black">We assess your business processes to identify inefficiencies, bottlenecks, and areas where operational improvements can be made, helping you streamline operations and enhance productivity.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                        <div class="card-header" id="headingFifteen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen"  style=" color: #418052A1;">
                                                Fraud Detection and Prevention
                                            </a>
                                        </div>
                                        <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p  style=" color: black;">Our internal audit procedures include a thorough assessment of fraud risks and the implementation of controls to detect and prevent fraudulent activities within your organization.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <!-- card -->
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                        <div class="card-header" id="headingSeventeen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen"  style=" color: #418052A1;">
                                               Customized Audit Approach
                                            </a>
                                        </div>
                                        <div id="collapseSeventeen" class="collapse " aria-labelledby="headingSeventeen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p  style=" color: black;">At BFAG, we understand that every organization is unique, and our Internal Audit services are tailored to meet your specific needs. We work closely with your management team to gain a deep understanding of your business objectives, risks, and operations. This allows us to develop a customized audit approach that addresses your organization's specific challenges and focuses on areas critical to your success.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                        <div class="card-header" id="headingEighteen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen"  style=" color: #418052A1;">
                                                Experienced Professionals and Advanced Tools
                                            </a>
                                        </div>
                                        <div id="collapseEighteen" class="collapse " aria-labelledby="headingEighteen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p  style=" color: black;">Our Internal Audit team comprises experienced professionals who possess a strong understanding of industry best practices and local regulations. We leverage advanced audit tools and methodologies to enhance the effectiveness and efficiency of our audit procedures, ensuring a comprehensive and timely delivery of our services.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->

                                </div>
                            </div>
                            <!-- faq accordion -->
                        </div>
                    </div>
                    <!-- row -->
                </div>
                <!-- container -->
            </div>
            <p class="mb-15 mt-20 text-dark servicep">
                BFAG's Internal Audit services are designed to help organizations enhance their governance, risk management, and control processes. With our comprehensive approach, customized audit methodology, and experienced professionals, we deliver reliable and valuable insights to improve your internal control systems, identify and mitigate risks, streamline operations, and ensure compliance with applicable laws and regulations. Trust BFAG to be your partner in strengthening your organization's internal processes and achieving your strategic objectives. Contact us today.
            </p>
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>
