<!-- USA Started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150">
    <div class="col-lg-12 col-md-12">
      <div class="p-3 mb-5 bg-white">
        <div class="row">
          <div
            class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
          >
          <div class="" style="padding: 1%">
            <img
              style="border-radius: 10px; width: 100%"
              class="img-fluid"
              src="../../../../../assets/images/teams/Ketan Choksi.jpg"
              alt="Ketan Chokshi"
            />
          </div>
          </div>
          <div
            class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start"
          >
          <h3
          class="text-uppercase text-dark title"
          style="font-size: 20px; font-weight: 700;"
        >
        Ketan Chokshi
        </h3>
        <div>
          <span
          class="text-uppercase text-dark"
          style="font-size: 16px; font-weight: 600"
        >
          ADVISOR
        </span>
        <a
        style="color: #fff"
        href="https://www.linkedin.com/in/sameerpaddalwar/"
        target="_blank"
      >
        <i
          class="fab fa-linkedin-in"
          style="
            margin-left: 10px;
            background-color: #0072b1;
            padding: 4px;
            border-radius: 5px;
          "
        ></i
      >
    </a>
      </div>
        <p
          class="mt-20 text-dark text-justify"
          style="font-size: 16px !important"
        >
        Ketan Chokshi is a strategist par excellence with proven expertise in devising business strategy and global corporate business restructuring, and mergers and acquisitions. Having operated an NBFC for over two decades and grown it from strength to strength, Ketan brings his extensive experience to strengthen BFAG’s strategic development and M&A practice.
        </p>
  
        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        A certified chartered accountant and law graduate, Ketan has been involved in various financial transactions including financial restructuring, raising structured debts, managing IPOs, equity placements, due diligences, negotiations, M&A, valuations, financial closure for large projects, etc. He has been responsible for turning businesses around for several large industrial and business houses in IT / IT enabled BPOs, KPOs, FMCGs, telecom, entertainment, etc. Ketan has served as an independent director on the board of several listed companies.
        </p>
          </div>
        </div>
        
      </div>
    </div>
  </div>
  <!-- USA End -->
  
  
  <!-- India started -->
  <div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150">
    <div class="col-lg-12 col-md-12">
        <div class="p-3 mb-5 bg-white">
          <div class="row">
            <div
              class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
            >
            <div class="" style="padding: 1%">
              <img
                style="border-radius: 10px; width: 100%"
                class="img-fluid"
                src="../../../../../assets/images/teams/Ketan Choksi.jpg"
                alt="Ketan Chokshi"
              />
            </div>
            </div>
            <div
              class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start"
            >
            <h3
            class="text-uppercase text-dark title"
            style="font-size: 20px; font-weight: 700;"
          >
          Ketan Chokshi
          </h3>
          <div>
            <span
            class="text-uppercase text-dark"
            style="font-size: 16px; font-weight: 600"
          >
            ADVISOR
          </span>
          <a
          style="color: #fff"
          href="https://www.linkedin.com/in/sameerpaddalwar/"
          target="_blank"
        >
          <i
            class="fab fa-linkedin-in"
            style="
              margin-left: 10px;
              background-color: #0072b1;
              padding: 4px;
              border-radius: 5px;
            "
          ></i
        >
      </a>
        </div>
          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Ketan Chokshi is a strategist par excellence with proven expertise in devising business strategy and global corporate business restructuring, and mergers and acquisitions. Having operated an NBFC for over two decades and grown it from strength to strength, Ketan brings his extensive experience to strengthen BFAG’s strategic development and M&A practice.
          </p>
    
          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          A certified chartered accountant and law graduate, Ketan has been involved in various financial transactions including financial restructuring, raising structured debts, managing IPOs, equity placements, due diligences, negotiations, M&A, valuations, financial closure for large projects, etc. He has been responsible for turning businesses around for several large industrial and business houses in IT / IT enabled BPOs, KPOs, FMCGs, telecom, entertainment, etc. Ketan has served as an independent director on the board of several listed companies.
          </p>
            </div>
          </div>
          
        </div>
      </div>
  </div>
  <!-- India ends -->
  
  
  <!-- Dubai started -->
  <div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150">
    <div class="col-lg-12 col-md-12">
        <div class="p-3 mb-5 bg-white">
          <div class="row">
            <div
              class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
            >
            <div class="" style="padding: 1%">
              <img
                style="border-radius: 10px; width: 100%"
                class="img-fluid"
                src="../../../../../assets/images/teams/Ketan Choksi.jpg"
                alt="Ketan Chokshi"
              />
            </div>
            </div>
            <div
              class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start"
            >
            <h3
            class="text-uppercase text-dark title"
            style="font-size: 20px; font-weight: 700;"
          >
          Ketan Chokshi
          </h3>
          <div>
            <span
            class="text-uppercase text-dark"
            style="font-size: 16px; font-weight: 600"
          >
            ADVISOR
          </span>
          <a
          style="color: #fff"
          href="https://www.linkedin.com/in/sameerpaddalwar/"
          target="_blank"
        >
          <i
            class="fab fa-linkedin-in"
            style="
              margin-left: 10px;
              background-color: #0072b1;
              padding: 4px;
              border-radius: 5px;
            "
          ></i
        >
      </a>
        </div>
          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Ketan Chokshi is a strategist par excellence with proven expertise in devising business strategy and global corporate business restructuring, and mergers and acquisitions. Having operated an NBFC for over two decades and grown it from strength to strength, Ketan brings his extensive experience to strengthen BFAG’s strategic development and M&A practice.
          </p>
    
          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          A certified chartered accountant and law graduate, Ketan has been involved in various financial transactions including financial restructuring, raising structured debts, managing IPOs, equity placements, due diligences, negotiations, M&A, valuations, financial closure for large projects, etc. He has been responsible for turning businesses around for several large industrial and business houses in IT / IT enabled BPOs, KPOs, FMCGs, telecom, entertainment, etc. Ketan has served as an independent director on the board of several listed companies.
          </p>
            </div>
          </div>
          
        </div>
      </div>
  </div>
  <!-- Dubai ends -->
  