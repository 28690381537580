import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {
  sendContactForm(value: any) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) { }
  //this method is use home page contact form, header modal contact form & contact page form
  contactform(data){
    return this.http.post(`${environment.baseUrl}save_contact_details`, data)
  }

  contactEnquiry(data){
    return this.http.post(`${environment.baseUrl}save_enquiry_details`, data);
  }
  
  // private apiURL = 'http://bfag.ezii.live/admin_bfag/save_contact_details';

  // constructor(private http: HttpClient) { }

  // contactform(data) {
  //   return this.http.post(this.apiURL, data);
  // }

}
