<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/Accounting.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Accounting & Transaction Processing
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                  <div class="col-md-4">
                    <div class="faq-accordion-3 pt-0">
                      <div class="accordion" id="accordionExample1">
                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                      <div class="card-header" id="headingSev" >
                        <a  href="#accounting-and-transition-processing" data-toggle="collapse" data-target="#collapseSevv"
                        aria-expanded="true" aria-controls="collapseSevv" >
                          Accounting & Transaction Processing
                        </a>
                      </div>
                      <div id="collapseSevv" class="collapse show" aria-labelledby="headingSev" data-parent="#accordionExample1">
                        <div class="card-body bg-white">
                          <div class="list-group">
                            <ul class="text-dark serviceul">

                              <li  routerLink="/accounts-payable">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Accounts Payable
                              </li>
                              <li routerLink="/accounts-receivable">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Accounts Receivable
                              </li>
                              <li routerLink="/fixed-assets-accounting">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Fixed Assets Accounting
                              </li>
                              <li class="shli" routerLink="/financial-reporting">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Financial Reporting
                              </li>
                              <li routerLink="/budgeting-and-variance-analysis">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Budgeting & Variance Analysis
                              </li>
                              <li routerLink="/reconciliation-services">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Reconciliation Services
                              </li>
                              <li routerLink="/consolidation-reporting-gaap-conversion">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                 Consolidation Reporting & GAAP Conversion
                              </li>
                              <li routerLink="/small-business-accounting">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Small Business Accounting
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->
                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                      <div class="card-header" id="headingEight">
                        <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                          aria-expanded="false" aria-controls="collapseEight">
                          <span (click)="onTax()">Tax & Regulatory Compliances</span>
                        </a>
                      </div>
                      <div id="collapseEight" class="collapse " aria-labelledby="headingEight" data-parent="#accordionExample1">
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark serviceul">
                              <li  routerLink="/individual-tax-return">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Individual Tax Return
                              </li>
                              <li  routerLink="/corporate-tax-return">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Corporate Tax Return
                              </li>
                              <li routerLink="/sales-and-use-tax">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Sales & Use Tax
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- card -->
                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                      <div class="card-header" id="headingNine">
                        <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                          aria-expanded="false" aria-controls="collapseNine">
                          <span (click)="onPayroll()">Payroll Advisory</span>
                        </a>
                      </div>
                      <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark pt-4 serviceul">
                              <li routerLink="/payroll-processing">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Payroll Processing
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->

                    <!-- card -->
                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                      <div class="card-header" id="headingTen">
                        <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                          aria-expanded="false" aria-controls="collapseTen">
                          <span (click)="onConsulting()">Consulting & Advisory</span>
                        </a>
                      </div>
                      <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark pt-4 serviceul">
                              <li routerLink="/business-setup">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Setting up an entity in the US
                              </li>
                              <li routerLink="/global-corporate-structuring">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Global Corporate Structuring
                              </li>
                              <li routerLink="/business-plan-preparation">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Business Plan Preparation
                              </li>
                              <li routerLink="/business-valuation-service">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               <a href="#business-valuation-service"> Business Valuation</a>
                              </li>
                              <li routerLink="/mergers-and-acquisitions">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Mergers and Acquisitions
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->
                    </div>
                    </div>
                  </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3 id="financial-reporting">Financial Reporting</h3>
                    <!--<p class="mb-15 mt-20 text-dark servicep">
                      Unlock the Power of Data with Professional Financial Reporting
                    </p>-->
                    <p class="mb-15 mt-20 text-dark servicep">
                      Accurate and insightful financial reporting is vital for businesses to make informed decisions, comply with regulatory requirements, and communicate financial performance to stakeholders. However, generating comprehensive reports and analyzing financial data can be complex and time-consuming, especially for organizations with limited resources. At BFAG, we offer professional financial reporting services designed to unlock the power of data and provide you with valuable insights. Our expert team can help you streamline your reporting processes and maximize the value of your financial information.
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                  Customized Financial Reporting Solutions
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Our experienced professionals understand that every business is unique, with distinct reporting requirements. We offer tailored financial reporting solutions that align with your specific needs and industry standards. Whether you require monthly management reports, annual financial statements, or specialized reports for investors or regulatory bodies, our team will collaborate with you to create customized reporting frameworks.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                            <div class="card-header" id="headingTwelve">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                  Comprehensive Financial Data Analysis
                                                </a>
                                            </div>
                                            <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Financial reports are more than just numbers on a page. They hold the key to understanding your business's financial health and performance. Our financial reporting services go beyond mere data compilation; we analyze the numbers to provide meaningful insights. By applying advanced analytics techniques and financial ratios, we help you gain a deeper understanding of your company's financial performance, identify trends, and make data-driven decisions.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingThirteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                  Regulatory Compliance and Standards Adherence
                                                </a>
                                            </div>
                                            <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Compliance with financial reporting regulations and standards is crucial for businesses, ensuring transparency and accountability. Our team stays up-to-date with the latest regulatory requirements, including generally accepted accounting principles (GAAP) and international financial reporting standards (IFRS). We help you navigate complex reporting frameworks and ensure accurate and compliant financial statements.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- card -->
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingFourteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                  Timely and Accurate Reporting
                                                </a>
                                            </div>
                                            <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>In today's fast-paced business environment, timely financial reporting is essential for effective decision-making. Our financial reporting services are designed to provide you with accurate and up-to-date information when you need it most. We streamline reporting processes, implement efficient data collection methods, and utilize robust reporting tools to deliver reports within agreed timelines.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                            <div class="card-header" id="headingFifteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                  Visual Presentation of Financial Data
                                                </a>
                                            </div>
                                            <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We understand that presenting financial data in a clear and visually appealing manner enhances understanding and engagement. Our financial reporting services include the creation of visually impactful reports, utilizing charts, graphs, and visual representations to convey complex financial information in a digestible format. This enables you to communicate financial insights effectively to stakeholders, including management, investors, and board members.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                            <div class="card-header" id="headingSixteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                  Data Security and Confidentiality
                                                </a>
                                            </div>
                                            <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We prioritize the security and confidentiality of your financial data. Our financial reporting services employ stringent data protection measures, including secure data transmission, restricted access controls, and compliance with industry best practices. You can trust that your sensitive financial information is handled with the utmost care and in accordance with relevant data privacy regulations.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->

                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                  BFAG provides businesses with the expertise and resources needed to unlock the full potential of their financial data. We offer customized reporting solutions, comprehensive data analysis, and adherence to regulatory standards. By leveraging our expertise and advanced reporting tools, you can gain valuable insights, ensure compliance, and make well-informed decisions. Contact us today to harness the power of data and take your business to new heights.
                </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>

<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/Accounting.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Accounting & Transaction Processing
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                  <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev">
                      <a class="" href="" data-toggle="collapse" data-target="#collapseSev"
                        aria-expanded="true" aria-controls="collapseSev">
                        <span routerLink="/accounting-and-transition-processing">Accounting & Transaction Processing</span>
                      </a>
                    </div>
                    <div id="collapseSev" class="collapse show" aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#accounts-receivable"> Accounts Receivable</a>
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#fixed-assets-accounting"> Fixed Assets Accounting</a>
                            </li>
                            <li class="shli" routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              <a href="#budgeting-and-variance-analysis">Budgeting & Variance Analysis</a>
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Consolidation Reporting & GAAP Conversion
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li routerLink="/income-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Income Tax
                            </li>
                            <li  routerLink="/goods-and-service-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Goods & Service Tax
                            </li>
                            <li routerLink="/corporate-secretarial-compliance">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Secretarial Compliance
                            </li>
                            <li  routerLink="/fema-advisory">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              FEMA Compliance
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                <!-- card -->
                <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                  <div class="card-header" id="headingNine">
                    <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                      aria-expanded="false" aria-controls="collapseNine">
                      <span (click)="onPayroll()">Payroll Advisory</span>
                    </a>
                  </div>
                  <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                    <div class="card-body bg-white">
                      <div>
                        <ul class="text-dark pt-4 serviceul">
                          <li routerLink="/payroll-processing">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Payroll - Compensation & Benefit Management
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card -->

                <!-- card -->
                <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                  <div class="card-header" id="headingTen">
                    <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                      aria-expanded="false" aria-controls="collapseTen">
                      <span (click)="onConsulting()">Consulting & Advisory</span>
                    </a>
                  </div>
                  <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                    <div class="card-body bg-white">
                      <div>
                        <ul class="text-dark pt-4 serviceul">
                          <li routerLink="/business-setup">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Setting up an entity in the India
                          </li>
                          <li routerLink="/global-corporate-structuring">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Global Corporate Structuring
                          </li>
                          <li routerLink="/business-plan-preparation">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Business Plan Preparation
                          </li>
                          <li routerLink="/business-valuation-service">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Business Valuation
                          </li>
                          <li routerLink="/mergers-and-acquisitions">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Mergers and Acquisitions
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card -->
                <!-- card -->
                <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                  <div class="card-header" id="headingElevenStartup">
                    <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseElevenStartup"
                      aria-expanded="false" aria-controls="collapseElevenStartup">
                      <span (click)="onStartup()">Startup Solution</span>
                    </a>
                  </div>
                  <div id="collapseElevenStartup" class="collapse" aria-labelledby="headingElevenStartup" data-parent="#accordionExample1">
                    <div class="card-body bg-white">
                      <div>
                        <ul class="text-dark pt-4 serviceul">
                          <li routerLink="/startup-solution">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Startup Solution
                          </li>
                          <li routerLink="/fund-raising">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                           Fund Raise
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card -->
                  </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3 id="financial-reporting">Financial Reporting</h3>
                    <!--<p class="mb-15 mt-20 text-dark servicep">
                      Unlock the Power of Data with Professional Financial Reporting
                    </p>-->
                    <p class="mb-15 mt-20 text-dark servicep">
                      Accurate and insightful financial reporting is vital for businesses to make informed decisions, comply with regulatory requirements, and communicate financial performance to stakeholders. However, generating comprehensive reports and analyzing financial data can be complex and time-consuming, especially for organizations with limited resources. At BFAG, we offer professional financial reporting services designed to unlock the power of data and provide you with valuable insights. Our expert team can help you streamline your reporting processes and maximize the value of your financial information.
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                  Customized Financial Reporting Solutions
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Our experienced professionals understand that every business is unique, with distinct reporting requirements. We offer tailored financial reporting solutions that align with your specific needs and industry standards. Whether you require monthly management reports, annual financial statements, or specialized reports for investors or regulatory bodies, our team will collaborate with you to create customized reporting frameworks.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                            <div class="card-header" id="headingTwelve">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                  Comprehensive Financial Data Analysis
                                                </a>
                                            </div>
                                            <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Financial reports are more than just numbers on a page. They hold the key to understanding your business's financial health and performance. Our financial reporting services go beyond mere data compilation; we analyze the numbers to provide meaningful insights. By applying advanced analytics techniques and financial ratios, we help you gain a deeper understanding of your company's financial performance, identify trends, and make data-driven decisions.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingThirteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                  Regulatory Compliance and Standards Adherence
                                                </a>
                                            </div>
                                            <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Compliance with financial reporting regulations and standards is crucial for businesses, ensuring transparency and accountability. Our team stays up-to-date with the latest regulatory requirements, including generally accepted accounting principles (GAAP) and international financial reporting standards (IFRS). We help you navigate complex reporting frameworks and ensure accurate and compliant financial statements.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- card -->
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingFourteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                  Timely and Accurate Reporting
                                                </a>
                                            </div>
                                            <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>In today's fast-paced business environment, timely financial reporting is essential for effective decision-making. Our financial reporting services are designed to provide you with accurate and up-to-date information when you need it most. We streamline reporting processes, implement efficient data collection methods, and utilize robust reporting tools to deliver reports within agreed timelines.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                            <div class="card-header" id="headingFifteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                  Visual Presentation of Financial Data
                                                </a>
                                            </div>
                                            <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We understand that presenting financial data in a clear and visually appealing manner enhances understanding and engagement. Our financial reporting services include the creation of visually impactful reports, utilizing charts, graphs, and visual representations to convey complex financial information in a digestible format. This enables you to communicate financial insights effectively to stakeholders, including management, investors, and board members.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                            <div class="card-header" id="headingSixteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                  Data Security and Confidentiality
                                                </a>
                                            </div>
                                            <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We prioritize the security and confidentiality of your financial data. Our financial reporting services employ stringent data protection measures, including secure data transmission, restricted access controls, and compliance with industry best practices. You can trust that your sensitive financial information is handled with the utmost care and in accordance with relevant data privacy regulations.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->

                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                  BFAG provides businesses with the expertise and resources needed to unlock the full potential of their financial data. We offer customized reporting solutions, comprehensive data analysis, and adherence to regulatory standards. By leveraging our expertise and advanced reporting tools, you can gain valuable insights, ensure compliance, and make well-informed decisions. Contact us today to harness the power of data and take your business to new heights.
                </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>
