<!-- start dubai -->
<div *ngIf="selectCountryName == 'Dubai'"
  class="min-vh-100 mt-160 container d-flex flex-column justify-content-center align-items-center"
>
  <div class="justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      FINANCIAL SERVICES
    </h2>
  </div>
  <div class="mt-50">
    <ul
      class="nav nav-tabs justify-content-around border-0"
      id="myTab"
      role="tablist"
      style="gap: 5rem; margin-bottom: 20px !important;"
    >
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active btn-dgn1"
          id="home-tab"
          data-toggle="tab"
          data-target="#home"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          
        >
          F & A Service
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link btn-dgn2"
          id="profile-tab"
          data-toggle="tab"
          data-target="#profile"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
          
        >
          Global Structuring & Advisory
        </button>
      </li>
    </ul>
    <div class="tab-content mt-50" id="myTabContent">
      <div
        class="tab-pane fade show active col"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="col">
          <div
            class="row mb-100 justify-content-center align-items-center"
            style="gap: 3rem"
          >
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10">
                <i class="fas fa-search fa-2x" style="color: #21ac51"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Source To Pay</h5>
                <a
                  routerLink="/source-to-pay"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30"
                  >Know More</a
                >
              </div>
            </div>

            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10">
                <i class="far fa-globe fa-2x" style="color: #21ac51"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Order To Cash</h5>
                <a
                  routerLink="/order-to-cash"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30"
                  >Know More</a
                >
              </div>
            </div>
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10">
                <i class="fas fa-search fa-2x" style="color: #21ac51"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Financial Analysis</h5>
                <a
                  routerLink="/financial-analysis"
                  routerLinkActive="active"
                  href="#"
                  class="cardBtn text-white mt-40 mb-30"
                  >Know More</a
                >
              </div>
            </div>
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10">
                <i class="far fa-globe fa-2x" style="color: #21ac51"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Global Report</h5>
                <a
                  routerLink="/global-report"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30"
                  >Know More</a
                >
              </div>
            </div>
          </div>
          <div
            class="row justify-content-center align-items-center"
            style="gap: 3rem"
          >
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10">
                <i class="far fa-sack-dollar fa-2x" style="color: #21ac51"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Direct Tax Services</h5>
                <a
                  routerLink="/direct-tax-services"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30"
                  >Know More</a
                >
              </div>
            </div>

            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10">
                <i class="far fa-sack-dollar fa-2x" style="color: #21ac51"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Indirect Tax Service</h5>
                <a
                  routerLink="/indirect-tax-services"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30"
                  >Know More</a
                >
              </div>
            </div>
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10">
                <i
                  class="fas fa-balance-scale-right fa-2x"
                  style="color: #21ac51"
                ></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Corporate Law Compliance</h5>
                <a
                  routerLink="/corporate-law-compliance"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30"
                  >Know More</a
                >
              </div>
            </div>
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10">
                <i
                  class="far fa-money-check-alt fa-2x"
                  style="color: #21ac51"
                ></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">
                  Payroll – Compensation And Benefit Management
                </h5>
                <a
                  routerLink="/payroll-compensation-and-benefit-management"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30"
                  >Know More</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div
          class="row mb-100 justify-content-center align-items-center"
          style="gap: 3rem"
        >
          <div
            class="card justify-content-center align-items-center shadow border-0"
            style="width: 15rem"
          >
            <div class="icon shadow-sm mt-10">
              <i class="fas fa-search fa-2x" style="color: #21ac51"></i>
            </div>
            <div class="card-body text-center">
              <h5 style="font-weight: 600">
                Business Set Up & Corporate Structuring
              </h5>
              <a routerLink="/business-setup-and-corporate-structuring"
              routerLinkActive="active"  class="cardBtn text-white mt-40 mb-30">Know More</a>
            </div>
          </div>

          <div
            class="card justify-content-center align-items-center shadow border-0"
            style="width: 15rem"
          >
            <div class="icon shadow-sm mt-10">
              <i class="far fa-globe fa-2x" style="color: #21ac51"></i>
            </div>
            <div class="card-body text-center">
              <h5 style="font-weight: 600">Global Compliance Management</h5>
              <a routerLink="/global-compliance-management"
              routerLinkActive="active"  class="cardBtn text-white mt-40 mb-30">Know More</a>
            </div>
          </div>
          <div
            class="card justify-content-center align-items-center shadow border-0"
            style="width: 15rem"
          >
            <div class="icon shadow-sm mt-10">
              <i class="fas fa-search fa-2x" style="color: #21ac51"></i>
            </div>
            <div class="card-body text-center">
              <h5 style="font-weight: 600">
                CFO Services & Debit Syndication
              </h5>
              <a routerLink="/cfo-service-and-debit-syndication"
              routerLinkActive="active"  class="cardBtn text-white mt-40 mb-30">Know More</a>
            </div>
          </div>
          <div
            class="card justify-content-center align-items-center shadow border-0"
            style="width: 15rem"
          >
            <div class="icon shadow-sm mt-10">
              <i class="far fa-globe fa-2x" style="color: #21ac51"></i>
            </div>
            <div class="card-body text-center">
              <h5 style="font-weight: 600">FEMA Advisory</h5>
              <a routerLink="/fema-advisory"
              routerLinkActive="active"  class="cardBtn text-white mt-40 mb-30">Know More</a>
            </div>
          </div>
        </div>
      </div> 
      
    </div>
  </div>
</div>
<!-- end dubai -->

<!-- start India -->
<div *ngIf="selectCountryName == 'India'"
  class="min-vh-100 mt-160 container d-flex flex-column justify-content-center align-items-center"
>
  <div class="justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      FINANCIAL SERVICES
    </h2>
  </div>
  <div class="mt-50">
    <ul
      class="nav nav-tabs-india justify-content-around border-0"
      id="myTab"
      role="tablist"
      style="gap: 5rem; margin-bottom: 20px !important;"
    >
      <li class="nav-item-india" role="presentation">
        <button
          class="nav-link-india active btn-dgn1 "
          id="home-tab"
          data-toggle="tab"
          data-target="#home"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          
        >
          F & A Service
        </button>
      </li>
      <li class="nav-item-india" role="presentation">
        <button
          class="nav-link-india btn-dgn2"
          id="profile-tab"
          data-toggle="tab"
          data-target="#profile"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
          
        >
          Global Structuring & Advisory
        </button>
      </li>
    </ul>
    <div class="tab-content mt-50" id="myTabContent">
      <div
        class="tab-pane fade show active col"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="col">
          <div
            class="row mb-100 justify-content-center align-items-center"
            style="gap: 3rem"
          >
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i class="fas fa-search fa-2x" style="color: #0951A4"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Source To Pay</h5>
                <a
                  routerLink="/source-to-pay"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>

            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i class="far fa-globe fa-2x" style="color: #0951A4"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Order To Cash</h5>
                <a
                  routerLink="/order-to-cash"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i class="fas fa-search fa-2x" style="color: #0951A4"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Financial Analysis</h5>
                <a
                  routerLink="/financial-analysis"
                  routerLinkActive="active"
                  href="#"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i class="far fa-globe fa-2x" style="color: #0951A4"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Global Report</h5>
                <a
                  routerLink="/global-report"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>
          </div>
          <div
            class="row justify-content-center align-items-center"
            style="gap: 3rem"
          >
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i class="far fa-sack-dollar fa-2x" style="color: #0951A4"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Direct Tax Services</h5>
                <a
                  routerLink="/direct-tax-services"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>

            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i class="far fa-sack-dollar fa-2x" style="color: #0951A4"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Indirect Tax Service</h5>
                <a
                  routerLink="/indirect-tax-services"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i
                  class="fas fa-balance-scale-right fa-2x"
                  style="color: #0951A4"
                ></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Corporate Law Compliance</h5>
                <a
                  routerLink="/corporate-law-compliance"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i
                  class="far fa-money-check-alt fa-2x"
                  style="color: #0951A4"
                ></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">
                  Payroll – Compensation And Benefit Management
                </h5>
                <a
                  routerLink="/payroll-compensation-and-benefit-management"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div
          class="row mb-100 justify-content-center align-items-center"
          style="gap: 3rem"
        >
          <div
            class="card justify-content-center align-items-center shadow border-0"
            style="width: 15rem"
          >
            <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
              <i class="fas fa-search fa-2x" style="color: #0951A4"></i>
            </div>
            <div class="card-body text-center">
              <h5 style="font-weight: 600">
                Business Set Up & Corporate Structuring
              </h5>
              <a routerLink="/business-setup-and-corporate-structuring"
              routerLinkActive="active"  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;">Know More</a>
            </div>
          </div>

          <div
            class="card justify-content-center align-items-center shadow border-0"
            style="width: 15rem"
          >
            <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
              <i class="far fa-globe fa-2x" style="color: #0951A4"></i>
            </div>
            <div class="card-body text-center">
              <h5 style="font-weight: 600">Global Compliance Management</h5>
              <a routerLink="/global-compliance-management"
              routerLinkActive="active"  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;">Know More</a>
            </div>
          </div>
          <div
            class="card justify-content-center align-items-center shadow border-0"
            style="width: 15rem"
          >
            <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
              <i class="fas fa-search fa-2x" style="color: #0951A4"></i>
            </div>
            <div class="card-body text-center">
              <h5 style="font-weight: 600">
                CFO Services & Debit Syndication
              </h5>
              <a routerLink="/cfo-service-and-debit-syndication"
              routerLinkActive="active"  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;">Know More</a>
            </div>
          </div>
          <div
            class="card justify-content-center align-items-center shadow border-0"
            style="width: 15rem"
          >
            <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
              <i class="far fa-globe fa-2x" style="color: #0951A4"></i>
            </div>
            <div class="card-body text-center">
              <h5 style="font-weight: 600">FEMA Advisory</h5>
              <a routerLink="/fema-advisory"
              routerLinkActive="active"  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;">Know More</a>
            </div>
          </div>
        </div>
      </div> 
      
    </div>
  </div>
</div>
<!-- end India -->

<!-- start USA -->
<div *ngIf="selectCountryName == 'USA'"
  class="min-vh-100 mt-160 container d-flex flex-column justify-content-center align-items-center"
>
  <div class="justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      FINANCIAL SERVICES
    </h2>
  </div>
  <div class="mt-50">
    <ul
      class="nav nav-tabs-india justify-content-around border-0"
      id="myTab"
      role="tablist"
      style="gap: 5rem; margin-bottom: 20px !important;"
    >
      <li class="nav-item-india" role="presentation">
        <button
          class="nav-link-india active btn-dgn1 "
          id="home-tab"
          data-toggle="tab"
          data-target="#home"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          
        >
          F & A Service
        </button>
      </li>
      <li class="nav-item-india" role="presentation">
        <button
          class="nav-link-india btn-dgn2"
          id="profile-tab"
          data-toggle="tab"
          data-target="#profile"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
          
        >
          Global Structuring & Advisory
        </button>
      </li>
    </ul>
    <div class="tab-content mt-50" id="myTabContent">
      <div
        class="tab-pane fade show active col"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="col">
          <div
            class="row mb-100 justify-content-center align-items-center"
            style="gap: 3rem"
          >
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i class="fas fa-search fa-2x" style="color: #0951A4"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Source To Pay</h5>
                <a
                  routerLink="/source-to-pay"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>

            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i class="far fa-globe fa-2x" style="color: #0951A4"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Order To Cash</h5>
                <a
                  routerLink="/order-to-cash"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i class="fas fa-search fa-2x" style="color: #0951A4"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Financial Analysis</h5>
                <a
                  routerLink="/financial-analysis"
                  routerLinkActive="active"
                  href="#"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i class="far fa-globe fa-2x" style="color: #0951A4"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Global Report</h5>
                <a
                  routerLink="/global-report"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>
          </div>
          <div
            class="row justify-content-center align-items-center"
            style="gap: 3rem"
          >
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i class="far fa-sack-dollar fa-2x" style="color: #0951A4"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Direct Tax Services</h5>
                <a
                  routerLink="/direct-tax-services"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>

            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i class="far fa-sack-dollar fa-2x" style="color: #0951A4"></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Indirect Tax Service</h5>
                <a
                  routerLink="/indirect-tax-services"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i
                  class="fas fa-balance-scale-right fa-2x"
                  style="color: #0951A4"
                ></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">Corporate Law Compliance</h5>
                <a
                  routerLink="/corporate-law-compliance"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>
            <div
              class="card justify-content-center align-items-center shadow border-0"
              style="width: 15rem"
            >
              <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
                <i
                  class="far fa-money-check-alt fa-2x"
                  style="color: #0951A4"
                ></i>
              </div>
              <div class="card-body text-center">
                <h5 style="font-weight: 600">
                  Payroll – Compensation And Benefit Management
                </h5>
                <a
                  routerLink="/payroll-compensation-and-benefit-management"
                  routerLinkActive="active"
                  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;"
                  >Know More</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div
          class="row mb-100 justify-content-center align-items-center"
          style="gap: 3rem"
        >
          <div
            class="card justify-content-center align-items-center shadow border-0"
            style="width: 15rem"
          >
            <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
              <i class="fas fa-search fa-2x" style="color: #0951A4"></i>
            </div>
            <div class="card-body text-center">
              <h5 style="font-weight: 600">
                Business Set Up & Corporate Structuring
              </h5>
              <a routerLink="/business-setup-and-corporate-structuring"
              routerLinkActive="active"  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;">Know More</a>
            </div>
          </div>

          <div
            class="card justify-content-center align-items-center shadow border-0"
            style="width: 15rem"
          >
            <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
              <i class="far fa-globe fa-2x" style="color: #0951A4"></i>
            </div>
            <div class="card-body text-center">
              <h5 style="font-weight: 600">Global Compliance Management</h5>
              <a routerLink="/global-compliance-management"
              routerLinkActive="active"  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;">Know More</a>
            </div>
          </div>
          <div
            class="card justify-content-center align-items-center shadow border-0"
            style="width: 15rem"
          >
            <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
              <i class="fas fa-search fa-2x" style="color: #0951A4"></i>
            </div>
            <div class="card-body text-center">
              <h5 style="font-weight: 600">
                CFO Services & Debit Syndication
              </h5>
              <a routerLink="/cfo-service-and-debit-syndication"
              routerLinkActive="active"  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;">Know More</a>
            </div>
          </div>
          <div
            class="card justify-content-center align-items-center shadow border-0"
            style="width: 15rem"
          >
            <div class="icon shadow-sm mt-10" style="background: #0951a43b;">
              <i class="far fa-globe fa-2x" style="color: #0951A4"></i>
            </div>
            <div class="card-body text-center">
              <h5 style="font-weight: 600">FEMA Advisory</h5>
              <a routerLink="/fema-advisory"
              routerLinkActive="active"  class="cardBtn text-white mt-40 mb-30" style="background: #0951A4;">Know More</a>
            </div>
          </div>
        </div>
      </div> 
      
    </div>
  </div>
</div>
<!-- end USA -->