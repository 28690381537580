import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-erp-consultancy',
  templateUrl: './erp-consultancy.component.html',
  styleUrls: ['./erp-consultancy.component.css']
})
export class ErpConsultancyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
