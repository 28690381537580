import { Component, HostListener, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ContactModalComponent } from '../../dialog/contact-modal/contact-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TestimonialService } from 'src/app/services/testimonial.service';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { ContactFormService } from 'src/app/services/contact-form.service';
import { CaseStudiesService } from 'src/app/services/case-studies.service';
import { BlogsNewsService } from 'src/app/services/blogs-news.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CustomCaptcha } from 'src/app/services/custom-captcha';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
import { CareerService } from 'src/app/services/career.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css'],
})
export class CareersComponent implements OnInit {
  image;
  testimonialRes;
  result;
  selectedIndex;
  inqForm: FormGroup;
  subjectData;
  resultFalse = false;
  resultTrue = false;
  caseStudiesResult;
  caseStudiesRes;
  blogList: any;
  ckEditorRes: any;
  resultOfTestimonial;
  submitted = false;
  _subs;
  validCaptcha = false;
  defaultCountry: string;
  selectCountryName: any;
  selectedFlag: string;
  positionName: any;
  windowSize: any;
  isMobileView!: boolean;
  selected_visa: string = 'N/A';
  selected_yoexp: string = 'Select an option';
  selected_noticePeriod: string = 'Select an option';
  dropdownOpen = false;
  dropdownOpenYoExp = false;
  currentDiv: string = 'v-pills-job-0';
  uploadedFile: File;
  countryCode: any;
  dropdownOpennoticePeriod = false;
  jobList: any;
  selectedJob: any
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    public dialog: MatDialog,
    private _testimonialService: TestimonialService,
    private _contactFormService: ContactFormService,
    private formBuilder: FormBuilder,
    private _caseStudiesService: CaseStudiesService,
    private _blogsNewsService: BlogsNewsService,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private _customCaptcha: CustomCaptcha,
    private _careerService: CareerService,
    private LocalStorageServiceService: LocalStorageServiceService
  ) {}

  ngOnInit(): void {
    this.getBlogList();
    this.testimonials();
    this.checkIfMobileView();
    this.defaultCountry = 'USA';

    this.selectCountryName = localStorage.getItem('selectedCountry');

    if (this.selectCountryName == null) {
      localStorage.setItem('selectedCountry', this.defaultCountry);
      window.location.reload();
    } else {
      this.selectCountryName = localStorage.getItem('selectedCountry');
    }
    this.positionName = 'Any Position Available';
    if (this.selectCountryName === 'Dubai') {
      this.countryCode = '+971 ';
    } else if (this.selectCountryName === 'India') {
      this.countryCode = '+91 ';
    } else if (this.selectCountryName === 'USA') {
      this.countryCode = '+1 ';
    } else {
      this.countryCode = '+91 ';
    }
    console.log('THIS IS COUNTRY CODE');
    console.log(this.countryCode);
    this.contactForm();
    this.fetchJobs();
  }

  selectCountry(country: string): void {
    // Update the selectedFlag with the corresponding image path
    if (country === 'Dubai') {
      this.selectedFlag =
        '/assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png';
      this.countryCode = '+971';
    } else if (country === 'India') {
      this.selectedFlag = '/assets/images/flag/download.png';
      this.countryCode = '+91';
    } else if (country === 'USA') {
      this.selectedFlag = '/assets/images/flag/USA.png';
      this.countryCode = '+1';
    } else {
      // Set USA as the default country
      this.selectedFlag = '/assets/images/flag/USA.png';
      country = 'USA';
      this.countryCode = '+91';
    }
    console.log('THIS IS COUNTRY CODE');
    console.log(this.countryCode);
    this.LocalStorageServiceService.setItem('selectedCountry', country);
  }

  activateTab(tabId: string) {
    const tabElement = document.getElementById(tabId);
    if (tabElement) {
      tabElement.classList.add('show', 'active');
    }
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.checkIfMobileView();
    this.windowSize = event.target.innerWidth;
    this.getHeightOfElement()
  }

  checkIfMobileView() {
    if (window.innerWidth < 991) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }

    console.log(this.isMobileView);
  }

  get f() {
    return this.inqForm.controls;
  }

  setIndex(index: number) {
    this.selectedIndex = index;
  }

  ngAfterViewInit(): void {
    this._customCaptcha.createCaptcha();
    this.caseStudies();
    this.getHeightOfElement()
    //testimonal script
    var testimTimer,
      touchStartPos,
      touchEndPos,
      touchPosDiff,
      testim = document.getElementById('testim1'),
      testimDots = Array.prototype.slice.call(
        document.getElementById('testim-dots1').children
      ),
      testimContent = Array.prototype.slice.call(
        document.getElementById('testim-content1').children
      ),
      testimLeftArrow = document.getElementById('left-arrow'),
      testimRightArrow = document.getElementById('right-arrow'),
      testimSpeed = 4500,
      currentSlide = 0,
      currentActive = 0,
      ignoreTouch = 30;
    function i(t) {
      for (var e = 0; e < testimDots.length; e++)
        testimContent[e].classList.remove('active'),
          testimContent[e].classList.remove('inactive'),
          testimDots[e].classList.remove('active');
      t < 0 && (t = currentSlide = testimContent.length - 1),
        t > testimContent.length - 1 && (t = currentSlide = 0),
        currentActive != currentSlide &&
          testimContent[currentActive].classList.add('inactive'),
        testimContent[t].classList.add('active'),
        testimDots[t].classList.add('active'),
        (currentActive = currentSlide),
        clearTimeout(testimTimer),
        (testimTimer = setTimeout(function () {
          i((currentSlide += 1));
        }, testimSpeed));
    }
    testimLeftArrow.addEventListener('click', function () {
      i(--currentSlide);
    }),
      testimRightArrow.addEventListener('click', function () {
        i((currentSlide += 1));
      });
    for (var t = 0; t < testimDots.length; t++)
      testimDots[t].addEventListener('click', function () {
        i((currentSlide = testimDots.indexOf(this)));
      });
    i(currentSlide),
      document.addEventListener('keyup', function (t) {
        switch (t.keyCode) {
          case 37:
            testimLeftArrow.click();
            break;
          case 39:
          case 39:
            testimRightArrow.click();
        }
      }),
      testim.addEventListener('touchstart', function (t) {
        touchStartPos = t.changedTouches[0].clientX;
      }),
      testim.addEventListener('touchend', function (t) {
        if (
          ((touchEndPos = t.changedTouches[0].clientX),
          (touchPosDiff = touchStartPos - touchEndPos),
          console.log(touchPosDiff),
          console.log(touchStartPos),
          console.log(touchEndPos),
          0 + ignoreTouch < touchPosDiff)
        )
          testimLeftArrow.click();
        else {
          if (!(touchPosDiff < 0 - ignoreTouch)) return;
          testimRightArrow.click();
        }
      });
  }

  caseStudies() {
    this._caseStudiesService.caseStudies().subscribe((res) => {
      this.caseStudiesRes = res;
      this.caseStudiesResult = this.caseStudiesRes.data;
    });
  }

  testimonials() {
    this._testimonialService.testimonialData().subscribe((res) => {
      this.testimonialRes = res;
      this.resultOfTestimonial = this.testimonialRes.data;
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(ContactModalComponent, {
      width: '555px',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  caseDetails(id) {
    this.router.navigate(['/case-detail', id], {
      relativeTo: this._activatedRoute,
    });
  }

  getBlogList() {
    this._blogsNewsService.blogList().subscribe((res) => {
      this.result = res;
      this.blogList = this.result.data;
    });
  }

  blogDetails(id) {
    this.router.navigate(['/news-and-blogs/details', id], {
      relativeTo: this._activatedRoute,
    });
  }

  reCaptcha() {
    this._customCaptcha.createCaptcha();
  }

  contactForm() {
    this.inqForm = this.formBuilder.group({
      name: ['', Validators.required],
      // company_name:[''],
      mobile: [
        `${this.countryCode}`,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[(+0-9) 0-9 -]+$'),
        ]),
      ],
      email_id: [
        '',
        Validators.compose([Validators.required, Validators.email]),
      ],
      yoexp: [this.selected_yoexp, Validators.required],
      highest_qualification: [''],
      // gender:['',Validators.required],
      // dob: ['', Validators.required],
      // nationality:['',Validators.required],
      visa_status: [this.selected_visa],
      expires_on: [''],
      notice_period: [''],
      // previous_salary:[''],
      expected_salary: [''],
      reason_to_join: [''],
      current_location: ['', Validators.required],
      upload_cv: ['', Validators.required],
      recaptchaReactive: ['', Validators.required],
    });
  }
  selectOption(option: string): void {
    this.selected_visa = option;
    this.inqForm.value.visa_status = this.selected_visa;
    this.inqForm.patchValue({
      visa_status: option,
    });
    this.dropdownOpen = false;
  }
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectOptionYoExp(option: string): void {
    this.selected_yoexp = option;
    this.inqForm.value.visa_status = this.selected_yoexp;
    this.inqForm.patchValue({
      yoexp: option,
    });
    this.dropdownOpenYoExp = false;
  }
  toggleDropdownYoExp() {
    this.dropdownOpenYoExp = !this.dropdownOpenYoExp;
  }

  selectOptionNoticePeriod(option: string): void {
    this.selected_noticePeriod = option;
    this.inqForm.value.visa_status = this.selected_noticePeriod;
    this.inqForm.patchValue({
      notice_period: option,
    });
    this.dropdownOpennoticePeriod = false;
  }
  toggleDropdownNoticePeriod() {
    this.dropdownOpennoticePeriod = !this.dropdownOpennoticePeriod;
  }

  onSubmit() {
    this.submitted = true;
    if (this.positionName == undefined) {
      alert('Position undefined');
    }
    console.log(this.inqForm);
    if (this.inqForm.invalid) {
      console.log('FORM INVALID');
      console.log(this.inqForm);
      return;
    } else if (
      this.inqForm.value.recaptchaReactive !=
      this._customCaptcha.validateCaptcha()
    ) {
      alert('Invalid Captcha. try Again');
      return;
    } else {
      const formData = new FormData();
      formData.append('position', this.positionName);
      console.log(this.positionName);
      formData.append('name', this.inqForm.value.name || ' ');
      console.log(this.inqForm.value.name);
      formData.append(
        'highest_qualification',
        this.inqForm.value.highest_qualification || ' '
      );
      console.log(this.inqForm.value.highest_qualification);
      // formData.append('company_name', this.inqForm.value.company_name || ' ');
      // console.log(this.inqForm.value.company_name);
      formData.append('mobile', this.inqForm.value.mobile || ' ');
      console.log('MOBILE NO BELOW');
      console.log(this.inqForm.value.mobile);
      formData.append('email_id', this.inqForm.value.email_id || ' ');
      console.log(this.inqForm.value.email_id);
      formData.append('yoexp', this.inqForm.value.yoexp || ' ');
      console.log(this.inqForm.value.yoexp);
      // formData.append('gender', this.inqForm.value.gender || ' ');
      // console.log(this.inqForm.value.gender);
      // formData.append('dob', this.inqForm.value.dob || ' ');
      // console.log(this.inqForm.value.dob);
      // formData.append('nationality', this.inqForm.value.nationality || ' ');
      // console.log(this.inqForm.value.nationality);
      formData.append('visa_status', this.inqForm.value.visa_status || ' ');
      console.log(this.inqForm.value.visa_status);
      formData.append('expires_on', this.inqForm.value.expires_on || ' ');
      console.log(this.inqForm.value.expires_on);
      formData.append('notice_period', this.inqForm.value.notice_period || ' ');
      console.log(this.inqForm.value.notice_period);
      // formData.append('previous_salary', this.inqForm.value.previous_salary || ' ');
      // console.log(this.inqForm.value.previous_salary);
      formData.append(
        'expected_salary',
        this.inqForm.value.expected_salary || ' '
      );
      console.log(this.inqForm.value.expected_salary);
      formData.append(
        'reason_to_join',
        this.inqForm.value.reason_to_join || ' '
      );
      console.log(this.inqForm.value.reason_to_join);
      formData.append(
        'current_location',
        this.inqForm.value.current_location || ' '
      );
      console.log(this.inqForm.value.current_location);
      formData.append('upload_cv', this.uploadedFile);
      console.log(this.uploadedFile);
      console.log('Form submitted');
      console.log(this.inqForm);
      console.log(formData);
      // return
      this._careerService.careerDetails(formData).subscribe((res) => {
        let response = res;
        console.log(res);
        if (response['result'] == 'False') {
          this.resultFalse = true;
          this.resultTrue = false;
        } else {
          this.resultFalse = false;
          this.resultTrue = true;
          // this.inqForm.reset();
          this._customCaptcha.createCaptcha();
          this.submitted = false;
        }
      });
    }
  }
  onFileUpload($event: any) {
    if ($event.target.files && $event.target.files[0]) {
      const file = $event.target.files[0];
      console.log(file);
      // console.log(this.f);
      if (
        file.type == 'application/pdf' ||
        file.type == 'application/msword' ||
        file.type ==
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        this.uploadedFile = file;
        console.log(this.uploadedFile)
      } else {
        //call validation
        // this.inqForm.controls["fileInput"].setValidators([Validators.required]);
        this.inqForm.controls.fileInput.setValue(null);
        this.inqForm.controls.fileInput.setErrors({ invalidFileType: true });
        this.inqForm.get('fileInput').updateValueAndValidity();
      }
    }
  }
  captchaResolved(captchaResponse: string) {
    if (!captchaResponse) {
      this.validCaptcha = false;
    } else {
      this.validCaptcha = true;
    }
    this.inqForm.patchValue({
      recaptchaReactive: this.validCaptcha,
    });
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  gotojob(jobid: any) {
    this.router.navigateByUrl('careers#' + jobid);
  }

  scrollToDiv( job: any) {
    this.selectedJob ={
      title: job.title,
      createdAt: job.createdAt,
      timingAndLocation: job.timingAndLocation,
      requirements: job.requirements,
      qualification: job.qualification
    } 
    console.log(this.selectedJob)
    const divToDisplay = 'v-pills-job-1'

    // Hide the currently displayed div
    const currentDivElement = document.getElementById(this.currentDiv);
    if (currentDivElement) {
      currentDivElement.style.display = 'none';
    }

    // Show the new div
    const newDivElement = document.getElementById(divToDisplay);
    if (newDivElement) {
      newDivElement.style.display = 'block';
    }

    this.currentDiv = divToDisplay;
    console.log(this.currentDiv);
    const element = document.getElementById('career-info');
    if (element) {
      // element.scrollIntoView({ behavior: 'smooth' });
      const yOffset = -50; // Change this value to adjust the distance above the target div
      const yCoordinate =
        element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: yCoordinate, behavior: 'smooth' });
    }
    this.getHeightOfElement();
    
  }
  goToForm(job: any) {
    this.positionName = job.title;
    console.log(this.positionName);
    const divToDisplay = 'v-pills-job-0';
    const currentDivElement = document.getElementById(this.currentDiv);
    if (currentDivElement) {
      currentDivElement.style.display = 'none';
    }

    // Show the new div
    const newDivElement = document.getElementById(divToDisplay);
    if (newDivElement) {
      newDivElement.style.display = 'block';
    }

    this.currentDiv = divToDisplay;
    console.log(this.currentDiv);
    const element = document.getElementById('career-info');
    if (element) {
      // element.scrollIntoView({ behavior: 'smooth' });
      const yOffset = -50; // Change this value to adjust the distance above the target div
      const yCoordinate =
        element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: yCoordinate, behavior: 'smooth' });
    }
    this.getHeightOfElement();
  }
  getHeightOfElement() {
    const el = document.getElementById('tab-content')
    const careerList =  document.getElementById('career-list')
    if (el) {
      const height = el.offsetHeight;
      if(height < 50){
        careerList.style.height = `620px`;
      }else{
        careerList.style.height = `${height}px`;
      }
      console.log(careerList.offsetHeight)
    }
  }
  optionsUSA: string[] = [
    'N/A',
    'Business',
    'F visa',
    'H-1B visa',
    'H-2B visa',
    'Work Permit',
    'Other',
  ];
  optionsUAE: string[] = [
    'N/A',
    'Green visa',
    'Golden visa',
    'Freelancer visa',
    'Domestic worker visa',
    'Other',
  ];
  optionsYoExp: string[] = [
    '0 - 3 years',
    '4 - 7 years',
    '7 - 10 years',
    '10 years and above',
  ];
  optionsNoticePeriod: string[] = ['30 days', '45 days', '60 days', '90 days'];


 async fetchJobs(){
    const res = await fetch(`https://rich-tan-dhole-kilt.cyclic.app/api/jobs/`, {
          method: 'GET',
        });
        this.jobList = await res.json();
        console.log(this.jobList);
        console.log('JOBS LIST ABOVE')
  }

}
