<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center banner-image bmTop"
      style="background: url('../../../../assets/images/service/Accounting.png')  no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Accounting & Transaction Processing
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                  <div class="col-md-4">
                    <div class="faq-accordion-3 pt-0">
                      <div class="accordion" id="accordionExample1">
                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                      <div class="card-header" id="headingSev" >
                        <a  href="#accounting-and-transition-processing" data-toggle="collapse" data-target="#collapseSevv"
                        aria-expanded="true" aria-controls="collapseSevv" >
                          Accounting & Transaction Processing
                        </a>
                      </div>
                      <div id="collapseSevv" class="collapse show" aria-labelledby="headingSev" data-parent="#accordionExample1">
                        <div class="card-body bg-white">
                          <div class="list-group">
                            <ul class="text-dark serviceul">

                              <li  routerLink="/accounts-payable">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Accounts Payable
                              </li>
                              <li class="shli" routerLink="/accounts-receivable">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Accounts Receivable
                              </li>
                              <li routerLink="/fixed-assets-accounting">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Fixed Assets Accounting
                              </li>
                              <li routerLink="/financial-reporting">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Financial Reporting
                              </li>
                              <li routerLink="/budgeting-and-variance-analysis">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Budgeting & Variance Analysis
                              </li>
                              <li routerLink="/reconciliation-services">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Reconciliation Services
                              </li>
                              <li routerLink="/consolidation-reporting-gaap-conversion">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                 Consolidation Reporting & GAAP Conversion
                              </li>
                              <li routerLink="/small-business-accounting">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Small Business Accounting
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->
                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                      <div class="card-header" id="headingEight">
                        <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                          aria-expanded="false" aria-controls="collapseEight">
                          <span (click)="onTax()">Tax & Regulatory Compliances</span>
                        </a>
                      </div>
                      <div id="collapseEight" class="collapse " aria-labelledby="headingEight" data-parent="#accordionExample1">
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark serviceul">
                              <li  routerLink="/individual-tax-return">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Individual Tax Return
                              </li>
                              <li  routerLink="/corporate-tax-return">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Corporate Tax Return
                              </li>
                              <li routerLink="/sales-and-use-tax">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Sales & Use Tax
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- card -->
                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                      <div class="card-header" id="headingNine">
                        <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                          aria-expanded="false" aria-controls="collapseNine">
                          <span (click)="onPayroll()">Payroll Advisory</span>
                        </a>
                      </div>
                      <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark pt-4 serviceul">
                              <li routerLink="/payroll-processing">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                                Payroll Processing
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->

                    <!-- card -->
                    <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                      <div class="card-header" id="headingTen">
                        <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                          aria-expanded="false" aria-controls="collapseTen">
                          <span (click)="onConsulting()">Consulting & Advisory</span>
                        </a>
                      </div>
                      <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                        <div class="card-body bg-white">
                          <div>
                            <ul class="text-dark pt-4 serviceul">
                              <li routerLink="/business-setup">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Setting up an entity in the US
                              </li>
                              <li routerLink="/global-corporate-structuring">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Global Corporate Structuring
                              </li>
                              <li routerLink="/business-plan-preparation">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Business Plan Preparation
                              </li>
                              <li routerLink="/business-valuation-service">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               <a href="#business-valuation-service"> Business Valuation</a>
                              </li>
                              <li routerLink="/mergers-and-acquisitions">
                                <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Mergers and Acquisitions
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- card -->
                    </div>
                    </div>
                  </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3 id="accounts-receivable">Accounts Receivable</h3>
                    <p class="mb-15 mt-20 text-dark servicep">
                      At BFAG, we provide comprehensive services for managing accounts receivable, helping businesses maintain healthy cash flow and streamline their financial operations. Our accounts receivable services includes:
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                  Invoicing and Billing
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We handle the entire invoicing and billing process on behalf of our clients. This includes generating and sending out invoices to customers or clients, ensuring accuracy and timeliness.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                            <div class="card-header" id="headingTwelve">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                  Payment Processing
                                                </a>
                                            </div>
                                            <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Our team manages the payment processing aspect of accounts receivable. We collect and process payments from customers, using various methods such as credit cards, electronic transfers, and checks. We ensure that payments are recorded accurately and promptly.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingThirteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                  Accounts Reconciliation
                                                </a>
                                            </div>
                                            <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We perform regular accounts reconciliation to ensure that all payments received are properly matched with the corresponding invoices. This process helps identify any discrepancies or outstanding payments, allowing for timely resolution.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                          <div class="card-header" id="headingFourteen">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                Customer Relationship Management
                                              </a>
                                          </div>
                                          <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p>We prioritize maintaining strong relationships with your customers. Our team interacts with customers in a professional and courteous manner, addressing any inquiries or concerns related to invoices or payments promptly and efficiently.</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                          <div class="card-header" id="headingFifteen">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                Collection Services
                                              </a>
                                          </div>
                                          <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p>In cases where customers fail to make timely payments, we offer collection services to help recover outstanding debts. We employ ethical and legal methods to pursue payment, while also safeguarding the reputation and relationship between our clients and their customers.</p>
                                              </div>
                                          </div>
                                      </div>
                                        <!-- card -->
                                    </div>

                                    <div class="col-lg-6">

                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                            <div class="card-header" id="headingSixteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                  Reporting and Analytics
                                                </a>
                                            </div>
                                            <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We provide regular reports and analytics related to accounts receivable, giving our clients insight into their financial performance. These reports include key metrics such as outstanding balances, average collection periods, and aging reports, which can be utilized to make informed decisions and optimize cash flow.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                          <div class="card-header" id="headingSeventeen">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                                Integration and Automation
                                              </a>
                                          </div>
                                          <div id="collapseSeventeen" class="collapse " aria-labelledby="headingSeventeen" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p>Our accounts receivable services can be seamlessly integrated with existing accounting systems or software. We leverage automation tools and technologies to streamline processes, reduce manual errors, and enhance efficiency.</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                          <div class="card-header" id="headingEightteen">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEightteen" aria-expanded="false" aria-controls="collapseEightteen">
                                                Compliance and Security
                                              </a>
                                          </div>
                                          <div id="collapseEightteen" class="collapse " aria-labelledby="headingEightteen" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p>We prioritize data security and adhere to strict compliance standards. Our systems and processes are designed to protect sensitive financial information, ensuring confidentiality and privacy for our clients and their customers.</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                        <div class="card-header" id="headingNineteen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                                              Customized Solutions
                                            </a>
                                        </div>
                                        <div id="collapseNineteen" class="collapse " aria-labelledby="headingNineteen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p>We understand that each business is unique, and we tailor our services to meet specific requirements. Whether you have specific invoicing preferences, reporting needs, or industry-specific considerations, we work closely with our clients to provide customized solutions.</p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                  By availing our accounts receivable services, businesses can focus on core operations while entrusting the management of their financial transactions to a reliable and experienced partner. Our goal is to optimize cash flow, enhance customer relationships, and improve overall financial performance.
                </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>

<!-- india -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center banner-image bmTop"
      style="background: url('../../../../assets/images/service/Accounting.png')  no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Accounting & Transaction Processing
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                  <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev">
                      <a class="" href="" data-toggle="collapse" data-target="#collapseSev"
                        aria-expanded="true" aria-controls="collapseSev">
                        <span routerLink="/accounting-and-transition-processing">Accounting & Transaction Processing</span>
                      </a>
                    </div>
                    <div id="collapseSev" class="collapse show" aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li class="shli" routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#accounts-receivable"> Accounts Receivable</a>
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#fixed-assets-accounting"> Fixed Assets Accounting</a>
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              <a href="#budgeting-and-variance-analysis">Budgeting & Variance Analysis</a>
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Consolidation Reporting & GAAP Conversion
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li routerLink="/income-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Income Tax
                            </li>
                            <li  routerLink="/goods-and-service-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Goods & Service Tax
                            </li>
                            <li routerLink="/corporate-secretarial-compliance">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Secretarial Compliance
                            </li>
                            <li  routerLink="/fema-advisory">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              FEMA Compliance
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span (click)="onPayroll()">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Payroll - Compensation & Benefit Management
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span (click)="onConsulting()">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Setting up an entity in the India
                            </li>
                            <li routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Global Corporate Structuring
                            </li>
                            <li routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Plan Preparation
                            </li>
                            <li routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Valuation
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingElevenStartup">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseElevenStartup"
                        aria-expanded="false" aria-controls="collapseElevenStartup">
                        <span (click)="onStartup()">Startup Solution</span>
                      </a>
                    </div>
                    <div id="collapseElevenStartup" class="collapse" aria-labelledby="headingElevenStartup" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/startup-solution">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Startup Solution
                            </li>
                            <li routerLink="/fund-raising">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Fund Raise
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3 id="accounts-receivable">Accounts Receivable</h3>
                    <p class="mb-15 mt-20 text-dark servicep">
                      At BFAG, we provide comprehensive services for managing accounts receivable, helping businesses maintain healthy cash flow and streamline their financial operations. Our accounts receivable services includes:
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                  Invoicing and Billing
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We handle the entire invoicing and billing process on behalf of our clients. This includes generating and sending out invoices to customers or clients, ensuring accuracy and timeliness.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                            <div class="card-header" id="headingTwelve">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                  Payment Processing
                                                </a>
                                            </div>
                                            <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Our team manages the payment processing aspect of accounts receivable. We collect and process payments from customers, using various methods such as credit cards, electronic transfers, and checks. We ensure that payments are recorded accurately and promptly.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingThirteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                  Accounts Reconciliation
                                                </a>
                                            </div>
                                            <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We perform regular accounts reconciliation to ensure that all payments received are properly matched with the corresponding invoices. This process helps identify any discrepancies or outstanding payments, allowing for timely resolution.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                          <div class="card-header" id="headingFourteen">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                Customer Relationship Management
                                              </a>
                                          </div>
                                          <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p>We prioritize maintaining strong relationships with your customers. Our team interacts with customers in a professional and courteous manner, addressing any inquiries or concerns related to invoices or payments promptly and efficiently.</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                          <div class="card-header" id="headingFifteen">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                Collection Services
                                              </a>
                                          </div>
                                          <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p>In cases where customers fail to make timely payments, we offer collection services to help recover outstanding debts. We employ ethical and legal methods to pursue payment, while also safeguarding the reputation and relationship between our clients and their customers.</p>
                                              </div>
                                          </div>
                                      </div>
                                        <!-- card -->
                                    </div>

                                    <div class="col-lg-6">

                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                            <div class="card-header" id="headingSixteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                  Reporting and Analytics
                                                </a>
                                            </div>
                                            <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We provide regular reports and analytics related to accounts receivable, giving our clients insight into their financial performance. These reports include key metrics such as outstanding balances, average collection periods, and aging reports, which can be utilized to make informed decisions and optimize cash flow.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                          <div class="card-header" id="headingSeventeen">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                                Integration and Automation
                                              </a>
                                          </div>
                                          <div id="collapseSeventeen" class="collapse " aria-labelledby="headingSeventeen" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p>Our accounts receivable services can be seamlessly integrated with existing accounting systems or software. We leverage automation tools and technologies to streamline processes, reduce manual errors, and enhance efficiency.</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                          <div class="card-header" id="headingEightteen">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEightteen" aria-expanded="false" aria-controls="collapseEightteen">
                                                Compliance and Security
                                              </a>
                                          </div>
                                          <div id="collapseEightteen" class="collapse " aria-labelledby="headingEightteen" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p>We prioritize data security and adhere to strict compliance standards. Our systems and processes are designed to protect sensitive financial information, ensuring confidentiality and privacy for our clients and their customers.</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                        <div class="card-header" id="headingNineteen">
                                            <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                                              Customized Solutions
                                            </a>
                                        </div>
                                        <div id="collapseNineteen" class="collapse " aria-labelledby="headingNineteen" data-parent="#accordionExample2">
                                            <div class="card-body text-justify" style="padding-left:20px !important">
                                                <p>We understand that each business is unique, and we tailor our services to meet specific requirements. Whether you have specific invoicing preferences, reporting needs, or industry-specific considerations, we work closely with our clients to provide customized solutions.</p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                  By availing our accounts receivable services, businesses can focus on core operations while entrusting the management of their financial transactions to a reliable and experienced partner. Our goal is to optimize cash flow, enhance customer relationships, and improve overall financial performance.
                </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>
<!-- india ends -->
