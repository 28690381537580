<!-- dubai start -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      SOURCE TO PAY
    </h2>
    <small class="mt-10" style="font-size: 10px;"><a routerLink="/financial-services" class="text-dark border-0" href="">HOME <i class="fas fa-arrow-circle-right"></i> SOURCE TO PAY</a></small>
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/source to pay.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 14rem"
        />
        <a class="imgBtn btn"><i class="far fa-chevron-right" style="color: white;"></i></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Our Source to Pay solution offers end-to-end management of accounts
        payables. We blend technology with personnel to provide a comprehensive
        solution that helps improve cash management and realize savings that
        positively affect the bottom-line.
      </p>
      <div>
        <h5 class="mb-20" style="color: green;">
          OUR SOLUTIONS INCLUDES TO HANDLE THE FOLLOWING ACTIVITIES:
        </h5>
        <ul class="mt-10" style="line-height: 2rem;">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Centralized management of accounts payable
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Spend analysis
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Document tracking
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Workflows to handle payment cycles
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Monitoring agreements
          </li>
        </ul>
        <h5 class="mb-20 mt-50" style="color: green">BENEFITS:</h5>
        <ul style="line-height: 2rem;">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Manage all aspects of accounts payable using a single centralized
            solution
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Favorably impact the bottom line by making efficient use of cash and maintaining control over payment cycles
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
            Always stay informed by accessing any information of choice on your personalized dashboard
          </li>
        </ul>
      </div>
    </div>
  </div>
</div> 
<!-- dubai end -->

<!-- India start -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      SOURCE TO PAY
    </h2>
    <small class="mt-10" style="font-size: 10px;"><a routerLink="/financial-services" class="text-dark border-0" href="">HOME <i class="fas fa-arrow-circle-right"></i> SOURCE TO PAY</a></small>
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/source to pay.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 14rem"
        />
        <!-- <a class=".imgBtn-india btn"><i class="far fa-chevron-right" style="color: white;"></i></a> -->
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Our Source to Pay solution offers end-to-end management of accounts
        payables. We blend technology with personnel to provide a comprehensive
        solution that helps improve cash management and realize savings that
        positively affect the bottom-line.
      </p>
      <div>
        <h5 class="mb-20" style="color: #0951A4;">
          OUR SOLUTIONS INCLUDES TO HANDLE THE FOLLOWING ACTIVITIES:
        </h5>
        <ul class="mt-10" style="line-height: 2rem;">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Centralized management of accounts payable
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Spend analysis
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Document tracking
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Workflows to handle payment cycles
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Monitoring agreements
          </li>
        </ul>
        <h5 class="mb-20 mt-50" style="color: #0951A4">BENEFITS:</h5>
        <ul style="line-height: 2rem;">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Manage all aspects of accounts payable using a single centralized
            solution
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Favorably impact the bottom line by making efficient use of cash and maintaining control over payment cycles
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Always stay informed by accessing any information of choice on your personalized dashboard
          </li>
        </ul>
      </div>
    </div>
  </div>
</div> 
<!-- India end -->

<!-- India start -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      SOURCE TO PAY
    </h2>
    <small class="mt-10" style="font-size: 10px;"><a routerLink="/financial-services" class="text-dark border-0" href="">HOME <i class="fas fa-arrow-circle-right"></i> SOURCE TO PAY</a></small>
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 460px; height: 100px"
      >
        <img
          src="../../../../assets/images/financial service/source to pay.png"
          class="img-fluid"
          style="max-width: 400px; position: relative; top: 14rem"
        />
        <a class=".imgBtn-india btn"><i class="far fa-chevron-right" style="color: white;"></i></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Our Source to Pay solution offers end-to-end management of accounts
        payables. We blend technology with personnel to provide a comprehensive
        solution that helps improve cash management and realize savings that
        positively affect the bottom-line.
      </p>
      <div>
        <h5 class="mb-20" style="color: #0951A4;">
          OUR SOLUTIONS INCLUDES TO HANDLE THE FOLLOWING ACTIVITIES:
        </h5>
        <ul class="mt-10" style="line-height: 2rem;">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Centralized management of accounts payable
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Spend analysis
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Document tracking
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Workflows to handle payment cycles
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Monitoring agreements
          </li>
        </ul>
        <h5 class="mb-20 mt-50" style="color: #0951A4">BENEFITS:</h5>
        <ul style="line-height: 2rem;">
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Manage all aspects of accounts payable using a single centralized
            solution
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Favorably impact the bottom line by making efficient use of cash and maintaining control over payment cycles
          </li>
          <li class="mb-2">
            <i class="fas fa-arrow-circle-right" style="color: #0951A4"></i>
            Always stay informed by accessing any information of choice on your personalized dashboard
          </li>
        </ul>
      </div>
    </div>
  </div>
</div> 
<!-- India end -->