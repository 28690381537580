<!-- dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center  text-uppercase"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      Accounting for the Accountants 
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0 text-uppercase" routerLink="/cpa-solution"
        >Home <i class="fas fa-arrow-circle-right"></i> Accounting For The
        Accountants</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 justify-content-center align-items-center imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 420px; height: 100px"
      >
        <img
          src="../../../../assets/images/cpa solutions/accounts.png"
          class="img-fluid"
          style="max-width: 350px; position: relative; top: 12rem"
        />
        <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        As a financial and tax strategist, a certified public accountant helps
        chart financial strategies for businesses, manage regulatory compliance,
        and optimize taxation while ensuring that books of accounts are up to
        date at all times. At Boston Financial, we do all the routine tasks and
        much more to ensure that you focus your energies on activities that are
        higher in the value chain.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Our primary aim is to provide efficient and quality support to CPAs for
        bookkeeping and accounting, book close, reconciliations, periodic
        reporting, financial statement preparation, etc., which ensures that you
        are left with more time to provide value-added services.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        This enables you to have a quantum leap in client retention and
        increased revenue from your clients along with overall cost reduction.
      </p>
    </div>
  </div>
  <div class="col mt-70">
    <div style="margin-bottom: 45px !important">
      <h3
        class="text-center"
        style="border-bottom: 3px solid #21ac51; display: inline-block"
      >
        OFFERING
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10">
            <li
              class="mb-2 text-dark"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: green"></i>
              Bookkeeping and Accounting
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: green"></i>
              AR / AP / Bank Reconciliation
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: green"></i>
              GL Maintenance
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: green"></i>
              Expense Accounting and Reporting
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: green"></i>
              Fixed Asset Accounting
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: green"></i>
              Financial Statement Compilation & Review
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/cpa solutions/accounts.png"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70 mb-100">
    <div class="d-flex justify-content-end" style="margin-bottom: 45px !important">
      
    </div>
    <div class="row">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/cpa solutions/BFAG advantage.png"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0">
        <div>
          <h3
        class="text-center"
        style="border-bottom: 3px solid #21ac51; display: inline-block;"
      >
        THE BFAG ADVANTAGE
      </h3>
          <p class="text-dark" style="font-size: 20px; margin-top: 1.5rem; margin-bottom: 1rem;">
            We ensure that we deliver high quality and on time. Our back office
            in India enables us to complete the work while you are asleep. In
            addition, we guarantee the following:
          </p>
          <ul class="mt-10">
            <li
              class="mb-2 text-dark"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: green"></i>
              Customized and scalable solutions to provide you  exactly what you
              need
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: green"></i>
              Data security and confidentiality measures at par with the
              Industry
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: green"></i>
              Systems and processes customized to reduce the on-boarding time
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: green; text-align: justify;"></i>
              Freedom from hiring and retaining qualified and   experienced
              resources
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: green"></i>
              Significant savings on payroll and associated costs  in addition to
              zero investment in infrastructure
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: green"></i>
              Financial Statement Compilation & Review
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- dubai end -->

<!-- india started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center  text-uppercase"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      Accounting for the Accountants
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0 text-uppercase" routerLink="/cpa-solution"
        >Home <i class="fas fa-arrow-circle-right"></i> Accounting For The
        Accountants</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 justify-content-center align-items-center imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 420px; height: 100px"
      >
        <img
          src="../../../../assets/images/cpa solutions/accounts.png"
          class="img-fluid"
          style="max-width: 350px; position: relative; top: 12rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        As a financial and tax strategist, a certified public accountant helps
        chart financial strategies for businesses, manage regulatory compliance,
        and optimize taxation while ensuring that books of accounts are up to
        date at all times. At Boston Financial, we do all the routine tasks and
        much more to ensure that you focus your energies on activities that are
        higher in the value chain.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Our primary aim is to provide efficient and quality support to CPAs for
        bookkeeping and accounting, book close, reconciliations, periodic
        reporting, financial statement preparation, etc., which ensures that you
        are left with more time to provide value-added services.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        This enables you to have a quantum leap in client retention and
        increased revenue from your clients along with overall cost reduction.
      </p>
    </div>
  </div>
  <div class="col mt-70">
    <div style="margin-bottom: 45px !important">
      <h3
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
        OFFERING
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10">
            <li
              class="mb-2 text-dark"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Bookkeeping and Accounting
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              AR / AP / Bank Reconciliation
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              GL Maintenance
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Expense Accounting and Reporting
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Fixed Asset Accounting
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Financial Statement Compilation & Review
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/cpa solutions/accounts.png"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70 mb-100">
    <div class="d-flex justify-content-end" style="margin-bottom: 45px !important">
      
    </div>
    <div class="row">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/cpa solutions/BFAG advantage.png"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0">
        <div>
          <h3
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block;"
      >
        THE BFAG ADVANTAGE
      </h3>
          <p class="text-dark" style="font-size: 20px; margin-top: 1.5rem; margin-bottom: 1rem;">
            We ensure that we deliver high quality and on time. Our back office
            in India enables us to complete the work while you are asleep. In
            addition, we guarantee the following:
          </p>
          <ul class="mt-10">
            <li
              class="mb-2 text-dark"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Customized and scalable solutions to provide you  exactly what you
              need
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Data security and confidentiality measures at par with the
              Industry
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Systems and processes customized to reduce the on-boarding time
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: #0951A4; text-align: justify;"></i>
              Freedom from hiring and retaining qualified and   experienced
              resources
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Significant savings on payroll and associated costs  in addition to
              zero investment in infrastructure
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Financial Statement Compilation & Review
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- india end -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center  text-uppercase"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      Accounting for the Accountants
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0 text-uppercase" routerLink="/cpa-solution"
        >Home <i class="fas fa-arrow-circle-right"></i> Accounting For The
        Accountants</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 justify-content-center align-items-center imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 420px; height: 100px"
      >
        <img
          src="../../../../assets/images/cpa solutions/accounts.png"
          class="img-fluid"
          style="max-width: 350px; position: relative; top: 12rem"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        As a financial and tax strategist, a certified public accountant helps
        chart financial strategies for businesses, manage regulatory compliance,
        and optimize taxation while ensuring that books of accounts are up to
        date at all times. At Boston Financial, we do all the routine tasks and
        much more to ensure that you focus your energies on activities that are
        higher in the value chain.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        Our primary aim is to provide efficient and quality support to CPAs for
        bookkeeping and accounting, book close, reconciliations, periodic
        reporting, financial statement preparation, etc., which ensures that you
        are left with more time to provide value-added services.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
        This enables you to have a quantum leap in client retention and
        increased revenue from your clients along with overall cost reduction.
      </p>
    </div>
  </div>
  <div class="col mt-70">
    <div style="margin-bottom: 45px !important">
      <h3
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block"
      >
        OFFERING
      </h3>
    </div>
    <div class="row">
      <div class="col-lg-7 pr-0">
        <div>
          <ul class="mt-10">
            <li
              class="mb-2 text-dark"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Bookkeeping and Accounting
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              AR / AP / Bank Reconciliation
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              GL Maintenance
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Expense Accounting and Reporting
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Fixed Asset Accounting
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 30px !important"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Financial Statement Compilation & Review
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/cpa solutions/accounts.png"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col mt-70 mb-100">
    <div class="d-flex justify-content-end" style="margin-bottom: 45px !important">
      
    </div>
    <div class="row">
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../../../assets/images/cpa solutions/BFAG advantage.png"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0">
        <div>
          <h3
        class="text-center"
        style="border-bottom: 3px solid #0951A4; display: inline-block;"
      >
        THE BFAG ADVANTAGE
      </h3>
          <p class="text-dark" style="font-size: 20px; margin-top: 1.5rem; margin-bottom: 1rem;">
            We ensure that we deliver high quality and on time. Our back office
            in India enables us to complete the work while you are asleep. In
            addition, we guarantee the following:
          </p>
          <ul class="mt-10">
            <li
              class="mb-2 text-dark"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Customized and scalable solutions to provide you  exactly what you
              need
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Data security and confidentiality measures at par with the
              Industry
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Systems and processes customized to reduce the on-boarding time
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: #0951A4; text-align: justify;"></i>
              Freedom from hiring and retaining qualified and   experienced
              resources
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Significant savings on payroll and associated costs  in addition to
              zero investment in infrastructure
            </li>
            <li
              class="mb-2 text-dark mb-40"
              style="font-size: 24px; margin-bottom: 9px !important; font-weight: 500;"
            >
              <i class="fas fa-star" style="color: #0951A4"></i>
              Financial Statement Compilation & Review
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- USA end -->