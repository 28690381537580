
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area approval-management-system approval-management customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Approval Management</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Approval Management</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Approval Management System PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Approval Management Solutions</span>
                    <h2 class="title pb-15">Approval Management System</h2>
                    <p>Organisations are increasingly focusing on improving efficiency and productivity. Technology and systems are being used to improve Business Process Automation (BPM) to achieve gains in productivity and efficiency. Many organisations
                        have deployed multiple and disparate systems that often lack the functionality to improve productivity.</p>
                    <p>Approval management functionality enhances Horizon by enabling users to automate and thus manage business processes more efficiently and cost effectively. A major advantage of the Approval management software solution is it enables
                        you to focus on those processes truly enhanced by automation.</p>
                    <p>Does your approval process sit in multiple in-boxes, potentially delaying the procurement of much needed items? The tasks incorporated in a business process flow can be automated to allow the system to pass the work item, in this example
                        the purchase order, to the needed individual(s) for approval so the procurement is approved and the purchase order is placed with the correct supplier in a timely manner.</p>
                    <p>Approval Management is not simply about improving and mapping workflow. It begins with the business rules which underlie processes and focuses on automating/integrating systems and people through processes and technology.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Approval Management System PART END ======-->

<!--====== Key Features PART START ======-->
<div class="about-faq-area about-faq-area-custom horizon-hrms dec-spacing bg_cover gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Highlights</span>
                    <h2 class="title">Key Features</h2>
                </div>
            </div>
            <div class="faq-accordion-3">
                <div class="accordion" id="accordionExample">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Approval Flow
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Function wise, transaction wise, amount limit wise, Rule-based (conditional based on business rules such as quantity or price, in the instance of our example of a Purchase Order) approval flows can be setup.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Actions
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Various actions can be defined within the system for approving the documents.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Approval Requests and Approval Form
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Approval requests from various modules can be generated based on the setup done for approval flow. From Approval Management screen you can initiate your Process Requests, see all your initiated requests, see requests
                                        that need your Action, and provide Approvals quickly and easily.</p>
                                </div>
                            </div>
                        </div>

                        <!-- card -->
                    </div>

                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSev">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSev" aria-expanded="false" aria-controls="collapseSev">
                                    <i class="fal fa-magic"></i>Integration
                                </a>
                            </div>
                            <div id="collapseSev" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Integrate disparate business applications into a single view for the user. Leverage existing IT infrastructure. Update external applications with improved validation, security and control. Link business systems with
                                        process logic rather than user behaviour</p>
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEight">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    <i class="fal fa-magic"></i>Mobility
                                </a>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Approve the documents on the go. Horizon Approval Management App enables you to receive the notification on your phone/tablets, viewing the request and approved, disapprove or clarification requests on your phone.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingNine">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    <i class="fal fa-magic"></i>Compliance/Control
                                </a>
                            </div>
                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Empower lower level management and staff without sacrificing control. Enforce compliance to business rules, policies and procedures. Provide preventative rather than detective controls (build the control into the process).
                                        Conform to legislative requirements Enforce segregation of duties. Manage documents and records as part of the process. Provide transparency in the processes with full audit trail</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->

                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== Key Features PART START ======-->

<!--====== Benefits at a glance PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                    <p class="add-arrow"> Enables departments to reduce costs and turnaround times.</p>
                    <p class="add-arrow"> Manages everyday business tasks like material, purchase requests, sales quotations, payment vouchers and even capital expenditure more efficiently.</p>
                    <p class="add-arrow"> Improved relationship with banks with reduction in discrepant documents</p>
                    <p class="add-arrow"> Eliminates the guesswork and the uncontrolled paper trails by creating an electronic trail which is fully tracked and from which reports can be generated.</p>
                    <p class="add-arrow"> Improved communication, Promotes process innovation</p>
                    <p class="add-arrow"> Minimum change management and training requirements, Improved employee morale</p>
                    <p class="add-arrow"> Provides unbiased performance measurement of process maturity within a business</p>
                    <p class="add-arrow"> Helps to identify improvement opportunities</p>
                    <p class="add-arrow"> Approval Management makes provision for Mobile and Tablet, Browser Based and Windows user access.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Benefits at a glance PART END ======-->
