
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area bank-facility-management customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Bank Facility Management</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Bank Facility Management</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Bank Facility Management PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Bank Facility Solutions</span>
                    <h2 class="title pb-15">Bank Facility Management</h2>
                    <p>Bank Facility Management serves as decision making tool for higher management for their groups’ financial activities. This also takes care of the complete treasury activities of a company.</p>
                    <p>After processing the data the system generates report like the current facilities available for companies in different banks, utilization of facilities by companies from several banks, consolidated statements of financial commitments
                        in a particular period for a particular division or company towards a bank or beneficiary, group wise or company wise collection forecast, etc.</p>
                    <p>It also includes the processing of bank facilities like, LC, LG, TR, Short term loan, Long term loan, etc. after calculating the cost involved in each activity. You will be to make apt decision which facility you may have to plan for
                        your future commitments taking care of its cost and availability. It shall help you to take the decision to meet the future financial commitments after optimization of the companies’ existing financial resources and facilities.</p>
                    <p>It has interfaces to capture data with minimum effort which will be integrated with the existing financial and inventory system for the required linking. The system also has a number of reports based on both current and history data
                        to help management in planning/arranging funds and in decision making.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Bank Facility Management PART END ======-->

<!--====== Key Features PART START ======-->
<div class="about-faq-area about-faq-area-custom horizon-hrms dec-spacing bg_cover gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Highlights</span>
                    <h2 class="title">Key Features</h2>
                </div>
            </div>
            <div class="faq-accordion-3">
                <div class="accordion" id="accordionExample">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Facility Group
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>You can easily group your facilities by tagging each facility. It shows group wise total facilities, payable amount, receivable amount etc.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Management of Facilities
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>It enables you to manage your various bank facilities information effectively and easily. You can manage vendors documents, goods receive and payment related information easily. It has an extensive feature than you
                                        can add all expenses as cost with imported items. It enables you to track customer bank, your receiving bank and others basic information. It enables you to manage Back to Back facilities.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Dashboard
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Dashboard presents quick overview of your business. Module specific port-lets and interactive charts highlight brings key operation related information to your attention. Our dashboard can be customized by the users
                                        which help maximize their daily productivity.</p>
                                </div>
                            </div>
                        </div>

                        <!-- card -->
                    </div>

                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSev">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSev" aria-expanded="false" aria-controls="collapseSev">
                                    <i class="fal fa-magic"></i>Automated data
                                </a>
                            </div>
                            <div id="collapseSev" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Captured Data from Bank Facility Module and ERP systems to ensure data is mapped accurately and instantaneously to the required documents and minimizing manual data entry</p>
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEight">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    <i class="fal fa-magic"></i>Alerts
                                </a>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Provide comprehensive information throughout the duration of the availed facilities. Sets alerts for automatic notification of significant events, such as new Facilities, amendments, approvals, maturities, payments,
                                        delays, expirations, latest shipment dates and other exception events.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingNine">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    <i class="fal fa-magic"></i>Payment Tracking
                                </a>
                            </div>
                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Traces facility payments and manages maturity and payment dates, amounts, currency, fees deducted and accounts credited.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->

                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== Key Features PART END ======-->


<!--====== >Benefits at a glance PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                    <p class="add-arrow"> No typing errors while creating documents</p>
                    <p class="add-arrow"> Integrated with ERP system, hence less chance of mistakes</p>
                    <p class="add-arrow"> Improved relationship with banks with reduction in discrepant documents</p>
                    <p class="add-arrow"> Speed – up document processing and checking, subsequent LC documents require hardly any effort</p>
                    <p class="add-arrow"> Standardize the LC processing throughout the team</p>
                    <p class="add-arrow"> Lowers your overhead costs</p>
                    <p class="add-arrow"> View and print Facilities immediately as advised</p>
                    <p class="add-arrow"> Receive automatic notification of a specific Facility status such as upcoming expirations, delays and payments</p>
                    <p class="add-arrow"> Reduce discrepancies caused by inconsistent document preparation</p>
                    <p class="add-arrow"> View required documents as they are being prepared</p>
                    <p class="add-arrow"> Manage and archive electronic documents with an audit trail of each transaction, reducing handling expenses</p>
                    <p class="add-arrow"> Streamline and manage creation of Facilities</p>
                    <p class="add-arrow"> Streamline reconciliation of invoices against Facilities</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== >Benefits at a glance PART END ======-->
