import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DsarService } from 'src/app/services/dsar-page/dsar.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  inquiryType = ["Scheduling Demo", "Product Enquiry", "Others"];
  resultTrue = false;
  defaultCountry: string;
  selectCountryName: string;
  countryCode: string;

  constructor(
    private _dsar: DsarService,
  ) { }

  ngOnInit(): void {
    this.defaultCountry = 'USA';
    this.selectCountryName = localStorage.getItem('selectedCountry') || this.defaultCountry;
    this.countryCode = this.getCountryCode(this.selectCountryName);
  }

  onSubmit(contactForm: NgForm) {
    if (contactForm.invalid) {
      return;
    }
    this._dsar.addContact(contactForm.value).subscribe({
      next: (data: any) => {
        Swal.fire({
          icon: "success",
          title: "Thank you",
          text: "Your request has been saved successfully",
        }).then((result) => {
          if (result.isConfirmed) {
            contactForm.reset();
            this.resultTrue = true;
            setTimeout(() => {
              this.resultTrue = false;
              window.location.reload(); 
            }, 1000);
          }
        });
      },
      error: error => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    });
  }

  getCountryCode(country: string): string {
    switch (country) {
      case 'Dubai':
        return '+971';
      case 'India':
        return '+91';
      case 'USA':
        return '+1';
      default:
        return '+91';
    }
  }
}
