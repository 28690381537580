<!-- dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100">
  <div class="container-fluid  mt-150 divmart d-flex flex-column justify-content-center align-items-center">
    <div class="row d-flex flex-column justify-content-center align-items-center">
      <div class="col-lg-12 d-flex flex-column justify-content-center align-items-center">
        <div>
          <h3 class="text-center " style="border-bottom: 4px solid #418052; display: inline-block">
            EXECUTION METHODOLOGY
          </h3>
        </div>
        <div class=" mb-5 " style="margin-top: 3rem !important;">
          <img class="img-fluid" style="border-radius: 15px; " src="../../../../../assets/images/how we do it/ExeMeth.jpg"
          alt="execution_methodology" />
        </div>
      </div>
      <div class="col-lg-12">
        <p class="text-dark text-justify ">
          Preferably BFAG works on two models ie Dedicated resource Model and
          Hourly Rate Model. Both these models are designed to increase delivery
          efficiency, result into cost reduction and at the same time ensure
          timely and quality delivery of our finance & accounts, compliance and
          payroll services. The type of model that best suits a clients
          requirement depends on the volume and complexity of business. But
          whatever model is chosen, we ensure flexibility, scalability at all
          times.
        </p>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="col-12 pt-3 pb-3 pl-0 pr-0 ml-0 mr-0">
      <ul class="d-flex justify-content-center nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item col-lg-4 pl-0 pr-0" role="presentation">
          <button class="nav-link active border-0 text-white buttonUAE" id="pills-home-tab" data-toggle="pill"
            data-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"
            style="background: #418052;  border-radius: 0; width: 100%;">
            WORKING IN CLOUD ENVIRONMENT
          </button>
        </li>
        <li class="nav-item col-lg-4 pl-0 pr-0" role="presentation">
          <button class="nav-link border-0 text-white buttonUAE" id="pills-profile-tab" data-toggle="pill"
            data-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"
            style="background: #418052;  border-radius: 0; width: 100%;">
            REMOTE DESKTOP
          </button>
        </li>
        <li class="nav-item col-lg-4 pl-0 pr-0 " role="presentation">
          <button class="nav-link border-0 text-white buttonUAE" id="pills-contact-tab" data-toggle="pill"
            data-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false"
            style="background: #418052; border-radius: 0; width: 100%;">
            WORK IN LAN ENVIRONMENT
          </button>
        </li>
      </ul>
      <div class="col-12 pl-0 ml-0 pr-0 mr-0 tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

          <p class="text-dark">
            In this case both client and Service provider access the same software
            Application with separate dedicated login access.
          </p>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <p class="text-dark">
            We use remote login application like Citrix, LogMeIn etc. to access
            the CPA server and carry out the work as if any other employee of CPA
            is working. We will use the Login ids provided to BFAG users, so all
            the data entered and processed would be tracked by CPA Supervisor.
          </p>
        </div>
        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
          <p class="text-dark">
            In this case, we use our own License software for Accounting and for
            Tax we use license provided by Client and after processing the
            information and preparing the returns, soft copy is forwarded to the
            client for review.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- dubai india -->

<!-- India started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100">
  <div class="container-fluid  mt-150 divmart d-flex flex-column justify-content-center align-items-center">
    <div class="row d-flex flex-column justify-content-center align-items-center">
      <div class="col-lg-12 d-flex flex-column justify-content-center align-items-center">
        <div>
          <h3 class="text-center " style="border-bottom: 4px solid #072F5F; display: inline-block">
            EXECUTION METHODOLOGY
          </h3>
        </div>
        <div class=" mb-5 " style="margin-top: 3rem !important;">
          <img class="img-fluid" style="border-radius: 15px; " src="../../../../../assets/images/how we do it/ExeMeth.jpg"
          alt="execution_methodology" />
        </div>
      </div>
      <div class="col-lg-12">
        <p class="text-dark text-justify ">
          Preferably BFAG works on two models ie Dedicated resource Model and
          Hourly Rate Model. Both these models are designed to increase delivery
          efficiency, result into cost reduction and at the same time ensure
          timely and quality delivery of our finance & accounts, compliance and
          payroll services. The type of model that best suits a clients
          requirement depends on the volume and complexity of business. But
          whatever model is chosen, we ensure flexibility, scalability at all
          times.
        </p>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="col-12 pt-3 pb-3 pl-0 pr-0 ml-0 mr-0">
      <ul class="d-flex justify-content-center nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item col-lg-4 pl-0 pr-0" role="presentation">
          <button class="nav-link active border-0 text-white buttonInd" id="pills-home-tab" data-toggle="pill"
            data-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"
            style="background: #072F5F;  border-radius: 0; width: 100%;">
            WORKING IN CLOUD ENVIRONMENT
          </button>
        </li>
        <li class="nav-item col-lg-4 pl-0 pr-0" role="presentation">
          <button class="nav-link border-0 text-white buttonInd" id="pills-profile-tab" data-toggle="pill"
            data-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"
            style="background: #072F5F;  border-radius: 0; width: 100%;">
            REMOTE DESKTOP
          </button>
        </li>
        <li class="nav-item col-lg-4 pl-0 pr-0 " role="presentation">
          <button class="nav-link border-0 text-white buttonInd" id="pills-contact-tab" data-toggle="pill"
            data-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false"
            style="background: #072F5F; border-radius: 0; width: 100%;">
            WORK IN LAN ENVIRONMENT
          </button>
        </li>
      </ul>
      <div class="col-12 pl-0 ml-0 pr-0 mr-0 tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

          <p class="text-dark">
            In this case both client and Service provider access the same software
            Application with separate dedicated login access.
          </p>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <p class="text-dark">
            We use remote login application like Citrix, LogMeIn etc. to access
            the CPA server and carry out the work as if any other employee of CPA
            is working. We will use the Login ids provided to BFAG users, so all
            the data entered and processed would be tracked by CPA Supervisor.
          </p>
        </div>
        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
          <p class="text-dark">
            In this case, we use our own License software for Accounting and for
            Tax we use license provided by Client and after processing the
            information and preparing the returns, soft copy is forwarded to the
            client for review.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- india india -->

<!-- usa started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100">
  <div class="container-fluid  mt-150 divmart d-flex flex-column justify-content-center align-items-center">
    <div class="row d-flex flex-column justify-content-center align-items-center">
      <div class="col-lg-12 d-flex flex-column justify-content-center align-items-center">
        <div>
          <h3 class="text-center " style="border-bottom: 4px solid #072F5F; display: inline-block">
            EXECUTION METHODOLOGY
          </h3>
        </div>
        <div class=" mb-5 " style="margin-top: 3rem !important;">
          <img class="img-fluid" style="border-radius: 15px;" src="../../../../../assets/images/how we do it/ExeMeth.jpg"
          alt="execution_methodology" />
        </div>
      </div>
      <div class="col-lg-12">
        <p class="text-dark text-justify ">
          Preferably BFAG works on two models ie Dedicated resource Model and
          Hourly Rate Model. Both these models are designed to increase delivery
          efficiency, result into cost reduction and at the same time ensure
          timely and quality delivery of our finance & accounts, compliance and
          payroll services. The type of model that best suits a clients
          requirement depends on the volume and complexity of business. But
          whatever model is chosen, we ensure flexibility, scalability at all
          times.
        </p>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="col-12 pt-3 pb-3 pl-0 pr-0 ml-0 mr-0">
      <ul class="d-flex justify-content-center nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item col-lg-4 pl-0 pr-0" role="presentation">
          <button class="nav-link active border-0 text-white buttonInd" id="pills-home-tab" data-toggle="pill"
            data-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"
            style="background: #072F5F;  border-radius: 0; width: 100%;">
            WORKING IN CLOUD ENVIRONMENT
          </button>
        </li>
        <li class="nav-item col-lg-4 pl-0 pr-0" role="presentation">
          <button class="nav-link border-0 text-white buttonInd" id="pills-profile-tab" data-toggle="pill"
            data-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"
            style="background: #072F5F;  border-radius: 0; width: 100%;">
            REMOTE DESKTOP
          </button>
        </li>
        <li class="nav-item col-lg-4 pl-0 pr-0 " role="presentation">
          <button class="nav-link border-0 text-white buttonInd" id="pills-contact-tab" data-toggle="pill"
            data-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false"
            style="background: #072F5F; border-radius: 0; width: 100%;">
            WORK IN LAN ENVIRONMENT
          </button>
        </li>
      </ul>
      <div class="col-12 pl-0 ml-0 pr-0 mr-0 tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

          <p class="text-dark">
            In this case both client and Service provider access the same software
            Application with separate dedicated login access.
          </p>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <p class="text-dark">
            We use remote login application like Citrix, LogMeIn etc. to access
            the CPA server and carry out the work as if any other employee of CPA
            is working. We will use the Login ids provided to BFAG users, so all
            the data entered and processed would be tracked by CPA Supervisor.
          </p>
        </div>
        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
          <p class="text-dark">
            In this case, we use our own License software for Accounting and for
            Tax we use license provided by Client and after processing the
            information and preparing the returns, soft copy is forwarded to the
            client for review.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- usa india -->








