<!-- Dubai started -->
<div  *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 container">
  <div
    class="row mt-150 divmart  d-flex flex-column justify-content-center align-items-center pl-3 pr-3"
  >
    <div class="mb-5 col-lg-12 d-flex flex-column justify-content-center align-items-center">
      <h3
        class="text-center"
        style="border-bottom: 4px solid #418052; display: inline-block"
      >
        ENGAGEMENT PROCESS
      </h3>
    </div>
    <div class=" mb-5">
      <img
      class="img-fluid" style="border-radius: 15px; max-height: 30rem;"
      src="../../../../../assets/images/how we do it/EngProcess.png"
      alt="engagement_process"
    />
    </div>
    <div class="col-12 pt-3 pb-3 pl-0 pr-0 ml-0 mr-0">
      <ul
        class="justify-content-between nav nav-pills mb-3"
        id="pills-tab"
        role="tablist"
      >
        <li class="nav-item col-lg-3 pl-0 pr-0 " role="presentation">
          <button
            class="nav-link active border-0 text-white box1"
            id="pills-home-tab"
            data-toggle="pill"
            data-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
            style="background: #418052; border-radius: 0; width: 100%; padding: 1.25rem;"
          >
            ASSESSMENT & PROPOSAL
          </button>
        </li>
        <li class="nav-item col-lg-3  pl-0 pr-0 " role="presentation">
          <button
            class="nav-link border-0 text-white box2"
            id="pills-profile-tab"
            data-toggle="pill"
            data-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
            style="background: #418052; border-radius: 0; width: 100%; padding: 1.25rem;"
          >
            WORK FLOW DESIGN
          </button>
        </li>
        <li class="nav-item col-lg-3  pl-0 pr-0" role="presentation">
          <button
            class="nav-link border-0 text-white"
            id="pills-contact-tab"
            data-toggle="pill"
            data-target="#pills-contact"
            type="button"
            role="tab"
            aria-controls="pills-contact"
            aria-selected="false"
            style="background: #418052; border-radius: 0; width: 100%"
          >
            SLA DEVELOPMENT AND RISK MANAGEMENT
          </button>
        </li>
        <li class="nav-item col-lg-3 pl-0 pr-0" role="presentation">
          <button
            class="nav-link border-0 text-white"
            id="pills-four-tab"
            data-toggle="pill"
            data-target="#pills-four"
            type="button"
            role="tab"
            aria-controls="pills-four"
            aria-selected="false"
            style="background: #418052; border-radius: 0; width: 100%"
          >
            IMPLEMENTATION AND SERVICE MANAGEMENT
          </button>
        </li>
      </ul>
      <div class="col-12 pl-0 ml-0 pr-0 mr-0 tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <p class="text-dark font-weight-bold">
            During this initial phase, Our Domain & Process experts work with
            the client to;
          </p>
          <ul class="text-dark" style="line-height: 2rem">
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Understand and document the Systems, Process that are in use
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Understand the corporate culture
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Assess the volume of transactions or process that is to be
              outsourced
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Understand the business the client is into and the specific
              requirements
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Define the desired process and the expected outcome
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Likely impact of transition
            </li>
          </ul>
        </div>
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <p class="text-dark font-weight-bold">
            Once the proposal is accepted, we set forth to;
          </p>
          <ul class="text-dark" style="line-height: 2rem">
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Design the best work flow suitable for the outsourced activity
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Define the Process and internal controls, Scope
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Define the document flow
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Define the Organisation structure for the outsourced activity
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Define the desired process and the expected outcome
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i
              >Define the desired process and the expected outcome
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Define the migration roadmap which includes timeline, resource
              deployment, software
            </li>
          </ul>
        </div>
        <div
          class="tab-pane fade"
          id="pills-contact"
          role="tabpanel"
          aria-labelledby="pills-contact-tab"
        >
          <p class="text-dark font-weight-bold">
            Based on the data gathered during the initial phases and on
            completion of process definition, scope, we decide on the below;
          </p>
          <ul class="text-dark" style="line-height: 2rem">
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              MIS structure and the frequency
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Timelines for reporting critical KPI’s
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Abnormal reports
            </li>

          </ul>
        </div>
        <div
          class="tab-pane fade"
          id="pills-four"
          role="tabpanel"
          aria-labelledby="pills-four-tab"
        >
          <p class="text-dark font-weight-bold">
            We follow different approaches to implementation of the agreed transition plan depending on the volume & complexity of business. This is a critical phase and as such we look into the following aspects;
          </p>
          <ul class="text-dark" style="line-height: 2rem">
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Each process is well defined and agreed, any changes to the same are updated
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Training of deployed staff on the standard operating process
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Information regarding SLA are shared
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Tracking status of implementation using various project management tools & perform course correction to make processes more efficient
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Processes are reviewed from viewpoint of consistent & accurate delivery of tasks assigned
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i
              >Ensure improved accuracy & efficiency by placing additional checks, automation wherever possible, internal control methods
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #418052"></i>
              Overall outsourced activity is controlled thru regular reporting, monitoring and suitable dashboards
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Dubai end -->

<!-- india started -->
<div  *ngIf="selectCountryName == 'India'" class="min-vh-100 container">
  <div
    class="row mt-150 divmart  d-flex flex-column justify-content-center align-items-center pl-3 pr-3"
  >
    <div class="mb-5 col-lg-12 d-flex flex-column justify-content-center align-items-center">
      <h3
        class="text-center"
        style="border-bottom: 4px solid #072F5F; display: inline-block"
      >
        ENGAGEMENT PROCESS
      </h3>
    </div>
    <div class="mb-5">
      <img
      class="img-fluid" style="border-radius: 15px; max-height: 30rem;"
      src="../../../../../assets/images/how we do it/EngProcess.png"
      alt="engagement_process"
    />
    </div>
    <div class="col-12 pt-3 pb-3 pl-0 pr-0 ml-0 mr-0">
      <ul
        class="justify-content-between nav nav-pills mb-3"
        id="pills-tab"
        role="tablist"
      >
        <li class="nav-item col-lg-3 pl-0 pr-0" role="presentation">
          <button
            class="nav-link active border-0 text-white box1"
            id="pills-home-tab"
            data-toggle="pill"
            data-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
            style="background: #072F5F; border-radius: 0; width: 100%; padding: 1.25rem;"
          >
            ASSESSMENT & PROPOSAL
          </button>
        </li>
        <li class="nav-item col-lg-3  pl-0 pr-0 " role="presentation">
          <button
            class="nav-link border-0 text-white box2"
            id="pills-profile-tab"
            data-toggle="pill"
            data-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
            style="background: #072F5F; border-radius: 0; width: 100%; padding: 1.25rem;"
          >
            WORK FLOW DESIGN
          </button>
        </li>
        <li class="nav-item col-lg-3 pl-0 pr-0" role="presentation">
          <button
            class="nav-link border-0 text-white"
            id="pills-contact-tab"
            data-toggle="pill"
            data-target="#pills-contact"
            type="button"
            role="tab"
            aria-controls="pills-contact"
            aria-selected="false"
            style="background: #072F5F; border-radius: 0; width: 100%"
          >
            SLA DEVELOPMENT AND RISK MANAGEMENT
          </button>
        </li>
        <li class="nav-item col-lg-3 pl-0 pr-0" role="presentation">
          <button
            class="nav-link border-0 text-white"
            id="pills-four-tab"
            data-toggle="pill"
            data-target="#pills-four"
            type="button"
            role="tab"
            aria-controls="pills-four"
            aria-selected="false"
            style="background: #072F5F; border-radius: 0; width: 100%"
          >
            IMPLEMENTATION AND SERVICE MANAGEMENT
          </button>
        </li>
      </ul>
      <div class="col-12 pl-0 ml-0 pr-0 mr-0 tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <p class="text-dark font-weight-bold">
            During this initial phase, Our Domain & Process experts work with
            the client to;
          </p>
          <ul class="text-dark" style="line-height: 2rem">
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Understand and document the Systems, Process that are in use
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Understand the corporate culture
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Assess the volume of transactions or process that is to be
              outsourced
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Understand the business the client is into and the specific
              requirements
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Define the desired process and the expected outcome
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Likely impact of transition
            </li>
          </ul>
        </div>
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <p class="text-dark font-weight-bold">
            Once the proposal is accepted, we set forth to;
          </p>
          <ul class="text-dark" style="line-height: 2rem">
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Design the best work flow suitable for the outsourced activity
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Define the Process and internal controls, Scope
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Define the document flow
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Define the Organisation structure for the outsourced activity
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Define the desired process and the expected outcome
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i
              >Define the desired process and the expected outcome
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Define the migration roadmap which includes timeline, resource
              deployment, software
            </li>
          </ul>
        </div>
        <div
          class="tab-pane fade"
          id="pills-contact"
          role="tabpanel"
          aria-labelledby="pills-contact-tab"
        >
          <p class="text-dark font-weight-bold">
            Based on the data gathered during the initial phases and on
            completion of process definition, scope, we decide on the below;
          </p>
          <ul class="text-dark" style="line-height: 2rem">
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              MIS structure and the frequency
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Timelines for reporting critical KPI’s
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Abnormal reports
            </li>

          </ul>
        </div>
        <div
          class="tab-pane fade"
          id="pills-four"
          role="tabpanel"
          aria-labelledby="pills-four-tab"
        >
          <p class="text-dark font-weight-bold">
            We follow different approaches to implementation of the agreed transition plan depending on the volume & complexity of business. This is a critical phase and as such we look into the following aspects;
          </p>
          <ul class="text-dark" style="line-height: 2rem">
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Each process is well defined and agreed, any changes to the same are updated
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Training of deployed staff on the standard operating process
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Information regarding SLA are shared
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Tracking status of implementation using various project management tools & perform course correction to make processes more efficient
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Processes are reviewed from viewpoint of consistent & accurate delivery of tasks assigned
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i
              >Ensure improved accuracy & efficiency by placing additional checks, automation wherever possible, internal control methods
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Overall outsourced activity is controlled thru regular reporting, monitoring and suitable dashboards
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- india end -->

<!-- USA started -->
<div  *ngIf="selectCountryName == 'USA'" class="min-vh-100 container">
  <div
    class="row mt-150 divmart  d-flex flex-column justify-content-center align-items-center pl-3 pr-3"
  >
    <div class="mb-5 col-lg-12  d-flex flex-column justify-content-center align-items-center">
      <h3
        class="text-center"
        style="border-bottom: 4px solid #072F5F; display: inline-block"
      >
        ENGAGEMENT PROCESS
      </h3>
    </div>
    <div class="mb-5">
      <img
      class="img-fluid" style="border-radius: 15px; max-height: 30rem;"
      src="../../../../../assets/images/how we do it/EngProcess.png"
      alt="engagement_process"
    />
    </div>
    <div class="col-12 pt-3 pb-3 pl-0 pr-0 ml-0 mr-0">
      <ul
        class="justify-content-between nav nav-pills mb-3"
        id="pills-tab"
        role="tablist"
      >
        <li class="nav-item col-lg-3 pl-0 pr-0" role="presentation">
          <button
            class="nav-link active border-0 text-white box1"
            id="pills-home-tab"
            data-toggle="pill"
            data-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
            style="background: #072F5F; border-radius: 0; width: 100%; padding: 1.25rem;"
          >
            ASSESSMENT & PROPOSAL
          </button>
        </li>
        <li class="nav-item col-lg-3 pl-0 pr-0" role="presentation">
          <button
            class="nav-link border-0 text-white box2"
            id="pills-profile-tab"
            data-toggle="pill"
            data-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
            style="background: #072F5F; border-radius: 0; width: 100%; padding: 1.25rem;"
          >
            WORK FLOW DESIGN
          </button>
        </li>
        <li class="nav-item col-lg-3 pl-0 pr-0" role="presentation">
          <button
            class="nav-link border-0 text-white"
            id="pills-contact-tab"
            data-toggle="pill"
            data-target="#pills-contact"
            type="button"
            role="tab"
            aria-controls="pills-contact"
            aria-selected="false"
            style="background: #072F5F; border-radius: 0; width: 100%"
          >
            SLA DEVELOPMENT AND RISK MANAGEMENT
          </button>
        </li>
        <li class="nav-item col-lg-3 pl-0 pr-0" role="presentation">
          <button
            class="nav-link border-0 text-white"
            id="pills-four-tab"
            data-toggle="pill"
            data-target="#pills-four"
            type="button"
            role="tab"
            aria-controls="pills-four"
            aria-selected="false"
            style="background: #072F5F; border-radius: 0; width: 100%"
          >
            IMPLEMENTATION AND SERVICE MANAGEMENT
          </button>
        </li>
      </ul>
      <div class="col-12 pl-0 ml-0 pr-0 mr-0 tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <p class="text-dark font-weight-bold">
            During this initial phase, Our Domain & Process experts work with
            the client to;
          </p>
          <ul class="text-dark" style="line-height: 2rem">
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Understand and document the Systems, Process that are in use
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Understand the corporate culture
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Assess the volume of transactions or process that is to be
              outsourced
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Understand the business the client is into and the specific
              requirements
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Define the desired process and the expected outcome
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Likely impact of transition
            </li>
          </ul>
        </div>
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <p class="text-dark font-weight-bold">
            Once the proposal is accepted, we set forth to;
          </p>
          <ul class="text-dark" style="line-height: 2rem">
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Design the best work flow suitable for the outsourced activity
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Define the Process and internal controls, Scope
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Define the document flow
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Define the Organisation structure for the outsourced activity
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Define the desired process and the expected outcome
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i
              >Define the desired process and the expected outcome
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Define the migration roadmap which includes timeline, resource
              deployment, software
            </li>
          </ul>
        </div>
        <div
          class="tab-pane fade"
          id="pills-contact"
          role="tabpanel"
          aria-labelledby="pills-contact-tab"
        >
          <p class="text-dark font-weight-bold">
            Based on the data gathered during the initial phases and on
            completion of process definition, scope, we decide on the below;
          </p>
          <ul class="text-dark" style="line-height: 2rem">
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              MIS structure and the frequency
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Timelines for reporting critical KPI’s
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Abnormal reports
            </li>

          </ul>
        </div>
        <div
          class="tab-pane fade"
          id="pills-four"
          role="tabpanel"
          aria-labelledby="pills-four-tab"
        >
          <p class="text-dark font-weight-bold">
            We follow different approaches to implementation of the agreed transition plan depending on the volume & complexity of business. This is a critical phase and as such we look into the following aspects;
          </p>
          <ul class="text-dark" style="line-height: 2rem">
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Each process is well defined and agreed, any changes to the same are updated
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Training of deployed staff on the standard operating process
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Information regarding SLA are shared
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Tracking status of implementation using various project management tools & perform course correction to make processes more efficient
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Processes are reviewed from viewpoint of consistent & accurate delivery of tasks assigned
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i
              >Ensure improved accuracy & efficiency by placing additional checks, automation wherever possible, internal control methods
            </li>
            <li>
              <i class="fas fa-arrow-circle-right" style="color: #072F5F"></i>
              Overall outsourced activity is controlled thru regular reporting, monitoring and suitable dashboards
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- USA end -->
