<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/Accounting.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Accounting & Transaction Processing
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                  <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev" >
                      <a  href="#accounting-and-transition-processing" data-toggle="collapse" data-target="#collapseSevv"
                      aria-expanded="true" aria-controls="collapseSevv" >
                        Accounting & Transaction Processing
                      </a>
                    </div>
                    <div id="collapseSevv" class="collapse show" aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div class="list-group">
                          <ul class="text-dark serviceul">

                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Receivable
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Fixed Assets Accounting
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Budgeting & Variance Analysis
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li class="shli" routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Consolidation Reporting & GAAP Conversion
                            </li>
                            <li routerLink="/small-business-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Small Business Accounting
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse " aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li  routerLink="/individual-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Individual Tax Return
                            </li>
                            <li  routerLink="/corporate-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Tax Return
                            </li>
                            <li routerLink="/sales-and-use-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Sales & Use Tax
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span (click)="onPayroll()">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Payroll Processing
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span (click)="onConsulting()">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Setting up an entity in the US
                            </li>
                            <li routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Global Corporate Structuring
                            </li>
                            <li routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Plan Preparation
                            </li>
                            <li routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#business-valuation-service"> Business Valuation</a>
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3 id="consolidation-reporting-gaap-conversion">Consolidation Reporting & GAAP Conversion</h3>
                    <!--<p class="mb-15 mt-20 text-dark servicep">
                      Streamline Financial Reporting and Ensure GAAP Compliance with Consolidation Reporting & GAAP Conversion Services
                    </p>-->
                    <p class="mb-15 mt-20 text-dark servicep">
                      Consolidation reporting and GAAP conversion are critical components of financial management for businesses operating in multiple entities or jurisdictions. Accurate and compliant financial reporting is essential for decision-making, stakeholder communication, and regulatory compliance. However, consolidating financial data and converting to Generally Accepted Accounting Principles (GAAP) can be complex and time-consuming, particularly for organizations facing diverse accounting standards. At BFAG we offer specialized Consolidation Reporting & GAAP Conversion services to help you streamline your financial reporting processes and ensure compliance. Our expert team can assist you in achieving accurate financial consolidation and seamless GAAP conversion.
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                  Comprehensive Financial Consolidation
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Our experienced professionals excel in performing comprehensive financial consolidations for businesses with multiple entities or subsidiaries. We help you consolidate financial data from various sources, reconcile intercompany transactions, and eliminate duplications or inconsistencies. By providing a consolidated view of your financial performance and position, we enable you to make informed decisions, identify trends, and communicate effectively with stakeholders.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingThirteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                  Compliance with GAAP and Regulatory Standards
                                                </a>
                                            </div>
                                            <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Compliance with GAAP is essential for financial transparency and credibility. Our team stays up-to-date with the latest GAAP guidelines and regulatory standards, ensuring that your financial statements adhere to relevant requirements. By leveraging our expertise, you can confidently navigate regulatory audits, enhance stakeholder trust, and avoid penalties or reputational risks associated with non-compliance.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                          <div class="card-header" id="headingTwelve">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                Harmonization of Accounting Standards
                                              </a>
                                          </div>
                                          <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p>Operating in different jurisdictions often involves adhering to diverse accounting standards. Our services include the harmonization of financial statements to ensure consistency and compliance with GAAP. We assist in converting financial data from various accounting frameworks to GAAP, enabling you to present unified and comparable financial statements that meet regulatory requirements.</p>
                                              </div>
                                          </div>
                                      </div>
                                      <!-- card -->

                                    </div>

                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingFourteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                  Financial Statement Preparation and Presentation
                                                </a>
                                            </div>
                                            <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Our Consolidation Reporting & GAAP Conversion services encompass the preparation and presentation of accurate and compliant financial statements. We assist in creating consolidated financial statements, including balance sheets, income statements, cash flow statements, and comprehensive footnotes. Our team ensures that financial statements are properly formatted, disclose all necessary information, and provide a clear understanding of your business's financial performance.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                            <div class="card-header" id="headingFifteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                  Comparative Analysis and Performance Metrics
                                                </a>
                                            </div>
                                            <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Effective consolidation reporting goes beyond presenting financial statements. Our services include comparative analysis and the calculation of performance metrics to help you assess financial performance and trends. We provide insights into key financial ratios, profitability measures, and other performance indicators, enabling you to benchmark against industry standards and make informed strategic decisions.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                            <div class="card-header" id="headingSixteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                  Data Integrity and Confidentiality
                                                </a>
                                            </div>
                                            <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Maintaining data integrity and confidentiality is a top priority in Consolidation Reporting & GAAP Conversion services. We employ stringent data protection measures, including secure data transmission, restricted access controls, and compliance with industry best practices. You can trust that your financial data is handled with the utmost care and confidentiality throughout the consolidation and conversion process.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->

                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                  Accurate financial consolidation and GAAP conversion are essential for streamlined financial reporting and compliance. BFAG offers specialized Consolidation Reporting & GAAP Conversion services tailored to your business's specific needs. By leveraging our expertise, comprehensive consolidation processes, and adherence to GAAP and regulatory standards, you can achieve accurate and compliant financial reporting. Contact us today to experience the benefits of our professional services and gain confidence in your financial consolidation and GAAP conversion efforts.
                </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>

<!-- india -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/Accounting.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Accounting & Transaction Processing
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                  <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev">
                      <a class="" href="" data-toggle="collapse" data-target="#collapseSev"
                        aria-expanded="true" aria-controls="collapseSev">
                        <span routerLink="/accounting-and-transition-processing">Accounting & Transaction Processing</span>
                      </a>
                    </div>
                    <div id="collapseSev" class="collapse show" aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#accounts-receivable"> Accounts Receivable</a>
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#fixed-assets-accounting"> Fixed Assets Accounting</a>
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              <a href="#budgeting-and-variance-analysis">Budgeting & Variance Analysis</a>
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li class="shli" routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Consolidation Reporting & GAAP Conversion
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li routerLink="/income-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Income Tax
                            </li>
                            <li  routerLink="/goods-and-service-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Goods & Service Tax
                            </li>
                            <li routerLink="/corporate-secretarial-compliance">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Secretarial Compliance
                            </li>
                            <li  routerLink="/fema-advisory">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              FEMA Compliance
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                   <!-- card -->
                   <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span (click)="onPayroll()">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Payroll - Compensation & Benefit Management
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span (click)="onConsulting()">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Setting up an entity in the India
                            </li>
                            <li routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Global Corporate Structuring
                            </li>
                            <li routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Plan Preparation
                            </li>
                            <li routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Valuation
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingElevenStartup">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseElevenStartup"
                        aria-expanded="false" aria-controls="collapseElevenStartup">
                        <span (click)="onStartup()">Startup Solution</span>
                      </a>
                    </div>
                    <div id="collapseElevenStartup" class="collapse" aria-labelledby="headingElevenStartup" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/startup-solution">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Startup Solution
                            </li>
                            <li routerLink="/fund-raising">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Fund Raise
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3 id="consolidation-reporting-gaap-conversion">Consolidation Reporting & GAAP Conversion</h3>
                    <!--<p class="mb-15 mt-20 text-dark servicep">
                      Streamline Financial Reporting and Ensure GAAP Compliance with Consolidation Reporting & GAAP Conversion Services
                    </p>-->
                    <p class="mb-15 mt-20 text-dark servicep">
                      Consolidation reporting and GAAP conversion are critical components of financial management for businesses operating in multiple entities or jurisdictions. Accurate and compliant financial reporting is essential for decision-making, stakeholder communication, and regulatory compliance. However, consolidating financial data and converting to Generally Accepted Accounting Principles (GAAP) can be complex and time-consuming, particularly for organizations facing diverse accounting standards. At BFAG we offer specialized Consolidation Reporting & GAAP Conversion services to help you streamline your financial reporting processes and ensure compliance. Our expert team can assist you in achieving accurate financial consolidation and seamless GAAP conversion.
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                  Comprehensive Financial Consolidation
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Our experienced professionals excel in performing comprehensive financial consolidations for businesses with multiple entities or subsidiaries. We help you consolidate financial data from various sources, reconcile intercompany transactions, and eliminate duplications or inconsistencies. By providing a consolidated view of your financial performance and position, we enable you to make informed decisions, identify trends, and communicate effectively with stakeholders.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingThirteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                  Compliance with GAAP and Regulatory Standards
                                                </a>
                                            </div>
                                            <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Compliance with GAAP is essential for financial transparency and credibility. Our team stays up-to-date with the latest GAAP guidelines and regulatory standards, ensuring that your financial statements adhere to relevant requirements. By leveraging our expertise, you can confidently navigate regulatory audits, enhance stakeholder trust, and avoid penalties or reputational risks associated with non-compliance.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                          <div class="card-header" id="headingTwelve">
                                              <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                Harmonization of Accounting Standards
                                              </a>
                                          </div>
                                          <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                              <div class="card-body text-justify" style="padding-left:20px !important">
                                                  <p>Operating in different jurisdictions often involves adhering to diverse accounting standards. Our services include the harmonization of financial statements to ensure consistency and compliance with GAAP. We assist in converting financial data from various accounting frameworks to GAAP, enabling you to present unified and comparable financial statements that meet regulatory requirements.</p>
                                              </div>
                                          </div>
                                      </div>
                                      <!-- card -->

                                    </div>

                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingFourteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                  Financial Statement Preparation and Presentation
                                                </a>
                                            </div>
                                            <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Our Consolidation Reporting & GAAP Conversion services encompass the preparation and presentation of accurate and compliant financial statements. We assist in creating consolidated financial statements, including balance sheets, income statements, cash flow statements, and comprehensive footnotes. Our team ensures that financial statements are properly formatted, disclose all necessary information, and provide a clear understanding of your business's financial performance.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                            <div class="card-header" id="headingFifteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                  Comparative Analysis and Performance Metrics
                                                </a>
                                            </div>
                                            <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Effective consolidation reporting goes beyond presenting financial statements. Our services include comparative analysis and the calculation of performance metrics to help you assess financial performance and trends. We provide insights into key financial ratios, profitability measures, and other performance indicators, enabling you to benchmark against industry standards and make informed strategic decisions.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                            <div class="card-header" id="headingSixteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                  Data Integrity and Confidentiality
                                                </a>
                                            </div>
                                            <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Maintaining data integrity and confidentiality is a top priority in Consolidation Reporting & GAAP Conversion services. We employ stringent data protection measures, including secure data transmission, restricted access controls, and compliance with industry best practices. You can trust that your financial data is handled with the utmost care and confidentiality throughout the consolidation and conversion process.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->

                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                  Accurate financial consolidation and GAAP conversion are essential for streamlined financial reporting and compliance. BFAG offers specialized Consolidation Reporting & GAAP Conversion services tailored to your business's specific needs. By leveraging our expertise, comprehensive consolidation processes, and adherence to GAAP and regulatory standards, you can achieve accurate and compliant financial reporting. Contact us today to experience the benefits of our professional services and gain confidence in your financial consolidation and GAAP conversion efforts.
                </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>
