<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/Accounting.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Accounting & Transaction Processing
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                  <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev" >
                      <a  href="#accounting-and-transition-processing" data-toggle="collapse" data-target="#collapseSevv"
                      aria-expanded="true" aria-controls="collapseSevv" >
                        Accounting & Transaction Processing
                      </a>
                    </div>
                    <div id="collapseSevv" class="collapse show" aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div class="list-group">
                          <ul class="text-dark serviceul">

                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Receivable
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Fixed Assets Accounting
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Budgeting & Variance Analysis
                            </li>
                            <li class="shli" routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Consolidation Reporting & GAAP Conversion
                            </li>
                            <li routerLink="/small-business-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Small Business Accounting
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li  routerLink="/individual-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Individual Tax Return
                            </li>
                            <li  routerLink="/corporate-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Tax Return
                            </li>
                            <li routerLink="/sales-and-use-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Sales & Use Tax
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span (click)="onPayroll()">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Payroll Processing
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span (click)="onConsulting()">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Setting up an entity in the US
                            </li>
                            <li routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Global Corporate Structuring
                            </li>
                            <li routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Plan Preparation
                            </li>
                            <li routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#business-valuation-service"> Business Valuation</a>
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3 id="reconciliation-services">Reconciliation Services</h3>
                    <!--<p class="mb-15 mt-20 text-dark servicep">
                      Ensure Accuracy and Compliance with Expert Reconciliation Services
                    </p>-->
                    <p class="mb-15 mt-20 text-dark servicep">
                      Accurate financial reconciliation is crucial for businesses to ensure the integrity of their financial records, detect errors, and maintain regulatory compliance. However, reconciling accounts, transactions, and statements can be a time-consuming and complex task, especially for organizations with high transaction volumes or multiple financial systems. At BFAG we offer specialized reconciliation services designed to help you achieve accurate and streamlined financial reconciliation processes. Our expert team can assist you in ensuring accuracy and compliance through our comprehensive reconciliation services.
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                  Comprehensive Account Reconciliation
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Our experienced professionals excel in performing comprehensive account reconciliations. Whether it involves bank accounts, credit cards, receivables, payables, or other general ledger accounts, our team ensures that transactions are accurately recorded and matched to corresponding statements. By identifying discrepancies and resolving outstanding items, we help you maintain accurate financial records and reduce the risk of errors or fraud.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                            <div class="card-header" id="headingTwelve">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                  Streamlined Transaction Reconciliation
                                                </a>
                                            </div>
                                            <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Reconciling many transactions across different systems or platforms can be challenging. Our reconciliation services include streamlined matching and reconciliation of transactions, such as sales orders, purchase orders, invoices, and payments. We leverage advanced reconciliation tools and automated processes to improve efficiency, accuracy, and timeliness, enabling you to focus on core business activities.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingThirteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                  Bank Reconciliation and Cash Management
                                                </a>
                                            </div>
                                            <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Bank reconciliation is a critical process for maintaining accurate cash balances and identifying discrepancies between your records and bank statements. Our services encompass bank reconciliation, ensuring that your bank transactions, including deposits, withdrawals, and fees, are accurately reflected in your financial records. We help you maintain cash management controls, optimize cash flow, and mitigate the risk of errors or fraudulent activities.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- card -->
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingFourteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                  Regulatory Compliance and Audit Readiness
                                                </a>
                                            </div>
                                            <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Compliance with regulatory requirements and audit readiness are essential for businesses across industries. Our reconciliation services ensure that your financial records adhere to relevant regulations, such as Generally Accepted Accounting Principles (GAAP) or International Financial Reporting Standards (IFRS). By maintaining accurate and reconciled financial data, you can confidently navigate audits, comply with regulatory obligations, and avoid penalties or reputational damage.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                            <div class="card-header" id="headingFifteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                  Timely and Accurate Reporting
                                                </a>
                                            </div>
                                            <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Timely financial reporting relies on accurate and up-to-date reconciliations. Our reconciliation services provide you with comprehensive reports, highlighting reconciled items, outstanding discrepancies, and financial insights. By gaining a clear overview of your financial position, you can make informed decisions, assess financial performance, and plan with confidence.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                            <div class="card-header" id="headingSixteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                  Data Security and Confidentiality
                                                </a>
                                            </div>
                                            <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We understand the importance of data security and confidentiality in financial reconciliation processes. Our services prioritize the protection of your sensitive financial information. We employ robust security measures, including encrypted data transmission, restricted access controls, and compliance with industry best practices. You can trust that your financial data is handled with the utmost care and confidentiality.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->

                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                  Accurate financial reconciliation is essential for maintaining the integrity of financial records and ensuring compliance. BFAG offers comprehensive reconciliation services tailored to your business's specific needs. By leveraging our expertise, streamlined processes, and adherence to regulatory requirements, you can achieve accurate and efficient financial reconciliations. Contact us today to experience the benefits of our professional reconciliation services.
                </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>


<!-- india -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/Accounting.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Accounting & Transaction Processing
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                  <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev">
                      <a class="" href="" data-toggle="collapse" data-target="#collapseSev"
                        aria-expanded="true" aria-controls="collapseSev">
                        <span routerLink="/accounting-and-transition-processing">Accounting & Transaction Processing</span>
                      </a>
                    </div>
                    <div id="collapseSev" class="collapse show" aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#accounts-receivable"> Accounts Receivable</a>
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#fixed-assets-accounting"> Fixed Assets Accounting</a>
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              <a href="#budgeting-and-variance-analysis">Budgeting & Variance Analysis</a>
                            </li>
                            <li class="shli" routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Consolidation Reporting & GAAP Conversion
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li routerLink="/income-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Income Tax
                            </li>
                            <li  routerLink="/goods-and-service-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Goods & Service Tax
                            </li>
                            <li routerLink="/corporate-secretarial-compliance">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Secretarial Compliance
                            </li>
                            <li  routerLink="/fema-advisory">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              FEMA Compliance
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span (click)="onPayroll()">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Payroll - Compensation & Benefit Management
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span (click)="onConsulting()">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Setting up an entity in the India
                            </li>
                            <li routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Global Corporate Structuring
                            </li>
                            <li routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Plan Preparation
                            </li>
                            <li routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Valuation
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingElevenStartup">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseElevenStartup"
                        aria-expanded="false" aria-controls="collapseElevenStartup">
                        <span (click)="onStartup()">Startup Solution</span>
                      </a>
                    </div>
                    <div id="collapseElevenStartup" class="collapse" aria-labelledby="headingElevenStartup" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/startup-solution">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Startup Solution
                            </li>
                            <li routerLink="/fund-raising">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Fund Raise
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3 id="reconciliation-services">Reconciliation Services</h3>
                    <!--<p class="mb-15 mt-20 text-dark servicep">
                      Ensure Accuracy and Compliance with Expert Reconciliation Services
                    </p>-->
                    <p class="mb-15 mt-20 text-dark servicep">
                      Accurate financial reconciliation is crucial for businesses to ensure the integrity of their financial records, detect errors, and maintain regulatory compliance. However, reconciling accounts, transactions, and statements can be a time-consuming and complex task, especially for organizations with high transaction volumes or multiple financial systems. At BFAG we offer specialized reconciliation services designed to help you achieve accurate and streamlined financial reconciliation processes. Our expert team can assist you in ensuring accuracy and compliance through our comprehensive reconciliation services.
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                  Comprehensive Account Reconciliation
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Our experienced professionals excel in performing comprehensive account reconciliations. Whether it involves bank accounts, credit cards, receivables, payables, or other general ledger accounts, our team ensures that transactions are accurately recorded and matched to corresponding statements. By identifying discrepancies and resolving outstanding items, we help you maintain accurate financial records and reduce the risk of errors or fraud.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                            <div class="card-header" id="headingTwelve">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                  Streamlined Transaction Reconciliation
                                                </a>
                                            </div>
                                            <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Reconciling many transactions across different systems or platforms can be challenging. Our reconciliation services include streamlined matching and reconciliation of transactions, such as sales orders, purchase orders, invoices, and payments. We leverage advanced reconciliation tools and automated processes to improve efficiency, accuracy, and timeliness, enabling you to focus on core business activities.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingThirteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                  Bank Reconciliation and Cash Management
                                                </a>
                                            </div>
                                            <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Bank reconciliation is a critical process for maintaining accurate cash balances and identifying discrepancies between your records and bank statements. Our services encompass bank reconciliation, ensuring that your bank transactions, including deposits, withdrawals, and fees, are accurately reflected in your financial records. We help you maintain cash management controls, optimize cash flow, and mitigate the risk of errors or fraudulent activities.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- card -->
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingFourteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                  Regulatory Compliance and Audit Readiness
                                                </a>
                                            </div>
                                            <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Compliance with regulatory requirements and audit readiness are essential for businesses across industries. Our reconciliation services ensure that your financial records adhere to relevant regulations, such as Generally Accepted Accounting Principles (GAAP) or International Financial Reporting Standards (IFRS). By maintaining accurate and reconciled financial data, you can confidently navigate audits, comply with regulatory obligations, and avoid penalties or reputational damage.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                            <div class="card-header" id="headingFifteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                  Timely and Accurate Reporting
                                                </a>
                                            </div>
                                            <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Timely financial reporting relies on accurate and up-to-date reconciliations. Our reconciliation services provide you with comprehensive reports, highlighting reconciled items, outstanding discrepancies, and financial insights. By gaining a clear overview of your financial position, you can make informed decisions, assess financial performance, and plan with confidence.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                            <div class="card-header" id="headingSixteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                  Data Security and Confidentiality
                                                </a>
                                            </div>
                                            <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>We understand the importance of data security and confidentiality in financial reconciliation processes. Our services prioritize the protection of your sensitive financial information. We employ robust security measures, including encrypted data transmission, restricted access controls, and compliance with industry best practices. You can trust that your financial data is handled with the utmost care and confidentiality.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->

                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                  Accurate financial reconciliation is essential for maintaining the integrity of financial records and ensuring compliance. BFAG offers comprehensive reconciliation services tailored to your business's specific needs. By leveraging our expertise, streamlined processes, and adherence to regulatory requirements, you can achieve accurate and efficient financial reconciliations. Contact us today to experience the benefits of our professional reconciliation services.
                </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>
