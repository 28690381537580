<!-- dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center text-uppercase"
      style="border-bottom: 4px solid #21ac51; display: inline-block"
    >
      Registration Under Start-Up India
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0  text-uppercase" routerLink="/startup-solution"
        >Home <i class="fas fa-arrow-circle-right"></i> Registration Under
        Start-Up India</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 justify-content-center align-items-center imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #21ac51; max-width: 420px; height: 100px"
      >
        <img
          src="../../../../assets/images/startup solution/make-in-India-logo.jpg"
          class="img-fluid"
          style="max-width: 350px; position: relative; top: 6rem;"
        />
        <a class="imgBtn btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      In their bid to support entrepreneurship development in India the government of India has started a Startup India initiative.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      
      An entity receiving recognition under the startup India initiative stands to benefit from various incentives such as Tax Exemption for Income, Credit Guarantee, Exemption on Investment above fair market value, rebate in patent filing fee and much more.
      </p>
    </div>
  </div>
</div>
<!-- dubai  end -->

<!-- india started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center text-uppercase"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      Registration Under Start-Up India
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0  text-uppercase" routerLink="/startup-solution"
        >Home <i class="fas fa-arrow-circle-right"></i> Registration Under
        Start-Up India</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 justify-content-center align-items-center imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 420px; height: 100px"
      >
        <img
          src="../../../../assets/images/startup solution/make-in-India-logo.jpg"
          class="img-fluid"
          style="max-width: 350px; position: relative; top: 6rem;"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      In their bid to support entrepreneurship development in India the government of India has started a Startup India initiative.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      
      An entity receiving recognition under the startup India initiative stands to benefit from various incentives such as Tax Exemption for Income, Credit Guarantee, Exemption on Investment above fair market value, rebate in patent filing fee and much more.
      </p>
    </div>
  </div>
</div>
<!-- india  end -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150 container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2
      class="text-center text-uppercase"
      style="border-bottom: 4px solid #0951A4; display: inline-block"
    >
      Registration Under Start-Up India
    </h2>
    <small class="mt-10" style="font-size: 10px"
      ><a class="text-dark border-0  text-uppercase" routerLink="/startup-solution"
        >Home <i class="fas fa-arrow-circle-right"></i> Registration Under
        Start-Up India</a
      ></small
    >
  </div>
  <div class="row mt-50">
    <div class="col-lg-5 justify-content-center align-items-center imgDiv">
      <div
        class="border-0 rounded d-flex justify-content-center align-items-center"
        style="background: #0951A4; max-width: 420px; height: 100px"
      >
        <img
          src="../../../../assets/images/startup solution/make-in-India-logo.jpg"
          class="img-fluid"
          style="max-width: 350px; position: relative; top: 6rem;"
        />
        <a class="imgBtn-india btn"
          ><i class="far fa-chevron-right" style="color: white"></i
        ></a>
      </div>
    </div>
    <div class="col-lg-7 pr-0">
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      In their bid to support entrepreneurship development in India the government of India has started a Startup India initiative.
      </p>
      <p
        class="text-dark mb-30"
        style="font-size: 20px; line-height: normal; font-weight: 500"
      >
      
      An entity receiving recognition under the startup India initiative stands to benefit from various incentives such as Tax Exemption for Income, Credit Guarantee, Exemption on Investment above fair market value, rebate in patent filing fee and much more.
      </p>
    </div>
  </div>
</div>
<!-- USA  end -->
