
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area MEP-Contracting customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">MEP Contracting</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">MEP Contracting</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Horizon for MEP Contracting PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>MEP Contracting Industries</span>
                    <h2 class="title pb-15">Horizon - EBS for MEP Contracting </h2>
                    <p>Mechanical, Electrical, and Plumbing (MEP) contracting have grown along with the growth of the construction industry globally. Most of the MEP contractors find it difficult to manage the projects and run into losses due to a lack of
                        accurate information and data. Horizon - EBS for Mechanical, Electrical, and Plumbing (MEP) is an innovative business management software solution specifically designed for the MEP management and contracting industry. Our Solution
                        empowers you to make management decisions with heightened confidence, as you will have easy access to timely and accurate data as per your need.</p>
                    <p>Horizon Enterprise Business Solution for Mechanical, Electrical, and Plumbing- MEP Management, is an industry-specific ERP solution that covers end-to-end business processes starting from the project creation to the completion of an
                        MEP project. Industry-specific processes of the MEP industry are seamlessly integrated into Horizon with finance, assets, inventory control, procurement, labor and resource allocation, sub-contracting management etc., giving complete
                        visibility that enhances operational efficiency and overall project management.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Horizon for MEP Contracting PART END ======-->

<!--====== CLIENTS PART START ======-->
<div class="brand-2-area pb-120  dec-spacing  gray-bg">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-12">
                <div class="experience-item">
                    <span>Damy Text</span>
                    <h2 class="title pb-15">Damy Text</h2>
                </div>
            </div> -->
            <div class="col-lg-12">
                <div class="brand-active">
                    <div class="brand-item">
                        <img src="assets/images/clients/tes.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/specon.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/saifco.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/lunar.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/izone.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/glx.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/fmt.jpg" alt="brand name">
                    </div>
                </div>
                <!-- brand item -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== CLIENTS PART ENDS ======-->

<!--====== Components PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom ">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="experience-item">
                    <span>Solution Modules</span>
                    <h2 class="title pb-15">Components of MEP Contracting</h2>
                </div>
            </div>
            <div class="col-lg-6 add-arrow-circle">
                <h5 class="title pb-15">Main Components</h5>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/project-accounting">Project Accounting </a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/financial-accounting">Financial Accounting</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/fixed-asset-management">Fixed Asset Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/inventory-management">Procurement and Inventory Control</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/human-resource-management-system"> Human Resource Management System (Standard)</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/approval-management">Approval Management System</a></p>
                <!-- experience item -->
            </div>
            <div class="col-lg-6 add-arrow-circle">
                <h5 class="title pb-15">Additional Components</h5>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/mep-contracting">Supplier Self Service Portal</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/mep-contracting">Approval Management App</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/human-resource-management-system">Human Resource Management System (Advance)</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/pmv-management">Plant, Machinery and Vehicle Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/bank-facility-management">Bank Facility Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/mep-contracting">Dashboard Management</a></p>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Components PART END ======-->

<!--====== Benefits at a glance PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                    <p class="add-arrow">Reduce delays due to shortage of material and equipment by integrating core project management activities with material management, purchase, subcontracting and transportation solutions.
                    </p>
                    <p class="add-arrow">Gives real-time visibility and control over costs with accurate Budgeting & Accounting which automatically Improves profitability.</p>
                    <p class="add-arrow">Schedule, deploy and ensure higher utilization of manpower with effective project management.</p>
                    <p class="add-arrow">Improve customer satisfaction levels and meet service level agreements.</p>

                    <p class="add-arrow">Ensure equipment availability, and maximize utilization.</p>
                    <p class="add-arrow">Obtain better business planning to meet customer demands.</p>
                    <p class="add-arrow">Enhanced profitability and real time visibility to all the phases of MEP management.</p>
                    <p class="add-arrow">Complete visibility of project based resources including assets, inventory, materials and labor.</p>
                    <p>Horizon – EBS for MEP can be deployed on-premise or on the cloud with aligned mobile applications. If you are looking for more details or having queries about Horizon – EBS for MEP, please feel free to Contact Us</p>

                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Benefits at a glance PART END ======-->
