
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area horizon-ebs customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Horizon Enterprise Business Solution</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Horizon EBS</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Business Solution (EBS) PART START ======-->
<div class="horizon-ebs mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>EBS Products</span>
                    <h2 class="title pb-15">Horizon – Enterprise <br/> Business Solution (EBS)</h2>
                    <p>Horizon - EBS is a robust, flexible, secure, and comprehensive Enterprise Resource Planning (ERP) system. It is a powerful, completely integrated, and easy platform to unify all of your company's business processes.</p>
                    <p>Horizon - EBS is sophisticated in its features and simple in its usability. With over 20+ modules and submodules that are integrated and customizable, Horizon-EBS gives you all the features and functions that automate all your business
                        processes. </p>
                    <p>Horizon - EBS offers a modular approach with robust capabilities focused on reducing costs and streamlining processes. It helps in improving customer responsiveness and in achieving continued growth and profitability.</p>
                    <p>With a wide range of industry-specific solutions from Horizon - EBS, you can be sure and confident that your business management solution will meet both the present and the future demands of your customers. Horizon - EBS is developed
                        by keeping in mind all sizes of organizations of various industry sectors such as Construction, MEP Contracting, General Contracting, Facility Management, Trading and Distribution, Manufacturing, and Real Estate, etc. The solutions
                        offer deep, industry-specific functionality within each of these sectors.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Business Solution (EBS) PART end ======-->

<!--====== Components  PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="experience-item">
                    <span>Solution Modules</span>
                    <h2 class="title pb-15">Components of EBS</h2>
                </div>
            </div>

            <div class="col-lg-6 add-arrow-circle">
                <h5 class="title pb-15">Main Components</h5>

                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/financial-accounting">Financial Accounting</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/inventory-management">Procurement and Inventory Control</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/project-accounting">Project Accounting</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/human-resource-management-system">Human Resource Management System (Standard) </a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/facility-management">Facility Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/pmv-management">Plant, Machinery and Vehicle Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/fixed-asset-management">Fixed Asset Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/bank-facility-management">Bank Facility Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/real-estate-management">Real Estate Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/sales-management">Sales Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/manufacturing-management">Manufacturing Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/approval-management">Approval Management System</a></p>

                <!-- experience item -->
            </div>
            <div class="col-lg-6 add-arrow-circle">
                <h5 class="title pb-15">Additional Components</h5>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/horizon-ebs">Supplier Self Service Portal</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/horizon-ebs">Approval Management App</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/human-resource-management-system">Human Resource Management System (Advance)</a></p>

                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Components  PART START ======-->

<!--====== Key Features PART START ======-->
<div class="about-faq-area about-faq-area-custom dec-spacing bg_cover horizon-ebs">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Highlights</span>
                    <h2 class="title pb-20">Key Features</h2>
                </div>
            </div>
            <div class="faq-accordion-3 pt-0">
                <div class="accordion" id="accordionExample">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Dashboard Management
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Very sophisticated dashboards are exclusively designed to offer you complete visibility and control over the business processes in your organization. The dashboards are powerful enough to convert large volume of data
                                        into visual cues that enables you to perform efficient decision making. It gathers critical information and presents it in a simple format that can be interpreted at one glance. </p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Workflow Management
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Complete work flow management system to manage the hierarchical approval in various function. Workflow management system designed in unique way to provide complete visibility of the approval processes in an organization,
                                        it minimizes the delay on gaining approvals and eliminates paper usage. </p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Transactions in Multi Currency
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>It supports transactions in multiple currencies with user-definable exchange rates. Reports can be hand in all currencies.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <i class="fal fa-magic"></i>Multi Company and Multi Locations
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>It empowers you to take care of numerous subsidiary companies or branches across the globe that operates under the corporate headquarters adequately. It is equipped with multi-company management abilities so that it
                                        can manage financial consolidation required for separate subsidiary companies along with multi-company reporting and business intelligence.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSix">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    <i class="fal fa-magic"></i>Modular Design
                                </a>
                            </div>
                            <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Integrated modular design which covers Financial Accounting, Procurement and Inventory control, Sales Management, Project management, Human Resource and Payroll and many more. Our solutions are basically end to end
                                        business solutions but can be deployed as modular solutions and scalable to add more modules and features later.</p>
                                </div>
                            </div>
                        </div>


                        <!-- faq accordion -->
                    </div>
                    <div class="col-lg-6">
                        <!-- card -->

                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingSev">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSev" aria-expanded="false" aria-controls="collapseSev">
                                    <i class="fal fa-magic"></i>Flexible year-end operations
                                </a>
                            </div>
                            <div id="collapseSev" class="collapse" aria-labelledby="headingSev" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>No Need to wait for the Financial Year to End for Trial Balance, Profit & Loss & Balance Sheet Reports. You can keep any number of accounting years simultaneously open. They are all one click away at any time. Auto
                                        generation of closing JV & P&L items. Auto transfer of closing balance to next year. </p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEight">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    <i class="fal fa-magic"></i>Reporting Wizard
                                </a>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>It has a rich set of standard reports. All can be customized as per the requirement. Besides, these reports can be printed with or Without Letter Heads, Can Be Exported to various Formats like PDF, RTF, Excel etc.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingNine">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    <i class="fal fa-magic"></i>Extensive Search Facility
                                </a>
                            </div>
                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Powerful Enquiry with Extensive Search Facility in all major forms along with Date Filters, Various Search Options, Various Sort Options, Ascending Order Or Descending Order Display Options, No. of Records per Page
                                        View Options to be selected by the user.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingTen">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                    <i class="fal fa-magic"></i>Powerful Security Features
                                </a>
                            </div>
                            <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Powerful Multi-level Security features. User defined transaction types as well as voucher types with individual security. User Wise – Transaction wise user rights can be given</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== Key Features PART end ======-->

<!--====== Benefits at a glance PART start ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                    <p class="add-arrow"> Integrates all aspects of the business processes; sales, customer services, finance, procurement, inventory and HR. It provides more efficient work flow and improved productivity.
                    </p>
                    <p class="add-arrow"> Reduce and Eliminate paper usage, create an eco-friendly office environment by using the electronic approval management system. Put an end to manual process inefficiencies by business processes automation.</p>
                    <p class="add-arrow"> Very sophisticated dashboard for bird’s eye view. Dashboards to provide real-time graphical overview of the entire system.</p>
                    <p class="add-arrow"> The implementation helps organizations to keep lower cost, better quality and quicker delivery.</p>
                    <p class="add-arrow"> It provides everyone in the organization on the same page looking at the same real-time data. Complete visibility into resources including assets, inventory, materials, and labor.</p>
                    <p class="add-arrow"> The departmental benefits include faster processing, accuracy, production and efficiency.</p>

                    <p class="add-arrow"> The systems provide right information to the right people at the right time anywhere in the world, enables you to improve productivity, enhance decision making and fast communication between employees, customers and vendors.</p>
                    <p class="add-arrow"> It eliminates duplicate work, repeated data entry, and automates all processes. It provides easy access to information. Reduce overhead costs and optimize the use of resources.</p>
                    <p class="add-arrow"> Achieve exceptional customer satisfaction & improve customer retention rates.</p>
                    <p class="add-arrow"> The system standardizes the processes and improves overall quality.</p>
                </div>
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Benefits at a glance PART ENDS ======-->
