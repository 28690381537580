<!-- dubai started -->
<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase text-center">
        IT COMPANY CASE <br />
        STUDY
      </h3>
    </div>
  </div>
  <div class="container-fluid">
  <div
    class="d-flex row justify-content-center align-items-start pl-4 pr-4"
    style="gap: 2rem"
  >
    <div
      class="col-lg-8 mt-50 mb-50 border shadow"
      style="padding: 2rem"
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img
          class="img-fluid"
          src="../../../../assets/images/case-studies/IT.png"
          alt=""
          style="margin-bottom: 2rem"
        />
        <div>
          <div
            style="
              background: #418052;
              display: inline;
              width: fit-content;
              padding: 0.2rem 1rem 0.2rem 1rem;
            "
          >
            <small
              class="text-uppercase text-white"
              style="font-weight: 600 !important; font-size: 15px !important"
              >case details</small
            >
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >overview</span
              >
            </div>
            <p class="text-dark servicep">
              Client is into providing customized & cost effective solutions in
              the areas of SAP/JD Edwards implementation such as Business
              Process Reengineering, Expert Consulting, Training, Solution
              reviews, Safeguarding Services, Post implementation maintenance
              support. The client is having operations spread over places such
              as Australia, USA, Malaysia, South America, Africa apart from
              India.
            </p>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >Client</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Presence in multiple jurisdictions with legal entities for ease
                of local jurisdiction. Multiple office locations within India.
                Employees travelling to various locations across the globe for
                project execution.
              </li>
              <li>
                Specialization in SAP, JD Edwards, Apriso, IT infrastructure
                operations support.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >Strategies Described</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Defining the proper off-site Accounting organization structure
                and providing capable resources.
              </li>
              <li>
                Defining the SLA’s for keeping the F & A function online,
                defining the roadmap for ongoing accounting activity.
              </li>
              <li>
                Providing consulting solution for creating the group structure
                globally.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >Client's Challenges</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Defining the proper off-site Accounting organization structure
                and providing capable resources.
              </li>
              <li>
                Defining the SLA’s for keeping the F & A function online,
                defining the roadmap for ongoing accounting activity.
              </li>
              <li>
                Providing consulting solution for creating the group structure
                globally.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >Solution Provider</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Providing experienced and capable team to manage the global F &
                A function, establishing accounting organization structure to
                suit the global expansion. Defining proper SLA’s, Compliance
                Calendar, Structuring the MIS to highlight on the KPI’s.
              </li>
              <li>
                Online solution for Employee expense management, right from
                submission of reports, to verification, approval, processing,
                payments, MIS.
              </li>
              <li>
                Consolidation reporting and Dashboard for MIS which can be
                viewed 24 * 7 from anywhere.
              </li>
              <li>Employee self service module for Payroll.</li>
              <li>Providing the global corporate structure.</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #418052 !important;
                "
                >Outcome</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Tax efficient Global corporate structure with due regards to
                wealth creation.
              </li>
              <li>
                Well defined accounting process, proper internal controls.
              </li>
              <li>
                Excellent coordination between operations and Accounting
                function.
              </li>
              <li>
                Accurate & Timely consolidated reporting which has facilitated
                quick decision making.
              </li>
              <li>
                Management time on accounting & finance function has been
                restricted to authorizations and discussion on MIS.
              </li>
              <li>
                Negligible employee grievance on account of expenses reports and
                payroll processing.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--<div
      class="mt-50 border d-flex flex-column justify-content-start align-items-start"
      style="padding: 2rem"
    >
      <div class="mt-15 mb-10 d-flex justify-content-center align-items-center" style="gap: 5px;">
        <div class="" style="border: 3px solid #072F5F; width: 1.5rem; height: 1px;"></div>
        <div class="" style="border: 3px solid #072F5F; width: 0.5rem; height: 1px;"></div>
        <div>
          <span
            class="text-capitalize"
            style="
              font-size: 1.5rem !important;
              font-weight: 700 !important;
              color: #002249 !important;
            "
            >Case Studies</span
          >
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="width: 18rem; gap: 1rem; padding: 1rem; border-bottom: 2px solid #E3EEFF;"
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img1.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Facilities <br />
            Management</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 19, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="width: 18rem; gap: 1rem; padding: 1rem; border-bottom: 2px solid #E3EEFF;"
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img2.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Specialities Co. <br />
            L.L.C</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 7, 2020</small
            >
          </div>
        </div>
      </div>
      <div
      class="d-flex justify-content-start align-items-start"
      style="width: 18rem; gap: 1rem; padding: 1rem; border-bottom: 2px solid #E3EEFF;"
    >
      <img
        class="img-fluid"
        src="../../../../../assets/images/case-studies/img3.png"
        alt="Card image cap"
      />
      <div>
        <small
          class="text-capitalize text-dark"
          style="font-weight: 600 !important; font-size: 15px !important"
          >Specon LLC</small
        >
        <div>
          <small
            class="text-capitalize"
            style="
              font-weight: 600 !important;
              font-size: 13px !important;
              color: #69a1bb;
            "
            ><i class="fas fa-calendar-alt"></i> Jun 17, 2020</small
          >
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-start align-items-start"
      style="width: 18rem; gap: 1rem; padding: 1rem; border-bottom: 2px solid #E3EEFF;"
    >
      <img
        class="img-fluid"
        src="../../../../../assets/images/case-studies/img4.png"
        alt="Card image cap"
      />
      <div>
        <small
          class="text-capitalize text-dark"
          style="font-weight: 600 !important; font-size: 15px !important"
          >Al Ahmadiah <br>
          Contracting & Trading</small
        >
        <div>
          <small
            class="text-capitalize"
            style="
              font-weight: 600 !important;
              font-size: 13px !important;
              color: #69a1bb;
            "
            ><i class="fas fa-calendar-alt"></i> Jun 13, 2020</small
          >
        </div>
      </div>
    </div>

    </div>-->
  </div>
</div>
</section>
<!-- dubai ends -->

<!-- india started -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase text-center">
        IT COMPANY CASE <br />
        STUDY
      </h3>
    </div>
  </div>
  <div class="container-fluid">
  <div
    class="d-flex row justify-content-center align-items-start pl-4 pr-4"
    style="gap: 2rem"
  >
    <div
      class="col-lg-8 mt-50 mb-50 border shadow"
      style="padding: 2rem"
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img
          class="img-fluid"
          src="../../../../assets/images/case-studies/IT.png"
          alt=""
          style="margin-bottom: 2rem"
        />
        <div>
          <div
            style="
              background: #072f5f;
              display: inline;
              width: fit-content;
              padding: 0.2rem 1rem 0.2rem 1rem;
            "
          >
            <small
              class="text-uppercase text-white"
              style="font-weight: 600 !important; font-size: 15px !important"
              >case details</small
            >
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >overview</span
              >
            </div>
            <p class="text-dark servicep">
              Client is into providing customized & cost effective solutions in
              the areas of SAP/JD Edwards implementation such as Business
              Process Reengineering, Expert Consulting, Training, Solution
              reviews, Safeguarding Services, Post implementation maintenance
              support. The client is having operations spread over places such
              as Australia, USA, Malaysia, South America, Africa apart from
              India.
            </p>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Client</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Presence in multiple jurisdictions with legal entities for ease
                of local jurisdiction. Multiple office locations within India.
                Employees travelling to various locations across the globe for
                project execution.
              </li>
              <li>
                Specialization in SAP, JD Edwards, Apriso, IT infrastructure
                operations support.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Strategies Described</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Defining the proper off-site Accounting organization structure
                and providing capable resources.
              </li>
              <li>
                Defining the SLA’s for keeping the F & A function online,
                defining the roadmap for ongoing accounting activity.
              </li>
              <li>
                Providing consulting solution for creating the group structure
                globally.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Client's Challenges</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Defining the proper off-site Accounting organization structure
                and providing capable resources.
              </li>
              <li>
                Defining the SLA’s for keeping the F & A function online,
                defining the roadmap for ongoing accounting activity.
              </li>
              <li>
                Providing consulting solution for creating the group structure
                globally.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Solution Provider</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Providing experienced and capable team to manage the global F &
                A function, establishing accounting organization structure to
                suit the global expansion. Defining proper SLA’s, Compliance
                Calendar, Structuring the MIS to highlight on the KPI’s.
              </li>
              <li>
                Online solution for Employee expense management, right from
                submission of reports, to verification, approval, processing,
                payments, MIS.
              </li>
              <li>
                Consolidation reporting and Dashboard for MIS which can be
                viewed 24 * 7 from anywhere.
              </li>
              <li>Employee self service module for Payroll.</li>
              <li>Providing the global corporate structure.</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Outcome</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Tax efficient Global corporate structure with due regards to
                wealth creation.
              </li>
              <li>
                Well defined accounting process, proper internal controls.
              </li>
              <li>
                Excellent coordination between operations and Accounting
                function.
              </li>
              <li>
                Accurate & Timely consolidated reporting which has facilitated
                quick decision making.
              </li>
              <li>
                Management time on accounting & finance function has been
                restricted to authorizations and discussion on MIS.
              </li>
              <li>
                Negligible employee grievance on account of expenses reports and
                payroll processing.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--<div
      class="mt-50 border d-flex flex-column justify-content-start align-items-start"
      style="padding: 2rem"
    >
      <div class="mt-15 mb-10 d-flex justify-content-center align-items-center" style="gap: 5px;">
        <div class="" style="border: 3px solid #072F5F; width: 1.5rem; height: 1px;"></div>
        <div class="" style="border: 3px solid #072F5F; width: 0.5rem; height: 1px;"></div>
        <div>
          <span
            class="text-capitalize"
            style="
              font-size: 1.5rem !important;
              font-weight: 700 !important;
              color: #002249 !important;
            "
            >Case Studies</span
          >
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="width: 18rem; gap: 1rem; padding: 1rem; border-bottom: 2px solid #E3EEFF;"
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img1.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Facilities <br />
            Management</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 19, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="width: 18rem; gap: 1rem; padding: 1rem; border-bottom: 2px solid #E3EEFF;"
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img2.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Specialities Co. <br />
            L.L.C</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 7, 2020</small
            >
          </div>
        </div>
      </div>
      <div
      class="d-flex justify-content-start align-items-start"
      style="width: 18rem; gap: 1rem; padding: 1rem; border-bottom: 2px solid #E3EEFF;"
    >
      <img
        class="img-fluid"
        src="../../../../../assets/images/case-studies/img3.png"
        alt="Card image cap"
      />
      <div>
        <small
          class="text-capitalize text-dark"
          style="font-weight: 600 !important; font-size: 15px !important"
          >Specon LLC</small
        >
        <div>
          <small
            class="text-capitalize"
            style="
              font-weight: 600 !important;
              font-size: 13px !important;
              color: #69a1bb;
            "
            ><i class="fas fa-calendar-alt"></i> Jun 17, 2020</small
          >
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-start align-items-start"
      style="width: 18rem; gap: 1rem; padding: 1rem; border-bottom: 2px solid #E3EEFF;"
    >
      <img
        class="img-fluid"
        src="../../../../../assets/images/case-studies/img4.png"
        alt="Card image cap"
      />
      <div>
        <small
          class="text-capitalize text-dark"
          style="font-weight: 600 !important; font-size: 15px !important"
          >Al Ahmadiah <br>
          Contracting & Trading</small
        >
        <div>
          <small
            class="text-capitalize"
            style="
              font-weight: 600 !important;
              font-size: 13px !important;
              color: #69a1bb;
            "
            ><i class="fas fa-calendar-alt"></i> Jun 13, 2020</small
          >
        </div>
      </div>
    </div>

    </div>-->
  </div></div>
</section>
<!-- india ends -->

<!-- USA started -->
<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop divmart">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/case-studies/case-study-main-banner.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase text-center">
        IT COMPANY CASE <br />
        STUDY
      </h3>
    </div>
  </div>
  <div class="container-fluid">
  <div
    class="d-flex row justify-content-center align-items-start pl-4 pr-4"
    style="gap: 2rem"
  >
    <div
      class="col-lg-8 mt-50 mb-50 border shadow"
      style="padding: 2rem"
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img
          class="img-fluid"
          src="../../../../assets/images/case-studies/IT.png"
          alt=""
          style="margin-bottom: 2rem"
        />
        <div>
          <div
            style="
              background: #072f5f;
              display: inline;
              width: fit-content;
              padding: 0.2rem 1rem 0.2rem 1rem;
            "
          >
            <small
              class="text-uppercase text-white"
              style="font-weight: 600 !important; font-size: 15px !important"
              >case details</small
            >
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >overview</span
              >
            </div>
            <p class="text-dark servicep">
              Client is into providing customized & cost effective solutions in
              the areas of SAP/JD Edwards implementation such as Business
              Process Reengineering, Expert Consulting, Training, Solution
              reviews, Safeguarding Services, Post implementation maintenance
              support. The client is having operations spread over places such
              as Australia, USA, Malaysia, South America, Africa apart from
              India.
            </p>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Client</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Presence in multiple jurisdictions with legal entities for ease
                of local jurisdiction. Multiple office locations within India.
                Employees travelling to various locations across the globe for
                project execution.
              </li>
              <li>
                Specialization in SAP, JD Edwards, Apriso, IT infrastructure
                operations support.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Strategies Described</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Defining the proper off-site Accounting organization structure
                and providing capable resources.
              </li>
              <li>
                Defining the SLA’s for keeping the F & A function online,
                defining the roadmap for ongoing accounting activity.
              </li>
              <li>
                Providing consulting solution for creating the group structure
                globally.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Client's Challenges</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Defining the proper off-site Accounting organization structure
                and providing capable resources.
              </li>
              <li>
                Defining the SLA’s for keeping the F & A function online,
                defining the roadmap for ongoing accounting activity.
              </li>
              <li>
                Providing consulting solution for creating the group structure
                globally.
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Solution Provider</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Providing experienced and capable team to manage the global F &
                A function, establishing accounting organization structure to
                suit the global expansion. Defining proper SLA’s, Compliance
                Calendar, Structuring the MIS to highlight on the KPI’s.
              </li>
              <li>
                Online solution for Employee expense management, right from
                submission of reports, to verification, approval, processing,
                payments, MIS.
              </li>
              <li>
                Consolidation reporting and Dashboard for MIS which can be
                viewed 24 * 7 from anywhere.
              </li>
              <li>Employee self service module for Payroll.</li>
              <li>Providing the global corporate structure.</li>
            </ul>
          </div>
          <div>
            <div class="mt-15">
              <span
                class="text-capitalize"
                style="
                  font-size: 1.5rem !important;
                  font-weight: 700 !important;
                  color: #002249 !important;
                "
                >Outcome</span
              >
            </div>
            <ul class="text-dark servicep" style="list-style-type: disc">
              <li>
                Tax efficient Global corporate structure with due regards to
                wealth creation.
              </li>
              <li>
                Well defined accounting process, proper internal controls.
              </li>
              <li>
                Excellent coordination between operations and Accounting
                function.
              </li>
              <li>
                Accurate & Timely consolidated reporting which has facilitated
                quick decision making.
              </li>
              <li>
                Management time on accounting & finance function has been
                restricted to authorizations and discussion on MIS.
              </li>
              <li>
                Negligible employee grievance on account of expenses reports and
                payroll processing.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--<div
      class="mt-50 border d-flex flex-column justify-content-start align-items-start"
      style="padding: 2rem"
    >
      <div class="mt-15 mb-10 d-flex justify-content-center align-items-center" style="gap: 5px;">
        <div class="" style="border: 3px solid #072F5F; width: 1.5rem; height: 1px;"></div>
        <div class="" style="border: 3px solid #072F5F; width: 0.5rem; height: 1px;"></div>
        <div>
          <span
            class="text-capitalize"
            style="
              font-size: 1.5rem !important;
              font-weight: 700 !important;
              color: #002249 !important;
            "
            >Case Studies</span
          >
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="width: 18rem; gap: 1rem; padding: 1rem; border-bottom: 2px solid #E3EEFF;"
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img1.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Facilities <br />
            Management</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 19, 2020</small
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-start"
        style="width: 18rem; gap: 1rem; padding: 1rem; border-bottom: 2px solid #E3EEFF;"
      >
        <img
          class="img-fluid"
          src="../../../../../assets/images/case-studies/img2.png"
          alt="Card image cap"
        />
        <div>
          <small
            class="text-capitalize text-dark"
            style="font-weight: 600 !important; font-size: 15px !important"
            >Emirates Specialities Co. <br />
            L.L.C</small
          >
          <div>
            <small
              class="text-capitalize"
              style="
                font-weight: 600 !important;
                font-size: 13px !important;
                color: #69a1bb;
              "
              ><i class="fas fa-calendar-alt"></i> Jul 7, 2020</small
            >
          </div>
        </div>
      </div>
      <div
      class="d-flex justify-content-start align-items-start"
      style="width: 18rem; gap: 1rem; padding: 1rem; border-bottom: 2px solid #E3EEFF;"
    >
      <img
        class="img-fluid"
        src="../../../../../assets/images/case-studies/img3.png"
        alt="Card image cap"
      />
      <div>
        <small
          class="text-capitalize text-dark"
          style="font-weight: 600 !important; font-size: 15px !important"
          >Specon LLC</small
        >
        <div>
          <small
            class="text-capitalize"
            style="
              font-weight: 600 !important;
              font-size: 13px !important;
              color: #69a1bb;
            "
            ><i class="fas fa-calendar-alt"></i> Jun 17, 2020</small
          >
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-start align-items-start"
      style="width: 18rem; gap: 1rem; padding: 1rem; border-bottom: 2px solid #E3EEFF;"
    >
      <img
        class="img-fluid"
        src="../../../../../assets/images/case-studies/img4.png"
        alt="Card image cap"
      />
      <div>
        <small
          class="text-capitalize text-dark"
          style="font-weight: 600 !important; font-size: 15px !important"
          >Al Ahmadiah <br>
          Contracting & Trading</small
        >
        <div>
          <small
            class="text-capitalize"
            style="
              font-weight: 600 !important;
              font-size: 13px !important;
              color: #69a1bb;
            "
            ><i class="fas fa-calendar-alt"></i> Jun 13, 2020</small
          >
        </div>
      </div>
    </div>

    </div>-->
  </div></div>
</section>
<!-- USA ends -->
