<!-- dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150">
    <div class="col-lg-12 col-md-12">
        <div class="p-3 mb-5 bg-white">
            <div class="row">
                <div
                    class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3">
                    <div class="" style="padding: 1%">
                        <img style="border-radius: 10px; width: 100%" class="img-fluid"
                            src="../../../../../assets/images/teams/AMehta.jpg" alt="Anoop Natwar Mehta" />
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start">
                    <h3 class="text-uppercase text-dark title" style="font-size: 20px; font-weight: 700;">
                        Anoop Natwar Mehta
                    </h3>
                    <div>
                        <span class="text-uppercase text-dark" style="font-size: 16px; font-weight: 600">
                            ADVISOR
                        </span>
                        <a style="color: #fff" href="https://www.linkedin.com/in/anoop-n-mehta-cpa-cgma-2741ab1b/"
                            target="_blank">
                            <i class="fab fa-linkedin-in" style="
            margin-left: 10px;
            background-color: #0072b1;
            padding: 4px;
            border-radius: 5px;
          "></i>
                        </a>
                    </div>
                    <p class="mt-20 text-dark text-justify" style="font-size: 16px !important">
                        Mr. Anoop Natwar Mehta, CPA, CGMA, is an esteemed leader in the accounting, taxation and finance
                        profession, renowned for his extensive contributions and trailblazing achievements. As the Past
                        Chairman of the American Institute of CPAs (AICPA) and the Immediate Past Chairman of the
                        Association of International Certified Professional Accountants, Anoop made history as the first
                        person of Indian origin and only the second minority to chair the AICPA in its 135-year history.
                    </p>

                    <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                        Currently, Anoop serves as the Chief Strategist for Analytical Mechanics Associates, Inc. (AMA),
                        a firm known for tackling the most challenging engineering, science, and business problems for
                        both government and commercial entities. In this role, he provides his expertise in business
                        development, strategic planning, and operational improvements, driving significant advancements
                        across AMA.
                    </p>

                    <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                        Anoop's distinguished career includes a notable tenure as the president of Science Systems and
                        Applications, Inc. (SSAI), where he led the executive management team and oversaw the company's
                        overall performance. Under his leadership, SSAI grew from a two-person startup to a thriving
                        organization with over 1,000 employees, consistently achieving profitability above target
                        levels. His more than 40 years of experience in government contracting, particularly in
                        supporting NASA and NOAA programs, underscores his exceptional leadership and strategic vision.
                    </p>

                    <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">

                        A dedicated volunteer, Anoop serves as the Chairman of the AICPA’s National Commission on
                        Diversity and Inclusion (NCDI). His commitment to the profession is further evidenced by his
                        numerous roles within the AICPA and
                    </p>
                </div>
            </div>
            <div class="col-lg-12 pl-0 pr-0">
                <p class="mt-1 text-dark text-justify" style="font-size: 16px !important"> His involvement in
                    CIMA, including positions on the AICPA Board of Directors, the Association Board of Directors, the
                    AICPA Business and Industry Executive Committee, the AICPA Political Action Committee, and the AICPA
                    Council.
                </p>
                <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Anoop's leadership extends to the Maryland Association of Certified Public Accountants (MACPA),
                    where he served as Chair (2012-2013) and on the Government Contractors Committee, which he chaired
                    from 2001 to 2003. His academic background includes a Bachelor of Science degree in Accounting from
                    the University of Maryland, College Park, and he continues to contribute to his alma mater as a
                    member of the Board of Advisors for the Robert H. Smith School of Business and the Accounting and
                    Information Assurance Department.
                </p>
                <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    In addition to his professional accomplishments, Anoop is recognized for his enterprising spirit. He
                    is a seventh-degree Master Black Belt in Tae Kwon Do and has been teaching martial arts and
                    operating a karate school for over 35 years, demonstrating his dedication, discipline, and
                    leadership in all aspects of his life.
                </p>

                <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Anoop N. Mehta's profound influence and unwavering commitment to excellence continue to inspire and
                    drive the accounting profession forward, making him a highly respected and impactful leader.
                </p>

                <!-- <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Prafulla has also been a prominent figure in the media, having written
                    a weekly column titled 'Paisa Jhala Motha' for the renowned Marathi
                    daily 'Maharashtra Times' for almost two decades.
                </p> -->

                <!-- <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Additionally, Prafulla has been involved in various committees and
                    organizations, such as the Extensible Business Reporting Language
                    (XBRL) India, Banking & Finance Committee of IMC Chamber of Commerce,
                    CII National Committee on Financial Reporting.
                </p> -->

                <!-- <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Through his diverse leadership roles, extensive industry
                    contributions, and remarkable achievements, Prafulla has made an
                    indelible impact on the accountancy profession and continues to be a
                    respected figure in the field.
                </p> -->
            </div>
        </div>
    </div>
</div>
<!-- dubai ends -->

<!-- india started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150">
    <div class="col-lg-12 col-md-12">
        <div class="p-3 mb-5 bg-white">
            <div class="row">
                <div
                    class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3">
                    <div class="" style="padding: 1%">
                        <img style="border-radius: 10px; width: 100%" class="img-fluid"
                            src="../../../../../assets/images/teams/AMehta.jpg" alt="Anoop Natwar Mehta" />
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start">
                    <h3 class="text-uppercase text-dark title" style="font-size: 20px; font-weight: 700;">
                        Anoop Natwar Mehta
                    </h3>
                    <div>
                        <span class="text-uppercase text-dark" style="font-size: 16px; font-weight: 600">
                            ADVISOR
                        </span>
                        <a style="color: #fff" href="https://www.linkedin.com/in/anoop-n-mehta-cpa-cgma-2741ab1b/"
                            target="_blank">
                            <i class="fab fa-linkedin-in" style="
            margin-left: 10px;
            background-color: #0072b1;
            padding: 4px;
            border-radius: 5px;
          "></i>
                        </a>
                    </div>
                    <p class="mt-20 text-dark text-justify" style="font-size: 16px !important">
                        Mr. Anoop Natwar Mehta, CPA, CGMA, is an esteemed leader in the accounting, taxation and finance
                        profession, renowned for his extensive contributions and trailblazing achievements. As the Past
                        Chairman of the American Institute of CPAs (AICPA) and the Immediate Past Chairman of the
                        Association of International Certified Professional Accountants, Anoop made history as the first
                        person of Indian origin and only the second minority to chair the AICPA in its 135-year history.
                    </p>

                    <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                        Currently, Anoop serves as the Chief Strategist for Analytical Mechanics Associates, Inc. (AMA),
                        a firm known for tackling the most challenging engineering, science, and business problems for
                        both government and commercial entities. In this role, he provides his expertise in business
                        development, strategic planning, and operational improvements, driving significant advancements
                        across AMA.
                    </p>

                    <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                        Anoop's distinguished career includes a notable tenure as the president of Science Systems and
                        Applications, Inc. (SSAI), where he led the executive management team and oversaw the company's
                        overall performance. Under his leadership, SSAI grew from a two-person startup to a thriving
                        organization with over 1,000 employees, consistently achieving profitability above target
                        levels. His more than 40 years of experience in government contracting, particularly in
                        supporting NASA and NOAA programs, underscores his exceptional leadership and strategic vision.
                    </p>

                    <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">

                        A dedicated volunteer, Anoop serves as the Chairman of the AICPA’s National Commission on
                        Diversity and Inclusion (NCDI). His commitment to the profession is further evidenced by his
                        numerous roles within the AICPA and
                    </p>
                </div>
            </div>
            <div class="col-lg-12 pl-0 pr-0">
                <p class="mt-1 text-dark text-justify" style="font-size: 16px !important"> His involvement in
                    CIMA, including positions on the AICPA Board of Directors, the Association Board of Directors, the
                    AICPA Business and Industry Executive Committee, the AICPA Political Action Committee, and the AICPA
                    Council.
                </p>
                <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Anoop's leadership extends to the Maryland Association of Certified Public Accountants (MACPA),
                    where he served as Chair (2012-2013) and on the Government Contractors Committee, which he chaired
                    from 2001 to 2003. His academic background includes a Bachelor of Science degree in Accounting from
                    the University of Maryland, College Park, and he continues to contribute to his alma mater as a
                    member of the Board of Advisors for the Robert H. Smith School of Business and the Accounting and
                    Information Assurance Department.
                </p>
                <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    In addition to his professional accomplishments, Anoop is recognized for his enterprising spirit. He
                    is a seventh-degree Master Black Belt in Tae Kwon Do and has been teaching martial arts and
                    operating a karate school for over 35 years, demonstrating his dedication, discipline, and
                    leadership in all aspects of his life.
                </p>

                <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Anoop N. Mehta's profound influence and unwavering commitment to excellence continue to inspire and
                    drive the accounting profession forward, making him a highly respected and impactful leader.
                </p>

                <!-- <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Prafulla has also been a prominent figure in the media, having written
                    a weekly column titled 'Paisa Jhala Motha' for the renowned Marathi
                    daily 'Maharashtra Times' for almost two decades.
                </p> -->

                <!-- <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Additionally, Prafulla has been involved in various committees and
                    organizations, such as the Extensible Business Reporting Language
                    (XBRL) India, Banking & Finance Committee of IMC Chamber of Commerce,
                    CII National Committee on Financial Reporting.
                </p> -->

                <!-- <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Through his diverse leadership roles, extensive industry
                    contributions, and remarkable achievements, Prafulla has made an
                    indelible impact on the accountancy profession and continues to be a
                    respected figure in the field.
                </p> -->
            </div>
        </div>
    </div>
</div>
<!-- india ends -->



<!-- USA Started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150">
    <div class="col-lg-12 col-md-12">
        <div class="p-3 mb-5 bg-white">
            <div class="row">
                <div
                    class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3">
                    <div class="" style="padding: 1%">
                        <img style="border-radius: 10px; width: 100%" class="img-fluid"
                            src="../../../../../assets/images/teams/AMehta.jpg" alt="Anoop Natwar Mehta" />
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start">
                    <h3 class="text-uppercase text-dark title" style="font-size: 20px; font-weight: 700;">
                        Anoop Natwar Mehta
                    </h3>
                    <div>
                        <span class="text-uppercase text-dark" style="font-size: 16px; font-weight: 600">
                            ADVISOR
                        </span>
                        <a style="color: #fff" href="https://www.linkedin.com/in/anoop-n-mehta-cpa-cgma-2741ab1b/"
                            target="_blank">
                            <i class="fab fa-linkedin-in" style="
            margin-left: 10px;
            background-color: #0072b1;
            padding: 4px;
            border-radius: 5px;
          "></i>
                        </a>
                    </div>
                    <p class="mt-20 text-dark text-justify" style="font-size: 16px !important">
                        Mr. Anoop Natwar Mehta, CPA, CGMA, is an esteemed leader in the accounting, taxation and finance
                        profession, renowned for his extensive contributions and trailblazing achievements. As the Past
                        Chairman of the American Institute of CPAs (AICPA) and the Immediate Past Chairman of the
                        Association of International Certified Professional Accountants, Anoop made history as the first
                        person of Indian origin and only the second minority to chair the AICPA in its 135-year history.
                    </p>

                    <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                        Currently, Anoop serves as the Chief Strategist for Analytical Mechanics Associates, Inc. (AMA),
                        a firm known for tackling the most challenging engineering, science, and business problems for
                        both government and commercial entities. In this role, he provides his expertise in business
                        development, strategic planning, and operational improvements, driving significant advancements
                        across AMA.
                    </p>

                    <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                        Anoop's distinguished career includes a notable tenure as the president of Science Systems and
                        Applications, Inc. (SSAI), where he led the executive management team and oversaw the company's
                        overall performance. Under his leadership, SSAI grew from a two-person startup to a thriving
                        organization with over 1,000 employees, consistently achieving profitability above target
                        levels. His more than 40 years of experience in government contracting, particularly in
                        supporting NASA and NOAA programs, underscores his exceptional leadership and strategic vision.
                    </p>

                    <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">

                        A dedicated volunteer, Anoop serves as the Chairman of the AICPA’s National Commission on
                        Diversity and Inclusion (NCDI). His commitment to the profession is further evidenced by his
                        numerous roles within the AICPA and
                    </p>
                </div>
            </div>
            <div class="col-lg-12 pl-0 pr-0">
                <p class="mt-1 text-dark text-justify" style="font-size: 16px !important"> His involvement in
                    CIMA, including positions on the AICPA Board of Directors, the Association Board of Directors, the
                    AICPA Business and Industry Executive Committee, the AICPA Political Action Committee, and the AICPA
                    Council.
                </p>
                <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Anoop's leadership extends to the Maryland Association of Certified Public Accountants (MACPA),
                    where he served as Chair (2012-2013) and on the Government Contractors Committee, which he chaired
                    from 2001 to 2003. His academic background includes a Bachelor of Science degree in Accounting from
                    the University of Maryland, College Park, and he continues to contribute to his alma mater as a
                    member of the Board of Advisors for the Robert H. Smith School of Business and the Accounting and
                    Information Assurance Department.
                </p>
                <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    In addition to his professional accomplishments, Anoop is recognized for his enterprising spirit. He
                    is a seventh-degree Master Black Belt in Tae Kwon Do and has been teaching martial arts and
                    operating a karate school for over 35 years, demonstrating his dedication, discipline, and
                    leadership in all aspects of his life.
                </p>

                <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Anoop N. Mehta's profound influence and unwavering commitment to excellence continue to inspire and
                    drive the accounting profession forward, making him a highly respected and impactful leader.
                </p>

                <!-- <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Prafulla has also been a prominent figure in the media, having written
                    a weekly column titled 'Paisa Jhala Motha' for the renowned Marathi
                    daily 'Maharashtra Times' for almost two decades.
                </p> -->

                <!-- <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Additionally, Prafulla has been involved in various committees and
                    organizations, such as the Extensible Business Reporting Language
                    (XBRL) India, Banking & Finance Committee of IMC Chamber of Commerce,
                    CII National Committee on Financial Reporting.
                </p> -->

                <!-- <p class="mt-10 text-dark text-justify" style="font-size: 16px !important">
                    Through his diverse leadership roles, extensive industry
                    contributions, and remarkable achievements, Prafulla has made an
                    indelible impact on the accountancy profession and continues to be a
                    respected figure in the field.
                </p> -->
            </div>
        </div>
    </div>
</div>
<!-- USA End -->