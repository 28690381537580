import { Component, OnInit } from '@angular/core';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';LocalStorageServiceService


@Component({
  selector: 'app-sanjay-profile',
  templateUrl: './sanjay-profile.component.html',
  styleUrls: ['./sanjay-profile.component.css']
})
export class SanjayProfileComponent implements OnInit {
  defaultCountry: string;
  selectCountryName: any;
  selectedFlag: string;

  constructor(private LocalStorageServiceService: LocalStorageServiceService,) { }

  ngOnInit(): void {
    this.defaultCountry = 'USA';

    this.selectCountryName = localStorage.getItem('selectedCountry');

    if (this.selectCountryName == null) {
      localStorage.setItem('selectedCountry', this.defaultCountry);
      window.location.reload();
    } else {
      this.selectCountryName = localStorage.getItem('selectedCountry');
    }
  }

  selectCountry(country: string): void {
    // Update the selectedFlag with the corresponding image path
    if (country === 'Dubai') {
      this.selectedFlag =
        '/assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png';
    } else if (country === 'India') {
      this.selectedFlag = '/assets/images/flag/download.png';
    } else if (country === 'USA') {
      this.selectedFlag = '/assets/images/flag/USA.png';
    } else {
      // Set USA as the default country
      this.selectedFlag =
        '/assets/images/flag/USA.png';
      country = 'USA';
    }

    this.LocalStorageServiceService.setItem('selectedCountry', country);
  }

}
