<!-- Dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100">
  <div class="stify-content-center">
    <div class="text-center bottom pb-60" style="overflow: hidden; position: relative">
      <img src="../../../../../assets/images/teams/abstract-images/P-abstract-left.png" alt="shape"
        style="position: absolute; overflow: clip; left: -3%; bottom: 0%" />
      <img src="../../../../../assets/images/teams/abstract-images/P-abstract-right.png" alt="shape"
        style="position: absolute; overflow: clip; right: -3%; bottom: 0%" />
      <div class="container">
        <h3 class="title " style="
            border-bottom: 3px solid #418052;
            display: inline-block;
            width: fit-content;
          " class="marginTop text-dark">
          Advisory Board
        </h3>
      </div>

      <div class="mt-80">
        <div class="row justify-content-center mt-50" style="gap: 2rem">
          <div routerLink="/prafulla-chhajed" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/prafulla.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital">
              <h3 class="text-uppercase text-white m-0" style="margin: 0; font-size: 18px">
                Prafulla Chhajed
              </h3>
              <div style="margin-left: 22%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  Advisor
                </span>
                <i class="fab fa-linkedin-in" style="
                    margin-left: 25px;
                    background-color: #0072b1;
                    padding: 4px;
                    border-radius: 5px;
                    font-size: 14px;
                  "></i>
              </div>
            </div>
          </div>
          <div routerLink="/prafulla-chhajed" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/prafulla.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital">
              <h3 class="text-uppercase text-white m-0" style="margin: 0; font-size: 18px">
                Prafulla Chhajed
              </h3>
              <div style="margin-left: 22%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  Advisor
                </span>
                <i class="fab fa-linkedin-in" style="
                    margin-left: 25px;
                    background-color: #0072b1;
                    padding: 4px;
                    border-radius: 5px;
                    font-size: 14px;
                  "></i>
              </div>
            </div>
          </div>
          <div routerLink="/sameer-paddalwar" class="card border-0 cursor-pointer rounded text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/sameer.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital" style="margin-left: 3%">
              <h3 class="text-uppercase text-center text-white m-0" style="margin: 0; color: white; font-size: 16px">
                SAMEER PADDALWAR
              </h3>
              <div style="margin-left: 25%">
                <span style="
                    margin: 0;
                    color: white;
                    transition: all 0.3s;
                    font-size: 16px;
                  ">
                  CEO
                </span>
                <i class="fab fa-linkedin-in" style="
                    margin-left: 25px;
                    background-color: #0072b1;
                    padding: 4px;
                    border-radius: 5px;
                    font-size: 14px;
                  "></i>
              </div>
            </div>
          </div>
          <div routerLink="/sanjay-kapadia" class="card border-0 rounded cursor-pointer text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/sanjay.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital" style="margin-left: 9%">
              <h3 class="text-uppercase text-center text-white m-0" style="margin: 0; font-size: 16px">
                Sanjay Kapadia
              </h3>
              <div style="margin-left: 15%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  Advisor
                </span>
                <i class="fab fa-linkedin-in" style="
                    margin-left: 25px;
                    background-color: #0072b1;
                    padding: 4px;
                    border-radius: 5px;
                    font-size: 14px;
                  "></i>
              </div>
            </div>
          </div>
          <div class="container">
            <p class="mt-20 text-center text-dark pl-4 pr-4">
              Team BFAG is a group of passionate and committed professionals
              from diverse domains such as finance, accounting, law, IT and
              compliance reporting with decades of experience across diverse
              sectors and geographies. Our CPA’s, CA’s, Corporate Secretaries,
              Counselors, Financial Analysts are experts in their domain with
              focus on providing solutions. The team has worked on platforms
              such as SAP, Oracle, Xero, Sage, QB and globally used jurisdiction
              specific Tax software viz. CHH, Lacerte etc.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center shadow-lg mt-50 pt-40 pb-60" style="overflow: hidden; position: relative">
      <img src="../../../../../assets/images/teams/abstract-images/Leadership-abstract-left.png" alt="shape"
        style="position: absolute; width: 14%; overflow: clip; left: 0%; top: 0" />

      <div class="container">
        <h3 class="title text-dark" style="border-bottom: 3px solid #418052; display: inline-block">
          LEADERSHIP
        </h3>
      </div>
      <div class="mt-50">
        <div class="row justify-content-center mt-50" style="gap: 2rem">

          <!-- newcard -->

          <div routerLink="/ganesh-padakannayaa" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/ganesh.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital">
              <h3 class="text-uppercase text-white m-0" style="margin: 0; font-size: 15px">
                GANESH PADAKANNAYA
              </h3>
              <div style="margin-left: 22%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  COO
                </span>
                <i class="fab fa-linkedin-in" style="
                  margin-left: 25px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                  font-size: 14px;
                "></i>
              </div>
            </div>
          </div>


          <!-- newcard -->

          <!-- newcard -->

          <div routerLink="/amit-mulgund" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../../assets/images/teams/amit.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital">
              <h3 class="text-uppercase text-white m-0" style="margin: 0; font-size: 15px; padding-left: 2rem;">
                Amit Mulgund
              </h3>
              <div style="margin-left: 22%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  MD - EMEA
                </span>
                <i class="fab fa-linkedin-in" style="
                   margin-left: 25px;
                   background-color: #0072b1;
                   padding: 4px;
                   border-radius: 5px;
                   font-size: 14px;
                 "></i>
              </div>
            </div>
          </div>
          <!-- newcard -->

          <!-- newcard -->

          <div routerLink="/kartik-mehta" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../../assets/images/teams/teamMember.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="" style="position: absolute;
          bottom: 0;
          left: 0;
          padding: 8px;
          display: flex;
          flex-direction: column;
          ">
              <h3 class="text-uppercase text-center text-white m-0"
                style="margin: 0; font-size: 15px; padding-left: 2rem;">
                Kartik Mehta
              </h3>
              <div class="d-flex" style="margin-left: 22%; padding-left: 1.5rem;">
                <span class="text-white" style="margin-left:-7px; transition: all 0.3s; font-size: 16px">
                  VP-TAX
                </span>
                <i class="fab fa-linkedin-in" style="
                  margin-left: 17px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                  font-size: 14px;
                "></i>
              </div>
            </div>
          </div>
          <!-- newcard -->

          <!-- newcard -->

          <div routerLink="/ammar-tumpalliwar" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../../assets/images/teams/Ammar.jpg" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="" style="position: absolute;
          bottom: 0;
          left: 0;
          padding: 8px;
          display: flex;
          flex-direction: column;
          ">
              <h3 class="text-uppercase text-center text-white m-0" style="margin: 0; font-size: 16px;">
                Ammar Tumpalliwar
              </h3>
              <div class="d-flex" style="margin-left: 22%; padding-left: 1.5rem;">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  VP- BD
                </span>
                <i class="fab fa-linkedin-in" style="
                  margin-left: 25px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                  font-size: 14px;
                "></i>
              </div>
            </div>
          </div>
          <!-- newcard -->
        </div>
      </div>
      <!--  -->
    </div>

    <div class="mt-70" style="overflow: hidden; position: relative">
      <img src="../../../../../assets/images/teams/abstract-images/abstract-vector-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          top: 1%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-vector-right.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          right: -3%;
          top: 10%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-vector-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          top: 22%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-vector-right.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          right: -3%;
          top: 30%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/P-abstract-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          top: 42%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-circle-right.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          right: -3%;
          top: 50%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-circle-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          top: 62%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-circle-right.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          right: -3%;
          top: 70%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-circle-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          bottom: 0%;
        " />
      <div class="text-center pb-3">
        <h3 class="text-dark" style="border-bottom: 3px solid #418052; display: inline-block">
          LINE MANAGERS
        </h3>
      </div>
      <div class="container line-managers">
        <div class="row justify-content-center mt-5">
          <div class="col-lg-12 col-md-12">
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/ankush.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase text-dark">Ankush Birewar</h5>
                  <small class="text-dark font-weight-bold">VP- Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Ankush is a Chartered Accountant (CA) and Commerce graduate
                    with decades plus years of experience. He is adept at
                    handling clients with multi-Jurisdictional presence and is
                    well conversant with IFRS, US GAAP, India GAAP, State and
                    Federal Compliances. Valuation, Due Diligence, Technical
                    Accounting are his forte.
                  </p>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    His strong analytical skills with the ability to interpret
                    complex information quickly and precisely is an asset. As
                    VP – Operations at Boston Financial Advisory Group, he
                    manages F&A Processes and is instrumental in spearheading
                    new segments
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/nitesh.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase text-dark">Nitesh Vaswani</h5>
                  <small class="text-dark font-weight-bold">VP – International Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Nitesh is a Certified Public Accountant (CPA), he is also a Chartered Accountant (CA) from India and has done MBA in Finance. Nitesh comes with 10+ years of experience in Multi Jurisdictional Accounting, Consolidation, Reporting, Compliances.
                  </p>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    He has a rich exposure to diversified industry segment such
                    as Chemicals, Construction, Healthcare. His MNC background
                    has given him expertise in international reporting
                    requirements. His work as a Tax Compliance specialist has
                    added many a cheer to our international clients.
                  </p>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    His ability to manage multi-cultural teams is an asset to
                    the company.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Pramod.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase texxt-dark">Pramod KP</h5>
                  <small class="text-dark font-weight-bold">AVP – Regulatory</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Pramod K P is an astute and professional with more than 15
                    years of experience across diverse industry segments. Right
                    from IT MNC’s, Construction companies, Project Engineering
                    companies, he has spearheaded the Tax and Finance function
                    with ease and aplomb. In depth knowledge on Direct &
                    Indirect Taxes, Labour Laws & other statutory compliances
                    with focus on providing solutions to complex situations are
                    his forte.
                  </p>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    He is heading the Regulatory Function at BFAG for the past 8
                    years and believes in developing leadership capabilities in
                    his people while cultivating an open and transparent
                    culture.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/raguveer.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-dark">Raghuveer S</h5>
                  <small class="text-dark font-weight-bold">AVP – Taxation</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Raghuveer is a Chartered Accountant with 8+ years of
                    experience in US direct taxes. He has been leading the CPA
                    back-office operations wherein he manages compilation,
                    review, tax preparation, and filing for US clients.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/nagesh.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-dark">Nagesh K C</h5>
                  <small class="text-dark font-weight-bold">Senior Manager – Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Nagesh, A Chartered Accountant with 5+ years of experience
                    in Audit, Tax, Accounting. Manages multi jurisdictional
                    accounting, tax compliances for growth stage companies. Very
                    meticulous in his approach and always delivers in time.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Photograph.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Anand Bang</h5>
                  <small class="text-dark font-weight-bold">AVP – International Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Anand Bang is an experienced international operations manager with expertise in finance, compliance,
                    and strategic consultancy. With over a decade of experience, he excels in accounting, financial
                    reporting, statutory compliance, and virtual CFO services. Anand is skilled in audits, risk
                    management, budgeting, forecasting, and project consultancy. His proficiency in SAP environment,
                    revenue recognition, and variance analysis adds value to organizational decision-making. Anand's
                    dedication and expertise make him an indispensable asset in international operations management.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Gulam_Hussain.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase text-dark">Gulam Hussain</h5>
                  <small class="text-dark font-weight-bold">AVP - MEA</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Gulam is a dedicated professional and qualified Chartered
                    Accountant (ICAI) with over 15 years of extensive
                    experience, including 9 years in the UAE. His specializes in
                    Financial Consultancy, VAT Consultancy, Taxation,
                    Bookkeeping, Audit, and Management Consultancy. With
                    exceptional analytical abilities and attention to detail, he
                    provides accurate and reliable financial solutions. In 2018,
                    he established his own Financial Consultancy, serving
                    clients with Financial Consultancy, Tax Consultancy, and CFO
                    Services. Gulam has a strong background in accounting,
                    taxation, and business regulations enabled him to offer
                    comprehensive support to his 100+ satisfied clients. He has
                    joined BFAG MEA with an aim to foster growth through a
                    dedicated team of professionals. Gulam is also a qualified
                    D.I.S.A. (ICAI) and holds an M.Com degree.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/vimal.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-dark">Vimal Chande</h5>
                  <small class="text-dark font-weight-bold">Controller – Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Bringing with him a 15 plus years of experience in Corporate
                    Financial reporting, Financial Analytics, Audits etc. Having
                    worked in sectors such as Logistics, Marine and Other
                    multiple sectors he is well conversant with the complex
                    requirements of each sector. Developing MIS Systems for an
                    organization is his forte and excels his role in Financial
                    Management, Due diligence etc. His focus always has been on
                    continuous improvement in process as he believes “Change is
                    always permanent “ hence drives with scope for improvement
                    in work process and bringing effective results for the
                    organization.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/sumit batra.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-dark">Sumit Batra</h5>
                  <small class="text-dark font-weight-bold">AVP – Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Sumit has an MBA in Finance and worked with Ernst & Young
                    LLP, Mumbai (EY) as Asst. Vice President deployed at
                    Maharashtra State Road Dev. Corp. (MSRDC). He has 13+ years
                    of experience that include exposure in Financial Planning,
                    Fund Raising and Fund Management, Co-Ordination and bridge
                    between Indian Management and US Parent Company Management
                    and Financers, and Co-ordination with a consortium of
                    lenders & Lender’s Independent Engineers. He advised raising
                    finance for many large infra projects in India. Excellent
                    client management skills with being a team player make him
                    an asset.
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div
                  class="col-lg-2 d-flex justify-content-center align-items-center mb-3"
                >
                  <img
                    src="../../../../../assets/images/teams/anirudh.png"
                    class="img-fluid shadow"
                    style="border-radius: 15px"
                    alt=""
                  />
                </div>
                <div
                  class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto"
                >
                  <h5 class="text-dark">Anirudh Pal</h5>
                  <small class="text-dark font-weight-bold"
                    >AVP – IT & Systemss</small
                  >
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Anirudh comes with 15 plus years of experience with CISCO
                    certification. He has virtuous knowledge in accomplishment
                    of IT Infrastructure and Networking & Infrastructure Project
                    management aligning to the organizational strategy. He
                    manages the end-to-end IT Infrastructure setup at internal
                    Datacenter at BFAS . He excels in his role with effective
                    Budget Planning for IT Operations (Budget vs Actuals) and
                    Asset Inventory Management.
                  </p>
                </div>
              </div>
            </div> -->
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Vedang.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase text-dark">Vedang Shevade</h5>
                  <small class="text-dark font-weight-bold">AVP – Corporate Compliance</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Vedang is a Company Secretary from the Institute of Company
                    Secretaries of India. He is also a graduate in Law and
                    Commerce. Heading the Corporate Secretarial division of
                    BFAG, Vedang comes with more than 7 years of extensive
                    expertise in the field of Corporate Laws, SEBI, Foreign
                    Exchange Management Compliances, Legal Due Diligence,
                    drafting, reviewing and vetting of various legal agreements,
                    shareholder’s transaction documents.He is an expert in
                    business set up whether in India or Overseas.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Ujjal-Mukherjee.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase text-dark">Ujjal Mukherjee</h5>
                  <small class="text-dark font-weight-bold">Product Manager</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Ujjal is a skilled Product Manager with 7+ years of
                    experience in end-to-end product and project management. He
                    excels in project scoping, execution, and delivery, working
                    closely with stakeholders at all levels, including senior
                    executives and cross-functional teams. Proficient in project
                    management, agile methodologies, and data-driven insights,
                    he holds a B. Tech and PGCPM from IIM Indore. Leading the
                    Design & Development of COMPLIANCE iNBOX, he showcases
                    strong leadership and an unwavering commitment to
                    excellence, consistently achieving outstanding results in
                    dynamic business environments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Dubai end -->

<!-- INdia started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100">
  <div class="stify-content-center">
    <div class="text-center bottom pb-60" style="overflow: hidden; position: relative">
      <img src="../../../../../assets/images/teams/abstract-images/P-abstract-left.png" alt="shape"
        style="position: absolute; overflow: clip; left: -3%; bottom: 0%" />
      <img src="../../../../../assets/images/teams/abstract-images/P-abstract-right.png" alt="shape"
        style="position: absolute; overflow: clip; right: -3%; bottom: 0%" />
      <div class="container">
        <h3 class="title " style="
            border-bottom: 3px solid #418052;
            display: inline-block;
            width: fit-content;
          " class="marginTop">
          Advisory Board
        </h3>
      </div>

      <div class="mt-80">
        <div class="row justify-content-center mt-50" style="gap: 2rem">

          <div routerLink="/anoop-mehta" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/AMehta.jpg" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital">
              <h3 class="text-uppercase text-white m-0" style="margin: 0; font-size: 18px">
                Anoop Natwar Mehta
              </h3>
              <div style="margin-left: 22%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  Advisor
                </span>
                <i class="fab fa-linkedin-in" style="
                  margin-left: 25px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                  font-size: 14px;
                "></i>
              </div>
            </div>
          </div>


          <div routerLink="/prafulla-chhajed" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/prafulla.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital">
              <h3 class="text-uppercase text-white m-0" style="margin: 0; font-size: 18px">
                Prafulla Chhajed
              </h3>
              <div style="margin-left: 22%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  Advisor
                </span>
                <i class="fab fa-linkedin-in" style="
                    margin-left: 25px;
                    background-color: #0072b1;
                    padding: 4px;
                    border-radius: 5px;
                    font-size: 14px;
                  "></i>
              </div>
            </div>
          </div>
          <!-- <div routerLink="/sameer-paddalwar" class="card border-0 cursor-pointer rounded text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/sameer.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital" style="margin-left: 3%">
              <h3 class="text-uppercase text-center text-white m-0" style="margin: 0; color: white; font-size: 16px">
                SAMEER PADDALWAR
              </h3>
              <div style="margin-left: 25%">
                <span style="
                    margin: 0;
                    color: white;
                    transition: all 0.3s;
                    font-size: 16px;
                  ">
                  CEO
                </span>
                <i class="fab fa-linkedin-in" style="
                    margin-left: 25px;
                    background-color: #0072b1;
                    padding: 4px;
                    border-radius: 5px;
                    font-size: 14px;
                  "></i>
              </div>
            </div>
          </div> -->
          <div routerLink="/sanjay-kapadia" class="card border-0 rounded cursor-pointer text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/sanjay.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital" style="margin-left: 9%">
              <h3 class="text-uppercase text-center text-white m-0" style="margin: 0; font-size: 16px">
                Sanjay Kapadia
              </h3>
              <div style="margin-left: 15%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  Advisor
                </span>
                <i class="fab fa-linkedin-in" style="
                    margin-left: 25px;
                    background-color: #0072b1;
                    padding: 4px;
                    border-radius: 5px;
                    font-size: 14px;
                  "></i>
              </div>
            </div>
          </div>
          <div routerLink="/ketan-chokshi" class="card border-0 rounded cursor-pointer text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/Ketan Choksi.jpg" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital" style="margin-left: 9%">
              <h3 class="text-uppercase text-center text-white m-0" style="margin: 0; font-size: 16px">
                Ketan Chokshi
              </h3>
              <div style="margin-left: 15%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  Advisor
                </span>
                <i class="fab fa-linkedin-in" style="
                    margin-left: 25px;
                    background-color: #0072b1;
                    padding: 4px;
                    border-radius: 5px;
                    font-size: 14px;
                  "></i>
              </div>
            </div>
          </div>
          <div class="container">
            <p class="mt-20 text-center text-dark pl-4 pr-4">
              Team BFAG is a group of passionate and committed professionals
              from diverse domains such as finance, accounting, law, IT and
              compliance reporting with decades of experience across diverse
              sectors and geographies. Our CPA’s, CA’s, Corporate Secretaries,
              Counselors, Financial Analysts are experts in their domain with
              focus on providing solutions. The team has worked on platforms
              such as SAP, Oracle, Xero, Sage, QB and globally used jurisdiction
              specific Tax software viz. CHH, Lacerte etc.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center shadow-lg mt-50 pt-40 pb-60" style="overflow: hidden; position: relative">
      <img src="../../../../../assets/images/teams/abstract-images/Leadership-abstract-left.png" alt="shape"
        style="position: absolute; width: 14%; overflow: clip; left: 0%; top: 0" />

      <div class="container">
        <h3 class="title" style="border-bottom: 3px solid #418052; display: inline-block">
          LEADERSHIP
        </h3>
      </div>
      <div class="mt-50">
        <div class="row justify-content-center mt-50" style="gap: 2rem">

          <!-- newcard -->

          <div routerLink="/sameer-paddalwar" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/sameer.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital">
              <h3 class="text-uppercase text-white m-0" style="margin: 0; font-size: 15px">
                SAMEER PADDALWAR
              </h3>
              <div style="margin-left: 22%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  CEO
                </span>
                <i class="fab fa-linkedin-in" style="
                margin-left: 25px;
                background-color: #0072b1;
                padding: 4px;
                border-radius: 5px;
                font-size: 14px;
              "></i>
              </div>
            </div>
          </div>
          <!-- new card -->

          <div routerLink="/ganesh-padakannayaa" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/ganesh.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital">
              <h3 class="text-uppercase text-white m-0" style="margin: 0; font-size: 15px">
                GANESH PADAKANNAYA
              </h3>
              <div style="margin-left: 22%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  COO
                </span>
                <i class="fab fa-linkedin-in" style="
                  margin-left: 25px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                  font-size: 14px;
                "></i>
              </div>
            </div>
          </div>
          <!-- newcard -->

          <!-- newcard -->

          <div routerLink="/amit-mulgund" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../../assets/images/teams/amit.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital">
              <h3 class="text-uppercase text-white m-0" style="margin: 0; font-size: 15px; padding-left: 2rem;">
                Amit Mulgund
              </h3>
              <div style="margin-left: 22%">
                <span class="text-white" style="margin-left:-4px;  transition: all 0.3s; font-size: 16px">
                  MD-EMEA
                </span>
                <i class="fab fa-linkedin-in" style="
                   margin-left: 25px;
                   background-color: #0072b1;
                   padding: 4px;
                   border-radius: 5px;
                   font-size: 14px;
                 "></i>
              </div>
            </div>
          </div>
          <!-- newcard -->

          <!-- newcard -->

          <div routerLink="/kartik-mehta" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../../assets/images/teams/teamMember.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="" style="position: absolute;
          bottom: 0;
          left: 0;
          padding: 8px;
          display: flex;
          flex-direction: column;
          ">
              <h3 class="text-uppercase text-center text-white m-0"
                style="margin: 0; font-size: 15px; padding-left: 2rem;">
                Kartik Mehta
              </h3>
              <div class="d-flex" style="margin-left: 22%; padding-left: 1.5rem;">
                <span class="text-white" style="margin: -7px; margin-top: 1px; transition: all 0.3s; font-size: 16px">
                  VP-Tax
                </span>
                <i class="fab fa-linkedin-in" style="
                  margin-left: 17px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                  font-size: 14px;
                "></i>
              </div>
            </div>
          </div>
          <!-- newcard -->

          <!-- newcard -->

          <div routerLink="/ammar-tumpalliwar" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../../assets/images/teams/Ammar.jpg" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="" style="position: absolute;
          bottom: 0;
          left: 0;
          padding: 8px;
          display: flex;
          flex-direction: column;
          ">
              <h3 class="text-uppercase text-center text-white m-0" style="margin: 0; font-size: 16px;">
                Ammar Tumpalliwar
              </h3>
              <div class="d-flex" style="margin-left: 22%; padding-left: 1.5rem;">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  VP-BD
                </span>
                <i class="fab fa-linkedin-in" style="
                  margin-left: 25px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                  font-size: 14px;
                "></i>
              </div>
            </div>
          </div>
          <!-- newcard -->
        </div>
      </div>
      <!--  -->
    </div>

    <div class="mt-70" style="overflow: hidden; position: relative">
      <img src="../../../../../assets/images/teams/abstract-images/abstract-vector-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          top: 1%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-vector-right.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          right: -3%;
          top: 10%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-vector-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          top: 22%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-vector-right.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          right: -3%;
          top: 30%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/P-abstract-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          top: 42%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-circle-right.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          right: -3%;
          top: 50%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-circle-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          top: 62%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-circle-right.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          right: -3%;
          top: 70%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-circle-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          bottom: 0%;
        " />
      <div class="text-center pb-3">
        <h3 style="border-bottom: 3px solid #418052; display: inline-block">
          LINE MANAGERS
        </h3>
      </div>
      <div class="container line-managers">
        <div class="row justify-content-center mt-5">
          <div class="col-lg-12 col-md-12">
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/ankush.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Ankush Birewar</h5>
                  <small class="text-dark font-weight-bold">VP- Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Ankush is a Chartered Accountant (CA) and Commerce graduate
                    with decades plus years of experience. He is adept at
                    handling clients with multi-Jurisdictional presence and is
                    well conversant with IFRS, US GAAP, India GAAP, State and
                    Federal Compliances. Valuation, Due Diligence, Technical
                    Accounting are his forte.
                  </p>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    His strong analytical skills with the ability to interpret
                    complex information quickly and precisely is an asset. As
                    VP – Operations at Boston Financial Advisory Group, he
                    manages F&A Processes and is instrumental in spearheading
                    new segments
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/nitesh.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Nitesh Vaswani</h5>
                  <small class="text-dark font-weight-bold">VP – International Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Nitesh is a Certified Public Accountant (CPA), he is also a Chartered Accountant (CA) from India and has done MBA in Finance. Nitesh comes with 10+ years of experience in Multi Jurisdictional Accounting, Consolidation, Reporting, Compliances.
                  </p>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    He has a rich exposure to diversified industry segment such
                    as Chemicals, Construction, Healthcare. His MNC background
                    has given him expertise in international reporting
                    requirements. His work as a Tax Compliance specialist has
                    added many a cheer to our international clients.
                  </p>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    His ability to manage multi-cultural teams is an asset to
                    the company.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Pramod.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Pramod KP</h5>
                  <small class="text-dark font-weight-bold">AVP – Regulatory</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Pramod K P is an astute and professional with more than 15
                    years of experience across diverse industry segments. Right
                    from IT MNC’s, Construction companies, Project Engineering
                    companies, he has spearheaded the Tax and Finance function
                    with ease and aplomb. In depth knowledge on Direct &
                    Indirect Taxes, Labour Laws & other statutory compliances
                    with focus on providing solutions to complex situations are
                    his forte.
                  </p>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    He is heading the Regulatory Function at BFAG for the past 8
                    years and believes in developing leadership capabilities in
                    his people while cultivating an open and transparent
                    culture.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/raguveer.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5>Raghuveer S</h5>
                  <small class="text-dark font-weight-bold">AVP – Taxation</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Raghuveer is a Chartered Accountant with 8+ years of
                    experience in US direct taxes. He has been leading the CPA
                    back-office operations wherein he manages compilation,
                    review, tax preparation, and filing for US clients.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/nagesh.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5>Nagesh K C</h5>
                  <small class="text-dark font-weight-bold">Senior Manager – Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Nagesh, A Chartered Accountant with 5+ years of experience
                    in Audit, Tax, Accounting. Manages multi jurisdictional
                    accounting, tax compliances for growth stage companies. Very
                    meticulous in his approach and always delivers in time.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Photograph.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Anand Bang</h5>
                  <small class="text-dark font-weight-bold">AVP – International Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Anand Bang is an experienced international operations manager with expertise in finance, compliance,
                    and strategic consultancy. With over a decade of experience, he excels in accounting, financial
                    reporting, statutory compliance, and virtual CFO services. Anand is skilled in audits, risk
                    management, budgeting, forecasting, and project consultancy. His proficiency in SAP environment,
                    revenue recognition, and variance analysis adds value to organizational decision-making. Anand's
                    dedication and expertise make him an indispensable asset in international operations management.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Gulam_Hussain.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Gulam Hussain</h5>
                  <small class="text-dark font-weight-bold">AVP - MEA</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Gulam is a dedicated professional and qualified Chartered
                    Accountant (ICAI) with over 15 years of extensive
                    experience, including 9 years in the UAE. His specializes in
                    Financial Consultancy, VAT Consultancy, Taxation,
                    Bookkeeping, Audit, and Management Consultancy. With
                    exceptional analytical abilities and attention to detail, he
                    provides accurate and reliable financial solutions. In 2018,
                    he established his own Financial Consultancy, serving
                    clients with Financial Consultancy, Tax Consultancy, and CFO
                    Services. Gulam has a strong background in accounting,
                    taxation, and business regulations enabled him to offer
                    comprehensive support to his 100+ satisfied clients. He has
                    joined BFAG MEA with an aim to foster growth through a
                    dedicated team of professionals. Gulam is also a qualified
                    D.I.S.A. (ICAI) and holds an M.Com degree.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/vimal.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5>Vimal Chande</h5>
                  <small class="text-dark font-weight-bold">Controller – Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Bringing with him a 15 plus years of experience in Corporate
                    Financial reporting, Financial Analytics, Audits etc. Having
                    worked in sectors such as Logistics, Marine and Other
                    multiple sectors he is well conversant with the complex
                    requirements of each sector. Developing MIS Systems for an
                    organization is his forte and excels his role in Financial
                    Management, Due diligence etc. His focus always has been on
                    continuous improvement in process as he believes “Change is
                    always permanent “ hence drives with scope for improvement
                    in work process and bringing effective results for the
                    organization.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/sumit batra.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5>Sumit Batra</h5>
                  <small class="text-dark font-weight-bold">AVP – Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Sumit has an MBA in Finance and worked with Ernst & Young
                    LLP, Mumbai (EY) as Asst. Vice President deployed at
                    Maharashtra State Road Dev. Corp. (MSRDC). He has 13+ years
                    of experience that include exposure in Financial Planning,
                    Fund Raising and Fund Management, Co-Ordination and bridge
                    between Indian Management and US Parent Company Management
                    and Financers, and Co-ordination with a consortium of
                    lenders & Lender’s Independent Engineers. He advised raising
                    finance for many large infra projects in India. Excellent
                    client management skills with being a team player make him
                    an asset.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/anirudh.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5>Anirudh Pal</h5>
                  <small class="text-dark font-weight-bold">AVP – IT & Systems</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Anirudh comes with 15 plus years of experience with CISCO
                    certification. He has virtuous knowledge in accomplishment
                    of IT Infrastructure and Networking & Infrastructure Project
                    management aligning to the organizational strategy. He
                    manages the end-to-end IT Infrastructure setup at internal
                    Datacenter at BFAS . He excels in his role with effective
                    Budget Planning for IT Operations (Budget vs Actuals) and
                    Asset Inventory Management.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Vedang.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Vedang Shevade</h5>
                  <small class="text-dark font-weight-bold">AVP – Corporate Compliance</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Vedang is a Company Secretary from the Institute of Company
                    Secretaries of India. He is also a graduate in Law and
                    Commerce. Heading the Corporate Secretarial division of
                    BFAG, Vedang comes with more than 7 years of extensive
                    expertise in the field of Corporate Laws, SEBI, Foreign
                    Exchange Management Compliances, Legal Due Diligence,
                    drafting, reviewing and vetting of various legal agreements,
                    shareholder’s transaction documents.He is an expert in
                    business set up whether in India or Overseas.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Ujjal-Mukherjee.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Ujjal Mukherjee</h5>
                  <small class="text-dark font-weight-bold">Product Manager</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Ujjal is a skilled Product Manager with 7+ years of
                    experience in end-to-end product and project management. He
                    excels in project scoping, execution, and delivery, working
                    closely with stakeholders at all levels, including senior
                    executives and cross-functional teams. Proficient in project
                    management, agile methodologies, and data-driven insights,
                    he holds a B. Tech and PGCPM from IIM Indore. Leading the
                    Design & Development of COMPLIANCE iNBOX, he showcases
                    strong leadership and an unwavering commitment to
                    excellence, consistently achieving outstanding results in
                    dynamic business environments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- INdia end -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100">
  <div class="stify-content-center">
    <div class="text-center bottom pb-60" style="overflow: hidden; position: relative">
      <img src="../../../../../assets/images/teams/abstract-images/P-abstract-left.png" alt="shape"
        style="position: absolute; overflow: clip; left: -3%; bottom: 0%" />
      <img src="../../../../../assets/images/teams/abstract-images/P-abstract-right.png" alt="shape"
        style="position: absolute; overflow: clip; right: -3%; bottom: 0%" />
      <div class="container">
        <h3 class="title " style="
            border-bottom: 3px solid #418052;
            display: inline-block;
            width: fit-content;
          " class="marginTop">
          Advisory Board
        </h3>
      </div>

      <div class="mt-80">
        <div class="row justify-content-center mt-50" style="gap: 2rem">

        <div routerLink="/anoop-mehta" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/AMehta.jpg" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital">
              <h3 class="text-uppercase text-white m-0" style="margin: 0; font-size: 18px">
                Anoop Natwar Mehta
              </h3>
              <div style="margin-left: 22%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  Advisor
                </span>
                <i class="fab fa-linkedin-in" style="
                  margin-left: 25px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                  font-size: 14px;
                "></i>
              </div>
            </div>
          </div> 


          <div routerLink="/prafulla-chhajed" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/prafulla.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital">
              <h3 class="text-uppercase text-white m-0" style="margin: 0; font-size: 18px">
                Prafulla Chhajed
              </h3>
              <div style="margin-left: 22%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  Advisor
                </span>
                <i class="fab fa-linkedin-in" style="
                    margin-left: 25px;
                    background-color: #0072b1;
                    padding: 4px;
                    border-radius: 5px;
                    font-size: 14px;
                  "></i>
              </div>
            </div>
          </div>
          <!-- <div
            routerLink="/sameer-paddalwar"
            class="card border-0 cursor-pointer rounded text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative"
          >
            <img
              src="../../../../assets//images/teams/sameer.png"
              class="card-img img-fluid"
              alt="..."
              style="height: 100%; width: 100%; object-fit: cover"
            />
            <div class="teamTital" style="margin-left: 3%">
              <h3
                class="text-uppercase text-center text-white m-0"
                style="margin: 0; color: white; font-size: 16px"
              >
                SAMEER PADDALWAR
              </h3>
              <div style="margin-left: 25%">
                <span
                  style="
                    margin: 0;
                    color: white;
                    transition: all 0.3s;
                    font-size: 16px;
                  "
                >
                  CEO
                </span>
                <i
                  class="fab fa-linkedin-in"
                  style="
                    margin-left: 25px;
                    background-color: #0072b1;
                    padding: 4px;
                    border-radius: 5px;
                    font-size: 14px;
                  "
                ></i>
              </div>
            </div>
          </div> -->
          <div routerLink="/sanjay-kapadia" class="card border-0 rounded cursor-pointer text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/sanjay.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital" style="margin-left: 9%">
              <h3 class="text-uppercase text-center text-white m-0" style="margin: 0; font-size: 16px">
                Sanjay Kapadia
              </h3>
              <div style="margin-left: 15%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  Advisor
                </span>
                <i class="fab fa-linkedin-in" style="
                    margin-left: 25px;
                    background-color: #0072b1;
                    padding: 4px;
                    border-radius: 5px;
                    font-size: 14px;
                  "></i>
              </div>
            </div>
          </div>
          <div routerLink="/ketan-chokshi" class="card border-0 rounded cursor-pointer text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/Ketan Choksi.jpg" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital" style="margin-left: 9%">
              <h3 class="text-uppercase text-center text-white m-0" style="margin: 0; font-size: 16px">
                Ketan Chokshi
              </h3>
              <div style="margin-left: 15%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  Advisor
                </span>
                <i class="fab fa-linkedin-in" style="
                  margin-left: 25px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                  font-size: 14px;
                "></i>
              </div>
            </div>
          </div>
          <div class="container">
            <p class="mt-20 text-center text-dark pl-4 pr-4">
              Team BFAG is a group of passionate and committed professionals
              from diverse domains such as finance, accounting, law, IT and
              compliance reporting with decades of experience across diverse
              sectors and geographies. Our CPA’s, CA’s, Corporate Secretaries,
              Counselors, Financial Analysts are experts in their domain with
              focus on providing solutions. The team has worked on platforms
              such as SAP, Oracle, Xero, Sage, QB and globally used jurisdiction
              specific Tax software viz. CHH, Lacerte etc.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center shadow-lg mt-50 pt-40 pb-60" style="overflow: hidden; position: relative">
      <img src="../../../../../assets/images/teams/abstract-images/Leadership-abstract-left.png" alt="shape"
        style="position: absolute; width: 14%; overflow: clip; left: 0%; top: 0" />

      <div class="container">
        <h3 class="title" style="border-bottom: 3px solid #418052; display: inline-block">
          LEADERSHIP
        </h3>
      </div>
      <div class="mt-50">
        <div class="row justify-content-center mt-50" style="gap: 2rem">

          <!-- newcard -->

          <div routerLink="/sameer-paddalwar" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/sameer.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital">
              <h3 class="text-uppercase text-white m-0" style="margin: 0; font-size: 15px">
                SAMEER PADDALWAR
              </h3>
              <div style="margin-left: 22%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  CEO
                </span>
                <i class="fab fa-linkedin-in" style="
                  margin-left: 25px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                  font-size: 14px;
                "></i>
              </div>
            </div>
          </div>
          <!-- newcard -->
          <div routerLink="/ganesh-padakannayaa" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../assets//images/teams/ganesh.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital">
              <h3 class="text-uppercase text-white m-0" style="margin: 0; font-size: 15px">
                GANESH PADAKANNAYA
              </h3>
              <div style="margin-left: 22%">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  COO
                </span>
                <i class="fab fa-linkedin-in" style="
                  margin-left: 25px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                  font-size: 14px;
                "></i>
              </div>
            </div>
          </div>
          <!-- newcard -->

          <div routerLink="/amit-mulgund" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../../assets/images/teams/amit.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="teamTital">
              <h3 class="text-uppercase text-white m-0" style="margin: 0; font-size: 15px; padding-left: 2rem;">
                Amit Mulgund
              </h3>
              <div style="margin-left: 22%">
                <span class="text-white" style="margin-left:-4px; transition: all 0.3s; font-size: 16px">
                  MD-EMEA
                </span>
                <i class="fab fa-linkedin-in" style="
                   margin-left: 25px;
                   background-color: #0072b1;
                   padding: 4px;
                   border-radius: 5px;
                   font-size: 14px;
                 "></i>
              </div>
            </div>
          </div>
          <!-- newcard -->

          <!-- newcard -->

          <div routerLink="/kartik-mehta" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../../assets/images/teams/teamMember.png" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="" style="position: absolute;
          bottom: 0;
          left: 0;
          padding: 8px;
          display: flex;
          flex-direction: column;
          ">
              <h3 class="text-uppercase text-center text-white m-0"
                style="margin: 0; font-size: 15px; padding-left: 2rem;">
                Kartik Mehta
              </h3>
              <div class="d-flex" style="margin-left: 22%; padding-left: 1.5rem;">
                <span class="text-white" style="margin: -7px; margin-top: 1px; transition: all 0.3s; font-size: 16px">
                  VP-Tax
                </span>
                <i class="fab fa-linkedin-in" style="
                  margin-left: 17px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                  font-size: 14px;
                "></i>
              </div>
            </div>
          </div>
          <!-- newcard -->

          <!-- newcard -->

          <div routerLink="/ammar-tumpalliwar" class="card cursor-pointer border-0 text-white shadeCSS"
            style="height: 280px; width: 200px; position: relative">
            <img src="../../../../../assets/images/teams/Ammar.jpg" class="card-img img-fluid" alt="..."
              style="height: 100%; width: 100%; object-fit: cover" />
            <div class="" style="position: absolute;
          bottom: 0;
          left: 0;
          padding: 8px;
          display: flex;
          flex-direction: column;
          ">
              <h3 class="text-uppercase text-center text-white m-0" style="margin: 0; font-size: 16px;">
                Ammar Tumpalliwar
              </h3>
              <div class="d-flex" style="margin-left: 22%; padding-left: 1.5rem;">
                <span class="text-white" style="margin: 0; transition: all 0.3s; font-size: 16px">
                  VP-BD
                </span>
                <i class="fab fa-linkedin-in" style="
                  margin-left: 25px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                  font-size: 14px;
                "></i>
              </div>
            </div>
          </div>
          <!-- newcard -->
        </div>
      </div>
      <!--  -->
    </div>

    <div class="mt-70" style="overflow: hidden; position: relative">
      <img src="../../../../../assets/images/teams/abstract-images/abstract-vector-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          top: 1%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-vector-right.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          right: -3%;
          top: 10%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-vector-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          top: 22%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-vector-right.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          right: -3%;
          top: 30%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/P-abstract-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          top: 42%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-circle-right.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          right: -3%;
          top: 50%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-circle-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          top: 62%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-circle-right.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          right: -3%;
          top: 70%;
        " />
      <img src="../../../../../assets/images/teams/abstract-images/abstract-circle-left.png" alt="shape" style="
          position: absolute;
          width: 22%;
          overflow: clip;
          left: -3%;
          bottom: 0%;
        " />
      <div class="text-center pb-3">
        <h3 style="border-bottom: 3px solid #418052; display: inline-block">
          LINE MANAGERS
        </h3>
      </div>
      <div class="container line-managers">
        <div class="row justify-content-center mt-5">
          <div class="col-lg-12 col-md-12">
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/ankush.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Ankush Birewar</h5>
                  <small class="text-dark font-weight-bold">VP- Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Ankush is a Chartered Accountant (CA) and Commerce graduate
                    with decades plus years of experience. He is adept at
                    handling clients with multi-Jurisdictional presence and is
                    well conversant with IFRS, US GAAP, India GAAP, State and
                    Federal Compliances. Valuation, Due Diligence, Technical
                    Accounting are his forte.
                  </p>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    His strong analytical skills with the ability to interpret
                    complex information quickly and precisely is an asset. As
                    VP – Operations at Boston Financial Advisory Group, he
                    manages F&A Processes and is instrumental in spearheading
                    new segments
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/nitesh.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Nitesh Vaswani</h5>
                  <small class="text-dark font-weight-bold">VP – International Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Nitesh is a Certified Public Accountant (CPA), he is also a Chartered Accountant (CA) from India and has done MBA in Finance. Nitesh comes with 10+ years of experience in Multi Jurisdictional Accounting, Consolidation, Reporting, Compliances.
                  </p>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    He has a rich exposure to diversified industry segment such
                    as Chemicals, Construction, Healthcare. His MNC background
                    has given him expertise in international reporting
                    requirements. His work as a Tax Compliance specialist has
                    added many a cheer to our international clients.
                  </p>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    His ability to manage multi-cultural teams is an asset to
                    the company.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Pramod.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Pramod KP</h5>
                  <small class="text-dark font-weight-bold">AVP – Regulatory</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Pramod K P is an astute and professional with more than 15
                    years of experience across diverse industry segments. Right
                    from IT MNC’s, Construction companies, Project Engineering
                    companies, he has spearheaded the Tax and Finance function
                    with ease and aplomb. In depth knowledge on Direct &
                    Indirect Taxes, Labour Laws & other statutory compliances
                    with focus on providing solutions to complex situations are
                    his forte.
                  </p>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    He is heading the Regulatory Function at BFAG for the past 8
                    years and believes in developing leadership capabilities in
                    his people while cultivating an open and transparent
                    culture.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/raguveer.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5>Raghuveer S</h5>
                  <small class="text-dark font-weight-bold">AVP – Taxation</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Raghuveer is a Chartered Accountant with 8+ years of
                    experience in US direct taxes. He has been leading the CPA
                    back-office operations wherein he manages compilation,
                    review, tax preparation, and filing for US clients.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/nagesh.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5>Nagesh K C</h5>
                  <small class="text-dark font-weight-bold">Senior Manager – Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Nagesh, A Chartered Accountant with 5+ years of experience
                    in Audit, Tax, Accounting. Manages multi jurisdictional
                    accounting, tax compliances for growth stage companies. Very
                    meticulous in his approach and always delivers in time.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Photograph.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Anand Bang</h5>
                  <small class="text-dark font-weight-bold">AVP – International Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Anand Bang is an experienced international operations manager with expertise in finance, compliance,
                    and strategic consultancy. With over a decade of experience, he excels in accounting, financial
                    reporting, statutory compliance, and virtual CFO services. Anand is skilled in audits, risk
                    management, budgeting, forecasting, and project consultancy. His proficiency in SAP environment,
                    revenue recognition, and variance analysis adds value to organizational decision-making. Anand's
                    dedication and expertise make him an indispensable asset in international operations management.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Gulam_Hussain.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Gulam Hussain</h5>
                  <small class="text-dark font-weight-bold">AVP - MEA</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Gulam is a dedicated professional and qualified Chartered
                    Accountant (ICAI) with over 15 years of extensive
                    experience, including 9 years in the UAE. His specializes in
                    Financial Consultancy, VAT Consultancy, Taxation,
                    Bookkeeping, Audit, and Management Consultancy. With
                    exceptional analytical abilities and attention to detail, he
                    provides accurate and reliable financial solutions. In 2018,
                    he established his own Financial Consultancy, serving
                    clients with Financial Consultancy, Tax Consultancy, and CFO
                    Services. Gulam has a strong background in accounting,
                    taxation, and business regulations enabled him to offer
                    comprehensive support to his 100+ satisfied clients. He has
                    joined BFAG MEA with an aim to foster growth through a
                    dedicated team of professionals. Gulam is also a qualified
                    D.I.S.A. (ICAI) and holds an M.Com degree.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/vimal.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5>Vimal Chande</h5>
                  <small class="text-dark font-weight-bold">Controller – Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Bringing with him a 15 plus years of experience in Corporate
                    Financial reporting, Financial Analytics, Audits etc. Having
                    worked in sectors such as Logistics, Marine and Other
                    multiple sectors he is well conversant with the complex
                    requirements of each sector. Developing MIS Systems for an
                    organization is his forte and excels his role in Financial
                    Management, Due diligence etc. His focus always has been on
                    continuous improvement in process as he believes “Change is
                    always permanent “ hence drives with scope for improvement
                    in work process and bringing effective results for the
                    organization.
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/sumit batra.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5>Sumit Batra</h5>
                  <small class="text-dark font-weight-bold">AVP – Operations</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Sumit has an MBA in Finance and worked with Ernst & Young
                    LLP, Mumbai (EY) as Asst. Vice President deployed at
                    Maharashtra State Road Dev. Corp. (MSRDC). He has 13+ years
                    of experience that include exposure in Financial Planning,
                    Fund Raising and Fund Management, Co-Ordination and bridge
                    between Indian Management and US Parent Company Management
                    and Financers, and Co-ordination with a consortium of
                    lenders & Lender’s Independent Engineers. He advised raising
                    finance for many large infra projects in India. Excellent
                    client management skills with being a team player make him
                    an asset.
                  </p>
                </div>
              </div>
            </div> -->
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/anirudh.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5>Anirudh Pal</h5>
                  <small class="text-dark font-weight-bold">AVP – IT & Systems</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Anirudh comes with 15 plus years of experience with CISCO
                    certification. He has virtuous knowledge in accomplishment
                    of IT Infrastructure and Networking & Infrastructure Project
                    management aligning to the organizational strategy. He
                    manages the end-to-end IT Infrastructure setup at internal
                    Datacenter at BFAS . He excels in his role with effective
                    Budget Planning for IT Operations (Budget vs Actuals) and
                    Asset Inventory Management.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Vedang.jpg" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Vedang Shevade</h5>
                  <small class="text-dark font-weight-bold">AVP – Corporate Compliance</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Vedang is a Company Secretary from the Institute of Company
                    Secretaries of India. He is also a graduate in Law and
                    Commerce. Heading the Corporate Secretarial division of
                    BFAG, Vedang comes with more than 7 years of extensive
                    expertise in the field of Corporate Laws, SEBI, Foreign
                    Exchange Management Compliances, Legal Due Diligence,
                    drafting, reviewing and vetting of various legal agreements,
                    shareholder’s transaction documents.He is an expert in
                    business set up whether in India or Overseas.
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow p-3 mb-5 bg-white rounded border-0">
              <div class="row">
                <div class="col-lg-2 d-flex justify-content-center align-items-center mb-3">
                  <img src="../../../../../assets/images/teams/Ujjal-Mukherjee.png" class="img-fluid shadow"
                    style="border-radius: 15px" alt="" />
                </div>
                <div class="col-md-10 col-sm-10 col-lg-10 d-flex flex-column justify-content-center m-auto">
                  <h5 class="text-uppercase">Ujjal Mukherjee</h5>
                  <small class="text-dark font-weight-bold">Product Manager</small>
                  <p class="mt-2 text-dark" style="line-height: normal">
                    Ujjal is a skilled Product Manager with 7+ years of
                    experience in end-to-end product and project management. He
                    excels in project scoping, execution, and delivery, working
                    closely with stakeholders at all levels, including senior
                    executives and cross-functional teams. Proficient in project
                    management, agile methodologies, and data-driven insights,
                    he holds a B. Tech and PGCPM from IIM Indore. Leading the
                    Design & Development of COMPLIANCE iNBOX, he showcases
                    strong leadership and an unwavering commitment to
                    excellence, consistently achieving outstanding results in
                    dynamic business environments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- USA end -->