
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area Manufacturing customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Manufacturing </h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Manufacturing</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Horizon for Manufacturing PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Manufacturing Industries</span>
                    <h2 class="title pb-15">Horizon - EBS for Manufacturing </h2>
                    <p>Make to Order ERP software or Build to Order manufacturing software must meet the unique requirements which are not met by traditional make to stock manufacturing software solutions. Many of the required materials for make to order
                        manufacturing are not carried in stock but must be purchased directly to the customer order. As the customer’s order is completed, it is shipped directly to the customer rather than passing through inventory. Costs need to be linked
                        directly to the customer order and compared with budgets for the order.</p>
                    <p>Horizon – EBS for Manufacturing is a comprehensive make to order (MTO) software system designed to accommodate the unique manufacturing ERP software requirements of the made to order MTO manufacturer. It provides management with the
                        tools needed to quote a price to the customer, enter the order and get it into the shop quickly, order the necessary materials, track the progress through the shop with closed-loop MRP, and closely monitor the build to order actual
                        costs.
                    </p>
                    <p>It seamlessly integrates with inventory, purchase, subcontracting, and accounts, giving you overall visibility to make timely and sound decisions. You can benefit from flexible and comprehensive planning, scheduling and execution systems
                        regardless of whether you are a single plant, multi-plant, multi-geography or multi-company enterprise.</p>

                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Horizon for Manufacturing PART END ======-->

<!--====== CLIENTS PART START ======-->
<div class="brand-2-area pb-120  dec-spacing  gray-bg">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-12">
                <div class="experience-item">
                    <span>Damy Text</span>
                    <h2 class="title pb-15">Damy Text</h2>
                </div>
            </div> -->
            <div class="col-lg-12">
                <div class="brand-active">
                    <div class="brand-item">
                        <img src="assets/images/clients/urm.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/tms.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/gmc.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/gds.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/dar.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/alc-f.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/als-c.jpg" alt="brand name">
                    </div>
                </div>
                <!-- brand item -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== CLIENTS PART ENDS ======-->

<!--====== Components  PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="experience-item">
                    <span>Solution Modules</span>
                    <h2 class="title pb-15">Components of Manufacturing</h2>
                </div>
            </div>
            <div class="col-lg-6 add-arrow-circle">
                <h5 class="title pb-15">Main Components</h5>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/manufacturing-management">Manufacturing Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/financial-accounting">Financial Accounting</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/fixed-asset-management">Fixed Asset Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/inventory-management">Procurement and Inventory Control</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/human-resource-management-system"> Human Resource Management System (Standard)</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/approval-management">Approval Management System</a></p>

                <!-- experience item -->
            </div>
            <div class="col-lg-6 add-arrow-circle">
                <h5 class="title pb-15">Additional Components</h5>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/manufacturing">Supplier Self Service Portal</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/manufacturing">Approval Management App</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/human-resource-management-system">Human Resource Management System (Advance)</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/pmv-management">Plant, Machinery and Vehicle Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/bank-facility-management">Bank Facility Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/manufacturing">Dashboard Management</a></p>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Components  PART END ======-->

<!--====== Benefits at a glance  PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons

</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                    <p class="add-arrow">Analyzing material requirements accurately, keeping stocks as low as possible
                    </p>
                    <p class="add-arrow"> Tracking the status of production jobs by monitoring dates, activities, material requirements, and subcontractor activities</p>
                    <p class="add-arrow"> Helping you to quickly look for suppliers and make just-in-time delivery arrangements</p>
                    <p class="add-arrow"> Enabling you to combine production orders and refine the production process to its maximum potential</p>
                    <p class="add-arrow"> Highly flexible processing of orders helps meet irregular sales demands</p>
                    <p class="add-arrow"> Collect time against jobs directly from the shop floor</p>
                    <p class="add-arrow"> Get accurate, up to the minute costing on every job with accessible status reports</p>
                    <p class="add-arrow"> Meet and exceed customer delivery deadlines</p>
                    <p class="add-arrow"> Meet your own job profitability targets</p>
                    <p class="add-arrow"> Run a more efficient business with reduced overhead</p>
                    <p class="add-arrow"> Manage every aspect of your business with a complete, integrated ERP manufacturing software solution</p>
                    <p>Horizon – EBS for Manufacturing can be deployed on-premise or on the cloud with aligned mobile applications. If you are looking for more details or having queries about Horizon – EBS for Manufacturing, please feel free to Contact Us.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Benefits at a glance  PART END ======-->
