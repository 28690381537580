<!-- Dubai starts -->
<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div
      class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="
        background: url('../../../../assets/images/service/consulting.png') no-repeat center;
      background-size: cover;
      height: 430px;
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase text-center">Consulting & Advisory</h3>
        <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
      </div>
    </div>
    <div
      class="container-fluid d-flex justify-content-center align-content-center bg-white"
    >
      <div class="about-faq-area-custom horizon-hrms bg_cover">

          <div class="row">
            <div class="col-md-4">
              <div class="faq-accordion-3 pt-0">
              <div class="accordion" id="accordionExample1">
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px; background-color: #4180521A;">
                <div class="card-header" id="headingSev">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                    aria-expanded="true" aria-controls="collapseSevv" style="color: #418052;">
                    <span (click)="onAccounting()">Accounting & Transaction Processing</span>
                  </a>
                </div>
                <div id="collapseSevv" class="collapse " aria-labelledby="headingSev" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark serviceul">
                        <li routerLink="/accounting-services" style="color: black">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Accounts Payable
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingEight">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                    aria-expanded="false" aria-controls="collapseEight" style="color: #418052;">
                    <span (click)="onTax()">Tax & Regulatory Compliances</span>
                  </a>
                </div>
                <div id="collapseEight" class="collapse " aria-labelledby="headingEight" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark serviceul">
                        <li class="shli" routerLink="/coordination-and-assistance-for-vat-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         VAT Advisory
                        </li>
                        <li  routerLink="/coordination-and-assistance-for-tax-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Corporate Tax Advisory
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingNine">
                  <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                    aria-expanded="false" aria-controls="collapseNine" style="color: #418052;">
                    <span (click)="onAudit()"> Audit & Assurance Support</span>
                  </a>
                </div>
                <div id="collapseNine" class="collapse " aria-labelledby="headingNine" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark pt-4 serviceul">
                        <li  routerLink="/statutory-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Statutory Audit
                        </li>
                        <li  routerLink="/internal-audit">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Internal Audit
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->

              <!-- card -->
              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s"
              style="background-color: #4180521A;">
                <div class="card-header" id="headingTen">
                  <a  href="" data-toggle="collapse" data-target="#collapseTen"
                    aria-expanded="false" aria-controls="collapseTen" style="color: #418052;">
                    <span (click)="onConsulting()">Consulting & Advisory</span>
                  </a>
                </div>
                <div id="collapseTen" class="collapse show" aria-labelledby="headingTen" data-parent="#accordionExample1">
                  <div class="card-body bg-white">
                    <div>
                      <ul class="text-dark pt-4 serviceul">
                        <li  routerLink="/business-setup">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Setting up an entity in Dubai
                        </li>
                        <li  routerLink="/business-plan-preparation">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Business Plan Preparation
                        </li>
                        <li class="shli" routerLink="/business-valuation-service">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Business Valuation
                        </li>
                        <li routerLink="/mergers-and-acquisitions">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                         Mergers and Acquisitions (M&A)
                        </li>
                        <li routerLink="/strategic-cfo-services">
                          <i class="far fa-angle-double-right pt-1 pr-1"></i>
                          Strategic CFO
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card -->
              </div>
              </div>

            </div>
            <div class="col-md-8">
              <div style="margin-top: 1rem">
                <h3 id="business-valuation-service" style="color: #418052;">Business Valuation</h3>
                <p class="mb-15 mt-20 text-dark servicep">
                  Understanding the true value of your business is crucial for making
                  informed decisions regarding acquisitions, mergers, partnerships, or
                  selling your company. BFAG specializes in providing accurate and
                  comprehensive business valuation solutions to help you navigate
                  these critical decisions. Our experienced team of valuation experts
                  combines industry knowledge, financial analysis, and market insights
                  to deliver reliable and objective valuation reports.
                </p>
              </div>
              <div class="about-faq-area-custom horizon-hrms gray-bg clearfix" style="background-color: #4180521A;">
                <div class="container">
                  <div class="row" style="display: block">
                    <div
                      class="faq-accordion-2"
                      style="padding-bottom: 20px; padding-top: 20px"
                    >
                      <div class="accordion" id="accordionExample2">
                        <div class="col-lg-6">
                          <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".2s"
                          >
                            <div class="card-header" id="headingEleven">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseEleven"
                                aria-expanded="false"
                                aria-controls="collapseEleven" style=" color: #418052A1;"
                              >
                                Expert Valuation Professionals
                              </a>
                            </div>
                            <div
                              id="collapseEleven"
                              class="collapse"
                              aria-labelledby="headingEleven"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;"
                                >
                                  BFAG boasts a team of experienced valuation
                                  professionals who possess in-depth knowledge of
                                  various industries and valuation methodologies. We
                                  stay updated with the latest market trends and
                                  regulatory requirements to ensure that our valuation
                                  reports are accurate, reliable, and in compliance
                                  with industry standards.
                                </p>
                              </div>
                            </div>
                          </div>
                          <!-- card -->
                          <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".3s"
                          >
                            <div class="card-header" id="headingTwelve">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseTwelve"
                                aria-expanded="false"
                                aria-controls="collapseTwelve" style=" color: #418052A1;"
                              >
                                Comprehensive Valuation Analysis
                              </a>
                            </div>
                            <div
                              id="collapseTwelve"
                              class="collapse"
                              aria-labelledby="headingTwelve"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;"
                                >
                                  Our business valuation services encompass a thorough
                                  analysis of your company's financial performance,
                                  assets, liabilities, market position, and growth
                                  prospects. We utilize industry-specific valuation
                                  approaches such as income-based, market-based, and
                                  asset-based methods to provide you with a
                                  comprehensive assessment of your business's worth.
                                </p>
                              </div>
                            </div>
                          </div>
                          <!-- card -->
                          <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".7s"
                          >
                            <div class="card-header" id="headingSeventeen">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseSeventeen"
                                aria-expanded="false"
                                aria-controls="collapseSeventeen" style=" color: #418052A1;"
                              >
                                Confidentiality and Data Security
                              </a>
                            </div>
                            <div
                              id="collapseSeventeen"
                              class="collapse"
                              aria-labelledby="headingSeventeen"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;"
                                >
                                  BFAG understands the importance of confidentiality
                                  when it comes to business valuation. We prioritize
                                  the security of your sensitive financial
                                  information. Our systems and processes are designed
                                  to protect your data and ensure compliance with
                                  industry best practices and data protection
                                  regulations.
                                </p>
                              </div>
                            </div>
                          </div>
                          <!-- <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".4s"
                          >
                            <div class="card-header" id="headingThirteen">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseThirteen"
                                aria-expanded="false"
                                aria-controls="collapseThirteen" style=" color: #418052A1;"
                              >
                                Expert Valuation Professionals
                              </a>
                            </div>
                            <div
                              id="collapseThirteen"
                              class="collapse"
                              aria-labelledby="headingThirteen"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;"
                                >
                                  BFAG boasts a team of experienced valuation
                                  professionals who possess in-depth knowledge of
                                  various industries and valuation methodologies. We
                                  stay updated with the latest market trends and
                                  regulatory requirements to ensure that our valuation
                                  reports are accurate, reliable, and in compliance
                                  with industry standards.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".5s"
                          >
                            <div class="card-header" id="headingFourteen">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseFourteen"
                                aria-expanded="false"
                                aria-controls="collapseFourteen" style=" color: #418052A1;"
                              >
                                Comprehensive Valuation Analysis
                              </a>
                            </div>
                            <div
                              id="collapseFourteen"
                              class="collapse"
                              aria-labelledby="headingFourteen"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;"
                                >
                                  Our business valuation services encompass a thorough
                                  analysis of your company's financial performance,
                                  assets, liabilities, market position, and growth
                                  prospects. We utilize industry-specific valuation
                                  approaches such as income-based, market-based, and
                                  asset-based methods to provide you with a
                                  comprehensive assessment of your business's worth.
                                </p>
                              </div>
                            </div>
                          </div> -->

                          <!-- card -->
                        </div>

                        <div class="col-lg-6">
                          <!-- card -->
                          <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".5s"
                          >
                            <div class="card-header" id="headingNineteen">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseNineteen"
                                aria-expanded="false"
                                aria-controls="collapseNineteen" style=" color: #418052A1;"
                              >
                                Objective and Independent Assessments
                              </a>
                            </div>
                            <div
                              id="collapseNineteen"
                              class="collapse"
                              aria-labelledby="headingNineteen"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;"
                                >
                                  As an independent valuation firm, BFAG ensures that
                                  our valuation reports are objective and unbiased. We
                                  adhere to professional ethics and standards,
                                  maintaining transparency and integrity throughout
                                  the valuation process. Our unbiased assessments
                                  provide you with a reliable foundation for
                                  decision-making and negotiations.
                                </p>
                              </div>
                            </div>
                          </div>
                          <!-- card -->
                          <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".6s"
                          >
                            <div class="card-header" id="headingSixteen">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseSixteen"
                                aria-expanded="false"
                                aria-controls="collapseSixteen" style=" color: #418052A1;"
                              >
                                Transaction Support and Due Diligence
                              </a>
                            </div>
                            <div
                              id="collapseSixteen"
                              class="collapse"
                              aria-labelledby="headingSixteen"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;"
                                >
                                  BFAG goes beyond business valuation by offering
                                  transaction support and due diligence services.
                                  Whether you are involved in acquisitions, mergers,
                                  partnerships, or selling your business, our team can
                                  assist you in assessing the financial viability and
                                  value implications of the transaction. We provide
                                  valuable insights to help you negotiate favorable
                                  terms and mitigate risks.
                                </p>
                              </div>
                            </div>
                          </div>
                          <!-- card -->

                          <div
                            class="card wow fadeInLeft"
                            data-wow-duration=".1.2s"
                            data-wow-delay=".8s"
                          >
                            <div class="card-header" id="headingEightteen">
                              <a
                                class="collapsed"
                                href=""
                                data-toggle="collapse"
                                data-target="#collapseEightteen"
                                aria-expanded="false"
                                aria-controls="collapseEightteen" style=" color: #418052A1;"
                              >
                                Client-Centric Approach
                              </a>
                            </div>
                            <div
                              id="collapseEightteen"
                              class="collapse"
                              aria-labelledby="headingEightteen"
                              data-parent="#accordionExample2"
                            >
                              <div class="card-body text-justify" style="padding-left:20px !important">
                                <p style=" color: black;"
                                >
                                  At BFAG, we prioritize client satisfaction and
                                  strive to build long-term relationships based on
                                  trust and exceptional service. We take the time to
                                  understand your unique business requirements and
                                  tailor our valuation services accordingly. Our
                                  dedicated team provides ongoing support and guidance
                                  throughout the valuation process, ensuring your
                                  needs are met.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- faq accordion -->
                    </div>
                  </div>
                  <!-- row -->
                </div>
                <!-- container -->
              </div>
              <p class="mb-15 mt-20 text-dark servicep">
                We adopt internationally accepted methods of valuation and help with:
              </p>
              <div class="flex flex-wrap" style="gap: 0.5rem !important">
                <span class="pl-3"><li class="text-dark">Enterprise Valuation</li> </span>
                <span class="pl-3"><li class="text-dark">License Valuation</li></span>
                 <span class="pl-3"> <li class="text-dark">Option Valuation</li></span>
              </div>
              <p class="mb-15 mt-20 text-dark servicep">
                Uncover the true value of your business with BFAG's professional
                business valuation services. Our experienced team of valuation experts
                will provide you with comprehensive and accurate valuation reports to
                support your strategic decisions regarding acquisitions, mergers,
                partnerships, or selling your company. Benefit from our industry
                knowledge, financial expertise, and client-centric approach to gain a
                competitive edge in the market. Contact BFAG today to unlock the true
                worth of your business.
              </p>
            </div>
          </div>
          <!-- row -->

      </div>

    </div>
  </div>
</section>
<!-- Dubai ends -->

<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div
      class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="
        background: url('../../../../assets/images/service/consulting.png') no-repeat center;
      background-size: cover;
      height: 430px;
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase text-center">Consulting & Advisory</h3>
        <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
      </div>
    </div>
    <div
      class="container-fluid d-flex justify-content-center align-content-center bg-white"
    >
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                  <div class="faq-accordion-3 pt-0">
                  <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev" >
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                      aria-expanded="true" aria-controls="collapseSevv" >
                       <span (click)="onAccounting()"> Accounting & Transaction Processing</span>
                      </a>
                    </div>
                    <div id="collapseSevv" class="collapse " aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div class="list-group">
                          <ul class="text-dark serviceul">

                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Receivable
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Fixed Assets Accounting
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Budgeting & Variance Analysis
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Consolidation Reporting & GAAP Conversion
                            </li>
                            <li routerLink="/small-business-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Small Business Accounting
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li routerLink="/individual-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Individual Tax Return
                            </li>
                            <li routerLink="/corporate-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Tax Return
                            </li>
                            <li routerLink="/sales-and-use-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Sales & Use Tax
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span (click)="onPayroll()">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Payroll Processing
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a  href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span (click)="onConsulting()">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse show" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Setting up an entity in the US
                            </li>
                            <li routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Global Corporate Structuring
                            </li>
                            <li routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Plan Preparation
                            </li>
                            <li class="shli" routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#business-valuation-service"> Business Valuation</a>
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div id="business-valuation-service" style="margin-top: 1rem">
                    <h3>Business Valuation</h3>
                    <p class="mb-15 mt-20 text-dark servicep">
                      Understanding the true value of your business is crucial for making
                      informed decisions regarding acquisitions, mergers, partnerships, or
                      selling your company. BFAG specializes in providing accurate and
                      comprehensive business valuation solutions to help you navigate
                      these critical decisions. Our experienced team of valuation experts
                      combines industry knowledge, financial analysis, and market insights
                      to deliver reliable and objective valuation reports.
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                      <div class="row" style="display: block">
                        <div
                          class="faq-accordion-2"
                          style="padding-bottom: 20px; padding-top: 20px"
                        >
                          <div class="accordion" id="accordionExample2">
                            <div class="col-lg-6">
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".2s"
                              >
                                <div class="card-header" id="headingEleven">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseEleven"
                                    aria-expanded="false"
                                    aria-controls="collapseEleven"
                                  >
                                    Expert Valuation Professionals
                                  </a>
                                </div>
                                <div
                                  id="collapseEleven"
                                  class="collapse"
                                  aria-labelledby="headingEleven"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG boasts a team of experienced valuation
                                      professionals who possess in-depth knowledge of
                                      various industries and valuation methodologies. We
                                      stay updated with the latest market trends and
                                      regulatory requirements to ensure that our valuation
                                      reports are accurate, reliable, and in compliance
                                      with industry standards.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".3s"
                              >
                                <div class="card-header" id="headingTwelve">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseTwelve"
                                    aria-expanded="false"
                                    aria-controls="collapseTwelve"
                                  >
                                    Comprehensive Valuation Analysis
                                  </a>
                                </div>
                                <div
                                  id="collapseTwelve"
                                  class="collapse"
                                  aria-labelledby="headingTwelve"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      Our business valuation services encompass a thorough
                                      analysis of your company's financial performance,
                                      assets, liabilities, market position, and growth
                                      prospects. We utilize industry-specific valuation
                                      approaches such as income-based, market-based, and
                                      asset-based methods to provide you with a
                                      comprehensive assessment of your business's worth.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".4s"
                              >
                                <div class="card-header" id="headingThirteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseThirteen"
                                    aria-expanded="false"
                                    aria-controls="collapseThirteen"
                                  >
                                    Accurate Financial Modeling
                                  </a>
                                </div>
                                <div
                                  id="collapseThirteen"
                                  class="collapse"
                                  aria-labelledby="headingThirteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG employs advanced financial modeling techniques
                                      to assess the financial health and future potential
                                      of your business. We evaluate historical financial
                                      data, forecast future cash flows, and consider
                                      relevant risk factors to develop a robust financial
                                      model that supports the valuation process.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".4s"
                              >
                                <div class="card-header" id="headingFourteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseFourteen"
                                    aria-expanded="false"
                                    aria-controls="collapseFourteen"
                                  >
                                    Objective and Independent Assessments
                                  </a>
                                </div>
                                <div
                                  id="collapseFourteen"
                                  class="collapse"
                                  aria-labelledby="headingFourteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      As an independent valuation firm, BFAG ensures that
                                      our valuation reports are objective and unbiased. We
                                      adhere to professional ethics and standards,
                                      maintaining transparency and integrity throughout
                                      the valuation process. Our unbiased assessments
                                      provide you with a reliable foundation for
                                      decision-making and negotiations.
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <!-- card -->
                            </div>

                            <div class="col-lg-6">
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".6s"
                              >
                                <div class="card-header" id="headingSixteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseSixteen"
                                    aria-expanded="false"
                                    aria-controls="collapseSixteen"
                                  >
                                    Transaction Support and Due Diligence
                                  </a>
                                </div>
                                <div
                                  id="collapseSixteen"
                                  class="collapse"
                                  aria-labelledby="headingSixteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG goes beyond business valuation by offering
                                      transaction support and due diligence services.
                                      Whether you are involved in acquisitions, mergers,
                                      partnerships, or selling your business, our team can
                                      assist you in assessing the financial viability and
                                      value implications of the transaction. We provide
                                      valuable insights to help you negotiate favorable
                                      terms and mitigate risks.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".4s"
                              >
                                <div class="card-header" id="headingSeventeen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseSeventeen"
                                    aria-expanded="false"
                                    aria-controls="collapseSeventeen"
                                  >
                                    Confidentiality and Data Security
                                  </a>
                                </div>
                                <div
                                  id="collapseSeventeen"
                                  class="collapse"
                                  aria-labelledby="headingSeventeen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG understands the importance of confidentiality
                                      when it comes to business valuation. We prioritize
                                      the security of your sensitive financial
                                      information. Our systems and processes are designed
                                      to protect your data and ensure compliance with
                                      industry best practices and data protection
                                      regulations.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".5s"
                              >
                                <div class="card-header" id="headingEightteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseEightteen"
                                    aria-expanded="false"
                                    aria-controls="collapseEightteen"
                                  >
                                    Client-Centric Approach
                                  </a>
                                </div>
                                <div
                                  id="collapseEightteen"
                                  class="collapse"
                                  aria-labelledby="headingEightteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      At BFAG, we prioritize client satisfaction and
                                      strive to build long-term relationships based on
                                      trust and exceptional service. We take the time to
                                      understand your unique business requirements and
                                      tailor our valuation services accordingly. Our
                                      dedicated team provides ongoing support and guidance
                                      throughout the valuation process, ensuring your
                                      needs are met.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- faq accordion -->
                        </div>
                      </div>
                      <!-- row -->
                    </div>
                    <!-- container -->
                  </div>
                  <p class="mb-15 mt-20 text-dark servicep">
                    We adopt internationally accepted methods of valuation and help with:
                  </p>
                  <div class="flex flex-wrap" style="gap: 0.5rem !important">
                    <span class="pl-3"><li class="text-dark">Enterprise Valuation</li> </span>
                    <span class="pl-3"><li class="text-dark">License Valuation</li></span>
                     <span class="pl-3"> <li class="text-dark">Option Valuation</li></span>
                  </div>
                  <p class="mb-15 mt-20 text-dark servicep">
                    Uncover the true value of your business with BFAG's professional
                    business valuation services. Our experienced team of valuation experts
                    will provide you with comprehensive and accurate valuation reports to
                    support your strategic decisions regarding acquisitions, mergers,
                    partnerships, or selling your company. Benefit from our industry
                    knowledge, financial expertise, and client-centric approach to gain a
                    competitive edge in the market. Contact BFAG today to unlock the true
                    worth of your business.
                  </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>
  </div>
</section>
<!-- India Starts -->


<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div
      class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="
        background: url('../../../../assets/images/service/consulting.png') no-repeat center;
      background-size: cover;
      height: 430px;
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase">Consulting & Advisory</h3>
        <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
      </div>
    </div>
    <div
      class="container-fluid d-flex justify-content-center align-content-center bg-white"
    >
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                   <div class="faq-accordion-3 pt-0">
                   <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev" >
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSevv"
                      aria-expanded="true" aria-controls="collapseSevv" >
                       <span (click)="onAccounting()"> Accounting & Transaction Processing</span>
                      </a>
                    </div>
                    <div id="collapseSevv" class="collapse " aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div class="list-group">
                          <ul class="text-dark serviceul">

                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Receivable
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Fixed Assets Accounting
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Budgeting & Variance Analysis
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Consolidation Reporting & GAAP Conversion
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li routerLink="/income-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Income Tax
                            </li>
                            <li  routerLink="/goods-and-service-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Goods & Service Tax
                            </li>
                            <li routerLink="/corporate-secretarial-compliance">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Corporate Secretarial Compliance
                            </li>
                            <li  routerLink="/fema-advisory">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             FEMA Compliance
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span (click)="onPayroll()">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Payroll - Compensation & Benefit Management
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a  href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span (click)="onConsulting()">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse show" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Setting up an entity in the India
                            </li>
                            <li routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Global Corporate Structuring
                            </li>
                            <li routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Plan Preparation
                            </li>
                            <li class="shli" routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Valuation
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingElevenStartup">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseElevenStartup"
                        aria-expanded="false" aria-controls="collapseElevenStartup">
                        <span (click)="onStartup()">Startup Solution</span>
                      </a>
                    </div>
                    <div id="collapseElevenStartup" class="collapse" aria-labelledby="headingElevenStartup" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/startup-solution">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Startup Solution
                            </li>
                            <li routerLink="/fund-raising">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Fund Raise
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                   </div>
                   </div>
                </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3 id="business-valuation-service">Business Valuation</h3>
                    <p class="mb-15 mt-20 text-dark servicep">
                      Understanding the true value of your business is crucial for making
                      informed decisions regarding acquisitions, mergers, partnerships, or
                      selling your company. BFAG specializes in providing accurate and
                      comprehensive business valuation solutions to help you navigate
                      these critical decisions. Our experienced team of valuation experts
                      combines industry knowledge, financial analysis, and market insights
                      to deliver reliable and objective valuation reports.
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                      <div class="row" style="display: block">
                        <div
                          class="faq-accordion-2"
                          style="padding-bottom: 20px; padding-top: 20px"
                        >
                          <div class="accordion" id="accordionExample2">
                            <div class="col-lg-6">
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".2s"
                              >
                                <div class="card-header" id="headingEleven">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseEleven"
                                    aria-expanded="false"
                                    aria-controls="collapseEleven"
                                  >
                                    Expert Valuation Professionals
                                  </a>
                                </div>
                                <div
                                  id="collapseEleven"
                                  class="collapse"
                                  aria-labelledby="headingEleven"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG boasts a team of experienced valuation
                                      professionals who possess in-depth knowledge of
                                      various industries and valuation methodologies. We
                                      stay updated with the latest market trends and
                                      regulatory requirements to ensure that our valuation
                                      reports are accurate, reliable, and in compliance
                                      with industry standards.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".3s"
                              >
                                <div class="card-header" id="headingTwelve">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseTwelve"
                                    aria-expanded="false"
                                    aria-controls="collapseTwelve"
                                  >
                                    Comprehensive Valuation Analysis
                                  </a>
                                </div>
                                <div
                                  id="collapseTwelve"
                                  class="collapse"
                                  aria-labelledby="headingTwelve"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      Our business valuation services encompass a thorough
                                      analysis of your company's financial performance,
                                      assets, liabilities, market position, and growth
                                      prospects. We utilize industry-specific valuation
                                      approaches such as income-based, market-based, and
                                      asset-based methods to provide you with a
                                      comprehensive assessment of your business's worth.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                              class="card wow fadeInLeft"
                              data-wow-duration=".1.2s"
                              data-wow-delay=".7s"
                            >
                              <div class="card-header" id="headingSeventeen">
                                <a
                                  class="collapsed"
                                  href=""
                                  data-toggle="collapse"
                                  data-target="#collapseSeventeen"
                                  aria-expanded="false"
                                  aria-controls="collapseSeventeen"
                                >
                                  Confidentiality and Data Security
                                </a>
                              </div>
                              <div
                                id="collapseSeventeen"
                                class="collapse"
                                aria-labelledby="headingSeventeen"
                                data-parent="#accordionExample2"
                              >
                                <div class="card-body text-justify" style="padding-left:20px !important">
                                  <p>
                                    BFAG understands the importance of confidentiality
                                    when it comes to business valuation. We prioritize
                                    the security of your sensitive financial
                                    information. Our systems and processes are designed
                                    to protect your data and ensure compliance with
                                    industry best practices and data protection
                                    regulations.
                                  </p>
                                </div>
                              </div>
                            </div>
                              <!-- card -->
                              <!-- <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".4s"
                              >
                                <div class="card-header" id="headingThirteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseThirteen"
                                    aria-expanded="false"
                                    aria-controls="collapseThirteen"
                                  >
                                    Expert Valuation Professionals
                                  </a>
                                </div>
                                <div
                                  id="collapseThirteen"
                                  class="collapse"
                                  aria-labelledby="headingThirteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG boasts a team of experienced valuation
                                      professionals who possess in-depth knowledge of
                                      various industries and valuation methodologies. We
                                      stay updated with the latest market trends and
                                      regulatory requirements to ensure that our valuation
                                      reports are accurate, reliable, and in compliance
                                      with industry standards.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".5s"
                              >
                                <div class="card-header" id="headingFourteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseFourteen"
                                    aria-expanded="false"
                                    aria-controls="collapseFourteen"
                                  >
                                    Comprehensive Valuation Analysis
                                  </a>
                                </div>
                                <div
                                  id="collapseFourteen"
                                  class="collapse"
                                  aria-labelledby="headingFourteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      Our business valuation services encompass a thorough
                                      analysis of your company's financial performance,
                                      assets, liabilities, market position, and growth
                                      prospects. We utilize industry-specific valuation
                                      approaches such as income-based, market-based, and
                                      asset-based methods to provide you with a
                                      comprehensive assessment of your business's worth.
                                    </p>
                                  </div>
                                </div>
                              </div> -->

                              <!-- card -->
                            </div>

                            <div class="col-lg-6">
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".5s"
                              >
                                <div class="card-header" id="headingNineteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseNineteen"
                                    aria-expanded="false"
                                    aria-controls="collapseNineteen"
                                  >
                                    Objective and Independent Assessments
                                  </a>
                                </div>
                                <div
                                  id="collapseNineteen"
                                  class="collapse"
                                  aria-labelledby="headingNineteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      As an independent valuation firm, BFAG ensures that
                                      our valuation reports are objective and unbiased. We
                                      adhere to professional ethics and standards,
                                      maintaining transparency and integrity throughout
                                      the valuation process. Our unbiased assessments
                                      provide you with a reliable foundation for
                                      decision-making and negotiations.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".6s"
                              >
                                <div class="card-header" id="headingSixteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseSixteen"
                                    aria-expanded="false"
                                    aria-controls="collapseSixteen"
                                  >
                                    Transaction Support and Due Diligence
                                  </a>
                                </div>
                                <div
                                  id="collapseSixteen"
                                  class="collapse"
                                  aria-labelledby="headingSixteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      BFAG goes beyond business valuation by offering
                                      transaction support and due diligence services.
                                      Whether you are involved in acquisitions, mergers,
                                      partnerships, or selling your business, our team can
                                      assist you in assessing the financial viability and
                                      value implications of the transaction. We provide
                                      valuable insights to help you negotiate favorable
                                      terms and mitigate risks.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <!-- card -->
                              <div
                                class="card wow fadeInLeft"
                                data-wow-duration=".1.2s"
                                data-wow-delay=".8s"
                              >
                                <div class="card-header" id="headingEightteen">
                                  <a
                                    class="collapsed"
                                    href=""
                                    data-toggle="collapse"
                                    data-target="#collapseEightteen"
                                    aria-expanded="false"
                                    aria-controls="collapseEightteen"
                                  >
                                    Client-Centric Approach
                                  </a>
                                </div>
                                <div
                                  id="collapseEightteen"
                                  class="collapse"
                                  aria-labelledby="headingEightteen"
                                  data-parent="#accordionExample2"
                                >
                                  <div class="card-body text-justify" style="padding-left:20px !important">
                                    <p>
                                      At BFAG, we prioritize client satisfaction and
                                      strive to build long-term relationships based on
                                      trust and exceptional service. We take the time to
                                      understand your unique business requirements and
                                      tailor our valuation services accordingly. Our
                                      dedicated team provides ongoing support and guidance
                                      throughout the valuation process, ensuring your
                                      needs are met.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- faq accordion -->
                        </div>
                      </div>
                      <!-- row -->
                    </div>
                    <!-- container -->
                  </div>
                  <p class="mb-15 mt-20 text-dark servicep">
                    We adopt internationally accepted methods of valuation and help with:
                  </p>
                  <!-- <ul style="padding-left: 2%">
                    <li class="text-dark">1. Enterprise Valuation</li>
                    <li class="text-dark">2. License Valuation</li>
                    <li class="text-dark">3. Option Valuation</li>
                  </ul> -->
                  <div class="flex flex-wrap" style="gap: 0.5rem !important">
                    <span class="pl-3"><li class="text-dark">Enterprise Valuation</li> </span>
                    <span class="pl-3"><li class="text-dark">License Valuation</li></span>
                     <span class="pl-3"> <li class="text-dark">Option Valuation</li></span>
                  </div>
                  <p class="mb-15 mt-20 text-dark servicep">
                    Uncover the true value of your business with BFAG's professional
                    business valuation services. Our experienced team of valuation experts
                    will provide you with comprehensive and accurate valuation reports to
                    support your strategic decisions regarding acquisitions, mergers,
                    partnerships, or selling your company. Benefit from our industry
                    knowledge, financial expertise, and client-centric approach to gain a
                    competitive edge in the market. Contact BFAG today to unlock the true
                    worth of your business.
                  </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>
  </div>
</section>
<!-- India Ends -->
