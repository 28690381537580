<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="container-fluid min-vh-100">
  <div class="row d-flex justify-content-center align-items-center " style="padding-top: 150px;">
    <div class="text-center mt-20 d-flex flex-column justify-content-center align-items-center">
      <h1 class="">Under Development</h1>
      <p class="lead">We apologize for the inconvenience. This page is currently under development.</p>
      <p>Please check back later.</p>
    </div>
  </div>
</div>
<!-- USA ends -->

<!-- India start-->
<div *ngIf="selectCountryName == 'India'" class="container-fluid min-vh-100">
  <div class="row d-flex justify-content-center align-items-center " style="padding-top: 150px;">
    <div class="text-center mt-20 d-flex flex-column justify-content-center align-items-center">
      <h1 class="">Under Development</h1>
      <p class="lead">We apologize for the inconvenience. This page is currently under development.</p>
      <p>Please check back later.</p>
    </div>
  </div>
</div>
<!-- India Ends -->

<!-- Dubai start-->
<div *ngIf="selectCountryName == 'Dubai'" class="container-fluid min-vh-100">
  <div class="row d-flex justify-content-center align-items-center " style="padding-top: 150px;">
    <div class="text-center mt-20 d-flex flex-column justify-content-center align-items-center">
      <h1 class="">Under Development</h1>
      <p class="lead">We apologize for the inconvenience. This page is currently under development.</p>
      <p>Please check back later.</p>
    </div>
  </div>
</div>
<!-- dubai Ends -->
