<!--====== PRELOADER PART START ======-->
<div class="preloader">
    <div class="loading">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!--====== PRELOADER PART START ======-->
