import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mep-contracting',
  templateUrl: './mep-contracting.component.html',
  styleUrls: ['./mep-contracting.component.css']
})
export class MepContractingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
