
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Project Accounting</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Project Accounting</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Project Management Module PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Project Accounting Solutions</span>
                    <h2 class="title pb-15">Project Accounting</h2>
                    <p>The user-friendly Project Accounting module in Horizon delivers a robust platform that aids in managing project costing, budgeting, and invoicing. It connects project activities with company financials, helps ensure accurate accounting
                        and billing throughout project lifecycles, and streamlines time and expense management and provides extensive reporting capabilities. </p>
                    <p>Project Accounting Module is seamlessly integrated with the general ledger, accounts payable, accounts receivable, purchase orders, subcontracting, inventory management, and the human resource management system. You can get the total
                        view of all costs related to a project or a job including materials, labor, subcontractors, inventory, and overhead. You can allocate shared costs and overhead expenses to projects or jobs.</p>
                    <p>It is simple, easy-to-use, powerful and ideal for project-centric businesses including Construction, MEP Contracting and General Contracting companies.</p>

                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Project Management Module PART END ======-->

<!--====== Features  PART START ======-->
<div class="about-faq-area about-faq-area-custom horizon-hrms dec-spacing bg_cover gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="about-faq-content">
                    <span>Highlights</span>
                    <h2 class="title">Features</h2>
                </div>
            </div>
            <div class="faq-accordion-3">
                <div class="accordion" id="accordionExample">
                    <div class="col-lg-6">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOne">
                                <a class="remove-icon  collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Simplified project hierarchy for maximum efficiency
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>It manages multiple types of projects and project expense categories including labor, materials, sub contracting, overheads, plant and equipments for better monitoring of project across all the integrated modules.</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwo">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>User defined Project/Job Types
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>It estimates labor, material, sub contractor, overheads, plant and equipment cost and revenue for jobs based on the values entered in the system using the various expense categories available. This further gets monitored and validate through various integrated modules.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Expense or Budget Class Definition
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Labor </p>
                                    <p>Material </p>
                                    <p>Subcontract </p>
                                    <p>Overhead </p>
                                    <p>Plant and Equipment etc.</p>

                                </div>
                            </div>
                        </div>

                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEight">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    <i class="fal fa-magic"></i>Payment/ Invoice Component Definition
                                </a>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Revenue</p>
                                    <p>Advance</p>
                                    <p>Retention</p>
                                    <p>Back Charges etc. </p>
                                    <!-- <p></p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingNine">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseNine">
                                    <i class="fal fa-magic"></i>Unlimited Expense or Budget Codes with GL integration
                                </a>
                            </div>
                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                <div class="card-body">

                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingNinea">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseNinea">
                                    <i class="fal fa-magic"></i>Upload the estimated value with Expense or Budget Codes
                                </a>
                            </div>
                            <div id="collapseNinea" class="collapse" aria-labelledby="headingNinea" data-parent="#accordionExample">
                                <div class="card-body">

                                </div>
                            </div>
                        </div>
                        <!-- card -->

                    </div>

                    <div class="col-lg-6">

                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwoa">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseTwoa">
                                    <i class="fal fa-magic"></i>Revision of Budgets or Estimations with Variations
                                </a>
                            </div>
                            <div id="collapseTwoa" class="collapse" aria-labelledby="headingTwoa" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>It estimates labor, material, sub contractor, overheads, plant and equipment cost and revenue for jobs based on the values entered in the system using the various expense categories available. This further gets monitored and validate through various integrated modules.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwob">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwob" aria-expanded="false" aria-controls="collapseTwob">
                                    <i class="fal fa-magic"></i>Sub Contract Management
                                </a>
                            </div>
                            <div id="collapseTwob" class="collapse" aria-labelledby="headingTwob" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Sub Contract LPOs</p>
                                    <p>Variation to the jobs</p>
                                    <p>Payment Applications</p>
                                    <p>Payment Certifications</p>

                                    <!-- <p>It estimates labor, material, sub contractor, overheads, plant and equipment cost and revenue for jobs based on the values entered in the system using the various expense categories available. This further gets monitored and validate through various integrated modules.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Project Invoicing / Application and Certifications
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Labor </p>
                                    <p>Material </p>
                                    <p>Subcontract </p>
                                    <p>Overhead </p>
                                    <p>Plant and Equipment etc.</p> -->

                                </div>
                            </div>
                        </div>

                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingEight">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseEight">
                                    <i class="fal fa-magic"></i>Integration with all the other ERP Modules
                                </a>
                            </div>
                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                <div class="card-body">
                                    <!-- <p>Revenue</p>
                                    <p>Advance</p>
                                    <p>Retention</p>
                                    <p>Back Charges etc. </p> -->
                                    <!-- <p></p> -->
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingNine">
                                <a class="remove-icon collapsed" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseNine">
                                    <i class="fal fa-magic"></i>Cost Control and Analysis based on the Budget Codes
                                </a>
                            </div>
                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                <div class="card-body">

                                </div>
                            </div>
                        </div>
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingNinea">
                                <a class="remove-icon collapsed change-line-height" href="" data-toggle="collapse" data-target="#" aria-expanded="false" aria-controls="collapseNinea">
                                    <i class="fal fa-magic"></i>Powerful MIS, Analytical and Business Intelligence Reporting
                                </a>
                            </div>
                            <div id="collapseNinea" class="collapse" aria-labelledby="headingNinea" data-parent="#accordionExample">
                                <div class="card-body">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== Features  PART END ======-->

<!--====== Benefits at a glance  PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                    <p class="add-arrow">It manages multiple types of projects and project expense categories including labor, materials, sub-contracting, overheads, plant, and equipment for better monitoring of projects across all the integrated modules. </p>
                    <p class="add-arrow">You can bill projects based on completing project activities or achieving project milestones. This can be further certified by the client for actual revenue recognition in the system.
                    </p>
                    <p class="add-arrow">You can track all the financial metrics of a project including budgets, estimates, work in progress, bookings, invoices, profit, and costs.</p>
                    <p class="add-arrow">Sub Contract Management can be done more efficiently with complete monitoring of Advance, Retention, Material Back Charges, and Variations, etc. The complete payment application and certification can be done very efficiently. </p>
                    <p class="add-arrow">Gain insights and make better decisions with a powerful summary and detailed MIS reports available. You can track the progress and monitor costs associated with each project, keeping you on top of financial performance. Monitor project
                        profitability and get real-time visibility into project against estimated project expense categories.</p>
                    <p class="add-arrow">The Project Dashboard gives project managers a visual, graphical analysis of the status of specific projects. Project managers can see important key performance indicators (KPIs) at a glance such as which projects are on time, under
                        budget, and destined for success. The dashboard updates automatically to provide you with an up-to-date view of the current health of your projects, while it’s happening.</p>

                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Benefits at a glance  PART END ======-->
