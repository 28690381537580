import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ContactModalComponent } from '../../dialog/contact-modal/contact-modal.component';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  searchDiv: boolean = false;
  iconHide: boolean = false;
  iconShow: boolean = true;
  id;
  activeNew: boolean = false;
  selectCountryName: any;
  selectedFlag: string;
  defaultCountry: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private LocalStorageServiceService: LocalStorageServiceService,
    public dialog: MatDialog
  ) {}



  ngOnInit(): void {
    this.defaultCountry = 'USA';

    this.selectCountryName = localStorage.getItem('selectedCountry');

    if (this.selectCountryName == null) {
      localStorage.setItem('selectedCountry', this.defaultCountry);
      window.location.reload();
    } else {
      this.selectCountryName = localStorage.getItem('selectedCountry');
    }
    this.refreshIfUAESaved();
  }

  ngAfterViewInit(): void {
    this.id = this.route.snapshot.params.id;
    if (this.router.url == '/news-and-blogs/details/' + this.id) {
      this.activeNew = true;
    } else {
      this.activeNew = false;
    }
    this.refreshIfUAESaved();
  }


  refreshIfUAESaved(): void {
    const selectedCountry = localStorage.getItem('selectedCountry');

    if (selectedCountry === 'UAE') {
      // Remove 'UAE' from local storage
      localStorage.removeItem('selectedCountry');

      // Reload the page
      window.location.reload();
    }
  }

  showSearchBar() {
    this.searchDiv = true;
    this.iconShow = false;
    this.iconHide = true;
  }

  hideSearchBar() {
    this.searchDiv = false;
    this.iconShow = true;
    this.iconHide = false;
  }

  search() {
    this.router.navigate(['./search'], { relativeTo: this.route });
  }

  openDialog() {
    const dialogRef = this.dialog.open(ContactModalComponent, {
      width: '555px',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }



  selectCountry(country: string): void {
    // Update the selectedFlag with the corresponding image path
    if (country === 'Dubai') {
      this.selectedFlag =
        '/assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png';
    } else if (country === 'India') {
      this.selectedFlag = '/assets/images/flag/download.png';
    } else if (country === 'USA') {
      this.selectedFlag = '/assets/images/flag/USA.png';
    } else {
      // Set USA as the default country
      this.selectedFlag =
        '/assets/images/flag/USA.png';
      country = 'USA';
    }

    this.LocalStorageServiceService.setItem('selectedCountry', country);
  }




  redirectToOtherPage() {
    this.router.navigate(['/app-services']);
    console.log('clicked');
    console.log(this.router);
  }
}
