<div
  *ngIf="selectCountryName == 'Dubai'"
  class="min-vh-100 container-fluid mt-150 pb-5"
>
  <div class="row pl-2 pr-3">
    <div class="col-12 pl-0 pr-0 col-md-3 col-md-3 shadow">
      <div class="p-3" style="background-color: #21ac51">
        <h5 class="pl-2 text-white">Consulting And Advisory Services</h5>
      </div>
      <div
        class="nav flex-column nav-pills mt-3 mr-3 mb-3 ml-2 justify-content-center"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <button
          class="nav-link  border-0 bg-white text-dark d-flex"
          id="v-pills-businessPlanPreparation-tab"
          data-toggle="pill"
          data-target="#v-pills-businessPlanPreparation"
          type="button"
          role="tab"
          aria-controls="v-pills-businessPlanPreparation"
          aria-selected="false"
        >
        <i class="far fa-angle-double-right pt-1 pr-1"></i>Business Plan Preparation
        </button>
        <button
          class="nav-link  border-0 bg-white text-dark d-flex"
          id="v-pills-businessValuationService-tab"
          data-toggle="pill"
          data-target="#v-pills-businessValuationService"
          type="button"
          role="tab"
          aria-controls="v-pills-businessValuationService"
          aria-selected="false"
        >
        <i class="far fa-angle-double-right pt-1 pr-1"></i>Business Valuation Service
        </button>
        <button
          class="nav-link active border-0 bg-white text-dark d-flex"
          id="v-pills-financialDueDiligenceServices-tab"
          data-toggle="pill"
          data-target="#v-pills-financialDueDiligenceServices"
          type="button"
          role="tab"
          aria-controls="v-pills-financialDueDiligenceServices"
          aria-selected="true"
        >
        <i class="far fa-angle-double-right pt-1 pr-1"></i>Financial Due Diligence Services
        </button>
        <button
          class="nav-link border-0 bg-white text-dark d-flex"
          id="v-pills-fundRaise-tab"
          data-toggle="pill"
          data-target="#v-pills-fundRaise"
          type="button"
          role="tab"
          aria-controls="v-pills-fundRaise"
          aria-selected="false"
        >
        <i class="far fa-angle-double-right pt-1 pr-1"></i>Fund Raise
        </button>
        <button
          class="nav-link border-0 bg-white text-dark d-flex"
          id="v-pills-mergersAndAcquisitions-tab"
          data-toggle="pill"
          data-target="#v-pills-mergersAndAcquisitions"
          type="button"
          role="tab"
          aria-controls="v-pills-mergersAndAcquisitions"
          aria-selected="false"
        >
        <i class="far fa-angle-double-right pt-1 pr-1"></i>Mergers and Acquisitions
        </button>
        <button
          class="nav-link border-0 bg-white text-dark d-flex"
          id="v-pills-strategicCFOServices-tab"
          data-toggle="pill"
          data-target="#v-pills-strategicCFOServices"
          type="button"
          role="tab"
          aria-controls="v-pills-strategicCFOServices"
          aria-selected="false"
        >
        <i class="far fa-angle-double-right pt-1 pr-1"></i>Strategic CFO Services
        </button>
      </div>
    </div>

    <div class="col-9 pt-5">
      <div class="tab-content" id="v-pills-tabContent">
        <div
          class="tab-pane fade"
          id="v-pills-businessPlanPreparation"
          role="tabpanel"
          aria-labelledby="v-pills-businessPlanPreparation-tab"
        >
          <div class="col">
            <div
              class="col d-flex flex-column justify-content-center align-items-center"
            >
              <h4
                style="border-bottom: 3px solid #21ac51; display: inline-block"
                class="mb-4"
              >
                Business Plan Preparation
              </h4>
              <div class="pt-2 pb-2">
                <img
                  class="img-fluid"
                  src="../../../../assets/images/audit And Assurance/audit.png"
                  alt=""
                />
              </div>
              <p class="text-dark text-justify pt-2">
                Bringing business ideas to life is to prepare a promising
                business plan. A professional business plan should be curated
                with an understanding about the purpose of business, an
                extensive market research, competitor analysis, financial
                projections and convey its growth potential which can be
                presented to the investors or lenders. In short, a professional
                business plan is a roadmap for business.
              </p>
              <p class="text-dark text-justify">
                For a business or a startup, a good business plan is very
                important as it can serve as a roadmap for the Company as well
                as provide information in a systematic format to the promoters,
                investors and financial advisors. Business plan needs to have
                all the components that are required to determine the success of
                an enterprise.
              </p>
            </div>
            <div class="pl-3">
              <h6 class="mt-3 mb-3">
                ADVANTAGES OF HAVING A SOUND BUSINESS PLAN:
              </h6>
              <ul
                class="text-dark"
                style="
                  list-style-type: none;
                  line-height: 3rem;
                  font-size: 0.9rem;
                "
              >
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Determining whether the idea can succeed
                </li>
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Setting the priorities
                </li>
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Ensuring proper focus
                </li>
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Devising a strategy
                </li>
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Review Mechanism
                </li>
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Review Mechanism
                </li>
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Fund Management
                </li>
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Managing the change
                </li>
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Investment from Angels & Venture Capitalists
                </li>
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Raising Institutional Finance
                </li>
              </ul>
              <p class="text-dark pb-4 text-justify">
                Based on the requirements and business idea, we at BFAG conduct
                thorough market research, competitor analysis, financial
                forecasting as a part of building a comprehensive business plan.
              </p>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-businessValuationService"
          role="tabpanel"
          aria-labelledby="v-pills-businessValuationService-tab"
        >
          <div class="col">
            <div
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <h4
                style="border-bottom: 3px solid #21ac51; display: inline-block"
                class="mb-4"
              >
                Business Valuation Service
              </h4>
              <div class="pt-2 pb-2">
                <img
                  class="img-fluid"
                  src="../../../../assets/images/audit And Assurance/Rectangle 125.png"
                  alt=""
                />
              </div>
              <p class="text-dark text-justify pt-2">
                Valuation of a business or part / segment of a business requires
                deep understanding of complex factors such as market segment,
                technology, projected performance, current economic outlook, and
                industry knowledge. Enterprise or Business Valuation also
                requires an understanding of the purpose of valuation. A few of
                these purposes might be M&A, business restructuring, financial
                reporting, disputes, and litigations. Valuation is performed
                using asset-based, earning value, and market value approaches,
                so on and so forth. This may vary from one business to another.
                The key pieces of information used to determine the value of
                your business include financial records, management experience,
                market conditions, intangible assets, tangible assets, company
                size, and competitive advantage.
              </p>
              <p class="text-dark text-justify">
                We, at BFAG, put our many years of industry experience to use by
                providing strategies, methodologies, and models to undertake
                valuation of target companies
              </p>
            </div>
          </div>
          <div class="pl-3">
            <h6 class="mt-3 mb-3">
              BFAG adopts internationally accepted methods of valuation and
              assists in;
            </h6>
            <ul
              class="text-dark"
              style="
                list-style-type: none;
                line-height: 3rem;
                font-size: 0.9rem;
              "
            >
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Enterprise Valuation
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                License Valuation
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Option Valuation
              </li>
            </ul>
          </div>
        </div>
        <div
          class="tab-pane fade show active"
          id="v-pills-financialDueDiligenceServices"
          role="tabpanel"
          aria-labelledby="v-pills-financialDueDiligenceServices-tab"
        >
          <div class="col">
            <div
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <h4
                style="border-bottom: 3px solid #21ac51; display: inline-block"
                class="mb-4"
              >
                Financial Due Diligence Services
              </h4>
              <div class="pt-2 pb-2">
                <img
                  class="img-fluid"
                  src="../../../../assets/images/audit And Assurance/Rectangle 126.png"
                  alt=""
                />
              </div>
              <p class="text-dark text-justify pt-2">
                Financial Due Diligence (FDD) is performed by a potential
                investor to verify all the financial information. Financial Due
                Diligence provides an insight on compliance, operational,
                marketing, and financial matters. Any investment opportunity or
                potential deal has its own risk and without proper due
                diligence, it is challenging for an investor to decide on M&A.
                The purpose of FDD is to provide the investor a certain level of
                comfort.
              </p>
            </div>
          </div>
          <div class="pl-3">
            <h6 class="mt-3 mb-3">Our due diligence services include:</h6>
            <ul
              class="text-dark text-justify"
              style="
                list-style-type: none;
                line-height: 3rem;
                font-size: 0.9rem;
              "
            >
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Commercial and operational due diligence to provides a coherent
                overview of business operations including quality and scale of
                operations
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Financial and commercial due diligence, including
                target-specific market risks, industry-specific investment
                risks, market prospects, and value-creating opportunities
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Analysis of financial performance and market valuation and
                evaluation of their impact on financial reporting for pre-and
                post-deal strategies
              </li>
            </ul>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-fundRaise"
          role="tabpanel"
          aria-labelledby="v-pills-fundRaise-tab"
        >
          <div class="col">
            <div
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <h4
                style="border-bottom: 3px solid #21ac51; display: inline-block"
                class="mb-4"
              >
                Fund Raise
              </h4>
              <div class="pt-2 pb-2">
                <img
                  class="img-fluid"
                  src="../../../../assets/images/audit And Assurance/Rectangle 127.png"
                  alt=""
                />
              </div>
              <p class="text-dark text-justify pt-2">
                To raise funds for enterprises, they must have sustainable
                business, the idea must be sound, there should be a good
                business plan, investor preparedness, commitment from promoters
                and more. BFAG experts will help you become investment ready and
                then take you to suitable investors.
              </p>
            </div>
            <div>
              <h6 class="mt-3 mb-3">
                There are many ways in which funding can be raised:
              </h6>
              <ul
                class="text-dark text-justify"
                style="list-style-type: none; line-height: 3rem; font-size:0.9rem;"
              >
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Crowd Funding
                </li>
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Angel Investors
                </li>
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Venture Capitalists
                </li>
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Incubators and Accelerators
                </li>
                <li>
                  <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                  Institutional Finance
                </li>
              </ul>
            </div>

            <p class="text-dark text-justify">
              In order that a sound idea generate revenue, it needs to be backed
              by sufficient capital to help it survive and grow. Many
              enterprises fail and the common reason turns out to be lack of
              sufficient funds.
            </p>

            <h6 class="mt-3 mb-3">Debt syndication:</h6>
            <p class="text-dark text-justify">
              BFAG has strong and long-standing relationships with financial
              institutions, banks and NBFCs, mutual funds and insurance
              companies both in India and globally.
            </p>
            <ul
              class="text-dark"
              style="list-style-type: none; line-height: 3rem; font-size: 0.9rem;"
            >
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Our experienced professionals, advise and serve clients in the
                debt syndication and structured corporate finance space. We have
                been instrumental in leading debt syndication programs across
                industries like manufacturing, infrastructure, real estate,
                hospitality and healthcare.
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                We have been assisting corporates for their requirement of
                working capital, offshore finance, acquisition finance and
                cross-border projects funding. Our expertise spans raising debt
                financing both in domestic and international markets, with
                cross-border experience.
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Evaluating better sources of funds and exploring refinancing
                options
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Preparation of DPR (Detailed Project Report)
              </li>
            </ul>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-mergersAndAcquisitions"
          role="tabpanel"
          aria-labelledby="v-pills-mergersAndAcquisitions-tab"
        >
          <div class="col">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <h4 style="border-bottom: 3px solid #21ac51; display: inline-block;" class=" mb-4">Mergers and Acquisitions</h4>
              <div class="pt-2 pb-2">
                <img
                  class="img-fluid"
                  src="../../../../assets/images/audit And Assurance/Rectangle 128.png"
                  alt=""
                />
              </div>
              <p class="text-dark text-justify pt-2">
                We realize every M&A is unique. Knowing this, we conduct financial
                and tax due diligence, evaluating quality of earnings, working
                capital and other purchase adjustments analysis, understanding
                liquidity and cash flow management, managing tax structure. A
                successful M&A results in significant presence in the market,
                value generation, increased market share, access to new talent to
                name a few.
              </p>
              <p class="text-dark text-justify" >
                Mergers and acquisitions is the most happening thing in the
                startup segment as well. There are many investments happening in
                the startup segment and this has given rise to a spate of mergers
                and acquisitions. They are usually done with a view to achieve;
              </p>
            </div>



            <ul
              class="text-dark text-justify"
              style="list-style-type: none; line-height: 3rem; font-size: 0.9rem;"
            >
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Operational Economics
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Eliminating Competition
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Value creation
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Greater Synergy
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Growth
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Diversification
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Tax Shield benefits
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Effective Financial planning
              </li>
            </ul>

            <p class="text-dark">
              However, many mergers and acquisitions fail to bear fruit due to
              inadequate planning, incorrect valuations, or inappropriate
              post-merger integration. BFAG has decades of experience and
              expertise to offer guidance and support at each stage of planning,
              execution, and consolidation of mergers and acquisitions.
            </p>

            <p class="text-dark" >
              The success of an M & A also depends on successful integration of
              the Accounting & finance function in the merged entity.
              Integration from accounting aspect requires merging of financial
              within the existing regulatory framework and with a view to
              protecting shareholders wealth. In this regard our services
              include:
            </p>
            <ul
              class="text-dark"
              style="list-style-type: none; line-height: 3rem; font-size: 0.9rem;"
            >
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Consolidation of accounts with different year endings and
                accounting policies
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Consolidation and restructuring of monthly, quarterly, yearly
                reporting processes
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Finance function restructuring
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Returning the internal controls
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Financial Reporting (IFRS and GAAP)
              </li>
            </ul>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-strategicCFOServices"
          role="tabpanel"
          aria-labelledby="v-pills-strategicCFOServices-tab"
        >
          <div class="col">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <h4 style="border-bottom: 3px solid #21ac51; display: inline-block;" class=" mb-4">Strategic CFO Services</h4>
              <div class="pt-2 pb-2">
                <img
                  class="img-fluid"
                  src="../../../../assets/images/audit And Assurance/Rectangle 129.png"
                  alt=""
                />
              </div>
              <p class="text-dark text-justify pt-2">
                A Strategic CFO should be able to analyze the business properly,
                ask the right questions to understand where the company stands
                currently and address needs such as process development, process
                optimization, internal controls, financial restructuring and fund
                raising. An efficient CFO will collaborate with financial leaders,
                charting the future course of the company in terms of growth, both
                organically and inorganically, operating plan & performance
                management, board reporting and more.
              </p>
            </div>

            <h6 class="mt-3 mb-3">Website:</h6>
            <p class="text-dark text-justify">
              CFOs today play a vital role in any company and the role of a CFO
              and reach now extends far beyond the traditional role of reporting
              finances. Our services in this regard are fully customized to
              ensure priority organizational needs such as:
            </p>

            <ul
              class="text-dark"
              style="list-style-type: none; line-height: 3rem; font-size: 0.9rem;"
            >
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Process development and process optimization to maximize
                resource utilization to continually improve organization’s
                efficiency
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Strategic Planning, Profitability Improvement, Cash Flow
                Management & Forecasting
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Forging proactive and value-added partnerships that support
                decision-making throughout the company
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Operating Plan & Performance Management, Board reporting
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Charting the future course of the company in terms of growth,
                both organically and inorganically
              </li>
              <li> <i class="fas fa-hand-point-right" style="color: #21ac51"></i> Fund raise</li>
            </ul>

            <h6 class="text-dark text-justify mt-3 mb-3">
              The senior leadership team at BFAG has developed a unique set of
              services addressing these growing demands in the form of
              strategies blended with technology that enable CFOs to:
            </h6>

            <ul
              class="text-dark"
              style="list-style-type: none; line-height: 3rem; font-size: 0.9rem;"
            >
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Improve overall corporate performance by improving core finance
                process
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Gain access to real-time management information to aid decision
                making at the click of a button
              </li>
              <li>
                <i class="fas fa-hand-point-right" style="color: #21ac51"></i>
                Reduce the total cost of the finance function by increasing the
                time spent on analysis as against transaction processing
              </li>
            </ul>
            <p class="text-dark text-justify">
              Our solution includes Virtual CFO or part time CFO services which
              prove to be effective as well as cost cautious in the long run.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
