<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="background: url('../../../../assets/images/service/Accounting.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Accounting & Transaction Processing
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">


            <div >
              <div >
                <div class="row">
                <div class="col-md-4">
                  <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample1">
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev" >
                      <a  href="#accounting-and-transition-processing" data-toggle="collapse" data-target="#collapseSevv"
                      aria-expanded="true" aria-controls="collapseSevv" >
                        Accounting & Transaction Processing
                      </a>
                    </div>
                    <div id="collapseSevv" class="collapse show" aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div class="list-group">
                          <ul class="text-dark serviceul">

                            <li  routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Receivable
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Fixed Assets Accounting
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Budgeting & Variance Analysis
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                               Consolidation Reporting & GAAP Conversion
                            </li>
                            <li class="shli" routerLink="/small-business-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Small Business Accounting
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span (click)="onTax()">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse " aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li  routerLink="/individual-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Individual Tax Return
                            </li>
                            <li  routerLink="/corporate-tax-return">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Tax Return
                            </li>
                            <li routerLink="/sales-and-use-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Sales & Use Tax
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span (click)="onPayroll()">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Payroll Processing
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span (click)="onConsulting()">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Setting up an entity in the US
                            </li>
                            <li routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Global Corporate Structuring
                            </li>
                            <li routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Business Plan Preparation
                            </li>
                            <li routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             <a href="#business-valuation-service"> Business Valuation</a>
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                             Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div style="margin-top: 1rem">
                    <h3 id="small-business-accounting">Small Business Accounting</h3>
                    <!--<p class="mb-15 mt-20 text-dark servicep">
                      Streamline Your Small Business Accounting with Expert Financial Advisory Services
                    </p>-->
                    <p class="mb-15 mt-20 text-dark servicep">
                      Accurate and efficient accounting is essential for the success of small businesses. However, managing finances, bookkeeping, and complying with tax regulations can be overwhelming, especially for busy entrepreneurs focused on growing their businesses. At BFAG we offer specialized Small Business Accounting Services designed to alleviate the burden of accounting tasks and provide you with the financial insights needed to make informed decisions. Our expert team can streamline your small business accounting processes, ensuring accuracy, compliance, and financial control.
                    </p>
                  </div>
                  <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
                    <div class="container">
                        <div class="row" style="display: block;">
                            <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                                <div class="accordion" id="accordionExample2">
                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                            <div class="card-header" id="headingEleven">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                  Professional Bookkeeping Services
                                                </a>
                                            </div>
                                            <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Our experienced professionals handle the day-to-day bookkeeping tasks, allowing you to focus on running your business. We accurately record your financial transactions, reconcile accounts, and maintain organized financial records. By leveraging our expertise, you can save time, reduce errors, and have a clear overview of your business's financial health.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                            <div class="card-header" id="headingTwelve">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                  Financial Statement Preparation
                                                </a>
                                            </div>
                                            <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Preparing accurate financial statements is crucial for assessing the financial performance of your small business. Our services include the preparation of financial statements, including balance sheets, income statements, and cash flow statements. We ensure that your financial statements comply with accounting standards, enabling you to gain valuable insights into your business's profitability, liquidity, and solvency.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- card -->
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingThirteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                  Tax Planning and Compliance
                                                </a>
                                            </div>
                                            <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Navigating the complexities of tax regulations can be challenging for small businesses. Our team provides comprehensive tax planning and compliance services, helping you optimize your tax strategy and ensure compliance with local tax laws. We stay up-to-date with tax regulations and assist you in maximizing deductions, managing tax liabilities, and filing accurate tax returns on time.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- card -->
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                            <div class="card-header" id="headingFourteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                  Financial Analysis and Reporting
                                                </a>
                                            </div>
                                            <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>Understanding your financial performance is key to making informed business decisions. Our small business accounting services provide you with comprehensive financial analysis and reporting. We help you analyze key financial ratios, identify trends, and assess the profitability of your business. By gaining valuable insights, you can make data-driven decisions and strategize for growth.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                            <div class="card-header" id="headingFifteen">
                                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                  Expert Financial Advice
                                                </a>
                                            </div>
                                            <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                                <div class="card-body text-justify" style="padding-left:20px !important">
                                                    <p>As a small business owner, having access to expert financial advice is invaluable. BFAG provides you with personalized guidance and support. We help you interpret financial data, set realistic financial goals, and develop strategies to optimize your business's financial performance. Our team serves as your trusted advisor, offering insights and recommendations tailored to your unique business needs.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- faq accordion -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <p class="mb-15 mt-20 text-dark servicep">
                  Streamlining your small business accounting is essential for financial control, compliance, and informed decision-making. BFAG offers specialized Small Business Accounting Services tailored to the needs of small businesses. By leveraging our expertise, you can save time, ensure accuracy, and gain valuable financial insights. Contact us today to experience the benefits of our professional services and take your small business accounting to the next level.
                </p>
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->

      </div>

    </div>

  </div>
</section>
