
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Term of Use</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Term of Use</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Term of Use PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Terms of website</span>
                    <h2 class="title pb-15">Term of Use</h2>
                    <p>The “Site” is owned and operated by Frontline Information Technology LLC. By accessing this site you indicate your acknowledgment and acceptance of our privacy policy and the terms of use. You may not access or use the web site and
                        you must terminate your use immediately if you are not agreeing to be bound by and comply with all terms of use.</p>
                    <p>All content present on this site including the site design, logo, text, images, graphics, videos and audios are exclusive property of Frontline IT. No content of this site may be reproduced, stored in a database or retrieval system
                        or adapted, modified or published in any form or by any means graphic, electronic, web or otherwise without prior written permission from Frontline IT.</p>
                    <p>All rights, not specifically granted herein are reserved. Any unauthorized use of the resources appear on this site may violate copyright, trademark and other applicable laws, and could result in criminal or civil penalties. Frontline
                        Information Technology and Horizon – EBS is a registered trademark. This trademark may not be used in any manner without prior written consent from Frontline Information Technology LLC.</p>
                    <p>Your use of the site shall be deemed to be your agreement to abide by each of the terms set forth below, including any modifications that we may make. We may modify these terms at any time and from time to time without notice to you
                        by posting revised terms herein.</p>
                    <p>User agrees to indemnify, defend and hold Frontline IT harmless from and against all losses, expenses, damages and costs, including reasonable attorneys’ fees, arising out of or relating to any misuse by the User of the content and
                        services provided on the Site.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Term of Use PART START ======-->
<!--====== Accuracy of Information PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="experience-item">
                    <!-- <span>Highlights</span> -->
                    <!-- <h2 class="title pb-15">Terms of website</h2> -->
                </div>
                <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a class="w-100" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Accuracy of Information
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">Frontline IT uses reasonable efforts to ensure the accuracy of the contents in the site. All the information contained in the Site has been obtained from sources believed to be reliable; however the sources may occasionally
                                        contain contents, specifications or information that are out of date, inaccurate or incomplete. Frontline IT accepts no liability on the subject of the accuracy of the information contained on this site and use
                                        of the information is at user’s own risk. Frontline IT may change or discontinue any aspect of its website at any time, including, its content or features. We are not responsible for any damage, liability, or argument
                                        resulting from your use, reliance or reaction on any of the information or contents available in the site.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Limitation Of Liability
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">In no event shall Frontline IT be liable for any direct, indirect, special, consequential, exemplary or punitive damages arising out of user access or use of this web site, including, without limitation, any lost profits,
                                        business interruption, loss of data or programs or otherwise, even if expressly advised of the possibility of such damages. User should not send any proprietary or confidential information to Frontline IT through
                                        this web site. Any information sent to Frontline IT shall be deemed not to be confidential. User hereby grants Frontline IT an unrestricted license to use, reproduce, disclose, modify, transmit publish, broadcast
                                        and post the information received from user, subject however to the Frontline IT confidentiality policy referred to on this web site. Furthermore, Frontline IT shall be free to use any ideas, concepts, know-how
                                        or techniques user sent to the website for any purpose whatsoever. If you have any questions regarding these Terms Of Use, please contact Frontline IT at <a class="color-red">info@fit.ae</a></p>

                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Copyright
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">Every aspects of this website including the graphics, contents, images, videos, underlying source code and all other aspects are owned and retained by Frontline IT or its affiliates or technology providers. By accessing
                                        this site, you agree that any download of content is for your personal, non-commercial use only. No one is allowed to reproduce or transmit the contents of this site in any form or by any means, electronic, mechanical,
                                        recording or otherwise, without prior written permission from Frontline IT. The contents of this website are sole property of Frontline IT and their owners. There for all materials posted on this site including
                                        audio or video clips, newsletters, articles and any other material despite of the form or format, is and shall remain the copy right of Frontline IT. Pictures, graphics or some other contents of similar kind in
                                        this website are either owned by Frontline IT or their suppliers and are protected by copy right laws throughout the world. Subject to the Terms of Use, Frontline IT hereby grants you a limited, revocable, non-exclusive
                                        license to view, copy and print the contents of this site for your personal, non-commercial purposes without altering or modifying them in anyway. You may also download a copy of the materials found in this site
                                        to any single computer for your informational, personal non-commercial use, ensuring that all the copyright information are kept unharmed. No other use of the contents in this site is authorized. The provision of
                                        information by Frontline IT shall not be construed as granting user any licenses to any copyright or any other intellectual property rights of Frontline IT or any third party. All the information contained in the
                                        Site has been obtained from sources believed to be reliable; however the sources may occasionally contain contents, specifications or information that are out of date, inaccurate or incomplete. Frontline IT offers
                                        no guarantee that all the information in this site is up to date and accurate. Except as specified above, nothing contained herein shall be construed as conferring by implication, estoppels, or otherwise any license
                                        or right under any patent, trademark or copyright of Frontline IT or any third party. Frontline IT retains all rights, title and interest in and to the Site Content not granted in these Terms of Use.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <i class="fal fa-magic"></i>Links To Third-Party Sites
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">The third party website links are provided in this site as a convenience to you and do not involve an endorsement by Frontline IT of any such linked sites. Frontline IT assumes no responsibility or assurance for materials
                                        and information found on the sites which are functioned or managed outside of its domain. The above term can be revised or modified by Frontline IT at any moment without prior notice.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <i class="fal fa-magic"></i>Data Protection and Loss
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">Any personal data submitted via this site, whether by email or other means, shall be used by Frontline IT only and will not be revealed to any third parties. Frontline IT reserves the right to use your personal data
                                        for providing you a more personalized online experience. Please contact us if you wish to view, remove or amend your personal data. Frontline IT assumes no responsibility for any damage to the user’s computers or
                                        any data loss caused by downloading any content, material or information from this site.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Accuracy of Information PART END ======-->

<!--====== You agree not to PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <!-- <span>Highlights</span> -->
                    <h2 class="title pb-15">You agree not to</h2>
                    <p class="add-arrow"> Interfere with or interrupt the Site or servers or networks connected to the Site, or disobey any requirements, procedures, policies, or regulations of networks connected to the Site
                    </p>
                    <p class="add-arrow"> Replicate, duplicate, sell, resell, or exploit any content of this site for any commercial purpose.</p>
                    <p class="add-arrow"> Hack, crack, or otherwise attempt to outwit any copy protection, access control, or license-enforcement mechanisms associated with or related to the Site or Site Content.</p>

                    <!-- <span class="mt-30">Highlights</span> -->
                    <h2 class="title pb-15">Without our prior written
                        <br/> permission, you may not</h2>

                    <p class="add-arrow"> Allow, enable, or otherwise support the transmission of unwelcomed commercial advertising or solicitations via e-mail (spam);
                    </p>
                    <p class="add-arrow"> Use any high volume, automated, or electronic means (including without limitation robots, spiders, scripts, or other automatic device) to access the Site or monitor or copy our web pages or the Site Content; deep-link to the Site for
                        any purpose; or Frontline IT</p>
                    <p class="add-arrow"> Frame the Site, place pop-up windows over its pages, or otherwise affect the display of its pages.</p>

                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== You agree not to PART END ======-->

<!--====== ABOUT EXPERIENCE PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <!-- <span>Highlights</span> -->
                    <!-- <h2 class="title pb-15">Terms of website</h2> -->
                </div>
            </div>
            <div class="col-md-12">
                <div class="faq-accordion-3 pt-0">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingSix">
                                <a class="w-100" href="" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                    <i class="fal fa-magic"></i>Browsing
                                </a>
                            </div>
                            <div id="collapseSix" class="collapse show" aria-labelledby="headingSix" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">By Posting, uploading, e-mailing, publishing or transmitting any content to Frontline IT, you are automatically granting Frontline IT a royalty-free, permanent, irreversible right and license to use, modify, adapt,
                                        publish and distribute that document in any form, medium or technology now available or later developed. In addition, you warrant (or warrant on behalf of the owner of such rights) that all moral rights in the content
                                        have been waived. By indicating you want to be placed on the Frontline IT mailing list, you accept to receive emails or newsletters from us about our products, services, and other relevant news. These Terms of Use
                                        will apply to all such emails, and such emails will be considered part of the Site Content.</p>
                                </div>
                            </div>
                        </div>
                        <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingSeven">
                                <a class="collapsed w-100" href="" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    <i class="fal fa-magic"></i>Disclaimer
                                </a>
                            </div>
                            <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="pb-15">The Site, and all content, materials, information, software, products, press releases , news flashes and services provided on the Site, are provided on an “as is” and “as available” basis. Frontline IT expressly disclaims
                                        all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement.</p>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- faq accordion -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>

<!--======Frontline IT makes PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>The above term can be revised or modified by Frontline IT at any moment without prior
                        notice</span>
                    <h2 class="title pb-15">Frontline IT makes no
                        <br/> warranty that</h2>
                    <p class="add-arrow"> The Site will meet your requirements
                    </p>
                    <p class="add-arrow"> The Site will be available on an uninterrupted, timely, secure, or error-free basis</p>
                    <p class="add-arrow"> The results that may be obtained from the use of the Site or any services offered through the Site will be accurate or reliable.
                    </p>
                    <p>Frontline IT cannot be held liable under any circumstance for any damages whatsoever, including without limitation, damages for loss of profit, business interruption, loss of information and data, arising out of the use of or inability
                        to use this Web site and the information it contains, even if Frontline IT has been made aware of the possibility of such damages.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--======Frontline IT makes PART END ======-->
