<!-- USA Started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
        <div class="" style="padding: 1%">
          <img
            style="border-radius: 10px; width: 100%"
            class="img-fluid"
            src="../../../../../assets/images/teams/sameer cercile.jpeg"
            alt="SAMEER PADDALWAR"
          />
        </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start"
        >
        <h3
        class="text-uppercase text-dark title"
        style="font-size: 20px; font-weight: 700;"
      >
      SAMEER PADDALWAR
      </h3>
      <div>
        <span
        class="text-uppercase text-dark"
        style="font-size: 16px; font-weight: 600"
      >
      Chief Executive Officer
      </span>
      <a
      style="color: #fff"
      href="https://www.linkedin.com/in/sameerpaddalwar/"
      target="_blank"
    >
      <i
        class="fab fa-linkedin-in"
        style="
          margin-left: 10px;
          background-color: #0072b1;
          padding: 4px;
          border-radius: 5px;
        "
      ></i
    >
  </a>
    </div>
      <p
        class="mt-20 text-dark text-justify"
        style="font-size: 16px !important"
      >
      Sameer is a highly accomplished leader renowned for his exceptional
      track record in driving organizational success and fostering growth.
      With a steadfast focus on leadership capabilities and a wealth of
      experience in team building, he has played an instrumental role as
      the driving force and promoter behind the thriving Boston Financial
      Advisory group.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
      Sameer's academic achievements and extensive industry expertise have
      positioned him as a trusted and relied-upon figure within the firm.
      Holding certifications as a Certified Public Accountant (CPA),
      Certified Management Accountant (CMA), and Certified Financial
      Manager (CFM), he exemplifies his profound proficiency in financial
      management and compliance. With an impressive three decades of
      industry experience, Sameer's strategic vision and unwavering
      commitment to excellence have propelled the group to unprecedented
      levels of revenue growth and performance improvement for over a
      decade.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
      His profound understanding of compliance and regulatory requirements
      led to the creation of the ground-breaking solution known as
      Compliance in Box. Under Sameer's guidance, Compliance in Box and
      the CFO dashboards have had a transformative impact on operations, elevating
      the role of public accounting and financial management within
      organizations while streamlining processes.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
      His dedication to
      excellence, coupled with a relentless pursuit of technological
      advancements, has enabled him to deliver innovative products that
      revolutionize the industry.
    </p>
        </div>
      </div>
      <div class="col-lg-12 pl-0 pr-0">

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        His expertise lies in global corporate structuring, strategic partner
          and investor relationship management, M&A synergies, post-acquisition
          business integration management, financial re-engineering, systems
          transformation, and reducing product portfolio complexity.
        </p>
        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        As a visionary and dynamic leader with extensive experience in
        leadership, team building, and technology advancement, Sameer has a
        wealth of expertise in all these areas. He consistently inspires and
        motivates his teams to reach their full potential and fosters a
        collaborative and inclusive work environment, encouraging innovation
        and embracing technological advancements to drive business success.
        His exceptional ability to build and nurture high-performing teams has
        been instrumental in the group's continued growth and triumph.
        Sameer’s profound knowledge of compliance and financial management,
        combined with his strategic acumen, has left an indelible mark on the
        Boston Financial Advisory Group and the industry.
        </p>

      </div>
    </div>
  </div>
</div>
<!-- USA End -->


<!-- India started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
        <div class="" style="padding: 1%">
          <img
            style="border-radius: 10px; width: 100%"
            class="img-fluid"
            src="../../../../../assets/images/teams/sameer cercile.jpeg"
            alt="SAMEER PADDALWAR"
          />
        </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start"
        >
        <h3
        class="text-uppercase text-dark title"
        style="font-size: 20px; font-weight: 700;"
      >
      SAMEER PADDALWAR
      </h3>
      <div>
        <span
        class="text-uppercase text-dark"
        style="font-size: 16px; font-weight: 600"
      >
      Chief Executive Officer
      </span>
      <a
      style="color: #fff"
      href="https://www.linkedin.com/in/sameerpaddalwar/"
      target="_blank"
    >
      <i
        class="fab fa-linkedin-in"
        style="
          margin-left: 10px;
          background-color: #0072b1;
          padding: 4px;
          border-radius: 5px;
        "
      ></i
    >
  </a>
    </div>
      <p
        class="mt-20 text-dark text-justify"
        style="font-size: 16px !important"
      >
      Sameer is a highly accomplished leader renowned for his exceptional
      track record in driving organizational success and fostering growth.
      With a steadfast focus on leadership capabilities and a wealth of
      experience in team building, he has played an instrumental role as
      the driving force and promoter behind the thriving Boston Financial
      Advisory group.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
      Sameer's academic achievements and extensive industry expertise have
      positioned him as a trusted and relied-upon figure within the firm.
      Holding certifications as a Certified Public Accountant (CPA),
      Certified Management Accountant (CMA), and Certified Financial
      Manager (CFM), he exemplifies his profound proficiency in financial
      management and compliance. With an impressive three decades of
      industry experience, Sameer's strategic vision and unwavering
      commitment to excellence have propelled the group to unprecedented
      levels of revenue growth and performance improvement for over a
      decade.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
      His profound understanding of compliance and regulatory requirements
      led to the creation of the ground-breaking solution known as
      Compliance in Box. Under Sameer's guidance, Compliance in Box and
      the CFO dashboards have had a transformative impact on operations, elevating
      the role of public accounting and financial management within
      organizations while streamlining processes.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
      His dedication to
      excellence, coupled with a relentless pursuit of technological
      advancements, has enabled him to deliver innovative products that
      revolutionize the industry.
    </p>
        </div>
      </div>
      <div class="col-lg-12 pl-0 pr-0">
        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        His expertise lies in global corporate structuring, strategic partner
          and investor relationship management, M&A synergies, post-acquisition
          business integration management, financial re-engineering, systems
          transformation, and reducing product portfolio complexity.
        </p>
        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        As a visionary and dynamic leader with extensive experience in
        leadership, team building, and technology advancement, Sameer has a
        wealth of expertise in all these areas. He consistently inspires and
        motivates his teams to reach their full potential and fosters a
        collaborative and inclusive work environment, encouraging innovation
        and embracing technological advancements to drive business success.
        His exceptional ability to build and nurture high-performing teams has
        been instrumental in the group's continued growth and triumph.
        Sameer’s profound knowledge of compliance and financial management,
        combined with his strategic acumen, has left an indelible mark on the
        Boston Financial Advisory Group and the industry.
        </p>

      </div>
    </div>
  </div>
</div>
<!-- India ends -->


<!-- Dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
        <div class="" style="padding: 1%">
          <img
            style="border-radius: 10px; width: 100%"
            class="img-fluid"
            src="../../../../../assets/images/teams/sameer cercile.jpeg"
            alt="SAMEER PADDALWAR"
          />
        </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start"
        >
        <h3
        class="text-uppercase text-dark title"
        style="font-size: 20px; font-weight: 700;"
      >
      SAMEER PADDALWAR
      </h3>
      <div>
        <span
        class="text-uppercase text-dark"
        style="font-size: 16px; font-weight: 600"
      >
      Chief Executive Officer
      </span>
      <a
      style="color: #fff"
      href="https://www.linkedin.com/in/sameerpaddalwar/"
      target="_blank"
    >
      <i
        class="fab fa-linkedin-in"
        style="
          margin-left: 10px;
          background-color: #0072b1;
          padding: 4px;
          border-radius: 5px;
        "
      ></i
    >
  </a>
    </div>
      <p
        class="mt-20 text-dark text-justify"
        style="font-size: 16px !important"
      >
      Sameer is a highly accomplished leader renowned for his exceptional
      track record in driving organizational success and fostering growth.
      With a steadfast focus on leadership capabilities and a wealth of
      experience in team building, he has played an instrumental role as
      the driving force and promoter behind the thriving Boston Financial
      Advisory group.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
      Sameer's academic achievements and extensive industry expertise have
      positioned him as a trusted and relied-upon figure within the firm.
      Holding certifications as a Certified Public Accountant (CPA),
      Certified Management Accountant (CMA), and Certified Financial
      Manager (CFM), he exemplifies his profound proficiency in financial
      management and compliance. With an impressive three decades of
      industry experience, Sameer's strategic vision and unwavering
      commitment to excellence have propelled the group to unprecedented
      levels of revenue growth and performance improvement for over a
      decade.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
      His profound understanding of compliance and regulatory requirements
      led to the creation of the ground-breaking solution known as
      Compliance in Box. Under Sameer's guidance, Compliance in Box and
      the CFO dashboards have had a transformative impact on operations, elevating
      the role of public accounting and financial management within
      organizations while streamlining processes.
      </p>

      <p
        class="mt-10 text-dark text-justify"
        style="font-size: 16px !important"
      >
      His dedication to
      excellence, coupled with a relentless pursuit of technological
      advancements, has enabled him to deliver innovative products that
      revolutionize the industry.
    </p>
        </div>
      </div>
      <div class="col-lg-12 pl-0 pr-0">

        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        His expertise lies in global corporate structuring, strategic partner
          and investor relationship management, M&A synergies, post-acquisition
          business integration management, financial re-engineering, systems
          transformation, and reducing product portfolio complexity.
        </p>
        <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
        >
        As a visionary and dynamic leader with extensive experience in
        leadership, team building, and technology advancement, Sameer has a
        wealth of expertise in all these areas. He consistently inspires and
        motivates his teams to reach their full potential and fosters a
        collaborative and inclusive work environment, encouraging innovation
        and embracing technological advancements to drive business success.
        His exceptional ability to build and nurture high-performing teams has
        been instrumental in the group's continued growth and triumph.
        Sameer’s profound knowledge of compliance and financial management,
        combined with his strategic acumen, has left an indelible mark on the
        Boston Financial Advisory Group and the industry.
        </p>

      </div>
    </div>
  </div>
</div>
<!-- Dubai ends -->
