import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-on-fema',
  templateUrl: './faq-on-fema.component.html',
  styleUrls: ['./faq-on-fema.component.css']
})
export class FaqOnFemaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
