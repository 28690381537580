import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ContactModalComponent } from '../../dialog/contact-modal/contact-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TestimonialService } from 'src/app/services/testimonial.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ContactFormService } from 'src/app/services/contact-form.service';
import { CaseStudiesService } from 'src/app/services/case-studies.service';
import { BlogsNewsService } from 'src/app/services/blogs-news.service';
import { Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import { CustomCaptcha } from 'src/app/services/custom-captcha';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';

declare let $: any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
})
export class ServicesComponent implements OnInit {

  image;
  testimonialRes;
  result;
  selectedIndex;
  inqForm: FormGroup;
  subjectData;
  resultFalse = false;
  resultTrue = false;
  caseStudiesResult;
   caseStudiesRes;
  blogList: any;
  ckEditorRes: any;
  resultOfTestimonial;
  submitted = false;
  _subs;
  validCaptcha = false;
  defaultCountry: string;
  selectCountryName: string;
  selectedFlag: string;
  countryCode: any;
  constructor(
    private metaTagService: Meta,
    public dialog: MatDialog,
    private _testimonialService:TestimonialService,
    private _contactFormService:ContactFormService,
    private formBuilder: FormBuilder,
    private _caseStudiesService:CaseStudiesService,
    private _blogsNewsService:BlogsNewsService,
    private router:Router,
    private _activatedRoute:ActivatedRoute,
    private _customCaptcha:CustomCaptcha,

    private LocalStorageServiceService: LocalStorageServiceService,
  ) {}



  ngOnInit(): void {
    

    this.defaultCountry = 'USA';

    this.selectCountryName = localStorage.getItem('selectedCountry');

    if (this.selectCountryName == null) {
      localStorage.setItem('selectedCountry', this.defaultCountry);
      window.location.reload();
    } else {
      this.selectCountryName = localStorage.getItem('selectedCountry');
    }
    if (this.selectCountryName === 'Dubai') {
      this.countryCode = '+971 ';
    } else if (this.selectCountryName === 'India') {
      this.countryCode = '+91 ';
    } else if (this.selectCountryName === 'USA') {
      this.countryCode = '+1 ';
    } else {
      this.countryCode = '+91 ';
    }
    console.log('THIS IS COUNTRY CODE');
    console.log(this.countryCode);

    this.inqForm = this.formBuilder.group({
      name: ['', Validators.required],
      email_id: ['', [Validators.required, Validators.email]],
      mobile_number: [`${this.countryCode}`, [Validators.required, Validators.pattern('\\d*')]],
      subject: [''],
      message: ['', Validators.required],
      recaptchaReactive: ['']
    });
  }

  activateTab(tabId: string) {
    const tabElement = document.getElementById(tabId);
    if (tabElement) {
      tabElement.classList.add('show', 'active');
    }
  }

  get f() {
    return this.inqForm.controls;
  }

  setIndex(index: number) {
    this.selectedIndex = index;
}


  ngAfterViewInit(): void {
    this._customCaptcha.createCaptcha();
    this.caseStudies();
    //testimonal script
    var testimTimer,touchStartPos,touchEndPos,touchPosDiff,testim=document.getElementById("testim1"),testimDots=Array.prototype.slice.call(document.getElementById("testim-dots1").children),testimContent=Array.prototype.slice.call(document.getElementById("testim-content1").children),testimLeftArrow=document.getElementById("left-arrow"),testimRightArrow=document.getElementById("right-arrow"),testimSpeed=4500,currentSlide=0,currentActive=0,ignoreTouch=30;function i(t){for(var e=0;e<testimDots.length;e++)testimContent[e].classList.remove("active"),testimContent[e].classList.remove("inactive"),testimDots[e].classList.remove("active");t<0&&(t=currentSlide=testimContent.length-1),t>testimContent.length-1&&(t=currentSlide=0),currentActive!=currentSlide&&testimContent[currentActive].classList.add("inactive"),testimContent[t].classList.add("active"),testimDots[t].classList.add("active"),currentActive=currentSlide,clearTimeout(testimTimer),testimTimer=setTimeout(function(){i(currentSlide+=1)},testimSpeed)}testimLeftArrow.addEventListener("click",function(){i(--currentSlide)}),testimRightArrow.addEventListener("click",function(){i(currentSlide+=1)});for(var t=0;t<testimDots.length;t++)testimDots[t].addEventListener("click",function(){i(currentSlide=testimDots.indexOf(this))});i(currentSlide),document.addEventListener("keyup",function(t){switch(t.keyCode){case 37:testimLeftArrow.click();break;case 39:case 39:testimRightArrow.click()}}),testim.addEventListener("touchstart",function(t){touchStartPos=t.changedTouches[0].clientX}),testim.addEventListener("touchend",function(t){if(touchEndPos=t.changedTouches[0].clientX,touchPosDiff=touchStartPos-touchEndPos,console.log(touchPosDiff),console.log(touchStartPos),console.log(touchEndPos),0+ignoreTouch<touchPosDiff)testimLeftArrow.click();else{if(!(touchPosDiff<0-ignoreTouch))return;testimRightArrow.click()}});
   }

  caseStudies(){
    this._caseStudiesService.caseStudies().subscribe(res=>{
      this.caseStudiesRes = res;
      this.caseStudiesResult = this.caseStudiesRes.data;
    })
  }

  testimonials(){
    this._testimonialService.testimonialData().subscribe(res=>{
      this.testimonialRes = res;
      this.resultOfTestimonial =  this.testimonialRes.data;
    })
  }

  openDialog() {
    const dialogRef = this.dialog.open(ContactModalComponent, {
      width: '555px',
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  caseDetails(id){
    this.router.navigate(['/case-detail', id],  { relativeTo: this._activatedRoute})
  }


  getBlogList(){
    this._blogsNewsService.blogList().subscribe(res=>{
      this.result  = res;
      this.blogList = this.result.data;
    })
  }

  blogDetails(id){
    this.router.navigate(['/news-and-blogs/details', id],  { relativeTo: this._activatedRoute})
  }

  reCaptcha(){
    this._customCaptcha.createCaptcha();
  }

  contactForm(){
    this.inqForm = this.formBuilder.group({
      name: ['',  Validators.required],
      email_id: ['',  Validators.compose([
        Validators.required,
        Validators.email
      ])],
      mobile_number: [`${this.countryCode}`, Validators.compose([Validators.required,
        Validators.pattern('^[(+0-9) 0-9 -]+$')])],
      subject: [''],
      message: ['', Validators.required],
      recaptchaReactive: ['', Validators.required]
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.inqForm.invalid){
      return;
    }
    else if (this.inqForm.value.recaptchaReactive !=  this._customCaptcha.validateCaptcha()){
      alert("Invalid Captcha. try Again");
      return
    }
    else {
    const formData = new FormData();
    formData.append('name', this.inqForm.value.name);
    formData.append('email_id', this.inqForm.value.email_id);
    formData.append('mobile_number', this.inqForm.value.mobile_number);
    formData.append('subject', this.inqForm.value.subject);
    formData.append('message', this.inqForm.value.message);

    this._contactFormService.contactform(formData).subscribe(res => {
        let response = res;
        if(response['result'] == 'F'){
          this.resultFalse = true;
          this.resultTrue = false;
        }else{
          this.resultFalse = false;
          this.resultTrue = true;
          this.inqForm.reset();
          this._customCaptcha.createCaptcha();
          this.submitted = false;
        }
      })
    }

  }

  captchaResolved(captchaResponse: string) {
    if (!captchaResponse) {
      this.validCaptcha = false;
    } else {
      this.validCaptcha = true;
    }
    this.inqForm.patchValue({
      recaptchaReactive: this.validCaptcha
    });
  }

  trackByFn(index, item) {
    return index; // or item.id
  }


  selectCountry(country: string): void {
    // Update the selectedFlag with the corresponding image path
    if (country === 'Dubai') {
      this.selectedFlag =
        '/assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png';
      this.countryCode = '+971';
    } else if (country === 'India') {
      this.selectedFlag = '/assets/images/flag/download.png';
      this.countryCode = '+91';
    } else if (country === 'USA') {
      this.selectedFlag = '/assets/images/flag/USA.png';
      this.countryCode = '+1';
    } else {
      // Set USA as the default country
      this.selectedFlag = '/assets/images/flag/USA.png';
      country = 'USA';
      this.countryCode = '+91';
    }
    console.log('THIS IS COUNTRY CODE');
    console.log(this.countryCode);
    this.LocalStorageServiceService.setItem('selectedCountry', country);
  }
}
