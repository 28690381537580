import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vat-advisory',
  templateUrl: './vat-advisory.component.html',
  styleUrls: ['./vat-advisory.component.css']
})
export class VatAdvisoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
