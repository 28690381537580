import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import APIURL from 'src/app/helper/apiUrl';

@Component({
  selector: 'app-cookies-view',
  templateUrl: './cookies-view.component.html',
  styleUrls: ['./cookies-view.component.css']
})
export class CookiesViewComponent implements OnInit {

  cookies:any[] = [];

  constructor(
    private http:HttpClient,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.getCookies();
  }

  httpOptions = {
    headers: new HttpHeaders({
    'X-Skip-SSL-Validation' : 'true'
    })
  };
  
  getCookies() {
    this.http.get(`${APIURL}/getCookiesDetails`,this.httpOptions).subscribe({
      next: (data:any) => {
        this.cookies = data;
      },
      error: error => {
        this.snackBar.open('Error has been occured','OK',{
          duration: 5000,
        });
      }
    });
  }

}
