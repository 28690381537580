<style>
  .link-img {
  cursor: pointer;
}

</style>

<!--====== footer Dubai START ======-->
<section *ngIf="selectCountryName == 'Dubai'">
  <footer class="footer-area footer-area-2 footer-area-1 bg_cover">
    <div class="footer-overlay" style="background: #050605">
      <div class="position-relative" style="padding-left: 1rem !important; padding-right: 1rem !important;">
        <div class="row" style="padding: 1rem;">
          <div class="col-lg-4 col-md-4" style="padding-left: 3%">
            <div class="widget-item-1 mt-30">
              <img src="../../../../assets/images/BFAG_logo.png" alt="BFAG" style="max-width: 216px" />
              <h5 class="text-white mt-25">
                Sound Finance Solutions for Successful People
              </h5>
              <p >
                Boston Financial Advisory Group (BFAG) is a group of passionate
                and committed professionals from diverse domains such as
                finance, accounting, law, and compliance reporting. We take in
                upon ourselves to manage all your accounting and advisory needs
                so you can concentrate on what you do best – grow your business.
              </p>
     
            </div>
            <!-- widget item 1 -->
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="widget-item-2 mt-30">
              <h4 class="title">Useful Links</h4>
              <div class="footer-list">
                <ul>
                  <li class="cursor-pointer" style="margin-right:0px">
                    <a routerLink="/faq-on-cpa-solution">FAQ on CPA Solution
                    </a>
                  </li>
                  <li class="cursor-pointer" style="margin-right:0px">
                    <a routerLink="/faq-dubai-entity-setup">FAQ Dubai Entity Setup
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- widget item 2 -->
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="widget-item-2 mt-30">
              <h4 class="title">Quick Reference</h4>
              <div class="footer-list">
                <ul>
                  <li style="margin-right:0px"><a routerLink="/why-bfag"> Why BFAG </a></li>
                  <li style="margin-right:0px">
                    <a routerLink="/business-setup"> Business Setup in Dubai</a>
                  </li>
                  <!-- <li><a (click)="selectCountry('India')" > Business Setup in India </a></li>
                                <li><a (click)="selectCountry('Dubai')" > Business Setup in Dubai</a></li> -->
                  <li style="margin-right:0px"><a routerLink="/contact-us">Contact Us</a></li>
                  <li style="margin-right:0px"><a routerLink="/privacy-policy">Privacy Policy</a></li>
                  <li><a routerLink="/trust-center">Trust Center</a></li>
                  <li><a routerLink="/ccpa-page">Do Not Sell My Info</a></li>
                  <img src="assets/images/NewIcon/iso-symbol.png" alt="Image 2" class="custom-img">

                </ul>
              </div>
            </div>
            <!-- widget item 2 -->
          </div>
          <div class="col-lg-4 col-md-4 pr-10">
            <div class="widget-item-2 mt-30">
              <h4 class="title">Contact Info</h4>
              <div class="footer-list">
                <ul>
                  <li style="margin-right:0px">
                    <i class="fal fa-map-marker pr-3"></i> Boston Financial Advisory Group Inc.Unit 2540, 25th
                    Floor,
                    Iris Bay, Business Bay, Dubai, UAE
                  </li>
                  <li style="margin-right:0px">
                    <i class="fal fa-phone pr-3"></i>  +971 4 8714340
                  </li>
                  <li style="margin-right:0px">
                    <i class="fal fa-envelope pr-3"></i> 
                    <a href="">info@bostonfagroup.com</a> 
                    <a href="">sales@bostonfagroup.com</a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- widget item 2 -->
          </div>
        </div>
        <!-- row -->
        <div class="row" style="background: #072f5f">
          <div class="col-lg-12" style="
              border-top: 1px solid rgba(255, 255, 255, 0.2);
              padding-top: 7px;
              padding-bottom: 7px;
            ">
            <div class="col-lg-6" style="float: left">
              <div class="footer-copyright">
                <p>Copyright @ 2023 | All Rights Reserved</p>
              </div>
            </div>
            <div class="col-lg-6" style="float: left">
              <div class="header-right-social text-center text-sm-right">
                <ul>
                  <li style="display: inline-block; padding-right: 2%">
                    <a target="_blank" href="https://www.facebook.com/BostonFinancialAdvisoryGroup/"><i
                        class="fab fa-facebook-f" style="color: #fff"></i>
                    </a>
                  </li>
                  <li style="display: inline-block; padding-right: 2%">
                    <a target="_blank" href="https://twitter.com/BostonfaGroup"><i class="fab fa-twitter"
                        style="color: #fff"></i>
                    </a>
                  </li>
                  <li style="display: inline-block; padding-right: 7%">
                    <a target="_blank" href="https://www.linkedin.com/company/boston-financial-advisory-group/"><i
                        class="fab fa-linkedin-in" style="color: #fff"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- footer copt right -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>
  </footer>
</section>

<!--====== footer Dubai ENDS ======-->
<!--====== footer India START ======-->
<section *ngIf="selectCountryName == 'India'">
  <footer class="footer-area footer-area-2 footer-area-1 bg_cover">
    <div class="footer-overlay" style="background: #050605">
      <div class="position-relative" style="padding-left: 1rem !important; padding-right: 1rem !important;">
        <div class="row" style="padding: 1rem;">
          <div class="col-lg-4 col-md-4" style="padding-left: 3%">
            <div class="widget-item-1 mt-30">
              <img src="../../../../assets/images/BFAG_logo.png" alt="BFAG" style="max-width: 216px" />
              <h5 class="text-white mt-25">
                Sound Finance Solutions for Successful People
              </h5>
              <p >
                Boston Financial Advisory Group (BFAG) is a group of passionate
                and committed professionals from diverse domains such as
                finance, accounting, law, and compliance reporting. We take in
                upon ourselves to manage all your accounting and advisory needs
                so you can concentrate on what you do best – grow your business.
              </p>     
              <img routerLink="/iso-page" src="assets/images/NewIcon/isoccpa.jpeg" alt="Image 2" class="custom-img link-img" height="87" width="62" style="margin-left: 18px;"> &nbsp;
              <img routerLink="/ccpa-page" src="assets/images/NewIcon/CCPAlogo.jpg" alt="Image 2" class="custom-img link-img">
              
              
            </div>
            <!-- widget item 1 -->
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="widget-item-2 mt-30">
              <h4 class="title">Useful Links</h4>
              <div class="footer-list">
                <ul>
                  <li class="cursor-pointer" style="margin-right:0px">
                    <a routerLink="/faq-on-cpa-solution">FAQ on CPA Solution
                    </a>
                  </li>
                  <li class="cursor-pointer" style="margin-right:0px">
                    <a routerLink="/faq-india-entity-setup">FAQ India Entity Setup
                    </a>
                  </li>
                  <li class="cursor-pointer" style="margin-right:0px">
                    <a routerLink="/faq-odi">FAQ ODI
                    </a>
                  </li>
                  <li class="cursor-pointer" style="margin-right:0px">
                    <a routerLink="/faq-fdi">FAQ FDI
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- widget item 2 -->
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="widget-item-2 mt-30">
              <h4 class="title">Quick Reference</h4>
              <div class="footer-list">
                <ul>
                  <li style="margin-right:0px"><a routerLink="/why-bfag"> Why BFAG </a></li>
                  <li style="margin-right:0px">
                    <a routerLink="/business-setup"> Business Setup in India</a>
                  </li>
                  <!-- <li><a (click)="selectCountry('India')" > Business Setup in India </a></li>
                                <li><a (click)="selectCountry('Dubai')" > Business Setup in Dubai</a></li> -->
                  <li style="margin-right:0px"><a routerLink="/contact-us">Contact Us</a></li>
                  <li style="margin-right:0px"><a routerLink="/privacy-policy">Privacy Policy</a></li>
                  <li><a routerLink="/trust-center">Trust Center</a></li>
                  <li><a routerLink="/ccpa-page">Do Not Sell My Info</a></li>

                </ul>
              </div>
            </div>
            <!-- widget item 2 -->
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="widget-item-2 mt-30">
              <h4 class="title">Contact Info</h4>
              <div class="footer-list">
                <ul>
                  <li style="padding-right:0px; margin-right:0px">
                    <i class="fal fa-map-marker pr-3"></i> Boston
                    Financial
                    Advisory Group Inc.#
                    54, 3rd Floor, 13th
                    Main
                    17th
                    Cross.Sector
                    6,
                    Behind
                    BDA
                    Shopping
                    Complex,HSR Layout, Bangalore 560 102..
                  </li>
                  <li style="padding-right:0px; margin-right:0px">
                    <i class="fal fa-map-marker pr-3"></i>  Boston Financial Advisory Group Inc.Office No. 203/204,
                    2nd
                    Floor,Crystal Empire, Near Karnataka Bank,Behind D-Mart,
                    Baner, Pune 411045.
                  </li>
                  <li style="padding-right:0px; margin-right:0px">
                    <i class="fal fa-phone pr-3"></i>  
                    <a href="tel:+917420061235">+91 7420061235</a>
                </li>
                <li style="padding-right:0px; margin-right:0px">
                    <i class="fal fa-envelope pr-3"></i> 
                    <a href="mailto:info@bostonfagroup.com">info@bostonfagroup.com</a> <br/>
                    <a href="mailto:sales@bostonfagroup.com" style="padding-left: 30px;">sales@bostonfagroup.com</a>
                </li>
                </ul>
              </div>
            </div>
            <!-- widget item 2 -->
          </div>
        </div>
        <!-- row -->
        <div class="row" style="background: #072f5f">
          <div class="col-lg-12" style="
              border-top: 1px solid rgba(255, 255, 255, 0.2);
              padding-top: 7px;
              padding-bottom: 7px;
            ">
            <div class="col-lg-6" style="float: left">
              <div class="footer-copyright">
                <p>Copyright @ 2023 | All Rights Reserved</p>
              </div>
            </div>
            <div class="col-lg-6" style="float: left">
              <div class="header-right-social text-center text-sm-right">
                <ul>
                  <li style="display: inline-block; padding-right: 2%">
                    <a target="_blank" href="https://www.facebook.com/BostonFinancialAdvisoryGroup/"><i
                        class="fab fa-facebook-f" style="color: #fff"></i>
                    </a>
                  </li>
                  <li style="display: inline-block; padding-right: 2%">
                    <a target="_blank" href="https://twitter.com/BostonfaGroup"><i class="fab fa-twitter"
                        style="color: #fff"></i>
                    </a>
                  </li>
                  <li style="display: inline-block; padding-right: 7%">
                    <a target="_blank" href="https://www.linkedin.com/company/boston-financial-advisory-group/"><i
                        class="fab fa-linkedin-in" style="color: #fff"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- footer copt right -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>
  </footer>
</section>

<!--====== footer India ENDS ======-->
<!--====== footer USA START ======-->

<section *ngIf="selectCountryName == 'USA'">
  <footer class="footer-area footer-area-2 footer-area-1 bg_cover">
    <div class="footer-overlay" style="background: #050605">
      <div class="position-relative" style="padding-left: 1rem !important; padding-right: 1rem !important;">
        <div class="row" style="padding: 1rem;">
          <div class="col-lg-4 col-md-4" style="padding-left: 3%">
            <div class="widget-item-1 mt-30">
              <img src="../../../../assets/images/BFAG_logo.png" alt="BFAG" style="max-width: 216px" />
              <h5 class="text-white mt-25">
                Sound Finance Solutions for Successful People
              </h5>
              <p>
                Boston Financial Advisory Group (BFAG) is a group of passionate
                and committed professionals from diverse domains such as
                finance, accounting, law, and compliance reporting. We take in
                upon ourselves to manage all your accounting and advisory needs
                so you can concentrate on what you do best – grow your business.
              </p>
              <img routerLink="/iso-page" src="assets/images/NewIcon/isoccpa.jpeg" alt="Image 2" class="custom-img link-img" height="87" width="62"  style="margin-left: 18px;"> &nbsp;
              <img routerLink="/ccpa-page" src="assets/images/NewIcon/CCPAlogo.jpg" alt="Image 2" class="custom-img link-img">            </div>
            <!-- widget item 1 -->
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="widget-item-2 mt-30">
              <h4 class="title">Useful Links</h4>
              <div class="footer-list">
                <ul>
                  <li class="cursor-pointer" style="margin-right:0px">
                    <a routerLink="/faq-on-cpa-solution">FAQ on CPA Solution
                    </a>
                  </li>
                  <li class="cursor-pointer" style="margin-right:0px">
                    <a routerLink="/faq-usa-entity-setup">FAQ USA Entity Setup
                    </a>
                  </li>
                  <li class="cursor-pointer" style="margin-right:0px">
                    <a routerLink="/faq-fdi">
                      FAQ FDI
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- widget item 2 -->
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="widget-item-2 mt-30">
              <h4 class="title">Quick Reference</h4>
              <div class="footer-list">
                <ul>
                  <li><a routerLink="/why-bfag"> Why BFAG </a></li>
                  <li>
                    <a routerLink="/business-setup">Business Setup in USA</a>
                  </li>
                  <!-- <li><a routerLink="/business-setup" > Business Setup in India </a></li> -->
                  <!-- <li><a (click)="selectCountry('Dubai')" > Business Setup in Dubai</a></li> -->
                  <li><a routerLink="/contact-us">Contact Us</a></li>
                  <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                  <li><a routerLink="/trust-center">Trust Center</a></li>
                  <li><a routerLink="/ccpa-page">Do Not Sell My Info</a></li>
               
                </ul>
                <!-- <img src="assets/images/NewIcon/CCPAlogo." alt="Image 2" class="custom-img"> -->

              </div>
            </div>
            <!-- widget item 2 -->
          </div>
          <div class="col-lg-4 col-md-4" style="padding-left: 0 !important;">
            <div class="widget-item-2 mt-30">
              <h4 class="title">Contact Info</h4>
              <div class="footer-list">
                <ul>
                  <li class="text-justify" style="margin-right:0px">
                    <i class="fal fa-map-marker pr-3"></i> Boston Financial
                    Advisory Group Inc. <br />The Schrafts Center Power House,
                    Office No 341, 529 Main Street, Charlestown, MA-02129
                  </li>
                  <li class="text-justify" style="margin-right:0px">
                    <i class="fal fa-map-marker pr-3"></i>  2214 Walnut Street, Mckeesport, PA 15132
                  </li>
                  <!-- <li class="text-justify" style="margin-right:0px">
                    <i class="fal fa-map-marker pr-3"></i> <br /> Boston Financial
                    Advisory Group Inc. <br />The Schrafts Center Power House,
                    Office No 341, 529 Main Street, Charlestown, MA-02129
                  </li> -->
                  <li style="margin-right:0px">
                    <i class="fal fa-phone pr-3"></i>  
                    <a href="tel:+16178865181">+1 617-886-5181</a>, <br/> 
                    <span style="padding-left: 34px;"><a href="tel:+18572144901">+1 857-214-4901</a></span>
                </li>
                <li style="margin-right:0px">
                  <i class="fal fa-envelope pr-3"></i> 
                  <a href="mailto:info@bostonfagroup.com">info@bostonfagroup.com</a>, <br/>
                  <a href="mailto:sales@bostonfagroup.com" style="padding-left: 31px;">sales@bostonfagroup.com</a>
              </li>
                </ul>
              </div>
            </div>
            <!-- widget item 2 -->
          </div>
        </div>
        <!-- row -->
        <div class="row" style="background: #072f5f">
          <div class="col-lg-12" style="
              border-top: 1px solid rgba(255, 255, 255, 0.2);
              padding-top: 7px;
              padding-bottom: 7px;
            ">
            <div class="col-lg-6" style="float: left">
              <div class="footer-copyright">
                <p>Copyright @ 2023 | All Rights Reserved</p>
              </div>
            </div>
            <div class="col-lg-6" style="float: left">
              <div class="header-right-social text-center text-sm-right">
                <ul>
                  <li style="display: inline-block; padding-right: 2%">
                    <a target="_blank" href="https://www.facebook.com/BostonFinancialAdvisoryGroup/"><i
                        class="fab fa-facebook-f" style="color: #fff"></i>
                    </a>
                  </li>
                  <li style="display: inline-block; padding-right: 2%">
                    <a target="_blank" href="https://twitter.com/BostonfaGroup"><i class="fab fa-twitter"
                        style="color: #fff"></i>
                    </a>
                  </li>
                  <li style="display: inline-block; padding-right: 7%">
                    <a target="_blank" href="https://www.linkedin.com/company/boston-financial-advisory-group/"><i
                        class="fab fa-linkedin-in" style="color: #fff"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- footer copt right -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>
  </footer>
</section>

<!--====== footer USA ENDS ======-->
<!--====== BACK TO TOP ======-->
<div class="icon-bar">
  <a target="_blank" href="https://www.linkedin.com/company/boston-financial-advisory-group/" class="linkedin"><i
      class="fab fa-linkedin fa-3x"></i></a>
</div>
<div class="whatsapp-icon">
  <a target="_blank"
    href="https://api.whatsapp.com/send?phone=+918600477799&text=Hi+Boston+Team%2C+I+would+like+to+know+more+about+the+services.+Can+you+please+help+me%3F">
    <img src="assets/images/whatsapp.png" />
  </a>
</div>

<!--====== BACK TO TOP ======-->