<div class="min-vh-100 mt-150">
  <div
    class="col-lg-12 m-0 d-flex flex-column justify-content-center align-items-center"
    style="background: #21ac5133; height: 25vh"
  >
    <h4>FAQ ON GST</h4>
    <div></div>
    <small class="text-dark" style="font-weight: 550"
      >Home << Blog << Blogs << FAQ On GST
    </small>
  </div>
  <div class="container-fluid pt-4">
    <div class="col-lg-12 d-flex justify-content-center">
      <div
        class="col-lg-8"
        style="
          overflow: hidden;
          height: 29.5rem;
          border-radius: 17px;
          padding-left: 0;
          padding-right: 0;
        "
      >
        <img
          class="img-fluid"
          src="../../../../assets/images/newsletter/img 1.png"
          alt="img"
        />
        <small style="color: #21ac51;"><a>Posted On 27 Apr 2022 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/ admin@bfag</a></small>
      </div>
      <div class="col-lg-4">
        <h5 class="pb-2">RELATED POST</h5>
        <div class="d-flex flex-row pb-2" style="gap: 0.5rem">
          <div>
            <img
              class="img-fluid"
              src="../../../../assets/images/newsletter/img 1.png"
              alt="image"
              style="width: 17rem"
            />
          </div>
          <div class="">
            <small  style="color: #21ac51;"><a>Posted On 27 Apr 2022 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/ admin@bfag</a></small>
            <p style="line-height: normal">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="d-flex flex-row pb-2" style="gap: 0.5rem">
          <div>
            <img
              class="img-fluid"
              src="../../../../assets/images/newsletter/img 1.png"
              alt="image"
              style="width: 17rem"
            />
          </div>
          <div class="">
            <small style="color: #21ac51;"><a>Posted On 27 Apr 2022 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/ admin@bfag</a></small>
            <p style="line-height: normal">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="d-flex flex-row pb-2" style="gap: 0.5rem">
          <div>
            <img
              class="img-fluid"
              src="../../../../assets/images/newsletter/img 1.png"
              alt="image"
              style="width: 17rem"
            />
          </div>
          <div class="">
            <small style="color: #21ac51;"><a>Posted On 27 Apr 2022 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/ admin@bfag</a></small>
            <p style="line-height: normal">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="d-flex flex-row pb-2" style="gap: 0.5rem">
          <div>
            <img
              class="img-fluid"
              src="../../../../assets/images/newsletter/img 1.png"
              alt="image"
              style="width: 17rem"
            />
          </div>
          <div class="">
            <small style="color: #21ac51;"><a>Posted On 27 Apr 2022 <i class="fas fa-comments-alt"></i>/ <i class="far fa-user-circle"></i>/ admin@bfag</a></small>
            <p style="line-height: normal">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 pt-5 pb-3">
      <h4 class="pb-3" style="color: #21ac51">FAQ ON GST</h4>
      <p class="text-dark">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop.
      </p>
      <p class="text-dark">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop.
      </p>
    </div>
  </div>
</div>
