
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area horizon-training customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Horizon Training</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Horizon Training</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== Horizon Training PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Training Support</span>
                    <h2 class="title pb-15">Horizon Training</h2>
                    <p>Implementing and operating any system has some cost involved. However, if an organization fails to train its user base on the proper use of the software the investment will largely be wasted. Avoid the number one mistake organizations
                        make with regards to implementing a system and make training a priority from the beginning of the implementation project. The training program must address overview training for managers and executives, configuration training for
                        the project team members, and functional training for the end users.</p>
                    <p>All users should be trained properly in using the system to its fullest. Different groups of people in the company will have different training needs. Managers need more focus on the decision-making and analysis features of the system
                        while the clerical staff need more focus on how to perform their jobs. But all the users must be trained in the Horizon basics, overview of the system and its working, how an action by an employee triggers a host of events throughout
                        the organization, how automation will help, what processes are changed and so on.</p>
                    <p>For the software to deliver on its value, end users must develop the skills and knowledge on how to use it effectively. End user adoption is critical to a successful software implementation and its on-going use – this is only possible
                        with effective training. Our extensive training methodology, educational materials and user resources provide customers an excellent platform to develop an effective training program. The array of resources enables customers to
                        choose the best options to meet their needs and tailor programs to their individual user preferences.</p>
                </div>
                <!-- experience item -->

                <h5 class="title pb-15 mt-20">Our Services and deliverables include</h5>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a>Classroom Training</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a>On-Site Training</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a>Remote Training</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a>Technical & End User Reference Guides</a></p>
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Horizon Training PART END ======-->

<!--====== Our Trainers PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Advisers</span>
                    <h2 class="title pb-15">Our Trainers</h2>
                    <p>Trainers provide the ability to easily create and provide custom training. Through its “show me, try me, test me” approach, our trainers provides a unique and interactive approach to learning. The method improves knowledge retention
                        as learning is more effective when performed in the context of actual work. Further, content developed for training can be re-used in job aids, reference guides, product documentation etc.</p>
                </div>
                <!-- experience item -->

                <h5 class="title pb-15 mt-20">Key features include</h5>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a>Easily create user training specific to your processes</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a>Link content to the actual application screens</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a>Track user progress and performance through training</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a>Identify knowledge levels of individuals, groups or departments</a></p>
                <p class="mt-20">Horizon System constantly changes due to upgrades, additional features, or addition of new functionalities. In addition, knowledge leakage due to employee attrition can be as high as 25% in any given year. Consequently, Horizon training
                    is an ongoing business requirement and not a one-time event. Best practices suggest providing recurring refresher training to all system users. Also, to ensure ongoing knowledge retention, many of our clients make the successful completion
                    of appropriate training courses part of the employees’ annual performance evaluation.</p>
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Our Trainers PART START ======-->
