<!-- usa -->
<section *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div
      class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="
        background: url('../../../../assets/images/service/Accounting.png') no-repeat center;
      background-size: cover;
      height: 430px;
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase text-center">
          Accounting & Transaction <br />
          Processing (US)
        </h3>
        <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
      </div>
    </div>
    <div
      class="container-fluid d-flex justify-content-center align-content-center bg-white"
    >
      <div
        class="about-faq-area about-faq-area-custom horizon-hrms dec-spacing bg_cover"
      >
        <div class="container">
          <div class="row">
            <div class="faq-accordion-3 pt-0">
              <div class="accordion" id="accordionExample1">
                <div>
                  <div
                    class="card wow fadeInLeft"
                    data-wow-duration=".1.2s"
                    data-wow-delay=".4s"
                  >
                    <div class="card-header" id="headingSev">
                      <a
                        class=""
                        href=""
                        data-toggle="collapse"
                        data-target="#collapseSev"
                        aria-expanded="true"
                        aria-controls="collapseSev"
                      >
                      <span routerLink="/accounting-and-transition-processing">Accounting & Transaction Processing</span>
                      </a>
                    </div>
                    <div
                      id="collapseSev"
                      class="collapse show"
                      aria-labelledby="headingSev"
                      data-parent="#accordionExample1"
                    >
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark">
                            <li routerLink="/accounts-payable">
                              <i
                                class="far fa-angle-double-right pt-1 pr-1"
                              ></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i
                                class="far fa-angle-double-right pt-1 pr-1"
                              ></i>
                              Accounts Receivable
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i
                                class="far fa-angle-double-right pt-1 pr-1"
                              ></i>
                              Fixed Assets Accounting
                            </li>
                            <li routerLink="/financial-reporting">
                              <i
                                class="far fa-angle-double-right pt-1 pr-1"
                              ></i>
                              Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i
                                class="far fa-angle-double-right pt-1 pr-1"
                              ></i>
                              Budgeting & Variance Analysis
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i
                                class="far fa-angle-double-right pt-1 pr-1"
                              ></i>
                              Reconciliation Services
                            </li>
                            <li
                              routerLink="/consolidation-reporting-gaap-conversion"
                            >
                              <i
                                class="far fa-angle-double-right pt-1 pr-1"
                              ></i>
                              Consolidation Reporting & GAAP Conversion
                            </li>
                            <li routerLink="/small-business-accounting">
                              <i
                                class="far fa-angle-double-right pt-1 pr-1"
                              ></i>
                              Small Business Accounting
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div
                    class="card wow fadeInLeft"
                    data-wow-duration=".1.2s"
                    data-wow-delay=".5s"
                  >
                    <div class="card-header" id="headingEight">
                      <a
                        class="collapsed"
                        href=""
                        data-toggle="collapse"
                        data-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                      <span routerLink="/tax-regulatory-compliances">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div
                      id="collapseEight"
                      class="collapse"
                      aria-labelledby="headingEight"
                      data-parent="#accordionExample1"
                    >
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark">
                            <li routerLink="/individual-tax-return">
                              <i
                                class="far fa-angle-double-right pt-1 pr-1"
                              ></i>
                              Individual Tax Return
                            </li>
                            <li routerLink="/corporate-tax-return">
                              <i
                                class="far fa-angle-double-right pt-1 pr-1"
                              ></i>
                              Corporate Tax Return
                            </li>
                            <li routerLink="/sales-and-use-tax">
                              <i
                                class="far fa-angle-double-right pt-1 pr-1"
                              ></i>
                              Sales & Use Tax
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                  <div
                    class="card wow fadeInLeft"
                    data-wow-duration=".1.2s"
                    data-wow-delay=".6s"
                  >
                    <div class="card-header" id="headingNine">
                      <a
                        class="collapsed"
                        href=""
                        data-toggle="collapse"
                        data-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                      <span routerLink="/payroll-advisory">Payroll Advisory</span>
                      </a>
                    </div>
                    <div
                      id="collapseNine"
                      class="collapse"
                      aria-labelledby="headingNine"
                      data-parent="#accordionExample1"
                    >
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4">
                            <li routerLink="/payroll-processing">
                              <i
                                class="far fa-angle-double-right pt-1 pr-1"
                              ></i>
                              Payroll Processing
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div
                    class="card wow fadeInLeft"
                    data-wow-duration=".1.2s"
                    data-wow-delay=".6s"
                  >
                    <div class="card-header" id="headingTen">
                      <a
                        class="collapsed"
                        href=""
                        data-toggle="collapse"
                        data-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                      >
                      <span routerLink="/consulting-and-advisory-services">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div
                      id="collapseTen"
                      class="collapse"
                      aria-labelledby="headingTen"
                      data-parent="#accordionExample1"
                    >
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4">
                            <li routerLink="/business-setup">
                              <i
                                class="far fa-angle-double-right pt-1 pr-1"
                              ></i>
                              Business Set-up
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->
        </div>
      </div>
      <div class="col-lg-8">
        <div style="margin-top: 3rem">
          <p
            class="mb-15 mt-20 text-dark"
            style="line-height: normal; text-align: justify; font-size: 16px"
          >
            At Boston Financial Advisory Group, we offer comprehensive
            accounting and transaction processing services tailored to meet the
            specific needs of businesses.
          </p>
          <p
            class="mb-15 mt-20 text-dark"
            style="line-height: normal; text-align: justify; font-size: 16px"
          >
            We handle various aspects of accounting and transaction processing,
            including bookkeeping, tracking payments, accounts payable and
            receivable management, financial statement preparation, and general
            ledger maintenance. Our meticulous approach and attention to detail
            ensure that your financial records are organized, up-to-date, and
            ready for analysis.
          </p>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column justify-content-center align-items-center"
      style="background: #f0f5f5"
    ></div>
  </div>
</section>


<!-- india  -->
<section *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div class="d-flex justify-content-center align-items-center  bmTop banner-image"
      style="background: url('../../../../assets/images/service/Accounting.png') no-repeat center;
      background-size: cover;
      height: 430px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-white text-uppercase text-center">
            Accounting & Transaction Processing
          </h3>
          <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-center align-content-center bg-white">
      <div class="about-faq-area-custom horizon-hrms bg_cover">
        <div class="container">
          <div class="row">
            <div class="faq-accordion-3 pt-0">
              <div class="accordion" id="accordionExample1">
                <div>
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s" style="margin-bottom: 0px;">
                    <div class="card-header" id="headingSev">
                      <a class="" href="" data-toggle="collapse" data-target="#collapseSev"
                        aria-expanded="true" aria-controls="collapseSev">
                        <span routerLink="/accounting-and-transition-processing">Accounting & Transaction Processing</span>
                      </a>
                    </div>
                    <div id="collapseSev" class="collapse show" aria-labelledby="headingSev" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li class="shli" routerLink="/accounts-payable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Payable
                            </li>
                            <li routerLink="/accounts-receivable">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Accounts Receivable
                            </li>
                            <li routerLink="/fixed-assets-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Fixed Assets Accounting
                            </li>
                            <li routerLink="/financial-reporting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Financial Reporting
                            </li>
                            <li routerLink="/budgeting-and-variance-analysis">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Budgeting & Variance Analysis
                            </li>
                            <li routerLink="/reconciliation-services">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Reconciliation Services
                            </li>
                            <li routerLink="/consolidation-reporting-gaap-conversion">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Consolidation Reporting & GAAP Conversion
                            </li>
                            <!-- <li routerLink="/small-business-accounting">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Small Business Accounting
                            </li> -->
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                    <div class="card-header" id="headingEight">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEight"
                        aria-expanded="false" aria-controls="collapseEight">
                        <span routerLink="/tax-regulatory-compliances">Tax & Regulatory Compliances</span>
                      </a>
                    </div>
                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark serviceul">
                            <li routerLink="/income-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Income Tax
                            </li>
                            <li  routerLink="/goods-and-service-tax">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Goods & Service Tax
                            </li>
                            <li routerLink="/corporate-secretarial-compliance">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Corporate Secretarial Compliance
                            </li>
                            <li  routerLink="/fema-advisory">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              FEMA Compliance
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingNine">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseNine"
                        aria-expanded="false" aria-controls="collapseNine">
                        <span routerLink="/payroll-advisory">Payroll Advisory</span>
                      </a>
                    </div>
                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/payroll-processing">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Payroll - Compensation & Benefit Management
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->

                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingTen">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTen"
                        aria-expanded="false" aria-controls="collapseTen">
                        <span routerLink="/consulting-and-advisory-services">Consulting & Advisory</span>
                      </a>
                    </div>
                    <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/business-setup">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Setting up an entity in the India
                            </li>
                            <li routerLink="/global-corporate-structuring">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Global Corporate Structuring
                            </li>
                            <li routerLink="/business-plan-preparation">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Business Plan Preparation
                            </li>
                            <li routerLink="/business-valuation-service">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Business Valuation
                            </li>
                            <li routerLink="/mergers-and-acquisitions">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Mergers and Acquisitions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                  <!-- card -->
                  <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                    <div class="card-header" id="headingElevenStartup">
                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseElevenStartup"
                        aria-expanded="false" aria-controls="collapseElevenStartup">

<span routerLink="/startup-solution-services">Startup Solution</span>
                      </a>
                    </div>
                    <div id="collapseElevenStartup" class="collapse" aria-labelledby="headingElevenStartup" data-parent="#accordionExample1">
                      <div class="card-body bg-white">
                        <div>
                          <ul class="text-dark pt-4 serviceul">
                            <li routerLink="/startup-solution">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Startup Solution
                            </li>
                            <li routerLink="/fund-raising">
                              <i class="far fa-angle-double-right pt-1 pr-1"></i>
                              Fund Raise
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- card -->
                </div>
              </div>
              <!-- faq accordion -->
            </div>
          </div>
          <!-- row -->
        </div>
      </div>
      <div class="col-lg-8">
        <div style="margin-top: 1rem">
          <h3>Accounts Payable</h3>
          <!--<p class="mb-15 mt-20 text-dark servicep">
            Streamline Your Finances with Expert Accounts Payable Services
          </p>-->
          <p class="mb-15 mt-20 text-dark servicep">
            Managing accounts payable is a critical aspect of financial management for businesses of all sizes. It involves handling invoices, tracking payments, and ensuring timely disbursements to vendors and suppliers. However, with the complex nature of accounts payable processes, it can be time-consuming and prone to errors. We at BFAG offer comprehensive and efficient accounts payable services to help you streamline your financial operations.
          </p>
          <p class="mb-15 mt-20 text-dark servicep">
            Our Accounts Payable Services include:
          </p>
        </div>
        <div class="about-faq-area-custom horizon-hrms gray-bg clearfix">
          <div class="container">
              <div class="row" style="display: block;">
                  <div class="faq-accordion-2" style="padding-bottom: 20px;padding-top: 20px;">
                      <div class="accordion" id="accordionExample2">
                          <div class="col-lg-6">
                              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                  <div class="card-header" id="headingEleven">
                                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                          Accurate and Efficient Invoice Processing
                                      </a>
                                  </div>
                                  <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample2">
                                      <div class="card-body">
                                          <p>Our experienced professionals are well-versed in invoice processing, allowing you to focus on your core business activities. By leveraging advanced software and streamlined workflows, we ensure accurate data entry, invoice matching, and approval processes. This attention to detail minimizes errors, reduces processing time, and improves vendor relationships.</p>
                                      </div>
                                  </div>
                              </div>
                              <!-- card -->
                              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                  <div class="card-header" id="headingTwelve">
                                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                          Timely Payment Management
                                      </a>
                                  </div>
                                  <div id="collapseTwelve" class="collapse " aria-labelledby="headingTwelve" data-parent="#accordionExample2">
                                      <div class="card-body">
                                          <p>Late payments can strain vendor relationships and harm your business's reputation. Our accounts payable services include proactive payment management, ensuring timely disbursements to suppliers. By optimizing payment schedules, monitoring due dates, and facilitating electronic payments, we help you avoid late fees and maintain strong vendor partnerships.</p>
                                      </div>
                                  </div>
                              </div>
                              <!-- card -->
                              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                  <div class="card-header" id="headingThirteen">
                                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                          Enhanced Financial Control
                                      </a>
                                  </div>
                                  <div id="collapseThirteen" class="collapse " aria-labelledby="headingThirteen" data-parent="#accordionExample2">
                                      <div class="card-body">
                                          <p>Maintaining financial control is essential for effective cash flow management. Our services provide comprehensive reporting and analysis, giving you a clear overview of your accounts payable status. By identifying payment trends, highlighting discrepancies, and monitoring key performance indicators, we enable you to make informed financial decisions and optimize your working capital.</p>
                                      </div>
                                  </div>
                              </div>

                              <!-- card -->
                          </div>

                          <div class="col-lg-6">
                              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                  <div class="card-header" id="headingFourteen">
                                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                          Streamlined Vendor Management
                                      </a>
                                  </div>
                                  <div id="collapseFourteen" class="collapse " aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                      <div class="card-body">
                                          <p>Managing multiple vendors can be challenging, particularly when it comes to tracking contracts, payment terms, and discounts. Our team ensures streamlined vendor management by centralizing vendor information, tracking contract terms, and optimizing cash flow. By negotiating favorable terms and leveraging vendor relationships, we help you minimize costs and improve your bottom line.</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                  <div class="card-header" id="headingFifteen">
                                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                          Data Security and Compliance
                                      </a>
                                  </div>
                                  <div id="collapseFifteen" class="collapse " aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                      <div class="card-body">
                                          <p>We understand the importance of data security and compliance in financial transactions. Our accounts payable services are designed to maintain the utmost confidentiality and adhere to industry regulations. We implement robust security measures, including encrypted data transmission, restricted access controls, and regular audits, ensuring your sensitive financial information is protected.</p>
                                      </div>
                                  </div>
                              </div>
                              <!-- card -->
                              <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                                  <div class="card-header" id="headingSixteen">
                                      <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                          Cost Savings and Efficiency Gains
                                      </a>
                                  </div>
                                  <div id="collapseSixteen" class="collapse " aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                      <div class="card-body">
                                          <p>By outsourcing your accounts payable processes to our expert team, you can achieve significant cost savings and efficiency gains. We eliminate the need for additional staff, software investments, and infrastructure costs associated with in-house accounts payable management. Our scalable solutions can adapt to your business needs, enabling you to focus on core competencies while reducing operational overhead.</p>
                                      </div>
                                  </div>
                              </div>
                              <!-- card -->

                          </div>
                      </div>
                      <!-- faq accordion -->
                  </div>
              </div>
              <!-- row -->
          </div>
          <!-- container -->
      </div>
      <p class="mb-15 mt-20 text-dark servicep">
        Efficient accounts payable management is crucial for maintaining healthy financial operations and vendor relationships. BFAG offers comprehensive accounts payable services tailored to your business needs. By leveraging our expertise, advanced software, and streamlined processes, you can optimize your accounts payable processes, enhance financial control, and achieve cost savings. Contact us today to experience the benefits of our professional accounts payable services and take your business's financial management to the next level.
      </p>
      </div>
    </div>

  </div>
</section>

