import { Component, OnInit } from '@angular/core';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-newsletter-details',
  templateUrl: './newsletter-details.component.html',
  styleUrls: ['./newsletter-details.component.css']
})
export class NewsletterDetailsComponent implements OnInit {

  defaultCountry: string;
  selectCountryName: any;
  selectedFlag: string;
//   relatedNewsList = [
//     {
//       posted_on: '27 Apr 2022',
//       author: 'admin@bfag',
//       news_title:" Some quick example text to build on the card title and make up the bulk of the card's content. ",
//       news_img:"../../../../assets/images/media hits/PT-26-1.jpg"
//     },
//     {
//       posted_on: '27 Apr 2022',
//       author: 'admin@bfag',
//       news_title:" Some quick example text to build on the card title and make up the bulk of the card's content. ",
//       news_img:"../../../../assets/images/media hits/PT-26-1.jpg"
//     },
//     {
//       posted_on: '27 Apr 2022',
//       author: 'admin@bfag',
//       news_title:" Some quick example text to build on the card title and make up the bulk of the card's content. ",
//       news_img:"../../../../assets/images/media hits/PT-26-1.jpg"
//     },
//     {
//       posted_on: '27 Apr 2022',
//       author: 'admin@bfag',
//       news_title:" Some quick example text to build on the card title and make up the bulk of the card's content. ",
//       news_img:"../../../../assets/images/media hits/PT-26-1.jpg"
//     },
// ]
  news:any
  subarray:any
  relatedNewsList:any
  constructor(
    private route: ActivatedRoute,
    private LocalStorageServiceService: LocalStorageServiceService,) { }

    ngOnInit(): void {
      this.defaultCountry = 'USA';

      this.selectCountryName = localStorage.getItem('selectedCountry');

      if (this.selectCountryName == null) {
        localStorage.setItem('selectedCountry', this.defaultCountry);
        window.location.reload();
      } else {
        this.selectCountryName = localStorage.getItem('selectedCountry');
      }
       // Retrieve the blog ID from the route parameter
    this.route.params.subscribe((params) => {
      const newsletter_id = params['id']; // 'id' should match the route parameter name

      // Fetch the specific blog post using the ID (you'll need to implement this)
      this.fetchNewsById(newsletter_id);
      this.fetchNews();
    });
    }
    async fetchNewsById(newsletter_id: string) {
      try {
        // Make an HTTP request to your API endpoint to fetch the blog post by ID
        const response = await fetch(`https://rich-tan-dhole-kilt.cyclic.app/api/news/${newsletter_id}`, {
          method: 'GET',
        });
  
        if (response.ok) {
          // Parse the response JSON to get the blog data
          const newsData = await response.json();
  
          // Assign the fetched blog data to the 'blog' property
          this.news = newsData;
          this.subarray = JSON.parse(this.news.subtitle)
          console.log(this.news)
        } else {
          console.error('Failed to fetch blog data.');
        }
      } catch (error) {
        console.error('An error occurred while fetching blog data:', error);
      }
    }
    async fetchNews() {
      const res = await fetch(`https://rich-tan-dhole-kilt.cyclic.app/api/news/`, {
        method: 'GET',
      });
      this.relatedNewsList = await res.json();
      console.log(this.relatedNewsList);
    }
    selectCountry(country: string): void {
      // Update the selectedFlag with the corresponding image path
      if (country === 'Dubai') {
        this.selectedFlag =
          '/assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png';
      } else if (country === 'India') {
        this.selectedFlag = '/assets/images/flag/download.png';
      } else if (country === 'USA') {
        this.selectedFlag = '/assets/images/flag/USA.png';
      } else {
        // Set USA as the default country
        this.selectedFlag =
          '/assets/images/flag/USA.png';
        country = 'USA';
      }

      this.LocalStorageServiceService.setItem('selectedCountry', country);
     
    }
    
}
