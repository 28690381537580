<!-- dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/CPA back-office/CPAsolutions1.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Tax Preparation</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
    </div>
  </div>
  <!-- <div
    class="d-flex flex-column justify-content-center align-items-center container"
  >
    <div>
      <div
        class="container-fluid col-lg-12 d-flex flex-column justify-content-center align-content-center"
        style="padding: 40px"
      >
        <div class="">
          <div>
            <p
              class="mb-15 mt-15 text-dark"
              style="line-height: normal; font-size: 16px; text-align: justify;"
            >
              We offer comprehensive back-office services specifically designed
              for Certified Public Accountants (CPAs) and accounting firms. Our
              goal is to support your practice by taking care of essential and
              routine tasks, allowing you to focus on providing high-quality
              services to your clients.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="container">
    <div class="row" style="margin-top: 5%;margin-bottom: 4%;">
      <p class="text-dark" style="margin-bottom: 3%; padding: 0px 15px 0px; text-align: justify;"> 
        We offer comprehensive back-office services specifically designed
        for Certified Public Accountants (CPAs) and accounting firms. Our
        goal is to support your practice by taking care of essential and
        routine tasks, allowing you to focus on providing high-quality
        services to your clients.</p>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/tax1.png"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0">
        <div>
          <span class="subSpan" style="color: #418052 !important"
            >Tax Compliance and Preparation</span
          >
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; font-size: 16px"
            >
              <b>Individual and business tax returns:</b> We assist in the
              preparation and filing of tax returns for individuals,
              corporations, partnerships, and other entities.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; font-size: 16px"
            >
              <b>Tax planning and consulting</b> : Our experts provide tax
              planning strategies to optimize your clients' tax positions and
              minimize their tax liabilities.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; font-size: 16px"
            >
              <b>IRS correspondence</b> : We handle communication with the IRS
              on behalf of your clients, addressing inquiries, notices, and
              audits.
            </li>
          </ul>
        </div>
      </div>
      <h4
        style="
          margin-top: 3%;
          margin-bottom: 2%;
          padding-left: 1%;
          color: #418052;
        "
      >
        Return Types
      </h4>
    </div>
  </div>
  <div
    class="about-faq-area-custom horizon-hrms gray-bg clearfix container"
    style="margin-bottom: 4%; background-color: #4180521a"
  >
    <div class="container">
      <div class="row" style="display: block">
        <div
          class="faq-accordion-2"
          style="padding-bottom: 20px; padding-top: 20px"
        >
          <div class="accordion" id="accordionExample2">
            <div class="col-lg-6">
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".2s"
              >
                <div class="card-header" id="headingEleven">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                    style="color: #418052a1"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1040 U.S. Individual Tax Returns
                  </a>
                </div>
              </div>
              <!-- card -->
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".3s"
              >
                <div class="card-header" id="headingTwelve">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseTwelve"
                    aria-expanded="false"
                    aria-controls="collapseTwelve"
                    style="color: #418052a1"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1065 U.S. Partnership Tax Returns
                  </a>
                </div>
              </div>
              <!-- card -->
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".4s"
              >
                <div class="card-header" id="headingThirteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseThirteen"
                    aria-expanded="false"
                    aria-controls="collapseThirteen"
                    style="color: #418052a1"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1120 U.S Corporate Tax Returns
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".5s"
              >
                <div class="card-header" id="headingFourteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                    style="color: #418052a1"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1120S U.S. S Corporation Tax Returns
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".6s"
              >
                <div class="card-header" id="headingFourteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                    style="color: #418052a1"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1041 U.S. Estate and Trusts Tax Returns
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".7s"
              >
                <div class="card-header" id="headingFourteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                    style="color: #418052a1"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 990 & 990EZ U.S. Exempt organizations Returns
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".8s"
              >
                <div class="card-header" id="headingFourteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                    style="color: #418052a1"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Foreign Corporation (Form 1120F)
                  </a>
                </div>
              </div>
              <!-- card -->
            </div>

            <div class="col-lg-6">
              <!-- card -->
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".2s"
              >
                <div class="card-header" id="headingSixteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseSixteen"
                    aria-expanded="false"
                    aria-controls="collapseSixteen"
                    style="color: #418052a1"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Foreign Partnership (Form 8865)
                  </a>
                </div>
              </div>
              <!-- card -->
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".3s"
              >
                <div class="card-header" id="headingSeventeen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseSeventeen"
                    aria-expanded="false"
                    aria-controls="collapseSeventeen"
                    style="color: #418052a1"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Foreign Trust (Form 3520)
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".4s"
              >
                <div class="card-header" id="headingEightteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEightteen"
                    aria-expanded="false"
                    aria-controls="collapseEightteen"
                    style="color: #418052a1"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 5472 / 5471
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".5s"
              >
                <div class="card-header" id="headingEightteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEightteen"
                    aria-expanded="false"
                    aria-controls="collapseEightteen"
                    style="color: #418052a1"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1116/2555
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".6s"
              >
                <div class="card-header" id="headingEightteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEightteen"
                    aria-expanded="false"
                    aria-controls="collapseEightteen"
                    style="color: #418052a1"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i> FAS
                    109 and FIN 48
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".7s"
              >
                <div class="card-header" id="headingEightteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEightteen"
                    aria-expanded="false"
                    aria-controls="collapseEightteen"
                    style="color: #418052a1"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    FinCEN 114 (FBAR)
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- faq accordion -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </div>
</div>
<!-- dubai end -->

<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/CPA back-office/CPAsolutions1.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Tax Preparation</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
    </div>
  </div>
  <!-- <div
    class="d-flex flex-column justify-content-center align-items-center container"
  >
    <div>
      <div
        class="container-fluid col-lg-12 d-flex flex-column justify-content-center align-content-center"
        style="padding: 40px"
      >
        <div class="">
          <div>
            <p
              class="mb-15 mt-15 text-dark"
              style="line-height: normal; font-size: 16px; text-align: justify;"
            >
              We offer comprehensive back-office services specifically designed
              for Certified Public Accountants (CPAs) and accounting firms. Our
              goal is to support your practice by taking care of essential and
              routine tasks, allowing you to focus on providing high-quality
              services to your clients.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="container">
    <div class="row" style="margin-top: 5%;margin-bottom: 4%;">
      <p class="text-dark" style="margin-bottom: 3%; padding: 0px 15px 0px; text-align: justify;"> 
        We offer comprehensive back-office services specifically designed
              for Certified Public Accountants (CPAs) and accounting firms. Our
              goal is to support your practice by taking care of essential and
              routine tasks, allowing you to focus on providing high-quality
              services to your clients.</p>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/tax1.png"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0">
        <div>
          <span class="subSpan">Tax Compliance and Preparation</span>
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; font-size: 16px"
            >
              <b>Individual and business tax returns:</b> We assist in the
              preparation and filing of tax returns for individuals,
              corporations, partnerships, and other entities.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; font-size: 16px"
            >
              <b>Tax planning and consulting</b> : Our experts provide tax
              planning strategies to optimize your clients' tax positions and
              minimize their tax liabilities.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify; font-size: 16px"
            >
              <b>IRS correspondence</b> : We handle communication with the IRS
              on behalf of your clients, addressing inquiries, notices, and
              audits.
            </li>
          </ul>
        </div>
      </div>
      <h4 style="margin-top: 3%; margin-bottom: 2%; padding-left: 1%">
        Return Types
      </h4>
    </div>
  </div>
  <div
    class="about-faq-area-custom horizon-hrms gray-bg clearfix container"
    style="margin-bottom: 4%"
  >
    <div class="container">
      <div class="row" style="display: block">
        <div
          class="faq-accordion-2"
          style="padding-bottom: 20px; padding-top: 20px"
        >
          <div class="accordion" id="accordionExample2">
            <div class="col-lg-6">
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".2s"
              >
                <div class="card-header" id="headingEleven">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1040 U.S. Individual Tax Returns
                  </a>
                </div>
              </div>
              <!-- card -->
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".3s"
              >
                <div class="card-header" id="headingTwelve">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseTwelve"
                    aria-expanded="false"
                    aria-controls="collapseTwelve"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1065 U.S. Partnership Tax Returns
                  </a>
                </div>
              </div>
              <!-- card -->
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".4s"
              >
                <div class="card-header" id="headingThirteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseThirteen"
                    aria-expanded="false"
                    aria-controls="collapseThirteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1120 U.S Corporate Tax Returns
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".4s"
              >
                <div class="card-header" id="headingFourteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1120S U.S. S Corporation Tax Returns
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".4s"
              >
                <div class="card-header" id="headingFourteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1041 U.S. Estate and Trusts Tax Returns
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".4s"
              >
                <div class="card-header" id="headingFourteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 990 & 990EZ U.S. Exempt organizations Returns
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".4s"
              >
                <div class="card-header" id="headingFourteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Foreign Corporation (Form 1120F)
                  </a>
                </div>
              </div>
              <!-- card -->
            </div>

            <div class="col-lg-6">
              <!-- card -->
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".6s"
              >
                <div class="card-header" id="headingSixteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseSixteen"
                    aria-expanded="false"
                    aria-controls="collapseSixteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Foreign Partnership (Form 8865)
                  </a>
                </div>
              </div>
              <!-- card -->
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".4s"
              >
                <div class="card-header" id="headingSeventeen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseSeventeen"
                    aria-expanded="false"
                    aria-controls="collapseSeventeen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Foreign Trust (Form 3520)
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".5s"
              >
                <div class="card-header" id="headingEightteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEightteen"
                    aria-expanded="false"
                    aria-controls="collapseEightteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 5472 / 5471
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".5s"
              >
                <div class="card-header" id="headingEightteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEightteen"
                    aria-expanded="false"
                    aria-controls="collapseEightteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1116/2555
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".5s"
              >
                <div class="card-header" id="headingEightteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEightteen"
                    aria-expanded="false"
                    aria-controls="collapseEightteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i> FAS
                    109 and FIN 48
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".5s"
              >
                <div class="card-header" id="headingEightteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEightteen"
                    aria-expanded="false"
                    aria-controls="collapseEightteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    FinCEN 114 (FBAR)
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- faq accordion -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </div>
</div>
<!-- USA end -->

<!-- India started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mTop">
  <div
    class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="
      background: url('../../../../assets/images/CPA back-office/CPAsolutions1.png') no-repeat center;
      background-size: cover;
      height: 430px;
    "
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h3 class="text-white text-uppercase">Tax Preparation</h3>
      <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
    </div>
  </div>
  <!-- <div
    class="d-flex flex-column justify-content-center align-items-center container"
  >
    <div>
      <div
        class="container-fluid col-lg-12 d-flex flex-column justify-content-center align-content-center"
        style="padding: 40px"
      >
        <div class="">
          <div>
            <p class="mb-15 mt-15 text-dark" style="line-height: normal; text-align: justify;">
              We offer comprehensive back-office services specifically designed
              for Certified Public Accountants (CPAs) and accounting firms. Our
              goal is to support your practice by taking care of essential and
              routine tasks, allowing you to focus on providing high-quality
              services to your clients.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="container">
    <div class="row" style="margin-top: 5%;margin-bottom: 4%;">
      <p class="text-dark" style="margin-bottom: 3%; padding: 0px 15px 0px; text-align: justify;"> 
        We offer comprehensive back-office services specifically designed
        for Certified Public Accountants (CPAs) and accounting firms. Our
        goal is to support your practice by taking care of essential and
        routine tasks, allowing you to focus on providing high-quality
        services to your clients.</p>
      <div class="col-lg-5 justify-content-center align-items-center imgDiv">
        <div class="d-flex justify-content-start align-items-start">
          <img
            src="../../../../assets/images/CPA back-office/tax1.png"
            class="img-fluid"
            style="max-width: 400px"
          />
        </div>
      </div>
      <div class="col-lg-7 pr-0">
        <div>
          <span class="subSpan">Tax Compliance and Preparation</span>
        </div>
        <div style="padding-left: 3%">
          <ul style="list-style-type: disc">
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Individual and business tax returns:</b> We assist in the
              preparation and filing of tax returns for individuals,
              corporations, partnerships, and other entities.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>Tax planning and consulting</b> : Our experts provide tax
              planning strategies to optimize your clients' tax positions and
              minimize their tax liabilities.
            </li>
            <li
              class="mb-15 mt-10 text-dark"
              style="line-height: normal; text-align: justify"
            >
              <b>IRS correspondence</b> : We handle communication with the IRS
              on behalf of your clients, addressing inquiries, notices, and
              audits.
            </li>
          </ul>
        </div>
      </div>
      <h4 style="margin-top: 3%; margin-bottom: 2%; padding-left: 1%">
        Return Types
      </h4>
    </div>
  </div>
  <div
    class="about-faq-area-custom horizon-hrms gray-bg clearfix container"
    style="margin-bottom: 4%"
  >
    <div class="container">
      <div class="row" style="display: block">
        <div
          class="faq-accordion-2"
          style="padding-bottom: 20px; padding-top: 20px"
        >
          <div class="accordion" id="accordionExample2">
            <div class="col-lg-6">
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".2s"
              >
                <div class="card-header" id="headingEleven">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1040 U.S. Individual Tax Returns
                  </a>
                </div>
              </div>
              <!-- card -->
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".3s"
              >
                <div class="card-header" id="headingTwelve">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseTwelve"
                    aria-expanded="false"
                    aria-controls="collapseTwelve"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1065 U.S. Partnership Tax Returns
                  </a>
                </div>
              </div>
              <!-- card -->
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".4s"
              >
                <div class="card-header" id="headingThirteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseThirteen"
                    aria-expanded="false"
                    aria-controls="collapseThirteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1120 U.S Corporate Tax Returns
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".4s"
              >
                <div class="card-header" id="headingFourteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1120S U.S. S Corporation Tax Returns
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".4s"
              >
                <div class="card-header" id="headingFourteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1041 U.S. Estate and Trusts Tax Returns
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".4s"
              >
                <div class="card-header" id="headingFourteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 990 & 990EZ U.S. Exempt organizations Returns
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".4s"
              >
                <div class="card-header" id="headingFourteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Foreign Corporation (Form 1120F)
                  </a>
                </div>
              </div>
              <!-- card -->
            </div>

            <div class="col-lg-6">
              <!-- card -->
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".6s"
              >
                <div class="card-header" id="headingSixteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseSixteen"
                    aria-expanded="false"
                    aria-controls="collapseSixteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Foreign Partnership (Form 8865)
                  </a>
                </div>
              </div>
              <!-- card -->
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".4s"
              >
                <div class="card-header" id="headingSeventeen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseSeventeen"
                    aria-expanded="false"
                    aria-controls="collapseSeventeen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Foreign Trust (Form 3520)
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".5s"
              >
                <div class="card-header" id="headingEightteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEightteen"
                    aria-expanded="false"
                    aria-controls="collapseEightteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 5472 / 5471
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".5s"
              >
                <div class="card-header" id="headingEightteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEightteen"
                    aria-expanded="false"
                    aria-controls="collapseEightteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    Form 1116/2555
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".5s"
              >
                <div class="card-header" id="headingEightteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEightteen"
                    aria-expanded="false"
                    aria-controls="collapseEightteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i> FAS
                    109 and FIN 48
                  </a>
                </div>
              </div>
              <div
                class="card wow fadeInLeft"
                data-wow-duration=".1.2s"
                data-wow-delay=".5s"
              >
                <div class="card-header" id="headingEightteen">
                  <a
                    class="collapsed"
                    href=""
                    data-toggle="collapse"
                    data-target="#collapseEightteen"
                    aria-expanded="false"
                    aria-controls="collapseEightteen"
                  >
                    <i _ngcontent-serverapp-c71="" class="fal fa-magic"></i>
                    FinCEN 114 (FBAR)
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- faq accordion -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </div>
</div>
<!-- India end -->
