<!-- USA started -->
<div *ngIf="selectCountryName == 'USA'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
          <div class="" style="padding: 1%">
            <img
              style="border-radius: 10px; max-width: 95%"
              class="img-fluid"
              src="../../../../../assets/images/teams/kartikCircle.jpg"
              alt="kartik"
            />
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start align-items-start"
        >
          <h3
            class="text-uppercase text-dark title"
            style="font-size: 20px; font-weight: 700;"
          >
          KARTIK MEHTA
          </h3>
          <div>
            <span
              class="text-uppercase text-dark"
              style="font-size: 16px; font-weight: 600"
            >
            Vice President International Taxation
            </span>
            <a
              style="color: #fff"
              href="https://www.linkedin.com/in/kartik-mehta-848385102/"
              target="_blank"
            >
              <i
                class="fab fa-linkedin-in"
                style="
                  margin-left: 10px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                "
              ></i>
            </a>
          </div>
          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Kartik Mehta is a Fellow Chartered Accountant who has amassed over a decade of experience as a trusted advisor to businesses. Possessing extensive knowledge in various areas such as domestic tax, transfer pricing, foreign exchange regulations, and international tax, Kartik has successfully guided numerous organizations through intricate financial matters.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Currently, Kartik brings over 15 years of expertise and experience to Boston Financial Advisory Group, where they provide strategic guidance and innovative solutions to meet the diverse needs of clients. Committed to excellence and possessing a comprehensive understanding of taxation, Kartik is dedicated to helping businesses optimize their financial operations and achieve sustainable growth.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Specializing in a broad range of direct taxation aspects, Kartik has assisted businesses in developing tax-efficient and compliant structures for both domestic operations and inbound/outbound investments. By deeply understanding taxation and regulatory frameworks, Kartik has facilitated cross-border transactions and ensured compliance with international tax obligations.
          </p>

          <p class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
          He has conducted thorough tax due diligence exercises and prepared comprehensive transfer pricing policies and study reports.
          </p>


        </div>
        <div class="col-lg-12">
          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
            Leveraging in-depth knowledge of transfer pricing regulations, he has helped organizations establish effective pricing mechanisms and maintain compliance with global standards.
          </p>
          <p class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
          He has conducted thorough tax due diligence exercises and prepared comprehensive transfer pricing policies and study reports. Leveraging in-depth knowledge of transfer pricing regulations, he has helped organizations establish effective pricing mechanisms and maintain compliance with global standards.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
          >
            He has active participation in various Committees of professional associations, contributing significantly to the advancement of the field. Kartik strives to contribute to the professional community and keep businesses informed of the latest developments in the tax landscape.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- USA end -->

<!-- india started -->
<div *ngIf="selectCountryName == 'India'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
          <div class="" style="padding: 1%">
            <img
              style="border-radius: 10px; max-width: 95%"
              class="img-fluid"
              src="../../../../../assets/images/teams/kartikCircle.jpg"
              alt="kartik"
            />
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start align-items-start"
        >
          <h3
            class="text-uppercase text-dark title"
            style="font-size: 20px; font-weight: 700;"
          >
          KARTIK MEHTA
          </h3>
          <div>
            <span
              class="text-uppercase text-dark"
              style="font-size: 16px; font-weight: 600"
            >
            Vice President International Taxation
            </span>
            <a
              style="color: #fff"
              href="https://www.linkedin.com/in/kartik-mehta-848385102/"
              target="_blank"
            >
              <i
                class="fab fa-linkedin-in"
                style="
                  margin-left: 10px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                "
              ></i>
            </a>
          </div>
          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Kartik Mehta is a Fellow Chartered Accountant who has amassed over a decade of experience as a trusted advisor to businesses. Possessing extensive knowledge in various areas such as domestic tax, transfer pricing, foreign exchange regulations, and international tax, Kartik has successfully guided numerous organizations through intricate financial matters.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Currently, Kartik brings over 15 years of expertise and experience to Boston Financial Advisory Group, where they provide strategic guidance and innovative solutions to meet the diverse needs of clients. Committed to excellence and possessing a comprehensive understanding of taxation, Kartik is dedicated to helping businesses optimize their financial operations and achieve sustainable growth.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Specializing in a broad range of direct taxation aspects, Kartik has assisted businesses in developing tax-efficient and compliant structures for both domestic operations and inbound/outbound investments. By deeply understanding taxation and regulatory frameworks, Kartik has facilitated cross-border transactions and ensured compliance with international tax obligations.
          </p>

          <p class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
          He has conducted thorough tax due diligence exercises and prepared comprehensive transfer pricing policies and study reports.
          </p>


        </div>
        <div class="col-lg-12">
          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
            Leveraging in-depth knowledge of transfer pricing regulations, he has helped organizations establish effective pricing mechanisms and maintain compliance with global standards.
          </p>
          <p class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
          He has conducted thorough tax due diligence exercises and prepared comprehensive transfer pricing policies and study reports. Leveraging in-depth knowledge of transfer pricing regulations, he has helped organizations establish effective pricing mechanisms and maintain compliance with global standards.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
          >
            He has active participation in various Committees of professional associations, contributing significantly to the advancement of the field. Kartik strives to contribute to the professional community and keep businesses informed of the latest developments in the tax landscape.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- india end -->

<!-- Dubai started -->
<div *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mt-150">
  <div class="col-lg-12 col-md-12">
    <div class="p-3 mb-5 bg-white">
      <div class="row">
        <div
          class="col-lg-4 d-flex justify-content-start align-items-start justify-content-md-center align-items-md-center justify-content-sm-center align-items-sm-center mb-3"
        >
          <div class="" style="padding: 1%">
            <img
              style="border-radius: 10px; max-width: 95%"
              class="img-fluid"
              src="../../../../../assets/images/teams/kartikCircle.jpg"
              alt="kartik"
            />
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-start align-items-start"
        >
          <h3
            class="text-uppercase text-dark title"
            style="font-size: 20px; font-weight: 700;"
          >
          KARTIK MEHTA
          </h3>
          <div>
            <span
              class="text-uppercase text-dark"
              style="font-size: 16px; font-weight: 600"
            >
            Vice President International Taxation
            </span>
            <a
              style="color: #fff"
              href="https://www.linkedin.com/in/kartik-mehta-848385102/"
              target="_blank"
            >
              <i
                class="fab fa-linkedin-in"
                style="
                  margin-left: 10px;
                  background-color: #0072b1;
                  padding: 4px;
                  border-radius: 5px;
                "
              ></i>
            </a>
          </div>
          <p
            class="mt-20 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Kartik Mehta is a Fellow Chartered Accountant who has amassed over a decade of experience as a trusted advisor to businesses. Possessing extensive knowledge in various areas such as domestic tax, transfer pricing, foreign exchange regulations, and international tax, Kartik has successfully guided numerous organizations through intricate financial matters.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Currently, Kartik brings over 15 years of expertise and experience to Boston Financial Advisory Group, where they provide strategic guidance and innovative solutions to meet the diverse needs of clients. Committed to excellence and possessing a comprehensive understanding of taxation, Kartik is dedicated to helping businesses optimize their financial operations and achieve sustainable growth.
          </p>

          <p
            class="mt-10 text-dark text-justify"
            style="font-size: 16px !important"
          >
          Specializing in a broad range of direct taxation aspects, Kartik has assisted businesses in developing tax-efficient and compliant structures for both domestic operations and inbound/outbound investments. By deeply understanding taxation and regulatory frameworks, Kartik has facilitated cross-border transactions and ensured compliance with international tax obligations.
          </p>

          <p class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
          He has conducted thorough tax due diligence exercises and prepared comprehensive transfer pricing policies and study reports.
          </p>


        </div>
        <div class="col-lg-12">
          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
            Leveraging in-depth knowledge of transfer pricing regulations, he has helped organizations establish effective pricing mechanisms and maintain compliance with global standards.
          </p>
          <p class="mt-10 text-dark text-justify"
          style="font-size: 16px !important">
          He has conducted thorough tax due diligence exercises and prepared comprehensive transfer pricing policies and study reports. Leveraging in-depth knowledge of transfer pricing regulations, he has helped organizations establish effective pricing mechanisms and maintain compliance with global standards.
          </p>

          <p
          class="mt-10 text-dark text-justify"
          style="font-size: 16px !important"
          >
            He has active participation in various Committees of professional associations, contributing significantly to the advancement of the field. Kartik strives to contribute to the professional community and keep businesses informed of the latest developments in the tax landscape.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Dubai end -->
