
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area Facility-Management customer-stories-clients">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title text-white">Facility Management</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Facility Management</li>
                        </ol>
                    </nav>
                </div>
                <!-- page title -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== >Horizon for Facility Management PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Facility Management Industries</span>
                    <h2 class="title pb-15">Horizon - EBS for Facility Management</h2>
                    <p>Service and facilities management is one of the fastest-growing industries in the Middle East. As customers become increasingly demanding, delivering the service they want can involve a wide range of contractors, sub-contractors and
                        suppliers. For the same reasons, facilities management is an increasingly competitive and complex industry sector. It’s easy for costs to get out of control.</p>
                    <p>For long, companies having business in Property, Services, and Asset Management have had trouble integrating their operation on One Single system. Whether you are into hard services or soft services, guarding & security or maintenance
                        & engineering services, Horizon - EBS for Facility Management offers a single solution integrating with you Financial Accounting, Procurement, Inventory Control, Human Resource Management System and other modules of ERP.</p>
                    <p>Horizon – EBS for Facilities Management with its unique features enables you to manage Corrective Maintenance, Planned Preventive Maintenance, Breakdown Maintenance, Asset Management, Predictive Maintenance, Sub Contractors, Employee
                        Timesheet, Payroll, Inventory, Procurement, Account Receivables, Account Payables & MIS reports in an effective methodology.</p>
                    <p>Our Integrated ERP covers Processes right from Contracts to Periodic Invoicing of the customer and everything in between like Site Deployment / Tracking, Roster Planning, Attendance & Work Reporting. The application helps you track
                        Profitability at different levels viz., Region, Customer, Site, and Employee. With informative Dashboards, it lets you monitor all operations in real-time giving you critical information to make faster and better-informed decisions.</p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== >Horizon for Facility Management PART END ======-->

<!--====== CLIENTS PART START ======-->
<div class="brand-2-area pb-120  dec-spacing  gray-bg">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-12">
                <div class="experience-item">
                    <span>Damy Text</span>
                    <h2 class="title pb-15">Damy Text</h2>
                </div>
            </div> -->
            <div class="col-lg-12">
                <div class="brand-active">
                    <div class="brand-item">
                        <img src="assets/images/clients/tanzifco.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/operon.jpg" alt="brand name">
                    </div>
                    <!-- <div class="brand-item">
                        <img src="assets/images/clients/mab.jpg" alt="brand name">
                    </div> -->
                    <div class="brand-item">
                        <img src="assets/images/clients/lahej-and-sultan.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/farnek.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/engie.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/efm.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/belcare.jpg" alt="brand name">
                    </div>
                    <div class="brand-item">
                        <img src="assets/images/clients/apleona.jpg" alt="brand name">
                    </div>
                </div>
                <!-- brand item -->
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</div>
<!--====== CLIENTS PART ENDS ======-->

<!--====== Components PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="experience-item">
                    <span>Solution Modules</span>
                    <h2 class="title pb-15">Components of Facility Management</h2>
                </div>
            </div>
            <div class="col-lg-7 add-arrow-circle">
                <h5 class="title pb-15">Main Components</h5>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/facility-management"> Facility Management</a></p>
                <!-- <div class="row">
                    <div class="col-lg-5">
                        <p><i class="fa fa-angle-right color-red pr-10 pl-3"></i> FM Help Desk</p>
                        <p class="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> Contract / Job Creation</p>
                        <p class="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> Preventive Maintenance</p>
                        <p class="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> Facility Masters</p>
                        <p class="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> Asset Register</p>
                        <p class="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> Document Storage</p>
                        <p class="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> Invoicing – Auto / Manual</p>
                        <p clss="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> Management Reports</p>
                    </div>
                    <div class="col-lg-7">
                        <p class="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> Complaint / Grievance Management</p>
                        <p class="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> Special Jobs / Projects Management</p>
                        <p class="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> Task Assignment / Update</p>
                        <p class="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> Sales Enquiry (Call out jobs)</p>
                        <p class="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> Estimation (Call out jobs)</p>
                        <p class="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> Quotation (Call out jobs)</p>
                        <p class="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> Sub-Contracting Management</p>
                        <p class="pb-5"><i class="fa fa-angle-right color-red pr-10 pl-3"></i> And more ………</p>

                    </div>
                </div> -->
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/financial-accounting">Financial Accounting</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/fixed-asset-management">Fixed Asset Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/inventory-management">Procurement and Inventory Control</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/human-resource-management-system"> Human Resource Management System (Standard)</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/approval-management">Approval Management System</a></p>
                <!-- experience item -->
            </div>
            <div class="col-lg-5 add-arrow-circle">
                <h5 class="title pb-15">Additional Components</h5>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/facility">Horizon FMS App for Field Staff and Technician</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/facility">Horizon FMS Client Help desk (Web and Mobile App)</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/facility">Supplier Self Service Portal</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/facility">Approval Management App</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/human-resource-management-system">Human Resource Management System (Advance)</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/bank-facility-management">Bank Facility Management</a></p>
                <p><i class="fa fa-arrow-circle-right color-red pr-15"></i> <a class="color-gray" routerLink="/facility">Dashboard Management</a></p>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Components PART END ======-->

<!--====== Benefits at a glance PART START ======-->
<div class="mep-contracting about-experience-area dec-spacing about-experience-area-custom  gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="experience-item">
                    <span>Expand New Horizons</span>
                    <h2 class="title pb-15">Benefits At A Glance</h2>
                    <p class="add-arrow"> Get your people, processes and assets working in perfect harmony
                    </p>
                    <p class="add-arrow"> Ensure automatic integration between operations and back-office departments</p>
                    <p class="add-arrow"> End to end integrated solution for operations, billing and payroll</p>
                    <p class="add-arrow"> Complete automation reduces data entry significantly</p>

                    <p class="add-arrow"> Bring huge improvement in operational efficiency</p>
                    <p class="add-arrow"> Manage the full life cycle of your service calls efficiently with a panoptic of resources and customer issues, and share all call information through a centralize database</p>
                    <p class="add-arrow"> Manage project costing with a fully automated and totally customizable administration system, so you can focus on the job at hand without getting buried in paperwork</p>
                    <p class="add-arrow"> Minimize your callers’ waiting by keeping track of customer history, direct maintenance activities, warranties and service histories</p>

                    <p class="add-arrow"> Flexible contract structure with multiple level analysis</p>
                    <p class="add-arrow"> Multiple charging methods</p>
                    <p class="add-arrow"> Flexible billing and reporting. Issue correct and timely bills, to boost profitability</p>
                    <p class="add-arrow"> Complete visibility of costs and usage</p>
                    <p class="add-arrow"> Make sure decision-makers have correct and up-to-date information at the click of a button</p>
                    <p class="add-arrow"> Very sophisticated Dash Board for Bird’s Eye View</p>
                    <p>Horizon – EBS for the Facility Management can be deployed on-premise or on the cloud with aligned mobile applications. If you are looking for more details or having queries about Horizon – EBS for Facility Management, please feel free
                        to Contact Us. </p>
                </div>
                <!-- experience item -->
            </div>
        </div>
        <!-- container -->
    </div>
</div>
<!--====== Benefits at a glance PART END ======-->
