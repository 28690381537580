import { Component, OnInit } from '@angular/core';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  selectCountryName: any;
  selectedFlag: string;
  defaultCountry: string;

  constructor(  private router: Router,
    private route: ActivatedRoute,
    private LocalStorageServiceService: LocalStorageServiceService,) { }

  ngOnInit(): void {
    this.defaultCountry = 'USA';

    this.selectCountryName = localStorage.getItem('selectedCountry');

    if (this.selectCountryName == null) {
      localStorage.setItem('selectedCountry', this.defaultCountry);
      window.location.reload();
      // this.router.navigate(['/'])
    } else {
      this.selectCountryName = localStorage.getItem('selectedCountry');
      // this.router.navigate(['/'])
    }
  }

//   selectCountry(country: string): void {


//     if (country === 'Dubai') {
//       this.selectedFlag = "../../../../assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png";
//     } else if (country === 'India') {
//       this.selectedFlag = "../../../../assets/images/flag/download.png";
//     } else if (country === 'USA') {
//       this.selectedFlag = "../../../../assets/images/flag/USA.png";
//     }

//     this.LocalStorageServiceService.setItem('selectedCountry', country);
//   }

selectCountry(country: string): void {
  // Update the selectedFlag with the corresponding image path
  if (country === 'Dubai') {
    this.selectedFlag =
      '/assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png';
  } else if (country === 'India') {
    this.selectedFlag = '/assets/images/flag/download.png';
  } else if (country === 'USA') {
    this.selectedFlag = '/assets/images/flag/USA.png';
  } else {
    // Set USA as the default country
    this.selectedFlag =
      '/assets/images/flag/USA.png';
    country = 'USA';
  }

  this.LocalStorageServiceService.setItem('selectedCountry', country);
}

}
