<div class="d-flex justify-content-center align-items-center banner-image bmTop"
    style="background-color: #808080; height: 430px;">
    <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase" style="margin-top: 103px;">Cookies Data</h3>
    </div>
</div>


<div class="container mt-5">
    <div class="row mt-5">
      <div class="table-responsive">
        <table class="table mt-5">
          <thead>
            <tr>
              <th scope="col">No.</th>
              <th scope="col">UID </th>
              <th scope="col">Ip Address</th>
              <th scope="col">OS</th>
              <th scope="col">Browser</th>
              <th scope="col">Longitude</th>
              <th scope="col">Latitude</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
              <ng-container *ngFor="let row of cookies; let i = index;">
                  <tr>
                      <th scope="row">{{i+1}}</th>
                      <td>{{row.id}}</td>
                      <td>{{row.ipAddress}}</td>
                      <td>{{row.os}}</td>
                      <td>{{row.userAgent}}</td>
                      <td>{{row.longiTude}}</td>
                      <td>{{row.latiTude}}</td>
                      <td>{{row.addedDate | date:('dd-MM-yyyy')}}</td>

                    </tr>
              </ng-container>
           
          </tbody>
        </table>
      </div>
    </div>
</div>
