<!-- dubai started -->
<section *ngIf="selectCountryName == 'Dubai'" class="min-vh-100 mTop">
  <div class="col p-0 m-0">
    <div
      class="d-flex justify-content-center align-items-center bmTop banner-image"
      style="
        background: url('../../../../assets/images/service/tax-regulatory-complance.png')
          no-repeat center;
        background-size: cover;
        height: 430px;
      "
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-white text-uppercase text-center">Audit & Assurance Support</h3>
        <!-- <h4 routerLink="/" class="cursor-pointer pt-2" style="color: #69a1bb">Home | Careers</h4> -->
      </div>
    </div>
    <div
      class="container-fluid d-flex justify-content-center align-content-center bg-white"
    >
      <div class="about-faq-area-custom horizon-hrms bg_cover">
        <div class="row">
          <div class="col-md-4">
            <div class="faq-accordion-3 pt-0">
              <div class="accordion" id="accordionExample1">
                <div
                  class="card wow fadeInLeft"
                  data-wow-duration=".1.2s"
                  data-wow-delay=".4s"
                  style="margin-bottom: 0px; background-color: #4180521a"
                >
                  <div class="card-header" id="headingSev">
                    <a
                      class="collapsed"
                      href=""
                      data-toggle="collapse"
                      data-target="#collapseSevv"
                      aria-expanded="true"
                      aria-controls="collapseSevv"
                      style="color: #418052"
                    >
                      <span (click)="onAccounting()"
                        >Accounting & Transaction Processing</span
                      >
                    </a>
                  </div>
                  <div
                    id="collapseSevv"
                    class="collapse"
                    aria-labelledby="headingSev"
                    data-parent="#accordionExample1"
                  >
                    <div class="card-body bg-white">
                      <div>
                        <ul class="text-dark serviceul">
                          <li
                            routerLink="/accounting-services"
                            style="color: black"
                          >
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Accounts Payable
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card -->
                <div
                  class="card wow fadeInLeft"
                  data-wow-duration=".1.2s"
                  data-wow-delay=".5s"
                  style="background-color: #4180521a"
                >
                  <div class="card-header" id="headingEight">
                    <a
                      class="collapsed"
                      href=""
                      data-toggle="collapse"
                      data-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                      style="color: #418052"
                    >
                      <span (click)="onTax()"
                        >Tax & Regulatory Compliances</span
                      >
                    </a>
                  </div>
                  <div
                    id="collapseEight"
                    class="collapse"
                    aria-labelledby="headingEight"
                    data-parent="#accordionExample1"
                  >
                    <div class="card-body bg-white">
                      <div>
                        <ul class="text-dark serviceul">
                          <li
                            class="shli"
                            routerLink="/coordination-and-assistance-for-vat-audit"
                          >
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            VAT Advisory
                          </li>
                          <li
                            routerLink="/coordination-and-assistance-for-tax-audit"
                          >
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Corporate Tax Advisory
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- card -->
                <div
                  class="card wow fadeInLeft"
                  data-wow-duration=".1.2s"
                  data-wow-delay=".6s"
                  style="background-color: #4180521a"
                >
                  <div class="card-header" id="headingNine">
                    <a
                      href=""
                      data-toggle="collapse"
                      data-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                      style="color: #418052"
                    >
                      <span routerLink="/audit-and-assurance-support">
                        Audit & Assurance Support</span
                      >
                    </a>
                  </div>
                  <div
                    id="collapseNine"
                    class="collapse show"
                    aria-labelledby="headingNine"
                    data-parent="#accordionExample1"
                  >
                    <div class="card-body bg-white">
                      <div>
                        <ul class="text-dark pt-4 serviceul">
                          <li routerLink="/statutory-audit">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Statutory Audit
                          </li>
                          <li routerLink="/internal-audit">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Internal Audit
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card -->

                <!-- card -->
                <div
                  class="card wow fadeInLeft"
                  data-wow-duration=".1.2s"
                  data-wow-delay=".6s"
                  style="background-color: #4180521a"
                >
                  <div class="card-header" id="headingTen">
                    <a
                      class="collapsed"
                      href=""
                      data-toggle="collapse"
                      data-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                      style="color: #418052"
                    >
                      <span (click)="onConsulting()"
                        >Consulting & Advisory</span
                      >
                    </a>
                  </div>
                  <div
                    id="collapseTen"
                    class="collapse"
                    aria-labelledby="headingTen"
                    data-parent="#accordionExample1"
                  >
                    <div class="card-body bg-white">
                      <div>
                        <ul class="text-dark pt-4 serviceul">
                          <li routerLink="/business-setup">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Setting up an entity in Dubai
                          </li>
                          <li routerLink="/business-plan-preparation">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Business Plan Preparation
                          </li>
                          <li routerLink="/business-valuation-service">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Business Valuation
                          </li>
                          <li routerLink="/mergers-and-acquisitions">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Mergers and Acquisitions (M&A)
                          </li>
                          <li routerLink="/strategic-cfo-services">
                            <i class="far fa-angle-double-right pt-1 pr-1"></i>
                            Strategic CFO
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card -->
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div style="margin-top: 1rem">
              <h3 style="color: #418052">Audit and Assurance Support</h3>
              <!--<p class="mb-15 mt-20 text-dark servicep">
                  Streamline Your Finances with Expert Accounts Payable Services
                </p>-->
              <p class="mb-15 mt-20 text-dark servicep">
                At BFAG, we understand that conducting audits and providing
                assurance services requires meticulous attention to detail,
                extensive knowledge of accounting standards, and significant
                time and resources. That's why we offer comprehensive
                back-office support specifically designed to assist accountants
                globally in delivering efficient and accurate audits and
                assurance engagements.
              </p>
              <p class="mb-15 mt-20 text-dark servicep">
                Our dedicated team of professionals is well-versed in the
                intricacies of auditing processes and provides a range of
                specialized services to support accountants throughout the
                entire audit lifecycle. Here's how our back-office support can
                benefit accountants:
              </p>
            </div>
            <div
              class="about-faq-area-custom horizon-hrms gray-bg clearfix"
              style="background-color: #4180521a"
            >
              <div class="container">
                <div class="row" style="display: block">
                  <div
                    class="faq-accordion-2"
                    style="padding-bottom: 20px; padding-top: 20px"
                  >
                    <div class="accordion" id="accordionExample2">
                      <div class="col-lg-6">
                        <div
                          class="card wow fadeInLeft"
                          data-wow-duration=".1.2s"
                          data-wow-delay=".2s"
                        >
                          <div class="card-header" id="headingEleven">
                            <a
                              class="collapsed"
                              href=""
                              data-toggle="collapse"
                              data-target="#collapseEleven"
                              aria-expanded="false"
                              aria-controls="collapseEleven"
                              style="color: #418052a1"
                            >
                              Documentation and Preparation
                            </a>
                          </div>
                          <div
                            id="collapseEleven"
                            class="collapse"
                            aria-labelledby="headingEleven"
                            data-parent="#accordionExample2"
                          >
                            <div
                              class="card-body text-justify"
                              style="padding-left: 20px !important"
                            >
                              <p style="color: black">
                                We assist in the preparation and organization of
                                audit documentation, ensuring compliance with
                                relevant standards and regulations. Our team
                                helps accountants streamline the preparation
                                process, enabling them to focus on core audit
                                activities and
                                <b>deliver high-quality results.</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- card -->
                        <div
                          class="card wow fadeInLeft"
                          data-wow-duration=".1.2s"
                          data-wow-delay=".3s"
                        >
                          <div class="card-header" id="headingTwelve">
                            <a
                              class="collapsed"
                              href=""
                              data-toggle="collapse"
                              data-target="#collapseTwelve"
                              aria-expanded="false"
                              aria-controls="collapseTwelve"
                              style="color: #418052a1"
                            >
                              Financial Analysis and Review
                            </a>
                          </div>
                          <div
                            id="collapseTwelve"
                            class="collapse"
                            aria-labelledby="headingTwelve"
                            data-parent="#accordionExample2"
                          >
                            <div
                              class="card-body text-justify"
                              style="padding-left: 20px !important"
                            >
                              <p style="color: black">
                                Our experts conduct in-depth financial analysis
                                and review to identify potential risks,
                                inconsistencies, and areas requiring further
                                investigation.
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- card -->
                        <div
                          class="card wow fadeInLeft"
                          data-wow-duration=".1.2s"
                          data-wow-delay=".4s"
                        >
                          <div class="card-header" id="headingThirteen">
                            <a
                              class="collapsed"
                              href=""
                              data-toggle="collapse"
                              data-target="#collapseThirteen"
                              aria-expanded="false"
                              aria-controls="collapseThirteen"
                              style="color: #418052a1"
                            >
                              Data Management and Analytics
                            </a>
                          </div>
                          <div
                            id="collapseThirteen"
                            class="collapse"
                            aria-labelledby="headingThirteen"
                            data-parent="#accordionExample2"
                          >
                            <div
                              class="card-body text-justify"
                              style="padding-left: 20px !important"
                            >
                              <p style="color: black">
                                We provide robust data management and analytics
                                solutions to efficiently handle vast amounts of
                                financial data.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div
                          class="card wow fadeInLeft"
                          data-wow-duration=".1.2s"
                          data-wow-delay=".6s"
                        >
                          <div class="card-header" id="headingSixteen">
                            <a
                              class="collapsed"
                              href=""
                              data-toggle="collapse"
                              data-target="#collapseSixteen"
                              aria-expanded="false"
                              aria-controls="collapseSixteen"
                              style="color: #418052a1"
                            >
                              Quality Control and Review
                            </a>
                          </div>
                          <div
                            id="collapseSixteen"
                            class="collapse"
                            aria-labelledby="headingSixteen"
                            data-parent="#accordionExample2"
                          >
                            <div
                              class="card-body text-justify"
                              style="padding-left: 20px !important"
                            >
                              <p style="color: black">
                                Our team implements stringent quality control
                                measures to maintain the highest level of
                                accuracy and consistency throughout the audit
                                process.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          class="card wow fadeInLeft"
                          data-wow-duration=".1.2s"
                          data-wow-delay=".7s"
                        >
                          <div class="card-header" id="headingSeventeen">
                            <a
                              class="collapsed"
                              href=""
                              data-toggle="collapse"
                              data-target="#collapseSeventeen"
                              aria-expanded="false"
                              aria-controls="collapseSeventeen"
                              style="color: #418052a1"
                            >
                              Timely Reporting and Compliance
                            </a>
                          </div>
                          <div
                            id="collapseSeventeen"
                            class="collapse"
                            aria-labelledby="headingSeventeen"
                            data-parent="#accordionExample2"
                          >
                            <div
                              class="card-body text-justify"
                              style="padding-left: 20px !important"
                            >
                              <p style="color: black">
                                We assist in the timely completion and
                                submission of audit reports, ensuring compliance
                                with reporting deadlines and regulatory
                                obligations.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- faq accordion -->
                  </div>
                </div>
                <!-- row -->
              </div>
              <!-- container -->
            </div>
            <p class="mb-15 mt-20 text-dark servicep">
              At BFAG, we recognize that every audit and assurance engagement is
              unique, and our back-office support is tailored to meet the
              specific requirements of accountants globally. By leveraging our
              expertise and resources, accountants can enhance their efficiency,
              accuracy, and overall effectiveness in delivering high-quality
              audits and assurance services.
            </p>
            <p class="mb-15 mt-20 text-dark servicep">
              Partner with us for your back-office support needs, and together,
              we can elevate the standard of audits and assurance services
              worldwide.
            </p>
          </div>
        </div>
        <!-- row -->
      </div>
    </div>
  </div>
</section>
<!-- dubai ends -->
