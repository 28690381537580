import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class CareerService {

  constructor(private http: HttpClient) { }

  careerCountry(){
    return this.http.get(`${environment.baseUrl}view_career_country`);
  }

  careerData(){
    return this.http.get(`${environment.baseUrl}view_career_position`);
  }

  contactDetails(data){
    return this.http.post(`${environment.baseUrl}save_contact_details`, data);
  }
  
  careerDetails(data){
    return this.http.post(`${environment.baseUrl}save_career_details`, data);
  }
  
  
}
